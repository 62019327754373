import * as React from "react"
import { memo } from "react"
const SvgComponent = (props) => (
  <svg
    width={112}
    height={112}
    fill="none"
    {...props}
  >
    <path
      fill="#5E6175"
      d="m77.09 11.408-3.024 3.024.928.928 3.025-3.024-.929-.928ZM81.05 17.764h-4.277v1.312h4.277v-1.312ZM71.922 8.262h-1.313v4.276h1.313V8.262Z"
    />
    <path
      fill="#A9AFC4"
      d="M14.544 82.208c-.064-.437-.12-.888-.167-1.33l1.313-.137c.046.424.098.853.162 1.275l-1.308.192Zm-.326-3.556a28.71 28.71 0 0 1-.022-1.341h1.312c0 .422 0 .809.02 1.284l-1.31.057Zm1.376-3.474-1.313-.096c.033-.438.077-.893.127-1.335l1.313.153c-.053.422-.092.853-.125 1.278h-.002Zm.437-3.395-1.28-.252c.081-.437.173-.875.274-1.312l1.28.295c-.097.429-.186.851-.265 1.269h-.009Zm.818-3.327-1.255-.383c.129-.425.27-.853.417-1.273l1.239.437c-.132.4-.267.812-.392 1.219h-.009Zm1.18-3.216-1.186-.518c.175-.41.36-.82.555-1.221l1.182.569c-.195.385-.374.778-.543 1.17h-.009Zm1.53-3.062-1.14-.657c.22-.385.438-.772.686-1.15l1.113.695c-.223.366-.446.737-.656 1.107l-.002.005Zm1.856-2.88-1.061-.771c.26-.36.533-.718.809-1.068l1.028.814c-.262.332-.523.676-.774 1.021l-.002.005Zm2.157-2.7-1.011-.839.838-1.01 1.01.838-.837 1.01Zm2.24-2.685-.998-.853.855-.998.996.856-.854.995Z"
    />
    <path
      fill="#DEE1EC"
      d="M86.443 77.47a26.263 26.263 0 0 1-26.266 26.267H46.442A26.266 26.266 0 0 1 26.22 60.711l14.52-17.518H65.88L80.4 60.711a26.27 26.27 0 0 1 6.043 16.758Z"
    />
    <path
      fill="url(#a)"
      d="M69.511 47.575H37.106l3.63-4.382H65.88l3.631 4.382Z"
      style={{
        mixBlendMode: "multiply",
      }}
    />
    <path
      fill="#DEE1EC"
      d="m41.107 36.172-7.525-20.543h12.007a8.59 8.59 0 0 1 8.59 8.59v4.668a8.591 8.591 0 0 1 8.604-8.59h8.183l-6.125 15.875H41.107Z"
    />
    <path
      fill="#A9AFC4"
      d="M65.01 43.194H41.606a3.511 3.511 0 0 1 0-7.022H65.01a3.51 3.51 0 1 1 0 7.022Z"
    />
    <path
      fill="#5E6175"
      d="M65.01 43.85H41.606a4.167 4.167 0 1 1 0-8.334H65.01a4.167 4.167 0 0 1 0 8.334Zm-23.405-7.022a2.855 2.855 0 0 0 0 5.71H65.01a2.855 2.855 0 0 0 0-5.71H41.605Z"
    />
    <path
      fill="#A9AFC4"
      d="M42.404 43.194c-3.994 0-7.924.993-11.439 2.89a3.613 3.613 0 1 1-3.436-6.36 29.906 29.906 0 0 1 14.033-3.55 3.51 3.51 0 0 0 .032 7.02h.81Z"
    />
    <path
      fill="url(#b)"
      d="M42.404 43.194c-3.994 0-7.924.993-11.439 2.89a3.613 3.613 0 1 1-3.436-6.36 29.906 29.906 0 0 1 14.033-3.55 3.51 3.51 0 0 0 .032 7.02h.81Z"
      style={{
        mixBlendMode: "multiply",
      }}
    />
    <path
      fill="#5E6175"
      d="M24.988 42.894a4.247 4.247 0 0 1 2.23-3.739 30.666 30.666 0 0 1 14.343-3.637v1.312a2.827 2.827 0 0 0-1.989.836c-1.785 1.776-.538 4.871 2.015 4.871h.8v1.313a23.498 23.498 0 0 0-11.108 2.81 4.279 4.279 0 0 1-6.29-3.766Zm13.418-5.882a29.419 29.419 0 0 0-10.567 3.28 2.96 2.96 0 1 0 2.815 5.207 24.866 24.866 0 0 1 8.183-2.708 4.157 4.157 0 0 1-.43-5.78Z"
    />
    <path
      fill="#5E6175"
      d="M60.177 104.393H46.444a26.92 26.92 0 0 1-20.729-44.1l14.713-17.756H66.19l14.713 17.756a26.92 26.92 0 0 1-20.726 44.1ZM41.045 43.85 26.725 61.13a25.607 25.607 0 0 0 19.719 41.95h13.733a25.608 25.608 0 0 0 19.716-41.95L65.573 43.85H41.045Zm24.231-7.022H40.649l-8.006-21.855h12.946a9.257 9.257 0 0 1 9.246 9.172 9.254 9.254 0 0 1 7.947-4.504h9.14l-6.646 17.187Zm-23.71-1.313h22.809L70 20.954h-7.219a7.945 7.945 0 0 0-7.936 7.934h-1.313V24.22a7.943 7.943 0 0 0-7.944-7.934H34.522l7.044 19.23Z"
    />
    <path
      fill="#E1830E"
      d="M87.377 65.753a13.18 13.18 0 0 1-10.64 2.887L65.684 55.382a13.225 13.225 0 1 1 21.693 10.37Z"
    />
    <path
      fill="#F5F5F5"
      d="M83.494 61.094a4.274 4.274 0 0 1-6.011-.545l2.054-1.712a1.592 1.592 0 1 0 .203-2.245 4.265 4.265 0 1 1 .543-6.009l-2.055 1.71a1.592 1.592 0 1 0-.203 2.245 4.271 4.271 0 1 1 5.469 6.563v-.007Z"
    />
    <path
      fill="#F5F5F5"
      d="m74.448 48.161-2.053 1.712 1.707 2.048 2.053-1.712-1.707-2.048ZM83.662 59.212l-2.053 1.712 1.708 2.048 2.053-1.712-1.708-2.048Z"
    />
    <path
      fill="#5E6175"
      d="m63.266 51.466-1.008.84 16.146 19.366 1.008-.84-16.146-19.366ZM86.44 103.082H14.542v1.313H86.44v-1.313ZM101.936 72.154a2.843 2.843 0 1 1 .006-5.687 2.843 2.843 0 0 1-.006 5.687Zm0-4.397a1.542 1.542 0 1 0-.017 3.085 1.542 1.542 0 0 0 .017-3.085ZM91.924 81.834a2.188 2.188 0 1 1 3.231-2.95 2.188 2.188 0 0 1-3.231 2.95Zm1.618-2.336a.876.876 0 1 0 .003 1.75.876.876 0 0 0-.003-1.75Z"
    />
    <path
      fill="#E1830E"
      d="M18.156 25.9a5.076 5.076 0 0 1 5.077 5.076 5.077 5.077 0 0 1 5.075-5.077 5.075 5.075 0 0 1-5.075-5.075 5.074 5.074 0 0 1-5.077 5.075ZM7.875 94.391a3.5 3.5 0 0 1 3.5 3.5 3.5 3.5 0 0 1 3.5-3.5 3.5 3.5 0 0 1-3.5-3.489 3.5 3.5 0 0 1-3.5 3.489Z"
    />
    <defs>
      <linearGradient
        id="a"
        x1={37.103}
        x2={69.511}
        y1={45.385}
        y2={45.385}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CACFDD" />
        <stop offset={1} stopColor="#EEF0F1" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={52.897}
        x2={33.255}
        y1={41.343}
        y2={41.343}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CACFDD" />
        <stop offset={1} stopColor="#EEF0F1" />
      </linearGradient>
    </defs>
  </svg>
)
const BIgMoneyBag = memo(SvgComponent)
export default BIgMoneyBag
