import { Box, Container } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import assets from "../../assets";
import biglogo from "../../assets/biglogo.png";
import womenlogo from "../../assets/womens-first/logopurple.png";
import womenlogin from '../../assets/womens-first/womenbg.png';
import colorConfigs from "../../configs/colorConfigs";



export default function CheckEmail() {
  return (
    <div>
      <div
        style={{
          background: `url(${process.env.REACT_APP_ENV==='women_first'?womenlogin:assets.images.background})`,
          height: "50vh",
        }}
      ></div>
      <div
        style={{
          height: "50vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Container
          component="main"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "15px",
            boxShadow:
              "0px 7px 23px 0px rgba(0, 0, 0, 0.05),0px 7px 23px 0px rgba(0, 0, 0, 0.05)",
            backgroundColor: "white",
            marginTop: "-250px",
            padding: "14px 0",
            width: "400px",
          }}
        >
         <img alt="logo" style={{ width: "50%" }} src={process.env.REACT_APP_ENV==='women_first'?womenlogo:biglogo} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              fontSize: "20px",
              fontStyle: "normal",
              gap: "8px",
              marginBottom: "14px",
            }}
          >
            <span
              style={{
                color: "#42526E",
                fontWeight: "700",
              }}
            >
              Check your email now!
            </span>
            <span
              style={{
                color: "#7A869A",
                fontWeight: "400",
                fontSize: "14px",
                letterSpacing: "-0.5px",
                fontFamily: "Poppins",
                textAlign: "center",
              }}
            >
              We have sent you an email to change your password.
            </span>
            <div
              style={{
                border: "1px solid #E7E7E7",
                width: "100%",
                margin: "16px 0",
              }}
            ></div>
            <span
              style={{
                color: "#7A869A",
                fontWeight: "400",
                fontSize: "14px",
                letterSpacing: "-0.5px",
                fontFamily: "Poppins",
                textAlign: "center",
              }}
            >
              If you have any questions or encounter issues, feel free to reach
              out to us at
            </span>
          {  process.env.REACT_APP_ENV==='women_first'? <span>
              <a
                style={{
                  textDecoration: "none",
                  fontSize: "16px",
                  fontWeight: "500",
                  color: colorConfigs?.sidebar?.bg,
                }}
                href="mailto:women_first@gmail.com?subject=Hello%20Friends"
              >
                women_first@gmail.com
              </a>
            </span>: <span>
              <a
                style={{
                  textDecoration: "none",
                  fontSize: "16px",
                  fontWeight: "500",
                  color: colorConfigs.sidebar.bg ,
                }}
                href="mailto:roadrunner@gmail.com?subject=Hello%20Friends"
              >
                roadrunner@gmail.com
              </a>
            </span>}
           
            <Box
              sx={{
                marginTop: "30px",
                mb: "42px",
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Link
                to="/login"
                style={{
                  width: "50%",
                  cursor: "pointer",
                  color: "white",
                  backgroundColor: colorConfigs?.sidebar?.bg,
                  fontWeight: "bold",
                  fontSize: "16px",
                  textDecoration: "none",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "4px",
                  padding: "12px",
                }}
              >
                Back to login
              </Link>
            </Box>
          </div>
        </Container>
      </div>
    </div>
  );
}
