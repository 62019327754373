import {
    Box,
    Typography,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button, IconButton, List, ListItem, ListItemText, Paper
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { closeModal, openModal } from '../../redux/features/modalSlice';
import colorConfigs from "../../configs/colorConfigs";
import { TextInput } from "@zetaton-dev/zetaton-components-bank";
import { useEffect, useRef, useState } from "react";
import { LuUpload } from "react-icons/lu";
import { useDispatch, useSelector } from "react-redux";
import Loading from "react-fullscreen-loading";
import { storageSdk } from '../../configs/firebase';
import { postMethod, getMethod, putMethod } from "../../pages/Hook/UseCustomApi";
import { postDataRedux, putDataRedux } from '../../redux/features/appStateSlice';

const nameImg = {
    marginLeft: "10px",
    marginTop: "-6px",
    width: "34px",
    height: "30px",
    borderRadius: "50%",
}
const ModalContainer = styled(Box)(({ theme }) => ({
    background: '#FFFFFF',
    padding: "20px",
    borderRadius: "12px",
    width: '700px',
    maxWidth: '100%',
    maxHeight: '94vh',
    overflow: 'auto',
    margin: '0 auto',
}));

const Header = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '3px 10px',
}));

const Input = styled("input")({
    display: "none",
});
const imgsBox = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    alignItems: "center",
}

const AddNewTicket = ({ status }) => {

    const addEditStatus = useSelector((state) => state.appState.addEditStatus);
    const details = useSelector((state) => state.appState.details);
    const [inputKey, setInputKey] = useState("");
    const [isOwnerListOpen, setIsOwnerListOpen] = useState(false);
    const [isNameSelected, setIsNameSelected] = useState(false);
    const [isTicketId, setIsTicketId] = useState(false);
    const [selectedOwner, setSelectedOwner] = useState({});
    const [isRideWalletListOpen, setIsRideWalletListOpen] = useState(false);

    const dispatch = useDispatch();
    const [allHelpCenter, setAllHelpCenter] = useState([]);
    const [loading, setLoading] = useState("");
    const [url, setUrl] = useState("");
    const [noData, setNoData] = useState(false);



    const [dataToAdd, setDataToAdd] = useState(() => {
        if (addEditStatus === "edit") {
            return {
                ...details,
                name: details.owner_info ? `${details.owner_info.first_name} ${details.owner_info.last_name}` : "",
                shortId: details?.ride_info?.short_id
            };
        } else {
            return {
                knowledgeBase_id: "",
                owner_id: "",
                name: "",
                shortId: "",
                ticketId: "",
                type: "ride",
                priority: "high",
                reasone: "Select a reason",
                sup_reasone: "Select a reason",
                description: "",
                note: "",
                images: []
            };
        }
    });

    const [errors, setErrors] = useState({});
    const [ownerList, setOwnerList] = useState([]);
    const [rideWalletList, setRideWalletList] = useState([]);
    const [reasone, setReasone] = useState("Select a reason");
    const [addNote, setAddNote] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleClose = () => {
        setErrors({})
        dispatch(closeModal());
        setDataToAdd(addEditStatus === "edit" ? details : {
            knowledgeBase_id: "",
            owner_id: "",
            name: "",
            ticketId: "",
            shortId: "",
            type: "ride",
            priority: "high",
            reasone: "Select a reason",
            sup_reasone: "Select a reason",
            description: "",
            note: "",
            images: []
        })
    };

    const getAllHelpCenter = () => {

        getMethod({
            url: `admin/help/knowledge-base`,
            params: {
                keyword: "",
                type: "all",
                limit: 50,
                user_type: status && status === "drivers" ? "driver" : "customer"
            },
        })
            .then((response) => {
                setAllHelpCenter(response.data)
            })
            .catch((error) => {
                console.log("Error on getting active ride:", error);
            });
    }

    useEffect(() => {
        getAllHelpCenter()
    }, []);

    const searchForOwner = (value) => {

        const url = status === "drivers" ? "driver" : "customer"

        getMethod({
            url: url,
            params: {
                page: 1,
                limit: 10,
                search_first_name: value,
                search_last_name: value,
            },
        })
            .then((response) => {
                setOwnerList(response.data)
                setIsOwnerListOpen(true);
            })
            .catch((error) => {
                console.log("Error on getting active ride:", error);
            });
    }
    const searchForRideWallet = (value) => {


        const url = dataToAdd.type === "ride" ? "ride" : status === "drivers" ?
            `driver/wallet/history/${selectedOwner?._id}` : `customer/wallet/history/${selectedOwner?._id}`

        getMethod({
            url: url,
            params: {
                page: 1,
                limit: 10,
                search_short_id: value,
            },
        })
            .then((response) => {
                setRideWalletList(response.data)
                setIsRideWalletListOpen(true);
            })
            .catch((error) => {
                console.log("Error on getting active ride:", error);
            });
    }

    const fieldStyle = {
        "& label.Mui-focused": {
            color: "#172B4D !important",
            fontSize: "17px !important",
            fontWeight: "500 !important",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#BBC1CE",
                borderWidth: 1,
                borderRadius: "8px",
            },
            "&:hover fieldset": {
                borderColor: "#BBC1CE",
                borderWidth: 1,
                borderRadius: "8px",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#BBC1CE",
                borderWidth: 1,
                borderRadius: "8px",
            },
            input: {
                "&::placeholder": {
                    color: "#3A3541DE",
                    opacity: 1,
                    fontSize: 14,
                },
            },
        },
    };

    const handleDelete = (index) => {
        const updatedImages = [...dataToAdd?.images];
        updatedImages.splice(index, 1);
        setDataToAdd(prevData => ({
            ...prevData,
            images: updatedImages
        }));
    };

    const handleImagrUrl = async (e) => {
        setLoading(true);
        let file = e.target?.files[0];
        if ((file && file?.length !== 0) || (file && file !== "" && typeof file !== "string")) {
            setLoading(true);
            const uploadTask = storageSdk.ref(`/ticket/${file.name}`).put(file);
            uploadTask.on(
                "state_changed",
                (snapShot) => {
                    //takes a snap shot of the process as it is happening
                },
                (err) => {
                    setLoading(false); // Set loading to false in case of an error
                },
                async () => {
                    try {
                        const fireBaseUrl = await storageSdk
                            .ref("ticket")
                            .child(file.name)
                            .getDownloadURL();

                        try {
                            setLoading(true);
                            setDataToAdd(prevData => ({
                                ...prevData,
                                images: [...prevData.images, fireBaseUrl],
                            }));
                        } catch (error) {
                            console.error("Error adding image URL to array:", error.message);
                        } finally {
                            setLoading(false);
                        }
                        setUrl(fireBaseUrl);
                    } catch (error) {
                        console.error("Download URL error:", error.message);
                        setLoading(false);
                    } finally {
                        setLoading(false);
                    }
                }
            );
        } else {
            setUrl(file);
            setLoading(false);
        }
        setInputKey(Math.random());
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));

        if (name === "name") {
            if (value === "") { setIsNameSelected(false) }
            if (addEditStatus === "edit") {
                setDataToAdd((prevData) => ({
                    ...prevData,
                    name: value, // Store the full name here for the TextInput
                    owner_info: {
                        ...prevData.owner_info,
                        first_name: value.split(" ")[0] || "", // Update first name based on the input
                        last_name: value.split(" ")[1] || "" // Update last name based on the input
                    }
                }));
            } else {
                setDataToAdd((prevData) => ({
                    ...prevData,
                    name: value // Store the full name here for the TextInput
                }));
            }
            searchForOwner(value); // Continue to search as the user types
        } else if (name === "ticketId") {
            setDataToAdd((prevData) => ({
                ...prevData,
                shortId: value
            }));
            searchForRideWallet(value); // Continue to search as the user types
        } else if (name === "type") {
            setDataToAdd((prevData) => ({
                ...prevData,
                [name]: value,
                reasone: "Select a reason",
                sup_reasone: "Select a reason",
                shortId: ""
            }));
            setIsMenuOpen(false)
        }
        else {
            setDataToAdd((prevData) => ({
                ...prevData,
                [name]: value
            }));
        }

    };
    const handleNameSelect = (ownerId, name, owner) => {
        // Destructure the necessary properties from the owner object
        const {
            code_phone_number,
            email,
            first_name,
            id,
            last_name,
            phone_number,
            profile_picture,
            short_id,
            _id
        } = owner;

        setDataToAdd((prevData) => {
            if (addEditStatus === "add") {
                return {
                    ...prevData,
                    name: name,
                    owner_id: ownerId,
                };
            }
            if (addEditStatus === "edit") {
                return {
                    ...prevData,
                    owner_info: {
                        code_phone_number,
                        email,
                        first_name,
                        id,
                        last_name,
                        phone_number,
                        profile_picture,
                        short_id,
                        _id
                    },
                    name: name,
                };
            }
            return prevData; // Just in case, return prevData if neither condition matches
        });
        setSelectedOwner(owner)
        setIsNameSelected(true)
        setOwnerList([]);
        setIsOwnerListOpen(false);
    };

    const handleticketIdSelect = (itemId, short, item) => {


        if (addEditStatus === "edit") {

            const { id, status, _id, short_id } = item;
            let second_user_info = {};
            if (dataToAdd?.owner_type === "driver") {
                const {
                    code_phone_number,
                    email,
                    first_name,
                    id,
                    last_name,
                    phone_number,
                    profile_picture,
                    short_id,
                    uid
                } = item.driver_info;

                second_user_info = {
                    code_phone_number,
                    email,
                    first_name,
                    id,
                    last_name,
                    phone_number,
                    profile_picture,
                    short_id,
                    _id: uid
                };
            } else {
                const {
                    code_phone_number,
                    email,
                    first_name,
                    id,
                    last_name,
                    phone_number,
                    profile_picture,
                    short_id,
                } = item.customer_details;

                second_user_info = {
                    code_phone_number,
                    email,
                    first_name,
                    id,
                    last_name,
                    phone_number,
                    profile_picture,
                    short_id,
                    _id: item.customer_id
                };
            }
            setDataToAdd((prevData) => ({
                ...prevData,
                shortId: short,
                ride_info: {
                    ...prevData.ride_info,
                    id,
                    _id,
                    short_id,
                    status,
                    second_user_info,
                }
            }));

        } else {
            setDataToAdd((prevData) => ({
                ...prevData,
                ticketId: itemId,
                shortId: short,
            }));
        }

        setIsTicketId(true)
        setRideWalletList([]);
        setIsRideWalletListOpen(false);
    };

    const handleSave = () => {

        const newErrors = {};

        if (dataToAdd.name === "" || (addEditStatus === "add" && !isNameSelected)) {
            newErrors.name = "Name is required.";
        }
        if (dataToAdd.ticketId === "" || (addEditStatus === "add" && !isTicketId && dataToAdd.type === "ride")) {
            newErrors.ticketId = dataToAdd.type === "ride" ? "Ride Id is required." : "Transaction Id is required.";
        }
        if (dataToAdd.reasone === "Select a reason") {
            newErrors.reasone = "Reason is required.";
        }
        if (dataToAdd.description === "") {
            newErrors.description = "Description is required.";
        }




        setErrors(newErrors);
        if (Object.keys(newErrors).length > 0) {
            return;
        } else {


            const addBody = {
                owner_id: dataToAdd.owner_id,
                owner_type: status === "drivers" ? "driver" : "customer",
                ticket_id: dataToAdd.ticketId,  //ride or wallet id
                ticket_type: dataToAdd?.type,
                knowledgeBase_id: dataToAdd.knowledgeBase_id,
                description: dataToAdd?.description,
                images: dataToAdd?.images,
                note: dataToAdd.note
            }




            const { name, shortId, ...dataToSend } = dataToAdd;
            const cleanedData = JSON.parse(JSON.stringify(dataToSend, (key, value) => {
                if (key === "_id" && value === "") {
                    return undefined; // Remove invalid _id fields
                }
                return value;
            }));

            setLoading(true);

            const method = addEditStatus === "add" ? postMethod : putMethod;
            const methodUrl = addEditStatus === "add" ? `admin/help/ticket` : `admin/help/ticket/${dataToAdd._id}`;

            method({
                url: methodUrl,
                body: addEditStatus === "add" ? addBody : cleanedData,
            })
                .then((response) => {
                    handleClose();
                    setLoading(false);
                    addEditStatus === "add" ? dispatch(postDataRedux({ stateName: "ticketsList", data: response.data, push: true })) :
                        dispatch(putDataRedux({ key: "_id", value: dataToAdd._id, stateName: "ticketsList", data: response.data }))
                })
                .catch((err) => {
                    console.error("Error fetching more stores: name already used ", err);
                    setLoading(false);
                });
            setLoading(false);


        }


    };


    const filteredItems = allHelpCenter?.filter(item =>
        item?.type === (addEditStatus === "add" ? dataToAdd?.type : dataToAdd?.knowledgeBase?.type)
    );

    useEffect(() => {
        if (addEditStatus === "add") {
            const timer = setTimeout(() => {
                if (filteredItems?.length === 0) {
                    setNoData(true);
                    console.log("No Data");
                } else {
                    setNoData(false);
                }
            }, 1000);

            // Clear timeout on cleanup
            return () => clearTimeout(timer);
        }
    }, [dataToAdd, filteredItems, addEditStatus]);


    return (
        <ModalContainer>
            <Header>
                <Typography sx={{
                    color: '#172B4D',
                    fontFamily: "Poppins",
                    fontSize: "18px",
                    fontWeight: "700",
                    lineHeight: "27px",
                    marginBottom: "15px"
                }}>
                    {addEditStatus === "add" ? "Add New" : "Edit"}   Ticket For {status === "drivers" ? "Driver." : "Passenger."}
                </Typography>
            </Header>
            <Grid container spacing={2}>
                {/* name */}
                <Grid item xs={6}>
                    <TextInput
                        disabled={addEditStatus === "edit"}
                        fullWidth
                        variant="outlined"
                        placeholder="Search By Name"
                        value={dataToAdd.name}
                        onChange={handleChange}
                        onBlur={() => { setOwnerList([]); }}
                        margin="dense"
                        styles={fieldStyle}
                        style={{
                            ...(addEditStatus === "edit" && { marginTop: "10px" })
                        }}
                        label={status === "riders" ? "Passenger Name" : "Driver Name"}
                        name="name"
                        filled="true"
                        focused
                        InputProps={{
                            style: { backgroundColor: 'transparent', borderRadius: '10px', height: 45 },
                        }}
                        error={Boolean(errors.name)}
                    />
                    {errors.name && (
                        <Typography variant="caption" style={{ color: "red", marginLeft: "15px" }}>
                            {errors.name}
                        </Typography>
                    )}
                    {isOwnerListOpen && ownerList.length > 0 && (
                        <div style={{ position: 'relative' }}>
                            <Paper
                                style={{
                                    maxHeight: 200,
                                    overflowY: 'auto',
                                    marginTop: 5,
                                    position: 'absolute',
                                    width: '100%',
                                    zIndex: 1000
                                }}
                            >
                                <List>
                                    {ownerList && ownerList.map((owner, index) => (
                                        <ListItem
                                            key={index}
                                            onMouseDown={() => {
                                                handleNameSelect(owner._id, owner.first_name + " " + owner.last_name, owner);
                                            }}
                                            style={{
                                                marginTop: "5px",
                                                cursor: 'pointer',
                                                zIndex: 1
                                            }}
                                        >
                                            <img
                                                style={nameImg}
                                                src={owner?.profile_picture ?
                                                    owner?.profile_picture :
                                                    "https://i.pinimg.com/736x/de/59/4e/de594ec09881da3fa66d98384a3c72ff.jpg"}
                                                alt=""
                                            />
                                            <span style={{ marginLeft: "10px" }}>{owner?.first_name + " " + owner?.last_name}</span>
                                        </ListItem>
                                    ))}
                                </List>
                            </Paper>
                        </div>
                    )}
                </Grid>
                {/* Category */}
                {addEditStatus === "add" && (
                    <Grid item xs={6}>
                        <FormControl
                            fullWidth
                            style={{ marginTop: "2px", paddingTop: "8px" }}
                            sx={{
                                "& label.Mui-focused": { color: "rgba(0, 0, 0, 0.6)" },
                                "& .MuiOutlinedInput-root": {
                                    "&.Mui-focused fieldset": {
                                        borderColor: "#A0AEC0",
                                    },
                                    borderRadius: "10px",
                                    height: "50px",
                                },
                            }}
                        >
                            <InputLabel id="demo-simple-select-label" style={{ paddingTop: "8px" }}>
                                Category
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={addEditStatus === "add" ? dataToAdd.type : dataToAdd?.knowledgeBase?.type}
                                label="Category"
                                name="type"
                                onChange={handleChange}
                                style={{ borderRadius: "10px", height: "45px", }}
                            >
                                <MenuItem value={"ride"}>Ride</MenuItem>
                                <MenuItem value={"payment"}>Payment</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                )}
                {/* ride wallet id */}
                <Grid item xs={6}>
                    <TextInput
                        disabled={(dataToAdd?.type === "payment" && !isNameSelected) || addEditStatus === "edit"}
                        fullWidth
                        variant="outlined"
                        placeholder="Search By Short Id"
                        value={addEditStatus === "edit" ? dataToAdd?.knowledgeBase?.type === "ride" ? dataToAdd?.ride_info?.short_id : dataToAdd?.payment_info?.short_id : dataToAdd.shortId}
                        onChange={handleChange}
                        onBlur={() => setRideWalletList([])}
                        margin="dense"
                        styles={fieldStyle}
                        label={addEditStatus === "add" ? dataToAdd.type === "ride" ? "Ride ID" : "Transaction ID" :
                            dataToAdd?.knowledgeBase?.type === "ride" ? "Ride ID" : "Transaction ID"}
                        name="ticketId"
                        filled="true"
                        focused
                        InputProps={{
                            style: { backgroundColor: 'transparent', borderRadius: '10px', height: 45 },
                            inputProps: { type: 'number' },
                        }}
                        InputLabelProps={{
                            style: {
                                position: (dataToAdd?.type === "payment" && !isNameSelected) && addEditStatus !== "edit" ? 'absolute' : '',
                                top: (dataToAdd?.type === "payment" && !isNameSelected) && addEditStatus !== "edit" ? '50%' : '',
                                transform: (dataToAdd?.type === "payment" && !isNameSelected) && addEditStatus !== "edit" ? 'translateY(-50%)' : '',
                                width: '100%',
                                left: (dataToAdd?.type === "payment" && !isNameSelected) && addEditStatus !== "edit" ? '15px' : ''
                            }
                        }}
                        style={{ marginTop: '10px' }}
                        error={Boolean(errors.ticketId)}
                    />
                    {errors.ticketId && (
                        <Typography variant="caption" style={{ color: "red", marginLeft: "15px" }}>
                            {errors.ticketId}
                        </Typography>
                    )}
                    {isRideWalletListOpen && rideWalletList.length > 0 && (
                        <Paper style={{ maxHeight: 200, overflowY: 'auto', marginTop: 5 }}>
                            <List>
                                {rideWalletList && rideWalletList.map((item, index) => (
                                    <ListItem
                                        key={index}
                                        onMouseDown={() => {
                                            handleticketIdSelect(item?._id, item?.short_id, item);
                                        }}
                                        style={{
                                            marginTop: "5px",
                                            cursor: 'pointer',
                                            zIndex: 1
                                        }}
                                    >
                                        <span>{item?.short_id}</span>
                                    </ListItem>
                                ))}
                            </List>
                        </Paper>
                    )}
                </Grid>
                {/* Priority */}
                {/* <Grid item xs={6}>
                    <FormControl
                        fullWidth
                        style={{ marginTop: "2px", paddingTop: "8px" }}
                        sx={{
                            "& label.Mui-focused": { color: "rgba(0, 0, 0, 0.6)" },
                            "& .MuiOutlinedInput-root": {
                                "&.Mui-focused fieldset": {
                                    borderColor: "#A0AEC0",
                                },
                                borderRadius: "10px",
                                height: "50px",
                            },
                        }}

                    >
                        <InputLabel id="demo-simple-select-label" style={{ paddingTop: "8px" }}>
                            Priority
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={addEditStatus === "add" ? dataToAdd.priority : dataToAdd?.knowledgeBase?.priority}
                            label="Priority"
                            name="priority"
                            onChange={handleChange}
                            style={{ borderRadius: "10px", height: "45px", }}
                        >
                            <MenuItem value={"high"}>High</MenuItem>
                            <MenuItem value={"medium"}>Medium</MenuItem>
                            <MenuItem value={"low"}>Low</MenuItem>
                        </Select>
                    </FormControl>
                </Grid> */}
                {/* Reason */}
                <Grid item xs={addEditStatus === "edit" ? 12 : 6}>
                    <FormControl
                        fullWidth
                        style={{ marginTop: "2px", paddingTop: "8px" }}
                        sx={{
                            "& label.Mui-focused": { color: "rgba(0, 0, 0, 0.6)" },
                            "& .MuiOutlinedInput-root": {
                                "&.Mui-focused fieldset": {
                                    borderColor: "#A0AEC0",
                                },
                                borderRadius: "10px",
                                height: "50px",
                            },
                        }}
                    >
                        <InputLabel id="demo-simple-select-label" style={{ paddingTop: "8px" }}>
                            Reason
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={addEditStatus === "add" ? reasone : dataToAdd?.knowledgeBase?.title}
                            label="Reason"
                            disabled={noData}
                            onOpen={() => setIsMenuOpen(true)}
                            onClose={() => setIsMenuOpen(false)}
                            onChange={(e) => {
                                const selectedValue = e.target.value;
                                setReasone(selectedValue);
                                const selectedItem = allHelpCenter.find((item) => item.title === selectedValue);
                                if (selectedItem) {
                                    if (addEditStatus === "edit") {
                                        setDataToAdd((prevData) => ({
                                            ...prevData,
                                            knowledgeBase: selectedItem
                                        }));
                                    } else if (addEditStatus === "add") {
                                        setDataToAdd((prevData) => ({
                                            ...prevData,
                                            reasone: selectedValue,
                                            sup_reasone: selectedItem.sup_reasone,
                                            knowledgeBase_id: selectedItem._id
                                        }));
                                    }
                                    setErrors((prevData) => ({
                                        ...prevData,
                                        reasone: '',
                                    }));
                                }
                            }}
                            style={{ borderRadius: "10px", height: "45px" }}
                        >
                            {!isMenuOpen && (
                                <MenuItem value="Select a reason" disabled>
                                    Select a reason
                                </MenuItem>
                            )}
                            {filteredItems?.length > 0 &&
                                filteredItems.map((item, index) => (
                                    <MenuItem key={index} value={item.title}>
                                        {item.title}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                    {noData && (
                        <Typography variant="caption" style={{ color: "red", }}>
                            {"Please add a help center for this category."}
                        </Typography>
                    )}
                    {errors.reasone && (
                        <Typography variant="caption" style={{ color: "red", marginLeft: "15px" }}>
                            {errors.reasone}
                        </Typography>
                    )}
                </Grid>
                {/* Sub Reason */}
                {/* <Grid item xs={12}>
                    <TextInput
                        fullWidth
                        variant="outlined"
                        placeholder="sub_reason"
                        value={addEditStatus === "add" ? dataToAdd.sup_reasone : dataToAdd?.knowledgeBase?.reasone}
                        margin="dense"
                        styles={fieldStyle}
                        label={"Sub Reason"}
                        name="sub_reason"
                        filled="true"
                        focused
                        InputProps={{
                            style: { backgroundColor: 'transparent', borderRadius: '10px', height: 45 },
                            readOnly: true,
                        }}
                    />
                </Grid> */}
                {/* Description */}
                <Grid item xs={12}>
                    <TextInput
                        margin="dense"
                        styles={fieldStyle}
                        fullWidth
                        label={"Description"}
                        name="description"
                        onChange={handleChange}
                        value={dataToAdd.description}
                        variant="outlined"
                        placeholder="Please describe the ticket"
                        filled="true"
                        focused
                        multiline
                        style={{ height: '100px' }}
                        InputProps={{
                            style: {
                                height: '100px',
                                whiteSpace: 'pre-wrap',
                                wordWrap: 'break-word',
                            },
                        }}
                        error={Boolean(errors.description)}
                    />
                    {errors.description && (
                        <Typography variant="caption" style={{ color: "red", marginLeft: "15px" }}>
                            {errors.description}
                        </Typography>
                    )}
                </Grid>
                {/* upload-imgs */}
                <Grid item xs={12}>
                    <Typography style={{
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontWeight: "400",
                        marginBottom: "5px",
                        color: "#7A869A",
                        lineHeight: "17px"
                    }}>
                        Upload Images
                    </Typography>
                    <Box style={imgsBox}>
                        {dataToAdd.images &&
                            dataToAdd?.images?.map((src, index) => (
                                <div
                                    key={index}
                                    style={{
                                        position: "relative",
                                        marginRight: "10px",
                                        display: "flex",
                                    }}
                                >
                                    <img
                                        src={src}
                                        alt={`alt-${index}`}
                                        style={{
                                            width: "200px",
                                            height: "165px",
                                            borderRadius: "8px",
                                            cursor: "pointer",
                                        }}
                                    />
                                    <button
                                        onClick={() => handleDelete(index)}
                                        style={{
                                            position: "absolute",
                                            top: "5px",
                                            left: "5px",
                                            background: "none",
                                            border: "none",
                                            cursor: "pointer",
                                            color: "red",
                                        }}
                                    >
                                        &#x2716;
                                    </button>
                                </div>
                            ))}

                        {dataToAdd?.images?.length < 3 && (
                            <div
                                style={{
                                    width: "180px",
                                    height: "165px",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    border: "1px dashed black",
                                    gap: "12px",
                                    borderRadius: "8px",
                                    backgroundColor: "#F5F5F5"
                                }}
                            >
                                <IconButton
                                    component="label"
                                    style={{
                                        color: colorConfigs.sidebar.bg,
                                    }}
                                >
                                    <LuUpload
                                        size={40}
                                    />
                                    <input
                                        type="file"
                                        hidden
                                        style={{ display: "none" }}
                                        multiple
                                        size="20971520"
                                        accept="image/png, image/jpeg"
                                        key={inputKey}
                                        onChange={(e) => handleImagrUrl(e)}
                                        onClick={(e) => (e.target.value = null)}
                                    />{" "}
                                </IconButton>
                                <Typography color={"#7A869A"}>
                                    {" "}
                                    Tap To Upload
                                </Typography>
                            </div>
                        )}
                    </Box>
                </Grid>
                {/* Add Note / Comment btn */}
                {addEditStatus === "add" && (
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            sx={{
                                textTransform: 'none',

                                backgroundColor: "#FFFFFF",
                                border: "0.1px solid",
                                boxShadow: "none",
                                borderColor: colorConfigs?.sidebar?.bg,
                                borderRadius: "8px",
                                fontWeight: "600",
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                lineHeight: "21px",
                                height: "40px",
                                width: "215px",
                                color: colorConfigs?.sidebar?.bg,
                                '&:hover': {
                                    backgroundColor: '#FFFFFF',
                                },
                            }}
                            onClick={() => { setAddNote(!addNote) }}
                        >
                            +{" "}{"Add Note / Comment"}

                        </Button>
                    </Grid>
                )}
                {/* Note / Comment */}
                {(addNote || addEditStatus === "edit") && (
                    <Grid item xs={12}>
                        <TextInput
                            margin="dense"
                            styles={fieldStyle}
                            fullWidth
                            label={"Note / Comment"}
                            name="note"
                            onChange={handleChange}
                            value={dataToAdd.note}
                            variant="outlined"
                            placeholder="Please add your note"
                            filled="true"
                            focused
                            multiline
                            style={{ height: '100px' }}
                            InputProps={{
                                style: {
                                    height: '100px',
                                    whiteSpace: 'pre-wrap',
                                    wordWrap: 'break-word',
                                },
                            }}

                        />
                    </Grid>
                )}
                {/* save and Cancel */}
                <Grid item xs={6}>
                    <Box style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "flex-start",
                        width: "100%",
                        padding: "0",
                        gap: "16px",
                        marginTop: "10px"
                    }}>
                        <Button
                            variant="contained"
                            sx={{
                                textTransform: 'none',
                                backgroundColor: 'white',
                                color: colorConfigs?.sidebar?.bg,
                                borderRadius: '10px',
                                width: "100%",
                                padding: '10px 20px',
                                '&:hover': {
                                    backgroundColor: '#E6E9F2',
                                },
                            }}
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>

                        <Button
                            variant="contained"
                            sx={{
                                textTransform: 'none',
                                backgroundColor: process.env.REACT_APP_ENV === 'women_first' ? colorConfigs?.sidebar?.bg : '#005e8b',
                                color: 'white',
                                borderRadius: '10px',
                                width: "100%",
                                padding: '10px 20px',
                                '&:hover': {
                                    backgroundColor: process.env.REACT_APP_ENV === 'women_first' ? colorConfigs?.sidebar?.bg : '#004b6e',
                                },
                            }}
                            onClick={handleSave}
                        >
                            Save
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            <Loading
                loading={loading}
                background="rgba(86, 100, 210, 0.1)"
                loaderColor={colorConfigs.sidebar?.bg}
            />
        </ModalContainer>
    );
};

export default AddNewTicket;
