import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { IoMdArrowRoundBack, IoMdClose } from 'react-icons/io';
import { useDispatch } from 'react-redux';
import colorConfigs from '../../../configs/colorConfigs';
import { selectChat, selectRide, updateOutChat } from '../../../redux/features/appStateSlice';
import { closeModal, openModal } from '../../../redux/features/modalSlice';
import SupportMessagingPage from '../../public-messaging/Messaging';
import DriverDetailsModal from './DriverDetailsModal';
import RideDetailsModal from './RideDetailsModal';

const ModalContainer = styled(Box)(({ theme }) => ({
    background: '#FFFFFF',
    padding: theme.spacing(1.5),
    borderRadius: theme.shape.borderRadius,
    width: '1106px',
    maxWidth: '95%',
    maxHeight: '94vh',
    overflow: 'auto',
    margin: '0 auto',
}));

const Header = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '3px 10px',
}));

const CloseButton = styled(Box)(({ theme }) => ({
    background: '#F0F0F0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer',
}));

const Card = styled(Box)(({ theme }) => ({
    background: 'white',
    padding: theme.spacing(1),
    boxShadow: 'rgba(0, 0, 0, 0.05) 0 4px 36px',
    borderRadius: theme.shape.borderRadius,
    margin: theme.spacing(1, 0, 0, 0),
}));

const CardHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#F4F4F4',
    padding: theme.spacing(1, 2),
    borderRadius: theme.shape.borderRadius,
}));

const ContactInfo = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(0.7),
    '& svg': {
        marginRight: theme.spacing(0.5),
        color: colorConfigs?.sidebar?.bg,
    },
}));

const InfoText = styled(Typography)(() => ({
    color: '#495974',
    fontSize: 12,
    fontWeight: 400,
}));

const BoldText = styled(Typography)(() => ({
    fontWeight: 700,
    color: '#172B4D',
    fontSize: 14,
}));

const DriverAndCustomerChatModal = ({ id,status ,active_ride_id}) => {
    
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(closeModal());
        dispatch(updateOutChat(false));
        dispatch(selectChat({}));
        dispatch(selectRide({}));
    };

    const Back = () => {
    
        if (status !== "free" || active_ride_id  || !id) {
            dispatch(updateOutChat(false));
            dispatch(openModal(<RideDetailsModal active_ride_id={active_ride_id}  status={status} />));
        } else {
            dispatch(updateOutChat(false));
            dispatch(openModal(<DriverDetailsModal id={id} status={status} />));
        }
    }


    return (
        <ModalContainer>
            <Header>
                <Typography onClick={Back} variant="h6" sx={{ color: '#172B4D', fontWeight: 700,display:'flex',alignItems:'center',gap:1,cursor:'pointer' }}>
                    <IoMdArrowRoundBack />
                    Back
                </Typography>
                <CloseButton onClick={handleClose}>
                    <IoMdClose size={20} />
                </CloseButton>
            </Header>
            <SupportMessagingPage />
        </ModalContainer>
    );
};

export default DriverAndCustomerChatModal;
