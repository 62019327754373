import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getMethod } from '../Hook/UseCustomApi';
import { postDataRedux } from '../../redux/features/appStateSlice';

const useTableData = (fetchUrl, initialState = {}, stateName) => {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [limit, setLimit] = useState(10);
    const [filters, setFilters] = useState(initialState);
    const [loading, setLoading] = useState(false);
    const [debouncedFilters, setDebouncedFilters] = useState(initialState);

    const dispatch = useDispatch();

    const fetchTableData = async (appliedFilters) => {
        setLoading(true);
        const params = {
            page: page,
            limit: limit,
            ...appliedFilters,
        };

        try {
            const response = await getMethod({ url: fetchUrl, params });
            setData(response?.data || []);
            // console.log(response?.data);

            setCount(response.count && response?.count !== 0 ? response?.count : 1);

            if (stateName) {
                dispatch(postDataRedux({ stateName, data: response?.data }));
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleRowsChange = (rows) => {
        // console.log(rows)
        setLimit(rows)
        setPage(1);
    };

    const updateFilters = (newFilters) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            ...newFilters,
        }));
    };

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedFilters(filters);
        }, 100);

        return () => {
            clearTimeout(handler);
        };
    }, [filters]);

    useEffect(() => {
        fetchTableData(debouncedFilters);
        window.scrollTo(0, 0);
    }, [debouncedFilters, page, limit]);

    return {
        data,
        page,
        count,
        loading,
        handlePageChange,
        handleRowsChange,
        updateFilters,
        filters,
    };
};

export default useTableData;