import { Typography } from "@mui/material";
import React from "react";
import ReactBnbGallery from "react-bnb-gallery";
const AttachmentHandler = ({
  images,
  onClick,
  CustomClick,
  maxHeight,
  paddingBottom,
}) => {
  const [isOpen, setOpen] = React.useState(false);
  const [main, setMain] = React.useState(0);
  const videoExtensions = ["mov", "avi", "wmv", "flv", "3gp", "mp4", "mpg"];
  const getMediaType = (url) => {
    const mediaExtension = url
      ?.split("?alt")[0]
      .substring(url?.lastIndexOf(".") + 1, url?.lastIndexOf(".") + 4)
      .toString();
    return mediaExtension ? mediaExtension : "img";
  };
  const handleOpen = (status, img) => {
    if (CustomClick) {
      onClick(status, img);
    } else {
      if (img) setMain(img);
      setOpen(status);
    }
  };
  const MediaTag = ({ index, style }) => {
    const media = images[index];
    const type = getMediaType(media);
    const isVideo = videoExtensions.includes(type?.toLowerCase());

    if (index === 3 && images?.length > 4) {
      return (
        <div
          onClick={() => handleOpen(true, 3)}
          style={{
            background: `url(${
              isVideo ? images[4] : media
            }) center center / cover`,
            backgroundColor: "rgb(240,240,240)",
            width: "100%",
            height: "calc(50% - 2px)",
            cursor: "pointer",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              background: "rgb(0 0 0 / 77%)",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography
              variant={"body2"}
              style={{ color: "white", margin: "auto", width: "fit-content" }}
            >{`+${images.length - 3}`}</Typography>
          </div>
        </div>
      );
    } else if (isVideo) {
      return (
        <video style={{ cursor: "pointer", ...style }} controls>
          <source src={media} type={`video/mp4`} />
          Your browser does not support the video tag.
        </video>
      );
    } else {
      return (
        <div
          onClick={() => handleOpen(true, index)}
          style={{
            background: `url("${media}") center center / cover`,
            backgroundColor: "rgb(240,240,240)",
            cursor: "pointer",
            ...style,
          }}
        />
      );
    }
  };
  const getGrid = () => {
    switch (images.length) {
      case 1:
        return (
          <div
            style={{
              display: "flex",
              overflow: "hidden",
              borderRadius: "12px",
              width: "100%",
              maxHeight: maxHeight,
            }}
          >
            <div style={{ paddingBottom: "60%" }} />
            <MediaTag
              index={0}
              style={{
                width: "100%",
                maxHeight: maxHeight,
                paddingBottom: paddingBottom ? "60%" : "auto",
                borderRadius: "12px",
              }}
            />
          </div>
        );
      case 2:
        return (
          <div
            style={{
              display: "flex",
              overflow: "hidden",
              borderRadius: "12px",
              width: "100%",
              maxHeight: maxHeight,
            }}
          >
            <div style={{ paddingBottom: "60%" }} />
            <MediaTag
              index={0}
              style={{
                width: "calc(50% - 2px)",
                borderRadius: "12px 0px 0px 12px",
              }}
            />
            <div
              style={{ width: "4px", height: "100%", background: "white" }}
            />
            <MediaTag
              index={1}
              style={{
                width: "calc(50% - 2px)",
                borderRadius: "0px 12px 12px 0px",
              }}
            />
          </div>
        );
      case 3:
        return (
          <div
            style={{
              display: "flex",
              overflow: "hidden",
              borderRadius: "12px",
              width: "100%",
              maxHeight: maxHeight,
            }}
          >
            <div style={{ paddingBottom: "60%" }} />
            <div style={{ flex: 1 }}>
              <MediaTag
                index={0}
                style={{
                  width: "100%",
                  height: "calc(50% - 2px)",
                  borderRadius: "12px 0px 0px 0px",
                }}
              />
              <div
                style={{ height: "4px", width: "100%", background: "white" }}
              />
              <MediaTag
                index={1}
                style={{
                  width: "100%",
                  height: "calc(50% - 2px)",
                  borderRadius: "0px 0px 0px 12px",
                }}
              />
            </div>
            <div
              style={{ width: "4px", height: "100%", background: "white" }}
            />
            <MediaTag
              index={2}
              style={{
                width: "calc(50% - 2px)",
                borderRadius: "0px 12px 12px 0px",
              }}
            />
          </div>
        );
      case 4:
        return (
          <div
            style={{
              display: "flex",
              overflow: "hidden",
              borderRadius: "12px",
              width: "100%",
              maxHeight: maxHeight,
            }}
          >
            <div style={{ paddingBottom: "60%" }} />
            <div style={{ flex: 1 }}>
              <MediaTag
                index={0}
                style={{
                  width: "100%",
                  height: "calc(50% - 2px)",
                  borderRadius: "12px 0px 0px 0px",
                }}
              />
              <div
                style={{ height: "4px", width: "100%", background: "white" }}
              />
              <MediaTag
                index={1}
                style={{
                  width: "100%",
                  height: "calc(50% - 2px)",
                  borderRadius: "0px 0px 0px 12px",
                }}
              />
            </div>
            <div
              style={{ width: "4px", height: "100%", background: "white" }}
            />
            <div style={{ flex: 1 }}>
              <MediaTag
                index={2}
                style={{
                  width: "100%",
                  height: "calc(50% - 2px)",
                  borderRadius: "0px 12px 0px 0px",
                }}
              />
              <div
                style={{ height: "4px", width: "100%", background: "white" }}
              />
              <MediaTag
                index={3}
                style={{
                  width: "100%",
                  height: "calc(50% - 2px)",
                  borderRadius: "0px 0px 12px 0px",
                }}
              />
            </div>
          </div>
        );
      default:
        return (
          <div
            style={{
              display: "flex",
              overflow: "hidden",
              borderRadius: "12px",
              width: "100%",
              maxHeight: maxHeight,
            }}
          >
            <div style={{ paddingBottom: "60%" }} />
            <div style={{ flex: 1 }}>
              <MediaTag
                index={0}
                style={{
                  width: "100%",
                  height: "calc(50% - 2px)",
                  borderRadius: "12px 0px 0px 0px",
                }}
              />
              <div
                style={{ height: "4px", width: "100%", background: "white" }}
              />
              <MediaTag
                index={1}
                style={{
                  width: "100%",
                  height: "calc(50% - 2px)",
                  borderRadius: "0px 0px 0px 12px",
                }}
              />
            </div>
            <div
              style={{ width: "4px", height: "100%", background: "white" }}
            />
            <div style={{ flex: 1 }}>
              <MediaTag
                index={2}
                style={{
                  width: "100%",
                  height: "calc(50% - 2px)",
                  borderRadius: "0px 12px 0px 0px",
                }}
              />
              <div
                style={{ height: "4px", width: "100%", background: "white" }}
              />
              <MediaTag
                index={3}
                style={{
                  width: "100%",
                  height: "calc(50% - 2px)",
                  borderRadius: "0px 0px 12px 0px",
                }}
              />
            </div>
          </div>
        );
    }
  };

  return (
    <div>
      {getGrid()}
      <ReactBnbGallery
        show={isOpen}
        activePhotoIndex={main}
        photos={images}
        onClose={() => setOpen(false)}
        opacity={0.8}
        showThumbnails={false}
      />
    </div>
  );
};

export default AttachmentHandler;
