import Typography from "@mui/material/Typography";
import React from "react";
import { useSelector } from "react-redux";
import MessageViewer from "./message-viewer";
import RR_empty_Chat from '../../assets/icons/chat_empty_RR.png'
import WM_empty_Chat from '../../assets/icons/chat_empty_WM.png'
const useStyles = {
  drawer: {
    padding: "16px 24px",
    width: "360px",
    height: "calc(100vh - 134px)",
    boxSizing: "border-box",
    borderRight: "2px solid rgb(240,240,240)",
  },
  content: {
    flex: 1,
    height: "calc(100vh - 134px)",
    boxSizing: "border-box",
    width: "100%",
  },
};

const Messaging = ({ allowSend = false }) => {
  const selectedChat = useSelector((state) => state.appState.selectedChat);
  const selectedRide = useSelector((state) => state.appState.selectedRide);
  const lastSupportChat = useSelector((state) => state.appState.lastSupportChat);

  const isMessageSelected = selectedChat?.customer || selectedChat?.driver || (selectedRide?.cutomer_id || selectedRide?.driver_info?.fb_id || selectedRide?.driver_info?.fb_uid) || lastSupportChat?.customer || lastSupportChat?.driver ? true : false;
  console.log(isMessageSelected)
  return (
    <div
      style={{
        display: "flex",
        background: "white",
        overflow: "hidden",
        marginTop: "0px",
      }}
    >
      <div style={useStyles.content}>
        {isMessageSelected ? (
          <MessageViewer allowSend={allowSend} />
        ) : (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              marginTop: "20%",
            }}
          >
            <img src={process.env.REACT_APP_ENV === 'women_first' ? WM_empty_Chat : RR_empty_Chat} />

            <Typography sx={{mt:3}} variant="body1" bold>
              You don’t have a message selected
            </Typography>
            <Typography
              variant="body2"
              regular
              style={{
                color: "grey",
                maxWidth: "350px",
                textAlign: "center",
                marginTop: "8px",
              }}
            >
              All messages are private conversations between Admins &
              Drivers, Customers on {process.env.REACT_APP_ENV === 'women_first' ? 'women-s-first' : "RideWyze"}.
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};

export default Messaging;