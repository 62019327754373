const distanceUnit = (distanceInMeters, ShowUnit = true) => {
    const env = process.env.REACT_APP_ENV;
    
    let distance;
    let unit;

    if (env === "production" || env === "development") {
        // Convert meters to miles
        distance = distanceInMeters * 1000;
        unit = "km";
        // distance = distanceInMeters * 1609.34;
        // unit = "mi";
    } else if (env === "women_first") {
        // Convert meters to kilometers
        distance = distanceInMeters * 1000;
        unit = "km";
    } else {
        throw new Error(`Unknown environment: ${env}`);
    }

    return `${distance.toFixed(2)}${ShowUnit?unit:''}`;
}

export default distanceUnit;
