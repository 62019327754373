import {
    Box,
    Typography,
    Grid,
    Button
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useDispatch } from 'react-redux';
import { closeModal } from '../../redux/features/modalSlice';
import colorConfigs from "../../configs/colorConfigs";
import { TextInput } from "@zetaton-dev/zetaton-components-bank";
import { useState } from "react";

const ModalContainer = styled(Box)(() => ({
    background: '#FFFFFF',
    padding: "20px",
    borderRadius: "12px",
    width: '600px',
    maxWidth: '100%',
    maxHeight: '94vh',
    overflow: 'auto',
    marginTop: "100px"
}));

const Header = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '3px 10px',
}));


const AddCategory = () => {

    const dispatch = useDispatch();
    const [category, setCategory] = useState("");


    const handleClose = () => {
        setCategory("")
        dispatch(closeModal());
    };

    const fieldStyle = {
        "& label.Mui-focused": {
            color: "#172B4D !important",
            fontSize: "17px !important",
            fontWeight: "500 !important",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#BBC1CE",
                borderWidth: 1,
                borderRadius: "8px",
            },
            "&:hover fieldset": {
                borderColor: "#BBC1CE",
                borderWidth: 1,
                borderRadius: "8px",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#BBC1CE",
                borderWidth: 1,
                borderRadius: "8px",
            },
            input: {
                "&::placeholder": {
                    color: "#3A3541DE",
                    opacity: 1,
                    fontSize: 14,
                },
            },
        },
    };

    return (
        <ModalContainer>
            <Header>
                <Typography sx={{
                    color: '#172B4D',
                    fontFamily: "Poppins",
                    fontSize: "18px",
                    fontWeight: "700",
                    lineHeight: "27px",
                    marginBottom: "15px"
                }}>
                    Add Category
                </Typography>
            </Header>
            <Grid container spacing={2}>
                {/* category name */}
                <Grid item xs={12}>
                    <TextInput
                        fullWidth
                        variant="outlined"
                        placeholder="Please add category name."
                        value={category}
                        onChange={(e) => { setCategory(e.target.value) }}
                        margin="dense"
                        styles={fieldStyle}
                        label={"Category Name"}
                        name="name"
                        filled="true"
                        focused
                        InputProps={{
                            style: { backgroundColor: 'transparent', borderRadius: '10px', height: 45 },
                        }}
                    />
                </Grid>
                {/* save and Cancel */}
                <Grid item xs={6}>
                    <Box style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "flex-start",
                        width: "100%",
                        padding: "0",
                        gap: "16px",
                        marginTop: "20px"
                    }}>
                        <Button
                            variant="contained"
                            sx={{
                                textTransform: 'none',
                                backgroundColor: 'white',
                                color: colorConfigs?.sidebar?.bg,
                                borderRadius: '10px',
                                width: "100%",
                                padding: '10px 20px',
                                '&:hover': {
                                    backgroundColor: '#E6E9F2',
                                },
                            }}
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>

                        <Button
                            variant="contained"
                            sx={{
                                textTransform: 'none',
                                backgroundColor: process.env.REACT_APP_ENV === 'women_first' ? colorConfigs?.sidebar?.bg : '#005e8b',
                                color: 'white',
                                borderRadius: '10px',
                                width: "100%",
                                padding: '10px 20px',
                                '&:hover': {
                                    backgroundColor: process.env.REACT_APP_ENV === 'women_first' ? colorConfigs?.sidebar?.bg : '#004b6e',
                                },
                            }}
                        // onClick={handleSave}
                        >
                            Save
                        </Button>
                    </Box>
                </Grid>
            </Grid>

        </ModalContainer>
    );
};

export default AddCategory;
