import { useTheme } from "@emotion/react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  ThemeProvider,
  Tooltip,
  Typography,
  tooltipClasses,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Switch } from "antd";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import colorConfigs from "../../configs/colorConfigs";
import { setRadius, updateForm } from "../../redux/features/appStateSlice";
import "./index.css";
import { FaCircleInfo } from "react-icons/fa6";
import usePrompt from "../Hook/usePrompt";
import { auth } from "../../configs/firebase";
import { postMethod } from "../Hook/UseCustomApi";
import Error5xx from "../../components/common/HandelError5xx";

const useStyles = makeStyles({
  rootBox: {
    fontFamily: "inter",
    fontSize: "small",
    marginTop: "10px",
    borderRadius: "8px",
    padding: "6px",
    width: "100%",
    marginBottom: "20px",
    color: colorConfigs?.sidebar?.bg,
    backgroundColor: "#F4F4F4",
  },
  flexContainer: {
    display: "flex",
    gap: "20px",
    fontSize: "16px",
    color: "var(--Dark, #172b4d)",
    fontWeight: 600,
    lineHeight: "140%",
    flexDirection: "row",
  },
  categoryBox: {
    borderRadius: "8px",
    backgroundColor: process.env.REACT_APP_ENV === 'women_first' ? '#F9F6FF' : "#F1FAFF",
    display: "flex",
    flexDirection: "row",
    padding: "12px",
    gap: "10px",
    marginTop: "10px",
  },
  buttonStyle: {
    backgroundColor: colorConfigs?.sidebar?.bg,
  },
  checkbox: {
    color: colorConfigs?.sidebar.bg,
    "&.Mui-checked": {
      color: colorConfigs?.sidebar.bg,
    },
  },
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  radioBox: {
    border: "1px solid lightgray",
    borderRadius: "8px",
    paddingLeft: "10px",
    marginTop: "10px",
    padding: "2px",
  },
  smallLabel: {
    fontSize: "0.875rem", // Adjust the font size as needed
  },
  customRadio: {
    color: colorConfigs.sidebar.bg,
    "&.Mui-checked": {
      color: colorConfigs.sidebar.bg,
    },
  },
});

const SmallFormControlLabel = (props) => {
  const classes = useStyles();

  return (
    <FormControlLabel
      {...props}
      control={
        <Radio
          {...props.control.props}
          sx={{
            color: colorConfigs.sidebar.bg,
            "&.Mui-checked": {
              color: colorConfigs.sidebar.bg,
            },
          }}
        />
      }
      label={
        <Typography className={classes.smallLabel}>{props.label}</Typography>
      }
    />
  );
};

const DriverStep = ({ prev, next }) => {
  const classes = useStyles();
  const [isFormDirty, setIsFormDirty] = useState(true);
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.appState.formData);
  const theme = useTheme();
  const [formErrors, setErrors] = useState({});
  const [additionalFees, setAdditionalFees] = useState(formData.additional_fees || []);
  const [loading, setLoading] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [showCustomerDetails, setShowCustomerDetails] = useState(formData.show_customer_details || true);
  const [showDropOffPoint, setShowDropOffPoint] = useState(formData.show_dropOff_point || true);
  const [
    acceptRequestBeforeFinishingCurrentRide,
    setAcceptRequestBeforeFinishingCurrentRide,
  ] = useState(formData.accept_request_before_finishing_current_ride || false);
  const [requestTime, setRequestTime] = useState(formData.accept_request_before_finishing_current_ride_time_per_min || 0);
  const navigate = useNavigate();

  useEffect(() => {
    setShowCustomerDetails(formData.show_customer_details);
    setShowDropOffPoint(formData.show_dropOff_point);
    setAcceptRequestBeforeFinishingCurrentRide(formData.accept_request_before_finishing_current_ride);
    setRequestTime(formData.accept_request_before_finishing_current_ride_time_per_min);
    setAdditionalFees(formData.additional_fees || []);
  }, [formData]);

  const handleAddFee = (values, resetForm) => {
    setErrors({});
    let error = {};
    if (!values.addName) {
      error.addName = "Name is required.";
    } else if (values?.addvalue <= 0) {
      error.addvalue = "Value should be 1 or more.";
    }

    if (Object.keys(error).length > 0) {
      setErrors(error);
      console.warn(Object.keys(error));
    } else {
      const newAdditionalFee = {
        name: values.addName,
        type: values.addType,
        value: values.addvalue,
      };
      if (editIndex !== null) {
        const updatedFees = [...additionalFees];
        updatedFees[editIndex] = newAdditionalFee;
        dispatch(updateForm({
          ...formData,
          additional_fees: updatedFees,
        }))
        setAdditionalFees(updatedFees);
        setEditIndex(null);
      } else {
        setAdditionalFees([...additionalFees, newAdditionalFee]);
        dispatch(updateForm({
          ...formData,
          additional_fees: [...additionalFees, newAdditionalFee],
        }))
      }
      resetForm();
      setOpenDialog(false);
    }
  };

  //handle error5xx
  const [tryAgain, setTryAgain] = useState();
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [errorCount, setErrorCount] = useState(0);
  const [errorDialogCounter, setErrorDialogCounter] = useState(0);
  const handleError5xx = () => {
    setIsErrorDialogOpen(true);
    setErrorCount(prevCount => {
      const newCount = prevCount + 1;
      if (newCount === 1) {
        setErrorDialogCounter(5);
      } else if (newCount === 2) {
        setErrorDialogCounter(10);
      }
      return newCount;
    });
  };
  const handleCloseErrorDialog = () => {
    setIsErrorDialogOpen(false);
    if (errorCount <= 2) {
      handleErrorCountByClose()
    }

  };
  const handleErrorCountByClose = () => {
    setErrorCount(prevCount => {
      const newCount = prevCount - 1;
      if (newCount === 1) {
        setErrorDialogCounter(5);
      } else if (newCount === 2) {
        setErrorDialogCounter(10);
      }
      return newCount;
    });
  }

  const handleDeleteFee = (index) => {
    const updatedFees = additionalFees.filter((_, i) => i !== index);
    setAdditionalFees(updatedFees);
    dispatch(updateForm({
      ...formData,
      additional_fees: updatedFees,
      show_customer_details: showCustomerDetails,
      show_dropOff_point: showDropOffPoint,
    }))
  };

  const handleEditFee = (index, setFieldValue) => {
    const fee = additionalFees[index];
    setFieldValue("addName", fee.name);
    setFieldValue("addType", fee.type);
    setFieldValue("addvalue", fee.value);
    setEditIndex(index);
    setOpenDialog(true);
  };
  const savetempDataFromThisStep = async () => {
    if (auth && auth.currentUser) {
      const tokenId = await auth?.currentUser
        ?.getIdToken()
        .then((token) => token);
      const headers = {
        "Content-Type": "application/json",
        Authorization: tokenId,
      };
      dispatch(
        updateForm({
          ...formData
        })
      );
      setLoading(true);
      try {
        // Make the API request to add a new admin

        postMethod({
          url: `zone`,
          body: {
            ...formData,
          },
          headers: headers,
          // handleErrorMessage: showSnackbar,
        }).then((res) => {
          setLoading(false);
          navigate("/rates", { replace: true });
          console.log(res, "res");
          dispatch(updateForm({}));
          dispatch(setRadius(0));
        });

        // Fetch the updated list of admins
      } catch (error) {
        console.error("Error adding new zone:", error);
        setLoading(false);
        if (error === "Internal Server Error: Server error occurred.") {
          handleError5xx();
          setTryAgain(() => savetempDataFromThisStep);
        }
      }
    }

  }

  const handleSubmit = async (values) => {
    let errors = {};

    if (
      (values.driver_cancellation_fees.waiting_time > 0 &&
        values.driver_cancellation_fees.fees <= 0) ||
      (values.driver_cancellation_fees.fees > 0 &&
        values.driver_cancellation_fees.waiting_time <= 0)
    ) {
      errors.driver_cancellation_fees =
        "Both waiting time and fees are required for driver cancellation fees.";
    }

    if (
      (values.customer_cancellation_fees.waiting_time > 0 &&
        values.customer_cancellation_fees.fees <= 0) ||
      (values.customer_cancellation_fees.fees > 0 &&
        values.customer_cancellation_fees.waiting_time <= 0)
    ) {
      errors.customer_cancellation_fees =
        "Both waiting time and fees are required for customer cancellation fees.";
    }

    if (acceptRequestBeforeFinishingCurrentRide && requestTime <= 0) {
      errors.requestTime = "Time before active order completes is required.";
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    } else {
      dispatch(
        updateForm({
          ...formData,
          additional_fees: additionalFees,
          driver_cancellation_fees: values.driver_cancellation_fees,
          customer_cancellation_fees: values.customer_cancellation_fees,
          show_customer_details: showCustomerDetails,
          show_dropOff_point: showDropOffPoint,
          accept_request_before_finishing_current_ride:
            acceptRequestBeforeFinishingCurrentRide,
          accept_request_before_finishing_current_ride_time_per_min:
            requestTime,
        })
      );

      // next();
      setIsFormDirty(false)
      savetempDataFromThisStep()
    }
  };

  const fieldStyle = {
    "& label.Mui-focused": {
      color: "#172B4D !important",
      fontSize: "16px !important",
      fontWeight: "500 !important",
    },
    marginTop: "10px",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#BBC1CE",
        borderWidth: 1,
        borderRadius: "8px",
      },
      "&:hover fieldset": {
        borderColor: "#BBC1CE",
        borderWidth: 1,
        borderRadius: "8px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#BBC1CE",
        borderWidth: 1,
        borderRadius: "8px",
      },
      input: {
        "&::placeholder": {
          color: "#3A3541DE",
          opacity: 1,
          fontSize: 14,
        },
        padding: "12.5px 14px",
      },
    },
  };


  return (
    <>
      {/* handleError5xx */}
      <Error5xx open={isErrorDialogOpen} errCount={errorCount} count={errorDialogCounter} handleClose={handleCloseErrorDialog} tryAgain={tryAgain} />
      <Box className={classes.rootBox}>
        <Typography variant="body1" style={{ fontWeight: "bold" }}>
          Additional fees
        </Typography>
      </Box>
      <Formik
        initialValues={{
          addName: formData?.additional_fees?.name || "",
          addvalue: formData?.additional_fees?.value || 0,
          addType: formData?.additional_fees?.type || "per_mile",
          driver_cancellation_fees: {
            waiting_time: formData?.driver_cancellation_fees?.waiting_time || 0,
            fees: formData?.driver_cancellation_fees?.fees || 0,
          },
          customer_cancellation_fees: {
            waiting_time: formData?.customer_cancellation_fees?.waiting_time || 0,
            fees: formData?.customer_cancellation_fees?.fees || 0,
          },
          show_customer_details: formData.show_customer_details || true,
          show_dropOff_point: formData.show_dropOff_point || true,
          accept_request_before_finishing_current_ride: formData.accept_request_before_finishing_current_ride || false,
          accept_request_before_finishing_current_ride_time_per_min: formData.accept_request_before_finishing_current_ride_time_per_min || 3,
        }}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({
          handleBlur,
          handleChange,
          values,
          errors,
          handleSubmit,
          touched,
          setFieldValue,
          resetForm,
        }) => (
          <ThemeProvider theme={theme}>
            <Dialog
              open={openDialog}
              onClose={() => {
                resetForm();
                setEditIndex(null);
                setOpenDialog(false);
              }}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">
                Add/Edit Additional Fee
              </DialogTitle>
              <DialogContent>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      label={"Name"}
                      name="addName"
                      onChange={(event) => {
                        setIsFormDirty(true)
                        handleChange("addName")(event);
                      }}
                      onWheel={(e) => e.target.blur()}
                      onBlur={handleBlur("addName")}
                      className={classes.input}
                      sx={fieldStyle}
                      required
                      type="text"
                      value={values?.addName}
                      variant="outlined"
                      placeholder="Enter name"
                      filled="true"
                      focused
                      error={formErrors.addName?.length > 0 ? true : false}
                    />
                    {formErrors.addName?.length > 0 && (
                      <Typography variant="caption" style={{ color: "red" }}>
                        {formErrors.addName}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Box className={classes.radioBox}>
                      <FormControl component="fieldset">
                        <RadioGroup
                          row
                          name="addType"
                          value={values.addType}
                          onChange={(event) => {
                            setIsFormDirty(true)
                            setFieldValue("addType", event.target.value);
                          }}
                        >
                          <SmallFormControlLabel
                            value="per_mile"
                            control={<Radio />}
                            label="Cost Per KM"
                          />
                          <SmallFormControlLabel
                            value="per_minute"
                            control={<Radio />}
                            label="Per Minute"
                          />
                          <SmallFormControlLabel
                            value="final_cost"
                            control={<Radio />}
                            label="Added to final cost"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      label={"Value"}
                      name="addvalue"
                      onChange={(event) => {
                        setIsFormDirty(true)
                        handleChange("addvalue")(event);
                      }}
                      onWheel={(e) => e.target.blur()}
                      onBlur={handleBlur("addvalue")}
                      className={classes.input}
                      sx={fieldStyle}
                      inputProps={{ pattern: "[0-9]*" }}
                      required
                      type="number"
                      value={values?.addvalue}
                      variant="outlined"
                      placeholder="Enter average value"
                      filled="true"
                      focused
                    />
                    {formErrors.addvalue?.length > 0 && (
                      <Typography variant="caption" style={{ color: "red" }}>
                        {formErrors.addvalue}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    resetForm();
                    setEditIndex(null);
                    setOpenDialog(false);
                  }}
                  variant="outlined"
                  style={{
                    marginRight: 8,
                    color: colorConfigs?.sidebar?.bg,
                    borderColor: colorConfigs?.sidebar?.bg,
                    backgroundColor: "white",
                  }}
                  color="primary"
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => handleAddFee(values, resetForm)}
                  color="primary"
                  variant="contained"
                  style={{
                    backgroundColor: colorConfigs?.sidebar?.bg,
                    color: "white",
                  }}
                >
                  {editIndex !== null ? "Update Fee" : "Add Fee"}
                </Button>
              </DialogActions>
            </Dialog>

            {additionalFees.length > 0 && (
              <Box mt={2} style={{ display: "flex" }}>
                <Grid container spacing={2}>
                  {additionalFees.map((fee, index) => (
                    <Grid item xs={12} sm={6} md={3} key={index}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        backgroundColor={process.env.REACT_APP_ENV === 'women_first' ? '#F9F6FF' : "#F1FAFF"}
                        style={{ border: `1px solid ${colorConfigs?.sidebar?.bg}` }}
                        borderRadius="8px"
                        
                      >
                        <Typography
                          style={{
                            paddingLeft: "8px",
                            fontWeight: "bold",
                            color: colorConfigs?.sidebar?.bg,
                          }}
                        >
                          {fee.name}
                        </Typography>

                        <Box>
                          <IconButton
                            onClick={() => handleEditFee(index, setFieldValue)}
                            color="primary"
                          >
                            <EditIcon
                              style={{ color: "#00C371", cursor: "pointer" }}
                            />
                          </IconButton>
                          <IconButton
                            onClick={() => handleDeleteFee(index)}
                            color="secondary"
                          >
                            <DeleteIcon
                              style={{ color: "red", cursor: "pointer" }}
                            />
                          </IconButton>
                        </Box>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}
            {additionalFees.length === 0 && (
              <div>
                <Typography
                  variant="body1"
                  style={{ fontWeight: "bold", paddingLeft: "2px" }}
                >
                  <div
                    style={{
                      height: "5px",
                      width: "5px",
                      backgroundColor: "black",
                      borderRadius: "50%",
                      display: "inline-block",
                      marginRight: "6px",
                    }}
                  />
                  No additional fees
                </Typography>
                <Typography variant="body2" style={{ paddingLeft: "2px" }}>
                  The ride types this plan applies to or any additional fees.
                </Typography>
              </div>
            )}
            <Button
              variant="outlined"
              onClick={() => setOpenDialog(true)}
              style={{
                marginRight: 8,
                color: colorConfigs?.sidebar?.bg,
                borderColor: colorConfigs?.sidebar?.bg,
                backgroundColor: "white",
                marginTop: "10px",
              }}
            >
              Add Additional Fees
            </Button>
            {/* cancel fee */}
            {/* <div>
              <Box className={classes.rootBox}>
                <Typography variant="body1" style={{ fontWeight: "bold" }}>
                  Cancellation Fees
                </Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <Typography
                    variant="body1"
                    style={{
                      fontWeight: "bold",
                      paddingLeft: "2px",
                      color: colorConfigs?.sidebar?.bg,
                    }}
                  >
                    <div
                      style={{
                        height: "5px",
                        width: "5px",
                        backgroundColor: colorConfigs?.sidebar?.bg,
                        borderRadius: "50%",
                        display: "inline-block",
                        marginRight: "6px",
                      }}
                    />
                    Driver Cancellation Fees:
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">{"Min"}</InputAdornment>
                          ),
                        }}
                        label={
                          <Box component={'span'} sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center', gap: 1 }}>
                            <Tooltip arrow
                              slotProps={{
                                popper: {
                                  sx: {

                                    [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
                                    {
                                      marginBottom: '8px',
                                    },

                                  },
                                },
                              }}
                              classes={{ tooltip: classes.customTooltip }}
                              title={
                                <Typography sx={{
                                  fontSize: 12,
                                }} >
                                  Waiting time ...............
                                </Typography>
                              } placement="top-start">{' '}
                              <FaCircleInfo size={20} color='#88939D' />
                            </Tooltip>
                            Waiting time *
                          </Box>
                        }
                        name="driver_cancellation_fees.waiting_time"
                        onChange={(event) => {
                          setIsFormDirty(true)
                          handleChange("driver_cancellation_fees.waiting_time")(event);
                        }}
                        onWheel={(e) => e.target.blur()}
                        onBlur={handleBlur}
                        className={classes.input}
                        sx={fieldStyle}

                        type="number"
                        value={values.driver_cancellation_fees.waiting_time}
                        variant="outlined"
                        placeholder="Enter waiting time"
                        filled="true"
                        focused
                      />
                      {errors.driver_cancellation_fees?.waiting_time && (
                        <Typography variant="caption" style={{ color: "red" }}>
                          {errors.driver_cancellation_fees.waiting_time}
                        </Typography>
                      )}
                      {formErrors.driver_cancellation_fees && (
                        <Typography variant="caption" style={{ color: "red" }}>
                          {formErrors.driver_cancellation_fees}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">{"$"}</InputAdornment>
                          ),
                        }}
                        label={
                          <Box component={'span'} sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center', gap: 1 }}>
                            <Tooltip arrow
                              slotProps={{
                                popper: {
                                  sx: {

                                    [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
                                    {
                                      marginBottom: '8px',
                                    },

                                  },
                                },
                              }}
                              classes={{ tooltip: classes.customTooltip }}
                              title={
                                <Typography sx={{
                                  fontSize: 12,
                                }} >
                                  Fees ...............
                                </Typography>
                              } placement="top-start">{' '}
                              <FaCircleInfo size={20} color='#88939D' />
                            </Tooltip>
                            Fees *
                          </Box>
                        }
                        name="driver_cancellation_fees.fees"
                        onChange={(event) => {
                          setIsFormDirty(true)
                          handleChange("driver_cancellation_fees.fees")(event);
                        }}
                        onWheel={(e) => e.target.blur()}
                        onBlur={handleBlur}
                        className={classes.input}
                        sx={fieldStyle}
                        inputProps={{ pattern: "[0-9]*" }}

                        type="number"
                        value={values.driver_cancellation_fees.fees}
                        variant="outlined"
                        placeholder="Enter fees"
                        filled="true"
                        focused
                      />
                      {errors.driver_cancellation_fees?.fees && (
                        <Typography variant="caption" style={{ color: "red" }}>
                          {errors.driver_cancellation_fees.fees}
                        </Typography>
                      )}
                      {formErrors.driver_cancellation_fees && (
                        <Typography variant="caption" style={{ color: "red" }}>
                          {formErrors.driver_cancellation_fees}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <Typography
                    variant="body1"
                    style={{
                      fontWeight: "bold",
                      paddingLeft: "2px",
                      color: colorConfigs?.sidebar?.bg,
                    }}
                  >
                    <div
                      style={{
                        height: "5px",
                        width: "5px",
                        backgroundColor: colorConfigs?.sidebar?.bg,
                        borderRadius: "50%",
                        display: "inline-block",
                        marginRight: "6px",
                      }}
                    />
                    Passenger Cancellation Fees:
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">{"Min"}</InputAdornment>
                          ),
                        }}
                        label={
                          <Box component={'span'} sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center', gap: 1 }}>
                            <Tooltip arrow
                              slotProps={{
                                popper: {
                                  sx: {

                                    [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
                                    {
                                      marginBottom: '8px',
                                    },

                                  },
                                },
                              }}
                              classes={{ tooltip: classes.customTooltip }}
                              title={
                                <Typography sx={{
                                  fontSize: 12,
                                }} >
                                  Waiting time ...............
                                </Typography>
                              } placement="top-start">{' '}
                              <FaCircleInfo size={20} color='#88939D' />
                            </Tooltip>
                            Waiting time *
                          </Box>
                        }
                        name="customer_cancellation_fees.waiting_time"
                        onChange={(event) => {
                          setIsFormDirty(true)
                          handleChange("customer_cancellation_fees.waiting_time")(event);
                        }}
                        onWheel={(e) => e.target.blur()}
                        onBlur={handleBlur}
                        className={classes.input}
                        sx={fieldStyle}

                        type="number"
                        value={values.customer_cancellation_fees.waiting_time}
                        variant="outlined"
                        placeholder="Enter waiting time"
                        filled="true"
                        focused
                      />
                      {errors.customer_cancellation_fees?.waiting_time && (
                        <Typography variant="caption" style={{ color: "red" }}>
                          {errors.customer_cancellation_fees.waiting_time}
                        </Typography>
                      )}
                      {formErrors.customer_cancellation_fees && (
                        <Typography variant="caption" style={{ color: "red" }}>
                          {formErrors.customer_cancellation_fees}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">{"$"}</InputAdornment>
                          ),
                        }}
                        label={<Box component={'span'} sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center', gap: 1 }}>
                          <Tooltip arrow
                            slotProps={{
                              popper: {
                                sx: {

                                  [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
                                  {
                                    marginBottom: '8px',
                                  },

                                },
                              },
                            }}
                            classes={{ tooltip: classes.customTooltip }}
                            title={
                              <Typography sx={{
                                fontSize: 12,
                              }} >
                                Fees ...............
                              </Typography>
                            } placement="top-start">{' '}
                            <FaCircleInfo size={20} color='#88939D' />
                          </Tooltip>
                          Fees *
                        </Box>}
                        name="customer_cancellation_fees.fees"
                        onChange={(event) => {
                          setIsFormDirty(true)
                          handleChange("customer_cancellation_fees.fees")(event);
                        }}
                        onWheel={(e) => e.target.blur()}
                        onBlur={handleBlur}
                        className={classes.input}
                        sx={fieldStyle}
                        inputProps={{ pattern: "[0-9]*" }}

                        type="number"
                        value={values.customer_cancellation_fees.fees}
                        variant="outlined"
                        placeholder="Enter fees"
                        filled="true"
                        focused
                      />
                      {errors.customer_cancellation_fees?.fees && (
                        <Typography variant="caption" style={{ color: "red" }}>
                          {errors.customer_cancellation_fees.fees}
                        </Typography>
                      )}
                      {formErrors.customer_cancellation_fees && (
                        <Typography variant="caption" style={{ color: "red" }}>
                          {formErrors.customer_cancellation_fees}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div> */}

            <Box className={classes.rootBox}>
              <Typography variant="body1" style={{ fontWeight: "bold" }}>
                Back to back ride for drivers
              </Typography>
            </Box>

            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <Box className={classes.categoryBox}>
                  <div style={{ display: "flex" }}>
                    <Switch
                      checked={showCustomerDetails}
                      onChange={() => {
                        const updatedValue = !showCustomerDetails;
                        setShowCustomerDetails(updatedValue);
                        dispatch(updateForm({
                          ...formData,
                          show_customer_details: updatedValue,
                        }));
                      }}
                      className="custom-switch"
                    />
                    <span style={{ marginLeft: 8 }}>
                      Show Passenger Details Before Order Starts
                    </span>
                  </div>
                </Box>
              </Grid>
              <Grid item md={6} xs={12}>
                <Box className={classes.categoryBox}>
                  <div style={{ display: "flex" }}>
                    <Switch
                      checked={showDropOffPoint}
                      onChange={() => {
                        const updatedValue = !showDropOffPoint;
                        setShowDropOffPoint(updatedValue);
                        dispatch(updateForm({
                          ...formData,
                          show_dropOff_point: updatedValue,
                        }));
                      }}
                      className="custom-switch"
                    />
                    <span style={{ marginLeft: 8 }}>
                      Show Drop-off Before Ride Starts
                    </span>
                  </div>
                </Box>
              </Grid>
              {/* <Grid item md={7} xs={12}>
                <Box className={classes.categoryBox}>
                  <div style={{ display: "flex" }}>
                    <Switch
                      checked={acceptRequestBeforeFinishingCurrentRide}
                      onChange={() => {
                        setIsFormDirty(true)
                        setAcceptRequestBeforeFinishingCurrentRide(!acceptRequestBeforeFinishingCurrentRide)
                      }
                      }
                      className="custom-switch"
                    />
                    <span style={{ marginLeft: 8 }}>
                      Allow drivers to accept ride requests while finishing a
                      current trip
                    </span>
                  </div>
                </Box>
              </Grid>
              {acceptRequestBeforeFinishingCurrentRide && (
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label={"Time before active order completes "}
                    name="requestTime"
                    onChange={(event) => {
                      setIsFormDirty(true)
                      setRequestTime(event.target.value)
                    }
                    }
                    onWheel={(e) => e.target.blur()}
                    onBlur={handleBlur}
                    className={classes.input}
                    sx={fieldStyle}
                    required
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">{"Min"}</InputAdornment>
                      ),
                    }}
                    type="number"
                    value={requestTime}
                    variant="outlined"
                    placeholder="Enter time in minutes"
                    filled="true"
                    focused
                  />
                  {formErrors.requestTime && (
                    <Typography variant="caption" style={{ color: "red" }}>
                      {formErrors.requestTime}
                    </Typography>
                  )}
                </Grid>
              )} */}
            </Grid>

            <div style={{ marginTop: 50, gap: 8 }}>
              <Button
                onClick={prev}
                variant="outlined"
                style={{
                  marginRight: 8,
                  color: colorConfigs?.sidebar?.bg,
                  borderColor: colorConfigs?.sidebar?.bg,
                  backgroundColor: "white",
                  width: "12%",
                }}
              >
                Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                loading={loading}
                style={{
                  backgroundColor: colorConfigs?.sidebar?.bg,
                  color: "white",
                  width: "12%",
                }}
              >
                {loading ? "Loading..." : "Save"}
              </Button>
              {/* <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                loading={loading}
                style={{
                  backgroundColor: colorConfigs?.sidebar?.bg,
                  color: "white",
                  width: "12%",
                }}
              >
                Next
              </Button> */}
            </div>
          </ThemeProvider>
        )}
      </Formik>
    </>
  );
};

export default DriverStep;
