import React, { useState } from 'react';
import {
    Box,
    Grid,
    TextField,
    Typography,
    Snackbar,
    Alert,
    CircularProgress
} from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import 'yup-phone-lite';
import AddressAutoComplete from '../../../components/common/AddressAutoComplete';
import BlueButton from '../../../components/common/Buttons/BlueButton';
import colorConfigs from '../../../configs/colorConfigs';
import { useNavigate } from 'react-router-dom';
import { postMethod } from '../../Hook/UseCustomApi';
import { postDataRedux } from '../../../redux/features/appStateSlice';

const inputStyle = {
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#BBC1CE",
            borderWidth: 1,
            borderRadius: "8px",
        },
        "&:hover fieldset": {
            borderColor: "#BBC1CE",
            borderWidth: 1,
            borderRadius: "8px",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#BBC1CE",
            borderWidth: 1,
            borderRadius: "8px",
        },
        input: {
            "&::placeholder": {
                color: "#BBC1CE",
                opacity: 1,
                fontSize: 14,
            },
            padding: "10px",
        },
    },
    "& label": {
        color: "#7A869A",
        lineHeight: "0.8375em",
    },
    "& label.Mui-focused": {
        color: "#000",
        fontSize: "14px",
        fontWeight: "500",
    },
};

const validationSchema = Yup.object().shape({
    name: Yup.string()
        .matches(/^[A-Za-z\s]+$/, 'Name can only contain letters and spaces')
        .max(20, 'Name cannot exceed 20 characters')
        .required('Name is required'),
    contactPerson: Yup.string().required('Contact Person is required'),
    phoneNumber: Yup.string()
        .required('Phone number is required')
        .test('is-valid-phone', 'Phone number is not valid for the selected region', function (value) {
            const { path, createError } = this;
            const selectedCountry = this.parent.countryCode || 'us';

            try {
                const phoneNumber = parsePhoneNumberFromString(value, selectedCountry.toUpperCase());
                if (!phoneNumber || !phoneNumber.isValid()) {
                    return createError({ path, message: `Phone number must be valid for region ${selectedCountry.toUpperCase()}` });
                }
                return true;
            } catch (error) {
                return createError({ path, message: `Phone number must be valid for region ${selectedCountry.toUpperCase()}` });
            }
        }),
    email: Yup.string().email('Please enter a valid email').required('Email is required'),
    postalCode: Yup.string(),
});

function AddCorporate({ setShowAddForm }) {
    const [address, setAddress] = useState({});
    const [addressToShowAddress, setAddressToShowAddress] = useState("");
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({ open: false, severity: 'success', message: '' });
    const [error, setError] = useState(''); // State to manage error messages
    const adminData = useSelector((state) => state.appState.userData);
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            name: '',
            contactPerson: '',
            phoneNumber: '',
            email: '',
            postalCode: '',
            countryCode: 'us',
            phoneNumberOnly: '',
            countryCallingCode: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setLoading(true);
            setError(''); // Clear any previous error
            const parsedPhoneNumber = parsePhoneNumberFromString(values.phoneNumber, values.countryCode.toUpperCase());
            const phoneNumber = parsedPhoneNumber ? parsedPhoneNumber.nationalNumber : '';
            const countryCallingCode = parsedPhoneNumber ? `+${parsedPhoneNumber.countryCallingCode}` : '';

            const submissionData = {
                created_by: adminData?._id,
                corporate_name: values?.name,
                contact_person: values?.contactPerson,
                phone_number: phoneNumber,
                code_phone_number: countryCallingCode,
                email: values?.email,
                address
            };

            postMethod({
                url: `admin/corporate`,
                body: submissionData,
            })
                .then((response) => {
                    setLoading(false);
                    if (response) {
                        setAlert({ open: true, severity: 'success', message: 'Corporate added successfully!' });
                        formik.resetForm(); // Clear form fields
                        setAddressToShowAddress(''); // Clear address field
                        setAddress({}); // Clear address object
                        setShowAddForm(false)
                        dispatch(postDataRedux({ stateName: 'corporatesList', data: response.data, push: true }))
                    } else {
                        setError('Adding failed.');
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    if (error === 'Conflict: Conflict in action or duplicate request.') {
                        setError('This corporate already exist');
                    } else {
                        setError('Adding failed.');
                    }
                });
        },
    });

    const handlePhoneChange = (value, countryData) => {
        formik.setFieldValue('phoneNumber', value);
        formik.setFieldValue('countryCode', countryData.countryCode.toLowerCase());

        const parsedPhoneNumber = parsePhoneNumberFromString(value, countryData.countryCode.toUpperCase());
        if (parsedPhoneNumber) {
            formik.setFieldValue('phoneNumberOnly', parsedPhoneNumber.nationalNumber);
            formik.setFieldValue('countryCallingCode', `+${parsedPhoneNumber.countryCallingCode}`);
        } else {
            formik.setFieldValue('phoneNumberOnly', '');
            formik.setFieldValue('countryCallingCode', '');
        }
    };

    const handleSelectzipcode = async (address, placeId, suggestion) => {
        try {
            const results = await geocodeByAddress(address);
            const latLng = await getLatLng(results[0]);

            const selectedPlaceData = {
                city:
                    results[0]?.address_components.find((component) =>
                        component.types.includes("locality")
                    )?.long_name || "",
                state:
                    results[0]?.address_components.find((component) =>
                        component.types.includes("administrative_area_level_1")
                    )?.short_name || "",
                country:
                    results[0]?.address_components.find((component) =>
                        component.types.includes("country")
                    )?.short_name || "",
                zip_code:
                    results[0]?.address_components.find((component) =>
                        component.types.includes("postal_code")
                    )?.long_name || "",
                lat: latLng.lat,
                lng: latLng.lng,
                route:
                    results[0]?.address_components.find((component) =>
                        component.types.includes("route")
                    )?.long_name || "",
                street_number:
                    results[0]?.address_components.find((component) =>
                        component.types.includes("street_number")
                    )?.long_name || "",
                primary_line: results[0]?.formatted_address || "",
                placeId: placeId || "",
                address: address || "",
            };
            setAddress(selectedPlaceData);
            setAddressToShowAddress(selectedPlaceData?.primary_line);
            formik.setFieldValue('postalCode', selectedPlaceData?.zip_code);
        } catch (error) {
            console.error("Error selecting address", error);
        }
    };

    const handleCloseAlert = () => {
        setAlert({ ...alert, open: false });
    };

    return (
        <Box>
            <Box
                style={{
                    fontFamily: "inter",
                    fontSize: "small",
                    marginTop: "30px",
                    borderRadius: "8px",
                    padding: "10px",
                    width: "100%",
                    marginBottom: "20px",
                    color: colorConfigs?.sidebar?.bg,
                    backgroundColor: "#F4F4F4",
                }}
            >
                <Typography variant="body1" style={{ fontWeight: "bold" }}>
                    Add New Corporate
                </Typography>
            </Box>
            <form onSubmit={formik.handleSubmit}>
                <Grid container columnSpacing={5} rowSpacing={3}>
                    <Grid item xs={6}>
                        <TextField
                            sx={inputStyle}
                            fullWidth
                            label="Name*"
                            placeholder='Please Enter a Name'
                            focused
                            name="name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                            inputProps={{ maxLength: 20 }}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <AddressAutoComplete
                            address={addressToShowAddress}
                            setAddress={setAddressToShowAddress}
                            handleSelect={handleSelectzipcode}
                            types={"postal_code"}
                            lable="Postal code"
                            placeholder={"Enter address"}
                            focused={true}
                        />
                        {formik.touched.postalCode && formik.errors.postalCode && (
                            <Typography color="error" variant="caption">
                                {formik.errors.postalCode}
                            </Typography>
                        )}
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Contact Person*"
                            sx={inputStyle}
                            focused
                            placeholder='Please Enter a Contact Person'
                            name="contactPerson"
                            value={formik.values.contactPerson}
                            onChange={formik.handleChange}
                            error={formik.touched.contactPerson && Boolean(formik.errors.contactPerson)}
                            helperText={formik.touched.contactPerson && formik.errors.contactPerson}
                            inputProps={{ maxLength: 20 }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <PhoneInput
                            id="phone-number-input"
                            autoFormat={true}
                            enableSearch={true}
                            country={formik.values.countryCode}
                            focused
                            specialLabel={'Phone Number*'}
                            value={formik.values.phoneNumber}
                            onChange={handlePhoneChange}
                            inputStyle={{
                                width: '100%',
                                borderColor: '#BBC1CE',
                                borderRadius: '8px',
                                height: '43px',
                            }}
                            buttonStyle={{
                                borderColor: '#BBC1CE',
                                borderRadius: '8px 0 0 8px',
                            }}
                            containerStyle={{
                                width: '100%',
                            }}
                            inputProps={{
                                name: 'phoneNumber',
                                required: true,

                            }}
                            placeholder="Please enter your phone number"
                        />
                        {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                            <Typography color="error" variant="caption">
                                {formik.errors.phoneNumber}
                            </Typography>
                        )}
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            focused
                            fullWidth
                            label="Email*"
                            sx={inputStyle}
                            placeholder='Please Enter an Email'
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                            inputProps={{ maxLength: 50 }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <BlueButton
                            onClick={() => { setShowAddForm(false) }}
                            sx={{
                                bgcolor: '#FFFFFF',
                                border: `1px solid ${colorConfigs?.sidebar?.bg}`,
                                color: colorConfigs?.sidebar?.bg,
                                mr: 3,
                                minWidth: '150px',
                                p: 2.8,
                                borderRadius: 3
                            }}
                            variant="outlined"
                            color="secondary"
                            type="reset"
                            disabled={loading}
                        >
                            Cancel
                        </BlueButton>

                        <BlueButton
                            sx={{ minWidth: '150px', p: 2.8, borderRadius: 3 }}
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={loading}
                        >
                            {loading ? <CircularProgress size={24} /> : 'Save'}
                        </BlueButton>
                    </Grid>

                    {error && (
                        <Grid item xs={12}>
                            <Typography color="error" variant="caption">
                                {error}
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </form>

            <Snackbar
                open={alert.open}
                autoHideDuration={6000}
                onClose={handleCloseAlert}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }} // Positioning the Snackbar at the top right
            >
                <Alert onClose={handleCloseAlert} severity={alert.severity} sx={{ width: '100%' }}>
                    {alert.message}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default AddCorporate;
