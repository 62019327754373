import {
    Box,
    Typography,
    Grid,
} from '@mui/material';
import React from 'react';
import colorConfigs from "../../configs/colorConfigs";
import { useSelector } from "react-redux";
import parse from 'html-react-parser';
import styled from 'styled-components';
// import DOMPurify from 'dompurify';

const CustomTypography = styled(Typography)`
    .ql-size-small {
        font-size: 0.75em;
    }
    .ql-size-large {
        font-size: 1.5em;
    }
    .ql-size-huge {
        font-size: 2em;
    }
`;
const card = {
    width: "100%",
    padding: "20px",
    borderRadius: "8px",
    backgroundColor: "#FAFAFA",
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    marginTop: "15px",
}
const descriptionCard = {
    width: "100%",
    padding: "20px",
    borderRadius: "8px",
    backgroundColor: "#FAFAFA",
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    marginTop: "15px",

}
const container = {
    marginTop: "35px",
}
const titel = {
    fontWeight: "700",
    fontFamily: "Poppins",
    fontSize: "15px",
    lineHeight: "21px",
    color: colorConfigs?.sidebar?.bg
}
const key = {
    display: "flex",
    alignItems: "center",
    fontWeight: "400",
    fontFamily: "Poppins",
    fontSize: "14px",
    lineHeight: "17px",
    color: "#7A869A",
    marginTop: "10px",
}
const value = {
    display: "flex",
    alignItems: "center",
    justifyContent: 'start',
    paddingLeft: "10px",
    fontWeight: "600",
    fontFamily: "Poppins",
    fontSize: "14px",
    lineHeight: "18px",
    color: "#495974",
}
const actionValue = {
    display: "flex",
    alignItems: "center",
    justifyContent: 'start',
    backgroundColor: '#EBEBEB',
    color: '#495974',
    padding: '8px 16px',
    borderRadius: '20px',
    fontWeight: "600",
    fontFamily: "Poppins",
    fontSize: "14px",
    lineHeight: "15px",
    marginLeft: "5px"
}

const HelpCenterDetails = () => {

    const details = useSelector((state) => state.appState.details);
    // const sanitizedHtml = DOMPurify.sanitize(details?.description_html || '');
    console.log(details);

    const capitalizeFirstLetter = (str) => {
        return str?.charAt(0)?.toUpperCase() + str?.slice(1);
    };
    return (
        <>
            {/* Help Center */}
            <Grid
                item
                xs={12}
                sm={12}
                md={9}
                lg={9}
                xl={9}
                style={{
                    display: "flex",
                    gap: "14px",
                    marginTop: "-68.5px",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                }}>
                <Box style={{
                    display: "flex",
                    flexDirection: 'row',
                    gap: "14px",
                }}>
                    <span style={{
                        color: "#FFFFFF",
                        fontFamily: "Outfit",
                        fontSize: "24px",
                        fontWeight: "700",
                        lineHeight: "33px",

                    }}>
                        Help Center
                    </span>
                </Box>
            </Grid>
            {/* Main Content */}
            <Box style={container}>
                {/* Help center Details */}
                <Grid style={card}>
                    <Typography style={titel}>
                        Help center Details
                    </Typography>
                    <Typography style={key}>
                        Title : <span style={value}>{details?.title}</span>
                    </Typography>
                    <Typography style={key}>
                        Category : <span style={value}>{capitalizeFirstLetter(details?.type)}</span>
                    </Typography>
                    <Typography style={key}>
                        Priority :  <span style={value}>{capitalizeFirstLetter(details?.priority)}</span>
                    </Typography>
                    {/* <Typography style={key}>
                        Reason : <span style={value}>{capitalizeFirstLetter(details?.reasone)}</span>
                    </Typography>
                    <Typography style={key}>
                        Sub Reason : <span style={value}>{capitalizeFirstLetter(details?.sup_reasone)}</span>
                    </Typography> */}
                </Grid>
                {/* description */}
                <Grid style={descriptionCard}>
                    <Typography style={titel}>
                        Description:
                    </Typography>
                    <CustomTypography>
                        {parse(details?.description_html || '')}
                    </CustomTypography>
                </Grid>
                {/* Actions: */}
                <Grid style={card}>
                    <Typography style={titel}>
                        Actions :
                    </Typography>
                    <Typography style={key}>
                        User Action:
                        {details?.user_action?.map((action, index) => {
                            let displayText = action;

                            if (action.toLowerCase() === 'chat') {
                                displayText = 'Message Support';
                            } else if (action.toLowerCase() === 'call') {
                                displayText = 'Call Support';
                            }

                            return (
                                <Typography key={index} style={value}>
                                    <span style={actionValue}>{displayText}</span>
                                </Typography>
                            );
                        })}
                    </Typography>
                    <Typography style={key}>
                        Suggested Actions For Agent  : <span style={value}>{capitalizeFirstLetter(details?.suggested_actions)}</span>
                    </Typography>

                </Grid>
            </Box >
        </>
    );
};

export default HelpCenterDetails;
