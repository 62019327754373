import React, { useEffect } from 'react';

const LoadGoogleMaps = ({ apiKey }) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places,drawing&v=3.52`;
        script.defer = true;
        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
    }, [apiKey]); 

    return null; 
};

export default LoadGoogleMaps;
