import { getMethod } from "../../pages/Hook/UseCustomApi";
import { postDataRedux, selectChat, selectRide, setLastRideChat, setLastSupportChat, setOpenChatFromDriver, updateOutChat } from "../../redux/features/appStateSlice"

const RidesHistoryNotifications = ['ride_cancel', 'ride_completed'];
const RidesActiveNotifications = ['ride_assigned', 'driver_arrived', 'new_admin_ride', 'new_customer_ride'];
const RidesScheduledNotifications = ['reminder_scheduled_ride_before_start', 'reminder_scheduled_ride'];

export const notificationClick = (notification, onClose, navigate, dispatch, reduxData, markAsRead, location) => {
    if (RidesHistoryNotifications.includes(notification?.object_type) || RidesActiveNotifications.includes(notification?.object_type) || RidesScheduledNotifications.includes(notification?.object_type) || notification?.object_type === 'panic_button_driver' || notification?.object_type === 'panic_button_customer') {
        dispatch(postDataRedux({ stateName: 'rides', data: 'notification' }))
        navigate(`/rides/details/${notification?.object_id}`)
        onClose()
        markAsRead(notification.id)
    } else if (notification?.object_type === 'create_new_admin') {
        const matchingAdmin = reduxData?.admins?.find(item => item._id === notification?.object_id);
        if (matchingAdmin) {
            navigate(`/admins`, { state: { id: notification?.object_id} })
            markAsRead(notification.id)
        } else {
            const getAdminById = (id) => {
                getMethod({
                    url: `admin/${id}`,
                }).then((response) => {
                    // dispatch(postDataRedux({ stateName: 'admins', data: response.data, push: true }))
                    navigate(`/admins`, { state: { id: notification?.object_id ,adminNotificationData:response.data } })
                }).catch((error) => {
                    console.error("Error fetching admin data:", error)
                })
            }
            getAdminById(notification?.object_id)
        }
        onClose()
        markAsRead(notification.id)
    } else if (notification?.object_type === 'messaging_with_admin') {
        const matchingAdmin = reduxData?.lastSupportChat.fb_uid === notification?.option?.uid;
        if (matchingAdmin) {
            navigate(`/support`)
            markAsRead(notification.id)
        } else {
            if (notification?.option?.chat_type === 'customer') {
                getMethod({
                    url: `customer/getOneCustomer/${notification?.option?.uid}`,
                }).then((response) => {
                    const selectedObject = response.data
                    dispatch(selectChat({
                        ...selectedObject, customer: `admin_${selectedObject?.fb_uid}`, type: "customer", user_name:
                            selectedObject?.first_name +
                            " " +
                            selectedObject?.last_name,
                        user_phoneNumber: selectedObject?.phone_number,
                        user_profile: selectedObject?.profile_picture,
                    }));
                    dispatch(setLastSupportChat({
                        ...selectedObject, customer: `admin_${selectedObject?.fb_uid}`, type: "customer", user_name:
                            selectedObject?.first_name +
                            " " +
                            selectedObject?.last_name,
                        user_phoneNumber: selectedObject?.phone_number,
                        user_profile: selectedObject?.profile_picture,
                    }));
                    dispatch(updateOutChat(false));
                    dispatch(setOpenChatFromDriver(true));
                    navigate(`/support`)
                }).catch((error) => {
                    console.error("Error fetching admin data:", error)
                })
                markAsRead(notification.id)
            } else {
                getMethod({
                    url: `driver/getOneDriver/${notification?.option?.uid}`,
                }).then((response) => {
                    const selectedObject = response.data
                    dispatch(selectChat({
                        ...selectedObject, driver: `admin_${selectedObject?.fb_uid}`, type: "driver", user_name:
                            selectedObject?.first_name +
                            " " +
                            selectedObject?.last_name,
                        user_phoneNumber: selectedObject?.phone_number,
                        user_profile: selectedObject?.profile_picture,
                    }));
                    dispatch(setLastSupportChat({
                        ...selectedObject, driver: `admin_${selectedObject?.fb_uid}`, type: "driver", user_name:
                            selectedObject?.first_name +
                            " " +
                            selectedObject?.last_name,
                        user_phoneNumber: selectedObject?.phone_number,
                        user_profile: selectedObject?.profile_picture,
                    }));
                    dispatch(updateOutChat(false));
                    dispatch(setOpenChatFromDriver(true));
                }).catch((error) => {
                    console.error("Error fetching admin data:", error)
                })
                markAsRead(notification.id)
            }
        }
        onClose()
    } else if (notification?.object_type === 'messaging' || notification?.object_type === 'new_message') {
        const matchingAdmin = reduxData?.lastRideChat.fb_uid === notification?.option?.uid;
        if (matchingAdmin) {
            navigate(`/chat`)
            markAsRead(notification.id)
        } else {
            getMethod({
                url: `ride/${notification?.object_id}`,
            }).then((response) => {
                const selectedRide = response.data
                dispatch(selectRide({ ...selectedRide, chat_type: 'ride' }));
                dispatch(setLastRideChat({ ...selectedRide, chat_type: 'ride', rideId: selectedRide?._id }));
                dispatch(updateOutChat(true));
                navigate(`/chat`)
            }).catch((error) => {
                console.error("Error fetching admin data:", error)
            })
            markAsRead(notification.id)
        }
        markAsRead(notification.id)
        onClose()
    } else if (notification?.object_type === 'incoming_money' || notification?.object_type === 'outcoming_money') {
        const matchingAdmin = reduxData?.lastSupportChat.fb_uid === notification?.option?.uid;
        if (matchingAdmin) {
            // navigate(`/support`)
        } else {
            if (true) {
                getMethod({
                    url: `customer/getOneCustomer/${notification?.object_id}`,
                }).then((response) => {
                    navigate(`/drivers/details/${response?.data._id}`)
                }).catch((error) => {
                    console.error("Error fetching admin data:", error)
                })
                markAsRead(notification.id)
            } else {
                getMethod({
                    url: `customer/getOneCustomer/${notification?.option?.uid}`,
                }).then((response) => {
                    const selectedObject = response.data
                    dispatch(selectChat({
                        ...selectedObject, driver: `admin_${selectedObject?.fb_uid}`, type: "driver", user_name:
                            selectedObject?.first_name +
                            " " +
                            selectedObject?.last_name,
                        user_phoneNumber: selectedObject?.phone_number,
                        user_profile: selectedObject?.profile_picture,
                    }));
                    dispatch(setLastSupportChat({
                        ...selectedObject, driver: `admin_${selectedObject?.fb_uid}`, type: "driver", user_name:
                            selectedObject?.first_name +
                            " " +
                            selectedObject?.last_name,
                        user_phoneNumber: selectedObject?.phone_number,
                        user_profile: selectedObject?.profile_picture,
                    }));
                    dispatch(updateOutChat(false));
                    dispatch(setOpenChatFromDriver(true));
                }).catch((error) => {
                    console.error("Error fetching admin data:", error)
                })
                markAsRead(notification.id)
            }
            // navigate(`/support`)
        }
        onClose()
    } else if (notification?.object_type === 'panic_button_driver' || notification?.object_type === 'panic_button_customer') {
        dispatch(postDataRedux({ stateName: 'rides', data: 'notification' }))
        navigate(`/rides/details/${notification?.object_id}`)
        onClose()
        markAsRead(notification.id)
    }
}