import {
  Alert,
  Box,
  Snackbar,
  Typography
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Loading from "react-fullscreen-loading";
import { AiFillEdit } from "react-icons/ai";
import { IoEyeSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import StatusBadge from "../../components/common/BadgeWrapper";
import BlueButton from "../../components/common/Buttons/BlueButton";
import colorConfigs from "../../configs/colorConfigs";
import { auth } from "../../configs/firebase";
import { setPayoutList, setPayrollList, setPayrollTabIndex, setSelectedPayout } from "../../redux/features/appStateSlice";
import { openModal } from "../../redux/features/modalSlice";
import { formatDate } from "../../utils/localization/dateTimeUtils";
import { getMethod } from "../Hook/UseCustomApi";
import DynamicTable from "../table/table";
import PayoutPlanDialog from "./component/PayoutPlanDialog";
import SettingsPayout from "./component/PayoutSettings";

export default function PayoutView() {
  const dispatch = useDispatch();
  const payout = useSelector((state) => state.appState.payout);
  const [data, setData] = useState(payout);
  const [count, setCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [editData, setEditData] = useState(null);
  let currentPage = useRef();
  let currentLimit = useRef(5);
  let currentKeyword = useRef("");
  let currentType = useRef("all");
  const [formErrors, setErrors] = useState({});
  const [type, setType] = useState("all");
  const [query, setQuery] = useState("");
  const rowsLimits = [5, 10, 25, 100];
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarErrorMessage, setSnackbarErrorMessage] = useState("");
  const countryCode = useSelector((state) => state.country.selectedCountry);
  const [editDialog, setEditDialog] = useState(false);

  const navigate = useNavigate();
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarErrorMessage(message);
    setSnackbarOpen(true);
  };

  const getParams = () => {
    return {
      page: currentPage.current,
      limit: currentLimit.current,
      keyword: currentKeyword.current,
    };
  };

  useEffect(() => {
    currentPage.current = 1;
    currentLimit.current = 5;
    currentKeyword.current = "";
    currentType.current = "all";

    fetchPayout();
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        if (payout?.length === 0) {
          fetchPayout();
        } else {
          setData(payout);
        }
        // } else {
        console.log("No user logged in.");
      }
    });

    return () => unsubscribe(); // Clean up the subscription
  }, []);

  const fetchPayout = async () => {
    if (payout?.length === 0) {
      setLoading(true);
    } else {
      setLoading(false);
    }

    getMethod({
      url: "payment/payout/billing-cycles",
      params: getParams(),
      handleErrorMessage: showSnackbar,
    })
      .then((response) => {
        setLoading(false);
        dispatch(setPayoutList(response?.data));
        console.log(response?.data, "test");
        setData(response?.data);
        setCount(response?.count);
      })
      .catch((err) => {
        console.error("Error fetching more stores:", err);
        setLoading(false);
      })
      .finally(() => setLoading(false));
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    currentPage.current = newPage;
    fetchPayout();
  };

  const handleRowsChange = (rows) => {
    currentPage.current = 1;
    currentLimit.current = rows;
    setPage(1);
    fetchPayout();
  };

  const handleTypeChange = (event) => {
    setType(event.target.value);
    currentPage.current = 1;
    currentType.current = event.target.value;
    fetchPayout();
  };
  const [settingsData, setSettingsData] = useState(null);

  // Fetch the settings data
  const fetchSettingsData = async () => {
    setLoading(true);
    try {
      getMethod({
        url: "admin/setting/project",
        params: getParams(),
        handleErrorMessage: showSnackbar,
      })
        .then((response) => {
          setLoading(false);

          console.log(response?.data, "olalalalaal");
          setSettingsData(response?.data);
        })
        .catch((err) => {
          console.error("Error fetching more stores:", err);
          setLoading(false);
        })
        .finally(() => setLoading(false));
    } catch (error) {
      console.error("Error fetching settings data:", error);
      showSnackbar("Failed to fetch settings data");
    } finally {
      setLoading(false);
    }
  };
  console.log(settingsData, "settingsData");

  useEffect(() => {
    fetchSettingsData();
  }, []);


  const fieldStyle = {
    "& label.Mui-focused": {
      color: "#172B4D !important",
      fontSize: "17px !important",
      fontWeight: "500 !important",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#BBC1CE",
        borderWidth: 1,
        borderRadius: "8px",
      },
      "&:hover fieldset": {
        borderColor: "#BBC1CE",
        borderWidth: 1,
        borderRadius: "8px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#BBC1CE",
        borderWidth: 1,
        borderRadius: "8px",
      },
      input: {
        "&::placeholder": {
          color: "#3A3541DE",
          opacity: 1, // otherwise firefox shows a lighter color
          fontSize: 14,
        },
      },
    },
  };

  function handleRowClick(rowData) {
    dispatch(setSelectedPayout(rowData))
    setEditData(rowData);
    navigate(`/payment/${rowData?._id}`);
    setEditDialog(true);
  }

  function handleCloseDialog() {
    setEditDialog(false);
    setEditData(null);
    setErrors({});
  }

  const initialValues = {
    name: editData?.name || "",
    email: editData?.email || "",
  };

  const style = {
    color: "#495974",
    fontWeight: "500",
    fontSize: "14px",
    fontFamily: "poppins",
    lineHeight: "18px" /* 150% */,
    padding: "8px 15px ",
  };

  const capitalizeFirstLetter = (str) => {
    return str?.charAt(0)?.toUpperCase() + str?.slice(1);
  };

  useEffect(() => {
    dispatch(setPayoutList(data));
  }, [data]);

  const columns = [
    {
      field: "short_id",
      headerName: "Billing cycle ID",
      flex: 1,
      minWidth: "150px",
      editable: true,
      style: style,
      align: "left",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "payout_date",
      headerName: "Pay Period",
      flex: 1,
      minWidth: "150px",
      style: style,
      align: "left",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        const datePayout = new Date(params?.row?.payout_date);
        const formattedDatePayout = formatDate(datePayout, countryCode, false); // Set includeTime to false
        const dateObject = new Date(params?.row?.payout_startAt);
        const formattedDate = formatDate(dateObject, countryCode, false); // Set includeTime to false
        return (
          <span>
            {formattedDate} - {formattedDatePayout}
          </span>
        );
      },
    },
    {
      field: "payout_startAt",
      headerName: "Payout Date",
      flex: 1,
      minWidth: "150px",
      style: style,
      align: "left",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        const dateObject = new Date(params?.row?.payout_startAt);
        const formattedDate = formatDate(dateObject, countryCode, false); // Set includeTime to false
        return <span>{formattedDate}</span>;
      },
    },
    {
      field: "number_driver",
      headerName: "#Drivers",
      minWidth: "120px",
      flex: 1,
      style: style,
      align: "center",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "amount",
      headerName: "Amount",
      minWidth: "120px",
      flex: 1,
      style: style,
      align: "left",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => <Typography>${params.row.amount}</Typography>,
    },
    {
      field: "frequency",
      headerName: "Frequency",
      minWidth: "120px",
      flex: 1,
      style: style,
      align: "left",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: "120px",
      flex: 1,
      style: style,
      align: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => <StatusBadge status={params.row.status} />,
    },
    {
      field: "Action",
      headerName: "Action",
      style: style,
      headerClassName: "super-app-theme--header",
      renderCell: (params) =>
        auth?.currentUser?.uid !== params?.row?.fb_uid && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <Box
              style={{
                width: "30px",
                height: "30px",
                backgroundColor: "#F3F8FD",
                borderRadius: "50%",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}


              onClick={() => { handleRowClick(params.row); dispatch(setPayrollTabIndex(0)); dispatch(setPayrollList([])) }}
            >
              <AiFillEdit size="20px" style={{ color: "#00C371" }} />
            </Box>
          </div>
        ),
    },
  ];

  return (
    <>
      <SettingsPayout />

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          background: "white",
          p: 1,
          borderRadius: 3,
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography
          style={{
            color: "#172B4D",
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "137%",
            letterSpacing: "-0.8px",
          }}
        >
          Payouts
        </Typography>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "14px",
          }}
        >

          <BlueButton
            onClick={() =>
              dispatch(
                openModal(<PayoutPlanDialog settingsData={settingsData} onUpdate={fetchSettingsData} />)
              )
            }
            sx={{
              minWidth: 200,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
              borderRadius: 2,
              height: 45,
            }}
          >
            <IoEyeSharp size={18} />
            <Typography sx={{ fontSize: 14, fontWeight: 600 }}>
              Pay out plan Details
            </Typography>
          </BlueButton>
        </div>
      </Box>

      <DynamicTable
        columns={columns}
        data={data}
        count={count}
        page={page}
        rowsLimits={rowsLimits}
        pageChange={handlePageChange}
        rowsChange={handleRowsChange}
        pageType="payment"
      />

      <Loading
        loading={loading && payout.length === 0}
        background="rgba(86, 100, 210, 0.1)"
        loaderColor={colorConfigs.sidebar?.bg}
      />

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleSnackbarClose} severity="error">
          {snackbarErrorMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
