import { ListItemButton, ListItemIcon } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import colorConfigs from "../../configs/colorConfigs";
import UnreadDocument from "./UnreadDocment";

const SidebarItem = ({ item }) => {
  const { appState } = useSelector((state) => state.appState);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return item.sidebarProps && item.path ? (
    <ListItemButton
      component={Link}
      to={item.path}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        "&: hover": {
          backgroundColor: "white",
          color: colorConfigs?.sidebar?.bg,

        },
        backgroundColor: appState === item.state ? 'white' : "unset",
        borderTopLeftRadius: "32px",
        borderBottomLeftRadius: "32px",
        paddingY: "12px",
        paddingX: "24px",

        color: appState === item.state ? colorConfigs?.sidebar?.bg : colorConfigs.sidebar.color,
      }}
    >
      <ListItemIcon
        sx={{
          "&: hover": {
            color: colorConfigs?.sidebar?.bg,
          },
          color: appState === item.state ? colorConfigs?.sidebar?.bg : colorConfigs.sidebar.color,
        }}
      >
        {(appState === item.state || isHovered) && item.sidebarProps.activeIcon
          ? item.sidebarProps.activeIcon
          : item.sidebarProps.icon}
      </ListItemIcon>
      {item.sidebarProps.displayText}
      {
        item?.path === '/support' ?
          <UnreadDocument
            collectionName={'messaging_with_admin'}
            conditions={[
              { key: 'unRead.admin', operator: '>', value: 0 },
            ]}
          />
          : item?.path === '/chat' ?
            <UnreadDocument collectionName={'messaging'}
              conditions={[
                { key: 'unRead.admin', operator: '>', value: 0 },
              ]}
            />
            :
            ''
      } 
    </ListItemButton>
  ) : null;
};

export default SidebarItem;
