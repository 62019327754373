import React, { useState } from 'react';
import { Box, Grid, Typography, IconButton, Button, DialogActions, DialogContentText, DialogContent, Dialog } from '@mui/material';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { styled } from '@mui/material/styles';
import { fontWeight } from '@mui/system';
import colorConfigs from '../../../../configs/colorConfigs';
import DeleteIcon from '@mui/icons-material/Delete';
import { CiEdit } from 'react-icons/ci';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteMethod } from '../../../Hook/UseCustomApi';
import deletee from "../../../../assets/images/delete.png";
import { deleteDataRedux } from '../../../../redux/features/appStateSlice';
import MembersTab from './MembersTab';
const StyledBox = styled(Box)(({ theme }) => ({
    borderRadius: '8px',
    padding: '20px',
    backgroundColor: '#fff',
    marginTop: '-64px',
    boxShadow: '0px 4px 28px rgba(0, 0, 0, 0.06)'

}));

const HeaderBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    background: '#FFF',
    boxShadow: '0 4px 36px rgba(0, 0, 0, 0.07)',
    padding: theme.spacing(1),
    borderRadius: 5,
}));

const LigthText = styled(Box)(({ theme }) => ({
    color: '#7A869A',
    fontSize: 14,
    fontWeight: 500,
    fontFamily: 'poppins',
}));

const DetailTypography = styled(Typography)(({ theme }) => ({
    color: '#495974',
    fontSize: 14,
    fontWeight: 700,
    fontFamily: 'poppins',
}));


function VoucherDetails() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [deleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] = useState(false);
    const selectedVoucher = useSelector((state) => state.appState.selectedVoucher);
    const [idToDelete, setIdToDelete] = useState("");




    const handleDelete = (id) => {
        setIdToDelete(id);
        setDeleteConfirmationDialogOpen(true);
    };

    const handleDeleteConfirmation = async (confirmed) => {
        setDeleteConfirmationDialogOpen(false);
        if (confirmed) {
            DeleteMethod({
                url: `admin/voucher/${idToDelete}`,
            })
                .then((res) => {
                    dispatch(deleteDataRedux({
                        stateName: 'vouchersList',
                        key: '_id',
                        value: selectedVoucher?._id,
                    }));
                })
                .catch((error) => console.error("Error deleting admin:", error))
        }

    };

    return (
        <>
            <StyledBox>
                {/* Header Section */}
                <HeaderBox>
                    <Typography sx={{ color: colorConfigs?.sidebar?.bg, fontSize: 16, fontWeight: 700 }} component="div">
                        Voucher Group Details
                    </Typography>
                    <Box>
                        <IconButton
                            sx={{ p: 0, mr: 2 }}
                            onClick={() => {
                                navigate('/cooperates/edit-voucher')
                            }}
                        >
                            <div
                                style={{
                                    backgroundColor: "#F3FDF7",
                                    display: "flex",
                                    borderRadius: "22px",
                                    alignItems: 'center',
                                    justifyContent: "center",
                                    padding: "4px",
                                }}>
                                <CiEdit
                                    size={22}
                                    color='#00C371'
                                />
                            </div>
                        </IconButton>
                        <IconButton
                            sx={{ p: 0, mr: 2 }}
                            onClick={() => handleDelete(selectedVoucher?._id)}
                        >
                            <div
                                style={{
                                    backgroundColor: "#ffd8d8",
                                    display: "flex",
                                    borderRadius: "22px",
                                    padding: "4px",
                                }}>
                                <DeleteIcon
                                    fontSize="small"
                                    className="icon"
                                    style={{ color: "#E71D36" }}
                                />
                            </div>
                        </IconButton>
                    </Box>
                </HeaderBox>

                {/* Details Section */}
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <DetailTypography>
                            <LigthText component={'span'}>Name: </LigthText>{selectedVoucher?.voucher_name}
                        </DetailTypography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <DetailTypography>
                            <LigthText component={'span'}>Ride Limit: </LigthText>{selectedVoucher?.max_rides_number}
                        </DetailTypography>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <DetailTypography>
                            <LigthText component={'span'}>Rides Type: </LigthText>{selectedVoucher?.ride_types?.map((ride) => ride.name).join(', ')}
                        </DetailTypography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <DetailTypography>
                            <LigthText component={'span'}>Zone Address: </LigthText>{selectedVoucher?.address?.address}
                        </DetailTypography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <DetailTypography>
                            <LigthText component={'span'}>Used by: </LigthText>25
                        </DetailTypography>
                    </Grid>
                </Grid>
            </StyledBox>
            <Box sx={{ background: '#FFF', mt: 2, p: 1.5, borderRadius: 3 }}>
                <MembersTab />
            </Box>


            {/* delete dialog */}
            <Dialog
                open={deleteConfirmationDialogOpen}
                onClose={() => setDeleteConfirmationDialogOpen(false)}
                keepMounted>
                <DialogContent style={{ padding: "30px" }}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <img src={deletee} alt="" />
                    </div>
                    <DialogContentText
                        id="alert-dialog-slide-description"
                        style={{ display: "flex", justifyContent: "center" }}>
                        <Typography
                            variant="h6"
                            style={{
                                fontWeight: "bold",
                                color: "black",
                                marginTop: "10px",
                                marginBottom: "8px",
                            }}>
                            Delete Card
                        </Typography>
                    </DialogContentText>
                    <Typography >
                        Are you sure you want to delete this card ?
                    </Typography>
                </DialogContent>
                <DialogActions
                    style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        marginBottom: "20px",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                    }}>
                    <Button
                        variant="outlined"
                        style={{
                            borderColor: "lightgray",
                            width: "100%",
                            color: 'black',
                        }}
                        onClick={() => handleDeleteConfirmation(false)}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        style={{
                            color: "white",
                            background: "var(--Error, #FD4343)",
                            width: "100%",
                        }}
                        onClick={() => handleDeleteConfirmation(true)}>
                        Yes, Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default VoucherDetails;
