import StarIcon from "@mui/icons-material/Star";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Snackbar,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import Loading from "react-fullscreen-loading";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import deletee from "../../assets/images/delete.png";
import colorConfigs from "../../configs/colorConfigs";
import DynamicTable from "../table/table";
import Error5xx from "../../components/common/HandelError5xx";
import { formatDate } from "../../utils/localization/dateTimeUtils";
import { DeleteMethod, getMethod, putMethod } from "../Hook/UseCustomApi";
// ***************************************

export default function DriverReviews({ driverId }) {
  const [data, setData] = useState([]);
  const [count, setCount] = useState(5);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [key, setKey] = useState("");
  const [value, setValue] = useState("");
  const rowsLimits = [5, 10, 25, 100];
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [isHideDialogOpen, setHideDialogOpen] = useState(false);
  const [hideItemId, setHideItemId] = useState(null);
  const [visiblity, setVisiblity] = useState("");
  const dispatch = useDispatch();
  let currentPage = useRef();
  let currentLimit = useRef(10);
  const navigate = useNavigate();
  const countryCode = useSelector((state) => state.country.selectedCountry);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  //handle error5xx
  const [tryAgain, setTryAgain] = useState();
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [errorCount, setErrorCount] = useState(0);
  const [errorDialogCounter, setErrorDialogCounter] = useState(0);
  const handleError5xx = () => {
    setIsErrorDialogOpen(true);
    setErrorCount(prevCount => {
      const newCount = prevCount + 1;
      if (newCount === 1) {
        setErrorDialogCounter(5);
      } else if (newCount === 2) {
        setErrorDialogCounter(10);
      }
      return newCount;
    });
  };
  const handleCloseErrorDialog = () => {
    setIsErrorDialogOpen(false);
    if (errorCount <= 2) {
      handleErrorCountByClose()
    }

  };
  const handleErrorCountByClose = () => {
    setErrorCount(prevCount => {
      const newCount = prevCount - 1;
      if (newCount === 1) {
        setErrorDialogCounter(5);
      } else if (newCount === 2) {
        setErrorDialogCounter(10);
      }
      return newCount;
    });
  }


  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const getParams = () => {
    return {
      page: currentPage.current,
      limit: currentLimit.current,
    };
  };

  const openHideDialog = (id) => {
    setHideItemId(id);
    setHideDialogOpen(true);
  };

  const closeHideDialog = () => {
    setHideDialogOpen(false);
    setHideItemId(null);
  };

  const handleHide = (id, status) => {
    openHideDialog(id);

    if (status === "visible") {
      setVisiblity("hidden");
    } else {
      setVisiblity("visible");
    }
  };

  const openDeleteDialog = (id) => {
    setDeleteItemId(id);
    setDeleteDialogOpen(true);
  };
  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setDeleteItemId(null);
  };
  const handleDelete = (id) => {
    openDeleteDialog(id);
  };


  const hideData = async () => {
    putMethod({
      url: `review/${hideItemId}`,
      body: visiblity,
      handleErrorMessage: showSnackbar,
    })
      .then((response) => {
        console.log("Review hidden successfully:", response);
        fetchDrivers();
      })
      .catch((error) => {
        console.error("Error hiding data:", error);
        if (error === "Internal Server Error: Server error occurred.") {
          handleError5xx();
          setTryAgain(() => hideData);
        }
      })
      .finally(() => {
        closeHideDialog();
      });
  };

  const deleteData = async () => {
    DeleteMethod({
      url: `review/${deleteItemId}`,
      handleErrorMessage: showSnackbar,
    })
      .then(() => {
        console.log("Review data deleted successfully");

        fetchDrivers();
      })
      .catch((error) => {
        console.error("Error deleting data:", error);
        if (error === "Internal Server Error: Server error occurred.") {
          handleError5xx();
          setTryAgain(() => deleteData);
        }
      })
      .finally(() => {
        closeDeleteDialog();
      });
  };

  useEffect(() => {
    currentPage.current = 1;
    currentLimit.current = 10;
    fetchDrivers();
  }, []); // Run once on component mount
  // fetch driver

  const fetchDrivers = () => {
    setLoading(true);

    getMethod({
      url: `driver/review/history/${driverId}`,
      params: getParams(),
      handleErrorMessage: showSnackbar,
    })
      .then((response) => {
        let result = response.data;
        setData(result);
        setCount(response.count);
        console.log("fetchDrivers", result);
      })
      .catch((error) => {
        console.error("Error fetching more stores:", error)
        if (error === "Internal Server Error: Server error occurred.") {
          handleError5xx();
          setTryAgain(() => fetchDrivers);
        }
      })
      .finally(() => setLoading(false));
  };

  // fetch more drivers
  const fetchMoreDrivers = (key, value) => {
    const getParam = () => {
      return {
        page: currentPage.current,
        limit: currentLimit.current,
        [key]: value,
      };
    };

    setLoading(true);
    if (key) {
      setKey(key);
      setValue(value);

      getMethod({
        url: `driver/review/history/${driverId}`,
        params: getParam(),
        handleErrorMessage: showSnackbar,
      })
        .then((response) => {
          let result = response?.data;
          setData(result);
          setCount(response.count);
        })
        .catch((error) => {
          console.error("Error fetching more stores:", error)
          if (error === "Internal Server Error: Server error occurred.") {
            handleError5xx();
            setTryAgain(() => () => fetchMoreDrivers(key, value));
          }
        })
        .finally(() => setLoading(false));
    } else {
      fetchDrivers();
    }
  };
  const [age, setAge] = useState("all");

  const handleChange = (event) => {
    fetchMoreDrivers("status", event.target.value);
    setAge(event.target.value);
  };
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    currentPage.current = newPage;
    fetchMoreDrivers(key, value);
  };
  const handleRowsChange = (rows) => {
    currentPage.current = 1;
    setPage(1);
    fetchMoreDrivers("limit", rows);
  };

  const style = {
    color: "#696969",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "24px" /* 150% */,
    letterSpacing: "0.17px",
    textTransform: "capitalize",
  };
  const columns = [
    {
      field: "delivery_No",
      headerName: "Trip No",
      // headerName: "Trip No",
      flex: 1,
      minWidth: "20px",
      editable: true,
      style: style,
      align: "left",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        console.log(params.row)
        return <Typography>
          {params.row.short_id ? params.row.short_id : "-"}
        </Typography>
      }
      ,
    },

    {
      field: "rider_name",
      headerName: "Passenger Name",
      flex: 1,
      minWidth: "150px",
      style: style,
      align: "left",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <Box style={{ display: "flex", alignItems: "center", gap: "12px" }}>
          <img
            style={{
              width: "34px",
              height: "34px",
              borderRadius: "50%",
            }}
            src="https://i.pinimg.com/736x/de/59/4e/de594ec09881da3fa66d98384a3c72ff.jpg"
            alt=""
          />
          <span>{params.row.reviewer_name}</span>
        </Box>
      ),
    },

    {
      field: "rating",
      headerName: "Rating",
      flex: 1,
      minWidth: "50px",
      editable: true,
      style: style,
      align: "left",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <>
          <IconButton aria-label="delete">
            {" "}
            <StarIcon style={{ color: "#faaf00" }} />
            <Typography>{params.row.rating}</Typography>
          </IconButton>
        </>
      ),
    },

    {
      field: "create_at",
      headerName: "Booking Date",
      flex: 1,
      minWidth: "20px",
      editable: true,
      style: style,
      align: "left",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return <Typography>{formatDate(params?.row?.create_at, countryCode)}</Typography>;
      },
    },
    {
      field: "content",
      headerName: "Review",
      flex: 1,
      minWidth: "50px",
      editable: true,
      style: style,
      align: "left",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <Typography>
          {params.row.text ? params.row.text : "No Review Text"}
        </Typography>
      ),
    },
  ];
  return (
    <>
      {/* handleError5xx */}
      <Error5xx open={isErrorDialogOpen} errCount={errorCount} count={errorDialogCounter} handleClose={handleCloseErrorDialog} tryAgain={tryAgain} />
      <Dialog
        style={{ zIndex: 10, borderRadius: "30px" }}
        open={isHideDialogOpen || isDeleteDialogOpen}
        onClose={() => {
          closeHideDialog();
          closeDeleteDialog();
        }}
        keepMounted
        aria-describedby="alert-dialog-slide-description">
        <DialogContent style={{ padding: "30px" }}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {isDeleteDialogOpen && <img src={deletee} alt="" />}
          </div>
          <DialogContentText
            id="alert-dialog-slide-description"
            style={{ display: "flex", justifyContent: "center" }}>
            <Typography
              variant="h6"
              style={{
                fontWeight: "bold",
                color: "black",
                marginTop: "10px",
                marginBottom: "8px",
              }}>
              {isDeleteDialogOpen
                ? " Delete Review"
                : visiblity === "visible"
                  ? "Visible Review"
                  : "Hide Review"}
            </Typography>
          </DialogContentText>
          <DialogContentText id="alert-dialog-slide-description">
            {isDeleteDialogOpen
              ? "Are you sure you want to Delete Driver Review?"
              : visiblity === "visible"
                ? "Are you sure you want to Visible Driver Review?"
                : "Are you sure you want to Hide Driver Review?"}
          </DialogContentText>
        </DialogContent>

        <DialogActions
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            marginBottom: "20px",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}>
          <Button
            variant="outlined"
            style={{
              color: loading ? "gray" : "black",
              borderColor: "lightgray",
              width: "100%",
            }}
            onClick={() => {
              closeHideDialog();
              closeDeleteDialog();
            }}>
            Cancel
          </Button>

          {isDeleteDialogOpen && (
            <Button
              variant="contained"
              style={{
                background: loading ? "gray" : "var(--Error, #FD4343)",
                color: "white",
                width: "100%",
              }}
              onClick={deleteData}
              disabled={loading}>
              {loading ? "...Loading" : "Yes, Delete"}
            </Button>
          )}

          {isHideDialogOpen && (
            <Button
              variant="contained"
              style={{
                background: loading ? "gray" : "var(--Error, #FD4343)",
                color: "white",
                width: "100%",
              }}
              onClick={hideData}
              disabled={loading}>
              {loading
                ? "...Loading"
                : visiblity === "visible"
                  ? "Yes, Visible"
                  : "Yes, Hide"}
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          gap: "14px",
          marginBottom: "14px",
          width: "100%",
        }}></div>
      <DynamicTable
        columns={columns}
        data={data}
        count={count}
        page={page}
        rowsLimits={rowsLimits}
        pageChange={handlePageChange}
        rowsChange={handleRowsChange}
        pageType="drivers"
      />
      <Loading
        loading={!!loading}
        background="rgba(86, 100, 210, 0.1)"
        loaderColor={colorConfigs.sidebar?.bg}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}>
        <Alert onClose={handleSnackbarClose} severity="error">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
