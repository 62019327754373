import * as React from "react"
import { memo } from "react"
const SvgComponent = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={50}
        height={50}
        fill="none"
        {...props}
    >
        <circle cx={25} cy={25} r={25} fill="#FF9191" opacity={0.2} />
        <path
            stroke="red"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={3}
            d="m16 34 8.5-8.5L33 34m0-17-8.502 8.5L16 17"
        />
    </svg>
)
const CancelRide = memo(SvgComponent)
export default CancelRide
