import CommentIcon from "@mui/icons-material/Comment";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import { Alert, Box, Button, Snackbar, Typography } from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import icon from "../../assets/images/Icon.png";
import location from "../../assets/images/location.png";
import taxi from "../../assets/taxi-booking.png";
import taxi1 from "../../assets/taxiwomen.png";
import colorConfigs from "../../configs/colorConfigs";
import TextRating from "../../pages/rating/rate";
import { chat, selectChat, setLastSupportChat, setOpenChatFromDriver, updateOutChat } from "../../redux/features/appStateSlice";
import { getMethod } from "./../../pages/Hook/UseCustomApi";
import { NewFormatPhoneNumber } from "../../utils/localization/NewPhoneUtils";
import { Avatar } from "antd";
import ClickablePhoneNumber from "../../utils/ClickablePhoneNumber";
import stopIcon from "../../assets/images/stop.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
export default function TripInfoDialog({ driver, onClose }) {
  const [valueTabs, setValueTabs] = React.useState("one");
  const [ride, setRide] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const countryCode = useSelector((state) => state.country.selectedCountry);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleChange = (e, newValue) => {
    setValueTabs(newValue);
  };

  const closeDialog = () => {
    onClose();
  };


  const getParams = () => {
    return {
    };
  };
  const getRide = async () => {
    getMethod({
      url: `ride/${driver.active_ride_id}`,
      params: getParams(),
      handleErrorMessage: showSnackbar,
    })
      .then((response) => {
        let result = response?.data;
        setRide(result);
      })
      .catch((error) => {
        console.log("Error on getting active ride:", error);
      });
  };
  useEffect(() => {
    getRide();
  }, [driver.active_ride_id]);
  const wattingTime = (ride) => {
    const assignedAt = new Date(ride.assigned_at);
    const arrivalPickupAt = new Date(ride.arrival_pickup_at);
    const timeDifferenceInMilliseconds = arrivalPickupAt - assignedAt;
    let minutes;
    if (timeDifferenceInMilliseconds < 0) {
      minutes = 0;
    } else {
      minutes = Math.floor(
        (timeDifferenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
      );
    }

    return `${minutes} min`;
  };

  const [showAll, setShowAll] = useState(false);


  return (
    <div
      style={{
        position: "absolute", // or "absolute" depending on your layout
        bottom: "16px",
        right: "50px",
        backgroundColor: "white",
        padding: "10px",
        border: "1px solid #ccc",
        color: "black",
        width: "85%",
        height: "270px",
        overflowY: 'auto',
        overflowX: 'hidden',
        borderRadius: "12px",
        fontFamily: "sans-serif",
      }}
    >
      <div>
        <Box
          sx={{
            width: "100%",
            background: "#F0F0F0",
          }}
        >
          <Tabs
            value={valueTabs}
            onChange={handleChange}
            textColor="inherit"
            sx={{ color: colorConfigs?.sidebar?.bg }}
            TabIndicatorProps={{
              style: {
                backgroundColor: colorConfigs?.sidebar?.bg,
              },
            }}
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            variant="scrollable"
          >
            <Tab
              value="one"
              label="Trip Details"
              style={{
                width: "150px",
                textTransform: "capitalize",
                fontWeight: "bold",
                fontFamily: "Poppins",
              }}
            />
            <Tab
              value="two"
              label="Driver"
              style={{
                width: "150px",
                textTransform: "capitalize",
                fontWeight: "bold",
                fontFamily: "Poppins",
              }}
            />
            {driver?.active_ride_id !== "" && (
              <Tab
                value="three"
                label="Passenger"
                style={{
                  width: "150px",
                  textTransform: "capitalize",
                  fontWeight: "bold",
                  fontFamily: "Poppins",
                }}
              />
            )}
            <Tab
              value="four"
              label="Vehicle Details"
              style={{
                width: "182px",
                textTransform: "capitalize",
                fontWeight: "bold",
                fontFamily: "Poppins",
              }}
            />
            {driver?.active_ride_id !== "" && (
              <Tab
                value="five"
                label="Payment"
                style={{
                  width: "150px",
                  textTransform: "capitalize",
                  fontWeight: "bold",
                  fontFamily: "Poppins",
                }}
              />
            )}
          </Tabs>
        </Box>
        {/* Close button added here */}
        <button
          style={{
            position: "absolute",
            top: "-7px",
            right: "-4px",
            cursor: "pointer",
            padding: "8px 12px",
            backgroundColor: "transparent",
            color: "#000000a6",
            border: "none",
            borderRadius: "4px",
            fontFamily: "Poppins",
            fontWeight: "bold",
            fontSize: "15px",
          }}
          onClick={closeDialog}
        >
          &#x2715;
        </button>
        {valueTabs === "one" ? (
          driver?.active_ride_id !== "" ? (
            <div>
              <div
                style={{
                  position: "relative",
                  fontFamily: "Poppins",
                  paddingTop: "12px",
                }}
              >
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    padding: "5px",
                  }}
                >
                  <img src={location} alt="loc" />
                  <Typography
                    variant="body2"
                    style={{
                      color: colorConfigs.sidebar.bg,
                      opacity: "0.8",
                      height: "85%",
                      marginTop: "3px",
                      fontWeight: "bold",
                      fontFamily: "Poppins",
                      marginLeft: "10px", // Add left margin for spacing
                    }}
                  >
                    Pick Up:
                    <br />
                    <Typography
                      key={ride._id}
                      variant="caption"
                      style={{
                        color: "black",
                        opacity: "0.8",
                        height: "85%",
                        marginTop: "3px",
                        fontFamily: "Poppins",
                      }}
                    >
                      {ride?.pickup_address?.address}
                    </Typography>
                  </Typography>
                </div>

                {/* Connecting line */}
                {/* <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "4.5%",
                    transform: "translate(-50%, -45%)",
                    borderLeft: "1px dashed gray", // Adjust the line style
                    height: "28px", // Adjust the height of the line
                  }}
                ></div> */}

                {ride?.stop_points?.length > 2 ? (
                  <div style={{ alignItems: "center", display: "flex", padding: "5px", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img src={stopIcon} alt="loc" style={{ height: "38px", width: "38px" }} />
                      <Typography
                        variant="body2"
                        style={{
                          color: colorConfigs.sidebar.bg,
                          opacity: "0.8",
                          marginTop: "3px",
                          fontWeight: "bold",
                          fontFamily: "Poppins",
                          marginLeft: "10px",
                        }}
                      >
                        Stop points: {ride?.stop_points?.length}
                      </Typography>
                    </div>
                    <div>
                      {!showAll ? (
                        <ExpandMoreIcon onClick={() => setShowAll(true)} style={{ fontSize: "28px", cursor: "pointer", color: colorConfigs.sidebar.bg }} />
                      ) : (
                        <ExpandLessIcon onClick={() => setShowAll(false)} style={{ fontSize: "28px", cursor: "pointer", color: colorConfigs.sidebar.bg }} />
                      )}
                    </div>
                  </div>
                ) : (
                  ride?.stop_points?.map((stopPoint, index) => (
                    <div key={index} style={{ alignItems: "center", display: "flex", padding: "5px" }}>
                      <img src={stopIcon} alt="loc" style={{ height: "38px", width: "38px" }} />
                      <Typography
                        variant="body2"
                        style={{
                          color: colorConfigs.sidebar.bg,
                          opacity: "0.8",
                          marginTop: "3px",
                          fontWeight: "bold",
                          fontFamily: "Poppins",
                          marginLeft: "10px",
                        }}
                      >
                        Stop point: {index + 1}
                        <br />
                        <Typography variant="caption" style={{ color: "black", opacity: "0.8", marginTop: "3px", fontFamily: "Poppins" }}>
                          {stopPoint?.address}
                        </Typography>
                      </Typography>
                    </div>
                  ))
                )}

                {showAll &&
                  ride?.stop_points?.map((stopPoint, index) => (
                    <div key={index} style={{ alignItems: "center", display: "flex", padding: "5px", marginLeft: "60px" }}>
                      <img src={stopIcon} alt="loc" style={{ height: "38px", width: "38px" }} />
                      <Typography
                        variant="body2"
                        style={{
                          color: colorConfigs.sidebar.bg,
                          opacity: "0.8",
                          marginTop: "3px",
                          fontWeight: "bold",
                          fontFamily: "Poppins",
                          marginLeft: "10px",
                        }}
                      >
                        Stop point: {index + 1}
                        <br />
                        <Typography variant="caption" style={{ color: "black", opacity: "0.8", marginTop: "3px", fontFamily: "Poppins" }}>
                          {stopPoint?.address}
                        </Typography>
                      </Typography>
                    </div>
                  ))}

                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    padding: "5px",
                  }}
                >
                  <img src={icon} alt="loc" />
                  <Typography
                    variant="body2"
                    style={{
                      color: colorConfigs.sidebar.bg,
                      opacity: "0.8",
                      marginTop: "3px",
                      fontWeight: "bold",
                      fontFamily: "Poppins",
                      marginLeft: "10px",
                    }}
                  >
                    Drop Off:
                    <br />
                    <Typography
                      key={ride._id}
                      variant="caption"
                      style={{
                        color: "black",
                        opacity: "0.8",
                        marginTop: "3px",
                        fontFamily: "Poppins",
                      }}
                    >
                      {ride?.delivery_address?.address}
                    </Typography>
                  </Typography>
                </div>
              </div>
            </div>
          ) : (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                style={{ width: "120px", height: "120px", marginTop: "12px" }}
                src={process.env.REACT_APP_ENV === 'women_first' ? taxi1 : taxi}
                alt="no-active delivery"
              />
              <p
                style={{
                  marginTop: "12px",
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "#172B4D",
                }}
              >
                No Active Delivery!
              </p>
            </div>
          )
        ) : (
          ""
        )}

        {valueTabs === "two" && (
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "16px",
              }}
            >
              <div className="info" style={{ display: "flex", gap: "16px" }}>
                <div
                  style={{
                    width: "70px",
                    height: "70px",
                    borderRadius: "50%",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "50%",
                    }}
                    src={driver.profile_picture}
                    alt="profile-picture"
                  />
                </div>
                <div>
                  <Typography
                    style={{
                      textTransform: "capitalize",
                      color: "#172B4D",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "700",
                    }}
                  >
                    {driver.first_name + " " + driver.last_name}
                  </Typography>

                  <div style={{ width: "50px" }}>
                    <TextRating value={+driver?.review_info?.total_avg} />
                  </div>
                </div>
              </div>
              <div className="chat">
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: colorConfigs.sidebar.bg,
                    color: "white",
                    fontSize: "12px",
                    fontFamily: "Poppins",
                    textTransform: "capitalize",
                    borderRadius: "30px",
                    padding: "4px 12px",
                  }}
                  onClick={() => {
                    dispatch(selectChat({ ...driver, driver: `admin_${driver?.fb_uid}`, type: "driver", user_profile: driver?.profile_picture, user_name: `${driver?.first_name + " " + driver?.last_name}` }));
                    dispatch(setLastSupportChat({ ...driver, driver: `admin_${driver?.fb_uid}`, type: "driver", user_profile: driver?.profile_picture, user_name: `${driver?.first_name + " " + driver?.last_name}` }));
                    dispatch(setOpenChatFromDriver(true));
                    dispatch(updateOutChat(false));
                    navigate(`/support`);
                    console.log(driver)

                  }}
                >
                  {" "}
                  <CommentIcon
                    style={{ width: "15px", marginRight: "8px" }}
                  />{" "}
                  Chat
                </Button>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                padding: "0 16px",
                marginLeft: "82px",
                gap: "16px",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <EmailIcon style={{ color: colorConfigs?.sidebar?.bg, marginRight: "12px" }} />
                <span>{driver?.email}</span>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <PhoneIcon style={{ color: colorConfigs?.sidebar?.bg, marginRight: "12px" }} />
                <span>
                  {NewFormatPhoneNumber(driver?.phone_number, countryCode)}
                </span>
              </div>
            </div>
          </div>
        )}
        {valueTabs === "three" && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "16px",
              }}
            >
              <div className="info" style={{ display: "flex", gap: "16px" }}>
                <div
                  style={{
                    width: "70px",
                    height: "70px",
                    borderRadius: "50%",
                  }}
                >
                  <Avatar
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "50%",
                    }}
                    src={ride?.customer_details?.profile_picture}
                    alt="profile-picture"
                  />
                </div>
                <div>
                  <Typography
                    style={{
                      textTransform: "capitalize",
                      color: "#172B4D",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "700",
                    }}
                  >
                    {ride?.customer_details?.first_name}{" "}{ride?.customer_details?.last_name}
                  </Typography>
                  {/* <Typography
                    style={{
                      color: "#172B4D",
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      fontWeight: "400",
                    }}
                  >
                    <span style={{ color: "#495974", fontWeight: "600" }}>
                      {" "}
                      ID NO.{" "}
                    </span>
                    {ride?.customer_details?.short_id}
                  </Typography> */}
                  <div style={{ width: "50px" }}>
                    <TextRating value={ride?.customer_details?.review_avg} />
                  </div>
                </div>
              </div>
              <div className="chat">
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: colorConfigs?.sidebar?.bg,
                    color: "white",
                    fontSize: "12px",
                    fontFamily: "Poppins",
                    textTransform: "capitalize",
                    borderRadius: "30px",
                    padding: "4px 12px",
                  }}
                  onClick={() => {
                    navigate(
                      `/support`
                    );
                    dispatch(
                      chat({
                        user_name:
                          ride?.customer_details?.first_name +
                          " " +
                          ride?.customer_details?.last_name,
                        user_phoneNumber: ride?.customer_details?.phone_number,
                        user_profile: ride?.customer_details?.profile_picture,
                      })
                    );
                    dispatch(selectChat({
                      ...ride?.customer_details, customer: `admin_${ride?.customer_details?.fb_uid}`, type: "customer",
                      user_name:
                        ride?.customer_details?.first_name +
                        " " +
                        ride?.customer_details?.last_name,
                      user_phoneNumber: ride?.customer_details?.phone_number,
                      user_profile: ride?.customer_details?.profile_picture,
                    }));
                    dispatch(setLastSupportChat({
                      ...ride?.customer_details, customer: `admin_${ride?.customer_details?.fb_uid}`, type: "customer",
                      user_name:
                        ride?.customer_details?.first_name +
                        " " +
                        ride?.customer_details?.last_name,
                      user_phoneNumber: ride?.customer_details?.phone_number,
                      user_profile: ride?.customer_details?.profile_picture,
                    }));
                    dispatch(updateOutChat(false));
                    dispatch(setOpenChatFromDriver(true));
                  }}
                >
                  {" "}
                  <CommentIcon
                    style={{ width: "15px", marginRight: "8px" }}
                  />{" "}
                  Chat
                </Button>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                padding: "0 16px",
                marginLeft: "82px",
                gap: "16px",
              }}
            >
              {ride?.customer_details?.email ? <div style={{ display: "flex", alignItems: "center" }}>
                <EmailIcon style={{ color: colorConfigs?.sidebar?.bg, marginRight: "12px" }} />
                <span>{ride?.customer_details?.email}</span>
              </div> : ''}
              {ride?.customer_details?.phone_number ? <div style={{ display: "flex", alignItems: "center" }}>
                <PhoneIcon style={{ color: colorConfigs?.sidebar?.bg, marginRight: "12px" }} />
                <span>
                  {ClickablePhoneNumber(ride?.customer_details?.code_phone_number, ride?.customer_details?.phone_number)}
                </span>
              </div> : ''}
            </div>
          </div>
        )}
        {valueTabs === "four" && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              padding: "12px",
            }}
          >
            <div
              className="images"
              style={{ display: "flex", gap: "16px", marginTop: "16px" }}
            >
              {driver?.vehicle_info?.vehicle_images?.map((i) => (
                <div>
                  <img
                    style={{
                      width: "160px",
                      height: "90px",
                      borderRadius: "8px",
                    }}
                    src={i}
                    alt="vehicle-image"
                  />
                </div>
              ))}
            </div>
            <div
              className="info"
              style={{
                display: "flex",
                flexDirection: "column",
                fontSize: "14px",
                gap: "4px",
              }}
            >
              <div style={{ display: "flex", columnGap: "8px" }}>
                <span
                  style={{ flex: "1", color: "#7A869A", fontWeight: "500" }}
                >
                  Vehicle No:{" "}
                  <span style={{ color: "#172B4D", fontWeight: "600", textTransform: 'uppercase' }}>
                    {driver?.vehicle_info?.license_plate}
                  </span>
                </span>

                <span
                  style={{ flex: "2", color: "#7A869A", fontWeight: "500" }}
                >
                  Vehicle Make:{" "}
                  <span style={{ color: "#172B4D", fontWeight: "600" }}>
                    {driver?.vehicle_info?.vehicle_make}
                  </span>
                </span>
              </div>
              <div style={{ display: "flex", columnGap: "16px" }}>
                <span
                  style={{ flex: "1.5", color: "#7A869A", fontWeight: "500" }}
                >
                  Vehicle Type:{" "}
                  <span style={{ color: "#172B4D", fontWeight: "600" }}>
                    {driver?.vehicle_info?.vehicle_type}
                  </span>
                </span>

                <span
                  style={{ flex: "2", color: "#7A869A", fontWeight: "500" }}
                >
                  Vehicle Model:{" "}
                  <span style={{ color: "#172B4D", fontWeight: "600" }}>
                    {driver?.vehicle_info?.vehicle_model}
                  </span>
                </span>

                <span
                  style={{ flex: "1", color: "#7A869A", fontWeight: "500" }}
                >
                  Year:{" "}
                  <span style={{ color: "#172B4D", fontWeight: "600" }}>
                    {driver?.vehicle_info?.vehicle_year}
                  </span>
                </span>
              </div>
            </div>
          </div>
        )}
        {valueTabs === "five" && (
          <div style={{ padding: "0 16px", textAlign: "center" }}>
            <p
              style={{
                flex: "1",
                color: "#7A869A",
                fontWeight: "500",
                backgroundColor: "#F5F7F8",
                borderRadius: "8px",
                padding: "12px",
              }}
            >
              Estimated Fare:{" "}
              <span style={{ color: "#172B4D", fontWeight: "600" }}>
                ${ride?.estimation_cost > 0 ? ride?.estimation_cost : ride?.pay_info?.total}
              </span>
            </p>
            <p
              style={{
                flex: "1",
                color: "#7A869A",
                fontWeight: "500",
                backgroundColor: "#F5F7F8",
                borderRadius: "8px",
                padding: "12px",
              }}
            >
              Payment Type:{" "}
              <span style={{ color: "#172B4D", fontWeight: "600" }}>
                {ride?.pay_type === 'cash' ? 'Cash' : 'Card'}
              </span>
            </p>
            <p
              style={{
                flex: "1",
                color: "#7A869A",
                fontWeight: "500",
                backgroundColor: "#F5F7F8",
                borderRadius: "8px",
                padding: "12px",
              }}
            >
              Payment Status:{" "}
              <span style={{ color: "#172B4D", fontWeight: "600" }}>
                {ride?.payment_status}
              </span>
            </p>
          </div>
        )}
      </div>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleSnackbarClose} severity="error">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
