import {
    Box,
    FormControl,
    MenuItem,
    Tab,
    Tabs,
    Typography,
    styled
} from "@mui/material";
import React from 'react';
import { FaArrowUp } from "react-icons/fa6";
import { TbFilterOff } from "react-icons/tb";
import BlueButton from '../../../components/common/Buttons/BlueButton';
import colorConfigs from "../../../configs/colorConfigs";
import AddNewRide from "../../rides/AddRide/AddNewRide";
import { useSelector } from "react-redux";




const zones = [
    { value: 100, color: "#10C100" },
    { value: 150, color: "#F0C000" },
    { value: 200, color: "#10C100" },
    { value: 260, color: "#FF0000" },
    { value: 244, color: "#FF0000" },
    { value: 280, color: "#F0C000", border: '1px solid black' },
    { value: 400, color: "#FF0000" },
];

const CustomTabs = styled(Tabs)(() => ({
    backgroundColor: '#F7F7F7',
    borderRadius: '8px',
    justifyContent: 'space-between',
    alignItems: 'center',
    p: 1
}));

const CustomTab = styled(Tab)(({ theme }) => ({
    textTransform: 'none',
    minWidth: 72,
    fontSize: '12px',
    fontWeight: '700',
    minHeight: '40px',
    marginRight: '5px',
    borderRadius: '8px',
    '&.Mui-selected': {
        color: '#FFFFFF',
        backgroundColor: colorConfigs?.sidebar?.bg,
    },
    '&:not(.Mui-selected)': {
        color: '#495974',
    },
    '&:last-child': {
        marginRight: 0,
    },
}));

const CustomFormControl = styled(FormControl)(({ theme }) => ({
    '& .MuiInputBase-root': {
        backgroundColor: '#f7f7f7',
        borderRadius: '8px',
        height: '32px',
        border: 'none',
        padding: '0 14px',
        display: 'flex',
        alignItems: 'center',
    },
    '& .MuiInputLabel-root': {
        top: '-8px',
        fontSize: '12px',
    },
    '& .MuiSelect-root': {
        display: 'flex',
        alignItems: 'center',
    },
    '& .MuiSelect-icon': {
        color: '#7c7c7c',
    },
    '& .MuiSelect-select': {
        display: 'flex',
        alignItems: 'center',
        padding: '0 14px',
        height: '32px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
}));

const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    height: '32px',
    fontSize: '12px',
    fontWeight: '500',
    fontFamily: 'Poppins',
}));

const ToggleContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    border: `1px solid ${colorConfigs?.sidebar?.bg}`,
    borderRadius: '50px',
    overflow: 'hidden',
    width: 'fit-content',
    cursor: 'pointer',
    padding: 1,
}));

const ToggleOption = styled(Box)(({ theme, active }) => ({
    padding: '8px 10px',
    borderRadius: '25px',
    fontSize: '12px',
    textAlign: 'center',
    backgroundColor: active ? colorConfigs?.sidebar?.bg : '#fff',
    color: active ? '#fff' : '#495974',
    fontWeight: active ? '700' : 'normal',
    fontFamily: 'poppins',
}));

const RideSidebar = ({
    isVisible,
    toggleVisibility,
    rideDataCount,
    handleNewRideAdded,
    selectedTab,
    handleTabChange,
    selectedCompany,
    handleCompanyChange,
    renderTabContent,
    handleClearFilters,
    handleMarkerClick,
    countChanges,
    handleUpdateClick,
    changeScheduledRides,
    scheduledRides,
}) => {

    const licAndConfig = useSelector((state) => state.licencesAndConfiguration);
    // console.log(licAndConfig);


    return (
        <Box
            sx={{
                width: '450px',
                maxWidth: '450px',
                padding: 2,
                height: "calc(100vh - 130px)",
                borderRadius: 2,
                backgroundColor: "white",
                boxShadow: 'rgba(0, 0, 0, 0.15) 0 4px 36px',
                display: isVisible ? "block" : "none",
                transition: 'transform 0.5s ease-in-out',
                overflowY: "hidden",
                position: { xs: 'absolute', lg: 'relative' },
                top: { xs: '50%' },
                left: { xs: '50%', lg: '0' },
                transform: { xs: 'translate(-50%, 4%)', lg: isVisible ? 'translateX(0)' : 'translateX(100%)' },
                zIndex: 1300,
            }}
            className={`sideRideContainer ${isVisible ? 'visible' : ''}`}
        >
            <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1, alignItems: 'center' }}>
                <Typography sx={{ fontSize: 18 }}>Rides</Typography>
                <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', gap: 1 }}>
                    <Box onClick={() => { handleClearFilters() }} sx={{ background: colorConfigs?.sidebar?.bg, height: 40, width: 40, borderRadius: 25, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
                        <TbFilterOff color="white" />
                    </Box>
                    {licAndConfig?.licenses?.create_ride_by_dispatch && (<AddNewRide />)}
                </Box>
            </Box>
            <CustomTabs value={selectedTab} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
                <CustomTab label={`Unassigned (${rideDataCount?.unassigned})`} />
                <CustomTab label={`Assigned (${rideDataCount?.assigned})`} />
                <CustomTab label={`Active (${rideDataCount?.active})`} />
            </CustomTabs>
            {licAndConfig?.licenses?.scheduled_ride && (
                selectedTab !== 2 ?
                    <ToggleContainer onClick={() => { changeScheduledRides(selectedTab) }}>
                        <ToggleOption active={!scheduledRides[selectedTab === 0 ? 'unassigned' : selectedTab === 1 ? 'assigned' : 'active']}>
                            Unscheduled
                        </ToggleOption>
                        <ToggleOption active={scheduledRides[selectedTab === 0 ? 'unassigned' : selectedTab === 1 ? 'assigned' : 'active']}>
                            Scheduled
                        </ToggleOption>
                    </ToggleContainer> : ''
            )}
            <Box sx={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: 'transparent', display: 'flex', justifyContent: 'center' }}>
                {countChanges.unassigned && selectedTab === 0 && (
                    <BlueButton variant="outlined" sx={{ px: 1.5, py: 1, mt: 1, fontSize: 13 }} onClick={() => handleUpdateClick('unassigned')}>
                        <FaArrowUp style={{ marginRight: 5 }} />   Update Rides
                    </BlueButton>
                )}
                {countChanges.assigned && selectedTab === 1 && (
                    <BlueButton variant="outlined" sx={{ px: 1.5, py: 1, mt: 1, fontSize: 13 }} onClick={() => handleUpdateClick('assigned')}>
                        <FaArrowUp style={{ marginRight: 5 }} />       Update  Rides

                    </BlueButton>
                )}
                {countChanges.active && selectedTab === 2 && (
                    <BlueButton variant="outlined" sx={{ px: 1.5, py: 1, mt: 1, fontSize: 13 }} onClick={() => handleUpdateClick('active')}>
                        <FaArrowUp style={{ marginRight: 5 }} />   Update Rides
                    </BlueButton>
                )}
            </Box>
            {renderTabContent()}
            {/* <Box sx={{ padding: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography sx={{ textWrap: 'nowrap', mr: 2, fontSize: 12, fontWeight: 600 }}>
                    Show Company
                </Typography>
                <CustomFormControl fullWidth>
                    <Select value={selectedCompany} onChange={handleCompanyChange}>
                        <CustomMenuItem value="">All Companies</CustomMenuItem>
                        <CustomMenuItem value="Company 1">Company 1</CustomMenuItem>
                        <CustomMenuItem value="Company 2">Company 2</CustomMenuItem>
                    </Select>
                </CustomFormControl>
            </Box> */}
            {/* <Divider></Divider> */}
            {/* <Box sx={{ padding: 2 }}>
                <Box sx={{ display: 'flex', marginBottom: 1 }}>
                    <Typography sx={{ fontWeight: 'bold', marginRight: 3, fontSize: 12 }}>ID</Typography>
                    <Typography sx={{ fontWeight: 'bold', color: '#172B4D', fontSize: 12 }}>Zone Queues</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Typography variant="body2" sx={{ fontWeight: 'bold', marginRight: 3 }}>0</Typography>
                    <Box sx={{ display: "flex", gap: 1 }}>
                        {zones.map((item, index) => (
                            <Box
                                key={index}
                                sx={{
                                    backgroundColor: item.color,
                                    width: 40,
                                    height: 20,
                                    borderRadius: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                                    {item.value}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                </Box>
            </Box> */}
        </Box >
    );
};

export default RideSidebar;
