import * as React from "react"
import { memo } from "react"
const SvgComponent = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={19}
        height={24}
        fill="none"
        {...props}
    >
        <circle
            cx={9.512}
            cy={6.49}
            r={5.574}
            stroke="#FFF"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2.16}
        />
        <path
            stroke="#FFF"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2.16}
            d="M.668 19.816a2.584 2.584 0 0 1 .256-1.132c.534-1.068 2.04-1.634 3.29-1.89.9-.193 1.814-.321 2.733-.385 1.702-.15 3.414-.15 5.115 0 .92.064 1.833.193 2.734.384 1.25.257 2.755.77 3.29 1.89.341.72.341 1.556 0 2.275-.535 1.122-2.04 1.634-3.29 1.88-.9.2-1.814.332-2.734.395a30.137 30.137 0 0 1-4.164.064c-.32 0-.63 0-.95-.064a17.995 17.995 0 0 1-2.724-.395c-1.26-.246-2.755-.759-3.3-1.88a2.66 2.66 0 0 1-.256-1.142Z"
            clipRule="evenodd"
        />
    </svg>
)
const WhiteCustomer = memo(SvgComponent)
export default WhiteCustomer
