import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { IoMdArrowRoundBack, IoMdClose } from 'react-icons/io';
import { useDispatch } from 'react-redux';
import { selectChat, selectRide, updateOutChat } from '../../../redux/features/appStateSlice';
import { closeModal, openModal } from '../../../redux/features/modalSlice';
import MessagingPage from './../../private_messaging/Messaging';
import RideDetailsModal from './RideDetailsModal';

const ModalContainer = styled(Box)(({ theme }) => ({
    background: '#FFFFFF',
    padding: theme.spacing(1.5),
    borderRadius: theme.shape.borderRadius,
    width: '1106px',
    maxWidth: '95%',
    maxHeight: '94vh',
    overflow: 'auto',
    margin: '0 auto',
}));

const Header = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '3px 10px',
}));

const CloseButton = styled(Box)(({ theme }) => ({
    background: '#F0F0F0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer',
}));


const RideChatModel = ({ id, active_ride_id }) => {

    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(closeModal());
        dispatch(updateOutChat(false));
        dispatch(selectChat({}));
        dispatch(selectRide({}));

    };

    const Back = () => {
        dispatch(updateOutChat(false));
        dispatch(openModal(<RideDetailsModal active_ride_id={active_ride_id}/>));
    }



    return (
        <ModalContainer>
            <Header>
                <Typography onClick={Back} variant="h6" sx={{ color: '#172B4D', fontWeight: 700, display: 'flex', alignItems: 'center', gap: 1, cursor: 'pointer' }}>
                    <IoMdArrowRoundBack />
                    Back
                </Typography>
                <CloseButton onClick={handleClose}>
                    <IoMdClose size={20} />
                </CloseButton>
            </Header>
            <MessagingPage />
        </ModalContainer>
    );
};

export default RideChatModel;
