export const formatNumber = (number) => {
    if (isNaN(number)) {
        return '0';
    }
    const formattedNumber = Number(number).toFixed(2);
    const [integerPart, decimalPart] = formattedNumber.split('.');
    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    if (decimalPart === '00') {
        return formattedInteger;
    }
    return `${formattedInteger}.${decimalPart}`;
};
