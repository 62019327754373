import * as React from "react"
import { memo } from "react"
import colorConfigs from "../../../configs/colorConfigs"
const SvgComponent = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={50}
        height={50}
        fill="none"
        {...props}
    >
        <circle cx={25} cy={25} r={25} fill="#fff" />
        <path
            fill={colorConfigs?.sidebar?.bg}
            fillRule="evenodd"
            d="M29.205 14a3.75 3.75 0 0 1 3.354 2.073l1.732 3.463c.305-.125.605-.26.9-.405a1.25 1.25 0 0 1 1.118 2.238c-.3.134-.603.264-.906.39l1.2 2.404A3.75 3.75 0 0 1 37 25.84V29a3.751 3.751 0 0 1-1.25 2.795v1.58a1.875 1.875 0 1 1-3.75 0v-.625H17v.625a1.875 1.875 0 1 1-3.75 0v-1.58A3.742 3.742 0 0 1 12 29v-3.16c0-.582.136-1.156.396-1.676l1.191-2.385c-.301-.127-.6-.263-.893-.409a1.262 1.262 0 0 1-.563-1.679 1.25 1.25 0 0 1 1.678-.56c.296.146.596.281.9.405l1.732-3.462A3.75 3.75 0 0 1 19.795 14h9.41Zm-10.33 11.25a1.875 1.875 0 1 0 0 3.75 1.875 1.875 0 0 0 0-3.75Zm11.25 0a1.875 1.875 0 1 0 0 3.75 1.875 1.875 0 0 0 0-3.75Zm-.92-8.75h-9.41a1.25 1.25 0 0 0-1.04.556l-.078.135-1.587 3.173c1.935.58 4.543 1.136 7.41 1.136 2.677 0 5.126-.485 7.012-1.021l.397-.115-1.586-3.173a1.25 1.25 0 0 0-.963-.681l-.154-.01h-.001Z"
            clipRule="evenodd"
        />
    </svg>
)
const RideCompleted = memo(SvgComponent)
export default RideCompleted
