import DriveFileRenameOutlineSharpIcon from "@mui/icons-material/DriveFileRenameOutlineSharp";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {
  Alert,
  Box,
  Button,
  CardContent,
  Grid,
  MenuItem,
  Select,
  Snackbar,
  Typography, Divider,
} from "@mui/material";
import {
  PhoneNumberInput,
  SubmitButton,
  TextInput,
} from "@zetaton-dev/zetaton-components-bank";
import { Modal } from "antd";
import { Formik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import colorConfigs from "../../configs/colorConfigs";
import { emailRegex } from "../../configs/validation";
import {
  setEnableEdit
} from "../../redux/features/appStateSlice";
import { NewFormatPhoneNumber } from "../../utils/localization/NewPhoneUtils";
import { putMethod } from "../Hook/UseCustomApi";
import "../drivers/index.css";
import { makeStyles } from "@mui/styles";
import { fontFamily, fontWeight } from "@mui/system";
import Error5xx from "../../components/common/HandelError5xx";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import { formatCurrency } from "../../utils/localization/currencyUtils";



const useStyles = makeStyles({
  select: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiSelect-select': {
      color: '#0FBE00',
      fontSize: '16px',
      fontWeight: '700',
      fontFamily: 'poppins'
    },
  }
});

const RiderInfo = ({ selectedObject, enabled, params, handleUpdate }) => {
  const [startDate, setStartDate] = useState(null);
  const classes = useStyles();
  const [formErrors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [view, setView] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const countryCode = useSelector((state) => state.country.selectedCountry);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const dispatch = useDispatch();
  const selectedUser = useSelector((state) => state.appState.selectedUser);

  //handle error5xx
  const [tryAgain, setTryAgain] = useState();
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [errorCount, setErrorCount] = useState(0);
  const [errorDialogCounter, setErrorDialogCounter] = useState(0);
  const handleError5xx = () => {
    setIsErrorDialogOpen(true);
    setErrorCount(prevCount => {
      const newCount = prevCount + 1;
      if (newCount === 1) {
        setErrorDialogCounter(5);
      } else if (newCount === 2) {
        setErrorDialogCounter(10);
      }
      return newCount;
    });
  };
  const handleCloseErrorDialog = () => {
    setIsErrorDialogOpen(false);
    if (errorCount <= 2) {
      handleErrorCountByClose()
    }

  };
  const handleErrorCountByClose = () => {
    setErrorCount(prevCount => {
      const newCount = prevCount - 1;
      if (newCount === 1) {
        setErrorDialogCounter(5);
      } else if (newCount === 2) {
        setErrorDialogCounter(10);
      }
      return newCount;
    });
  }

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const validationSchema = Yup.object().shape({
    first_name: Yup.string()
      .matches(/^[a-zA-Z]+(?: ?[a-zA-Z]+)* ?$/, "Only letters and up to two spaces are allowed")
      .required("First name is required"),
    last_name: Yup.string()
      .matches(/^[a-zA-Z]+(?: ?[a-zA-Z]+)* ?$/, "Only letters and up to two spaces are allowed")
      .required("Last name is required"),
    // email: Yup.string().email("Invalid email address").required("Email is required"),
    // Add more fields here if needed
    pricePlan: Yup.string().required("Rate type is required"),
  });

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const validateName = (value) => {
    const regex = /^[a-zA-Z]+(?: ?[a-zA-Z]+)* ?$/;
    return regex.test(value) ? undefined : "Use only letters.";
  };

  const showModal = (values) => {
    setErrors({});
    let error = {};

    const filteredFirstName = values.first_name.replace(/[^a-zA-Z0-9\s]/g, "");
    const filteredLastName = values.last_name.replace(/[^a-zA-Z0-9\s]/g, "");

    if (!filteredFirstName) {
      error.first_name = "First name is required.";
    } else if (filteredFirstName.length > 15) {
      error.first_name = "First name should not exceed 15 characters.";
    } else if (validateName(values.first_name) === "Use only letters.") {
      error.first_name = "Only letters And Up To Two Spaces Are Allowed.";
    }

    if (!filteredLastName) {
      error.last_name = "Last name is required.";
    } else if (filteredLastName.length > 15) {
      error.last_name = "Last name should not exceed 15 characters.";
    } else if (validateName(values.last_name) === "Use only letters.") {
      error.last_name = "Only letters And Up To Two Spaces Are Allowed.";
    }

    if (!values.email) {
      error.email = "Email is required.";
    } else if (!emailRegex.test(values.email)) {
      error.email = "Invalid email address.";
    }

    if (Object.keys(error).length > 0) {
      setErrors(error);
      setOpenDialog(false);
      console.warn(Object.keys(error));
    }

    if (error.first_name || error.last_name || error.email) {
      console.log("error => ", error);
    } else {
      setIsModalVisible(true);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const fieldStyle = {
    "& label.Mui-focused": {
      color: "#172B4D !important",
      fontSize: "17px !important",
      fontWeight: "500 !important",
    },

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#BBC1CE",
        borderWidth: 1,
        borderRadius: "8px",
      },
      "&:hover fieldset": {
        borderColor: "#BBC1CE",
        borderWidth: 1,
        borderRadius: "8px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#BBC1CE",
        borderWidth: 1,
        borderRadius: "8px",
      },
      input: {
        "&::placeholder": {
          color: "#3A3541DE",
          opacity: 1,
          fontSize: 14,
        },
      },
    },
  };

  const originalDateString = selectedObject?.dateOfBirth;
  const originalDate = new Date(originalDateString);

  const formattedDate = originalDate.toLocaleDateString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
  const startDateTimestamp = startDate && startDate?.valueOf();

  const handleSubmit = async (values) => {
    setErrors({});
    let error = {};

    const filteredFirstName = values.first_name.replace(/[^a-zA-Z0-9\s]/g, "");
    const filteredLastName = values.last_name.replace(/[^a-zA-Z0-9\s]/g, "");
    const email = values.email.toLowerCase();

    if (!filteredFirstName) {
      error.first_name = "First name is required.";
    } else if (filteredFirstName.length > 15) {
      error.first_name = "First name should not exceed 15 characters.";
    }

    if (!filteredLastName) {
      error.last_name = "Last name is required.";
    } else if (filteredLastName.length > 15) {
      error.last_name = "Last name should not exceed 15 characters.";
    }

    if (!values.email) {
      error.email = "Email is required.";
    } else if (!emailRegex.test(values.email)) {
      error.email = "Invalid email address.";
    }

    if (Object.keys(error).length > 0) {
      setErrors(error);
      setOpenDialog(false);
      console.warn(Object.keys(error));
    } else {
      const dataToUpdate = {
        first_name: filteredFirstName,
        last_name: filteredLastName,
        email: email,
        description: values.description,
        enabled: enabled,
        pricePlan: values.pricePlan,  // Add the rate type to the data being sent to the API
      };
      handleUpdate(dataToUpdate);
      putMethod({
        url: `customer/${params}`,
        body: dataToUpdate,
        handleErrorMessage: showSnackbar,
      })
        .then((r) => {
          setOpenDialog(false);
          setOpen(true);
          console.log(r);
          setIsModalVisible(false);
        })
        .catch((error) => {
          console.error("Update API error:", error.message);
          // Handle error scenarios
          if (error === "Internal Server Error: Server error occurred.") {
            handleError5xx();
            setTryAgain(() => () => handleSubmit(values));
          }
        })
        .finally(() => {
          setLoading(false);
          setOpenDialog(false);
        });
    }
  };

  const locale = countryCode === "USD" ? "en-US" : "ar-EG";
  const currencyCode =
    countryCode === "USD" ? "$" : countryCode === "EG" ? "EGP" : countryCode === 'JOD' ? "JOD" : '$';


  return (
    selectedObject &&
    Object.values(selectedObject)?.length > 0 && (
      <>


        <Formik
          initialValues={{
            first_name: selectedObject?.first_name,
            last_name: selectedObject?.last_name,
            email: selectedObject?.email,
            dateOfBirth: selectedObject?.dateOfBirth,
            phone_number: NewFormatPhoneNumber(
              selectedObject?.full_phone_number && selectedObject?.full_phone_number !== "" ? selectedObject?.full_phone_number :
                `${selectedObject?.code_phone_number + selectedObject?.phone_number}`
            ),
            description: selectedObject?.description,
            pricePlan: selectedObject?.pricePlan || 'flatRate', // Initial value for the dropdown
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ handleBlur, handleChange, values, errors, handleSubmit }) => (
            <>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {/* handleError5xx */}
                <Error5xx open={isErrorDialogOpen} errCount={errorCount} count={errorDialogCounter} handleClose={handleCloseErrorDialog} tryAgain={tryAgain} />
                <Box
                  display="flex"
                  style={{ width: "50%", padding: 16, cursor: "pointer" }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setView(true);
                      dispatch(setEnableEdit(false));
                    }}
                    style={{
                      borderTopLeftRadius: "88px",
                      borderBottomLeftRadius: "88px",
                      borderRight: "1px solid black",
                      borderColor: colorConfigs?.sidebar?.bg,
                      color: view ? "white" : colorConfigs?.sidebar?.bg,
                      backgroundColor: view ? colorConfigs?.sidebar?.bg : "white",
                      cursor: "pointer",
                    }}
                  >
                    <VisibilityOutlinedIcon fontSize="small" /> View
                  </Button>

                  <Button
                    variant="outlined"
                    onClick={() => {
                      setView(false);
                      dispatch(setEnableEdit(true));
                    }}
                    style={{
                      borderTopRightRadius: "88px",
                      borderBottomRightRadius: "88px",
                      borderColor: colorConfigs?.sidebar?.bg,
                      color: !view ? "white" : "#42526E",
                      backgroundColor: !view ? colorConfigs?.sidebar?.bg : "white",
                      cursor: "pointer",
                    }}
                  >
                    <DriveFileRenameOutlineSharpIcon fontSize="small" /> Edit
                  </Button>
                </Box>
                <Box sx={{ background: '#EEFFF0', display: 'flex', justifyContent: 'center', alignItems: 'center', minWidth: '150px', px: 1, pl: 3, gap: 0.1, borderRadius: 25, height: '50px' }}>
                  <Typography sx={{ minWidth: 'fit-content', color: '#7A869A', fontWeight: 600, fontSize: 14 }} >
                    Rate Type :
                  </Typography>
                  <>
                    <Select
                      fullWidth
                      label="Rate Type"
                      name="pricePlan"
                      value={values.pricePlan}
                      onChange={handleChange("pricePlan")}
                      onBlur={handleBlur("pricePlan")}
                      variant="outlined"
                      displayEmpty
                      inputProps={{ "aria-label": "Rate Type" }}
                      disabled={view}
                      className={classes.select}
                      style={{
                        pointerEvents: view ? "none" : "auto",
                      }}
                    >
                      <MenuItem value="default" className={classes.menuItem}>Default</MenuItem>
                      <MenuItem value="basic" className={classes.menuItem}>Basic</MenuItem>
                      <MenuItem value="flatRate" className={classes.menuItem}>Flat Rate</MenuItem>
                    </Select>
                    {errors.pricePlan && (
                      <Typography variant="caption" style={{ color: "red" }}>
                        {errors.pricePlan}
                      </Typography>
                    )}
                  </>
                </Box>

              </Box>
              <CardContent
                elevation={3}
                style={{
                  padding: 16,
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "13px",
                  marginTop: "5px",
                  backgroundColor: colorConfigs?.sidebar?.ligthBg,
                }}
              >

                <div style={{ display: "flex", alignItems: "center" }}>
                  <MonetizationOnOutlinedIcon
                    style={{ marginRight: 8, color: colorConfigs?.sidebar?.bg }}
                  />
                  <div>
                    {console.log(selectedObject, 'selectedObject')}
                    <Typography variant="caption" style={{ color: "gray" }}>
                      Wallet Balance{" "}
                    </Typography>
                    <br />
                    <Typography
                      variant="caption"
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {formatCurrency(
                        (selectedUser?.wallet_balance).toFixed(2),
                        locale,
                        currencyCode
                      )}
                    </Typography>
                  </div>
                </div>
                <Divider
                  orientation="vertical"
                  style={{ margin: "0 22px", width: "3px", height: "50px" }}
                />
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div>
                    <Typography variant="caption" style={{ color: "gray" }}>
                      Number of Rides{" "}
                    </Typography>
                    <br />
                    <Typography
                      variant="caption"
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {selectedObject?.rides_count?.completed > 0 || selectedObject?.rides_count?.canceled > 0
                        ? 'Total Rides: ' + (selectedObject?.rides_count?.completed + selectedObject?.rides_count?.canceled) : 'No Rides Yet'}

                    </Typography>
                  </div>
                </div>
                <Divider
                  orientation="vertical"
                  style={{ margin: "0 22px", width: "3px", height: "50px" }}
                />
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div>
                    <Typography variant="caption" style={{ color: "gray" }}>
                      Rides Count{" "}
                    </Typography>
                    <br />
                    <Typography
                      variant="caption"
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {selectedObject?.rides_count?.completed > 0 ? 'Completed Rides: ' + selectedObject?.rides_count?.completed : 'No Completed Rides'}

                    </Typography>
                  </div>
                </div>
                <Divider
                  orientation="vertical"
                  style={{ margin: "0 22px", width: "3px", height: "50px" }}
                />
                <div style={{ display: "flex", alignItems: "center" }}>

                  <div>
                    <Typography variant="caption" style={{ color: "gray" }}>
                      Rides Count{" "}
                    </Typography>
                    <br />
                    <Typography
                      variant="caption"
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {selectedObject?.rides_count?.canceled > 0 ? 'Canceled Rides: ' + selectedObject?.rides_count?.canceled : 'No Canceled Rides'}

                    </Typography>
                  </div>
                </div>
              </CardContent>
              <div style={{ height: "30px" }} />
              <div style={{ width: "100%" }}>
                <div>
                  <div style={{ height: "30px" }} />
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item md={6} xs={12}>

                        <TextInput
                          fullWidth
                          styles={fieldStyle}
                          label={"First Name"}
                          name="first_name"
                          onChange={handleChange("first_name")}
                          onBlur={handleBlur("first_name")}
                          required
                          value={values?.first_name}
                          variant="outlined"
                          placeholder="Please enter first name"
                          filled="true"
                          focused
                          InputProps={{
                            readOnly: view ? true : false,
                            style: { pointerEvents: view ? "none" : "auto" },
                          }}
                          error={Boolean(errors.first_name)}
                        />
                        {errors.first_name && (
                          <Typography variant="caption" style={{ color: "red" }}>
                            {errors.first_name}
                          </Typography>
                        )}
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <TextInput
                          fullWidth
                          label={"Last Name"}
                          styles={fieldStyle}
                          name="last_name"
                          onChange={handleChange("last_name")}
                          onBlur={handleBlur("last_name")}
                          required
                          value={values?.last_name}
                          variant="outlined"
                          placeholder="Please enter last name"
                          filled="true"
                          focused
                          InputProps={{
                            readOnly: view ? true : false,
                            style: { pointerEvents: view ? "none" : "auto" },
                          }}
                          error={Boolean(errors.last_name)}
                        />
                        {errors.last_name && (
                          <Typography variant="caption" style={{ color: "red" }}>
                            {errors.last_name}
                          </Typography>
                        )}
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <TextInput
                          fullWidth
                          label={"Email"}
                          styles={fieldStyle}
                          name="email"
                          onChange={handleChange("email")}
                          onBlur={handleBlur("email")}
                          required
                          value={values?.email}
                          variant="outlined"
                          placeholder="Please enter email"
                          filled="true"
                          focused
                          error={Boolean(errors.email)}
                          InputProps={{
                            readOnly: view ? true : false,
                            style: { pointerEvents: view ? "none" : "auto" },
                          }}
                        />
                        {errors.email && (
                          <Typography variant="caption" style={{ color: "red" }}>
                            {errors.email}
                          </Typography>
                        )}
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <PhoneNumberInput
                          fullWidth
                          styles={fieldStyle}
                          label={"Phone Number"}
                          name="phone_number"
                          onChange={handleChange("phone_number")}
                          onBlur={handleBlur("phone_number")}
                          required
                          disabled={true}
                          value={values.phone_number}
                          variant="outlined"
                          placeholder="Please enter contact number"
                          focused
                          filled="true"
                          error={Boolean(errors.phone_number)}
                        />
                        {errors.phone_number && (
                          <Typography variant="caption" style={{ color: "red" }}>
                            {errors.phone_number}
                          </Typography>
                        )}
                      </Grid>

                      <Grid item md={12} xs={12}>
                        <TextInput
                          fullWidth
                          helperText=""
                          styles={fieldStyle}
                          label={"Notes"}
                          name="description"
                          onChange={handleChange("description")}
                          onBlur={handleBlur("description")}
                          multiline
                          maxRows={5}
                          value={values.description}
                          error={Boolean(errors.description)}
                          InputProps={{
                            readOnly: view ? true : false,
                            style: { pointerEvents: view ? "none" : "auto" },
                          }}
                          variant="outlined"
                          placeholder="Please enter description"
                          focused
                          filled="true"
                        />
                        {errors.description && (
                          <Typography variant="caption" style={{ color: "red" }}>
                            {errors.description}
                          </Typography>
                        )}
                      </Grid>



                    </Grid>
                    <div style={{ height: "10px" }} />
                    {!view && (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          p: 2,
                        }}
                        style={{ marginLeft: "20px" }}
                      >
                        <SubmitButton
                          style={{
                            color: "white",
                            backgroundColor: colorConfigs?.sidebar?.bg,
                            width: "250px",
                            height: "50px",
                            borderRadius: "8px",
                            fontSize: "18px",
                          }}
                          type="submit"
                          variant="contained"
                          label={"Save"}
                          loading={loading}
                          onClick={() => { showModal(values) }}
                        />
                      </Box>
                    )}
                  </CardContent>
                  <Modal
                    title="Confirm Changes"
                    visible={isModalVisible}
                    onOk={handleSubmit}
                    onCancel={handleCancel}
                    okText="Yes"
                    okButtonProps={{
                      style: {
                        backgroundColor: colorConfigs?.sidebar?.bg,
                        color: "white",
                      },
                    }}
                  >
                    <p>Are you sure you want to save the changes?</p>
                  </Modal>
                </div>
              </div>
            </>

          )}
        </Formik>








        <div style={{ height: "30px" }} />
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            variant="filled"
            sx={{ width: "100%" }}
          >
            The data is saved successfully!
          </Alert>
        </Snackbar>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={handleSnackbarClose} severity="error">
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </>
    )
  );
};

export default RiderInfo;
