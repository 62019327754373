import DensityMediumIcon from '@mui/icons-material/DensityMedium';
import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import { setOpenDrawer } from "../../redux/features/appStateSlice";
import NotificationList from '../notification/NotificationList';
const Topbar = () => {
  const dispatch = useDispatch();
  const openDrawer = useSelector(state => state.appState.openDrawer);
  const [isOpenDrawer, setIsOpenDrawer] = useState(openDrawer);
  const location = useLocation();
  const params = useParams();
  useEffect(() => {
    setIsOpenDrawer(openDrawer);
  }, [openDrawer]);
  const path = location.pathname.split("/").filter(segment => segment !== "");

  const getPath = () => {
    if (location.pathname.includes('driversRequests')) {
      return '/drivers';
    }
    return `/${path[0]}`;
  };

  return (
    <div style={{
      marginTop: 60,
      display: 'flex',
      marginRight: '1%',
      marginBottom: '50px',
      position: 'relative',
      zIndex: 5,
      marginLeft: !isOpenDrawer ? '70px' : '19%',
      width: `calc(100% - ${isOpenDrawer ? '19%' : '70px'})`,
      justifyContent: 'space-between',
      alignItems:'start'
    }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
        }}
      >
        <div>
          <DensityMediumIcon
            onClick={() => dispatch(setOpenDrawer(!isOpenDrawer))}
            style={{
              color: 'white',
              marginRight: '1%',
              cursor: 'pointer',
              display: !openDrawer ? 'block' : 'none'
            }}
          />
        </div>
        <Typography
          style={{
            marginLeft: '15px',
            fontWeight: 500,
            color: '#BBC1CE',
            fontSize: '16px',
            lineHeight: '24px',
            textTransform: 'capitalize',
            width:'500px', 
            display: 'inline-block'
          }}
        >
          Dashboard
          {/* <Link to={path[0]} style={{textDecoration: 'none', color: 'inherit'}}>
            {location.pathname.includes(`/${params?.id}`) && !location.pathname.includes('chats')
              ? location.pathname.replace(`/${params?.id}`, '')
              : ! location.pathname.includes('chat') ? location.pathname : '/chats'}
          </Link> */}
          <Link to={getPath()} style={{ textDecoration: 'none', color: 'inherit', marginLeft: '10px' }}>
            {location.pathname.includes('driversRequests')
              ? '/driversRequests/details'
              : location.pathname.includes(`/${params?.id}`) && !location.pathname.includes('chats')
                ? location.pathname.replace(`/${params?.id}`, '')
                : !location.pathname.includes('chat')
                  ? location.pathname
                  : '/chats'}
          </Link>
        </Typography>
      </div>
      <NotificationList />
    </div>
  );
};
export default Topbar;