import React, { useState, useEffect } from "react";
import { Collapse, List, ListItem, ListItemIcon, ListItemText, ListItemButton } from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useNavigate, useLocation } from "react-router-dom";
import colorConfigs from "../../configs/colorConfigs";
import { transform } from "typescript";
import { IoIosArrowForward } from "react-icons/io";

const SidebarItemCollapse = ({ item }) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const isActive = (path) => {
    const regex = new RegExp(`${path.replace(/:\w+/g, '[^/]+')}`);
    return regex.test(location.pathname);
  };

  useEffect(() => {
    if (item.children.some(child => isActive(child.path))) {
      setOpen(true);
    }
  }, [location.pathname, item.children]);

  const handleClick = () => {
    setOpen(!open);
  };

  const CornerIcon = () => (
    <svg
      width="24"
      height="50" // Y-axis is three times taller than X-axis (24 * 3 = 50)
      viewBox="0 0 24 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    // style={{ transform: "rotate(60deg)" }}
    >
      <path d="M0 0 L0 50 L24 50" fill="none" stroke="#FFFFFF" />
    </svg>
  );


  return (
    <>
      <ListItem
        onClick={handleClick}
        sx={{
          cursor: 'pointer',
          pl: 3,
          zIndex: 100,
          color: item.children.some(child => isActive(child.path)) ? colorConfigs.sidebar.bg : "#FFF", borderRadius: '30px 0 0 30px',
          "&:hover": { backgroundColor: colorConfigs.sidebar.hoverBg, color: colorConfigs.sidebar.bg },
          backgroundColor: item.children.some(child => isActive(child.path)) ? 'white' : colorConfigs.sidebar.bg,
        }}
      >
        <ListItemIcon sx={{ color: item.children.some(child => isActive(child.path)) ? colorConfigs.sidebar.bg : "inherit" }}>{item.sidebarProps.icon}</ListItemIcon>
        <ListItemText primary={item.sidebarProps.displayText} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {item.children.map((child, index) => {
            return child.sidebarProps?.displayText ?
              <ListItemButton
                key={index}
                sx={{
                  p: 0,
                  pl: 8,
                  position: 'relative',
                }}
                onClick={() => navigate(child.path)}
              >
                <ListItemIcon sx={{
                  minWidth: "auto", marginRight: "16px",
                  position: 'absolute',
                  top: -25,
                  left: 30

                }}>
                  <CornerIcon />
                </ListItemIcon>
                <ListItemText sx={{
                  p: 1,
                  fontWeight: 600,
                  fontFamily: 'poppins',
                  borderRadius: "10px 0 0 10px",
                  color: isActive(child.path) ? colorConfigs.sidebar.bg : "#FFF",
                  backgroundColor: isActive(child.path) ? colorConfigs.sidebar.meduimColor : "inherit",
                }} primary={child.sidebarProps?.displayText} />
                {isActive(child?.path) ?
                  <IoIosArrowForward
                    style={{
                      position: 'absolute',
                      top: 15,
                      right: 15,
                      color: isActive(child.path) ? colorConfigs.sidebar.bg : "#FFF",
                    }}
                  /> : ''}
              </ListItemButton> : ''
          })}
        </List>
      </Collapse>
    </>
  );
};

export default SidebarItemCollapse;
