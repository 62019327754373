import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import { store } from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import "./global.css"; // Import the global CSS file
import { CssBaseline } from "@mui/material";
import "leaflet/dist/leaflet.css";
import "leaflet-routing-machine";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
import 'react-phone-input-2/lib/material.css';
import { createRoot } from 'react-dom/client'; // Import from 'react-dom/client'

const title = process.env.REACT_APP_SITE_NAME || 'React App';
document.title = title;
const icon = document.getElementById('icon');
if (icon) {
  if (title === 'Women First') {
    icon.href = 'https://firebasestorage.googleapis.com/v0/b/women-s-first.appspot.com/o/logowithpbg.png?alt=media&token=8331add6-6447-4902-a9fe-21afc075b46a';
  } else if (title === 'Road Runner') {
    icon.href = 'https://firebasestorage.googleapis.com/v0/b/ridewyze.appspot.com/o/logo.png?alt=media&token=0c835763-102c-4758-8ef3-f53a87bf0a85';

  } else {
    icon.href = '../public/favicon.ico';
  }
}


const container =document.getElementById("root");
const root = createRoot(container); // Create a root

root.render(
  <Provider store={store}>
    <CssBaseline />
    <App />
  </Provider>
);

reportWebVitals();
