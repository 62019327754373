import { Box, Button, Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Switch } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import colorConfigs from "../../configs/colorConfigs";
import { auth } from "../../configs/firebase";
import { setRadius, updateForm } from "../../redux/features/appStateSlice";
import { postMethod } from "../Hook/UseCustomApi";
import "./index.css";
import usePrompt from "../Hook/usePrompt";
import Error5xx from "../../components/common/HandelError5xx";

const useStyles = makeStyles({
  rootBox: {
    fontFamily: "inter",
    fontSize: "small",
    marginTop: "10px",
    borderRadius: "8px",
    padding: "6px",
    width: "100%",
    marginBottom: "20px",
    color: colorConfigs?.sidebar?.bg,
    backgroundColor: "#F4F4F4",
  },
  flexContainer: {
    display: "flex",
    gap: "20px",
    fontSize: "16px",
    color: "var(--Dark, #172b4d)",
    fontWeight: 600,
    lineHeight: "140%",
    flexDirection: "row",
  },
  categoryBox: {
    borderRadius: "8px",
    backgroundColor: process.env.REACT_APP_ENV === 'women_first' ? '#F9F6FF' : "#F1FAFF",
    display: "flex",
    flexDirection: "row",
    padding: "12px",
    gap: "10px",
    marginTop: "10px",
  },
  buttonStyle: {
    backgroundColor: colorConfigs?.sidebar?.bg,
  },
  checkbox: {
    color: colorConfigs?.sidebar.bg,
    "&.Mui-checked": {
      color: colorConfigs?.sidebar.bg,
    },
  },
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  radioBox: {
    border: "1px solid lightgray",
    borderRadius: "8px",
    paddingLeft: "10px",
    marginTop: "10px",
    padding: "2px",
  },
  smallLabel: {
    fontSize: "0.875rem", // Adjust the font size as needed
  },
  customRadio: {
    color: colorConfigs?.sidebar?.bg,
    "&.Mui-checked": {
      color: colorConfigs?.sidebar?.bg,
    },
  },
  paymentMethodsBox: {
    borderRadius: "8px",
    backgroundColor: process.env.REACT_APP_ENV === 'women_first' ? '#F9F6FF' : "#F1FAFF",
    padding: "12px",
    marginTop: "20px",
  },
});

const Payment = ({ prev, next }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.appState.formData);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [tipping, setTipping] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(true);
  const [extra_cost_from_driver, setExtra_cost_from_driver] = useState(false);
  const [total_charge_display, setTotal_charge_display] = useState(false);
  const [post_pay, setPost_pay] = useState(false);
  const [cash, setCash] = useState(false);
  const [digital_payment, setDigital_payment] = useState(false);


  //handle error5xx
  const [tryAgain, setTryAgain] = useState();
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [errorCount, setErrorCount] = useState(0);
  const [errorDialogCounter, setErrorDialogCounter] = useState(0);
  const handleError5xx = () => {
    setIsErrorDialogOpen(true);
    setErrorCount(prevCount => {
      const newCount = prevCount + 1;
      if (newCount === 1) {
        setErrorDialogCounter(5);
      } else if (newCount === 2) {
        setErrorDialogCounter(10);
      }
      return newCount;
    });
  };
  const handleCloseErrorDialog = () => {
    setIsErrorDialogOpen(false);
    if (errorCount <= 2) {
      handleErrorCountByClose()
    }

  };
  const handleErrorCountByClose = () => {
    setErrorCount(prevCount => {
      const newCount = prevCount - 1;
      if (newCount === 1) {
        setErrorDialogCounter(5);
      } else if (newCount === 2) {
        setErrorDialogCounter(10);
      }
      return newCount;
    });
  }


  const handleSubmit = async () => {
    if (auth && auth.currentUser) {
      const tokenId = await auth?.currentUser
        ?.getIdToken()
        .then((token) => token);
      const headers = {
        "Content-Type": "application/json",
        Authorization: tokenId,
      };
      dispatch(
        updateForm({
          ...formData,
          tipping: tipping,
          extra_cost_from_driver: extra_cost_from_driver,
          total_charge_display: total_charge_display,
          post_pay: post_pay,
          cash: cash,
          digital_payment: digital_payment
        })
      );
      setLoading(true);
      try {
        // Make the API request to add a new admin

        postMethod({
          url: `zone`,
          body: {
            ...formData,
            tipping: tipping,
            extra_cost_from_driver: extra_cost_from_driver,
            total_charge_display: total_charge_display,
            post_pay: post_pay,
            cash: cash,
            digital_payment: digital_payment
          },
          headers: headers,
          // handleErrorMessage: showSnackbar,
        }).then((res) => {
          setLoading(false);
          navigate("/rates", { replace: true });
          console.log(res, "res");
          dispatch(updateForm({}));
          dispatch(setRadius(0));
        });

        // Fetch the updated list of admins
      } catch (error) {
        console.error("Error adding new zone:", error);
        if (error === "Internal Server Error: Server error occurred.") {
          handleError5xx();
          setTryAgain(() => handleSubmit);
        }
        setLoading(false);
      }
    }
    // Include the additional fees in the form data
  };

  return (
    <>
      {/* handleError5xx */}
      <Error5xx open={isErrorDialogOpen} errCount={errorCount} count={errorDialogCounter} handleClose={handleCloseErrorDialog} tryAgain={tryAgain} />
      <Box className={classes.rootBox}>
        <Typography variant="body1" style={{ fontWeight: "bold" }}>
          Payment details
        </Typography>
      </Box>
      <Box className={classes.paymentMethodsBox}>
        <Typography variant="h6" style={{ fontWeight: "bold" }}>
          Select Payment Methods
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={post_pay}
              onChange={() => {
                setIsFormDirty(true)
                setPost_pay(!post_pay)
              }}
              style={{ color: colorConfigs?.sidebar?.bg, }}
            />
          }
          label="Post Pay"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={cash}
              onChange={() => {
                setIsFormDirty(true)
                setCash(!cash)
              }
              }
              style={{ color: colorConfigs?.sidebar?.bg }}
            />
          }
          label="Cash"
        />
        <br />
        <FormControlLabel
          control={
            <Checkbox
              checked={digital_payment}
              onChange={() => {
                setIsFormDirty(true)
                setDigital_payment(!digital_payment)
              }}
              style={{ color: colorConfigs?.sidebar?.bg }}
            />
          }
          label="Digital Payment"
        />
      </Box>
      <Grid container spacing={2}>
        <Grid item md={4} xs={12}>
          <Box className={classes.categoryBox}>
            <div style={{ display: "flex" }}>
              <Switch
                checked={total_charge_display}
                onChange={() => {
                  setIsFormDirty(true)
                  setTotal_charge_display(!total_charge_display)
                }}
                className="custom-switch"
              />
              <span style={{ marginLeft: 8 }}>
                Total Charge Display
              </span>
            </div>
          </Box>
        </Grid>

        <Grid item md={4} xs={12}>
          <Box className={classes.categoryBox}>
            <div style={{ display: "flex" }}>
              <Switch
                checked={extra_cost_from_driver}
                onChange={() => {
                  setIsFormDirty(true)
                  setExtra_cost_from_driver(!extra_cost_from_driver)
                }}
                className="custom-switch"
              />
              <span style={{ marginLeft: 8 }}>
                Allow Driver to Add Extra Cost
              </span>
            </div>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={4} xs={12}>
          <Box className={classes.categoryBox}>
            <div style={{ display: "flex" }}>
              <Switch
                checked={tipping}
                onChange={() => {
                  setIsFormDirty(true)
                  setTipping(
                    !tipping
                  )
                }
                }
                className="custom-switch"
              />
              <span style={{ marginLeft: 8 }}>
                Allow Customer Tipping
              </span>
            </div>
          </Box>
        </Grid>
      </Grid>

      <div style={{ marginTop: 50, gap: 8 }}>
        <Button
          onClick={prev}
          variant="outlined"
          style={{
            marginRight: 8,
            color: colorConfigs?.sidebar?.bg,
            borderColor: colorConfigs?.sidebar?.bg,
            backgroundColor: "white",
            width: "12%",
          }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          loading={loading}
          style={{
            backgroundColor: colorConfigs?.sidebar?.bg,
            color: "white",
            width: "12%",
          }}
        >
          {loading ? "Loading..." : "Submit"}
        </Button>
      </div>
    </>
  );
};

export default Payment;
