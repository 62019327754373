import React, { useEffect, useState, useRef } from 'react';
import {
    Box,
    Paper,
    Typography,
    TextField,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Avatar,
    Button,
    CircularProgress,
    Divider,
} from '@mui/material';
import colorConfigs from '../../configs/colorConfigs';
import { getMethod } from '../Hook/UseCustomApi';
import CommentIcon from '@mui/icons-material/Comment';
import { selectChat, setLastSupportChat, setOpenChatFromDriver, updateOutChat } from '../../redux/features/appStateSlice';
import { useDispatch } from 'react-redux';
import { closeModal } from '../../redux/features/modalSlice';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment } from '@mui/material';
function CreateChatDialog({ memberType, setSelectedTaskId }) {
    const [members, setMembers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedMember, setSelectedMember] = useState(null);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [isFetching, setIsFetching] = useState(false);
    const listRef = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const fetchMembers = async (searchTerm, page) => {
        setIsFetching(true);
        try {
            const response = await getMethod({
                url: memberType,
                params: {
                    page,
                    limit: 10,
                    search_first_name: searchTerm || '',
                    search_last_name: searchTerm || '',
                },
            });

            if (response?.data?.length > 0) {
                setMembers((prevMembers) => [...prevMembers, ...response.data]);
                setHasMore(true);
            } else {
                setHasMore(false);
            }

            setLoading(false);
            setIsFetching(false);
        } catch (error) {
            console.error(`Error fetching ${memberType === 'driver' ? 'drivers' : 'customer'}:`, error);
            setLoading(false);
            setIsFetching(false);
        }
    };

    useEffect(() => {
        setMembers([]);
        setPage(1);
        setHasMore(true);
        fetchMembers(searchTerm, 1);
    }, [searchTerm]);

    useEffect(() => {
        if (page > 1) {
            fetchMembers(searchTerm, page);
        }
    }, [page]);

    const handleScroll = () => {
        if (!listRef.current) return;
        const { scrollTop, scrollHeight, clientHeight } = listRef.current;
        if (scrollHeight - scrollTop - clientHeight <= 1 && hasMore && !isFetching) {
            setPage((prevPage) => prevPage + 1);
        }
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleMemberSelect = (member) => {
        setSelectedMember(member);
    };


    return (
        <Paper elevation={3} sx={{ padding: 3, width: 600, margin: '0 auto' }}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                New Chat
            </Typography>

            <TextField
                fullWidth
                label="Search For Drivers"
                placeholder={`Search by ${memberType} Name`}
                value={searchTerm}
                onChange={handleSearchChange}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon sx={{ color: "gray" }} />
                        </InputAdornment>
                    ),
                }}
                variant="outlined"
                sx={{
                    my: 2,
                    "& label.Mui-focused": { color: "rgba(0, 0, 0, 0.6)" },
                    "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                            borderColor: "#A0AEC0",
                        },
                        borderRadius: "10px",
                        height: "52px",
                    },
                }}
            />
            <Box
                ref={listRef}
                sx={{
                    height: 300,
                    overflowY: 'auto',
                    position: 'relative',
                }}
                onScroll={handleScroll}
            >
                {members.length === 0 && !loading ? (
                    <Typography sx={{ textAlign: 'center', mt: 2 }} >No {memberType === 'driver' ? 'drivers' : 'customer'} found</Typography>
                ) : (
                    <List>
                        {members.map((member) => (
                            <React.Fragment key={member._id}>
                                <ListItem
                                    button
                                    sx={{
                                        borderRadius: '8px',
                                        marginBottom: '8px',
                                        backgroundColor:
                                            selectedMember?._id === member?._id
                                                ? colorConfigs?.sidebar?.ligthBg
                                                : 'white',
                                        '&:hover': {
                                            backgroundColor: colorConfigs?.sidebar?.ligthBg,
                                        },
                                    }}
                                >
                                    <ListItemAvatar>
                                        <Avatar src={member?.profile_picture || ''} alt={member?.first_name} />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Typography sx={{ fontWeight: 'bold', fontSize: '1rem' }}>
                                                {member?.first_name} {member?.last_name}
                                            </Typography>
                                        }
                                    />
                                    <Box>
                                        <Button
                                            variant="contained"
                                            style={{
                                                backgroundColor: colorConfigs.sidebar.bg,
                                                color: "white",
                                                fontSize: "12px",
                                                fontFamily: "Poppins",
                                                textTransform: "capitalize",
                                                borderRadius: "30px",
                                                padding: "4px 12px",
                                            }}
                                            onClick={() => {
                                                dispatch(selectChat({
                                                    ...member, ...(memberType === 'driver'
                                                        ? { driver: `admin_${member?.fb_uid}` }
                                                        : { customer: `admin_${member?.fb_uid}` })
                                                    , type: memberType,
                                                    chat_type: memberType,
                                                    user_profile: member?.profile_picture || '', user_name: `${(member?.first_name || '') + " " + (member?.last_name || '')}`
                                                }));
                                                dispatch(setLastSupportChat({
                                                    ...member,
                                                    ...(memberType === 'driver'
                                                        ? { driver: `admin_${member?.fb_uid}` }
                                                        : { customer: `admin_${member?.fb_uid}` }),
                                                    type: memberType,
                                                    chat_type: memberType,
                                                    user_profile: member?.profile_picture || '', user_name: `${(member?.first_name || '') + " " + (member?.last_name || '')}`
                                                }));
                                                dispatch(setOpenChatFromDriver(true));
                                                dispatch(updateOutChat(false));
                                                dispatch(closeModal());
                                                setSelectedTaskId(`admin_${member?.fb_uid}`);

                                            }}
                                        >
                                            {" "}
                                            <CommentIcon
                                                style={{ width: "15px", marginRight: "8px" }}
                                            />{" "}
                                            Chat
                                        </Button>
                                    </Box>
                                </ListItem>
                                <Divider />
                            </React.Fragment>
                        ))}
                        {loading && (
                            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                                <CircularProgress size={24} />
                            </Box>
                        )}
                    </List>
                )}
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                <Button onClick={() => dispatch(closeModal())} variant="outlined" sx={{ borderRadius: '20px', width: '30%' }}>
                    Cancel
                </Button>
            </Box>
        </Paper>
    );
}

export default CreateChatDialog;
