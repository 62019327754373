import * as React from "react"
import { memo } from "react"
import colorConfigs from "../../../configs/colorConfigs"
const SvgComponent = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={50}
        height={50}
        fill="none"
        {...props}
    >
        <circle cx={25} cy={25} r={25} fill={colorConfigs?.sidebar?.ligthBg} />
        <path
            fill={colorConfigs?.sidebar?.bg}
            d="M25 16a9 9 0 0 0-7.906 13.308l-1.065 3.735a.75.75 0 0 0 .957.918l3.507-1.169A9 9 0 1 0 25 16Zm-3 7.5a.75.75 0 0 1 .75-.75h4.5a.75.75 0 1 1 0 1.5h-4.5a.75.75 0 0 1-.75-.75Zm.75 2.25h3a.75.75 0 1 1 0 1.5h-3a.75.75 0 1 1 0-1.5Z"
        />
    </svg>
)
const ChatMessage = memo(SvgComponent)
export default ChatMessage
