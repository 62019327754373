import * as React from "react"
import { memo } from "react"
const SvgComponent = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={29}
        height={29}
        fill="none"
        {...props}
    >
        <g clipPath="url(#a)">
            <path
                fill="#FFF"
                d="M27.563 11.227h-1.26l-3.241-9.51a2.376 2.376 0 0 0-.689-1.053A1.57 1.57 0 0 0 21.356.27H7.636A1.57 1.57 0 0 0 6.62.664a2.376 2.376 0 0 0-.689 1.052l-3.24 9.511H1.43c-.248 0-.486.128-.66.357a1.426 1.426 0 0 0-.274.86c0 .323.098.633.273.861.175.229.413.357.66.357h.934v12.174c0 .646.196 1.265.546 1.721.35.457.825.713 1.32.713h2.8c.495 0 .97-.256 1.32-.713.35-.456.547-1.075.547-1.721V23.4h11.2v2.435c0 .646.197 1.265.547 1.721.35.457.825.713 1.32.713h2.8c.495 0 .97-.256 1.32-.713.35-.456.546-1.075.546-1.721V13.662h.934c.247 0 .485-.128.66-.357.175-.228.273-.538.273-.86 0-.324-.098-.633-.273-.861-.175-.229-.413-.357-.66-.357ZM7.636 2.705h13.72l2.904 8.522H4.732l2.904-8.522Zm-.607 23.13h-2.8v-2.434h2.8v2.435Zm14.934 0v-2.434h2.8v2.435h-2.8Zm2.8-4.869H4.229v-7.304h20.534v7.304ZM6.096 17.314c0-.323.098-.633.273-.86.176-.23.413-.357.66-.357h1.867c.248 0 .485.128.66.356.175.229.273.538.273.861 0 .323-.098.633-.273.86-.175.23-.412.357-.66.357H7.03c-.247 0-.484-.128-.66-.356a1.427 1.427 0 0 1-.273-.861Zm13.067 0c0-.323.098-.633.273-.86.175-.23.413-.357.66-.357h1.867c.247 0 .485.128.66.356.175.229.273.538.273.861 0 .323-.098.633-.273.86-.175.23-.413.357-.66.357h-1.867c-.247 0-.485-.128-.66-.356a1.427 1.427 0 0 1-.273-.861Z"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M.496.27h28v28h-28z" />
            </clipPath>
        </defs>
    </svg>
)
const CarOutlineWhite = memo(SvgComponent)
export default CarOutlineWhite
