import React from 'react'

function NotificationBody({ notification }) {
    const body = (type) => {
        switch (type) {
            case 'new_message':
                return `new message from ${notification?.option?.name} has sent you a message. Reply now`
            case 'messaging_with_admin':
                return `${notification?.option?.type === 'driver' ? "Driver " : 'Passenger '} ${notification?.option?.name} has sent you a message. Reply now`
            case 'messaging':
                return `new message from ${notification?.title} has sent you a message. Reply now`
            case 'new_customer_ride':
                return "The customer has created a ride, but it has not been assigned yet."
            case 'new_admin_ride':
                return `The admin has created a new ride.`;
            case 'reminder_scheduled_ride':
                return `The customer has created a scheduled ride`
            case 'reminder_scheduled_ride_before_start':
                return `The customer has created a scheduled ride`
            case 'ride_assigned':
                return `assigned to Driver ${notification?.option?.name}. View details.`
            case 'driver_arrived':
                return ``
            case 'ride_completed':
                return `Ride #${notification?.option?.name} completed. View details.`
            case 'ride_cancel':
                return `Ride #${notification?.option?.name} canceled. View details.`
            case 'panic_button_driver':
                return `Driver ${notification?.option?.name} triggered a security alert. Immediate action required.`
            case 'panic_button_customer':
                return `Customer ${notification?.option?.name} triggered a security alert. Immediate action required.`
            case 'create_new_admin':
                return `New admin account has been created. View details.`
            case 'incoming_money':
                // return `Payment of ${notification?.option?.amount} has been successfully received via ${notification?.option?.name}.`
                return
            case 'support':
                return 'Passenger Ahmad H. has sent you a message. Reply now'
            case 'outcoming_money':
                // return `An outgoing payment of ${notification?.option?.amount} has been processed via ${notification?.option?.name}`
                return
            default:
                return ''
        }
    }

    return (
        <>
            {body(notification?.object_type)}
        </>
    )
}

export default NotificationBody