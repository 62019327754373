import {
    Button,
    Grid,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import colorConfigs from "../../configs/colorConfigs";
import {
    setCardsData, setBankAccStatus
} from "../../redux/features/appStateSlice";
import { getMethod } from "../Hook/UseCustomApi";
import PaymentsWrapper from "./Payments";
import Profile from "./Profile";


const Settings = () => {
    const [activeButton, setActiveButton] = useState("generalInformation");
    const userData = useSelector((state) => state.appState.userData);
    const dispatch = useDispatch();
    const handleButtonClick = (button) => {
        setActiveButton(button);
    };
    const checkBankAcc = async () => {
        getMethod({
            url: "payment/admin/bankAccount/projectSetting",
        })
            .then((response) => {
                response?.data?.stripe_account_id ? response?.data?.verified_stripe_account ?
                    dispatch(setBankAccStatus("verified")) : dispatch(setBankAccStatus("Unverified"))
                    :
                    dispatch(setBankAccStatus("add"))
            })
            .catch((err) => {
                console.error("Error fetching more stores:", err);
            });
    };


    const fetchCards = async () => {
        getMethod({
            url: "payment/admin/creditCard",
        })
            .then((response) => {
                dispatch(setCardsData(response.data));
            })
            .catch((err) => console.error("Error fetching more stores:", err))

    };
    useEffect(() => {
        fetchCards();
        checkBankAcc();
    }, []);

    return (
        <Box sx={{
            padding: "10px",
            mt: -9
        }}>
            <Grid
                container
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingLeft: "30px",
                    paddingTop: "30px",
                    width: "100%",
                    backgroundColor: "white",
                    borderTopLeftRadius: "22px",
                    borderTopRightRadius: "22px"
                }}
            >
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={7}
                    lg={5}
                    xl={3.5}
                    style={{
                        border: `1.5px solid ${colorConfigs?.sidebar?.bg}`,
                        height: "53px",
                        borderRadius: "8px",
                        marginTop: "2px",
                        paddingBottom: "10px",
                        paddingTop: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                // sx={{
                //     marginBottom: { lg: "0", xs: "20px", sm: "20px" },
                // }}
                >
                    <Grid item xs={7} style={{ display: "flex", justifyContent: "center" }}>
                        <Button
                            variant={
                                activeButton === "generalInformation" ? "contained" : "text"
                            }
                            style={{
                                textTransform: 'none',
                                color:
                                    activeButton === "generalInformation"
                                        ? "white"
                                        : colorConfigs?.sidebar?.bg,
                                backgroundColor:
                                    activeButton === "generalInformation"
                                        ? colorConfigs?.sidebar?.bg
                                        : "transparent",
                                fontWeight:
                                    activeButton === "generalInformation" ? "bold" : "normal",
                            }}
                            onClick={() => handleButtonClick("generalInformation")}
                        >
                            General Information
                        </Button>
                    </Grid>

                    {/* <Grid item xs={4} style={{ display: "flex", justifyContent: "center" }}>
                        <Button
                            variant={
                                activeButton === "Payout" ? "contained" : "text"
                            }
                            style={{
                                textTransform: 'none',
                                color:
                                    activeButton === "Payout"
                                        ? "white"
                                        : colorConfigs?.sidebar?.bg,
                                backgroundColor:
                                    activeButton === "Payout"
                                        ? colorConfigs?.sidebar?.bg
                                        : "transparent",
                                fontWeight: activeButton === "Payout" ? "bold" : "normal",

                                whiteSpace: "normal",
                                lineHeight: 2,
                            }}
                            onClick={() => handleButtonClick("Payout")}
                        >
                            Payout
                        </Button>
                    </Grid> */}

                    <Grid item xs={4} style={{ display: "flex", justifyContent: "center" }}>
                        <Button
                            variant={
                                activeButton === "Payments" ? "contained" : "text"
                            }
                            style={{
                                textTransform: 'none',
                                color:
                                    activeButton === "Payments"
                                        ? "white"
                                        : colorConfigs?.sidebar?.bg,
                                backgroundColor:
                                    activeButton === "Payments"
                                        ? colorConfigs?.sidebar?.bg
                                        : "transparent",
                                fontWeight: activeButton === "Payments" ? "bold" : "normal",
                                whiteSpace: "normal",
                                lineHeight: 2,
                            }}
                            onClick={() => handleButtonClick("Payments")}
                        >
                            Payments
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            {activeButton === "generalInformation" && (<Profile data={userData} />)}
            {activeButton === "Payments" && (<PaymentsWrapper getAllCards={fetchCards} />)}
        </Box>
    );
};

export default Settings;