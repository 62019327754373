import HelpCenter from "../../components/support/HelpCenter";


export default function DriverHelpCenter() {


    return (
        <>
            <HelpCenter status={"drivers"} />
        </>
    );
}
