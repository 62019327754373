import { Box, FormControl, Grid, TextField, ThemeProvider, Typography, createTheme } from "@mui/material";
import { styled } from "@mui/system";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { GoDotFill } from "react-icons/go";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import "yup-phone-lite";
import AddressAutoComplete from "../../../components/common/AddressAutoComplete";
import colorConfigs from "../../../configs/colorConfigs";
import { setAddNewRide } from "../../../redux/features/appStateSlice";
import { Switch } from "antd";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { IoCalendarClearOutline } from "react-icons/io5";
import { BsExclamationCircleFill } from "react-icons/bs";

const StyledGridItem = styled(Grid)(({ theme }) => ({
  padding: '5px',
}));

const inputStyle = {
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#BBC1CE",
      borderWidth: 1,
      borderRadius: "8px",
    },
    "&:hover fieldset": {
      borderColor: "#BBC1CE",
      borderWidth: 1,
      borderRadius: "8px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#BBC1CE",
      borderWidth: 2,
      borderRadius: "8px",
    },
    input: {
      "&::placeholder": {
        color: "#BBC1CE",
        opacity: 1,
        fontSize: 14,
      },
      padding: "10px",
    },
  },
  "& label": {
    color: "#7A869A",
    lineHeight: "0.8375em",
  },
  "& label.Mui-focused": {
    color: "#7A869A",
    fontSize: "17px",
    fontWeight: "500",
  },
};
const defaultMaterialTheme = createTheme({
  components: {
    MuiPickersDay: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: colorConfigs?.sidebar?.bg,
            color: '#ffffff',
            '&:hover': {
              backgroundColor: colorConfigs?.sidebar?.bg,
            },
          },
          '&.Mui-selected.Mui-focusVisible': {
            backgroundColor: colorConfigs?.sidebar?.bg,
          },
          '&:hover': {
            backgroundColor: 'rgba(0, 100, 150, 0.08)',
          },
        },
        current: {
          color: colorConfigs?.sidebar?.bg,
          backgroundColor: colorConfigs?.sidebar?.bg,

        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fill: colorConfigs?.sidebar?.bg, // Customize color for the calendar icon
        },
      },
    },
  },
});

const Title = styled(Typography)({
  fontSize: "14px",
  fontWeight: "600",
  color: colorConfigs,
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: "5px",
  marginBottom: "12px",
});

const RiderInfoForm = ({
  handleBlur,
  handleChange,
  values,
  errors,
  setAddressToShow,
  setAddressToShowDrop,
  handleSelectPickup,
  handleSelectDropOff,
  addressToShow,
  addressToShowDrop,
  state,
  setState,
  zipCode,
  setZipCode,
  stateDrop,
  setStateDrop,
  zipCodeDrop,
  setZipCodeDrop,
  formErrors,
  city,
  cityDrop,
  setCityDrop,
  setCity,
  setFieldAddress,
  setFieldValue,
  setSelectedDateTime,
  selectedDateTime,
  isScheduled,
  setIsScheduled
}) => {
  const dispatch = useDispatch();
  const [country, setCountry] = useState('ca');
  const licAndConfig = useSelector((state) => state.licencesAndConfiguration);

  const handleChangeSchedule = (checked) => {
    setIsScheduled(checked);
    if (!checked) setSelectedDateTime(null);
    setIsScheduled(checked);
    dispatch(setAddNewRide({ isScheduled: checked }));
  };

  const validationSchema = Yup.object().shape({
    phone_number: Yup.string().phone(country, true, "Phone number is invalid").required("Phone number is required"),
    pickup_zip_code: Yup.string().required("Postal Code is required").min(1, "Postal Code cannot be empty"),
    dropoff_zip_code: Yup.string().required("Postal Code is required").min(1, "Postal Code cannot be empty"),
    selectedDateTime: Yup.mixed().nullable().when('isScheduled', {
      is: true,
      then: Yup.date().required("Please select a date and time"),
    })
  });

  const formik = useFormik({
    initialValues: {
      phone_number: values.phone_number || "",
      pickup_zip_code: zipCode || "",
      dropoff_zip_code: zipCodeDrop || "",
    },
    validationSchema,
  });

  const handleInputChange = (e) => {
    handleChange(e);
    const { name, value } = e.target;
    dispatch(setAddNewRide({ ...values, [name]: value }));
  };

  const handlePhoneChange = (value, countryData) => {
    console.log("Selected Country:", countryData);
    if (formik.errors.phone_number) {
      dispatch(setAddNewRide({ ...values, phone_number: "unvalidNumber" }));
      setFieldValue('phone_number', 'unvalidNumber');
      formik.setFieldValue('phone_number', value);
      setCountry(countryData.countryCode.toUpperCase());
    } else {
      formik.setFieldValue('phone_number', value);
      dispatch(setAddNewRide({ ...values, phone_number: value }));
      setFieldValue('phone_number', value);
      setCountry(countryData.countryCode.toUpperCase());
    }
  };

  useEffect(() => {
    if (formik.errors.phone_number) {
      dispatch(setAddNewRide({ ...values, phone_number: "unvalidNumber" }));
      setFieldValue('phone_number', 'unvalidNumber');
    } else {
      dispatch(setAddNewRide({ ...values, phone_number: formik.values.phone_number }));
      setFieldValue('phone_number', formik.values.phone_number);
    }
  }, [formik.errors.phone_number]);

  return (
    <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 2 }}>
      <Box sx={{ mb: 1 }}>
        <Title>
          <GoDotFill size={12} />
          Passenger Info
        </Title>
      </Box>
      <Grid container spacing={1}>
        <StyledGridItem item md={6} xs={12} >
          <FormControl fullWidth error={!!formik.errors.phone_number}>
            <PhoneInput
              autoFormat={true}
              enableSearch={true}
              country={'ca'}
              specialLabel={'phone*'}
              value={formik.values.phone_number}
              onChange={handlePhoneChange}
              inputStyle={{
                width: '100%',
                borderColor: '#BBC1CE',
                borderRadius: '8px',
                height: '46px',
              }}
              buttonStyle={{
                borderColor: '#BBC1CE',
                borderRadius: '8px 0 0 8px',
              }}
              containerStyle={{
                width: '100%',
              }}
              inputProps={{
                name: 'phone_number',
                required: true,
                autoFocus: true,
                label: 'sss'
              }}
            />
          </FormControl>
        </StyledGridItem>
        <StyledGridItem item md={3} xs={12} >
          <FormControl fullWidth error={!!errors.first_name}>
            <TextField
              sx={inputStyle}
              fullWidth
              label="First Name"
              name="first_name"
              onChange={handleInputChange}
              onBlur={handleBlur("first_name")}
              required
              value={values.first_name}
              variant="outlined"
              placeholder="First Name"
              focused
            />
          </FormControl>
        </StyledGridItem>
        <StyledGridItem item md={3} xs={12} >
          <FormControl fullWidth error={!!errors.last_name}>
            <TextField
              sx={inputStyle}
              fullWidth
              label="Last Name"
              name="last_name"
              onChange={handleInputChange}
              onBlur={handleBlur("last_name")}
              required
              value={values.last_name}
              variant="outlined"
              placeholder="Last Name"
              focused
            />
          </FormControl>
        </StyledGridItem>
        <StyledGridItem item md={12} xs={12} >
          <Box sx={{ mb: 1 }}>
            <Title>
              <GoDotFill size={12} />
              Pickup Location
            </Title>
          </Box>
          <Grid container spacing={2}>
            <StyledGridItem item md={12} xs={12}>
              <AddressAutoComplete
                address={addressToShow}
                setAddress={setAddressToShow}
                handleSelect={(address, placeId, suggestion) =>
                  handleSelectPickup(address, placeId, suggestion, setFieldValue)
                }
                setFieldAddress={setFieldAddress}
                types="address"
                label="Address"
                required={true}
              />
            </StyledGridItem>
            <StyledGridItem item md={6} xs={12}>
              <FormControl fullWidth error={!!errors.pickup_state}>
                <TextField
                  sx={inputStyle}
                  fullWidth
                  label="State"
                  name="pickup_state"
                  onChange={(e) => {
                    setState(e.target.value);
                    handleInputChange(e);
                  }}
                  onBlur={handleBlur("pickup_state")}
                  value={state}
                  variant="outlined"
                  placeholder="State"
                  focused
                />
              </FormControl>
            </StyledGridItem>
            <StyledGridItem item md={6} xs={12}>
              <FormControl fullWidth error={!!errors.pickup_zip_code}>
                <TextField
                  sx={inputStyle}
                  fullWidth
                  label="Postal Code"
                  name="pickup_zip_code"
                  onChange={(e) => {
                    setZipCode(e.target.value);
                    handleInputChange(e);
                  }}
                  onBlur={handleBlur("pickup_zip_code")}
                  value={zipCode}
                  variant="outlined"
                  placeholder="Postal Code"
                  focused
                  inputProps={{ maxLength: 6 }}
                />
              </FormControl>
            </StyledGridItem>
          </Grid>
        </StyledGridItem>
        <StyledGridItem item md={12} xs={12} >
          <Box sx={{ mb: 1 }}>
            <Title>
              <GoDotFill size={12} />
              Dropoff Location
            </Title>
          </Box>
          <Grid container spacing={2}>
            <StyledGridItem item md={12} xs={12}>
              <AddressAutoComplete
                address={addressToShowDrop}
                setAddress={setAddressToShowDrop}
                handleSelect={(address, placeId, suggestion) =>
                  handleSelectDropOff(address, placeId, suggestion, setFieldValue)
                }
                types="address"
                label="Address"
                required={true}
              />
            </StyledGridItem>
            <StyledGridItem item md={6} xs={12}>
              <FormControl fullWidth error={!!errors.dropoff_state}>
                <TextField
                  sx={inputStyle}
                  fullWidth
                  label="State"
                  name="dropoff_state"
                  onChange={(e) => {
                    setStateDrop(e.target.value);
                    handleInputChange(e);
                  }}
                  onBlur={handleBlur("dropoff_state")}
                  value={stateDrop}
                  variant="outlined"
                  placeholder="State"
                  focused
                />
              </FormControl>
            </StyledGridItem>
            <StyledGridItem item md={6} xs={12}>
              <FormControl fullWidth error={!!errors.dropoff_zip_code}>
                <TextField
                  sx={inputStyle}
                  fullWidth
                  label="Postal Code"
                  name="dropoff_zip_code"
                  inputProps={{ maxLength: 6 }}
                  onChange={(e) => {
                    setZipCodeDrop(e.target.value);
                    handleInputChange(e);
                  }}
                  onBlur={handleBlur("dropoff_zip_code")}
                  value={zipCodeDrop}
                  variant="outlined"
                  placeholder="Postal Code"
                  focused
                />
              </FormControl>
            </StyledGridItem>
          </Grid>
        </StyledGridItem>
        <StyledGridItem item md={12} xs={12}>
          {licAndConfig?.licenses?.scheduled_ride && (
            <Grid item md={12} xs={12}>
              <Box
                sx={{
                  marginTop: 1,
                  border: "2px solid #bbc1ce",
                  borderRadius: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  px: 2,
                  py: 1.5,
                  marginBottom: 1,
                }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1, color: colorConfigs?.sidebar?.bg }}>
                  <IoCalendarClearOutline size={18} />
                  <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
                    Schedule Ride
                  </Typography>
                  <BsExclamationCircleFill size={18} style={{ ml: 2, color: '#88939D' }} />
                </Box>
                <Box className="categoryBox">
                  <div style={{ display: "flex" }}>
                    <Switch
                      checked={isScheduled}
                      onChange={(e) => handleChangeSchedule(e)}
                      className="custom-switch"
                    />
                    <span style={{ marginLeft: 8 }}>
                      {"Schedule"}
                    </span>
                  </div>
                </Box>
              </Box>
            </Grid>
          )}
          {isScheduled && (
            <Grid item md={12} xs={12}>
              <ThemeProvider theme={defaultMaterialTheme}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DateTimePicker
                    sx={{
                      ...inputStyle, mt: 1, width: '100%',
                      '& .css-1oftbfi-MuiFormLabel-root-MuiInputLabel-root': {
                        lineHeight: '0.9375em !important',
                      },

                    }}

                    renderInput={(props) => (
                      <TextField
                        {...props}
                        inputProps={{
                          ...props.inputProps,
                        }}
                      />
                    )}
                    label="Select Date & Time"
                    value={selectedDateTime}
                    onChange={(newValue) => {
                      setSelectedDateTime(newValue);
                      dispatch(setAddNewRide({ selectedDateTime: newValue }));
                    }}
                    disablePast
                  />
                </LocalizationProvider>

              </ThemeProvider>
            </Grid>
          )}
          {formik.errors.selectedDateTime && (
            <Typography variant="caption" color="error">
              {formik.errors.selectedDateTime}
            </Typography>
          )}
        </StyledGridItem>

      </Grid>
    </Box>
  );
};

export default RiderInfoForm;
