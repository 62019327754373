import React from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import { Paper, TextField, List, ListItem, ListItemText } from '@mui/material';
import { useSelector } from 'react-redux';

const fieldStyle = {
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#BBC1CE",
      borderWidth: 1,
      borderRadius: "8px",
      fontSize: 14,
    },
    "&:hover fieldset": {
      borderColor: "#BBC1CE",
      borderWidth: 1,
      borderRadius: "8px",
      lineHeight: '16px',
    },
    "&.Mui-focused fieldset": {
      borderColor: "#BBC1CE",
      borderWidth: 1,
      borderRadius: "8px",
      fontSize: 14,
      lineHeight: '16px',
    },
    input: {
      "&::placeholder": {
        color: "#3A3541DE",
        fontSize: 12,
      },
      height: "46px",
      padding: "0px 10px",
      lineHeight: '16px',
    },
  },
  "& label.Mui-focused": {
    padding: "0px",
    color: "#172B4D !important",
    fontSize: "17px !important",
    fontWeight: "500 !important",
    fontSize: 14,
  },
  "& .css-1oftbfi-MuiFormLabel-root-MuiInputLabel-root": {
    lineHeight: '16px',
  },
  lineHeight: '16px',
  fontSize: 14,
};

const AddressAutoComplete = ({ handleSelect, address, setAddress, types, label, placeholder, required = false, focused = false, setFieldAddress }) => {
  const licAndConfig = useSelector((state) => state.licencesAndConfiguration);
  const allowedCountries = licAndConfig?.config?.countries_restrictions || ['CA']; // Fallback to 'CA' if no restrictions provided

  return (
    <PlacesAutocomplete
      value={address || ''}
      onChange={value => {
        setAddress(value);
        if (setFieldAddress) {
          setFieldAddress('address', undefined);
          setFieldAddress('addressDrop', undefined);
        }
      }}
      onSelect={(address, placeId, suggestion) => handleSelect(address, placeId, suggestion)}
      searchOptions={{
        // types: [types],
        componentRestrictions: {
          country: allowedCountries.length === 1 ? allowedCountries[0] : allowedCountries, // Use list if multiple, or single if only one
        },
      }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div style={{ position: 'relative' }}>
          <TextField
            sx={fieldStyle}
            fullWidth
            value={address || ''}
            focused={focused}
            required={required}
            variant="outlined"
            {...getInputProps({
              placeholder: placeholder || 'Please enter address',
              label: label || 'Address',
              autoComplete: 'off',
            })}
          />
          {loading && <div>Loading...</div>}
          {address && suggestions.length > 0 && (
            <Paper style={{ position: 'absolute', zIndex: 9999, width: '100%', maxHeight: '200px', overflowY: 'auto' }}>
              <List>
                {suggestions.map(suggestion => {
                  const style = suggestion.active
                    ? { backgroundColor: "#fafafa", cursor: "pointer", fontSize: 12 }
                    : { backgroundColor: "#ffffff", cursor: "pointer", fontSize: 12 };
                  return (
                    <ListItem
                      {...getSuggestionItemProps(suggestion, { style })}
                      key={suggestion.placeId}
                    >
                      <ListItemText sx={{ fontSize: 12 }} primary={suggestion.description} />
                    </ListItem>
                  );
                })}
              </List>
            </Paper>
          )}
        </div>
      )}
    </PlacesAutocomplete>
  );
}

export default AddressAutoComplete;
