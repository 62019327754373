import {
    Box,
    Typography,
    Grid,
    Button, Dialog, MenuItem, Select,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions
} from '@mui/material';
import React from 'react';
import colorConfigs from "../../configs/colorConfigs";
import Status from "../../pages/status/status";
import { useSelector, useDispatch } from "react-redux";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { NewFormatPhoneNumber } from "../../utils/localization/NewPhoneUtils";
import { postDataRedux, putDataRedux } from '../../redux/features/appStateSlice';
import { putMethod } from '../../pages/Hook/UseCustomApi';
import Loading from "react-fullscreen-loading";
import { closeModal, openModal } from '../../redux/features/modalSlice';
import TicketChat from './TicketChat';
import { formatDate } from "../../utils/localization/dateTimeUtils";


const card = {
    width: "100%",
    padding: "15px",
    borderRadius: "8px",
    backgroundColor: "#FAFAFA",
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    marginTop: "15px",
}
const descriptionCard = {
    width: "100%",
    // height: "100px",
    padding: "15px",
    borderRadius: "8px",
    backgroundColor: "#FAFAFA",
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    marginTop: "15px",

}
const container = {
    display: "flex",
    flexDirection: "row",
    gap: "25px",
    justifyContent: "space-between",
}
const titel = {
    fontWeight: "700",
    fontFamily: "Poppins",
    fontSize: "15px",
    lineHeight: "21px",
    color: colorConfigs?.sidebar?.bg
}
const key = {
    display: "flex",
    fontWeight: "400",
    fontFamily: "Poppins",
    fontSize: "14px",
    lineHeight: "17px",
    color: "#7A869A",
    marginTop: "10px",
}
const value = {
    display: "flex",
    alignItems: "center",
    justifyContent: 'start',
    paddingLeft: "10px",
    fontWeight: "600",
    fontFamily: "Poppins",
    fontSize: "14px",
    lineHeight: "18px",
    color: "#495974",
}
const emailValue = {
    display: "flex",
    paddingLeft: "10px",
    fontWeight: "600",
    fontFamily: "Poppins",
    fontSize: "14px",
    lineHeight: "18px",
    color: "#495974",
    textDecoration: "underline"

}
const nameValue = {
    paddingLeft: "10px",
    fontWeight: "600",
    fontFamily: "Poppins",
    fontSize: "16px",
    lineHeight: "18px",
    color: "#172B4D",
}
const nameImg = {
    marginLeft: "10px",
    marginTop: "-6px",
    width: "32px",
    height: "32px",
    borderRadius: "50%",
}


const TicketDetails = () => {

    const countryCode = useSelector((state) => state.country.selectedCountry);
    const details = useSelector((state) => state.appState.details);
    const admin = useSelector((state) => state.appState.userData);
    console.log(details);

    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    const [selectedValue, setSelectedValue] = useState("new");
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [startIndex, setStartIndex] = useState(0);
    const [loading, setLoading] = useState("");

    const navigate = useNavigate();
    const dispatch = useDispatch();


    const images = details?.images?.slice(0, 3)?.map((image) => ({
        original: image,
        thumbnail: image,
    }));

    const handleImageClick = (index) => {
        setStartIndex(index);
        setIsDialogOpen(true);
    };

    const handleClose = () => {
        setIsDialogOpen(false);
    };

    const capitalizeFirstLetter = (str) => {
        return str?.charAt(0)?.toUpperCase() + str?.slice(1);
    };

    const getDateForamted = (date) => {
        if (date !== null && date !== undefined) {
            const dateObject = new Date(date);
            // Formatting options
            const options = {
                day: "numeric",
                month: "short",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
            };
            // Applying formatting and getting the formatted date string
            const formattedDate = dateObject.toLocaleDateString("en-US", options);
            return formattedDate;
        }

        return null;
    };

    const getBackgroundColor = (status) => {
        switch (status) {
            case 'new':
                return '#F0FAFF';
            case 'inProgress':
                return '#FAF6E7';
            case 'resolved':
                return '#E8FAE7';
            default:
                return 'transparent';
        }
    };
    const getColor = (status) => {
        switch (status) {
            case 'new':
                return '#206CFF';
            case 'inProgress':
                return '#DD9200';
            case 'resolved':
                return '#01973D';
            default:
                return 'transparent';
        }
    };

    useEffect(() => {
        if (details?.status) {
            setSelectedValue(details?.status);
        }
    }, []);

    const changeStatus = (status) => {

        if (status === "inProgress" || status === "resolved") {
            const url = status === "inProgress" ? `admin/help/ticket/${details?._id}/assigned` : `admin/help/ticket/${details?._id}/resolved`
            setLoading(true)
            putMethod({
                url: url,
                body: {
                    admin_id: admin?._id
                }
            })
                .then((response) => {
                    console.log(response);
                    dispatch(putDataRedux({ stateName: 'details', data: { ...details, status: status } }));
                    setSelectedValue(status)
                    setLoading(false)
                })
                .catch((error) => {
                    console.log("Error on getting active ride:", error);
                });
        }
    }


    const handleChange = (value) => {
        changeStatus(value)
    };

    const handleCloseConfirmationDialog = (confirm) => {
        setOpenConfirmationDialog(false);
        if (confirm) {
            handleChange(details?.status === "new" ? "inProgress" : "resolved");
        }
    };

    const selectStyle = {
        marginLeft: "10px",
        backgroundColor: getBackgroundColor(details?.status),
        borderRadius: "40px",
        height: "45px",
        width: "130px",
        color: getColor(details?.status),
        fontSize: "15px",
        fontWeight: 600,
        lineHeight: "45px",
        fontFamily: "Poppins",
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
    }
    return (
        <>
            {/* confirmation dialog */}
            <Dialog
                open={openConfirmationDialog}
                onClose={() => handleCloseConfirmationDialog(false)}
                aria-labelledby="confirmation-dialog-title"
                aria-describedby="confirmation-dialog-description"
                sx={{
                    '& .MuiDialog-paper': {
                        borderRadius: '10px',
                    },
                }}
            >
                <DialogTitle id="confirmation-dialog-title">
                    {"Confirm Action"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirmation-dialog-description">
                        {details?.status === "new" ? "Are you sure you want to assign this ticket to yourself ?" :
                            "Are you sure you want to mark this ticket as resolved ?"}
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{
                    marginRight: "10px"
                }}>
                    <Button
                        onClick={() => handleCloseConfirmationDialog(false)}
                        variant="contained"
                        sx={{
                            backgroundColor: 'white',
                            color: colorConfigs.sidebar?.bg,
                            borderRadius: '10px',
                            width: "25%",
                            padding: '10px 20px',
                            '&:hover': {
                                backgroundColor: 'white',
                            },
                            marginBottom: "10px"
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => handleCloseConfirmationDialog(true)}
                        variant="contained"
                        sx={{
                            backgroundColor: colorConfigs.sidebar?.bg,
                            color: 'white',
                            borderRadius: '10px',
                            width: "25%",
                            padding: '10px 20px',
                            marginBottom: "10px"
                        }} autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            {/* Ticket Management */}
            <Grid
                item
                xs={12}
                sm={12}
                md={9}
                lg={9}
                xl={9}
                style={{
                    display: "flex",
                    gap: "14px",
                    marginTop: "-68.5px",
                    marginBottom: "30px",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                }}>
                <Box style={{
                    display: "flex",
                    flexDirection: 'row',

                    gap: "14px",
                }}>
                    <span style={{
                        color: "#FFFFFF",
                        fontFamily: "Outfit",
                        fontSize: "24px",
                        fontWeight: "700",
                        lineHeight: "33px",

                    }}>
                        Ticket Management
                    </span>
                </Box>
            </Grid>
            {/* topbar */}
            <Box>
                <Grid style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "14px",
                    justifyContent: "space-between",
                }}>
                    <Grid style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "14px",
                        justifyContent: "space-between",
                    }}>
                        <Typography sx={{
                            color: '#172B4D',
                            fontFamily: "Poppins",
                            fontSize: "18px",
                            fontWeight: "700",
                            lineHeight: "27px",
                        }}>Ticket ID # {details?.short_id}</Typography>
                        <Typography sx={{
                            color: '#495974',
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            fontWeight: "500",
                            lineHeight: "18px",
                        }}>“{formatDate(details?.createdAt, countryCode)}”

                        </Typography>
                    </Grid>
                    <Grid style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}>
                        {details?.status !== "resolved" && (
                            <Button
                                variant="contained"
                                sx={{
                                    textTransform: 'none',
                                    backgroundColor: "#FFFFFF",
                                    border: "0.1px solid",
                                    boxShadow: "none",
                                    borderColor: colorConfigs?.sidebar?.bg,
                                    borderRadius: "40px",
                                    fontWeight: "600",
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                    lineHeight: "21px",
                                    height: "45px",
                                    width: details && details?.status === "new" ? "130px" : "150px",
                                    color: colorConfigs?.sidebar?.bg,
                                    '&:hover': {
                                        boxShadow: "none",
                                        backgroundColor: '#FFFFFF',
                                    },
                                }}
                                onClick={() => {
                                    setOpenConfirmationDialog(true);
                                }}
                            >
                                {details?.status === "new" ? "Assign To Me" : "Set As Resolved"}
                            </Button>
                        )}

                        {details?.status === "resolved" && (
                            <Button
                                variant="contained"
                                sx={{
                                    textTransform: 'none',
                                    backgroundColor: "#FFFFFF",
                                    border: "0.1px solid",
                                    boxShadow: "none",
                                    borderColor: colorConfigs?.sidebar?.bg,
                                    borderRadius: "40px",
                                    fontWeight: "600",
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                    lineHeight: "21px",
                                    height: "45px",
                                    width: "130px",
                                    color: colorConfigs?.sidebar?.bg,
                                    '&:hover': {
                                        boxShadow: "none",
                                        backgroundColor: '#FFFFFF',
                                    },
                                }}
                                onClick={() => { dispatch(openModal(<TicketChat details={details} collectionName={"ticket_chat"} chatId={details._id} />)) }}
                            >
                                {"Chat History"}

                            </Button>
                        )}
                        {/* {details?.status === "new" && (
                            <Select
                                fullWidth
                                value={selectedValue}
                                onChange={handleChange}
                                variant="outlined"
                                displayEmpty
                                disabled={details?.status === "resolved"}
                                style={{
                                    pointerEvents: details?.status === "resolved" ? "none" : "auto",
                                }}
                                sx={selectStyle}

                            >
                                (<MenuItem value="new">New</MenuItem>
                                <MenuItem value="inProgress">inProgress</MenuItem>
                            </Select>
                        )}
                        {details?.status === "inProgress" && (
                            <Select
                                fullWidth
                                value={selectedValue}
                                onChange={handleChange}
                                variant="outlined"
                                displayEmpty
                                disabled={details?.status === "resolved"}
                                sx={selectStyle}
                            >
                                <MenuItem value="inProgress">inProgress</MenuItem>
                                <MenuItem value="resolved">Resolved</MenuItem>
                            </Select>
                        )} */}
                        <Box sx={{
                            marginLeft: "5px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: 'center',
                            paddingBottom: '5px',
                            backgroundColor: getBackgroundColor(details?.status),
                            borderRadius: "40px",
                            height: "45px",
                            width: "130px",
                        }}>
                            <Status status={selectedValue} from={"ticketView"} style={{ width: "130px", }} />
                        </Box>

                    </Grid>
                </Grid>
            </Box >
            {/* Main Content */}
            < Box padding={"15px"} style={{ marginTop: "-10px", }
            }>
                {/* Reasons: and Images: */}
                < Grid style={container} >
                    <Grid style={card}>
                        <Typography style={titel}>
                            Reasons:
                        </Typography>
                        <Typography style={key}>
                            Category : <span style={value}>{capitalizeFirstLetter(details?.knowledgeBase?.type)}</span>
                        </Typography>
                        <Typography style={key}>
                            Reason : <span style={value}>{capitalizeFirstLetter(details?.knowledgeBase?.title)}</span>
                        </Typography>
                        {/* <Typography style={key}>
                            Sub Reason : <span style={value}>{capitalizeFirstLetter(details?.knowledgeBase?.sup_reasone)}</span>
                        </Typography> */}
                    </Grid>
                    {
                        details?.images?.length > 0 &&
                        (<Grid style={card}>
                            <Typography style={titel}>
                                Images:
                            </Typography>
                            <Grid container spacing={2} justifyContent="center">
                                {Array.from({ length: 3 }).map((_, index) => (
                                    <Grid item xs={4} key={index}>
                                        <Box
                                            sx={{
                                                minWidth: "50px",
                                                height: '100px',
                                                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                                border: '1px solid #e0e0e0',
                                                borderRadius: '4px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',

                                            }}
                                            onClick={() => details.images[index] && handleImageClick(index)}                                    >
                                            {details?.images && details?.images[index] ? (
                                                <img
                                                    src={details?.images[index]}
                                                    alt={`Img ${index}`}
                                                    style={{ maxWidth: '100%', maxHeight: '100%', cursor: "pointer" }}
                                                />
                                            ) : null}
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                            <Dialog
                                open={isDialogOpen}
                                onClose={handleClose}
                                maxWidth="md"
                                fullWidth
                            >
                                <ImageGallery
                                    items={images}
                                    startIndex={startIndex}
                                    showThumbnails={false}
                                    showPlayButton={false}
                                    showFullscreenButton={false}
                                />
                            </Dialog>
                        </Grid>)
                    }
                </Grid >
                {/* description */}
                < Grid style={container} >
                    <Grid style={descriptionCard}>
                        <Typography style={titel}>
                            Description:
                        </Typography>
                        <Typography style={{
                            fontWeight: "400",
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            lineHeight: "17px",
                            color: "#7A869A",

                        }}>
                            {details?.description}
                        </Typography>

                    </Grid>
                </Grid >
                {/* Reported by: and Ride Details:*/}
                < Grid style={container} >
                    <Grid style={card}>
                        <Typography style={titel}>
                            Reported by:
                        </Typography>
                        <Typography style={key}>
                            Name :
                            <img
                                style={nameImg}
                                src={details?.owner_info?.profile_picture ? details?.owner_info?.profile_picture : "https://i.pinimg.com/736x/de/59/4e/de594ec09881da3fa66d98384a3c72ff.jpg"}
                                alt=""
                            /> <span style={nameValue}>{details?.owner_info?.first_name + " " + details?.owner_info?.last_name}</span>
                        </Typography>
                        <Typography style={key}>
                            Type : <span style={value}>{capitalizeFirstLetter(details?.owner_type)}</span>
                        </Typography>
                        <Typography style={key}>
                            Email : <span style={emailValue}>
                                <a
                                    style={{ color: "#495974", textDecoration: "none" }}
                                    href={`mailto:${details?.owner_info?.email}`} >
                                    {details?.owner_info?.email}
                                </a>
                            </span>
                        </Typography>
                        <Typography style={key}>
                            Phone : <span style={value}>
                                <a
                                    style={{ color: "#495974", textDecoration: "none" }}
                                    href={`tel:${details?.owner_info?.code_phone_number}${details?.owner_info?.phone_number}`} >
                                    {NewFormatPhoneNumber(details?.owner_info?.phone_number, countryCode)}
                                </a>
                            </span>
                        </Typography>
                    </Grid>
                    <Grid style={card}>
                        <Typography style={titel}>
                            {details?.knowledgeBase?.type === "ride" ? "Ride" : "Payment"}  Details:
                        </Typography>
                        {details?.knowledgeBase?.type === "ride" && (
                            <Typography style={key}>
                                {details?.owner_type === "driver" ? "Passenger" : "Driver"} Name :
                                <img
                                    style={nameImg}
                                    src={details?.ride_info?.second_user_info?.profile_picture ?
                                        details?.ride_info?.second_user_info?.profile_picture :
                                        "https://i.pinimg.com/736x/de/59/4e/de594ec09881da3fa66d98384a3c72ff.jpg"}
                                    alt=""
                                /> <span style={nameValue}>{details?.ride_info?.second_user_info?.first_name + " " + details?.ride_info?.second_user_info?.last_name}</span>
                            </Typography>
                        )}
                        <Typography style={key}>
                            {details?.knowledgeBase?.type === "ride" ? "Ride" : "Payment"} ID : <span style={value}>
                                #{details?.knowledgeBase?.type === "ride" ? details?.ride_info?.short_id : details?.payment_info?.short_id}
                                {details?.knowledgeBase?.type === "ride" && (
                                    <span
                                        onClick={() => {
                                            dispatch(postDataRedux({ stateName: 'rides', data: 'ticketDetails' }))
                                            navigate(`/rides/details/${details?.ride_info?.id}`)
                                        }}
                                        style={{
                                            textDecoration: "underline",
                                            paddingLeft: "10px",
                                            color: colorConfigs?.sidebar?.bg,
                                            cursor: "pointer",
                                        }}
                                    >View Ride</span>)} </span>
                        </Typography>
                        {(details?.ride_info?.status || details?.payment_info?.status) && (
                            <Typography style={key}>
                                Status : <span style={{ marginTop: "-5px", paddingLeft: "10px", }}> <Status status={details?.knowledgeBase?.type === "ride" ? details?.ride_info?.status : details?.payment_info?.status} />
                                </span>
                            </Typography>
                        )}
                    </Grid>
                </Grid >
                {/* Suggested Actions: */}
                < Grid style={container} >
                    <Grid style={descriptionCard}>
                        <Typography style={titel}>
                            Suggested Actions:
                        </Typography>
                        <Typography style={{
                            fontWeight: "600",
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            lineHeight: "32px",
                            color: "#172B4D",

                        }}>
                            {details?.knowledgeBase?.suggested_actions}
                        </Typography>
                    </Grid>
                </Grid >
                {/* Admin Note: */}
                {
                    details?.note && (
                        <Grid style={container}>
                            <Grid style={descriptionCard}>
                                <Typography style={titel}>
                                    Admin Note:
                                </Typography>
                                <Typography style={{
                                    fontWeight: "600",
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                    lineHeight: "32px",
                                    color: "#172B4D",

                                }}>
                                    {details?.note}
                                </Typography>

                            </Grid>
                        </Grid>
                    )
                }
                {
                    details?.status === "inProgress" && (
                        <Button
                            variant="contained"
                            style={{ width: "150px", marginTop: "15px", }}
                            sx={{
                                textTransform: 'none',
                                backgroundColor: process.env.REACT_APP_ENV === 'women_first' ? colorConfigs?.sidebar?.bg : '#005e8b',
                                color: 'white',
                                borderRadius: '10px',
                                width: "100%",
                                padding: '10px 20px',
                                '&:hover': {
                                    backgroundColor: process.env.REACT_APP_ENV === 'women_first' ? colorConfigs?.sidebar?.bg : '#004b6e',
                                },
                            }}
                            onClick={() => { dispatch(openModal(<TicketChat details={details} collectionName={"ticket_chat"} chatId={details._id} />)) }}
                        >
                            Reply
                        </Button>
                    )
                }
            </Box >
            <Loading
                loading={loading}
                background="rgba(86, 100, 210, 0.1)"
                loaderColor={colorConfigs.sidebar?.bg}
            />
        </>
    );
};

export default TicketDetails;
