import * as React from "react"
import { memo } from "react"
import colorConfigs from "../../../configs/colorConfigs"
const SvgComponent = (props) => (
  <svg
  xmlns="http://www.w3.org/2000/svg"
  width={50}
  height={50}
  fill="none"
  {...props}
>
  <circle cx={25} cy={25} r={25} fill={colorConfigs?.sidebar?.ligthBg} />
  <path
    fill={colorConfigs?.sidebar?.bg}
    d="M25 27v8h-8a8 8 0 0 1 8-8Zm0-1c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6Zm9 4h1v5h-8v-5h1v-1a3 3 0 0 1 6 0v1Zm-2 0v-1a1 1 0 0 0-2 0v1h2Z"
  />
</svg>
)
const CreateAdmin = memo(SvgComponent)
export default CreateAdmin
