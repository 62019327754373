
import { useTheme } from "@emotion/react";
import GpsFixedOutlinedIcon from "@mui/icons-material/GpsFixedOutlined";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import PolylineOutlinedIcon from "@mui/icons-material/PolylineOutlined";
import ShareLocationOutlinedIcon from "@mui/icons-material/ShareLocationOutlined";
import {
  Button,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Box, ThemeProvider } from "@mui/system";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AddressAutoComplete from "../../../components/common/AddressAutoComplete";
import MapWithDraw from "../../../components/googleMaps/draw";
import SelectAdd from "../../../components/googleMaps/selectAdd";
import colorConfigs from "../../../configs/colorConfigs";
import {
  saveCoordinates,
  saveLocationInfo,
  setRadius,
  updateFormData,
} from "../../../redux/features/appStateSlice";
import distanceUnit from "../../../utils/distanceUnit";
import usePrompt from "../../Hook/usePrompt";

const useStyles = makeStyles({
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
});

const EditZones = ({
  //   formData,
  onInputChange,
  currentStep,
  steps,
  prev,
  next,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.appState.formDataDoc);
  const [activeRequirement, setActiveRequirement] = useState(
    formData?.type || "radius"
  );
  const [addressToShowZipCode, setAddressToShowZipCode] = useState(
    formData?.address?.zip_code || ""
  );
  const [addressToShowCity, setAddressToShowCity] = useState(
    formData?.address?.primary_line || ""
  );

  const [addressToShowAddress, setAddressToShowAddress] = useState(
    formData?.address?.primary_line || ""
  );
  const [zoneBy, setZoneBy] = useState("map");
  const [address, setAddress] = useState(formData?.address || {});
  const [formErrors, setErrors] = useState({});
  const navigate = useNavigate();
  const [mapView, setMapView] = useState(false);
  const classes = useStyles();
  const saveLocationInfoo = useSelector(
    (state) => state.appState.saveLocationInfo
  );
  const saveCoordinatess = useSelector(
    (state) => state.appState.saveCoordinates
  );
  const rad = useSelector((state) => state.appState.radius);

  useEffect(() => {
    if (activeRequirement === "radius") {
      dispatch(setRadius(formData?.options?.radius?.radius_length_show));
    } else {
      setRadius(0);
    }
  }, [formData, activeRequirement]);

  useEffect(() => {
    setAddressToShowZipCode(formData?.address?.zip_code);
    setAddressToShowCity(formData?.address?.primary_line);
    setAddressToShowAddress(formData?.address?.primary_line);
    setAddress(formData?.address);
  }, [formData?.address]);

  const handleSelectzipcode = async (address, placeId, suggestion) => {
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);

      const selectedPlaceData = {
        city:
          results[0]?.address_components.find((component) =>
            component.types.includes("locality")
          )?.long_name || "",
        state:
          results[0]?.address_components.find((component) =>
            component.types.includes("administrative_area_level_1")
          )?.short_name || "",
        country:
          results[0]?.address_components.find((component) =>
            component.types.includes("country")
          )?.short_name || "",
        zip_code:
          results[0]?.address_components.find((component) =>
            component.types.includes("postal_code")
          )?.long_name || "",
        lat: latLng?.lat,
        lng: latLng?.lng,
        route:
          results[0]?.address_components.find((component) =>
            component.types.includes("route")
          )?.long_name || "",
        street_number:
          results[0]?.address_components.find((component) =>
            component.types.includes("street_number")
          )?.long_name || "",
        primary_line: results[0]?.formatted_address || "",
        placeId: placeId || "",
        address: address || "",
      };
      setAddress(selectedPlaceData);
      setAddressToShowZipCode(selectedPlaceData?.zip_code);
      setAddressToShowCity(selectedPlaceData?.primary_line);
      setAddressToShowAddress(selectedPlaceData?.primary_line);
    } catch (error) {
      console.error("Error selecting address", error);
    }
  };
  const requirementsList = [
    {
      key: "zipCode",
      icon: (
        <ShareLocationOutlinedIcon
          style={{ color: colorConfigs?.sidebar?.bg }}
        />
      ),
      title: process.env.REACT_APP_ENV === 'women_first' ? 'Postal Code' : 'Zip Code',
    },

    {
      key: "city",
      icon: <PlaceOutlinedIcon style={{ color: colorConfigs?.sidebar?.bg }} />,
      title: "City",
    },
    {
      key: "radius",
      icon: (
        <GpsFixedOutlinedIcon style={{ color: colorConfigs?.sidebar?.bg }} />
      ),
      title: "Radius",
    },
    {
      key: "polygon",
      icon: (
        <PolylineOutlinedIcon style={{ color: colorConfigs?.sidebar?.bg }} />
      ),
      title: "Polygon",
    },
  ];

  const handleRequirementSelect = (key) => {
    setActiveRequirement(key);
    if (key === "city" || key === "zipCode") {
      setRadius(0);
      dispatch(setRadius(0));
      setMapView(true);
    } else {
      setMapView(false);
    }
    setAddressToShowZipCode("");
    setAddressToShowCity("");
    setAddressToShowAddress("");

  };
  const handleSubmit = async (values) => {
    setErrors({});
    let error = {};

    if (!values.name) {
      error.name = "Name is required.";
    } else if (values.name.length > 25) {
      error.name = "Name should not exceed 25 characters.";
    }
    //  else if (!values.currency) {
    //   error.currency = "currency is required.";
    // } else if (values.currency.length > 15) {
    //   error.currency = "Currency should not exceed 15 characters.";
    // }
    else if (!values.tax) {
      error.tax = "Tax is required.";
    } else if (values.tax < 1 || values.tax > 100) {
      error.tax = "Tax must be from 1 to 100.";
    }
    else if (values.radius <= 5 && activeRequirement === "radius") {
      error.radius = "Radius must be more than five.";
    } else if (activeRequirement === "zipCode" && address?.lat === undefined) {
      error.zip_code = "Postal code is required.";
    } else if (activeRequirement === "city" && address?.lat === undefined) {
      error.city = "City is required.";
    } else if (
      activeRequirement === "radius" &&
      zoneBy === "address" &&
      address?.lat === undefined
    ) {
      error.radiuss = "Address is required.";
    } else if (
      activeRequirement === "polygon" &&
      formData?.options?.polygon_points?.length === 0 &&
      saveCoordinatess?.length === 0
    ) {
      error.polygon = "Polygon is required. Please draw a polygon.";
    }

    if (Object.keys(error).length > 0) {
      setErrors(error);
    } else {
      // try {
      let options = {
        radius: { radius_length: 0, radius_length_show: 0 },
        polygon_points: []
      };
      if (activeRequirement === "zipCode") {
        options.zip_code = addressToShowZipCode;
      } else if (activeRequirement === "radius") {
        options.radius.radius_length = Number(distanceUnit(values?.radius, false)); // replace with your radius value
        options.radius.radius_length_show = values?.radius; // replace with your radius value
      } else if (activeRequirement === "city") {
        options.city = addressToShowCity; // replace with your city value
      } else if (activeRequirement === "polygon") {
        options.polygon_points = saveCoordinatess; 
      }
      let addressData = {};

      if (activeRequirement === "zipCode" || activeRequirement === "city") {
        addressData.lat = address?.lat;
        addressData.lng = address?.lng;
        addressData.street_number = address?.street_number;
        addressData.street_name = address?.route;
        addressData.city = address?.city;
        addressData.state = address?.state;
        addressData.country = "";
        addressData.zip_code = address?.zip_code;
        addressData.route = address?.route;
        addressData.primary_line = address?.primary_line;
        addressData.placeId = address?.placeId;
        addressData.address = address?.address;
        dispatch(saveLocationInfo({}));
        dispatch(saveCoordinates([]));
      } else if (activeRequirement === "radius" && zoneBy === "address") {
        addressData.lat = address?.lat;
        addressData.lng = address?.lng;
        addressData.street_number = address?.street_number;
        addressData.street_name = address?.route;
        addressData.city = address?.city;
        addressData.state = address?.state;
        addressData.country = "";
        addressData.zip_code = address?.zip_code;
        addressData.route = address?.route;
        addressData.primary_line = address?.primary_line;
        addressData.placeId = address?.placeId;
        addressData.address = address?.address;
        dispatch(saveLocationInfo({}));
        dispatch(saveCoordinates([]));
      } else if (activeRequirement === "radius" && zoneBy === "map") {
        addressData.lat = saveLocationInfoo?.lat;
        addressData.lng = saveLocationInfoo?.lng;
        addressData.street_number = "";
        addressData.street_name = "";
        addressData.city = "";
        addressData.state = "";
        addressData.country = "";
        addressData.zip_code = saveLocationInfoo?.zipCode;
        addressData.route = "";
        addressData.primary_line = "";
        addressData.placeId = "";
        addressData.address = saveLocationInfoo?.address;
        dispatch(saveLocationInfo({}));
        dispatch(saveCoordinates([]));
      } else if (activeRequirement === "polygon") {
        addressData.lat = saveLocationInfoo?.lat;
        addressData.lng = saveLocationInfoo?.lng;
        addressData.street_number = "";
        addressData.street_name = "";
        addressData.city = "";
        addressData.state = "";
        addressData.country = "";
        addressData.zip_code = saveLocationInfoo?.zipCode;
        addressData.route = "";
        addressData.primary_line = "";
        addressData.placeId = "";
        addressData.address = saveLocationInfoo?.address;
        dispatch(setRadius(0));
      }

      const data = {
        ...formData,

        created_at: Date.now(),
        name: values.name,
        tax: values?.tax,
        currency: "USD",
        enabled: true,
        type: activeRequirement,
        options: options,
        address: addressData,
      };
      dispatch(updateFormData({ ...formData, ...data }));
    
      setAddress({});
      next();
    }
  };

  const fieldStyle = {
    "& label.Mui-focused": {
      color: "#172B4D !important",
      fontSize: "17px !important",
      fontWeight: "500 !important",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#BBC1CE",
        borderWidth: 1,
        borderRadius: "8px",
      },
      "&:hover fieldset": {
        borderColor: "#BBC1CE",
        borderWidth: 1,
        borderRadius: "8px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#BBC1CE",
        borderWidth: 1,
        borderRadius: "8px",
      },
      input: {
        "&::placeholder": {
          color: "#3A3541DE",
          opacity: 1, // otherwise firefox shows a lighter color
          fontSize: 14,
        },
      },
    },
  };
  const handleBackClick = () => {
    if (currentStep === 0) {
      navigate('/rates');
    } else {
      prev();
    }
  };

  return (
    <>
      <Box
        style={{
          fontFamily: "inter",
          fontSize: "small",
          marginTop: "30px",
          borderRadius: "8px",
          padding: "6px",
          width: "100%",
          marginBottom: "20px",
          color: colorConfigs?.sidebar?.bg,
          backgroundColor: "#F4F4F4",
        }}
      >
        <Typography variant="body1" style={{ fontWeight: "bold" }}>
          Zone
        </Typography>
      </Box>
      <Formik
        initialValues={{
          ...formData,
          radius: formData?.options?.radius?.radius_length_show,
        }}
        onSubmit={handleSubmit}
      >
        {({
          handleBlur,
          handleChange,
          values,
          errors,
          handleSubmit,
          touched,
        }) => (
          <ThemeProvider theme={theme}>
            <Grid container spacing={3}>
              <Grid item lg={5}>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label={"Zone Name"}
                      name="name"
                      onChange={handleChange("name")}
                      onBlur={handleBlur("name")}
                      required
                      value={values?.name}
                      variant="outlined"
                      sx={fieldStyle}
                      placeholder="Enter Zone Name"
                      filled="true" // Set it as a string "true"
                      focused
                      error={formErrors.name?.length > 0 ? true : false}
                    />
                    {formErrors.name?.length > 0 && (
                      <Typography variant="caption" style={{ color: "red" }}>
                        {formErrors.name}
                      </Typography>
                    )}
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label={"Tax"}
                      name="tax"
                      onChange={(event) => {
                        let value = event.target.value;
                        if (value.length > 1 && value.startsWith("0")) {
                          value = value.replace(/^0+/, "");
                        }
                        handleChange("tax")({ target: { value } });
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">{"%"}</InputAdornment>
                        ),
                      }}
                      onWheel={(e) => e.target.blur()}
                      onBlur={handleBlur("tax")}
                      className={classes.input}
                      sx={fieldStyle}
                      inputProps={{ pattern: "[0-9]*" }}
                      required
                      type="number"
                      value={values?.tax}
                      variant="outlined"
                      placeholder="Enter a tax"
                      filled="true" // Set it as a string "true"
                      focused
                      error={formErrors.tax?.length > 0 ? true : false}
                    />
                    {formErrors.tax?.length > 0 && (
                      <Typography variant="caption" style={{ color: "red" }}>
                        {formErrors.tax}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item md={12} xs={12} sx={{ display: 'none' }}>
                    <TextField
                      fullWidth
                      label={"Currency"}
                      name="currency"
                      onChange={handleChange("currency")}
                      onBlur={handleBlur("currency")}
                      required
                      // value={values?.currency}
                      value={process.env.REACT_APP_ENV === 'women_first' ? 'CAD' : "USD"}
                      disabled
                      variant="outlined"
                      sx={fieldStyle}
                      placeholder="Enter Currency"
                      filled="true" // Set it as a string "true"
                      focused
                      error={formErrors.currency?.length > 0 ? true : false}
                    />
                    {formErrors.currency?.length > 0 && (
                      <Typography variant="caption" style={{ color: "red" }}>
                        {formErrors.currency}
                      </Typography>
                    )}
                  </Grid>
                  <Grid
                    container
                    spacing={1}
                    style={{
                      paddingTop: "30px",
                      justifyContent: "center",
                      paddingLeft: "24px",
                      width: "100%",
                    }}
                  >
                    {requirementsList.map((requirement) => (
                      <Grid
                        key={requirement.key}
                        item
                        lg={3}
                        sm={4}
                        onClick={() => (
                          handleRequirementSelect(requirement.key),
                          setErrors({})
                        )}
                      >
                        <div
                          style={{
                            border:
                              activeRequirement !== requirement.key
                                ? `0.5px solid lightgray`
                                : `1px solid ${colorConfigs?.sidebar?.bg}`,
                            borderRadius: "10px",
                            textAlign: "center",
                            padding: "16px 0",
                            boxSizing: "border-box",
                            transition: "0.3s",

                            "&:hover": {
                              boxShadow: "0 4px 6px 0 rgba(0,0,0,0.02)",
                            },
                          }}
                        >
                          {requirement.icon}
                          <Typography
                            variant={"body2"}
                            style={{
                              marginTop: "8px",
                              color: "#7A869A",
                            }}
                          >
                            {requirement.title}
                          </Typography>
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
                <Grid item md={12}>
                  {activeRequirement === "zipCode" && (
                    <>
                      <div style={{ marginTop: "30px" }}>
                        <AddressAutoComplete
                          address={addressToShowZipCode}
                          setAddress={setAddressToShowZipCode}
                          handleSelect={handleSelectzipcode}
                          types={"postal_code"}
                          lable="Postal code"
                          placeholder={"Please Enter Postal code"}
                        />
                        {formErrors.zip_code?.length > 0 && (
                          <Typography
                            variant="caption"
                            style={{ color: "red" }}
                          >
                            {formErrors.zip_code}
                          </Typography>
                        )}
                      </div>
                    </>
                  )}
                  {activeRequirement === "city" && (
                    <>
                      <div style={{ marginTop: "30px" }}>
                        <AddressAutoComplete
                          address={addressToShowCity}
                          setAddress={setAddressToShowCity}
                          handleSelect={handleSelectzipcode}
                          types={"(cities)"}
                          lable="City"
                          placeholder={"Please Enter City"}
                        />
                        {formErrors.city?.length > 0 && (
                          <Typography
                            variant="caption"
                            style={{ color: "red" }}
                          >
                            {formErrors.city}
                          </Typography>
                        )}
                      </div>
                    </>
                  )}
                  {activeRequirement === "radius" && (
                    <Grid item md={12} xs={12} style={{ marginTop: "20px" }}>
                      <TextField
                        className={classes.input}
                        fullWidth
                        label={"Radius"}
                        name="radius"
                        onChange={(event) => {
                          let value = event.target.value;
                          if (value.length > 1 && value.startsWith("0")) {
                            value = value.replace(/^0+/, "");
                          }
                          handleChange("radius")({ target: { value } });
                          dispatch(setRadius(value));
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">Km</InputAdornment>
                          ),
                        }}
                        sx={fieldStyle}
                        inputProps={{ pattern: "[0-9]*" }}
                        onBlur={handleBlur("radius")}
                        required
                        type="number"
                        value={rad}
                        variant="outlined"
                        placeholder="Please enter Radius Number"
                        filled="true" // Set it as a string "true"
                        focused
                        error={formErrors.radius?.length > 0 ? true : false}
                      />
                      {formErrors?.radius?.length > 0 && (
                        <Typography variant="caption" style={{ color: "red" }}>
                          {formErrors?.radius}
                        </Typography>
                      )}
                    </Grid>
                  )}
                </Grid>
                {activeRequirement === "radius" && (
                  <Grid item md={12}>
                    <Paper
                      style={{
                        marginTop: "20px",
                        paddingTop: "10px",
                        display: "flex",
                        justifyContent: "space-around",
                        paddingBottom: "10px",
                      }}
                    >
                      <div>
                        <input
                          type="radio"
                          id="address"
                          name="zoneBy"
                          value="address"
                          checked={zoneBy === "address"}
                          onChange={(e) => setZoneBy(e.target.value)}
                        />
                        <label htmlFor="address">Address</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="map"
                          name="zoneBy"
                          value="map"
                          checked={zoneBy === "map"}
                          onChange={(e) => setZoneBy(e.target.value)}
                        />
                        <label htmlFor="map">Map</label>
                      </div>
                    </Paper>
                    {zoneBy === "address" && (
                      <div style={{ marginTop: "30px" }}>
                        <AddressAutoComplete
                          address={addressToShowAddress}
                          setAddress={setAddressToShowAddress}
                          handleSelect={handleSelectzipcode}
                          types={"address"}
                          lable="Address"
                          placeholder={"Please Enter Address"}
                        />
                        {formErrors.radiuss?.length > 0 && (
                          <Typography
                            variant="caption"
                            style={{ color: "red" }}
                          >
                            {formErrors.radiuss}
                          </Typography>
                        )}
                      </div>
                    )}
                  </Grid>
                )}
              </Grid>
              <Grid item lg={7}>
                {activeRequirement === "polygon" && (
                  <>
                    {" "}
                    <MapWithDraw
                      options={formData?.options?.polygon_points}
                      type={formData?.type}
                    />
                    {formErrors?.polygon?.length > 0 && (
                      <Typography variant="caption" style={{ color: "red" }}>
                        {formErrors?.polygon}
                      </Typography>
                    )}
                  </>
                )}
                {activeRequirement !== "polygon" && (
                  <>
                    {" "}
                    <SelectAdd
                      selectedZone={undefined}
                      mapView={mapView}
                      lat={address?.lat}
                      lng={address?.lng}
                      ititialRadius={formData?.options?.radius?.radius_length}
                    />
                  </>
                )}
              </Grid>
            </Grid>
            <div style={{ marginTop: '-20px' }}>
              <Button
                variant="outlined"
                onClick={handleBackClick}
                style={{
                  marginRight: 8,
                  color: colorConfigs?.sidebar?.bg,
                  borderColor: colorConfigs?.sidebar?.bg,
                  backgroundColor: "white",
                  width: "12%",
                }}
              >
                Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                style={{
                  backgroundColor: colorConfigs?.sidebar?.bg,
                  color: "white",
                  width: "12%",
                }}
              >
                {currentStep === steps?.length - 1 ? "Finish" : "Next"}
              </Button>
            </div>
          </ThemeProvider>
        )}
      </Formik>
    </>
  );
};

export default EditZones;