import React, { useEffect, useState } from 'react';
import { Box, Typography, Paper, Select, MenuItem, FormControl, InputLabel, Grid, CircularProgress, TextField, Autocomplete, Snackbar } from '@mui/material';
import { Bar, Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';
import { styled, width } from '@mui/system';
import colorConfigs from '../../../configs/colorConfigs';
import { MdOutlineShowChart } from 'react-icons/md';
import { collection, getDocs, onSnapshot, doc, where, getDoc } from "firebase/firestore";
import { db } from '../../../configs/firebase';
import { useSelector } from "react-redux";
import Loading from 'react-fullscreen-loading';
import { getMethod } from '../../Hook/UseCustomApi';
import Error5xx from '../../../components/common/HandelError5xx';
import { formatNumber } from '../../../utils/formateNumber';

const CustomFormControl = styled(FormControl)({
    backgroundColor: '#F4F7FE',
    borderRadius: '10px',
    minWidth: '120px',
    padding: '8px',
    fontFamily: 'poppins',
    fontWeight: 600,
    fontSize: 12,
});

const CustomInputLabel = styled(InputLabel)({
    display: 'none'
});

const CustomSelect = styled(Select)({
    '& .MuiSelect-select': {
        backgroundColor: '#F4F7FE !important',
        display: 'flex',
        gap: 2,
        justifyContent: 'space-between',
        fontFamily: 'poppins',
        fontWeight: 600,
        padding: '0 0 0 15px !important',
        fontSize: 14,
        width: '110px'
    },
    '& .MuiSvgIcon-root': {
        color: '#A3AED0',
        fontSize: '30px',
    },
});

const LegendBox = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    gap: '10px',
    marginRight: '20px'
});
const LegendBoxDoughnut = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '10px',
});

const LegendItem = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: '5px'
});

const ColorDot = styled(Box)({
    width: '10px',
    height: '10px',
    borderRadius: '50%'
});

const DoughnutContainer = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    gap: '20px',
    height: '260px',
});

const CenteredDiv = styled(Box)({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    background: '#FFF',
    boxShadow: 'rgba(216, 218, 229, 0.8) 0 8px 17px',
    height: 110,
    width: 110,
    borderRadius: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
});

const StatBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    width: '45%',
});

const StatTextBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'start',
    marginLeft: 5
});

const StatCircleBox = styled(Box)({
    position: 'relative',
    display: 'inline-flex',
    marginTop: 15
});

const StatCircle = styled(CircularProgress)({
    color: 'transparent',
});

const StatCircleOverlay = styled(Box)({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

function getDayName(dayNumber) {
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    return days[dayNumber];
}

const Dashboard = () => {
    const [revenueTimePeriod, setRevenueTimePeriod] = useState('Monthly');
    const [ridesTimePeriod, setRidesTimePeriod] = useState('Monthly');
    const [driversEarningTimePeriod, setDriversEarningTimePeriod] = useState('Monthly');
    const [search, setSearch] = useState('');
    const [statistics, setStatistics] = useState({})
    const [companyPercentagesMonthly, setCompanyPercentagesMonthly] = useState([]);
    const [driversEarningMonthly, setDriversEarningMonthly] = useState([]);
    const [ridesMonthlyMonthly, setRidesMonthly] = useState([]);
    const [companyPercentagesQuarterly, setCompanyPercentagesQuarterly] = useState([]);
    const [driversEarningQuarterly, setDriversEarningQuarterly] = useState([]);
    const [ridesQuarterly, setRidesQuarterly] = useState([]);
    const [companyPercentagesWeekly, setCompanyPercentagesWeekly] = useState([]);
    const [ridesWeekly, setRidesWeekly] = useState([]);
    const [driversEarningWeekly, setDriversEarningWeekly] = useState([]);
    const subCollections = Array.from({ length: 12 }, (_, i) => (i + 1).toString().padStart(2, '0'));
    const currentDate = new Date();
    const currentMonth = new Date().getMonth() + 1;
    const currentMonthNOmber = currentMonth < 10 ? `0${currentMonth}` : currentMonth.toString();
    const [options, setOptions] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [savedDriversEarningMonthly, setSavedDriversEarningMonthly] = useState([]);
    const [savedDriversEarningQuarterly, setSavedDriversEarningQuarterly] = useState([]);
    const [savedDriversEarningWeekly, setSavedDriversEarningWeekly] = useState([]);
    const [tryAgain, setTryAgain] = useState();
    const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
    const [errorCount, setErrorCount] = useState(0);
    const [errorDialogCounter, setErrorDialogCounter] = useState(0);
    const [searchLoading, setSearchLoading] = useState(false);
    const [activeRides, setActiveRides] = useState(0);
    const [circleStatistics, setCircleStatistics] = useState({});
    const [selectedDriver, setSelectedDriver] = useState(false);
    // Initialize arrays to hold the last seven days as numbers and day names
    const lastSevenDaysNumbers = [];
    const lastSevenDaysNames = [];
    const selectedYear = useSelector((state) => state.appState.selectedYear);
    const activeShow = selectedYear === new Date().getFullYear().toString()

    // Loop to get the last seven days as numbers and day names
    for (let i = 6; i >= 0; i--) {
        const pastDate = new Date();
        pastDate.setDate(currentDate.getDate() - i);
        const dayNumber = String(pastDate.getDate()).padStart(2, '0');
        lastSevenDaysNumbers.push(dayNumber);
        lastSevenDaysNames.push(getDayName(pastDate.getDay()));
    }

    const handleError5xx = () => {
        setIsErrorDialogOpen(true);
        setErrorCount(prevCount => {
            const newCount = prevCount + 1;
            if (newCount === 1) {
                setErrorDialogCounter(5);
            } else if (newCount === 2) {
                setErrorDialogCounter(10);
            }
            return newCount;
        });
    };
    const handleCloseErrorDialog = () => {
        setIsErrorDialogOpen(false);
        if (errorCount <= 2) {
            handleErrorCountByClose()
        }

    };
    const handleErrorCountByClose = () => {
        setErrorCount(prevCount => {
            const newCount = prevCount - 1;
            if (newCount === 1) {
                setErrorDialogCounter(5);
            } else if (newCount === 2) {
                setErrorDialogCounter(10);
            }
            return newCount;
        });
    }

    const handleRevenueTimePeriodChange = (event) => {
        setRevenueTimePeriod(event.target.value);
    };

    const handleRidesTimePeriodChange = (event) => {
        setRidesTimePeriod(event.target.value);
    };

    const handleDriversEarningTimePeriodChange = (event) => {
        setDriversEarningTimePeriod(event.target.value);
    };

    const driversssss = [
        { id: 1234, first_name: 'John', last_name: 'Doe' },
        { id: 2114, first_name: 'Jane', last_name: 'Smith' },
        { id: 3124, first_name: 'James', last_name: 'Johnson' },
        { id: 4444, first_name: 'Jack', last_name: 'Brown' },
    ];

    const driversListWithFullName = drivers.map(driver => ({
        id: driver._id,
        full_name: `${driver.first_name} ${driver.last_name}`
    }));

    const handleSearchChange = (event) => {
        const searchValue = event.target.value;
        console.log(searchValue.length)
        setSearch(searchValue);
        setSelectedDriver(false)
        if (searchValue.length >= 2) {
            const filteredOptions = driversListWithFullName.filter(driver => driver.full_name.toLowerCase().includes(searchValue.toLowerCase()));
            setOptions(filteredOptions);
        } else if (searchValue.length === 0 || !searchValue || searchValue === '' || searchValue === ' ') {
            setOptions([]);
            setSearchLoading(false)
            setDriversEarningMonthly(savedDriversEarningMonthly);
            setDriversEarningQuarterly(savedDriversEarningQuarterly);
            setDriversEarningWeekly(savedDriversEarningWeekly);
        }
    };


    const getBackgroundColor = (value) => {
        if (value < 150) return '#7DD1DA'; // Blue
        if (value < 200) return '#A2C933'; // Green
        return colorConfigs?.sidebar?.bg; // Purple
    };

    const barDataRevenue = {
        labels: revenueTimePeriod === 'Daily' ? lastSevenDaysNames :
            revenueTimePeriod === 'Quarterly' ? ['Q1 (Jan-Mar)', 'Q2 (Apr-Jun)', 'Q3 (Jul-Sep)', 'Q4 (Oct-Dec)'] :
                ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [
            {
                data: revenueTimePeriod === 'Daily' ? companyPercentagesWeekly :
                    revenueTimePeriod === 'Quarterly' ? companyPercentagesQuarterly :
                        companyPercentagesMonthly,
                backgroundColor: revenueTimePeriod === 'Daily' ? companyPercentagesWeekly.map(getBackgroundColor) :
                    revenueTimePeriod === 'Quarterly' ? companyPercentagesQuarterly.map(getBackgroundColor) :
                        companyPercentagesMonthly.map(getBackgroundColor),
                borderRadius: 8,
            },
        ],
    };

    const barDataTotalRides = {
        labels: ridesTimePeriod === 'Daily' ? lastSevenDaysNames :
            ridesTimePeriod === 'Quarterly' ? ['Q1 (Jan-Mar)', 'Q2 (Apr-Jun)', 'Q3 (Jul-Sep)', 'Q4 (Oct-Dec)'] :
                ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [
            {
                data: ridesTimePeriod === 'Daily' ? ridesWeekly :
                    ridesTimePeriod === 'Quarterly' ? ridesQuarterly :
                        ridesMonthlyMonthly,
                backgroundColor: ridesTimePeriod === 'Daily' ? ridesWeekly.map(getBackgroundColor) :
                    ridesTimePeriod === 'Quarterly' ? ridesQuarterly.map(getBackgroundColor) :
                        ridesMonthlyMonthly.map(getBackgroundColor),
                borderRadius: 8,
            },
        ],
    };

    const barDataDriversEarning = {
        labels: driversEarningTimePeriod === 'Daily' ? lastSevenDaysNames :
            driversEarningTimePeriod === 'Quarterly' ? ['Q1 (Jan-Mar)', 'Q2 (Apr-Jun)', 'Q3 (Jul-Sep)', 'Q4 (Oct-Dec)'] :
                ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [
            {
                data: driversEarningTimePeriod === 'Daily' ? driversEarningWeekly :
                    driversEarningTimePeriod === 'Quarterly' ? driversEarningQuarterly :
                        driversEarningMonthly,
                backgroundColor: driversEarningTimePeriod === 'Daily' ? driversEarningWeekly.map(getBackgroundColor) :
                    driversEarningTimePeriod === 'Quarterly' ? driversEarningQuarterly.map(getBackgroundColor) :
                        driversEarningMonthly.map(getBackgroundColor),
                borderRadius: 8,
            },
        ],
    };

    const activeLabel = activeShow ? ['Active Rides', 'Canceled Rides', 'Scheduled Rides', 'Dispatched Rides'] : ['Canceled Rides', 'Scheduled Rides', 'Dispatched Rides']
    const activeDatasets = activeShow ? [
        {
            data: [activeRides, circleStatistics?.cancelRides, circleStatistics?.scheduledRides, circleStatistics?.dispatchRides],
            backgroundColor: ['#9e93fb', '#e26968', '#acd44c', '#3eb1a7'],
        },
    ] : [
        {
            data: [circleStatistics?.cancelRides, circleStatistics?.scheduledRides, circleStatistics?.dispatchRides],
            backgroundColor: ['#e26968', '#acd44c', '#3eb1a7'],
        },
    ]
    const doughnutData = {
        labels: activeLabel,
        datasets: activeDatasets
    };

    const barOptions = {
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: true,
                callbacks: {
                    label: function (context) {
                        let value = context?.raw;
                        if (value >= 1000) {
                            return (value / 1000) + 'K';
                        } else {
                            return value?.toFixed(2);
                        }
                    }
                }
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
            },
            y: {
                grid: {
                    display: true,
                },
                ticks: {
                    callback: function (value) {
                        if (value < 1000) {
                            return '$' + value;
                        } else {
                            return '$' + (value / 1000) + 'K';
                        }
                    },
                },
            },
        },
    };

    const doughnutOptions = {
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: false,
            }
        }
    };

    const showActiveDot = activeShow
        ? { label: 'Active Rides', value: activeRides || 0, color: '#9e93fb', background: '#E2E6FF' }
        : false;

    const stats = [
        showActiveDot,  // This will be null if activeShow is false
        { label: 'Canceled Rides', value: circleStatistics?.cancelRides || 0, color: '#e26968', background: 'rgba(198, 61, 61, 0.1)' },
        { label: 'Dispatched Rides', value: circleStatistics?.dispatchRides || 0, color: '#3eb1a7', background: 'rgba(218, 255, 252, 1)' },
        { label: 'Scheduled Rides', value: circleStatistics?.scheduledRides || 0, color: '#acd44c', background: 'rgba(158, 198, 0, 0.1)' },
    ].filter(Boolean);


    useEffect(() => {
        const unsubscribe = onSnapshot(collection(db, 'allAnalysis'), (snapshot) => {
            const currentYear = new Date().getFullYear().toString(); // Get the current year as a string
            const data = snapshot.docs
                .filter(doc => doc.id === selectedYear) // Filter docs with id equal to current year
                .map(doc => doc.data());
            setCircleStatistics(data[0])
            const rideCountDoc = doc(db, 'rides_count', 'rides_count');
            const rideCountUnsubscribe = onSnapshot(rideCountDoc, (rideDoc) => {
                const ridesCount = rideDoc.data();
                setActiveRides(ridesCount.active + ridesCount.assigned + ridesCount.unassigned);
            });
        });
        return () => unsubscribe();
    }, [selectedYear]);

    useEffect(() => {
        const fetchData = async () => {
            setStatistics(null);
            setCompanyPercentagesMonthly([]);
            setCompanyPercentagesQuarterly([]);
            setCompanyPercentagesWeekly([]);
            setRidesMonthly([]);
            setRidesQuarterly([]);
            setRidesWeekly([]);
            setDriversEarningMonthly([]);
            setDriversEarningQuarterly([]);
            setDriversEarningWeekly([]);
            // setLoading(true);
            setSearch('');
            setActiveRides(0);
            setOptions([]);

            const mainCollection = collection(db, 'allAnalysis');
            const unsubscribe = onSnapshot(mainCollection, async (snapshot) => {
                const data = await Promise.all(snapshot.docs.map(async (docSnapshot) => {
                    if (docSnapshot.id === selectedYear) {
                        // Fetch sub-collections dynamically                        
                        let subCollectionData = await Promise.all(
                            subCollections.map(async (subCollectionName) => {
                                const subCollectionRef = collection(docSnapshot.ref, subCollectionName);
                                const subCollectionDocs = await getDocs(subCollectionRef);
                                return {
                                    [subCollectionName]: subCollectionDocs.docs.map(subDoc => ({
                                        id: subDoc.id,
                                        ...subDoc.data(),
                                    })),
                                };
                            })
                        );
                        subCollectionData = Object.assign({}, ...subCollectionData);
                        return {
                            id: docSnapshot.id,
                            ...docSnapshot.data(),
                            subCollectionData,
                        };
                    } else {
                        setStatistics({});
                        setCompanyPercentagesMonthly([]);
                        setCompanyPercentagesQuarterly([]);
                        setCompanyPercentagesWeekly([]);
                        setRidesMonthly([]);
                        setRidesQuarterly([]);
                        setRidesWeekly([]);
                        setDriversEarningMonthly([]);
                        setDriversEarningQuarterly([]);
                        setDriversEarningWeekly([]);
                    }

                    return null;
                }));

                const filteredData = data.filter(item => item !== null);
                setStatistics(filteredData[0]);
                setLoading(false);

                if (filteredData[0]) {
                    const subCollectionEntries = Object.entries(filteredData[0].subCollectionData).sort(([a], [b]) => a.localeCompare(b));
                    const percentages = subCollectionEntries.map(([key, value]) => {
                        const found = value.find(doc => doc.id === "allAnalysis");
                        return found ? (found.companyPercentage || 0) : 0;
                    });
                    // Fetch ride counts

                    setCompanyPercentagesMonthly(percentages);

                    let result = [];
                    for (let i = 0; i < percentages?.length; i += 3) {
                        let sum = percentages[i] + percentages[i + 1] + percentages[i + 2];
                        result.push(sum);
                    }
                    setCompanyPercentagesQuarterly(result);

                    let percentagesWeeklyValues = [0, 0, 0, 0, 0, 0, 0];
                    const percentagesWeekly = subCollectionEntries.flatMap(([key, value]) => {
                        const filteredDocs = value.filter(doc => doc.id !== "allAnalysis").filter(doc => key === currentMonthNOmber);
                        return filteredDocs.map(doc => lastSevenDaysNumbers.includes(doc.id) ? doc : 0);
                    });
                    percentagesWeekly.forEach((doc) => {
                        if (lastSevenDaysNumbers.includes(doc.id)) {
                            percentagesWeeklyValues[lastSevenDaysNumbers.indexOf(doc.id)] = doc.companyPercentage || 0;
                        }
                    });
                    setCompanyPercentagesWeekly(percentagesWeeklyValues);

                    const Rides = subCollectionEntries.map(([key, value]) => {
                        const found = value.find(doc => doc.id === "allAnalysis");
                        return found ? (found.totalRide || 0) : 0;
                    });
                    setRidesMonthly(Rides);

                    let rideResult = [];
                    for (let i = 0; i < Rides?.length; i += 3) {
                        let sum = Rides[i] + Rides[i + 1] + Rides[i + 2];
                        rideResult.push(sum);
                    }
                    setRidesQuarterly(rideResult);

                    let ridesWeeklyValues = [0, 0, 0, 0, 0, 0, 0];
                    const ridesWeekly = subCollectionEntries.flatMap(([key, value]) => {
                        const filteredDocs = value.filter(doc => doc.id !== "allAnalysis").filter(doc => key === currentMonthNOmber);
                        return filteredDocs.map(doc => lastSevenDaysNumbers.includes(doc.id) ? doc : 0);
                    });
                    ridesWeekly.forEach((doc) => {
                        if (lastSevenDaysNumbers.includes(doc.id)) {
                            ridesWeeklyValues[lastSevenDaysNumbers.indexOf(doc.id)] = doc.totalRide || 0;
                        }
                    });
                    setRidesWeekly(ridesWeeklyValues);
                    console.log(ridesWeeklyValues)
                    const driversEarning = subCollectionEntries.map(([key, value]) => {
                        const found = value.find(doc => doc.id === "allAnalysis");
                        return found ? (found?.companyPercentage ? (found?.priceRides - found?.companyPercentage) : 0) : 0;
                    });
                    setSavedDriversEarningMonthly(driversEarning)
                    setDriversEarningMonthly(driversEarning);

                    let driversEarningResult = [];
                    for (let i = 0; i < driversEarning?.length; i += 3) {
                        let sum = driversEarning[i] + driversEarning[i + 1] + driversEarning[i + 2];
                        driversEarningResult.push(sum);
                    }

                    setDriversEarningQuarterly(driversEarningResult);
                    setSavedDriversEarningQuarterly(driversEarningResult)
                    let driversEarningValues = [0, 0, 0, 0, 0, 0, 0];
                    const driversEarningWeekly = subCollectionEntries.flatMap(([key, value]) => {
                        const filteredDocs = value.filter(doc => doc.id !== "allAnalysis").filter(doc => key === currentMonthNOmber);
                        return filteredDocs.map(doc => lastSevenDaysNumbers.includes(doc.id) ? doc : 0);
                    });
                    driversEarningWeekly.forEach((doc) => {
                        if (lastSevenDaysNumbers.includes(doc.id)) {
                            driversEarningValues[lastSevenDaysNumbers.indexOf(doc.id)] = doc?.priceRides - doc?.companyPercentage || 0;
                        }
                    });
                    setDriversEarningWeekly(driversEarningValues);
                    setSavedDriversEarningWeekly(driversEarningValues)
                } else {
                    setStatistics(null);
                    setCompanyPercentagesMonthly([]);
                    setCompanyPercentagesQuarterly([]);
                    setCompanyPercentagesWeekly([]);
                    setRidesMonthly([]);
                    setRidesQuarterly([]);
                    setRidesWeekly([]);
                    setDriversEarningMonthly([]);
                    setDriversEarningQuarterly([]);
                    setDriversEarningWeekly([]);
                }
            });

            return () => {
                unsubscribe();
            };
        };

        setSearch('');
        fetchData();
    }, [selectedYear]);

    const fetchDriversData = async (search) => {

        const fetchData = async (driverId) => {
            // Get the current year as a string
            // Fetch the main collection documents
            const mainCollection = collection(db, 'driver');
            const unsubscribe = onSnapshot(mainCollection, async (snapshot) => {
                await Promise.all(snapshot.docs.map(async (doc) => {
                    if (doc.id === driverId) {
                        setDriversEarningMonthly([]);
                        setDriversEarningQuarterly([]);
                        setDriversEarningWeekly([]);
                        // Fetch sub-collections dynamically
                        const subCollectionRef = collection(doc.ref, 'allAnalysis');
                        const subCollectionDocsYear = await getDocs(subCollectionRef);
                        const data = await Promise.all(subCollectionDocsYear?.docs?.map(async (current) => {
                            let subCollectionData = {}
                            if (current.id == selectedYear) {
                                subCollectionData = await Promise.all(subCollections.map(async (subCollectionName) => {
                                    const subCollectionRef = collection(current.ref, subCollectionName);
                                    const subCollectionDocs = await getDocs(subCollectionRef);
                                    return {
                                        [subCollectionName]: subCollectionDocs.docs.map(subDoc => ({
                                            id: subDoc.id,
                                            ...subDoc.data(),
                                        })),
                                    };
                                })
                                );
                                subCollectionData = Object.assign({}, ...subCollectionData);
                                return {
                                    id: current.id,
                                    ...current.data(),
                                    subCollectionData
                                };
                            }
                        }).filter(doc => doc !== undefined));
                        // setSearchLoading(true)

                        if (data[0]) {
                            const subCollectionEntries = Object.entries(data[0]?.subCollectionData).sort(([a], [b]) => a.localeCompare(b));

                            const driversEarning = subCollectionEntries.map(([key, value]) => {
                                const found = value.find(doc => doc.id === "allAnalysis");
                                return found ? (found?.priceRides - found?.companyPercentage) : 0;
                            });
                            setDriversEarningMonthly(driversEarning);
                            let driversEarningResult = [];
                            for (let i = 0; i < driversEarning?.length; i += 3) {
                                let sum = driversEarning[i] + driversEarning[i + 1] + driversEarning[i + 2];
                                driversEarningResult.push(sum);
                            }
                            setDriversEarningQuarterly(driversEarningResult);

                            let driversEarningValues = [0, 0, 0, 0, 0, 0, 0]
                            const driversEarningWeekly = subCollectionEntries.flatMap(([key, value]) => {
                                const filteredDocs = value.filter(doc => doc.id !== "driver").filter(doc => key === currentMonthNOmber);
                                // Map over the filtered documents and return the companyPercentage or 0 if not found
                                return filteredDocs.map(doc => lastSevenDaysNumbers.includes(doc.id) ? doc : 0);
                            });
                            driversEarningWeekly.map((doc) => {
                                if (lastSevenDaysNumbers.includes(doc.id)) {
                                    driversEarningValues[lastSevenDaysNumbers.indexOf(doc.id)] = doc?.priceRides - doc?.companyPercentage || 0;
                                }

                            })
                            setDriversEarningWeekly(driversEarningValues);
                        }

                    } else if (driverId?.length > 3 && driverId) {
                        setDriversEarningMonthly([]);
                        setDriversEarningQuarterly([]);
                        setDriversEarningWeekly([]);
                    }
                }));
                setSearchLoading(false)
            });



            return () => unsubscribe();
        };
        fetchData(search)

    };

    useEffect(() => {
        if (search?.length > 0) {
            fetchDriversData(search);
        }
    }, [search]);



    const handleDriverSelect = (event, value) => {
        setSearch(value?.id);
        setSelectedDriver(true)
        // setSearchLoading(true)
    };

    const fetchDrivers = () => {
        getMethod({
            url: `driver`,
            params: {
                limit: 9999999999999
            }
        })
            .then((response) => {
                const result = response.data;
                setDrivers(result)
            })
            .catch((err) => {
                console.error("Error fetching admin data:", err);
                if (err === "Internal Server Error: Server error occurred.") {
                    handleError5xx();
                    setTryAgain(() => fetchDrivers);
                }
            });
    }
    useEffect(() => {
        fetchDrivers();
    }, []);

    useEffect(() => {
        // Reset search and options when selectedYear changes
        setSavedDriversEarningMonthly(null)
        setSearch('');
        setOptions([]);
    }, [selectedYear]);

    return (
        <Box sx={{ py: 3 }}>
            <Error5xx open={isErrorDialogOpen} errCount={errorCount} count={errorDialogCounter} handleClose={handleCloseErrorDialog} tryAgain={tryAgain} />
            <Grid container spacing={3}>
                <Grid item xs={12} lg={7}>
                    <Paper sx={{ p: 2, px: 3, borderRadius: '20px', boxShadow: 'none', height: '100%' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                            <Typography sx={{ color: '#081735', fontSize: 18, fontWeight: 600 }} component="div">
                                Revenue
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                                <LegendBox>
                                    <LegendItem>
                                        <ColorDot sx={{ backgroundColor: '#7DD1DA' }} />
                                        <Typography sx={{ fontSize: 12 }}>{' < 150'}</Typography>
                                    </LegendItem>
                                    <LegendItem>
                                        <ColorDot sx={{ backgroundColor: '#A2C933' }} />
                                        <Typography sx={{ fontSize: 12 }}> {'< 200'}</Typography>
                                    </LegendItem>
                                    <LegendItem>
                                        <ColorDot sx={{ backgroundColor: colorConfigs?.sidebar?.bg }} />
                                        <Typography sx={{ fontSize: 12 }}> {'> 200'}</Typography>
                                    </LegendItem>
                                </LegendBox>
                                <CustomFormControl variant="filled">
                                    <CustomInputLabel id="revenue-time-period-label">Monthly</CustomInputLabel>
                                    <CustomSelect
                                        labelId="revenue-time-period-label"
                                        id="revenue-time-period-select"
                                        value={revenueTimePeriod}
                                        onChange={handleRevenueTimePeriodChange}
                                        disableUnderline
                                    >
                                        <MenuItem value="Daily">Daily</MenuItem>
                                        <MenuItem value="Monthly">Monthly</MenuItem>
                                        <MenuItem value="Quarterly">Quarterly</MenuItem>
                                    </CustomSelect>
                                </CustomFormControl>
                            </Box>
                        </Box>
                        <Bar data={barDataRevenue} options={barOptions} />
                        <Box sx={{ display: 'flex', justifyContent: 'end', flexWrap: 'wrap', gap: 3, mt: 2 }}>
                            <Box sx={{
                                textAlign: 'center',
                                backgroundColor: '#f7f9fc', // Adjust the background color as needed
                                padding: '0px 15px',
                                borderRadius: '8px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: 1,
                                height: '45px'
                            }}>
                                <Typography sx={{ color: '#4F6071', fontSize: 12, fontWeight: 500 }}>Annual Earning : </Typography>
                                <Typography sx={{ color: '#1B2559', fontWeight: 800, fontSize: 16 }}>${formatNumber(circleStatistics?.companyPercentage ? circleStatistics?.companyPercentage?.toFixed(2) : 0)}</Typography>
                            </Box>
                            <Box sx={{
                                textAlign: 'center',
                                backgroundColor: '#f7f9fc', // Adjust the background color as needed
                                padding: '0px 15px',
                                borderRadius: '8px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: 1,
                                height: '45px'
                            }}>
                                <Typography sx={{ color: '#4F6071', fontSize: 12, fontWeight: 500 }}>Annual Drivers Earning : </Typography>
                                <Typography sx={{ color: '#1B2559', fontWeight: 800, fontSize: 16 }}>${formatNumber(savedDriversEarningMonthly?.reduce((accumulator, currentValue) => accumulator + currentValue, 0).toFixed(2)) || 0}</Typography>
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12} lg={5}>
                    <Paper sx={{ p: 2, px: 3, borderRadius: '20px', boxShadow: 'none', height: '100%' }}>
                        <Typography sx={{ color: '#081735', fontSize: 18, fontWeight: 600 }} component="div">
                            Rides
                        </Typography>
                        <DoughnutContainer>
                            <Box sx={{ position: 'relative', height: '260px', width: '260px' }}>
                                <Doughnut data={doughnutData} options={doughnutOptions} style={{ boxShadow: 'rgba(108, 93, 211, 0.1) 0px 8px 22px', borderRadius: 200, padding: 10 }} />
                                <CenteredDiv>
                                    <Typography sx={{ fontSize: 24, fontWeight: 'bold' }}>{formatNumber(circleStatistics?.totalRide || 0)}</Typography>
                                    <Typography sx={{ fontSize: 16 }}>Total<br /> Rides</Typography>
                                </CenteredDiv>
                            </Box>
                            <LegendBoxDoughnut>
                                {activeShow && (<LegendItem>
                                    <ColorDot sx={{ backgroundColor: '#9e93fb' }} />
                                    <Typography sx={{ fontSize: 12, fontWeight: 600 }}>Active Rides</Typography>
                                </LegendItem>)}
                                <LegendItem>
                                    <ColorDot sx={{ backgroundColor: '#e26968' }} />
                                    <Typography sx={{ fontSize: 12, fontWeight: 600 }}>Canceled Rides</Typography>
                                </LegendItem>
                                <LegendItem>
                                    <ColorDot sx={{ backgroundColor: '#3eb1a7' }} />
                                    <Typography sx={{ fontSize: 12, fontWeight: 600 }}>Dispatched Rides</Typography>
                                </LegendItem>
                                <LegendItem>
                                    <ColorDot sx={{ backgroundColor: '#acd44c' }} />
                                    <Typography sx={{ fontSize: 12, fontWeight: 600 }}>Scheduled Rides</Typography>
                                </LegendItem>
                            </LegendBoxDoughnut>
                        </DoughnutContainer>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', mt: 2 }}>
                            {stats?.map((stat) => (
                                <StatBox key={stat.label} >
                                    <StatCircleBox style={{ background: stat.background, borderRadius: 50 }}>
                                        <StatCircle variant="determinate" value={stat.value} thickness={5} size={2} />
                                        <StatCircle
                                            variant="determinate"
                                            value={circleStatistics?.totalRide ? (Math.ceil((stat.value / circleStatistics?.totalRide) * 100)) : circleStatistics?.totalRide}
                                            thickness={4}
                                            size={50}
                                            sx={{ color: stat.color }}
                                        />
                                        <StatCircleOverlay>
                                            <Box sx={{ background: '#FFFFFF', p: 1, height: 35, width: 35, borderRadius: 50, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <MdOutlineShowChart size={25} color={stat.color} />
                                            </Box>
                                        </StatCircleOverlay>
                                    </StatCircleBox>
                                    <StatTextBox>
                                        <Typography sx={{ fontSize: 14, fontWeight: 'bold', color: stat.color }}>
                                            {formatNumber(stat?.value || 0)}
                                        </Typography>
                                        <Typography sx={{ fontSize: 12, color: '#6c757d' }}>{stat.label}</Typography>
                                    </StatTextBox>
                                </StatBox>
                            ))}
                        </Box>
                    </Paper>
                </Grid>

                {/* Total Rides Section */}
                <Grid item xs={12} lg={7}>
                    <Paper sx={{ p: 2, px: 3, borderRadius: '20px', boxShadow: 'none', height: '100%' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                            <Box sx={{
                                textAlign: 'center',
                                padding: '0px 15px',
                                borderRadius: '8px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: 1,
                                height: '45px'
                            }}>
                                <Typography sx={{ color: '#4F6071', fontSize: 12, fontWeight: 500 }}>Total Rides : </Typography>
                                <Typography sx={{ color: '#1B2559', fontWeight: 800, fontSize: 16 }}>
                                    {
                                        ridesTimePeriod === 'Daily' ?
                                            formatNumber(ridesWeekly.reduce((accumulator, currentValue) => accumulator + currentValue, 0)) || 0 :
                                            ridesTimePeriod === 'Monthly' ? formatNumber(statistics?.totalRide) || 0 : ridesTimePeriod === 'Quarterly' ?
                                                formatNumber(Math.floor(statistics?.totalRide) || 0) : 0
                                    }
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                                <LegendBox>
                                    <LegendItem>
                                        <ColorDot sx={{ backgroundColor: '#7DD1DA' }} />
                                        <Typography sx={{ fontSize: 12 }}>{' < 150'}</Typography>
                                    </LegendItem>
                                    <LegendItem>
                                        <ColorDot sx={{ backgroundColor: '#A2C933' }} />
                                        <Typography sx={{ fontSize: 12 }}> {'< 200'}</Typography>
                                    </LegendItem>
                                    <LegendItem>
                                        <ColorDot sx={{ backgroundColor: colorConfigs?.sidebar?.bg }} />
                                        <Typography sx={{ fontSize: 12 }}> {'> 200'}</Typography>
                                    </LegendItem>
                                </LegendBox>
                                <CustomFormControl variant="filled">
                                    <CustomInputLabel id="rides-time-period-label">Monthly</CustomInputLabel>
                                    <CustomSelect
                                        labelId="rides-time-period-label"
                                        id="rides-time-period-select"
                                        value={ridesTimePeriod}
                                        onChange={handleRidesTimePeriodChange}
                                        disableUnderline
                                    >
                                        <MenuItem value="Daily">Daily</MenuItem>
                                        <MenuItem value="Monthly">Monthly</MenuItem>
                                        <MenuItem value="Quarterly">Quarterly</MenuItem>
                                    </CustomSelect>
                                </CustomFormControl>
                            </Box>
                        </Box>
                        <Bar data={barDataTotalRides} options={barOptions} />
                        <Box sx={{ display: 'flex', justifyContent: 'end', flexWrap: 'wrap', gap: 3, mt: 2 }}>
                            <Box sx={{
                                textAlign: 'center',
                                backgroundColor: '#f7f9fc', // Adjust the background color as needed
                                padding: '0px 15px',
                                borderRadius: '8px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: 1,
                                height: '45px'
                            }}>
                                <Typography sx={{ color: '#4F6071', fontSize: 12, fontWeight: 500 }}>
                                    {ridesTimePeriod === 'Daily' ? 'Daily Avg Rides' : ridesTimePeriod === 'Monthly' ? 'Monthly Avg Rides' : 'Quarterly Avg Rides'}
                                </Typography>
                                <Typography sx={{ color: '#1B2559', fontWeight: 800, fontSize: 16 }}>
                                    {
                                        ridesTimePeriod === 'Daily' ?
                                            formatNumber(Math?.ceil((ridesWeekly.reduce((accumulator, currentValue) => accumulator + currentValue, 0)) / 7) || 0) :
                                            ridesTimePeriod === 'Monthly' ? formatNumber((Math.ceil(circleStatistics?.totalRide / 12)) || 0) : ridesTimePeriod === 'Quarterly' ?
                                                formatNumber((Math.floor((statistics?.totalRide) / 4)) || 0) : 0
                                    }
                                </Typography>
                            </Box>
                        </Box>
                    </Paper>
                </Grid>

                <Grid item xs={12} lg={5}>
                    <Paper sx={{ p: 2, px: 3, borderRadius: '20px', boxShadow: 'none', height: '100%' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                            <Typography sx={{ color: '#4F6071', fontSize: 12, fontWeight: 500 }} component="div">
                                {selectedDriver ? 'Total Driver Earning : ' : 'Total Drivers Earning : '}
                                <Typography sx={{ color: '#081735', fontSize: 18, fontWeight: 800 }} component="span">
                                    ${
                                        driversEarningTimePeriod === 'Daily' ?
                                            `${formatNumber(driversEarningWeekly.reduce((accumulator, currentValue) => accumulator + currentValue, 0).toFixed(2))}` :
                                            driversEarningTimePeriod === 'Monthly' ? `${formatNumber(driversEarningMonthly.reduce((accumulator, currentValue) => accumulator + currentValue, 0).toFixed(2))}` : driversEarningTimePeriod === 'Quarterly' ?
                                                `${formatNumber(driversEarningQuarterly.reduce((accumulator, currentValue) => accumulator + currentValue, 0).toFixed(2))}` : 0
                                    }
                                </Typography>
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                                <CustomFormControl variant="filled">
                                    <CustomInputLabel id="drivers-earning-time-period-label">Monthly</CustomInputLabel>
                                    <CustomSelect
                                        labelId="drivers-earning-time-period-label"
                                        id="drivers-earning-time-period-select"
                                        value={driversEarningTimePeriod}
                                        onChange={handleDriversEarningTimePeriodChange}
                                        disableUnderline
                                    >
                                        <MenuItem value="Daily">Daily</MenuItem>
                                        <MenuItem value="Monthly">Monthly</MenuItem>
                                        <MenuItem value="Quarterly">Quarterly</MenuItem>
                                    </CustomSelect>
                                </CustomFormControl>
                            </Box>
                        </Box>
                        <Box sx={{ mb: 2 }}>
                            <Autocomplete
                                freeSolo
                                options={options}
                                getOptionLabel={(option) => {
                                    return typeof option === 'string' ? option : option?.full_name || '';
                                }}
                                onChange={handleDriverSelect}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        variant="outlined"
                                        placeholder="Search by Driver Name or ID."
                                        value={search}
                                        onKeyUp={handleSearchChange}
                                        InputProps={{
                                            ...params.InputProps,
                                            style: { backgroundColor: 'transparent', borderRadius: '10px', height: 45, padding: '0 10px' },
                                        }}
                                        style={{ marginTop: '10px' }}
                                    />
                                )}
                            />
                        </Box>
                        <Bar data={barDataDriversEarning} options={barOptions} style={{ marginTop: '70px' }} />
                    </Paper>
                </Grid>
            </Grid>
            <Loading
                loading={!!loading}
                background="rgba(86, 100, 210, 0.1)"
                loaderColor={colorConfigs.sidebar?.bg}
            />
            <Loading
                loading={!!searchLoading}
                background="rgba(86, 100, 210, 0.1)"
                loaderColor={colorConfigs.sidebar?.bg}
            />
        </Box>
    );
}

export default Dashboard;
