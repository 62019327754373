import React, { useEffect, useState, useRef } from 'react';
import {
    Box,
    Paper,
    Typography,
    TextField,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Avatar,
    Button,
    CircularProgress,
    Divider,
} from '@mui/material';
import { getMethod } from '../../Hook/UseCustomApi';
import colorConfigs from '../../../configs/colorConfigs';

function AssignRideModal() {
    const [drivers, setDrivers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedDriver, setSelectedDriver] = useState(null);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [isFetching, setIsFetching] = useState(false);
    const listRef = useRef(null);


    const fetchDrivers = async (searchTerm, page) => {
        setIsFetching(true);
        try {
            const response = await getMethod({
                url: 'driver',
                params: {
                    page,
                    limit: 10,
                    search_first_name: searchTerm || '',
                    search_last_name: searchTerm || '',
                },
            });

            if (response?.data?.length > 0) {
                setDrivers((prevDrivers) => [...prevDrivers, ...response.data]);
                setHasMore(true);
            } else {
                setHasMore(false);
            }

            setLoading(false);
            setIsFetching(false);
        } catch (error) {
            console.error('Error fetching drivers:', error);
            setLoading(false);
            setIsFetching(false);
        }
    };


    useEffect(() => {
        setDrivers([]);
        setPage(1);
        setHasMore(true);
        fetchDrivers(searchTerm, 1);
    }, [searchTerm]);


    useEffect(() => {
        if (page > 1) {
            fetchDrivers(searchTerm, page);
        }
    }, [page]);


    const handleScroll = () => {
        if (!listRef.current) return;
        const { scrollTop, scrollHeight, clientHeight } = listRef.current;
        if (scrollHeight - scrollTop - clientHeight <= 1 && hasMore && !isFetching) {
            setPage((prevPage) => prevPage + 1);
        }
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleDriverSelect = (driver) => {
        setSelectedDriver(driver);
    };

    const handleAssign = () => {
        if (selectedDriver) {
            console.log('Assigned driver:', selectedDriver);
        }
    };

    return (
        <Paper elevation={3} sx={{ padding: 3, width: 600, margin: '0 auto' }}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                Assign to driver
            </Typography>
            <TextField
                placeholder="Search by Driver Name"
                fullWidth
                variant="outlined"
                value={searchTerm}
                onChange={handleSearchChange}
                sx={{ marginBottom: 2, borderRadius: '8px' }}
            />
            <Box
                ref={listRef}
                sx={{
                    height: 300,
                    overflowY: 'auto',
                    position: 'relative',
                }}
                onScroll={handleScroll}
            >
                {drivers.length === 0 && !loading ? (
                    <Typography>No drivers found</Typography>
                ) : (
                    <List>
                        {drivers.map((driver) => (
                            <React.Fragment key={driver._id}>
                                <ListItem
                                    button
                                    selected={selectedDriver?._id === driver?._id}
                                    onClick={() => handleDriverSelect(driver)}
                                    sx={{
                                        borderRadius: '8px',
                                        marginBottom: '8px',
                                        backgroundColor:
                                            selectedDriver?._id === driver?._id ? colorConfigs?.sidebar?.ligthBg : 'white',
                                        '&:hover': {
                                            backgroundColor:colorConfigs?.sidebar?.ligthBg,
                                        },
                                    }}
                                >
                                    <ListItemAvatar>
                                        <Avatar src={driver?.profile_picture || ''} alt={driver?.first_name} />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Typography sx={{ fontWeight: 'bold', fontSize: '1rem' }}>
                                                {driver?.first_name} {driver?.last_name}
                                            </Typography>
                                        }
                                        secondary={
                                            <>
                                                <Typography variant="body2" color="textSecondary">
                                                    {driver?.company}
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    sx={{ fontWeight: 'bold' }}
                                                >
                                                    {driver?.distance} 3 KM
                                                </Typography>
                                            </>
                                        }
                                    />

                                    <Divider />
                                </ListItem>
                            </React.Fragment>
                        ))}
                        {loading && (
                            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                                <CircularProgress size={24} />
                            </Box>
                        )}
                    </List>
                )}
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                <Button variant="outlined" sx={{ borderRadius: '20px', width: '45%' }}>
                    Cancel
                </Button>
                <Button
                    onClick={handleAssign}
                    variant="contained"
                    color="primary"
                    sx={{ borderRadius: '20px', width: '45%' }}
                    disabled={!selectedDriver}
                >
                    Assign
                </Button>
            </Box>
        </Paper>
    );
}

export default AssignRideModal;
