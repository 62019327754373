import { Avatar, Box, Typography } from "@mui/material";

export const PaymentMethod = ({ img, text }) => {
    return (
        <Box>
            <Box
                sx={{
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderRadius: "5px",
                }}
            >
                <Box sx={{ display: "flex", alignItems: "center" ,gap:3 }}>
                    <Avatar
                        src={img}
                        alt="payment-method"
                        sx={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "5px",
                        }}
                    />
                        <Typography
                            variant="subtitle1"
                            sx={{ color: "gray", fontSize: "1rem" }}
                        >
                            {text}
                        </Typography>
                </Box>
            </Box>
        </Box>
    );
};

