// Purpose: To store constants that are used throughout the application.
let API_URL = '';
let GOOGLE_API_KEY = '';

// when used empty [] style is used standard Default 👌
let mapStyles = [
  // {
  //   "featureType": "administrative.land_parcel",
  //   "elementType": "labels",
  //   "stylers": [
  //     {
  //       "visibility": "off"
  //     }
  //   ]
  // },
  // {
  //   "featureType": "poi",
  //   "elementType": "labels.text",
  //   "stylers": [
  //     {
  //       "visibility": "off"
  //     }
  //   ]
  // },
  // {
  //   "featureType": "poi.business",
  //   "stylers": [
  //     {
  //       "visibility": "off"
  //     }
  //   ]
  // },
  // {
  //   "featureType": "poi.park",
  //   "elementType": "labels.text",
  //   "stylers": [
  //     {
  //       "visibility": "off"
  //     }
  //   ]
  // },
  // {
  //   "featureType": "road",
  //   "stylers": [
  //     {
  //       "visibility": "off"
  //     }
  //   ]
  // },
  // {
  //   "featureType": "road.local",
  //   "elementType": "labels",
  //   "stylers": [
  //     {
  //       "visibility": "off"
  //     }
  //   ]
  // }
]
if (process.env.REACT_APP_ENV === 'production') {
  API_URL = 'https://api-gateway-eh6kl422wq-uc.a.run.app';
  GOOGLE_API_KEY = 'AIzaSyAweoAjPS9g4lhzR5EqdTFpfOVK-dA7fGw';
} else if (process.env.REACT_APP_ENV === 'women_first') {
  API_URL = 'https://api-gateway-eh6kl422wq-uc.a.run.app';
  GOOGLE_API_KEY = 'AIzaSyAylkxa21mdd06h3VwU0WeOx4quBu7uNAY';
  // GOOGLE_API_KEY =  'AIzaSyDX-6AHolu80-Yb9SnXQEEluPr_qEduodQ';

}
else {
  API_URL = 'https://api-gateway-dyz7xqrgja-uc.a.run.app';;
  GOOGLE_API_KEY = 'AIzaSyDX-6AHolu80-Yb9SnXQEEluPr_qEduodQ';
}

export { API_URL, GOOGLE_API_KEY, mapStyles };