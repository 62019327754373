import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { db } from "../../configs/firebase";
import MessageListItem from "./message-list-item";
import { Box, Typography } from "@mui/material";
import moment from "moment";
import chat_empty from "../../assets/icons/start_chat.png";
import Fade from "react-reveal/Fade";
import { formatDate } from "../../utils/localization/dateTimeUtils";

const MessageViewerList = ({ allowSend, admin }) => {
  const params = useParams();
  const [messages, setMessages] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const selectedChat = useSelector((state) => state.appState.selectedChat);
  const selectedRide = useSelector((state) => state.appState.selectedRide);
  const outChat = useSelector((state) => state.appState.outChat);
  const lastSupportChat = useSelector((state) => state.appState.lastSupportChat);
  const uid =
    selectedChat?.type === "driver" ? selectedChat?.driver : selectedChat?.customer;
  const messagesEndRef = useRef(null);
  const bottomRef = useRef(null); // Reference to the bottom of the messages
  const firstLoad = useRef(true); // Track if it's the first load
  const [isNearBottom, setIsNearBottom] = useState(true); // Track if user is near bottom
  const countryCode = useSelector((state) => state.country.selectedCountry);

  // Function to handle chat ID based on selected ride and chat type
  const handleChatId = () => {
    if (selectedRide?.chat_type === "customer") {
      return `admin_${selectedRide?.customer_details?.fb_uid}`;
    } else {
      return `admin_${
        selectedRide?.driver_info?.fb_uid
          ? selectedRide?.driver_info?.fb_uid
          : selectedRide?.driver_info?.fb_id
      }`;
    }
  };

  // Fetch initial messages when component mounts or dependencies change
  useEffect(() => {
    setMessages([]);
    setLastVisible(null);
    firstLoad.current = true; // Reset firstLoad on chat change

    const jobId = outChat ? handleChatId() : uid;
    if (jobId) {
      const messageRef = db
        .collection("messaging_with_admin")
        .doc(jobId)
        .collection("messages")
        .orderBy("createAt", "desc")
        .limit(10);

      const unsubscribe = messageRef.onSnapshot((snapshot) => {
        if (!snapshot.empty) {
          const messagesList = snapshot.docs.map((doc) => {
            const data = doc.data();
            data.id = doc.id;
            data.createAt = data.createAt ? data.createAt.toDate() : null;
            return data;
          });
          // Reverse to get messages in chronological order
          setMessages(messagesList.reverse());
          setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
        } else {
          setMessages([]);
        }
      });

      return () => unsubscribe();
    }
  }, [
    selectedChat?.driver,
    selectedChat?.customer,
    selectedRide,
    lastSupportChat,
    outChat,
    uid,
  ]);

  // Handle scrolling to fetch more messages when user scrolls to top
  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;

    // Check if user is near the bottom
    const atBottom = scrollHeight - scrollTop <= clientHeight + 100; // Threshold of 100px
    setIsNearBottom(true);

    if (scrollTop === 0 && !isFetching && lastVisible) {
      fetchMoreMessages();
    }
  };

  // Fetch more messages from Firestore
  const fetchMoreMessages = () => {
    setIsFetching(true);
    const jobId = outChat ? handleChatId() : uid;
    const messageRef = db
      .collection("messaging_with_admin")
      .doc(jobId)
      .collection("messages")
      .orderBy("createAt", "desc")
      .startAfter(lastVisible)
      .limit(10);

    // Save the current scroll position
    const scrollPosition = messagesEndRef.current.scrollHeight;

    messageRef.get().then((snapshot) => {
      if (!snapshot.empty) {
        const newMessages = snapshot.docs.map((doc) => {
          const data = doc.data();
          data.id = doc.id;
          data.createAt = data.createAt ? data.createAt.toDate() : null;
          return data;
        });
        // Reverse new messages and prepend to existing messages
        setMessages((prevMessages) => [...newMessages.reverse(), ...prevMessages]);
        setLastVisible(snapshot.docs[snapshot.docs.length - 1]);

        // Adjust scroll position to prevent jumpiness
        setTimeout(() => {
          if (messagesEndRef.current) {
            messagesEndRef.current.scrollTop =
              messagesEndRef.current.scrollHeight - scrollPosition;
          }
        }, 0);
      } else {
        // No more messages to fetch
        setLastVisible(null);
      }
      setIsFetching(false);
    });
  };

  // Scroll to bottom on first load or when new messages arrive
  useEffect(() => {
    if (firstLoad.current && messages.length > 0) {
      // Scroll to the bottom when messages are first loaded
      if (bottomRef.current) {
        bottomRef.current.scrollIntoView();
      }
      firstLoad.current = false;
    } else if (!firstLoad.current && isNearBottom) {
      // If new messages are added and user is near bottom, scroll to bottom
      if (bottomRef.current) {
        bottomRef.current.scrollIntoView();
      }
    }
  }, [messages, isNearBottom]);

  return (
    <div
      ref={messagesEndRef}
      onScroll={handleScroll}
      style={{
        display: "flex",
        flexDirection: "column",
        height: "66%",
        overflowY: "auto",
        position: "relative",
      }}
    >
      {messages.length > 0 ? (
        <div>
          {messages.map((message, index) => (
            <div
              key={message.id}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems:
                  message.isSupport ||
                  message?.text ===
                    "Hello! Thanks for joining the Ride Wyze Community."
                    ? "flex-end"
                    : "flex-start",
                wordBreak: "break-word",
              }}
            >
              {(index === 0 ||
                (index !== 0 &&
                  message.createAt.getTime() -
                    messages[index - 1].createAt.getTime() >
                    3600000)) && (
                <div
                  style={{
                    margin: "8px 0",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="caption" style={{ color: "grey" }}>
                    {formatDate(message.createAt,countryCode)}
                    {/* {moment(message.createAt).format("MMM DD, hh:mm A")} */}
                  </Typography>
                </div>
              )}

              <Fade bottom distance="12px" duration={300}>
                <MessageListItem
                  message={message}
                  messages={messages}
                  index={index}
                  nextUid={
                    index >= messages.length - 1
                      ? null
                      : messages[index + 1]?.uid
                  }
                  enablerUid={message?.uid}
                  admin_info={message?.admin_info}
                />
              </Fade>
            </div>
          ))}
          {/* Empty div to scroll into view */}
          <div ref={bottomRef} />
        </div>
      ) : messages.length === 0 ? (
        <Box
          sx={{
            position: "absolute",
            top: "38%",
            left: "50%",
            transform: "translateX(-50%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <img src={chat_empty} height={60} width={60} alt="No Chat" />
          <Typography
            sx={{
              color: "#C1C7D0",
              fontSize: "20px",
              fontWeight: 600,
              textAlign: "center",
            }}
          >
            No Chat Here
          </Typography>
        </Box>
      ) : (
        ""
      )}
    </div>
  );
};

export default MessageViewerList;
