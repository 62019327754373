import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  MenuItem,
  Select,
  Snackbar,
  Typography
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import fare from "../../assets/icons/wallet balance.png";
import fareWf from "../../assets/icons/wallet balance wf.png";
import Loading from "react-fullscreen-loading";
import { IoEyeSharp } from 'react-icons/io5';
import { useDispatch, useSelector } from "react-redux";
import deletee from "../../assets/images/delete.png";
import colorConfigs from "../../configs/colorConfigs";
import { openModal } from "../../redux/features/modalSlice";
import { formatCurrency } from "../../utils/localization/currencyUtils";
import { formatDate } from "../../utils/localization/dateTimeUtils";
import { DeleteMethod, getMethod } from "../Hook/UseCustomApi";
import DynamicTable from "../table/table";
import BadgeComponent from "./BadgeComponent";
import RiderWalletView from './RiderWalletView';
import Error5xx from '../../components/common/HandelError5xx';
import { Flex } from 'antd';

export default function RiderWallet({ driverId, isDriver }) {
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [status, setStatus] = useState("all");
  const [query, setQuery] = useState("");
  const [filterType, setFilterType] = useState("all");
  const selectedUser = useSelector((state) => state.appState.selectedUser);
  const rowsLimits = [5, 10, 25, 100];
  const countryCode = useSelector((state) => state.country.selectedCountry);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const addNewBalance = useSelector((state) => state.appState.addNewBalance);
  const locale = countryCode === "USD" ? "en-US" : "ar-EG";
  const currencyCode =
    countryCode === "USD" ? "$" : countryCode === "EG" ? "EGP" : countryCode === 'JOD' ? "JOD" : '$';
  const dispatch = useDispatch();

  //handle error5xx
  const [tryAgain, setTryAgain] = useState();
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [errorCount, setErrorCount] = useState(0);
  const [errorDialogCounter, setErrorDialogCounter] = useState(0);
  const handleError5xx = () => {
    setIsErrorDialogOpen(true);
    setErrorCount(prevCount => {
      const newCount = prevCount + 1;
      if (newCount === 1) {
        setErrorDialogCounter(5);
      } else if (newCount === 2) {
        setErrorDialogCounter(10);
      }
      return newCount;
    });
  };
  const handleCloseErrorDialog = () => {
    setIsErrorDialogOpen(false);
    if (errorCount <= 2) {
      handleErrorCountByClose()
    }

  };
  const handleErrorCountByClose = () => {
    setErrorCount(prevCount => {
      const newCount = prevCount - 1;
      if (newCount === 1) {
        setErrorDialogCounter(5);
      } else if (newCount === 2) {
        setErrorDialogCounter(10);
      }
      return newCount;
    });
  }



  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  let currentPage = useRef();
  let currentLimit = useRef(10);
  let currentKeyword = useRef();
  let currentStatus = useRef();

  const openDeleteDialog = (id) => {
    setDeleteItemId(id);
    setDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setDeleteItemId(null);
  };

  const handleDelete = (id) => {
    openDeleteDialog(id);
  };


  const deleteData = () => {
    DeleteMethod({
      url: `delivery/${deleteItemId}`,
    })
      .then(() => {
        fetchDrivers();
      })
      .catch((error) => {
        console.error("Error deleting data:", error);
        if (error === "Internal Server Error: Server error occurred.") {
          handleError5xx();
          setTryAgain(() => deleteData);
        }
      })
      .finally(() => {
        closeDeleteDialog();
      });
  };

  useEffect(() => {
    currentPage.current = 1;
    currentLimit.current = 10;
    currentKeyword.current = "";
    currentStatus.current = "all";
    fetchDrivers();
  }, [addNewBalance]);

  const getParams = () => {
    return {
      page: currentPage.current,
      limit: currentLimit.current,
      status: currentStatus.current,
      keyword: currentKeyword.current,
    };
  };

  const getParam = () => {
    return {
      page: currentPage.current,
      limit: currentLimit.current,
      filter: filterType,
    };
  };

  const fetchDrivers = () => {
    setLoading(true);

    getMethod({
      url: isDriver ? `driver/wallet/history/${driverId}` : `customer/wallet/history/${driverId}`,
      params: getParam(),
      handleErrorMessage: showSnackbar,
    })
      .then((response) => {
        let result = response?.data;
        console.log(result);
        setData(result);
        setCount(response?.count);
      })
      .catch((error) => {
        console.error("Error fetching more stores:", error);
        if (error === "Internal Server Error: Server error occurred.") {
          handleError5xx();
          setTryAgain(() => fetchDrivers);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchMoreDrivers = async (Type) => {
    setLoading(true);

    getMethod({
      url: isDriver ? `driver/wallet/history/${driverId}` : `customer/wallet/history/${driverId}`,
      params: { ...getParams(), type: Type },
      handleErrorMessage: showSnackbar,
    })
      .then((response) => {
        let result = response?.data;
        setData(result);
        setCount(response?.count);
      })
      .catch((error) => {
        console.error("Error fetching more stores:", error);
        if (error === "Internal Server Error: Server error occurred.") {
          handleError5xx();
          setTryAgain(() => () => fetchMoreDrivers(Type));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
    currentStatus.current = event.target.value;
    currentPage.current = 1;
    setPage(1);
    fetchMoreDrivers();
  };

  const handleSearch = (e) => {
    const inputValue = e.target.value;
    setQuery(inputValue);
    setPage(1);
    currentPage.current = 1;
    currentKeyword.current = inputValue;
    fetchMoreDrivers(filterType);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    currentPage.current = newPage;
    fetchMoreDrivers(filterType);
  };

  const handleRowsChange = (rows) => {
    currentLimit.current = rows;
    currentPage.current = 1;
    setPage(1);
    fetchMoreDrivers(filterType);
  };

  const handleFilterTypeChange = (event) => {
    setFilterType(event.target.value);
    fetchMoreDrivers(event.target.value);
  };

  const style = {
    color: "#495974",
    fontFamily: "poppines",
    fontSize: "14px",
    fontWeight: 500,
    textTransform: "capitalize",
    p: 0
  };

  const columns = [
    {
      field: "short_id",
      headerName: "Transaction ID",
      editable: true,
      style: style,
      align: "left",
    },
    {
      field: "type",
      headerName: "Type",
      minWidth: "100px",
      editable: true,
      style: style,
      align: "left",
      renderCell: (params) => {
        const handleText = (trans) => {
          switch (trans?.type) {
            case 'top-up':
              return "Wallet Top Up";
            case 'admin-charge':
              return "admin-charge";
            case 'payout':
              return "Payout";
            case 'ride':
              return "Ride #" + trans?.option?.short_id;
            case 'send-money':
              return "Send Money";
            case 'apple-pay':
              return "Apple Pay";
            case 'google-pay':
              return "Google Pay";
            case 'received-money':
              return "Received Money";
            default:
              return trans?.type;
          }
        }
        return <div>
          <BadgeComponent trans={{ ...params?.row }} text={handleText(params?.row)} />
        </div>
      }
    },
    {
      field: "created_at",
      headerName: "Transaction Date",
      minWidth: "200px",
      editable: true,
      style: style,
      align: "left",
      renderCell: (params) => {
        const dateObject = new Date(params?.row?.created_at);
        const formattedDate = formatDate(dateObject, countryCode);
        return <Typography>{formattedDate}</Typography>;
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      minWidth: "150px",
      editable: true,
      style: style,
      align: "center",
      renderCell: (params) => (
        <Typography >
          {params?.row?.amount_type === "incoming" ? "+" : "-"}
          {formatCurrency(params?.row?.amount, countryCode)}
        </Typography>
      ),
    },
    {
      headerName: "Action",
      minWidth: "50px",
      editable: true,
      style: style,
      align: "left",
      renderCell: (params) => (
        <Box
          onClick={() => {
            dispatch(openModal(<RiderWalletView trip={params?.row} />));
          }}
          sx={{
            background: '#F3F8FD',
            width: 'fit-content',
            borderRadius: 25,
            p: 1,
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <IoEyeSharp size={18} color='#5B93FF' />
        </Box>
      ),
    },
  ];

  return (
    <>
      {/* handleError5xx */}
      <Error5xx open={isErrorDialogOpen} errCount={errorCount} count={errorDialogCounter} handleClose={handleCloseErrorDialog} tryAgain={tryAgain} />
      <Dialog
        style={{ zIndex: 10, borderRadius: "30px" }}
        open={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent style={{ padding: "30px" }}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {isDeleteDialogOpen && <img src={deletee} alt="" />}
          </div>
          <DialogContentText
            id="alert-dialog-slide-description"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Typography
              variant="h6"
              style={{
                fontWeight: "bold",
                color: "black",
                marginTop: "10px",
                marginBottom: "8px",
              }}
            >
              Delete Ride
            </Typography>
          </DialogContentText>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to Delete Driver Ride?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            marginBottom: "20px",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
        >
          <Button
            variant="outlined"
            style={{
              color: loading ? "gray" : "black",
              borderColor: "lightgray",
              width: "100%",
            }}
            onClick={closeDeleteDialog}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            style={{
              background: loading ? "gray" : "var(--Error, #FD4343)",
              color: "white",
              width: "100%",
            }}
            onClick={deleteData}
            disabled={loading}
          >
            {loading ? "...Loading" : "Yes, Delete"}
          </Button>
        </DialogActions>
      </Dialog>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%", mb: 2 }}>
        <Box sx={{
          display: 'flex', alignItems: 'center', justifyContent: "space-between", minWidth: "350px",
          padding: "20px",
          height: "56px",
          boxShadow: '0px 4px 28px rgba(0, 0, 0, 0.06)',
          borderRadius: "12px",
        }}>
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", gap: 2, width: "100%" }}>
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 1 }}>
              <img src={process.env.REACT_APP_ENV === 'women_first' ? fareWf : fare} alt="loc" style={{ width: "25px", height: "25px" }} />
              <Typography variant="caption" style={{
                color: "rgba(73, 89, 116, 1)",
                fontFamily: 'Poppins',
                fontSize: 14,
                fontWeight: "700",
                lineHeight: "30px",

              }}>
                Total Balance{" : "}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Typography
                variant="caption"
                style={{
                  color: colorConfigs.sidebar?.bg,
                  fontFamily: 'Poppins',
                  fontSize: 20,
                  fontWeight: "700",
                  lineHeight: "40px",
                }}
              >
                {formatCurrency(
                  selectedUser?.wallet_balance.toFixed(2),
                  locale,
                  currencyCode
                )}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', minWidth: "200px", gap: 1 }}>
          <Typography sx={{ color: '#696969', fontWeight: 500, fontSize: 14 }}>
            Filter by :
          </Typography>
          <FormControl size="small" sx={{ borderRadius: '24px', border: '1px solid #ccc', padding: '2px 8px', minWidth: "150px" }}>
            <Select
              value={filterType}
              onChange={handleFilterTypeChange}
              displayEmpty
              IconComponent={ExpandMoreIcon}
              sx={{
                minWidth: 120,
                border: 'none',
                '.MuiOutlinedInput-notchedOutline': { border: "none" },
                '.MuiSelect-select': { padding: '6px 8px', display: 'flex', alignItems: 'center', },
              }}
              MenuProps={{
                disableScrollLock: true,
              }}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="ride">Ride</MenuItem>
              <MenuItem value="send-money">Send Money</MenuItem>
              <MenuItem value="received-money">Received Money</MenuItem>
              <MenuItem value="top-up">Top-up</MenuItem>
              <MenuItem value="payout">Payout</MenuItem>
              <MenuItem value="admin-charge">Admin Charge</MenuItem>
              <MenuItem value="google-pay">Google Pay</MenuItem>
              <MenuItem value="apple-pay">Apple Pay</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>


      <DynamicTable
        columns={columns}
        data={data}
        count={count}
        page={page}
        rowsLimits={rowsLimits}
        pageChange={handlePageChange}
        rowsChange={handleRowsChange}
        pageType="wallet"
      />
      <Loading
        loading={!!loading}
        background="rgba(86, 100, 210, 0.1)"
        loaderColor={colorConfigs.sidebar?.bg}
      />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleSnackbarClose} severity="error">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
