import * as React from "react"
import { memo } from "react"
const SvgComponent = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={50}
        height={50}
        fill="none"
        {...props}
    >
        <circle cx={25} cy={25} r={25} fill="#fff" />
        <path
            fill="red"
            d="M25.002 18.195a8.1 8.1 0 0 1 8.1 8.1v5.916a.813.813 0 0 1-.813.812H17.715a.813.813 0 0 1-.813-.812v-5.916a8.1 8.1 0 0 1 8.1-8.1Zm-.711-4.468h1.422a.203.203 0 0 1 .203.203v2.437a.203.203 0 0 1-.203.203H24.29a.203.203 0 0 1-.203-.203V13.93a.203.203 0 0 1 .203-.203Zm-7.789 3.14a.201.201 0 0 1 .222-.045c.024.01.047.026.066.044l1.723 1.724a.203.203 0 0 1 0 .287l-1.005 1.006a.203.203 0 0 1-.287 0l-1.724-1.724a.203.203 0 0 1 0-.287l1.005-1.006Zm17.002 0 1.005 1.005a.203.203 0 0 1 0 .287l-1.723 1.724a.204.204 0 0 1-.288 0l-1.005-1.006a.203.203 0 0 1 0-.287l1.724-1.724a.203.203 0 0 1 .287 0ZM16.877 34.647h16.25a.812.812 0 0 1 .812.813v.61a.203.203 0 0 1-.203.202H16.267a.203.203 0 0 1-.203-.203v-.61a.813.813 0 0 1 .813-.812Zm3.758-8.048v6.423h1.624V26.6h-1.625Z"
        />
    </svg>
)
const SecurityAlert = memo(SvgComponent)
export default SecurityAlert
