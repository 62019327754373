function formatAddress(address, countryCode) {
  const checkValues = (value) => (value !== null && value !== undefined && value !== '') ? value : '';

  if (!address) {
    return '';
  }

  const { address: fullAddress, city, route, state, zip_code, country, primary_line } = address;

  if (!city && !route && !state && !zip_code && !country && fullAddress) {
    return `${checkValues(fullAddress)}`;
  }

  let addressParts = [];

  switch (countryCode) {
    case 'US':
      addressParts = [checkValues(route), checkValues(city), checkValues(state), checkValues(zip_code), checkValues(country)];
      break;
    case 'EG':
      addressParts = [checkValues(primary_line), checkValues(city), checkValues(state), checkValues(country)];
      break;
    case 'JO':
      addressParts = [checkValues(city), checkValues(country), checkValues(zip_code)];
      break;
    case 'CA':
      addressParts = [checkValues(primary_line), checkValues(city), checkValues(state), checkValues(zip_code), checkValues(country)];
      break;
    default:
      addressParts = [checkValues(fullAddress)];
      break;
  }

  // Filter out empty strings and join the valid parts
  return addressParts.filter(part => part !== '').join(', ');
}

export { formatAddress };
