import HelpCenter from "../../components/support/HelpCenter";


export default function RiderHelpCenter() {


    return (
        <>
            <HelpCenter status={"riders"} />
        </>
    );
}
