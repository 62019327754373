import { Card, CardContent, Typography } from "@material-ui/core";
import { Box } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import icon from "../../assets/images/Icon.png";
import location from "../../assets/images/location.png";
import { formatAddress } from "../../utils/localization/addressUtils";
import Status from "../status/status";
import stopIcon from "../../assets/images/stop.png";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import colorConfigs from "../../configs/colorConfigs";

export default function RideCard({ ride }) {
  const countryCode = useSelector((state) => state.country.selectedCountry);
  const [showAll, setShowAll] = useState(false);


  return (
    <Box>
      <Card key={ride._id}>
        <CardContent>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="body2" color="textSecondary">
              Trip ID:
              <span style={{ color: "#172B4D", fontWeight: "600" }}>
                {ride.short_id && ride?.short_id}
              </span>
            </Typography>
            <div>
              <Status status={ride?.status} />
            </div>

          </div>

          {ride && (
            <div
              style={{
                position: "relative",
                fontFamily: "Poppins",
                paddingTop: "12px",
              }}
            >
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  padding: "5px",
                }}
              >
                <img src={location} alt="loc" />
                <Typography
                  variant="body2"
                  style={{
                    color: "#172B4D",
                    fontWeight: "600",
                    fontFamily: "Poppins",
                    marginLeft: "10px", // Add left margin for spacing
                  }}
                >
                  {formatAddress(ride?.pickup_address, countryCode)}.
                  <br />
                  {ride?.pickup_address.city &&
                    ride?.pickup_address.state &&
                    ride?.pickup_address.zip_code && (
                      <Typography
                        key={ride._id}
                        variant="caption"
                        style={{
                          color: "#7A869A",
                          marginTop: "3px",
                          fontFamily: "Poppins",
                        }}
                      >
                        {ride?.pickup_address.city},{" "}
                        {ride?.pickup_address.state},{" "}
                        {ride?.pickup_address.zip_code}.
                      </Typography>
                    )}
                </Typography>
              </div>

              {ride && ride?.stop_points && ride.stop_points.length > 2 ? (
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    padding: "5px",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img src={stopIcon} alt="loc" style={{ height: "38px", width: "38px" }} />
                    <Typography
                      variant="caption"
                      style={{
                        fontFamily: "Poppins",
                        color: "#7A869A",
                        marginTop: "3px",
                        marginLeft: "10px",
                      }}
                    >
                      Stop points: {ride.stop_points.length}
                    </Typography>
                  </div>
                  <div>
                    {!showAll ? (
                      <ExpandMoreIcon onClick={() => setShowAll(true)} style={{ fontSize: '28px', cursor: 'pointer', color: colorConfigs.sidebar.bg }} />
                    ) : (
                      <ExpandLessIcon onClick={() => setShowAll(false)} style={{ fontSize: '28px', cursor: 'pointer', color: colorConfigs.sidebar.bg }} />
                    )}
                  </div>
                </div>
              ) : (
                ride?.stop_points?.map((stopPoint, index) => (
                  <div
                    key={index}
                    style={{
                      alignItems: "center",
                      display: "flex",
                      padding: "5px",
                    }}
                  >
                    <img src={stopIcon} alt="loc" style={{ height: "38px", width: "38px" }} />
                    <Typography
                      variant="caption"
                      style={{
                        fontFamily: "Poppins",
                        color: "#7A869A",
                        marginTop: "3px",
                        marginLeft: "10px",
                      }}
                    >
                      {stopPoint.address}
                    </Typography>
                  </div>
                ))
              )}

              {showAll && ride?.stop_points && ride.stop_points.length > 2 && ride.stop_points.map((stopPoint, index) => (
                <div
                  key={index}
                  style={{
                    alignItems: "center",
                    display: "flex",
                    padding: "5px",
                    marginLeft: "60px",
                  }}
                >
                  <img src={stopIcon} alt="loc" style={{ height: "38px", width: "38px" }} />
                  <Typography
                    variant="caption"
                    style={{
                      fontFamily: "Poppins",
                      color: "#7A869A",
                      marginTop: "3px",
                      marginLeft: "10px",
                    }}
                  >
                    {stopPoint.address}
                  </Typography>
                </div>
              ))}

              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  padding: "5px",
                }}
              >
                <img src={icon} alt="loc" />
                <Typography
                  variant="body2"
                  style={{
                    color: "#172B4D",
                    fontWeight: "600",
                    fontFamily: "Poppins",
                    marginLeft: "10px", // Add left margin for spacing
                  }}
                >
                  {ride?.delivery_address?.address}
                  <br />
                  {ride?.delivery_address.city &&
                    ride?.delivery_address.state &&
                    ride?.delivery_address.zip_code && (<Typography
                      key={ride._id}
                      variant="caption"
                      style={{
                        color: "#7A869A",
                        marginTop: "3px",
                        fontFamily: "Poppins",
                      }}
                    >
                      {ride?.delivery_address.city}, {ride?.delivery_address.state},{" "}
                      {ride?.delivery_address.zip_code}.
                    </Typography>)}

                </Typography>
              </div>
            </div>
          )}
        </CardContent>
      </Card>
    </Box>
  );
}
