import { Avatar, Box, Card, CardContent, Divider, Grid, IconButton, Typography, styled } from '@mui/material';
import { format } from 'date-fns';
import React from 'react';
import { FaRegCalendarAlt } from "react-icons/fa";
import { GoClock } from "react-icons/go";
import { LuClock4 } from "react-icons/lu";
import { useSelector } from 'react-redux';
import amex from "../../assets/paymentMethods/Amex.png";
import discover from "../../assets/paymentMethods/diccover.png";
import dinars from "../../assets/paymentMethods/diners.png";
import geniric from "../../assets/paymentMethods/geniric.png";
import jbc from "../../assets/paymentMethods/jcb.png";
import mastercard from "../../assets/paymentMethods/mastercard.png";
import unionpay from "../../assets/paymentMethods/unionPay.png";
import visa from "../../assets/paymentMethods/visa.png";
import { PaymentMethod } from '../../components/common/PaymentMethod';
import colorConfigs from '../../configs/colorConfigs';
import WalletVector from './../../assets/svg/WalletVector';
import ClickablePhoneNumber from '../../utils/ClickablePhoneNumber';
import CloseIcon from '@mui/icons-material/Close';
import { closeModal } from "../../redux/features/modalSlice";
import { useDispatch } from "react-redux";
import rrLogo from "./../../assets/logo.png"
import wfLogo from "./../../assets/womens-first/logowithpbg.png"



const StyledCard = styled(Card)(({ theme }) => ({
    maxWidth: '95%',
    width: '550px',
    margin: '0 auto',
    padding: theme.spacing(2),
    position: 'relative',
    maxHeight: '93vh',
    overflowY: 'auto',
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
}));

const UserInfo = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
}));

const UserAvatar = styled(Avatar)(({ theme }) => ({
    marginRight: theme.spacing(1),
    width: '30px',
    height: '30px',
}));
const PaymentSummary = styled(Box)(({ theme }) => ({
    border: '1px solid #EFEFEF',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    boxShadow: '0 0 40px rgba(110, 135, 171, 0.11)',
}));


const RiderWalletView = ({ trip }) => {
    const dispatch = useDispatch();
    console.log(trip);
    const timestamp = trip?.created_at;
    const date = new Date(timestamp);
    const formattedDate = format(date, 'EEEE, MMMM d, yyyy');
    const formattedTime = format(date, 'hh:mm a');
    const selectUser = useSelector((state) => state.appState.selectedUser);
    const isRidersURL = window.location.href.includes("riders");
    console.log(selectUser);

    return (
        <StyledCard>
            <Typography sx={{ color: '#172B4D', fontSize: '20px', fontWeight: 700 }}>
                {
                    trip?.type === 'received-money' ? 'Received Money' : trip?.type === 'send-money' ? 'Send Money' : trip?.type === 'top-up' ? 'Top up' : trip?.type === 'payout' ? 'Payout' :
                        trip?.type === 'admin-charge' ? 'Admin Charge' : trip?.type === 'google-pay' ? 'Google Pay' : trip?.type === 'apple-pay' ? 'Apple Pay' : ''
                }
                {
                    trip?.type === 'ride' ? `Ride #${trip?.short_id}` : ''
                }
            </Typography>
            <CloseButton sx={{ color: '#172B4D', fontWeight: 700 }} onClick={() => {
                dispatch(closeModal(false));
            }}><CloseIcon /></CloseButton>
            <CardContent sx={{ p: 1 }} >
                <Box sx={{ textAlign: 'center', mb: 2 }}>
                    <WalletVector style={{ marginTop: '-25px' }} />
                    <Box sx={{ boxShadow: '0 0 40px rgba(110, 135, 171, 0.11)', p: 1.5, mt: 1 }}>
                        <Typography sx={{ color: '#172B4D', fontSize: '16px', fontWeight: 700 }}>
                            {trip?.type === 'received-money' ? `Received Money from ${trip?.option?.from_info?.first_name}` : ''}
                            {trip?.type === 'send-money' ? `Send Money To ${trip?.option?.to_info?.first_name}` : ''}
                            {trip?.type === 'payout' ? "Payout" : ''}
                            {trip?.type === 'top-up' ? "Top up" : ''}
                            {trip?.type === 'apple-pay' ? "Apple Pay" : ''}
                            {trip?.type === 'google-pay' ? "Google Pay" : ''}
                            {trip?.type === 'admin-charge' ? "Admin Charge" : ''}
                            {trip?.type === 'ride' && trip?.option?.paid_from_wallet === 0 ? "Wallet charge" : ''}
                            {trip?.type === 'ride' && trip?.option?.paid_from_wallet !== 0 ? "Amount Received " : ''}
                        </Typography>
                        <Typography sx={{ color: colorConfigs?.sidebar?.bg, fontSize: '24px', fontWeight: 700, background: colorConfigs?.sidebar?.ligthBg, width: 'fit-content', p: 1, px: 3, borderRadius: 2, mx: 'auto', mt: 0.5 }} variant="h4" >
                            {trip.amount_type === 'incoming' ? '' : ' - '}  ${trip?.amount.toFixed(2)}
                        </Typography>
                    </Box>
                </Box>
                <Grid container alignItems="center">
                    <Grid item xs={6}>
                        <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center', gap: 1 }}>
                            <FaRegCalendarAlt color='#7A869A' />
                            <Typography sx={{ color: '#7A869A', fontWeight: 500, fontSize: 15 }}>
                                {formattedDate}
                            </Typography>
                        </Box>


                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', gap: 1 }}>
                            <GoClock />
                            <Typography variant="body2" align="right">
                                {formattedTime}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                {/* Received money */}
                {trip?.type === 'received-money' || trip?.type === 'admin-charge' ? <Box sx={{ border: '1px solid #EFEFEF ', p: 2, borderRadius: 3, mt: 2 }}>
                    <UserInfo>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography sx={{ color: '#7A869A', fontWeight: 600, fontSize: 14 }} mr={1}> From : </Typography>
                            <UserAvatar src={trip?.type === 'admin-charge' ? process.env.REACT_APP_ENV === 'women_first' ? wfLogo : rrLogo : trip?.option?.profile_picture || ''} />
                            <Typography sx={{ color: '#172B4D', fontWeight: 600, fontSize: 16 }}>
                                {trip?.type === 'admin-charge' ? process.env.REACT_APP_ENV === 'women_first' ? "Women First" : "Ride Wyse" : trip?.option?.from_info?.first_name}
                            </Typography>
                        </Box>
                        <Typography sx={{ fontWeight: 600, fontSize: 14 }} color="#495974">
                            {ClickablePhoneNumber(trip?.option?.from_info?.phone_number_code, trip?.option?.from_info?.phone_number)}
                        </Typography>
                    </UserInfo>
                    <Divider>
                        <Box sx={{ background: '#FFFAF4', px: 1, borderRadius: 25 }}>
                            <LuClock4 color='#FF6B00' size={18} style={{ marginTop: 5 }} />
                        </Box>
                    </Divider>
                    <UserInfo>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography sx={{ color: '#7A869A', fontWeight: 600, fontSize: 14 }} mr={1}> To :  </Typography>
                            <Typography sx={{ color: '#172B4D', fontWeight: 600, fontSize: 16 }}>
                                {selectUser?.first_name}
                            </Typography>
                        </Box>
                        <Typography sx={{ fontWeight: 600, fontSize: 14 }} color="#495974">
                            {ClickablePhoneNumber(selectUser?.code_phone_number, selectUser?.phone_number)}
                        </Typography>
                    </UserInfo>
                </Box> : ''}
                {/* send money */}
                {trip?.type === 'send-money' ? <Box sx={{ border: '1px solid #EFEFEF ', p: 2, borderRadius: 3, mt: 2 }}>
                    <UserInfo>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography sx={{ color: '#7A869A', fontWeight: 600, fontSize: 14 }} mr={1}> From : </Typography>
                            <Typography sx={{ color: '#172B4D', fontWeight: 600, fontSize: 16 }}>
                                {selectUser?.first_name}
                            </Typography>
                        </Box>
                        <Typography sx={{ fontWeight: 600, fontSize: 14 }} color="#495974">
                            {ClickablePhoneNumber(selectUser?.code_phone_number, selectUser?.phone_number)}
                        </Typography>
                    </UserInfo>
                    <Divider>
                        <Box sx={{ background: '#FFFAF4', px: 1, borderRadius: 25 }}>
                            <LuClock4 color='#FF6B00' size={18} style={{ marginTop: 5 }} />
                        </Box>
                    </Divider>
                    <UserInfo>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography sx={{ color: '#7A869A', fontWeight: 600, fontSize: 14 }} mr={1}> To :  </Typography>
                            <UserAvatar src={trip?.option?.profile_picture || ''} />
                            <Typography sx={{ color: '#172B4D', fontWeight: 600, fontSize: 16 }}>
                                {trip?.option?.to_info?.first_name}
                            </Typography>
                        </Box>
                        <Typography sx={{ fontWeight: 600, fontSize: 14 }} color="#495974">
                            {ClickablePhoneNumber(trip?.option?.to_info?.phone_number_code, trip?.option?.to_info?.phone_number)}
                        </Typography>
                    </UserInfo>
                </Box> : ''}

                {/* Top up or payOuts */}
                {trip?.type === 'payout' || trip?.type === 'top-up' ? <Box sx={{ width: 'fit-content', boxShadow: '0 0 40px rgba(110, 135, 171, 0.11)', p: 2, px: 3, borderRadius: 3, mt: 1 }}>
                    <PaymentMethod
                        img={
                            trip?.option?.brand === 'American Express'
                                ? amex
                                : trip?.option?.brand === 'Diners Club'
                                    ? dinars
                                    : trip?.option?.brand === 'Discover'
                                        ? discover
                                        : trip?.option?.brand === 'JCB'
                                            ? jbc
                                            : trip?.option?.brand === 'Visa'
                                                ? visa
                                                : trip?.option?.brand === 'MasterCard'
                                                    ? mastercard
                                                    : trip?.option?.brand === 'UnionPay'
                                                        ? unionpay
                                                        : geniric
                        }
                        text={`**** **** **** ${trip?.option?.last4}`}
                    />
                </Box> : ''}

                {trip?.type === 'received-money' || trip?.type === 'send-money' || trip?.type === 'admin-charge' ? <Box mt={2}>
                    <Box sx={{ border: '1px solid #EFEFEF ', p: 2, borderRadius: 3 }}>
                        <UserInfo>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Typography variant="body2">Reference Number: </Typography>
                            </Box>
                            <Typography sx={{ fontWeight: 600, fontSize: 14 }} color="#495974">#{trip?.short_id}</Typography>
                        </UserInfo>
                        <Divider sx={{ my: 1 }}>
                        </Divider>
                        <UserInfo>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', maxWidth: '100%' }}>
                                <Typography sx={{ color: '#7A869A', fontWeight: 600, fontSize: 14 }} mr={1}> Note : </Typography>
                                <Typography sx={{ fontSize: 14, fontWeight: 600, wordWrap: 'break-word', wordBreak: 'break-all', overflowWrap: 'break-word', maxWidth: '100%' }}>
                                    {trip?.option?.note}
                                </Typography>
                            </Box>
                        </UserInfo>
                    </Box>
                </Box> : ''}

                {/* Payment Summary Section */}
                {trip?.type === 'ride' ?
                    <>
                        <PaymentSummary>
                            <Typography sx={{ fontWeight: 700, color: '#2B3641', fontSize: 14 }}>Payment Summary</Typography>
                            {trip?.option?.paid_from_cash !== 0 ?
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                                    <Typography sx={{ color: colorConfigs?.sidebar?.bg, fontWeight: 600, fontSize: 14 }}>Paid Cash</Typography>
                                    <Typography sx={{ color: colorConfigs?.sidebar?.bg, fontWeight: 600, fontSize: 14 }}>${trip?.option?.paid_from_cash.toFixed(2)}</Typography>
                                </Box> : ''}
                            {trip?.option?.paid_from_card !== 0 ?
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                                    <Typography sx={{ color: colorConfigs?.sidebar?.bg, fontWeight: 600, fontSize: 14 }}>Paid Card</Typography>
                                    <Typography sx={{ color: colorConfigs?.sidebar?.bg, fontWeight: 600, fontSize: 14 }}>${trip?.option?.paid_from_card.toFixed(2)}</Typography>
                                </Box> : ''}
                            {trip?.option?.paid_from_wallet !== 0 ?
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                                    <Typography sx={{ color: colorConfigs?.sidebar?.bg, fontWeight: 600, fontSize: 14 }}>Paid from wallet</Typography>
                                    <Typography sx={{ color: colorConfigs?.sidebar?.bg, fontWeight: 600, fontSize: 14 }}>${trip?.option?.paid_from_wallet.toFixed(2)}</Typography>
                                </Box> : ''}
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1, background: '#F5F5F5', p: .5 }}>
                                <Typography sx={{ fontSize: 14, fontWeight: 600 }} >Fare</Typography>
                                <Typography sx={{ fontSize: 14, fontWeight: 700 }}>${trip?.option?.fare.toFixed(2)}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                                <Typography sx={{ fontSize: 14, fontWeight: 600 }}>Tips</Typography>
                                <Typography sx={{ fontSize: 14, fontWeight: 700 }}>${trip?.option?.tips.toFixed(2)}</Typography>
                            </Box>
                            {trip?.option?.credit_passenger_wallet !== 0 ?
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                                    <Typography sx={{ fontSize: 14, fontWeight: 400, color: '#495974' }}>Credit passenger wallet</Typography>
                                    <Typography sx={{ fontSize: 14, fontWeight: 400, color: '#495974' }}>-${trip?.option?.credit_passenger_wallet.toFixed(2)}</Typography>
                                </Box> : ''}
                            {trip?.option?.company_percentage !== 0 && !isRidersURL ?
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                                    <Typography sx={{ fontSize: 14, fontWeight: 400, color: '#495974' }}>Company percentage</Typography>
                                    <Typography sx={{ fontSize: 14, fontWeight: 400, color: '#495974' }}>-${trip?.option?.company_percentage.toFixed(2)}</Typography>
                                </Box> : ''}
                            {trip?.option?.paid_from_wallet === 0 ?
                                <>
                                    <Divider sx={{ my: 1 }} />
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                                        <Typography sx={{ fontSize: 14, fontWeight: 500 }}>Wallet charge</Typography>
                                        <Typography sx={{ fontSize: 16, fontWeight: 700 }}>{trip.amount_type === 'incoming' ? '+' : '-'}${trip?.amount.toFixed(2)}</Typography>
                                    </Box>
                                </> : ''}
                            {trip?.option?.paid_from_wallet !== 0 ?
                                <>
                                    <Divider sx={{ my: 1 }} />
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                                        <Typography sx={{ fontSize: 14, fontWeight: 500 }}>Total Wallet Received</Typography>
                                        <Typography sx={{ fontSize: 16, fontWeight: 700 }}>{trip.amount_type === 'incoming' ? '+' : '-'}${trip?.amount.toFixed(2)}</Typography>
                                    </Box>
                                </> : ''}
                        </PaymentSummary>
                        {/* <BlueButton sx={{ mt: 2, width: '100%' }}>View ride details</BlueButton> */}
                    </> : ''}

            </CardContent>
        </StyledCard>
    );
};

export default RiderWalletView;
