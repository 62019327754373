import { Typography } from "@mui/material";
import React from "react";

const Reason = ({ reason }) => {

    return reason === "cancellation-limit" ? (
        <div style={styles.containerLimit} >
            <Typography style={styles.cancellation_limit}>Cancellation Limit</Typography>
        </div>
    ) : reason === "wallet-balance" ? (
        <div style={styles.containerBalance}>
            <Typography style={styles.wallet_balance}>Wallet Balance</Typography>
        </div>
    ) :
        (
            ""
        );
};
const styles = {
    cancellation_limit: {
        color: "rgba(55, 0, 212, 1)",
        fontSize: "14px",
        fontWeight: 600,
        lineHeight: "18px",
        fontFamily: "Poppins",
        minWidth: '135px'
    },
    wallet_balance: {
        color: "rgba(217, 104, 0, 1)",
        fontSize: "14px",
        fontWeight: 600,
        lineHeight: "18px",
        fontFamily: "Poppins",
        minWidth: '135px'
    },
    containerLimit: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "4px",
        borderRadius: "25px",
        backgroundColor: "rgba(237, 222, 253, 1)",
    },
    containerBalance: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "4px",
        borderRadius: "25px",
        backgroundColor: "#FFF1EA",
    }
};
export default Reason;
