import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
  styled
} from "@mui/material";
import { Formik } from "formik";
import React, { useState } from "react";
import Loading from "react-fullscreen-loading";
import { IoMdClose } from 'react-icons/io';
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import BlueButton from "../../../components/common/Buttons/BlueButton";
import colorConfigs from "../../../configs/colorConfigs";
import { auth } from "../../../configs/firebase";
import { resetAddNewRide, setAddNewRide } from "../../../redux/features/appStateSlice";
import { postMethod } from "../../Hook/UseCustomApi";
import CarTypeAndCreditCardForm from "./CreditCardForm";
import RiderInfoForm from "./RiderInfo";
import Summary from "./Summary";
import Error5xx from "../../../components/common/HandelError5xx";

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required("First Name is required."),
  last_name: Yup.string().required("Last Name is required."),
  phone_number: Yup.string().required("Phone Number is required."),
  address: Yup.object().shape({
    lat: Yup.number().required("Complete pickup address is required."),
    lng: Yup.number().required("Complete pickup address is required."),
    address: Yup.string().required("Complete pickup address is required."),
  }),
  addressDrop: Yup.object().shape({
    lat: Yup.number().required("Complete dropoff address is required."),
    lng: Yup.number().required("Complete dropoff address is required."),
    address: Yup.string().required("Complete dropoff address is required."),
  }),
});

const initialValues = {
  first_name: "",
  last_name: "",
  phone_number: "",
  address: {
    lat: "",
    lng: "",
    address: "",
  },
  addressDrop: {
    lat: "",
    lng: "",
    address: "",
  },
};

const CloseButton = styled(Box)(({ theme }) => ({
  background: '#F0F0F0',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(0.5),
  borderRadius: theme.shape.borderRadius,
  cursor: 'pointer',
  width: 30,
  marginLeft: 'auto',
  marginBottom: 15

}));

export default function AddNewRide({ fetchDriverss, handleNewRideAdded }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(1); // Start from 1
  const [formErrors, setErrors] = useState({});
  const [error, setError] = useState(false);
  const [addressToShow, setAddressToShow] = useState("");
  const [address, setAddress] = useState({});
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [city, setCity] = useState("");
  const [addressToShowDrop, setAddressToShowDrop] = useState("");
  const addNewRide = useSelector((state) => state.appState.addNewRide);
  const [selectedDateTime, setSelectedDateTime] = useState(addNewRide?.selectedDateTime ? addNewRide?.selectedDateTime : null);
  const [isScheduled, setIsScheduled] = useState(addNewRide?.isScheduled ? addNewRide?.isScheduled : false);
  const [addressDrop, setAddressDrop] = useState({});
  const [stateDrop, setStateDrop] = useState("");
  const [zipCodeDrop, setZipCodeDrop] = useState("");
  const [cityDrop, setCityDrop] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [carTypes, setCarTypes] = useState([]);
  const [cardData, setCardData] = useState(null);
  const [isCheckingAvailability, setIsCheckingAvailability] = useState(false);
  const AddNewRideSelector = (state) => state.appState.addNewRide;


  //handle error5xx
  const [tryAgain, setTryAgain] = useState();
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [errorCount, setErrorCount] = useState(0);
  const [errorDialogCounter, setErrorDialogCounter] = useState(0);
  const handleError5xx = () => {
    setIsErrorDialogOpen(true);
    setErrorCount(prevCount => {
      const newCount = prevCount + 1;
      if (newCount === 1) {
        setErrorDialogCounter(5);
      } else if (newCount === 2) {
        setErrorDialogCounter(10);
      }
      return newCount;
    });
  };
  const handleCloseErrorDialog = () => {
    setIsErrorDialogOpen(false);
    if (errorCount <= 2) {
      handleErrorCountByClose()
    }

  };
  const handleErrorCountByClose = () => {
    setErrorCount(prevCount => {
      const newCount = prevCount - 1;
      if (newCount === 1) {
        setErrorDialogCounter(5);
      } else if (newCount === 2) {
        setErrorDialogCounter(10);
      }
      return newCount;
    });
  }



  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSelectPickup = async (address, placeId, suggestion, setFieldValue) => {
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      const selectedPlaceData = {
        city:
          results[0]?.address_components.find((component) =>
            component.types.includes("locality")
          )?.long_name || "",
        state:
          results[0]?.address_components.find((component) =>
            component.types.includes("administrative_area_level_1")
          )?.short_name || "",
        country:
          results[0]?.address_components.find((component) =>
            component.types.includes("country")
          )?.short_name || "",
        zip_code:
          results[0]?.address_components.find((component) =>
            component.types.includes("postal_code")
          )?.long_name || "",
        lat: latLng.lat,
        lng: latLng.lng,
        route:
          results[0]?.address_components.find((component) =>
            component.types.includes("route")
          )?.long_name || "",
        street_number:
          results[0]?.address_components.find((component) =>
            component.types.includes("street_number")
          )?.long_name || "",
        primary_line: results[0]?.formatted_address || "",
        placeId: placeId || "",
        address: address || "",
      };
      setCity(selectedPlaceData?.city);
      setZipCode(selectedPlaceData?.zip_code);
      setState(selectedPlaceData?.state);
      setAddress(selectedPlaceData);
      setAddressToShow(selectedPlaceData?.address);
      setFieldValue("address", selectedPlaceData);
      dispatch(setAddNewRide({ address: selectedPlaceData }));
    } catch (error) {
      console.error("Error selecting address", error);
    }
  };

  const handleSelectDropOff = async (address, placeId, suggestion, setFieldValue) => {
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      const selectedPlaceData = {
        city:
          results[0]?.address_components.find((component) =>
            component.types.includes("locality")
          )?.long_name || "",
        state:
          results[0]?.address_components.find((component) =>
            component.types.includes("administrative_area_level_1")
          )?.short_name || "",
        country:
          results[0]?.address_components.find((component) =>
            component.types.includes("country")
          )?.short_name || "",
        zip_code:
          results[0]?.address_components.find((component) =>
            component.types.includes("postal_code")
          )?.long_name || "",
        lat: latLng.lat,
        lng: latLng.lng,
        route:
          results[0]?.address_components.find((component) =>
            component.types.includes("route")
          )?.long_name || "",
        street_number:
          results[0]?.address_components.find((component) =>
            component.types.includes("street_number")
          )?.long_name || "",
        primary_line: results[0]?.formatted_address || "",
        placeId: placeId || "",
        address: address || "",
      };
      setCityDrop(selectedPlaceData?.city);
      setZipCodeDrop(selectedPlaceData?.zip_code);
      setStateDrop(selectedPlaceData?.state);
      setAddressDrop(selectedPlaceData);
      setAddressToShowDrop(selectedPlaceData?.address);
      setFieldValue("addressDrop", selectedPlaceData);

      // Save to Redux
      dispatch(setAddNewRide({ addressDrop: selectedPlaceData }));
    } catch (error) {
      console.error("Error selecting address", error);
    }
  };


  const handleNext = async (values) => {

    if (activeStep === 1) {
      let error = {};
      if (!values.phone_number) {
        error.phone_number = "Phone Number is required.";
      }
      if (isScheduled && (!selectedDateTime || selectedDateTime === ' ')) {
        setError("Please select a date and time");
        return;
      }

      if (!values.first_name) {
        error.first_name = "First Name is required.";
      }
      if (!values.last_name) {
        error.last_name = "Last Name is required.";
      }
      console.log(values.address)
      if (values.address === '' || !values.address || !values.address.lat || !values.address.lng || !values.address.address) {
        error.pickup_address = "Complete pickup address is required.";
      }
      if (!values.addressDrop || !values.addressDrop.lat || !values.addressDrop.lng || !values.addressDrop.address) {
        error.delivery_address = "Complete dropoff address is required.";
      }
      if (!values.addressDrop || !values.addressDrop.lat || !values.addressDrop.lng || !values.addressDrop.address) {
        error.delivery_address = "Complete dropoff address is required.";
      }
      if (values.dropoff_zip_code) {
        if (!/^[A-Za-z0-9]{6}$/.test(values.dropoff_zip_code)) {
          error.dropoff_zip_code = "Invalid postal code format. Please use exactly 6 alphanumeric characters.";
        }
      }
      if (values.pickup_zip_code) {
        if (!/^[A-Za-z0-9]{6}$/.test(values.pickup_zip_code)) {
          error.pickup_zip_code = "Invalid postal code format. Please use exactly 6 alphanumeric characters.";
        }
      }


      setErrors(error);
      if (Object.keys(error).length === 0) {
        if (values.phone_number === 'unvalidNumber') {
          setError("Phone number must be valid.");
          return
        }
        setIsCheckingAvailability(true);
        await checkAvailability();
        setIsCheckingAvailability(false);
      } else {
        if (error.pickup_zip_code === "Invalid postal code format. Please use exactly 6 alphanumeric characters." || error.dropoff_zip_code === "postal code should be 5 numbers.") {
          setError("Invalid postal code format.");
        } else if (error.dropoff_zip_code === "Invalid postal code format. Please use exactly 6 alphanumeric characters." || error.dropoff_zip_code === "postal code should be 5 numbers.") {
          setError("Invalid postal code format.");
        } else {
          setError("Please fill in all required fields.");
          showSnackbar("Please fill in all required fields.");
        }

      }

    } else if (activeStep === 2) {
      setError("");
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

  };

  const checkAvailability = async () => {
    const tokenId = await auth?.currentUser
      ?.getIdToken()
      .then((token) => token);
    const headers = {
      "Content-Type": "application/json",
      Authorization: tokenId,
    };

    const requestBody = {
      female: true,
      delivery_address: {
        lat: addressDrop?.lat,
        lng: addressDrop?.lng,
        address: addressDrop?.address,
      },
      pickup_address: {
        lat: address?.lat,
        lng: address?.lng,
        address: address?.address,
      },
      is_schedule: isScheduled
    };


    setLoading(true);
    try {
      const response = await postMethod({
        url: `zone/cost/approximate`,
        body: requestBody,
        headers: headers,
      });
      if (response?.data.length === 0 && !response?.no_driver) {
        setError("Both selected locations must be within the operational zone. Please choose valid locations.");
        return
      } else if (response?.data.length === 0 && response?.no_driver) {
        setError("Currently, there are no available drivers in this area. Please try again later.");
        return
      }
      setCarTypes(response?.data);
      console.log(response?.data)

      dispatch(setAddNewRide({ carTypes: response?.data }));
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setError("");
    } catch (error) {
      setError("Pickup or drop off address is outside our coverage area. Sorry for the inconvience!");
      if (error === "Internal Server Error: Server error occurred.") {
        handleError5xx();
        setTryAgain(() => checkAvailability);
      }
    } finally {
      setLoading(false);
    }
  };

  const backStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }

  const handleSubmit = async (values) => {
    setLoading(true);
    console.log(values)
    if (auth && auth?.currentUser) {
      const tokenId = await auth?.currentUser
        ?.getIdToken()
        .then((token) => token);
      const headers = {
        "Content-Type": "application/json",
        Authorization: tokenId,
      };
      postMethod({
        url: `ride`,
        body: {
          created_by: "dispatch",
          dispatch_id: auth?.currentUser?.uid,
          scheduled_at: new Date(values?.selectedDateTime).getTime() || '',
          schedule: values?.selectedDateTime ? true : false,
          carType_id: values?.selectedCarType?.carTypeId,
          carType_name_id: values?.selectedCarType?.carTypeNameId,
          zone_id: values?.selectedCarType?.zoneId,
          status: values?.selectedDateTime ? 'scheduled' : "pending",
          pay_type: !values?.isCash ? values?.card : "cash",
          vehicle_type: values?.selectedCarType?.type,
          customer_details: {
            phone_number: '+' + values?.phone_number,
            first_name: `${values?.first_name}`,
            last_name: `${values?.last_name}`,
          },
          delivery_address: {
            lat: values?.addressDrop?.lat,
            lng: values?.addressDrop?.lng,
            address: values?.addressDrop?.address,
          },
          pickup_address: {
            lat: values?.address?.lat,
            lng: values?.address?.lng,
            address: values?.address?.address,
          },
          note: values?.note || ''
        },
        headers: headers,
        handleErrorMessage: showSnackbar,
      })
        .then((res) => {
          setIsAddOpen(false);
          setErrors({});
          setError("");
          if (fetchDriverss) {
            fetchDriverss()
          }
          if (handleNewRideAdded) {
            handleNewRideAdded()
          }
          onclose()

        })
        .catch((error) => {
          console.error("Error adding new ride:", error);
          setError(error?.response?.data?.code);
          setLoading(false);
          if (error === "Internal Server Error: Server error occurred.") {
            handleError5xx();
            setTryAgain(() => () => handleSubmit(values));
          }
        });

    }
  };

  const CircularProgressIndicator = ({ step, totalSteps }) => {
    const radius = 16;
    const strokeWidth = 4;
    const circumference = 2 * Math.PI * radius;
    const progress = (step / totalSteps) * circumference;

    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          width: radius * 2 + strokeWidth * 2,
          height: radius * 2 + strokeWidth * 2,
        }}
      >
        <svg width={radius * 2 + strokeWidth * 2} height={radius * 2 + strokeWidth * 2}>
          <circle
            stroke="#E0E0E0"
            fill="none"
            strokeWidth={strokeWidth}
            cx={radius + strokeWidth}
            cy={radius + strokeWidth}
            r={radius}
          />
          <circle
            stroke={colorConfigs?.sidebar?.bg}
            fill="none"
            strokeWidth={strokeWidth}
            cx={radius + strokeWidth}
            cy={radius + strokeWidth}
            r={radius}
            strokeDasharray={circumference}
            strokeDashoffset={circumference - progress}
            transform={`rotate(-90 ${radius + strokeWidth} ${radius + strokeWidth})`}
          />
        </svg>
        <Typography
          variant="h6"
          component="div"
          sx={{
            position: "absolute",
            fontSize: "14px",
            fontWeight: "bold",
            color: colorConfigs?.sidebar?.bg,
          }}
        >
          {step}/{totalSteps}
        </Typography>
      </Box>
    );
  };

  const onclose = () => {
    setIsAddOpen(false);
    setErrors({});
    setError("");
    setActiveStep(1);
    setAddress({});
    setAddressDrop({});
    setState("");
    setZipCode("");
    setCityDrop("");
    setZipCodeDrop("");
    setCity("");
    setStateDrop("");
    setAddressToShowDrop("");
    setAddressToShow("");
    setLoading(false);
    setSelectedDateTime(null);
    setIsScheduled(false)
  }
  const steps = ["Passenger Info", "Add Credit Card", "Summary"];
  return (
    <>
      {/* handleError5xx */}
      <Error5xx open={isErrorDialogOpen} errCount={errorCount} count={errorDialogCounter} handleClose={handleCloseErrorDialog} tryAgain={tryAgain} />
      <Dialog
        open={isAddOpen}
        onClose={onclose}
        sx={{
          width: "676px", mx: "auto",
          '& .MuiPaper-root':
          {
            minWidth: '676px',
            maxWidth: "95%",
            borderRadius: "10px",
          }
        }}
      >
        <DialogContent sx={{ borderRadius: "20px", px: 3, py: 2, minWidth: '676px' }}>
          <CloseButton onClick={onclose}>
            <IoMdClose size={20} />
          </CloseButton>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: '100%', mb: 1 }}>
            <Typography sx={{ fontSize: 20, fontWeight: 700, color: "black" }}>
              {activeStep === 3 ? "Summary" : "Add New Ride"}
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 5 }}>
              <CircularProgressIndicator step={activeStep} totalSteps={3} />

            </Box>
          </Box>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ handleBlur, handleChange, values, errors, touched, setFieldValue, resetForm }) => (
              <Box>
                {activeStep === 1 && (
                  <RiderInfoForm
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    values={values}
                    setFieldAddress={setFieldValue}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    setAddressToShow={setAddressToShow}
                    setAddressToShowDrop={setAddressToShowDrop}
                    handleSelectPickup={handleSelectPickup}
                    setSelectedDateTime={setSelectedDateTime}
                    selectedDateTime={selectedDateTime}
                    isScheduled={isScheduled}
                    setIsScheduled={setIsScheduled}
                    handleSelectDropOff={handleSelectDropOff}
                    addressToShow={addressToShow}
                    addressToShowDrop={addressToShowDrop}
                    state={state}
                    setState={setState}
                    zipCode={zipCode}
                    setZipCode={setZipCode}
                    stateDrop={stateDrop}
                    setStateDrop={setStateDrop}
                    zipCodeDrop={zipCodeDrop}
                    setZipCodeDrop={setZipCodeDrop}
                    formErrors={formErrors}
                    city={city}
                    cityDrop={cityDrop}
                  />
                )}
                {activeStep === 2 && (
                  <CarTypeAndCreditCardForm
                    handleNext={() => handleNext(values)}
                    carTypes={carTypes}
                    activeStep={activeStep}
                    backStep={backStep}
                    setFieldAddress={setFieldValue}
                    setAddressToShow={setAddressToShow}
                    addRideSubmit={handleSubmit}
                    isLoading={loading}
                    setCardData={setCardData}
                  />
                )}
                {activeStep === 3 && (
                  <Summary
                    setActiveStep
                    values={values}
                    backStep={backStep}
                    address={address}
                    handleSubmit={handleSubmit}
                    addressDrop={addressDrop}
                    isLoading={loading}
                    cardData={cardData}
                    handleNext={() => handleNext(values)}
                  />
                )}
                <Typography variant="caption" color="error">
                  {error}
                </Typography>                <DialogActions>
                  {activeStep < steps.length - 1 && activeStep !== 2 && (
                    <Button
                      variant="contained"
                      onClick={() => handleNext(values)}
                      sx={{
                        ml: 1,
                        background: colorConfigs?.sidebar?.bg,
                        color: "white",
                        px: 5,
                        borderRadius: "5px",
                      }}
                      disabled={isCheckingAvailability}
                    >
                      {isCheckingAvailability ? (
                        <CircularProgress size={24} sx={{ color: "white" }} />
                      ) : (
                        "Next"
                      )}
                    </Button>
                  )}
                </DialogActions>
              </Box>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
      <Grid >
        <BlueButton sx={{ p: 2 }} onClick={() => { setIsAddOpen(true); dispatch(resetAddNewRide()) }}> + Add new ride</BlueButton>
      </Grid>
      <Loading
        loading={!!loading}
        background="rgba(86, 100, 210, 0.1)"
        loaderColor={theme.palette.primary.main}
      />
    </>
  );
}