import moment from "moment";
import React from "react";

import Typography from "@mui/material/Typography";

import { Avatar } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import logo from '../../../assets/logo.png';
import womenLogin from "../../../assets/womens-first/Favicon.png";
import colorConfigs from "../../../configs/colorConfigs";
import { selectChat, setLastRideChat, updateOutChat } from "../../../redux/features/appStateSlice";
const useStyles = {
  taskCard: {
    display: "flex",
    flexDirection: "column",
    background: "white",
    position: "relative",
    borderRadius: "6px",
    cursor: "pointer",
    boxShadow: "0 1px 2px rgb(0,0,0,0.1)",
    border: "1.5px solid rgb(245,245,245)",
    "&:hover": {
      boxShadow: "0 10px 20px -14px rgba(50,50,50,0.3)",
    },
  },
};
const PrivateCard = ({
  task,
  promptCancel,
  fromDispute = false,
  activeButton,
  selected, onClick
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleOpenTask = (taskData) => {
    dispatch(selectChat({ ...taskData }));
    dispatch(updateOutChat(false));
    dispatch(setLastRideChat({ ...taskData }));
    
  };

  return (
    <div

      style={{ ...useStyles.taskCard, border: selected ? `2px solid ${colorConfigs?.sidebar?.bg}` : 'none', backgroundColor: selected ? process.env.REACT_APP_ENV === 'women_first' ? '#F9F6FF' :  '#f5fcff' : 'white' }} onClick={() => { handleOpenTask(task); onClick() }}>

      <div
        style={{ display: "flex", padding: "14px 16px", paddingBottom: "0px" }}
      >
        <div style={{ flex: 1, display: "flex", flexDirection: "row" }}>
          <Avatar src={process.env.REACT_APP_ENV === 'women_first' ? womenLogin : logo} />
          <div style={{ height: "4px" }} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "20px",
            }}
          >
            <Typography
              variant="body2"
              style={{
                overflowWrap: "anyWhere",
                fontWeight: 700,
                lineHeight: "22.4px",
              }}
            >
              {task?.title}
            </Typography>
            <Typography
              variant="body2"
              style={{
                overflowWrap: "anyWhere",
                fontWeight: task?.unRead?.admin === 0 ? "normal" : "bold",
                color: task?.unRead?.admin === 0 ? "#7D7C7C" : "black",
              }}
            >
              {task?.lastMessage?.uid === "admin" &&
                task?.lastMessage?.message?.length >= 15
                ? "Admin: " +
                task?.lastMessage?.message?.slice("0", "15") +
                "..."
                : task?.lastMessage?.uid === "admin" &&
                  task?.lastMessage?.message?.length < 15
                  ? "Admin: " + task?.lastMessage?.message
                  : task?.lastMessage?.uid !== "admin" &&
                    task?.lastMessage?.message?.length >= 15
                    ? `${task?.lastMessage?.uid === task?.driver
                      ? "Driver"
                      : task?.lastMessage?.uid === task?.customer
                        ? "Customer"
                        : "Admin"
                    }: ` +
                    task?.lastMessage?.message?.slice("0", "15") +
                    "..."
                    : `${task?.lastMessage?.uid === task?.driver
                      ? "Driver"
                      : task?.lastMessage?.uid === task?.customer
                        ? "Customer"
                        : "Admin"
                    }: ` + task?.lastMessage?.message}
            </Typography>
          </div>
          {task?.unRead?.admin > 0 && !selected && (
            <div style={{ fontWeight: 'bold', display: "flex", justifyContent: "center", alignItems: "center", position: "absolute", top: "10px", right: "10px", width: "25px", height: "25px", borderRadius: "50%", backgroundColor: "#FF4D4F", color: "white", fontSize: "14px" }}>
              {task?.unRead?.admin}
            </div>
          )}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "12px 16px",
          paddingTop: "0px",
          marginLeft: "20px",
        }}
      >
        <Typography variant="caption" style={{ color: "grey" }} medium>
          {moment(
            typeof task?.updatedAt === "string"
              ? new Date(task.updatedAt)
              : new Date(task?.updatedAt?.toDate()?.toString())
          ).format("dddd, MMM DD")}
        </Typography>
      </div>
    </div>
  );
};

export default PrivateCard;
