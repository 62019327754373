import { Alert, Box, Button, ButtonGroup, CircularProgress, Grid, Snackbar, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import React, { useState } from 'react';
import { BiEditAlt } from 'react-icons/bi';
import { HiOutlineUserGroup } from "react-icons/hi2";
import { IoEyeSharp } from 'react-icons/io5';
import PhoneInput from 'react-phone-input-2';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import 'yup-phone-lite';
import Balance from '../../../../assets/svg/Balance';
import CarOutline from '../../../../assets/svg/CarOutline';
import Employees from '../../../../assets/svg/Employees';
import Voucher from '../../../../assets/svg/Voucher';
import AddressAutoComplete from '../../../../components/common/AddressAutoComplete';
import BlueButton from '../../../../components/common/Buttons/BlueButton';
import colorConfigs from '../../../../configs/colorConfigs';
import { putDataRedux } from '../../../../redux/features/appStateSlice';
import { putMethod } from '../../../Hook/UseCustomApi';

const inputStyle = {
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#BBC1CE",
            borderWidth: 1,
            borderRadius: "8px",
        },
        "&:hover fieldset": {
            borderColor: "#BBC1CE",
            borderWidth: 1,
            borderRadius: "8px",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#BBC1CE",
            borderWidth: 1,
            borderRadius: "8px",
        },
        input: {
            "&::placeholder": {
                color: "#BBC1CE",
                opacity: 1,
                fontSize: 14,
            },
            padding: "10px",
        },
    },
    "& label": {
        color: "#7A869A",
        lineHeight: "0.8375em",
    },
    "& label.Mui-focused": {
        color: "#000",
        fontSize: "14px",
        fontWeight: "500",
    },
};

const validationSchema = Yup.object().shape({
    name: Yup.string()
        .matches(/^[A-Za-z\s]+$/, 'Name can only contain letters and spaces')
        .max(20, 'Name cannot exceed 20 characters')
        .required('Name is required'),
    contactPerson: Yup.string().required('Contact Person is required'),
    phoneNumber: Yup.string()
        .required('Phone number is required')
        .test('is-valid-phone', 'Phone number is not valid for the selected region', function (value) {
            const { path, createError } = this;
            const selectedCountry = this.parent.countryCode || 'us';

            try {
                const phoneNumber = parsePhoneNumberFromString(value, selectedCountry.toUpperCase());
                if (!phoneNumber || !phoneNumber.isValid()) {
                    return createError({ path, message: `Phone number must be valid for region ${selectedCountry.toUpperCase()}` });
                }
                return true;
            } catch (error) {
                return createError({ path, message: `Phone number must be valid for region ${selectedCountry.toUpperCase()}` });
            }
        }),
    email: Yup.string().email('Please enter a valid email').required('Email is required'),
    postalCode: Yup.string()
});

function CorporateDetailsTab() {
    const [isEdit, setIsEdit] = useState(false);

    // Keep the original corporate data separate
    const selectedCorporate = useSelector((state) => state.appState.selectedCorporate);
    const [originalValues, setOriginalValues] = useState({
        name: selectedCorporate.corporate_name,
        contactPerson: selectedCorporate.contact_person,
        phoneNumber: selectedCorporate.code_phone_number + selectedCorporate.phone_number,
        email: selectedCorporate.email,
        postalCode: selectedCorporate.corporate_name,
        countryCode: selectedCorporate.code_phone_number,
        phoneNumberOnly: selectedCorporate.phone_number,
        countryCallingCode: selectedCorporate.corporate_name,
        address: selectedCorporate.address,
    });

    const [address, setAddress] = useState(selectedCorporate?.address);
    const [addressToShowAddress, setAddressToShowAddress] = useState(selectedCorporate?.address?.primary_line || '');
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({ open: false, severity: 'success', message: '' });
    const [error, setError] = useState(''); // State to manage error messages
    const adminData = useSelector((state) => state.appState.userData);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Use formik with the original values
    const formik = useFormik({
        initialValues: originalValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setLoading(true);
            setError(''); // Clear any previous error
    
            // Function to get only updated fields
            const getUpdatedFields = (newValues, oldValues) => {
                let updatedFields = {};
                if (newValues.name !== oldValues.name) {
                    updatedFields.corporate_name = newValues.name;
                }
                if (newValues.contactPerson !== oldValues.contactPerson) {
                    updatedFields.contact_person = newValues.contactPerson;
                }
                if (newValues.email !== oldValues.email) {
                    updatedFields.email = newValues.email;
                }
                if (newValues.phoneNumber !== oldValues.phoneNumber) {
                    const parsedPhoneNumber = parsePhoneNumberFromString(newValues.phoneNumber, newValues.countryCode.toUpperCase());
                    const phoneNumber = parsedPhoneNumber ? parsedPhoneNumber.nationalNumber : '';
                    const countryCallingCode = parsedPhoneNumber ? `+${parsedPhoneNumber.countryCallingCode}` : '';
                    updatedFields.phone_number = phoneNumber;
                    updatedFields.code_phone_number = countryCallingCode;
                }
                if (address !== oldValues.address) {
                    updatedFields.address = address;
                }
                return updatedFields;
            };
    
            const updatedFields = getUpdatedFields(values, originalValues);
    
            // Add created_by only if there are changes
            if (Object.keys(updatedFields).length > 0) {
                updatedFields.created_by = adminData?._id;
            }
    
            // If no fields are updated, show an info alert
            if (Object.keys(updatedFields).length === 0) {
                setLoading(false);
                setAlert({ open: true, severity: 'info', message: 'No changes detected.' });
                return;
            }
    
            // Prepare data for submission
            const submissionData = {
                ...updatedFields,
            };
    
            // Make the API request
            putMethod({
                url: `admin/corporate/${selectedCorporate._id}`,
                body: submissionData,
            })
                .then((response) => {
                    setLoading(false);
                    if (response) {
                        setAlert({ open: true, severity: 'success', message: 'Corporate updated successfully!' });
                        setOriginalValues(values); // Update original values with new values
                        dispatch(putDataRedux({ stateName: 'corporatesList', key: '_id', value: selectedCorporate._id, data: response.data }));
                        dispatch(putDataRedux({ stateName: 'selectedCorporate', data: response.data }));
                        handleViewMode();
                    } else {
                        setError('Updating failed.');
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    if (error === 'Conflict: Conflict in action or duplicate request.') {
                        setError('This corporate already exists.');
                    } else {
                        setError('Updating failed.');
                    }
                });
        },
    });
    

    // Handle phone input changes
    const handlePhoneChange = (value, countryData) => {
        formik.setFieldValue('phoneNumber', value);
        formik.setFieldValue('countryCode', countryData.countryCode.toLowerCase());

        const parsedPhoneNumber = parsePhoneNumberFromString(value, countryData.countryCode.toUpperCase());
        if (parsedPhoneNumber) {
            formik.setFieldValue('phoneNumberOnly', parsedPhoneNumber.nationalNumber);
            formik.setFieldValue('countryCallingCode', `+${parsedPhoneNumber.countryCallingCode}`);
        } else {
            formik.setFieldValue('phoneNumberOnly', '');
            formik.setFieldValue('countryCallingCode', '');
        }
    };

    // Handle address selection
    const handleSelectzipcode = async (address, placeId, suggestion) => {
        try {
            const results = await geocodeByAddress(address);
            const latLng = await getLatLng(results[0]);

            const selectedPlaceData = {
                city:
                    results[0]?.address_components.find((component) =>
                        component.types.includes("locality")
                    )?.long_name || "",
                state:
                    results[0]?.address_components.find((component) =>
                        component.types.includes("administrative_area_level_1")
                    )?.short_name || "",
                country:
                    results[0]?.address_components.find((component) =>
                        component.types.includes("country")
                    )?.short_name || "",
                zip_code:
                    results[0]?.address_components.find((component) =>
                        component.types.includes("address")
                    )?.long_name || "",
                lat: latLng.lat,
                lng: latLng.lng,
                route:
                    results[0]?.address_components.find((component) =>
                        component.types.includes("route")
                    )?.long_name || "",
                street_number:
                    results[0]?.address_components.find((component) =>
                        component.types.includes("street_number")
                    )?.long_name || "",
                primary_line: results[0]?.formatted_address || "",
                placeId: placeId || "",
                address: address || "",
            };
            setAddress(selectedPlaceData);
            setAddressToShowAddress(selectedPlaceData?.primary_line);
            formik.setFieldValue('postalCode', selectedPlaceData?.zip_code);
        } catch (error) {
            console.error("Error selecting address", error);
        }
    };

    const handleCloseAlert = () => {
        setAlert({ ...alert, open: false });
    };

    const handleViewMode = () => {
        setError('')
        formik.resetForm({ values: originalValues });
        setIsEdit(false);
    };

    const data = [
        // { icon: <Balance />, label: 'Balance', value: `$${selectedCorporate.balance}` },
        { icon: <Voucher />, label: 'Vouchers', value: selectedCorporate.vouchers },
        { icon: <CarOutline />, label: 'Total Rides', value: selectedCorporate.rides },
        { icon: <Employees />, label: 'Total Employees', value: selectedCorporate.employees },
        { icon: <HiOutlineUserGroup style={{ fontSize: 25, color: colorConfigs?.sidebar?.bg }} />, label: 'Total Customers', value: selectedCorporate.customers },
    ];

    return (
        <Box sx={{ px: 3, pt: 2 }}>
            <Box sx={{
                bgcolor: colorConfigs?.sidebar?.ligthBg,
                borderRadius: 2,
                p: 2,
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
            }}>
                {data?.map((item, index) => (
                    <Box
                        key={index}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'row',
                            textAlign: 'left',
                            flex: 1,
                            px: 1.5,
                            borderLeft: index !== 0 ? '1px solid #E0E0E0' : 'none',
                        }}
                    >
                        {item.icon}
                        <Box sx={{ ml: 2 }}>
                            <Typography sx={{ color: '#7A869A', fontWeight: 500, fontSize: 14 }}>
                                {item.label}
                            </Typography>
                            <Typography sx={{ color: '#172B4D', fontWeight: 600, fontSize: 16 }}>
                                {item.value}
                            </Typography>
                        </Box>
                    </Box>
                ))}
            </Box>
            <ButtonGroup sx={{ borderRadius: 25, border: `1px solid ${colorConfigs.sidebar.bg}`, my: 3 }}>
                <Button
                    onClick={() => setIsEdit(true)}
                    style={{
                        backgroundColor: isEdit ? colorConfigs.sidebar.bg : 'transparent',
                        color: isEdit ? '#FFFFFF' : colorConfigs.sidebar.bg,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 4,
                        borderRadius: 25,
                        border: 'none',
                        textTransform: 'capitalize'
                    }}
                >
                    <BiEditAlt size={18} />
                    Edit
                </Button>
                <Button
                    onClick={handleViewMode} // Use the handleViewMode function
                    style={{
                        backgroundColor: !isEdit ? colorConfigs.sidebar.bg : 'transparent',
                        color: !isEdit ? '#FFFFFF' : colorConfigs.sidebar.bg,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 4,
                        borderRadius: 25,
                        border: 'none',
                        textTransform: 'capitalize'
                    }}
                >
                    <IoEyeSharp size={18} />
                    View
                </Button>
            </ButtonGroup>
            <Box>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container columnSpacing={5} rowSpacing={3}>
                        <Grid item xs={6}>
                            <TextField
                                sx={inputStyle}
                                fullWidth
                                label="Name"
                                placeholder='Please Enter a Name'
                                focused
                                name="name"
                                InputProps={{
                                    readOnly: !isEdit,
                                }}
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            {isEdit ?
                                <>
                                    <AddressAutoComplete
                                        address={addressToShowAddress}
                                        setAddress={setAddressToShowAddress}
                                        handleSelect={handleSelectzipcode}
                                        types={"address"}
                                        lable="address"
                                        placeholder={"Please Enter address"}
                                        focused={true}
                                        disabled={isEdit}
                                    />
                                    {formik.touched.postalCode && formik.errors.postalCode && (
                                        <Typography color="error" variant="caption">
                                            {formik.errors.postalCode}
                                        </Typography>
                                    )}
                                </>
                                :
                                <TextField
                                    sx={inputStyle}
                                    fullWidth
                                    label="address"
                                    placeholder='Please Enter a Name'
                                    focused
                                    InputProps={{
                                        readOnly: !isEdit,
                                    }}

                                    value={addressToShowAddress}
                                    onChange={formik.handleChange}
                                    error={formik.touched.name && Boolean(formik.errors.name)}
                                    helperText={formik.touched.name && formik.errors.name}
                                />
                            }
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Contact Person"
                                sx={inputStyle}
                                focused
                                InputProps={{
                                    readOnly: !isEdit,
                                }}

                                placeholder='Please Enter a Contact Person'
                                name="contactPerson"
                                value={formik.values.contactPerson}
                                onChange={formik.handleChange}
                                error={formik.touched.contactPerson && Boolean(formik.errors.contactPerson)}
                                helperText={formik.touched.contactPerson && formik.errors.contactPerson}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <PhoneInput
                                id="phone-number-input"
                                autoFormat={true}
                                enableSearch={true}
                                focused
                                value={formik.values.phoneNumber}
                                onChange={handlePhoneChange}
                                inputStyle={{
                                    width: '100%',
                                    borderColor: '#BBC1CE',
                                    borderRadius: '8px',
                                    height: '43px',
                                }}
                                buttonStyle={{
                                    borderColor: '#BBC1CE',
                                    borderRadius: '8px 0 0 8px',
                                }}
                                containerStyle={{
                                    width: '100%',
                                }}
                                inputProps={{
                                    name: 'phoneNumber',
                                    required: true,
                                    readOnly: !isEdit,
                                }}
                                placeholder="Please enter your phone number"
                            />
                            {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                                <Typography color="error" variant="caption">
                                    {formik.errors.phoneNumber}
                                </Typography>
                            )}
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                focused
                                fullWidth
                                label="Email"
                                sx={inputStyle}
                                InputProps={{
                                    readOnly: !isEdit,
                                }}

                                placeholder='Please Enter an Email'
                                name="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />
                        </Grid>
                        {isEdit &&
                            <Grid item xs={12}>
                                <BlueButton
                                    sx={{
                                        bgcolor: '#FFFFFF',
                                        border: `1px solid ${colorConfigs?.sidebar?.bg}`,
                                        color: colorConfigs?.sidebar?.bg,
                                        mr: 3,
                                        minWidth: '150px',
                                        p: 2.8,
                                        borderRadius: 3
                                    }}
                                    variant="outlined"
                                    color="secondary"
                                    type="reset"
                                    disabled={loading}
                                    onClick={handleViewMode}
                                >
                                    Cancel
                                </BlueButton>

                                <BlueButton
                                    sx={{ minWidth: '150px', p: 2.8, borderRadius: 3 }}
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={loading}
                                >
                                    {loading ? <CircularProgress size={24} /> : 'Save'}
                                </BlueButton>
                            </Grid>}

                        {error && isEdit && (
                            <Grid item sx={{ my: 2, mt: -1 }} xs={12}>
                                <Typography color="error" variant="caption">
                                    {error}
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                </form>

                <Snackbar
                    open={alert.open}
                    autoHideDuration={6000}
                    onClose={handleCloseAlert}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }} // Positioning the Snackbar at the top right
                >
                    <Alert onClose={handleCloseAlert} severity={alert.severity} sx={{ width: '100%' }}>
                        {alert.message}
                    </Alert>
                </Snackbar>
            </Box>

        </Box>
    );
}

export default CorporateDetailsTab;
