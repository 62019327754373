import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Typography } from '@mui/material';
import React from 'react';

function CompletedRideMessage() {
    return (
        <Box
            sx={{
                height: '300px' ,
                width: '700px' ,
                maxWidth: '95%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                bgcolor: 'white',
                borderRadius: '8px',
                border: 'none',
                textAlign: 'center',
                margin: 'auto',
            }}
        >
            <CheckCircleIcon sx={{ fontSize: { xs: 40, sm: 50 }, mb: { xs: 1, sm: 2 }, color: '#4caf50' }} />
            <Typography variant="h5" component="div" sx={{ mb: { xs: 1, sm: 1.5 } }}>
                Ride Completed!
            </Typography>
            <Typography variant="body2" component="div" sx={{ mb: { xs: 2, sm: 3 } }}>
                Thank you for using our service. We hope you had a pleasant ride.
            </Typography>
        </Box>
    );
}

export default CompletedRideMessage;
