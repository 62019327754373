import * as React from "react"
import { memo } from "react"
const SvgComponent = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={22}
        height={22}
        fill="none"
        viewBox="0 0 19 20"
        {...props}
    >
        <path
            fill="red"
            d="M9.5 5.027a5.919 5.919 0 0 1 5.92 5.92v4.323a.594.594 0 0 1-.594.593H4.176a.594.594 0 0 1-.594-.593v-4.324a5.919 5.919 0 0 1 5.919-5.919Zm-.519-3.265h1.04a.148.148 0 0 1 .148.148v1.781a.148.148 0 0 1-.149.149H8.982a.148.148 0 0 1-.148-.149v-1.78a.148.148 0 0 1 .148-.15ZM3.29 4.056a.149.149 0 0 1 .21 0l1.26 1.26a.148.148 0 0 1 0 .21l-.735.734a.148.148 0 0 1-.21 0L2.555 5a.148.148 0 0 1 0-.209l.735-.735Zm12.424 0 .735.735a.148.148 0 0 1 0 .21l-1.26 1.26a.148.148 0 0 1-.21 0l-.735-.735a.149.149 0 0 1 0-.21l1.26-1.26a.148.148 0 0 1 .21 0ZM3.564 17.051h11.874a.594.594 0 0 1 .594.594v.445a.149.149 0 0 1-.148.148H3.118a.149.149 0 0 1-.148-.148v-.445a.594.594 0 0 1 .593-.594Zm2.746-5.882v4.694h1.187V11.17H6.31Z"
        />
    </svg>
)
const SecurityAlertSmall = memo(SvgComponent)
export default SecurityAlertSmall
