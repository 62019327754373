import {
    Box,
    Button,
    Grid,
    Select, MenuItem,
    FormControl, InputLabel,
    TextField, InputAdornment
} from "@mui/material";
import Status from "../../pages/status/status";
import Subject from "../../pages/subject/Subject";
import DynamicTable from "../../pages/table/table";
import SearchIcon from "@mui/icons-material/Search";
import colorConfigs from "../../configs/colorConfigs";
import React, { useEffect, useRef, useState } from "react";
import { NewFormatPhoneNumber } from "../../utils/localization/NewPhoneUtils";
import { useDispatch, useSelector } from "react-redux";
import { formatDate } from "../../utils/localization/dateTimeUtils";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { closeModal, openModal } from '../../redux/features/modalSlice';
import AddNewTicket from "./AddNewTicketCs";
import { useNavigate } from "react-router-dom";
import useTableData from '../../pages/Hook/useTableData';
import { postDataRedux } from '../../redux/features/appStateSlice';
import UnreadDocument from "../common/UnreadDocment";

export default function TicketManagment({ status }) {

    const ticketsList = useSelector((state) => state.appState.ticketsList);
    const countryCode = useSelector((state) => state.country.selectedCountry);
    let currentLimit = useRef(10);
    let currentKeyword = useRef("");
    let currentType = useRef("all");
    const rowsLimits = [5, 10, 25, 100];
    let currentPage = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const { data, page, count, filters, loading, handlePageChange, handleRowsChange, updateFilters } = useTableData(
        "admin/help/ticket",
        { keyword: "", type: "all", owner_type: status && status === "drivers" ? "driver" : "customer", },
        'ticketsList'
    );
    console.log(ticketsList);



    const capitalizeFirstLetter = (str) => {
        return str?.charAt(0)?.toUpperCase() + str?.slice(1);
    };

    useEffect(() => {
        window.scrollTo({ top: 0 });
    }, []);

    const getDateForamted = (date) => {
        if (date !== null && date !== undefined) {
            const dateObject = new Date(date);
            // Formatting options
            const options = {
                day: "numeric",
                month: "short",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
            };
            // Applying formatting and getting the formatted date string
            const formattedDate = dateObject.toLocaleDateString("en-US", options);
            return formattedDate;
        }

        return null;
    };

    const style = {
        color: "#495974",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "16px",

    };
    const styleb = {
        color: "#495974",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: "600",
        lineHeight: "18px",

    };

    const handleSearch = (e) => {
        updateFilters({ keyword: e.target.value });
        updateFilters({ 'search_owner_info.first_name': e.target.value });
        updateFilters({ 'search_owner_info.last_name': e.target.value });
        updateFilters({ search_short_id: e.target.value });
    };

    const handleStatusChange = (e) => {
        updateFilters({ type: e.target.value });
    };

    const columns = [
        {
            field: "short_id",
            headerName: "Ticket ID",
            flex: 1,
            minWidth: "95px",
            editable: true,
            style: styleb,
            align: "left",
            headerClassName: "super-app-theme--header",
            renderCell: (params) => (
                <span
                    style={{ textDecoration: "underline", fontWeight: "bold", cursor: "pointer" }}
                    onClick={() => {
                        navigate(status && status === "drivers" ? `/driverTicketManagment/details/${params?.row?._id}` : `/passengerTicketManagment/details/${params?.row?._id}`)
                        dispatch(postDataRedux({ stateName: 'details', data: params?.row }));
                    }}
                >
                    {params?.row?.short_id}
                </span>
            ),
        },
        {
            field: "info",
            headerName: status === "drivers" ? "Driver Name" : "Passenger Name",
            flex: 1,
            minWidth: "205px",
            editable: true,
            style: styleb,
            align: "left",
            headerClassName: "super-app-theme--header",
            renderCell: (params) => (
                <span style={{
                    color: "#495974",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "18px" /* 150% */, textDecoration: "underline",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: 'start',
                    cursor: "pointer"
                }}
                    onClick={() => {
                        navigate(status && status === "drivers" ? `/driverTicketManagment/details/${params?.row?._id}` : `/passengerTicketManagment/details/${params?.row?._id}`)
                        dispatch(postDataRedux({ stateName: 'details', data: params?.row }));
                    }}
                >
                    <img
                        style={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "50%",
                            marginRight: "5px"
                        }}
                        src={params?.row?.owner_info?.profile_picture ? params?.row?.owner_info?.profile_picture : "https://i.pinimg.com/736x/de/59/4e/de594ec09881da3fa66d98384a3c72ff.jpg"}
                        alt=""
                    />
                    <span>{params?.row?.owner_info?.first_name + " " + params?.row?.owner_info?.last_name} </span>
                    <UnreadDocument collectionName={'ticket_chat'}
                        conditions={[
                            { key: 'unRead.admin', operator: '>', value: 0 },
                            { key: 'id', operator: '==', value: params?.row?._id },

                        ]}
                    />
                </span>
            ),
        },
        {
            field: "subject",
            headerName: "Subject",
            flex: 1,
            minWidth: "50px",
            editable: true,
            style: {
            },
            align: "center",
            headerClassName: "super-app-theme--header",
            renderCell: (params) => <Subject subject={params?.row?.knowledgeBase?.type} />,
        },
        {
            field: "priority",
            headerName: "Priority",
            flex: 1,
            minWidth: "50px",
            editable: true,
            style: styleb,
            align: "center",
            headerClassName: "super-app-theme--header",
            renderCell: (params) => <>
                {capitalizeFirstLetter(params?.row?.knowledgeBase?.priority)}
            </>,
        },
        {
            field: "reason",
            headerName: "Reason",
            flex: 1,
            minWidth: "200px",
            editable: true,
            style: style,
            align: "start",
            headerClassName: "super-app-theme--header",
            renderCell: (params) => <>
                {capitalizeFirstLetter(params?.row?.knowledgeBase?.title)}
            </>,
        },
        {
            field: "Date",
            headerName: "Date",
            minWidth: "190px",
            flex: 1,
            style: style,
            align: "left",
            headerClassName: "super-app-theme--header",
            renderCell: (params) => <>
                {formatDate(getDateForamted(params?.row?.createdAt), countryCode)}
            </>,
        },
        {
            field: "assigned_info",
            headerName: "Assigned Agent",
            flex: 1,
            minWidth: "205px",
            editable: true,
            style: styleb,
            align: "left",
            headerClassName: "super-app-theme--header",
            renderCell: (params) => (
                <span style={{
                    color: "#495974",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "18px" /* 150% */, textDecoration: "underline",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: 'start'

                }}
                >
                    <img
                        style={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "50%",
                            marginRight: "5px"
                        }}
                        src={params?.row?.assigned_info?.profile_picture ? params?.row?.assigned_info?.profile_picture : "https://i.pinimg.com/736x/de/59/4e/de594ec09881da3fa66d98384a3c72ff.jpg"}
                        alt=""
                    />
                    <span> {params?.row?.assigned_info?.name ? params?.row?.assigned_info?.name : "Not Assigned"} </span>
                </span>
            ),
        },
        {
            field: "description",
            headerName: "Description",
            minWidth: "150px",
            flex: 1,
            style: style,
            align: "left",
            overflow: "hidden",
            textOverflow: "ellipsis",
            headerClassName: "super-app-theme--header",
            renderCell: (params) => (
                <span style={{
                    display: "block",
                    whiteSpace: "nowrap",
                    minWidth: "100%",
                    maxWidth: "150px",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                }}
                >{capitalizeFirstLetter(params.row.description)}</span>
            ),
        },
        {
            field: "status",
            headerName: "Status",
            flex: 1,
            minWidth: "50px",
            editable: true,
            style: style,
            align: "center",
            headerClassName: "super-app-theme--header",
            renderCell: (params) => <Status status={params?.row?.status} from={"ticketTable"} />,
        }
        ,
        {
            field: "action",
            headerName: "Action",
            style: style,
            headerClassName: "super-app-theme--header",
            deleteFunc: { key: '_id', stateName: 'ticketsList', url: 'admin/help/ticket' },
            editFunc: (row) => {
                dispatch(postDataRedux({ stateName: 'details', data: row }));
                dispatch(postDataRedux({ stateName: 'addEditStatus', data: "edit" }));
                dispatch(openModal(<AddNewTicket status={status} />))
            },
            viewFunc: (row) => {
                navigate(status && status === "drivers" ? `/driverTicketManagment/details/${row?._id}` : `/passengerTicketManagment/details/${row?._id}`)
                dispatch(postDataRedux({ stateName: 'details', data: row }));
            }
        },
    ];

    return (
        <>
            {/* titel and add btn */}
            <Grid
                item
                xs={12}
                sm={12}
                md={9}
                lg={9}
                xl={9}
                style={{
                    display: "flex",
                    gap: "14px",
                    marginTop: "-70px",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                }}>
                <Box style={{
                    display: "flex",
                    flexDirection: 'row',

                    gap: "14px",
                }}>
                    <span style={{
                        color: "#FFFFFF",
                        fontFamily: "Outfit",
                        fontSize: "24px",
                        fontWeight: "700",
                        lineHeight: "33px",

                    }}>
                        Ticket Management
                    </span>
                </Box>
                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: "#FFFFFF",
                        borderRadius: "20px",
                        fontWeight: "bold",
                        color: colorConfigs?.sidebar?.bg,
                        '&:hover': {
                            backgroundColor: '#FFFFFF',
                        },
                    }}
                    onClick={() => {
                        dispatch(openModal(<AddNewTicket status={status} />))
                        dispatch(postDataRedux({ stateName: 'addEditStatus', data: "add" }));

                    }}
                >
                    +{" "}{"Add New Ticket"}

                </Button>

            </Grid>

            {/* search and filter */}
            <Grid
                container
                spacing={1}
                style={{
                    display: "flex",
                    flexDirection: 'row',
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingLeft: "10px",
                    marginTop: "30px",
                    marginBottom: "15px",
                }}>
                <Grid style={{
                    minWidth: '150px',

                }}>
                    <span style={{
                        color: "#172B4D",
                        fontFamily: "Poppins",
                        fontSize: "24px",
                        fontWeight: "600",
                        lineHeight: "27px",
                    }}> {status === "drivers" ? "Drivers" : "Passengers"} </span>
                </Grid>
                <Grid style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}>
                    <TextField
                        label="Search"
                        name="search"
                        placeholder={`Search By Ticket ID or ${status === "drivers" ? "Driver" : "Passenger"} Name.`} value={filters.keyword}
                        onChange={handleSearch}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon sx={{ color: "gray" }} />
                                </InputAdornment>
                            ),
                        }}
                        variant="outlined"
                        sx={{
                            "& label.Mui-focused": { color: "rgba(0, 0, 0, 0.6)" },
                            "& .MuiOutlinedInput-root": {
                                "&.Mui-focused fieldset": {
                                    borderColor: "#A0AEC0",
                                    borderWidth: "1px",
                                },
                                borderRadius: "10px",
                                height: "52px",
                                minWidth: status === "drivers" ? "350px" : "380px"
                            },
                        }}
                    />
                    <FormControl style={{ minWidth: "150px", marginLeft: "5px", height: "52px", }} sx={{
                        "& label.Mui-focused": {
                            color: "rgba(0, 0, 0, 0.6)",
                            bordercolor: "#BBC1CE",
                        },
                        "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                                borderColor: "#BBC1CE",
                            },
                            "&:hover fieldset": {
                                borderColor: "#BBC1CE",
                            },
                            "&.Mui-focused fieldset": {
                                borderColor: "#BBC1CE",
                                borderWidth: "1px",
                            },
                        },
                    }}>
                        <InputLabel id="demo-simple-select-label">Filter</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={filters.type || "all"}
                            onChange={handleStatusChange}
                            label="Status"
                            style={{ borderRadius: "10px", height: "50px" }}
                            MenuProps={{
                                disableScrollLock: true,
                            }}
                        >
                            <MenuItem value={"all"}>All</MenuItem>
                            <MenuItem value={"ride"}>Ride</MenuItem>
                            <MenuItem value={"payment"}>Payment</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

            {/* table */}
            {status === "drivers" && (
                <DynamicTable
                    columns={columns}
                    data={ticketsList}
                    count={count}
                    page={page}
                    rowsLimits={rowsLimits}
                    pageChange={handlePageChange}
                    rowsChange={handleRowsChange}
                    pageType="tickets"
                />
            )}
            {status === "riders" && (
                <DynamicTable
                    columns={columns}
                    data={ticketsList}
                    count={count}
                    page={page}
                    rowsLimits={rowsLimits}
                    pageChange={handlePageChange}
                    rowsChange={handleRowsChange}
                    pageType="tickets"
                />
            )}
        </>
    );
}
