// theme.js
import { createTheme } from "@mui/material";
import colorConfigs from "./colorConfigs";

const theme = createTheme({
  palette: {
    primary: {
      main: colorConfigs.sidebar.bg, // Set the background color here
    },
  },
  typography: {
    fontFamily: "poppins",
    textTransform: "capitalize",
  },
});

export default theme;
