import { Box, Container, FormControl, InputLabel, OutlinedInput } from "@mui/material";
import { SubmitButton } from "@zetaton-dev/zetaton-components-bank";
import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import biglogo from "../../assets/biglogo.png";
import loginback from "../../assets/loginback.png";
import womenlogo from "../../assets/womens-first/logopurple.png";
import womenlogin from '../../assets/womens-first/womenbg.png';
import colorConfigs from "../../configs/colorConfigs";
import { API_URL } from "../../utils/constant";
const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  let [error, setError] = useState("");
  let navigate = useNavigate();

  const resetPassword = async () => {
    axios
      .post(`${API_URL}/api/v1/admin/foregetPaswordEmail`, {
        email,
      }, {
        params: {
          projectId: process.env.REACT_APP_ENV === "production" ? "ridewyze" : process.env.REACT_APP_ENV === 'women_first' ? 'women-s-first' : "roadrunner"
        }
      })
      .then((response) => {
        navigate("/checkEmail");
      })
      .catch((error) => {
        setError("You are not authertized");
        setLoading(false);
      });
  };

  const validate = (value) => {
    let error = "";
    let email_pattern =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (value === "") {
      error = "Email is required*";
    } else if (!email_pattern.test(value)) {
      error = "Invalid Email, please enter valid email";
    }

    return error;
  };
  const handleResetPassword = async () => {
    setLoading(true);
    try {
      const validationError = await validate(email);

      if (validationError === "") {
        resetPassword();
      } else {
        setError(validationError);
      }
    } catch (error) {
      console.error("Error during validation:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <div>
      <div
        style={{
          background: `url(${process.env.REACT_APP_ENV === 'women_first' ? womenlogin : loginback})`,
          height: "50vh",
        }}
      ></div>
      <div
        style={{
          height: "50vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Container
          component="main"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "15px",
            boxShadow:
              "0px 7px 23px 0px rgba(0, 0, 0, 0.05),0px 7px 23px 0px rgba(0, 0, 0, 0.05)",
            backgroundColor: "white",
            marginTop: "-250px",
            padding: "14px 0",
            width: "400px",
          }}
        >
          <img alt="logo" style={{ width: "50%" }} src={process.env.REACT_APP_ENV === 'women_first' ? womenlogo : biglogo} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              fontSize: "20px",
              fontStyle: "normal",
              gap: "8px",
              marginBottom: "14px",
            }}
          >
            <span
              style={{
                color: "#42526E",
                fontWeight: "700",
              }}
            >
              Forgot Password
            </span>
            <span
              style={{
                color: "#7A869A",
                fontWeight: "400",
                fontSize: "14px",
                letterSpacing: "-0.5px",
                fontFamily: "Poppins",
                textAlign: "center",
              }}
            >
              Enter your email, and we'll send you a link to reset it.
            </span>
          </div>

          <form
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            <FormControl
              sx={{
                m: 1,
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  borderColor: colorConfigs.sidebar.bg, // Initial border color
                },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#BBC1CE", // Change this to the color you want
                },
              }}
              variant="outlined"
            >
              <InputLabel
                style={{ color: "#BBC1CE" }}
                htmlFor="outlined-adornment-email"
              >
                Email
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-email"
                type="email"
                value={email}
                onChange={handleEmailChange}
                label="Email"
              />
            </FormControl>
            <span
              style={{ color: "red", textAlign: "start", fontSize: "14px" }}
            >
              {error}
            </span>
            <Box
              sx={{
                marginTop: "30px",
                mb: "42px",
                display: "flex",
                justifyContent: "center",
                width: "100%",
                gap: "12px",
              }}
            >
              <Link
                to="/login"
                style={{
                  width: "50%",
                  border: "1px solid #7A869A",
                  cursor: "pointer",
                  color: colorConfigs.sidebar.bg,
                  fontWeight: "bold",
                  fontSize: "16px",
                  textDecoration: "none",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "4px",
                  marginLeft: "12px",
                }}
              >
                Back to login
              </Link>
              <SubmitButton
                variant="contained"
                style={{
                  width: "50%",
                  backgroundColor: colorConfigs.sidebar.bg,
                  cursor: "pointer",
                  color: "white",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
                onClick={handleResetPassword}
                label={"Reset"}
                loading={loading}
              />
            </Box>
          </form>
        </Container>
      </div>
    </div>
  );
};

export default ForgotPassword;
