import { Route } from "react-router-dom";
import PageWrapper from "../components/layout/PageWrapper";
import appRoutes from "./appRoutes";

const generateRoute = (routes) => {
  return routes.map((route, index) => (

    <Route
      index
      path={route.path}
      element={<PageWrapper state={route.state}>
        {route.element}
      </PageWrapper>}
      key={index}
    />
  ))
};

const speratedRoutes = []
const seperateRoute = appRoutes.forEach((route, index) => {
  if (route?.children) {
    route?.children.forEach((child) => {
      speratedRoutes.push(child)
    })
  } else {
    speratedRoutes.push(route)
  }
  return speratedRoutes
}
)


export const routes = generateRoute(speratedRoutes);
