import React, { useEffect, useState } from "react";
import dropoffIcon from "../../assets/images/deliver.png";
import pickupIcon from "../../assets/images/location.png";
import stopIcon from "../../assets/images/stop.png";
import colorConfigs from "../../configs/colorConfigs";
import { mapStyles } from "../../utils/constant";

const BestRoute = ({ pickup, dropoff, wayPoints }) => {

  const [pickupMarker, setPickupMarker] = useState(null);
  const [dropoffMarker, setDropoffMarker] = useState(null);
  const [routeDrawn, setRouteDrawn] = useState(false);

  // console.log(pickup);
  // console.log(dropoff);
  // console.log(wayPoints);

  const geocodeLatLng = (geocoder, latlng, callback) => {
    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          callback(results[0].formatted_address);
        } else {
          callback("No address found");
        }
      } else {
        callback("Geocoder failed due to: " + status);
      }
    });
  };

  const delay = ms => new Promise(res => setTimeout(res, ms));

  useEffect(() => {
    async function fetchDirections() {
      // التحقق من صحة الإحداثيات
      const isValidCoordinate = (coord) => {
        return coord && !isNaN(coord.lat) && !isNaN(coord.lng);
      }

      if (!isValidCoordinate(pickup) || !isValidCoordinate(dropoff)) {
        console.error('Invalid coordinates:', pickup, dropoff);
        return;
      }

      if (!routeDrawn) {
        const directionsService = new window.google.maps.DirectionsService();
        const geocoder = new window.google.maps.Geocoder();
        const map = new window.google.maps.Map(document.getElementById("map2"), {
          center: pickup,
          zoom: 14,
          styles:mapStyles,          
        });

        const bounds = new window.google.maps.LatLngBounds();

        const request = {
          origin: {
            lat: parseFloat(pickup.lat),
            lng: parseFloat(pickup.lng)
          },
          destination: {
            lat: parseFloat(dropoff.lat),
            lng: parseFloat(dropoff.lng)
          },
          travelMode: "DRIVING",
          waypoints: wayPoints,
        };

        // console.log("Request origin:", request.origin);
        // console.log("Request destination:", request.destination);
        // console.log("Waypoints:", wayPoints);

        directionsService.route(request, (result, status) => {
          if (status === "OK") {
          } else {
            console.error("Directions request failed due to: " + status);
          }
        });

        await delay(1000);

        directionsService.route(request, (result, status) => {
          if (status === "OK") {
            const directionsRenderer = new window.google.maps.DirectionsRenderer({
              map: map,
              directions: result,
              suppressMarkers: true,
              polylineOptions: {
                strokeColor: colorConfigs?.sidebar?.bg,
                strokeOpacity: 1.0,
                strokeWeight: 4,
              },
            });

            const routeBounds = result.routes[0].bounds;
            bounds.union(routeBounds);

            map.fitBounds(bounds);
            setRouteDrawn(true);
          }
        });

        if (pickup) {
          const pickupMarker = new window.google.maps.Marker({
            position: pickup,
            map: map,
            title: "Pickup Location",
            icon: {
              url: pickupIcon,
              scaledSize: new window.google.maps.Size(40, 40),
            },
          });

          geocodeLatLng(geocoder, pickup, (address) => {
            const pickupInfoWindow = new window.google.maps.InfoWindow({
              content: `Pickup Location: ${address}`,
            });

            pickupMarker.addListener("click", () => {
              pickupInfoWindow.open(map, pickupMarker);
            });
          });

          setPickupMarker(pickupMarker);
        }

        if (dropoff) {
          const dropoffMarker = new window.google.maps.Marker({
            position: dropoff,
            map: map,
            title: "Dropoff Location",
            icon: {
              url: dropoffIcon,
              scaledSize: new window.google.maps.Size(40, 40), // Adjust size as needed
            },
          });

          geocodeLatLng(geocoder, dropoff, (address) => {
            const dropoffInfoWindow = new window.google.maps.InfoWindow({
              content: `Dropoff Location: ${address}`,
            });

            dropoffMarker.addListener("click", () => {
              dropoffInfoWindow.open(map, dropoffMarker);
            });
          });

          setDropoffMarker(dropoffMarker);
        }

        if (wayPoints && wayPoints?.length > 0) {
          wayPoints.forEach((wp) => {
            const waypointMarker = new window.google.maps.Marker({
              position: wp.location,
              map: map,
              title: "Waypoint",
              icon: {
                url: stopIcon,
                scaledSize: new window.google.maps.Size(40, 40),
              },
            });

            geocodeLatLng(geocoder, waypointMarker.getPosition(), (address) => {
              const waypointInfoWindow = new window.google.maps.InfoWindow({
                content: `Waypoint: ${address}`,
              });

              waypointMarker.addListener("click", () => {
                waypointInfoWindow.open(map, waypointMarker);
              });
            });
          });
        }

        return () => {
          if (pickupMarker) {
            pickupMarker.setMap(null);
          }
          if (dropoffMarker) {
            dropoffMarker.setMap(null);
          }
          setRouteDrawn(false);
        };
      }
    }

    fetchDirections();
  }, [pickup, dropoff, wayPoints, routeDrawn]);

  return <div id="map2" style={{ height: "400px", width: "100%" }}></div>;
};

export default BestRoute;
