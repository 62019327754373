import React, { useEffect, useState, useRef } from 'react';
import { Box, TextField, Button, Grid, MenuItem, Typography, CircularProgress, ListItem, ListItemText, Divider, List } from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import PhoneInput from 'react-phone-input-2';
import colorConfigs from '../../../../configs/colorConfigs';
import { getMethod, postMethod, putMethod } from '../../../Hook/UseCustomApi';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { useDispatch, useSelector } from 'react-redux';
import { postDataRedux, putDataRedux } from '../../../../redux/features/appStateSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MobileDatePicker, MobileTimePicker } from '@mui/x-date-pickers';
import { Timestamp } from 'firebase/firestore';
const inputStyle = {
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#BBC1CE !important",
            borderWidth: 1,
            borderRadius: "8px",
        },
        "&:hover fieldset": {
            borderColor: "#BBC1CE",
            borderWidth: 1,
            borderRadius: "8px",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#BBC1CE",
            borderWidth: 1,
            borderRadius: "8px",
        },
        input: {
            "&::placeholder": {
                color: "#BBC1CE",
                opacity: 1,
                fontSize: 14,
            },
            padding: "10px",
        },
    },
    "& label": {
        color: "#7A869A !important",
        lineHeight: "0.8375em",
    },
    "& label.Mui-focused": {
        color: "#000",
        fontSize: "14px",
        fontWeight: "500",
    },
    '& .MuiInputLabel-root': {
        color: 'gray',
        borderColor: 'gray'
    },
    width: '100%',

};

const validationSchema = Yup.object({
    first_name: Yup.string()
        .matches(/^[a-zA-Z\s]+$/, 'First name can only contain letters')
        .required('First name is required'),
    last_name: Yup.string()
        .matches(/^[a-zA-Z\s]+$/, 'Last name can only contain letters')
        .required('Last name is required'),
    phoneNumber: Yup.string()
        .required('Phone number is required')
        .test('is-valid-phone', 'Phone number is not valid for the selected region', function (value) {
            const { path, createError } = this;
            const selectedCountry = this.parent.countryCode || 'us';

            try {
                const phoneNumber = parsePhoneNumberFromString(value, selectedCountry.toUpperCase());
                if (!phoneNumber || !phoneNumber.isValid()) {
                    return createError({ path, message: `Phone number must be valid for region ${selectedCountry.toUpperCase()}` });
                }
                return true;
            } catch (error) {
                return createError({ path, message: `Phone number must be valid for region ${selectedCountry.toUpperCase()}` });
            }
        }),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    voucherGroup: Yup.string().required('Voucher group is required'),
    startDate: Yup.date().required('Start date is required'),
    expireDate: Yup.date()
        .required('Expire date is required')
        .test('is-greater-or-equal', 'The expiration date should be on or after the start date.', function (value) {
            const { startDate } = this.parent;
            return new Date(value) >= new Date(startDate);
        }),
    fromTime: Yup.string()
        .required('From time is required')
        .test('is-future-time', 'From time should be in the future', function (value) {
            const { startDate } = this.parent;
            const currentDate = new Date();
            const selectedDate = new Date(startDate);

            // Check if start date is today
            if (selectedDate.toDateString() === currentDate.toDateString()) {
                const [hours, minutes] = value.split(':');
                const selectedTime = new Date(selectedDate.setHours(hours, minutes));

                if (selectedTime <= currentDate) {
                    return this.createError({ message: 'should be time in the future when start date is today.' });
                }
            }

            return true;
        }),
    toTime: Yup.string()
        .required('To time is required')
        .test('is-greater-or-equal', 'The end time should be on or after the start time.', function (value) {
            const { fromTime } = this.parent;
            return value >= fromTime;
        }),
});

const AddMember = ({ edit = false, view = false, setIsEdit }) => {
    const [vouchers, setVouchers] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [selectedVoucher, setSelectedVoucher] = useState(null);
    const [loading, setLoading] = useState(false);
    const [originalData, setOriginalData] = useState({});
    const [formKey, setFormKey] = useState(0); // Added key to force re-render
    const navigate = useNavigate();
    const selectedCorporate = useSelector((state) => state.appState.selectedCorporate);
    const selectedCorporateMember = useSelector((state) => state.appState.selectedCorporateMember);
    const dispatch = useDispatch();
    const resetFormRef = useRef(null); // Use ref to store the resetForm function
    const [generalError, setGeneralError] = useState('');
    const location = useLocation()
    const memberType = location?.pathname?.includes('employee') ? 'Employee' : 'Customer'

    useEffect(() => {
        const getVouchers = async () => {
            try {
                const response = await getMethod({
                    url: 'admin/voucher',
                    params: {
                        corporate: selectedCorporate._id
                    }
                });
                setVouchers(response?.data);
            } catch (error) {
                console.error('Error:', error);
            }
        };
        getVouchers();
    }, []);

    useEffect(() => {
        if (edit || view) {
            // Store the original data when the component is mounted in edit/view mode
            setOriginalData({
                first_name: selectedCorporateMember?.first_name || '',
                last_name: selectedCorporateMember?.last_name || '',
                phoneNumber: selectedCorporateMember?.full_phone_number || '',
                email: selectedCorporateMember?.email || '',
                voucherGroup: selectedCorporateMember?.voucher_details?.voucher_name || '',
                startDate: selectedCorporateMember?.voucher_details?.start_date || '',
                expireDate: selectedCorporateMember?.voucher_details?.expire_date || '',
                fromTime: selectedCorporateMember?.voucher_details?.start_time
                    ? new Date(selectedCorporateMember.voucher_details.start_time * 1000)
                    : '',
                toTime: selectedCorporateMember?.voucher_details?.end_time
                    ? new Date(selectedCorporateMember.voucher_details.end_time * 1000)
                    : '',

                countryCode: selectedCorporateMember?.countryCode || 'us',
            });
            setFormKey((prevKey) => prevKey + 1); // Force re-render by changing key
        }
    }, [edit, view, selectedCorporateMember]);

    useEffect(() => {
        if (!edit && view && resetFormRef?.current) {
            resetFormRef.current(); // Reset the form when switching to view mode
        }
    }, [edit, view]);

    const handlePhoneNumberInputChange = async (phone, countryData) => {
        if (!view) { // Disable fetch in view mode
            const input = phone.replace(countryData?.dialCode, '');
            if (input.length > 3) { // Fetch customers only when input length > 3
                try {
                    const response = await getMethod({
                        url: 'customer',
                        params: { search_phone_number: input }, // Send the phone number without country code
                    });
                    setCustomers(response?.data || []);
                } catch (error) {
                    console.error('Error fetching customers:', error);
                }
            } else {
                setCustomers([]); // Clear suggestions if input is less than 3 characters
            }
        }
    };

    const handleVoucherChange = (voucherId, setFieldValue) => {
        const voucher = vouchers.find((v) => v.voucher_name === voucherId);
        if (voucher) {
            setFieldValue('voucherGroup', voucher.voucher_name); // Display voucher name in input field
            setSelectedVoucher(voucher);
        }
    };

    const handleCustomerSelect = (member, setFieldValue) => {
        setFieldValue('phoneNumber', member?.full_phone_number);
        setFieldValue('first_name', member?.first_name);
        setFieldValue('last_name', member?.last_name);
        setFieldValue('email', member?.email);
        setCustomers([]);
    };

    const handleSubmit = async (values, { setSubmitting }) => {
        if (view) return;
        setLoading(true);
        setGeneralError('')

        try {
            const phoneNumberParsed = parsePhoneNumberFromString(values.phoneNumber, values.countryCode.toUpperCase());

            const formattedData = {
                first_name: values?.first_name,
                last_name: values?.last_name,
                phone_number: phoneNumberParsed?.nationalNumber,
                code_phone_number: `+${phoneNumberParsed?.countryCallingCode}`,
                email: values.email,
                corporate_id: selectedCorporate?._id,
                voucher_details: {
                    ...selectedVoucher,
                    voucher_id: selectedVoucher ? selectedVoucher?._id : selectedCorporateMember?.voucher_details?.voucher_id,
                    voucher_name: selectedVoucher ? selectedVoucher?.voucher_name : selectedCorporateMember?.voucher_details?.voucher_name,
                    voucher_code: selectedVoucher ? selectedVoucher?.voucher_code : selectedCorporateMember?.voucher_details?.voucher_code,
                    max_rides_number: selectedVoucher ? selectedVoucher?.max_rides_number : selectedCorporateMember?.voucher_details?.max_rides_number,
                    max_voucher_price: selectedVoucher ? selectedVoucher?.max_voucher_price : selectedCorporateMember?.voucher_details?.max_voucher_price,
                    max_ride_price: selectedVoucher ? selectedVoucher?.max_ride_price : selectedCorporateMember?.voucher_details?.max_ride_price,
                    start_date: new Date(values?.startDate).getTime() / 1000,
                    expire_date: new Date(values?.expireDate).getTime() / 1000,
                    start_time: Timestamp?.fromDate(new Date(convertTimeToSeconds(values?.fromTime)))?.seconds,
                    end_time: Timestamp?.fromDate(new Date(convertTimeToSeconds(values?.toTime)))?.seconds,
                },
                corporate_user_type: memberType === 'Employee' ? 'employee' : 'customer'
            };


            if (!edit) {
                await postMethod({
                    url: 'admin/corporate-user',
                    body: formattedData
                }).then(response => {
                    navigate('/cooperates/corporate-details');
                    dispatch(postDataRedux({ stateName: `corporate${memberType}List`, data: response.data, push: true }));
                    setLoading(false);
                });

            } else {
                await putMethod({
                    url: `admin/corporate-user/${selectedCorporateMember?._id}`,
                    body: formattedData
                }).then(response => {
                    dispatch(putDataRedux({ stateName: `corporate${memberType}List`, data: response.data, key: '_id', value: selectedCorporateMember?._id }));
                    dispatch(putDataRedux({ stateName: 'selectedCorporateMember', data: response.data }));
                    navigate('/cooperates/corporate-details');
                    setLoading(false);
                });
            }
        } catch (error) {
            console.error('Error:', error);
            console.log(error)
            setGeneralError('There was an error while saving your data. Please try again.');
            setLoading(false);
        }
        setSubmitting(false);
    };
    const convertTimeToDate = (time) => {
        console.log(time)
        if (typeof time !== 'string' || !time.includes(':')) {
            return null;
        }

        const [hours, minutes] = time.split(':').map(Number);
        const date = new Date();
        date.setHours(hours, minutes, 0, 0);
        return date;
    };



    const formatTime = (dateString) => {
        const date = new Date(dateString);
        const options = {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        };
        console.log( date.toLocaleTimeString('en-GB', options))
        return date.toLocaleTimeString('en-GB', options);
    }
    const convertTimeToSeconds = (time) => {
        const currentDate = new Date(); // Get the current date

        if (time instanceof Date) {
            // Set hours, minutes, and seconds using UTC methods
            currentDate.setUTCHours(time.getUTCHours(), time.getUTCMinutes(), 0);
            return currentDate;
        } else if (typeof time === 'string' && time.includes(':')) {
            const [hours, minutes] = time.split(':').map(Number);
            // Set hours, minutes, and seconds using UTC methods and increase by 3 hours
            currentDate.setUTCHours(hours, minutes, 0);
            return currentDate;
        }
        return null;
    };

    return (
        <Formik
            key={formKey} // Force re-render by changing key
            initialValues={{
                first_name: originalData?.first_name || '',
                last_name: originalData?.last_name || '',
                phoneNumber: originalData?.phoneNumber || '',
                email: originalData?.email || '',
                voucherGroup: originalData?.voucherGroup || '',
                startDate: originalData?.startDate ? new Date(originalData.startDate * 1000) : null,
                expireDate: originalData?.expireDate ? new Date(originalData.expireDate * 1000) : null,
                fromTime: originalData?.fromTime ? convertTimeToDate(formatTime(originalData.fromTime)) : null,
                toTime: originalData?.toTime ? convertTimeToDate(formatTime(originalData.toTime)) : null,
                countryCode: originalData?.countryCode || 'us',
            }}
            validationSchema={validationSchema}
            enableReinitialize // Ensure form reinitializes when initialValues change
            onSubmit={handleSubmit}
        >
            {({ values, handleChange, setFieldValue, resetForm }) => {
                resetFormRef.current = () => resetForm({ values: originalData });

                return (
                    <Form>
                        <Box
                            sx={{
                                width: '100%',
                                padding: (!edit && !view) ? 3 : 0,
                                bgcolor: 'white',
                                borderRadius: 4,
                                marginTop: (!edit && !view) ? -9 : 0,
                            }}
                        >
                            {!edit && !view && (
                                <Box
                                    style={{
                                        fontFamily: "inter",
                                        fontSize: "small",
                                        marginTop: "10px",
                                        borderRadius: "8px",
                                        padding: "10px",
                                        width: "100%",
                                        marginBottom: "20px",
                                        color: colorConfigs?.sidebar?.bg,
                                        backgroundColor: "#F4F4F4",
                                    }}
                                >
                                    <Typography variant="body1" style={{ fontWeight: "bold" }}>
                                        Add New {memberType}
                                    </Typography>
                                </Box>
                            )}
                            <Box display="flex" alignItems="center" style={{ marginBottom: "20px" }}>
                                <Typography component="span" style={{ fontWeight: 700, fontSize: 18, marginRight: "8px" }}>
                                    •
                                </Typography>
                                <Typography component="span" style={{ fontWeight: 700, fontSize: 18 }}>
                                    {memberType} Details
                                </Typography>
                            </Box>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <PhoneInput
                                        country={values.countryCode}
                                        value={values.phoneNumber}
                                        onChange={(phone, countryData) => {
                                            if (!view && !edit) {
                                                setFieldValue('phoneNumber', phone);
                                                setFieldValue('countryCode', countryData.countryCode);
                                                handlePhoneNumberInputChange(phone, countryData);
                                            }
                                        }}
                                        inputStyle={{
                                            width: '100%',
                                            borderColor: '#BBC1CE',
                                            borderRadius: '8px',
                                            height: '45px',
                                            paddingLeft: '52px',
                                            pointerEvents: view || edit ? 'none' : 'auto',
                                        }}
                                        buttonStyle={{
                                            borderColor: '#BBC1CE',
                                            borderRadius: '8px 0 0 8px',
                                            pointerEvents: view || edit ? 'none' : 'auto',
                                        }}
                                        containerStyle={{
                                            width: '100%',
                                        }}
                                        inputProps={{
                                            name: 'phoneNumber',
                                            required: true,
                                            disabled: view || edit,
                                        }}
                                        placeholder="Please enter your phone number"
                                    />
                                    <ErrorMessage name="phoneNumber" component="div" style={{ color: 'red', fontSize: 12, marginTop: '5px' }} />
                                    {customers.length > 0 && !view && (
                                        <Box sx={{ position: 'relative' }}>
                                            <List sx={{
                                                position: 'absolute',
                                                top: '10px',
                                                left: 0,
                                                width: '100%',
                                                maxHeight: '400px',
                                                overflowY: 'auto',
                                                backgroundColor: 'background.paper',
                                                border: '1px solid #ccc',
                                                borderRadius: '8px',
                                                boxShadow: 3,
                                                zIndex: 1000,
                                                padding: 0,
                                            }}>
                                                {customers?.map((customer) => (
                                                    <React.Fragment key={customer._id}>
                                                        <ListItem
                                                            button
                                                            onClick={() => handleCustomerSelect(customer, setFieldValue)}
                                                            sx={{ padding: '10px 15px', cursor: 'pointer' }}
                                                        >
                                                            <ListItemText primary={`${customer.code_phone_number} ${customer.phone_number}`} />
                                                        </ListItem>
                                                        <Divider component="li" />
                                                    </React.Fragment>
                                                ))}
                                            </List>
                                        </Box>
                                    )}
                                </Grid>
                                <Grid item xs={3}>
                                    <Field
                                        as={TextField}
                                        sx={inputStyle}
                                        fullWidth
                                        label="First Name"
                                        name="first_name"
                                        variant="outlined"
                                        helperText={<ErrorMessage component="div" style={{ color: 'red', fontSize: 12, marginTop: '2px', marginLeft: '-10px' }} name="first_name" />}
                                        InputProps={{ disabled: view || edit }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Field
                                        as={TextField}
                                        sx={inputStyle}
                                        fullWidth
                                        label="Last Name"
                                        name="last_name"
                                        variant="outlined"
                                        helperText={<ErrorMessage component="div" style={{ color: 'red', fontSize: 12, marginTop: '2px', marginLeft: '-10px' }} name="last_name" />}
                                        InputProps={{ disabled: view || edit }}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <Field
                                        as={TextField}
                                        sx={inputStyle}
                                        fullWidth
                                        label="Email"
                                        name="email"
                                        variant="outlined"
                                        type="email"
                                        helperText={<ErrorMessage component="div" style={{ color: 'red', fontSize: 12, marginTop: '2px', marginLeft: '-10px' }} name="email" />}
                                        InputProps={{ disabled: view || edit }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box display="flex" alignItems="center">
                                        <Typography component="span" style={{ fontWeight: 700, fontSize: 18, marginRight: "8px" }}>
                                            •
                                        </Typography>
                                        <Typography component="span" style={{ fontWeight: 700, fontSize: 18 }}>
                                            Voucher Details
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Field
                                        as={TextField}
                                        sx={{
                                            ...inputStyle,
                                            '& .MuiInputBase-root': {
                                                height: '45px',
                                                lineHeight: '45px',
                                            },
                                            '& .MuiSelect-select': {
                                                paddingTop: '10px',
                                                paddingBottom: '10px',
                                                display: 'flex',
                                                alignItems: 'center',
                                            },
                                        }}
                                        select={!view}
                                        fullWidth
                                        label="Voucher Groups"
                                        name="voucherGroup"
                                        variant="outlined"
                                        onChange={(e) => {
                                            if (!view) {
                                                const value = e.target.value;
                                                handleChange(e);
                                                handleVoucherChange(value, setFieldValue);
                                            }
                                        }}
                                        displayEmpty
                                        value={values.voucherGroup}
                                        helperText={<ErrorMessage component="div" style={{ color: 'red', fontSize: 12, marginTop: '2px', marginLeft: '-10px' }} name="voucherGroup" />}
                                        InputProps={{
                                            readOnly: view,
                                            placeholder: 'Select Voucher Group',
                                        }}
                                    >
                                        <MenuItem disabled value="">
                                            Select Voucher Group
                                        </MenuItem>
                                        {vouchers?.map((voucher) => (
                                            <MenuItem key={voucher?._id} value={voucher?.voucher_name}>
                                                {voucher.voucher_name}
                                            </MenuItem>
                                        ))}
                                    </Field>
                                </Grid>
                                <Grid item xs={3}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <Field name="startDate">
                                            {({ field, form }) => (
                                                <MobileDatePicker
                                                    label={'Start Date'}
                                                    value={field.value}
                                                    disabled={view} // Disable the date picker when view is true
                                                    sx={inputStyle}
                                                    onChange={(value) => form.setFieldValue("startDate", value)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            sx={inputStyle}
                                                            fullWidth
                                                            disabled
                                                            label="Start Date"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            InputProps={{
                                                                readOnly: view,
                                                            }}
                                                        />
                                                    )}
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage name="startDate" component="div" style={{ color: 'red', fontSize: 12, marginTop: '5px', marginLeft: '0px' }} />
                                    </LocalizationProvider>
                                </Grid>

                                <Grid item xs={3}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <Field name="expireDate">
                                            {({ field, form }) => (
                                                <MobileDatePicker
                                                    label={'Expire Date'}
                                                    value={field.value}
                                                    sx={inputStyle}
                                                    disabled={view}
                                                    onChange={(value) => form.setFieldValue("expireDate", value)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            sx={inputStyle}
                                                            fullWidth
                                                            label="Expire Date"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            InputProps={{
                                                                readOnly: view,
                                                            }}
                                                        />
                                                    )}
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage name="expireDate" component="div" style={{ color: 'red', fontSize: 12, marginTop: '5px', marginLeft: '0px' }} />
                                    </LocalizationProvider>
                                </Grid>

                                <Grid item xs={3}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <Field name="fromTime">
                                            {({ field, form }) => (
                                                <MobileTimePicker
                                                    label={'From Time'}
                                                    value={field.value}
                                                    sx={inputStyle}
                                                    disabled={view}
                                                    onChange={(value) => form.setFieldValue("fromTime", value)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            sx={inputStyle}
                                                            fullWidth
                                                            label="From Time"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            InputProps={{
                                                                readOnly: view,
                                                            }}
                                                        />
                                                    )}
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage name="fromTime" component="div" style={{ color: 'red', fontSize: 12, marginTop: '5px', marginLeft: '0' }} />
                                    </LocalizationProvider>
                                </Grid>

                                <Grid item xs={3}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <Field name="toTime">
                                            {({ field, form }) => (
                                                <MobileTimePicker
                                                    label={'To Time'}
                                                    value={field.value}
                                                    disabled={view}
                                                    sx={inputStyle}
                                                    onChange={(value) => form.setFieldValue("toTime", value)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            sx={inputStyle}
                                                            fullWidth
                                                            label="To Time"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            InputProps={{
                                                                readOnly: view,
                                                            }}
                                                        />
                                                    )}
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage name="toTime" component="div" style={{ color: 'red', fontSize: 12, marginTop: '5px', marginLeft: '0px' }} />
                                    </LocalizationProvider>
                                </Grid>

                                <Grid item xs={12}>
                                    {generalError && (
                                        <Typography variant="caption" style={{ color: 'red', marginBottom: '10px' }}>
                                            {generalError}
                                        </Typography>
                                    )}
                                </Grid>

                                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-start', gap: 2 }}>
                                    {!view && (<Button
                                        variant="outlined"
                                        sx={{
                                            bgcolor: '#FFFFFF',
                                            border: `1px solid ${colorConfigs?.sidebar?.bg}`,
                                            color: colorConfigs?.sidebar?.bg,
                                            width: '150px',
                                            height: '42px',
                                            borderRadius: 3
                                        }}
                                        onClick={() =>
                                            !edit ?
                                                navigate('/cooperates/corporate-details') :
                                                setIsEdit(false)
                                        }
                                        type="button"
                                    >
                                        Cancel
                                    </Button>)}
                                    {!view && (
                                        <Button
                                            variant="contained"
                                            sx={{
                                                backgroundColor: colorConfigs?.sidebar?.bg,
                                                color: 'white',
                                                width: '150px',
                                                borderRadius: 3,
                                                height: '42px',
                                                '&:hover': {
                                                    backgroundColor: colorConfigs?.sidebar?.bg,
                                                },
                                                position: 'relative', // Positioning for loading indicator
                                            }}
                                            type="submit"
                                            disabled={loading} // Disable button during loading
                                        >
                                            {loading ? (
                                                <CircularProgress
                                                    size={24}
                                                    sx={{
                                                        color: 'white',
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        marginTop: '-12px',
                                                        marginLeft: '-12px',
                                                    }}
                                                />
                                            ) : (
                                                edit ? 'Update' : 'Save'
                                            )}
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default AddMember;
