/* eslint-disable react-hooks/exhaustive-deps */
import SendIcon from "@mui/icons-material/Send";
import { Button } from "@mui/material";
import { TextInput } from "@zetaton-dev/zetaton-components-bank";
import firebase from "firebase/compat/app";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { db } from "../../configs/firebase";
import { RiSendPlaneFill } from "react-icons/ri";
import colorConfigs from "../../configs/colorConfigs";

const useStyles = {
  padding: {
    padding: "12px 16px",
  },
  underline: {
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
  },
};

const MessageViewerBottom = ({ admin, job }) => {
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [typing, setTyping] = useState(true);
  const user = useSelector((state) => state.appState.userData ?? '');
  const [message, setMessage] = useState("");
  const selectedChat = useSelector((state) => state.appState.selectedChat);
  const selectedRide = useSelector((state) => state.appState.selectedRide);
  const uid = selectedChat?.type === 'driver' ? selectedChat?.driver : selectedChat?.customer
  const outChat = useSelector((state) => state.appState.outChat);
  const lastSupportChat = useSelector((state) => state.appState.lastSupportChat);

  const handleChatId = () => {
    if (selectedRide?.chat_type === "customer") {
      return `admin_${selectedRide?.customer_details?.fb_uid}`
    } else {
      return `admin_${selectedRide?.driver_info?.fb_uid ? selectedRide?.driver_info?.fb_uid : selectedRide?.driver_info?.fb_id}`
    }
  }
  useEffect(() => {
    if (selectedRide?.chat_type || selectedChat?.driver || selectedChat?.customer) {
      const enablerId = outChat ? handleChatId()?.split("_")[1] : uid?.split("_")[1];
      const messageStatusStream = db
        .collection("messaging_with_admin")
        .doc(outChat ? handleChatId() : uid)
        .onSnapshot(async (snapshot) => {
          if (snapshot.exists) {
          } else {
            db.collection("messaging_with_admin")
              .doc(outChat ? handleChatId() : uid)
              .set({
                expired: null,
                title: "welcome To Enabl",
                admin: "admin",
                type: selectedChat?.chat_type,
                user: enablerId,
                typing: {
                  [enablerId]: false,
                  admin: false,
                },

                users: [enablerId, "admin"],
                lastRead: {
                  [enablerId]: null,
                  admin: null,
                },
                unRead: {
                  [enablerId]: 0,
                  admin: 0,
                },
              })
              .then();
          }
          setLoading(false);
        });

      return () => {
        messageStatusStream();
      };
    } else if (lastSupportChat) {
      const uid = lastSupportChat?.type === 'driver' ? lastSupportChat?.driver : lastSupportChat?.customer
      const messageStatusStream = db
        .collection("messaging_with_admin")
        .doc(uid)
        .onSnapshot(async (snapshot) => {
          if (snapshot.exists) {
          } else {
            db.collection("messaging_with_admin")
              .doc(uid)
              .set({
                expired: null,
                title: "welcome To Enabl",
                admin: "admin",
                type: selectedChat?.chat_type,
                user: uid,
                typing: {
                  [uid]: false,
                  admin: false,
                },
                users: [uid, "admin"],
                lastRead: {
                  [uid]: null,
                  admin: null,
                },
                unRead: {
                  [uid]: 0,
                  admin: 0,
                },
              })
              .then();
          }
          setLoading(false);
        });

      return () => {
        messageStatusStream();
      };
    }
  }, [uid, selectedChat?.customer, job, selectedRide, lastSupportChat]);

  const sendMessage = (e) => {
    e.preventDefault();
    return new Promise((resolve, reject) => {
      // const jobId = uid.split("_")[0];
      // const enabler = uid.split("_")[1];
      if (message) {
        const m = message;
        setMessage("");

        if (m) {
          let ref
          if (selectedRide?.chat_type || selectedChat?.driver || selectedChat?.customer) {
            ref = db.collection("messaging_with_admin").doc(outChat ? handleChatId() : uid);
          } else if (lastSupportChat) {
            const uid = lastSupportChat?.type === 'driver' ? lastSupportChat?.driver : lastSupportChat?.customer
            ref = db.collection("messaging_with_admin").doc(uid);
          }

          ref.get().then((doc) => {
            if (doc.exists) {
              const usersList = doc.data().users;

              let userExist = false;
              usersList.forEach((u) => {
                if (u === admin?.fb_uid) {
                  userExist = true;
                }
              });

              if (userExist) {
                ref
                  .update({
                    users: firebase.firestore.FieldValue.arrayRemove("admin"),
                  })
                  .then(() => {
                    ref.update({
                      users: firebase.firestore.FieldValue.arrayUnion("admin"),
                    });
                  });
              } else {
                ref
                  .update({
                    users: firebase.firestore.FieldValue.arrayUnion("admin"),
                  })
                  .then();
              }
              ref
                .update({
                  [`typing.admin`]: false,
                  [`unRead.${usersList.filter((item) => !(item === "admin"))}`]:
                    firebase.firestore.FieldValue.increment(1),
                  updatedAt: new Date(),
                  lastMessage: {
                    uid: "admin",
                    message: message,
                  },
                })
                .then()
                .catch((e) => console.log(e));
            }
          });
          ref
            .collection("messages")
            .add({
              text: message,
              uid: "admin",
              admin_info: {
                name: user?.name,

                profile_picture:
                  user?.profile_picture?.length > 0
                    ? user?.profile_picture
                    : "",
                fb_uid: lastSupportChat?.type ? lastSupportChat?.type === 'driver' ? lastSupportChat?.driver : lastSupportChat?.customer : selectedRide?.chat_type ? handleChatId() : user?.fb_uid,
              },
              type: "text",
              createAt: new Date(),
              isSupport: true,
            })
            .then(() => setMessage(""));
          resolve("Success");
        }
      }
    });
  };

  const handleSendMessage = (value) => {
    const ref = db.collection("messaging_with_admin").doc(outChat ? handleChatId() : uid);

    if (value.length > 0) {
      setMessage(value);
      ref
        .update({
          [`typing.admin`]: true,
        })
        .then()
        .catch((e) => console.log(e));
    } else {
      setMessage(value);
      ref
        .update({
          [`typing.admin`]: false,
        })
        .then()
        .catch((e) => console.log(e));
    }
  };
  return (
    <div
      style={{
        zIndex: 9,
        borderRadius: "8px",
        background: "#FFFFFF",
        position: "absolute",
        bottom: "0",
        left: "0",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "22px 0",
      }}
    >
      <div
        style={{
          border: "none",
          background: '#FAFAFB',
          borderRadius: "13.28px",
          width: "calc(100% - 48px)",
          height: "50px",
        }}
      >
        <form onSubmit={sendMessage}>
          <TextInput
            value={message}
            onChange={(e) => {
              handleSendMessage(e.target.value);
            }}
            placeholder="Type a message..."
            fullWidth
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: 'none',
                },
                '&.Mui-focused fieldset': {
                  border: 'none',
                },
              },
            }}
            focused
            InputProps={{
              style: {
                border: "none",
                height: "50px",
                paddingLeft: "8px",
              },
              endAdornment: (
                <Button
                  type="submit"
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                  variant="text"
                  style={{
                    marginRight: "22px",
                    textTransform: "capitalize",
                  }}
                >
                  <RiSendPlaneFill
                    style={{
                      color: colorConfigs?.sidebar.bg,
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                    size={25}
                  />
                </Button>
              ),
            }}
            classes={{
              root: useStyles.padding,
            }}
          />
        </form>
      </div>
    </div>
  );
};

export default MessageViewerBottom;
