import * as React from "react"
import { memo } from "react"
const SvgComponent = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={50}
        height={50}
        fill="none"
        {...props}
    >
        <circle cx={25} cy={25} r={25} fill="#C5C5C5" opacity={0.2} />
        <path
            fill="#F2AD00"
            fillRule="evenodd"
            d="M25.711 16c-2.258 0-4.316.746-5.533 1.355a9.107 9.107 0 0 0-.786.445l1.385 2.039.652.26c2.548 1.285 5.965 1.285 8.513 0l.74-.384 1.31-1.915a8.022 8.022 0 0 0-.845-.479C29.936 16.718 27.927 16 25.712 16m-3.221 2.308c-.49-.092-.975-.214-1.45-.364 1.14-.506 2.84-1.044 4.67-1.044 1.27 0 2.469.258 3.46.585a26.469 26.469 0 0 0-3.584.782c-.93.27-2.017.24-3.096.041Zm7.98 2.532-.123.062c-2.803 1.414-6.521 1.414-9.324 0l-.117-.059c-4.211 4.62-7.426 13.155 4.804 13.155s8.938-8.693 4.76-13.158ZM25.19 25a1 1 0 1 0 0 2v-2Zm1-1v-.5h-1v.5a2 2 0 1 0 0 4v2c-.434 0-.805-.277-.943-.666a.5.5 0 1 0-.942.332A2 2 0 0 0 25.192 31v.5h1V31a2 2 0 0 0 0-4v-2c.435 0 .805.277.943.666a.5.5 0 1 0 .943-.332A2 2 0 0 0 26.192 24Zm0 4v2a1 1 0 0 0 0-2Z"
            clipRule="evenodd"
        />
        <rect
            width={10.684}
            height={10.684}
            x={23.684}
            y={26.316}
            fill="#fff"
            rx={5.342}
            transform="rotate(90 23.684 26.316)"
        />
        <path fill="#fff" d="M23.684 26.317V37H13V26.317z" opacity={0.01} />
        <path
            fill="#F2AD00"
            d="m18.884 29.343 2.04 4.24a.452.452 0 0 1-.1.527.644.644 0 0 1-.59.16l-1.715-.377v-1.848c0-.085-.08-.154-.178-.154-.099 0-.178.069-.178.154v1.848l-1.716.377a.643.643 0 0 1-.59-.16.452.452 0 0 1-.098-.526l2.039-4.24c.093-.194.3-.314.543-.314.242 0 .45.12.543.313Z"
        />
    </svg>
)
const OutComingMoney = memo(SvgComponent)
export default OutComingMoney
