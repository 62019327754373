import { Box, Card, CardContent, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FaCar, FaUserGroup } from 'react-icons/fa6';
import colorConfigs from '../../../configs/colorConfigs';
import { collection, onSnapshot } from 'firebase/firestore';
import { db } from '../../../configs/firebase';
import { useDispatch, useSelector } from 'react-redux';
import { setYear } from '../../../redux/features/appStateSlice';
import WhiteDriver from '../../../assets/svg/WhiteDriver';
import WhiteCustomer from './../../../assets/svg/WhiteCustomer';
import WhiteAdmin from './../../../assets/svg/WhiteAdmin';
import CarOutlineWhite from '../../../assets/svg/CarOutlineWhite';
import { formatNumber } from './../../../utils/formateNumber';
import { useNavigate } from "react-router-dom";

function CardsSections() {
    const selectedYear = useSelector((state) => state.appState.selectedYear);
    const dispatch = useDispatch();
    const [statistics, setStatistics] = useState({})
    const pastYears = Array.from({ length: 5 }, (_, index) => new Date().getFullYear() - index);
    const navigate = useNavigate();

    const handleChange = (event) => {
        dispatch(setYear(event.target.value));
    };
    useEffect(() => {
        const unsubscribe = onSnapshot(collection(db, 'allAnalysis'), (snapshot) => {
            const currentYear = new Date().getFullYear().toString(); // Get the current year as a string
            const data = snapshot.docs
                .filter(doc => doc.id === selectedYear) // Filter docs with id equal to current year
                .map(doc => doc.data());
            setStatistics(data[0])
        });
        return () => unsubscribe();
    }, [selectedYear]);

    return <Box sx={{ flexGrow: 1, padding: 0, mt: -10 }}>
        <Box sx={{ position: 'absolute', top: -56, right: 40, background: '#FFF', borderRadius: 20, zIndex: 11 }}>
            <FormControl>
                <Select
                    id="year-select"
                    value={selectedYear}
                    onChange={handleChange}
                    style={{ width: 100, height: 40, fontFamily: 'poppines', fontSize: 18, color: '#172B4D', fontWeight: 700, borderRadius: 20 }} // Adjust the width as needed
                >
                    {pastYears.map((year) => (
                        <MenuItem key={year} value={year}>{year}</MenuItem>
                    ))}

                </Select>
            </FormControl>
        </Box>
        <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
                <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', p: 2, boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 4px', borderRadius: 5, gap: 3 }}>
                    <Box sx={{ cursor: "pointer", background: colorConfigs.sidebar.bg, borderRadius: 25, height: '55px', width: '55px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                        onClick={() => navigate('/drivers')}
                    >
                        <WhiteDriver />
                    </Box>
                    <CardContent sx={{ width: '70%', p: 0 }} style={{ padding: 0 }}>
                        <Typography sx={{ color: '#A3AED0', fontWeight: 500, fontSize: 15 }} component="div">
                            Total Drivers
                        </Typography>
                        <Typography sx={{ color: '#1E1D47', fontWeight: 700, fontSize: 22, mt: 0.5 }} component="div">
                            {formatNumber(statistics?.totalDriver)}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} md={3}>
                <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', p: 2, boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 4px', borderRadius: 5, gap: 3 }}>
                    <Box sx={{ cursor: "pointer", background: colorConfigs.sidebar.bg, borderRadius: 25, height: '55px', width: '55px', display: 'flex', alignItems: 'center', justifyContent: 'center', }}
                        onClick={() => navigate('/passengers')}
                    >
                        <WhiteCustomer />
                    </Box>
                    <CardContent sx={{ width: '70%', p: 0 }} style={{ padding: 0 }}>
                        <Typography sx={{ color: '#A3AED0', fontWeight: 500, fontSize: 15 }} component="div">
                            Total Passengers
                        </Typography>
                        <Typography sx={{ color: '#1E1D47', fontWeight: 700, fontSize: 22, mt: 0.5 }} component="div">
                            {formatNumber(statistics?.totalCustomer)}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} md={3}>
                <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', p: 2, boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 4px', borderRadius: 5, gap: 3 }}>
                    <Box sx={{ cursor: "pointer", background: colorConfigs.sidebar.bg, borderRadius: 25, height: '55px', width: '55px', display: 'flex', alignItems: 'center', justifyContent: 'center', }}
                        onClick={() => navigate('/admins')}
                    >
                        <WhiteAdmin />
                    </Box>
                    <CardContent sx={{ width: '70%', p: 0 }} style={{ padding: 0 }}>
                        <Typography sx={{ color: '#A3AED0', fontWeight: 500, fontSize: 15 }} component="div">
                            Total Dispatchers
                        </Typography>
                        <Typography sx={{ color: '#1E1D47', fontWeight: 700, fontSize: 22, mt: 0.5 }} component="div">
                            {formatNumber(statistics?.totalDispatch)}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} md={3}>
                <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', p: 2, boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 4px', borderRadius: 5, gap: 3 }}>
                    <Box sx={{ cursor: "pointer", background: colorConfigs.sidebar.bg, borderRadius: 25, height: '55px', width: '55px', display: 'flex', alignItems: 'center', justifyContent: 'center', }}
                        onClick={() => navigate('/rides')}
                    >
                        <CarOutlineWhite />
                    </Box>
                    <CardContent sx={{ width: '70%', p: 0 }} style={{ padding: 0 }}>
                        <Typography sx={{ color: '#A3AED0', fontWeight: 500, fontSize: 15 }} component="div">
                            Total Rides
                        </Typography>
                        <Typography sx={{ color: '#1E1D47', fontWeight: 700, fontSize: 22, mt: 0.5 }} component="div">
                            {formatNumber(statistics?.totalRide)}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    </Box>
}

export default CardsSections