import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GoogleMap, useJsApiLoader, Marker, Circle } from "@react-google-maps/api";
import car from "../../../src/assets/newMarker.png";
import Location_women_first from "../../../src/assets/womens-first/Location-women-first.png";
import { saveLocationInfo } from "../../redux/features/appStateSlice";
import { GOOGLE_API_KEY, mapStyles } from "../../utils/constant";
import colorConfigs from './../../configs/colorConfigs';


const containerStyle = {
  width: '100%',
  height: '480px'
};

const SelectAdd = (props) => {
  const dispatch = useDispatch();
  const [mapCenter, setMapCenter] = useState({
    lat: props.selectedZone?.address?.lat || 0,
    lng: props.selectedZone?.address?.lng || 0,
  });
  const radius = useSelector((state) => state.appState.radius);
  const [radiusV, setRadiusV] = useState(radius);
  const mapRef = useRef(null);
  const circleRef = useRef(null);
  const [showTraffic, setShowTraffic] = useState(
    localStorage.getItem("showTraffic") === "true" // Load from localStorage
  ); const trafficLayerRef = useRef(null);
  const [mapTypeId, setMapTypeId] = useState(
    localStorage.getItem("mapTypeId") || "hybrid"
  );

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_API_KEY,
  });

  const fetchAddress = async (lat, lng) => {
    try {
      const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}`;
      const response = await fetch(url);
      const data = await response.json();
      if (data.address) {
        const address = data.display_name;
        const zipCode = data.address.postcode || ""; // Attempt to get the zip code
        dispatch(
          saveLocationInfo({
            lat,
            lng,
            address,
            zipCode,
          })
        );
      } else {
        console.error("Could not fetch address.");
      }
    } catch (error) {
      console.error("Error fetching address: ", error);
    }
  };

  useEffect(() => {
    const pos = {
      lat: mapCenter.lat,
      lng: mapCenter.lng,
    };

    if (circleRef.current) {
      circleRef.current.setMap(null); // Remove the previous circle
    }

    if (radiusV && mapRef.current) {
      const newCircle = new window.google.maps.Circle({
        strokeColor: colorConfigs?.sidebar.bg,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: colorConfigs?.sidebar.ligthBg === '#F9F6FF' ? "#bfa2f8" : colorConfigs?.sidebar.ligthBg,
        fillOpacity: 0.35,
        map: mapRef.current,
        center: pos,
        radius: radiusV * 1000,
      });
      circleRef.current = newCircle; // Store the new circle in the ref
      const bounds = newCircle.getBounds();
      if (bounds) {
        mapRef.current.fitBounds(bounds);
      }
    } else if (mapRef.current) {
      const newCircle = new window.google.maps.Circle({
        strokeColor: colorConfigs?.sidebar.bg,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: colorConfigs?.sidebar.ligthBg === '#F9F6FF' ? "#bfa2f8" : colorConfigs?.sidebar.ligthBg,
        fillOpacity: 0.35,
        map: mapRef.current,
        center: pos,
        radius: 0,
      });
      circleRef.current = newCircle; // Store the new circle in the ref
    }
  }, [mapCenter, radiusV]);

  useEffect(() => {
    if (props?.lat !== undefined && props.lng !== undefined) {
      const pos = {
        lat: props?.lat,
        lng: props?.lng,
      };
      setMapCenter(pos);
      if (mapRef.current) {
        mapRef.current.panTo(pos);
        fetchAddress(pos.lat, pos.lng);
      }
    } else if (
      navigator.geolocation &&
      (!props.selectedZone?.address?.lat || !props.selectedZone?.address?.lng)
    ) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setMapCenter(pos);
          if (mapRef.current) {
            mapRef.current.panTo(pos);
            fetchAddress(pos.lat, pos.lng);
          }
        },
        () => {
          alert("Geolocation service failed. Using default location.");
        }
      );
    } else {
      const pos = {
        lat: props.selectedZone?.address?.lat || 0,
        lng: props.selectedZone?.address?.lng || 0,
      };
      setMapCenter(pos);
      if (mapRef.current) {
        mapRef.current.panTo(pos);
        fetchAddress(pos.lat, pos.lng);
      }
    }
  }, [props.lat, props.lng, props.selectedZone]);

  const onMapMoved = () => {
    if (mapRef.current) {
      const newCenter = mapRef.current.getCenter();
      setMapCenter({ lat: newCenter.lat(), lng: newCenter.lng() });
      fetchAddress(newCenter.lat(), newCenter.lng());
    }
  };

  const onMarkerDragEnd = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setMapCenter({ lat, lng });
    fetchAddress(lat, lng);
  };

  useEffect(() => {
    setRadiusV(radius);
  }, [radius]);

  const addTrafficControl = (map) => {
    // Create a div to hold the control.
    const controlDiv = document.createElement("div");

    // Set the styles for the control to match default Google controls
    controlDiv.style.backgroundColor = "#fff";
    controlDiv.style.color = localStorage.getItem("showTraffic") === "true" ? "rgb(25,25,25)" : "rgb(86, 86, 86)";
    controlDiv.style.borderRadius = "3px";
    controlDiv.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
    controlDiv.style.cursor = "pointer";
    controlDiv.style.marginTop = "10px";
    controlDiv.style.marginBottom = "22px";
    controlDiv.style.textAlign = "center";
    controlDiv.style.height = "40px";
    controlDiv.style.marginLeft = "-12px";

    const controlText = document.createElement("div");
    // controlText.style.color = "rgb(25,25,25)";
    controlText.style.fontFamily = "poppins";
    controlText.style.fontSize = "15px";
    controlText.style.lineHeight = "38px";
    controlText.style.fontWeight = localStorage.getItem("showTraffic") === "true" ? "600" : "500";
    controlText.style.paddingLeft = "5px";
    controlText.style.paddingRight = "5px";
    controlText.innerHTML = "Traffic Mode";
    controlDiv.appendChild(controlText);

    controlDiv.addEventListener('mouseover', () => {
      controlDiv.style.backgroundColor = "#f0f0f0";
      controlDiv.style.color = "rgb(25,25,25)";
    });

    controlDiv.addEventListener('mouseout', () => {
      controlDiv.style.backgroundColor = "#fff";
      controlDiv.style.color = "rgb(86, 86, 86)";
    });

    // Append the control to the same container as the default map controls
    map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(controlDiv);

    // Set up the click event listener for the control.
    controlDiv.addEventListener("click", () => {
      setShowTraffic((prevShowTraffic) => {
        const newShowTraffic = !prevShowTraffic;
        if (newShowTraffic) {
          controlDiv.style.color = "rgb(25,25,25)";
          controlText.style.fontWeight = "600"
          trafficLayerRef.current.setMap(map); // Show traffic layer
        } else {
          controlDiv.style.color = "rgb(86, 86, 86)";
          controlText.style.fontWeight = "500"
          trafficLayerRef.current.setMap(null); // Remove traffic layer
        }
        localStorage.setItem("showTraffic", newShowTraffic);
        return newShowTraffic;
      });
    });
  };


  useEffect(() => {
    if (mapRef.current && trafficLayerRef.current) {
      if (showTraffic) {
        trafficLayerRef.current.setMap(mapRef.current); // Show traffic layer if true
      } else {
        trafficLayerRef.current.setMap(null); // Remove traffic layer if false
      }
    }
  }, [showTraffic]);


  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
        }}
      >
        <div
          style={{ display: "flex", justifyContent: "space-between", flex: 1 }}
        >
          <div style={{ height: "480px", width: "100%", flex: 1 }}>
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={mapCenter}
              zoom={10}
              onDragEnd={props?.mapView ? null : onMapMoved}
              options={{
                minZoom: 1,
                mapTypeControl: true,
                mapTypeId: mapTypeId,
                showTraffic: true,
                styles: mapStyles
              }}
              onLoad={(map) => {
                mapRef.current = map;

                trafficLayerRef.current = new window.google.maps.TrafficLayer();

                if (showTraffic) {
                  trafficLayerRef.current.setMap(map); // Show traffic layer
                }

                addTrafficControl(map);

                window.google.maps.event.addListener(map, "maptypeid_changed", () => {
                  const newMapTypeId = map.getMapTypeId();
                  setMapTypeId(newMapTypeId);
                  localStorage.setItem("mapTypeId", newMapTypeId);
                });
              }}
            >
              <Marker
                position={mapCenter}
                draggable={true}
                onDragEnd={onMarkerDragEnd}
                icon={{
                  url: process.env.REACT_APP_ENV === 'women_first' ? Location_women_first : car,
                  scaledSize: new window.google.maps.Size(40, 40),
                }}
              />
            </GoogleMap>
          </div>
        </div>
      </div >
    </>
  );
};

export default SelectAdd;
