import { storageSdk } from "../../configs/firebase";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
    Box,
    Button,
    DialogActions,
    Grid,
    IconButton,
    InputAdornment,
    Paper,
    Typography,
    Snackbar,
    Alert
} from "@mui/material";
import { Modal } from "antd";
import { userData } from "../../redux/features/appStateSlice";
import DriveFileRenameOutlineSharpIcon from "@mui/icons-material/DriveFileRenameOutlineSharp";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { TextInput } from "@zetaton-dev/zetaton-components-bank";
import { Avatar } from "antd";
import React, { useState, useEffect } from "react";
import Loading from "react-fullscreen-loading";
import { useSelector, useDispatch } from "react-redux";
import colorConfigs from "../../configs/colorConfigs";
import { NewFormatPhoneNumber } from "../../utils/localization/NewPhoneUtils";
import { putMethod } from "../Hook/UseCustomApi";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import * as Yup from "yup";
import { emailRegex } from "../../configs/validation";

const Profile = (data) => {
    const [loading, setLoading] = useState("");
    const [url, setUrl] = useState("");
    const [inputKey, setInputKey] = useState("");
    // const countryCode = useSelector((state) => state.country.selectedCountry);
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [errors, setErrors] = useState({});
    const [numErrors, setNumErrors] = useState({});
    const [errorsPass, setErrorsPass] = useState({});
    const [view, setView] = useState({});
    const [viewPass, setViewPass] = useState({});
    const dispatch = useDispatch();
    const [selectedObject, setSelectedObject] = useState(data.data);
    const [open, setOpen] = useState(false);
    const [openPass, setOpenPass] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isPassModalVisible, setIsPassModalVisible] = useState(false);
    const [country, setCountry] = useState(process.env.REACT_APP_ENV === 'women_first' ? 'ca' : 'us');


    const [updatedData, setUpdatedData] = useState({
        name: selectedObject.name,
        email: selectedObject.email,
        phone_number: selectedObject.phone_number,
    });

    const [password, setPassword] = useState({
        current: "",
        new: "",
        confirm: "",
    });

    console.log(selectedObject);



    const handleCancelSave = (event, reason) => {
        setIsModalVisible(false);
        setIsPassModalVisible(false);
    };

    const showSnackbar = (message) => {
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
        setOpenPass(false);
    };

    const showModal = () => {
        const newErrors = {};

        if (updatedData?.name === "") {
            newErrors.name = "Name is required.";
        } else if (validateName(updatedData?.name) === "Use only letters.") {
            newErrors.name = "Only letters and a maximum of two spaces are allowed.";
        }
        if (!updatedData?.email) {
            newErrors.email = "Email is required.";
        } else if (!emailRegex?.test(updatedData?.email)) {
            newErrors.email = "Invalid email address.";
        }
        if (updatedData?.phone_number === "") {
            numErrors.phone_number = "Phone Number is required.";
        }

        if (country === "EG" && updatedData?.phone_number?.length < 17) {
            numErrors.phone_number = "phone_number must be a valid phone number for region EG .";
        }

        setErrors(newErrors);
        console.log(Object.keys(numErrors).length);
        console.log(newErrors);


        if (Object.keys(newErrors).length > 0 || (Object.keys(numErrors).length > 0)) {
            return;
        } else {
            setIsModalVisible(true);

        }
    };

    const showPassModal = () => {
        const newErrors = {};
        const specialCharCount = (password.new.match(/[!@#$%^&*(),.?":{}|<>]/g) || []).length;

        if (!password.current) {
            newErrors.current = "Please enter your current password.";
        }
        if (!password.new) {
            newErrors.new = "Please enter your new password.";
        } else if (password.new.length < 6) {
            newErrors.new = "Password should be at least 6 characters.";
        } else if (password.new.length > 15) {
            newErrors.new = "Password should not be more than 15 characters .";
        } else if (!/[A-Z]/.test(password.new)) {
            newErrors.new = "Password should contain at least one uppercase letter.";
        } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(password.new)) {
            newErrors.new = "Password should contain at least one special character.";
        } else if (specialCharCount > 4) {
            newErrors.new = 'Password should not contain more than 4 special characters.';
        }
        if (!password.confirm) {
            newErrors.confirm = "Please confirm your new password.";
        } else if (password.new !== password.confirm) {
            newErrors.confirm = "Passwords do not match.";
        }

        setErrorsPass(newErrors);

        if (Object.keys(newErrors).length > 0) {
            return;
        } else {

            setIsPassModalVisible(true);
        }


    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const handleClickShowNewPassword = () => {
        setShowNewPassword(!showNewPassword);
    };
    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handleChange = (name) => (event) => {
        setErrors({});
        let value = event.target.value;
        setUpdatedData({ ...updatedData, [name]: value });
    };
    const handleChangePassword = (name) => (event) => {
        setErrorsPass({});
        setPassword({ ...password, [name]: event.target.value });
    };
    const validateName = (value) => {
        const regex = /^[a-zA-Z]+(?: ?[a-zA-Z]+)* ?$/;
        return regex.test(value) ? undefined : "Use only letters.";
    };




    const handleSave = async () => {
        setIsModalVisible(false);
        setLoading(true);

        putMethod({
            url: `admin/${selectedObject?._id}`,
            body: updatedData,
            handleErrorMessage: showSnackbar,
        })
            .then((response) => {
                setSelectedObject(response.data);
                dispatch(userData(response.data));
            })
            .catch((error) => {
                console.error("Update API error:", error);
            })
            .finally(() => {
                setLoading(false);
                setOpen(true);
                setView(true);
            });
    };

    const handleCancel = () => {
        setNumErrors({})
        setErrors({});
        setUpdatedData({
            name: data.data.name,
            email: data.data.email,
            phone_number: data.data.phone_number,
        });
        setView(true)
    };



    const handleSavePassword = async () => {

        setIsPassModalVisible(false);
        try {
            setLoading(true);

            const user = firebase.auth().currentUser;

            const credential = firebase.auth.EmailAuthProvider.credential(
                user.email,
                password.current
            );

            await user.reauthenticateWithCredential(credential);

            await user.updatePassword(password.new);
            setSnackbarMessage("Password successfully updated.");
            setOpenPass(true);

            // Clear password fields after update
            setPassword({ current: "", new: "", confirm: "" });
            setViewPass(true);
            setIsPassModalVisible(false)
        } catch (error) {
            console.error("Password update error:", error);
            setIsPassModalVisible(false);
            switch (error.code) {
                case "auth/wrong-password":
                    setErrorsPass({ current: "Current password is incorrect." });
                    break;
                case "auth/weak-password":
                    setErrorsPass({ new: "Password should be at least 6 characters." });
                    break;
                case "auth/invalid-login-credentials":
                    setErrorsPass({ current: "Current password is incorrect." });
                    break;
                default:
                    setSnackbarMessage("Failed to update password.");
                    setSnackbarOpen(true);
                    break;
            }
        } finally {
            setLoading(false);
        }
    };

    const handleImagrUrl = async (e) => {
        setLoading(true);
        let file = e.target?.files[0];
        if ((file && file.length !== 0) || (file && file !== "" && typeof file !== "string")) {
            setLoading(true);
            const uploadTask = storageSdk.ref(`/admin/${file.name}`).put(file);
            uploadTask.on(
                "state_changed",
                (snapShot) => {
                    //takes a snap shot of the process as it is happening
                },
                (err) => {
                    setLoading(false); // Set loading to false in case of an error
                },
                async () => {
                    try {
                        const fireBaseUrl = await storageSdk
                            .ref("admin")
                            .child(file.name)
                            .getDownloadURL();

                        const dataToUpdate = {
                            profile_picture: fireBaseUrl,
                        };

                        try {
                            setLoading(true);
                            putMethod({
                                url: `admin/${selectedObject?._id}`,
                                body: dataToUpdate,
                                handleErrorMessage: showSnackbar,
                            })
                                .then((response) => {
                                    dispatch(userData(response.data));
                                })
                        } catch (error) {
                            console.error("Update API error:", error.message);
                        } finally {
                            setLoading(false);
                        }
                        setUrl(fireBaseUrl);
                    } catch (error) {
                        console.error("Download URL error:", error.message);
                        setLoading(false);
                    } finally {
                        setLoading(false);
                    }
                }
            );
        } else {
            setUrl(file);
            setLoading(false);
        }
        setInputKey(Math.random());
    };

    const fieldStyle = {
        "& label.Mui-focused": {
            color: "#172B4D !important",
            fontSize: "17px !important",
            fontWeight: "500 !important",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#BBC1CE",
                borderWidth: 1,
                borderRadius: "8px",
            },
            "&:hover fieldset": {
                borderColor: "#BBC1CE",
                borderWidth: 1,
                borderRadius: "8px",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#BBC1CE",
                borderWidth: 1,
                borderRadius: "8px",
            },
            input: {
                "&::placeholder": {
                    color: "#3A3541DE",
                    opacity: 1,
                    fontSize: 14,
                },
            },
        },
    };

    const validationSchema = Yup.object().shape({
        phone_number: Yup.string().phone(country, true, "Phone number is invalid").required("Phone number is required"),
    });

    const handlePhoneBlur = (event) => {
        const value = event.target.value;
        handlePhoneChange(value);
    };

    const handlePhoneChange = (value, countryData) => {
        if (countryData && countryData.countryCode) {
            const updatedCountry = countryData.countryCode.toUpperCase();
            setCountry(updatedCountry);
        }
        setUpdatedData({ ...updatedData, phone_number: value });


    };


    const [isInitialized, setIsInitialized] = useState(false);
    useEffect(() => {
        setIsInitialized(true);
    }, []);

    useEffect(() => {
        if (isInitialized && updatedData.phone_number) {
            setNumErrors({});
            validationSchema.validateAt('phone_number', { phone_number: updatedData.phone_number, country })
                .then(() => {
                    setNumErrors({});
                })
                .catch((err) => {
                    setNumErrors((prevErrors) => ({ ...prevErrors, phone_number: err.message }));
                });
        }
    }, [updatedData.phone_number, country]);

    return (
        <>
            <Box sx={{
                backgroundColor: "white",
                padding: "30px",
                borderBottomLeftRadius: "22px",
                borderBottomRightRadius: "22px"
            }}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                    <Box style={{ marginBottom: "15px" }}>
                        <div style={{ position: "relative", width: "135px", height: "135px" }}>
                            {selectedObject?.profile_picture || url ? (
                                <img
                                    src={url === "" ? selectedObject?.profile_picture : url}
                                    alt="Profile"
                                    loading="lazy"
                                    style={{
                                        width: "135px",
                                        height: "135px",
                                        border: "2px solid #EEEEEE7A",
                                        borderRadius: "105px"
                                    }}
                                />
                            ) : (
                                <Paper style={{
                                    width: "135px",
                                    height: "135px",
                                    border: "1px solid #EEEEEE7A",
                                    borderRadius: "105px"
                                }}>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            textAlign: "center",
                                            height: "100%",
                                        }}
                                    >
                                        {" "}
                                        <Avatar
                                            style={{ width: "100%", height: '100%' }}
                                            icon={<AccountCircleIcon style={{ width: "100%", height: '100%', color: "primary" }} />}
                                        />
                                    </div>{" "}
                                </Paper>
                            )}
                            {!view && (
                                <div
                                    style={{
                                        position: "absolute",
                                        bottom: 13,
                                        right: 5,
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-end",
                                    }}
                                >
                                    <form onSubmit={() => { }} className="form">
                                        <IconButton
                                            component="label"
                                            style={{
                                                borderRadius: "10%",
                                                width: "26px",
                                                height: "26px",
                                            }}
                                        >
                                            <AddAPhotoIcon style={{ color: colorConfigs.sidebar.bg }} />
                                            <input
                                                type="file"
                                                hidden
                                                style={{ display: "none" }}
                                                multiple
                                                size="20971520"
                                                accept="image/png, image/jpeg"
                                                key={inputKey}
                                                onChange={(e) => handleImagrUrl(e)}
                                                onClick={(e) => (e.target.value = null)}
                                            />
                                        </IconButton>
                                    </form>
                                </div>
                            )}

                        </div>
                    </Box>
                    <Box
                        display="flex"
                        style={{ cursor: "pointer", marginBottom: "120px" }}
                    >
                        <Button
                            variant="outlined"
                            onClick={() => {
                                setView(false);
                                setNumErrors({});
                            }}
                            style={{
                                borderTopLeftRadius: "88px",
                                borderBottomLeftRadius: "88px",
                                borderColor: colorConfigs?.sidebar?.bg,
                                color: !view ? "white" : "#42526E",
                                backgroundColor: !view ? colorConfigs?.sidebar?.bg : "white",
                                cursor: "pointer",
                            }}
                        >
                            <DriveFileRenameOutlineSharpIcon fontSize="small" /> Edit{" "}
                        </Button>

                        <Button
                            variant="outlined"
                            onClick={() => {
                                handleCancel();
                                setView(true);
                            }}
                            style={{
                                borderTopRightRadius: "88px",
                                borderBottomRightRadius: "88px",
                                borderRight: "1px solid black",
                                borderColor: colorConfigs?.sidebar?.bg,
                                color: view ? "white" : colorConfigs?.sidebar?.bg,
                                backgroundColor: view ? colorConfigs?.sidebar?.bg : "white",
                                cursor: "pointer",
                            }}
                        >
                            {" "}
                            <VisibilityOutlinedIcon fontSize="small" /> View
                        </Button>
                    </Box>
                    <Loading
                        loading={!!loading}
                        background="rgba(86, 100, 210, 0.1)"
                        loaderColor={colorConfigs.sidebar?.bg}
                    />
                </div>
                <Box style={{
                    // height: '215px',
                }}>
                    <Grid container spacing={3} style={{ padding: "0px" }} >
                        <Grid item md={6} xs={12}>
                            <TextInput
                                fullWidth
                                id="name-input"
                                styles={fieldStyle}
                                label={"Name"}
                                name="name"
                                disabled={view}
                                onChange={handleChange("name")}
                                value={updatedData?.name}
                                variant="outlined"
                                placeholder="Please enter your name"
                                filled="true"
                                focused
                                error={Boolean(errors.name)}
                            />
                            {errors.name && (
                                <Typography variant="caption" style={{ color: "red" }}>
                                    {errors.name}
                                </Typography>
                            )}
                        </Grid>

                        <Grid item md={6} xs={12}>
                            <TextInput
                                fullWidth
                                id="email-input"
                                label={"Email"}
                                styles={fieldStyle}
                                name="email"
                                disabled={view}
                                onChange={handleChange("email")}
                                value={updatedData?.email}
                                variant="outlined"
                                placeholder="Please enter your email"
                                filled="true"
                                focused
                                error={Boolean(errors.email)}
                            />
                            {errors.email && (
                                <Typography variant="caption" style={{ color: "red" }}>
                                    {errors.email}
                                </Typography>
                            )}
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <PhoneInput
                                name="phone"
                                autoComplete="off"
                                id="phone-number-input"
                                autoFormat={true}
                                enableSearch={true}
                                country={process.env.REACT_APP_ENV === 'women_first' ? 'ca' : 'us'}
                                value={updatedData?.phone_number}
                                onChange={handlePhoneChange}
                                onBlur={handlePhoneBlur}
                                inputStyle={{
                                    width: '100%',
                                    borderColor: '#BBC1CE',
                                    borderRadius: '8px',
                                    height: '56px',
                                }}
                                buttonStyle={{
                                    borderColor: '#BBC1CE',
                                    borderRadius: '8px 0 0 8px',
                                }}
                                containerStyle={{
                                    width: '100%',
                                }}
                                disabled={view}
                                inputProps={{
                                    name: 'phone_number',
                                    required: true,
                                }}
                                placeholder="Please enter your phone number"
                            />
                            {numErrors?.phone_number && !view && (
                                <Typography variant="caption" style={{ color: "red" }}>
                                    {numErrors.phone_number}
                                </Typography>
                            )}
                        </Grid>
                    </Grid>

                    {!view && (
                        <DialogActions
                            style={{
                                display: "flex",
                                justifyContent: "space-evenly",
                                alignItems: "flex-start",
                                width: "30%",
                                padding: "0",
                                gap: "16px",
                                marginTop: "20px"
                            }}
                        >
                            <Button
                                variant="contained"
                                sx={{
                                    textTransform: 'none',
                                    backgroundColor: 'white',
                                    color: colorConfigs?.sidebar?.bg,
                                    borderRadius: '10px',
                                    width: "100%",
                                    padding: '10px 20px',
                                    '&:hover': {
                                        backgroundColor: '#E6E9F2',
                                    },
                                }}
                                onClick={handleCancel}
                            >
                                Cancel
                            </Button>

                            <Button
                                variant="contained"
                                sx={{
                                    textTransform: 'none',
                                    backgroundColor: process.env.REACT_APP_ENV === 'women_first' ? colorConfigs?.sidebar?.bg : '#005e8b',
                                    color: 'white',
                                    borderRadius: '10px',
                                    width: "100%",
                                    padding: '10px 20px',
                                    '&:hover': {
                                        backgroundColor: process.env.REACT_APP_ENV === 'women_first' ? colorConfigs?.sidebar?.bg : '#004b6e',
                                    },
                                }}
                                onClick={showModal}
                            >
                                Save
                            </Button>
                        </DialogActions>
                    )}
                </Box>
            </Box>

            <Box sx={{
                backgroundColor: "white",
                padding: "30px",
                borderRadius: "22px",
                mt: 4
            }}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginBottom: "30px" }}>
                    <span style={{
                        fontSize: "16px",
                        fontWeight: "700",
                        color: "#1A1A1A",
                        lineHeight: "24px",
                        marginLeft: "16px"
                    }}>
                        Change Password
                    </span>
                    <Box
                        display="flex"
                        style={{ cursor: "pointer" }}
                    >
                        <Button
                            variant="outlined"
                            onClick={() => {
                                setViewPass(false);
                            }}
                            style={{
                                borderTopLeftRadius: "88px",
                                borderBottomLeftRadius: "88px",
                                borderColor: colorConfigs?.sidebar?.bg,
                                color: !viewPass ? "white" : "#42526E",
                                backgroundColor: !viewPass ? colorConfigs?.sidebar?.bg : "white",
                                cursor: "pointer",
                            }}
                        >
                            <DriveFileRenameOutlineSharpIcon fontSize="small" /> Edit{" "}
                        </Button>

                        <Button
                            variant="outlined"
                            onClick={() => {
                                setErrorsPass({});
                                setViewPass(true);
                                setPassword({
                                    current: "",
                                    new: "",
                                    confirm: "",
                                })
                            }}
                            style={{
                                borderTopRightRadius: "88px",
                                borderBottomRightRadius: "88px",
                                borderRight: "1px solid black",
                                borderColor: colorConfigs?.sidebar?.bg,
                                color: viewPass ? "white" : colorConfigs?.sidebar?.bg,
                                backgroundColor: viewPass ? colorConfigs?.sidebar?.bg : "white",
                                cursor: "pointer",
                            }}
                        >
                            {" "}
                            <VisibilityOutlinedIcon fontSize="small" /> View
                        </Button>
                    </Box>
                </div>
                <Box>
                    <Grid container spacing={3}>
                        <Grid item md={12} xs={12}>
                            <form autoComplete="off">
                                <TextInput
                                    fullWidth
                                    autoComplete="new-password"
                                    id="current-password"
                                    styles={fieldStyle}
                                    label={"Current Password"}
                                    name="new-password"
                                    disabled={viewPass}
                                    onChange={handleChangePassword("current")}
                                    value={password?.current}
                                    variant="outlined"
                                    placeholder="Please enter your current password"
                                    type={showPassword ? "text" : "password"}
                                    InputLabelProps={{
                                        style: { color: "black" },
                                    }}
                                    InputProps={{
                                        style: {
                                            color: "black",
                                            userSelect: "none", // Prevent text selection
                                        },
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    disabled={viewPass}
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                        sx: {
                                            "&.Mui-focused fieldset": {
                                                borderColor: "white", // Focused border color
                                            },
                                            borderRadius: "8px",
                                            border: "1px solid white",
                                            borderColor: "white", // Border color
                                            "input[type='password']": {
                                                userSelect: "none", // Prevent text selection
                                                MozUserSelect: "none",
                                                msUserSelect: "none",
                                                WebkitUserSelect: "none",
                                            },
                                        },
                                    }}

                                    error={Boolean(errorsPass.current)}
                                />
                            </form>

                            {errorsPass.current && (
                                <Typography variant="caption" style={{ color: "red" }}>
                                    {errorsPass.current}
                                </Typography>
                            )}
                        </Grid>

                        <Grid item md={6} xs={12}>
                            <form autoComplete="off">
                                <TextInput
                                    fullWidth
                                    id="new-password"
                                    styles={fieldStyle}
                                    label={"New Password"}
                                    name="new-password"
                                    disabled={viewPass}
                                    value={password?.new}
                                    onChange={handleChangePassword("new")}
                                    variant="outlined"
                                    placeholder="Please enter your new password"
                                    type={showNewPassword ? "text" : "password"}
                                    InputLabelProps={{
                                        style: { color: "black" },
                                    }}
                                    InputProps={{
                                        style: {
                                            color: "black",
                                            userSelect: "none", // Prevent text selection
                                        },
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    disabled={viewPass}
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowNewPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                        sx: {
                                            "&.Mui-focused fieldset": {
                                                borderColor: "white", // Focused border color
                                            },
                                            borderRadius: "8px",
                                            border: "1px solid white",
                                            borderColor: "white", // Border color
                                            "input[type='password']": {
                                                userSelect: "none", // Prevent text selection
                                                MozUserSelect: "none",
                                                msUserSelect: "none",
                                                WebkitUserSelect: "none",
                                            },
                                        },
                                    }}
                                    error={Boolean(errorsPass.new)}
                                />
                            </form>

                            {errorsPass.new && (
                                <Typography variant="caption" style={{ color: "red" }}>
                                    {errorsPass.new}
                                </Typography>
                            )}
                        </Grid>

                        <Grid item md={6} xs={12}>
                            <form autoComplete="off">
                                <TextInput
                                    fullWidth
                                    id="confirm-password"
                                    styles={fieldStyle}
                                    label={"Confirm Password"}
                                    name="confirm-password"
                                    value={password?.confirm}
                                    disabled={viewPass}
                                    onChange={handleChangePassword("confirm")}
                                    variant="outlined"
                                    placeholder="Please confirm your new password"
                                    type={showConfirmPassword ? "text" : "password"}
                                    InputLabelProps={{
                                        style: { color: "black" },
                                    }}
                                    InputProps={{
                                        style: {
                                            color: "black",
                                            userSelect: "none", // Prevent text selection
                                        },
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    disabled={viewPass}
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowConfirmPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                        sx: {
                                            "&.Mui-focused fieldset": {
                                                borderColor: "white", // Focused border color
                                            },
                                            borderRadius: "8px",
                                            border: "1px solid white",
                                            borderColor: "white", // Border color
                                            "input[type='password']": {
                                                userSelect: "none", // Prevent text selection
                                                MozUserSelect: "none",
                                                msUserSelect: "none",
                                                WebkitUserSelect: "none",
                                            },
                                        },
                                    }}
                                    error={Boolean(errorsPass.confirm)}
                                />
                            </form>

                            {errorsPass.confirm && (
                                <Typography variant="caption" style={{ color: "red" }}>
                                    {errorsPass.confirm}
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                    <div style={{ height: "10px" }} />
                    {!viewPass && (
                        <DialogActions
                            style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                                width: "25%",
                                padding: "0",
                                marginTop: "15px"
                            }}
                        >
                            <Button
                                variant="contained"
                                sx={{
                                    textTransform: 'none',
                                    backgroundColor: process.env.REACT_APP_ENV === 'women_first' ? colorConfigs?.sidebar?.bg : '#005e8b',
                                    color: 'white',
                                    borderRadius: '10px',
                                    width: "100%",
                                    padding: '10px 20px',
                                    '&:hover': {
                                        backgroundColor: process.env.REACT_APP_ENV === 'women_first' ? colorConfigs?.sidebar?.bg : '#004b6e',
                                    },
                                }}
                                onClick={showPassModal}
                            >
                                Change Password
                            </Button>
                        </DialogActions>
                    )}
                </Box>
            </Box>

            <Snackbar
                open={open}
                autoHideDuration={4000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <Alert
                    onClose={handleClose}
                    severity="success"
                    variant="filled"
                    sx={{ width: "100%" }}
                >
                    The data is saved successfully!
                </Alert>
            </Snackbar>
            <Snackbar
                open={openPass}
                autoHideDuration={4000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <Alert
                    onClose={handleClose}
                    severity="success"
                    variant="filled"
                    sx={{ width: "100%" }}
                >
                    The password changed successfully!
                </Alert>
            </Snackbar>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={4000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <Alert onClose={handleSnackbarClose} severity="error">
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            <Modal
                title="Confirm Changes"
                visible={isModalVisible}
                onOk={handleSave}
                onCancel={handleCancelSave}
                okText="Yes"
                okButtonProps={{
                    style: {
                        backgroundColor: colorConfigs?.sidebar?.bg,
                        color: "white",
                    },
                }}
            >
                <p>Are you sure you want to save the changes?</p>
            </Modal>
            <Modal
                title="Confirm Changes"
                visible={isPassModalVisible}
                onOk={handleSavePassword}
                onCancel={handleCancelSave}
                okText="Yes"
                okButtonProps={{
                    style: {
                        backgroundColor: colorConfigs?.sidebar?.bg,
                        color: "white",
                    },
                }}
            >
                <p>Are you sure you want to change your password?</p>
            </Modal>
        </>
    );
};

export default Profile;
