import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Use the storage you prefer (local storage, session storage, etc.)
import appStateSlice from "./features/appStateSlice";
import countryReducer from "./features/countrySlice";
import modalReducer from './features/modalSlice';
import licAndConfigSlice from './features/licAndConfigSlice';

const persistConfig = {
  key: "root", // The key to use in local storage
  storage, // Use the storage you prefer (local storage, session storage, etc.)
};

const persistedReducer = persistReducer(persistConfig, appStateSlice);

export const store = configureStore({
  reducer: {
    appState: persistedReducer,
    country: countryReducer,
    dynamicModal: modalReducer,
    licencesAndConfiguration: licAndConfigSlice,
    // Use the persisted reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store); // Create a persistor for the store
