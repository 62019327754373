import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

const BadgeWrapper = styled('div')(({ theme, status }) => {
    let backgroundColor;
    let color;

    switch (status) {
        case 'unpaid':
            backgroundColor = '#ffe0e0';
            color = '#d32f2f';
            break;
        case 'inprogress':
            backgroundColor = '#ffecb3';
            color = '#f57c00';
            break;
        case 'paid':
            backgroundColor = '#e8f5e9';
            color = '#388e3c';
            break;
        default:
            backgroundColor = '#e0e0e0';
            color = '#757575';
            break;
    }

    return {
        display: 'inline-block',
        padding: '8px 16px',
        borderRadius: '24px',
        backgroundColor: backgroundColor,
        fontSize: '12px',
        color: color,
        fontWeight: '600',
        textAlign: 'center',
        minWidth: '80px',
        width:'100%',
        fontFamily: 'poppins',
        textTransform:'capitalize'
    };
});

const StatusBadge = ({ status }) => {
    return (
        <BadgeWrapper status={status}>
            {status}
        </BadgeWrapper>
    );
};

StatusBadge.propTypes = {
    status: PropTypes.string.isRequired,
};

export default StatusBadge;
