


import { useTheme } from "@emotion/react";
import {
    Box,
    Card,
    CardContent,
    Grid,
    Typography
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { BsPeople } from "react-icons/bs";
import { LuCalendar } from "react-icons/lu";
import BIgMoneyBag from "../../../assets/svg/BIgMoneyBag";
import colorConfigs from "../../../configs/colorConfigs";
import { auth } from "../../../configs/firebase";
import { getMethod, postMethod } from "../../Hook/UseCustomApi";
import { useSelector } from "react-redux";
import { formatDate } from "../../../utils/localization/dateTimeUtils";


export default function PayRollDetails() {
    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const [isAddOpen, setIsAddOpen] = useState(false);
    const [role, setRole] = useState("");
    const [formErrors, setErrors] = useState({});
    const [error, setError] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("");
    const selectedPayout = useSelector((state) => state.appState.selectedPayout);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const countryCode = useSelector((state) => state.country.selectedCountry);

    const fieldStyle = {
        "& label.Mui-focused": {
            color: "#172B4D !important",
            fontSize: "17px !important",
            fontWeight: "500 !important",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#BBC1CE",
                borderWidth: 1,
                borderRadius: "8px",
            },
            "&:hover fieldset": {
                borderColor: "#BBC1CE",
                borderWidth: 1,
                borderRadius: "8px",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#BBC1CE",
                borderWidth: 1,
                borderRadius: "8px",
            },
            input: {
                "&::placeholder": {
                    color: "#3A3541DE",
                    opacity: 1, // otherwise firefox shows a lighter color
                    fontSize: 14,
                },
            },
        },
    };

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarErrorMessage, setSnackbarErrorMessage] = useState("");
    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const showSnackbar = (message) => {
        setSnackbarErrorMessage(message);
        setSnackbarOpen(true);
    };



    let currentPage = useRef();
    useEffect(() => {
        currentPage.current = 1;
        fetchDrivers();
    }, []);


    const getParams = () => {
        return {
            page: currentPage.current,
            projectId:
                process.env.REACT_APP_ENV === "production" ? "ridewyze" : "roadrunner",
        };
    };

    const fetchDrivers = async () => {

        getMethod({
            url: "admin",
            params: getParams(),
            handleErrorMessage: showSnackbar,
        })
            .then((response) => {

            })
            .catch((err) => console.error("Error fetching more stores:", err))
            .finally(() => setLoading(false));
    };

    const handleChangeRole = (event) => {
        setRole(event.target.value);
        console.log(event);
    };
    const projectId =
        process.env.REACT_APP_ENV === "production" ? "ridewyze" : "roadrunner";
    const handleSubmit = async (values) => {
        setErrors({});
        let error = {};
        if (!values.name) {
            error.name = "Name is required.";
        } else if (values.name.length > 15) {
            error.name = "Name should not exceed 15 characters.";
        }

        // Validate Email
        if (!values.email) {
            error.email = "Email is required.";
        } else if (!emailRegex.test(values.email)) {
            error.email = "Invalid email address.";
        }

        // Validate Role
        if (role === "") {
            error.role = "Role is required.";
        }

        if (Object.keys(error).length > 0) {
            setErrors(error);
        } else if (auth && auth.currentUser) {
            const tokenId = await auth?.currentUser
                ?.getIdToken()
                .then((token) => token);
            const headers = {
                "Content-Type": "application/json",
                Authorization: tokenId,
            };

            setLoading(true);
            try {
                // Make the API request to add a new admin
                postMethod({
                    url: `admin?projectId=${projectId}`,
                    body: {
                        name: values.name,
                        profile_picture: "",
                        email: values.email,
                        phone_number: "",
                        role: role,
                    },
                    headers: headers,
                    handleErrorMessage: showSnackbar,
                }).then((res) => {

                    setLoading(false);
                    setIsAddOpen(false);
                    fetchDrivers();
                    setSnackbarMessage("Successfully adding new admin account!");
                    setSnackbarSeverity("success");
                    setOpenSnackbar(true);
                });
                // Fetch the updated list of admins
            } catch (error) {
                console.error("Error adding new admin:", error);
                setError(
                    error?.response?.data?.code === "admin_Exist"
                        ? "Admin already exist"
                        : "Error adding new admin"
                );
                setLoading(false);
            }
        }
    };

    const originalHandleSubmit = async (values) => {
        setErrors({});
        let error = {};
        if (!values.name) {
            error.name = "Name is required.";
        } else if (values.name.length > 15) {
            error.name = "Name should not exceed 15 characters.";
        }

        // Validate Email
        if (!values.email) {
            error.email = "Email is required.";
        } else if (!emailRegex.test(values.email)) {
            error.email = "Invalid email address.";
        }

        // Validate Role
        if (role === "") {
            error.role = "Role is required.";
        }

        if (Object.keys(error).length > 0) {
            setErrors(error);
        } else if (auth && auth.currentUser) {
            const tokenId = await auth?.currentUser
                ?.getIdToken()
                .then((token) => token);
            const headers = {
                "Content-Type": "application/json",
                Authorization: tokenId,
            };

            setLoading(true);
            try {
                // Make the API request to add a new admin
                postMethod({
                    url: `admin?projectId=${projectId}`,
                    body: {
                        name: values.name,
                        profile_picture: "",
                        email: values.email,
                        phone_number: "",
                        role: role,
                    },
                    headers: headers,
                    handleErrorMessage: showSnackbar,
                }).then((res) => {

                    setLoading(false);
                    setIsAddOpen(false);
                    fetchDrivers();
                    setSnackbarMessage("Admin added successfully!");
                    setSnackbarSeverity("success");
                    setOpenSnackbar(true);
                });
                // Fetch the updated list of admins
            } catch (error) {
                console.error("Error adding new admin:", error);
                setError(
                    error?.response?.data?.code === "admin_Exist"
                        ? "Admin already exist"
                        : "Error adding new admin"
                );
                setLoading(false);
            }
        }
    };

    const initialValues = {
        name: "",
        email: "",
    };

    return (
        <>
            <Grid
                item
                md={12}
                xs={12}
                style={{ marginTop: "-80px" }}>
                <Box sx={{ flexGrow: 1, padding: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <Card sx={{ display: 'flex', alignItems: 'start', justifyContent: 'space-between', px: 1, boxShadow: 'rgba(0, 0, 0, 0.09) 0px 3px 12px', borderRadius: 2, p: 2 }}>
                                <CardContent>
                                    <Typography sx={{ color: '#172B4D', fontWeight: 600, fontSize: 14 }} component="div">
                                        Total payroll cost
                                    </Typography>
                                    <Typography sx={{ color: colorConfigs.sidebar.bg, fontWeight: 700, fontSize: 24, mt: 0.5 }} component="div">
                                        ${selectedPayout?.amount}
                                    </Typography>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1, mt: 1.5 }}>
                                        <Typography sx={{ color: '#4F6071', fontWeight: 500, fontSize: 14, mt: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 0.5 }} component="div">
                                            <LuCalendar color={colorConfigs.sidebar.bg} /> Pay period:
                                        </Typography>
                                        <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center', gap: 0.8 }}>
                                            <Typography sx={{ color: "#2B3641", fontWeight: 600, fontSize: 14, mt: 1 }} component="div">
                                                {formatDate(selectedPayout?.payout_startAt, countryCode)}
                                            </Typography>
                                            <Typography sx={{ color: '#4F6071', fontWeight: 600, fontSize: 14, mt: 1 }} component="div">
                                                To
                                            </Typography>
                                            <Typography sx={{ color: "#2B3641", fontWeight: 600, fontSize: 14, mt: 1 }} component="div">
                                                {formatDate(selectedPayout?.payout_date, countryCode)}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center', gap: 1 }}>
                                        <Typography sx={{ color: '#4F6071', fontWeight: 500, fontSize: 14, mt: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 0.5 }} component="div">
                                            <LuCalendar color={colorConfigs.sidebar.bg} />   Pay date:
                                        </Typography>
                                        <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center', gap: 0.8 }}>
                                            <Typography sx={{ color: "#2B3641", fontWeight: 600, fontSize: 14, mt: 1 }} component="div">
                                                {formatDate(selectedPayout?.payout_date, countryCode)}
                                            </Typography>

                                        </Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center', gap: 1 }}>
                                        <Typography sx={{ color: '#4F6071', fontWeight: 500, fontSize: 14, mt: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 0.5 }} component="div">
                                            <BsPeople color={colorConfigs.sidebar.bg} size={16} />  Drivers Num:
                                        </Typography>
                                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 0.8 }}>
                                            <Typography sx={{ color: "#2B3641", fontWeight: 600, fontSize: 14, mt: 1 }} component="div">
                                                {selectedPayout?.number_driver}
                                            </Typography>
                                        </Box>
                                    </Box>

                                </CardContent>
                                <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', gap: 2, mt: 3 }}>
                                    <Box sx={{ background: '#F4F4F4', borderRadius: '24px', color: colorConfigs?.sidebar.bg, padding: '5px 20px', fontSize: 16, fontWeight: 700, textTransform: 'capitalize' }}>
                                        {selectedPayout?.frequency} Payout
                                    </Box>
                                    <BIgMoneyBag />
                                </Box>

                            </Card>
                        </Grid>
                    </Grid>
                </Box>

            </Grid>
        </>
    );
}

