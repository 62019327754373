import {
    Alert,
    Button,
    DialogActions,
    Grid,
    Snackbar,
    Typography, Box, DialogContent, Dialog, DialogContentText
} from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import {
    TextInput
} from "@zetaton-dev/zetaton-components-bank";
import React, { useEffect, useState } from "react";
import colorConfigs from "../../configs/colorConfigs";
import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    Elements,
    useElements,
    useStripe,
} from "@stripe/react-stripe-js";
import { CiEdit } from "react-icons/ci";
import deletee from "../../assets/images/delete.png";
import Loading from "react-fullscreen-loading";
import { useSelector } from "react-redux";
import { stripePromise } from './stripeConfig';
import { postMethod, DeleteMethod, getMethod } from "../Hook/UseCustomApi";
import amex from "../../assets/paymentMethods/Amex.png";
import discover from "../../assets/paymentMethods/diccover.png";
import dinars from "../../assets/paymentMethods/diners.png";
import geniric from "../../assets/paymentMethods/geniric.png";
import jbc from "../../assets/paymentMethods/jcb.png";
import mastercard from "../../assets/paymentMethods/mastercard.png";
import unionpay from "../../assets/paymentMethods/unionPay.png";
import visa from "../../assets/paymentMethods/visa.png";



const Payments = (getAllCards) => {
    const stripe = useStripe();
    const elements = useElements();
    const [deleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] = useState(false);
    const [errors, setErrors] = useState({});
    const user = useSelector((state) => state.appState.userData);
    const cards = useSelector((state) => state.appState.cardsData);
    const bankAccStatus = useSelector((state) => state.appState.bankAccStatus);
    const [openDialog, setOpenDialog] = useState(false);
    const [openSaveCard, setOpenSaveCard] = useState(false);
    const [openDeleteCard, setOpenDeleteCard] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [cardNumberError, setCardNumberError] = useState("");
    const [expiryError, setExpiryError] = useState("");
    const [cvcError, setCvcError] = useState("");
    const [cardName, setCardName] = useState("");
    const fetchCards = getAllCards.getAllCards.getAllCards;
    const [loading, setLoading] = useState("");
    const [idToDelete, setIdToDelete] = useState("");
    const licAndConfig = useSelector((state) => state.licencesAndConfiguration);


    const handleClose = () => {
        setOpenDialog(false);
        setCardName("")
        setLoading(false)
        setCardNumberError("")
        setExpiryError("")
        setCvcError("")
        setErrors({});
    };
    const handleCloseSaveCard = () => {
        setOpenSaveCard(false);
        setOpenDeleteCard(false)

    };

    const showSnackbar = (message) => {
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };
    const handleChange = (name) => (event) => {
        setErrors({});
        let value = event.target.value;
        setCardName(value);
    };

    const validateName = (value) => {
        const regex = /^[a-zA-Z]+(?: ?[a-zA-Z]+)* ?$/;
        return regex.test(value) ? undefined : "Use only letters.";
    };

    const handleSave = async () => {
        const newErrors = {};
        const cardNumberElement = elements.getElement(CardNumberElement);
        const cardExpiryElement = elements.getElement(CardExpiryElement);
        const cardCvcElement = elements.getElement(CardCvcElement);
        const cardNumberComplete = cardNumberElement._complete;
        const cardExpiryComplete = cardExpiryElement._complete;
        const cardCvcComplete = cardCvcElement._complete;

        if (!cardNumberComplete) {
            setCardNumberError("Card Number is required.");
        }

        if (!cardExpiryComplete) {
            setExpiryError("Card Expiration date is required.");
        }

        if (!cardCvcComplete) {
            setCvcError("Card CVC is required.");
        }

        if (cardName === "") {
            newErrors.name = "Name is required.";
        } else if (validateName(cardName) === "Use only letters.") {
            newErrors.name = "Only letters and a maximum of two spaces are allowed.";
        }
        setErrors(newErrors);

        if (Object.keys(newErrors).length > 0 || !cardNumberComplete || !cardExpiryComplete || !cardCvcComplete) {
        } else {
            if (!stripe || !elements) {
                return;
            } else {
                setOpenDialog(false)
                setLoading(true)

                const cardElement = elements.getElement(CardNumberElement);

                const result = await stripe.createToken(cardElement, {
                    name: cardName,
                });

                if (result.error) {
                    setErrors(result.error.message);
                    setLoading(false);
                    return;
                } else {

                    const body = {
                        uid: user._id,
                        token: result.token.id
                    }
                    postMethod({
                        url: `payment/admin/creditCard`,
                        body: body,
                        handleErrorMessage: showSnackbar,
                    })
                        .then((response) => {
                            fetchCards();
                            setOpenSaveCard(true);
                            handleClose();
                            setLoading(false);
                        })
                        .catch((err) => {
                            console.error("Error fetching more stores: name already used ", err);
                            setLoading(false);
                        });
                }
            }
        }
    };

    const handleDelete = (id) => {

        setIdToDelete(id);
        setDeleteConfirmationDialogOpen(true);

    };

    const handleDeleteConfirmation = async (confirmed) => {
        setDeleteConfirmationDialogOpen(false);
        if (confirmed) {
            setLoading(true);
            DeleteMethod({
                url: `payment/admin/creditCard/${idToDelete}`,
                handleErrorMessage: showSnackbar,
            })
                .then((res) => {
                    setLoading(false);
                    fetchCards();
                    setOpenDeleteCard(true)
                })
                .catch((error) => console.error("Error deleting admin:", error))
                .finally(() => setLoading(false));
        }

    };


    const handleAddBankAcc = async () => {
        setLoading(true);
        getMethod({
            url: "payment/admin/bankAccount/link",
            handleErrorMessage: showSnackbar,
        })
            .then((response) => {
                const link = response.data.url;
                window.open(link, '_blank');
                setLoading(false);
            })
            .catch((err) => {
                console.error("Error fetching bank account link:", err);
                setLoading(false);
            });
    };

    const inputStyle = {
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#BBC1CE",
                borderWidth: 1,
                borderRadius: "8px",
            },
            "&:hover fieldset": {
                borderColor: "#BBC1CE",
                borderWidth: 1,
                borderRadius: "8px",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#BBC1CE",
                borderWidth: 2,
                borderRadius: "8px",
            },
            input: {
                "&::placeholder": {
                    color: "#BBC1CE",
                    opacity: 1,
                    fontSize: 14,
                },
                padding: "11.5px",
            },
        },
        "& label": {
            color: "#7A869A",
        },
        "& label.Mui-focused": {
            color: "#7A869A",
            fontSize: "17px",
            fontWeight: "500",
        },
    };

    return (
        <>
            <Dialog open={openDialog} onClose={handleClose} fullWidth maxWidth="sm" >
                <DialogContent style={{ padding: "30px" }}>
                    <Box display="flex" style={{
                        justifyContent: "space-between", backgroundColor: "#F3F3F3",
                        width: "100%",
                        height: "30px",
                        alignItems: "center",
                        borderRadius: '8px'
                    }}>
                        <span style={{
                            paddingLeft: '15px',
                            fontFamily: "poppins",
                            fontWeight: "700",
                            fontSize: "18px",
                            lineHeight: "27px",
                            color: "#172B4D",
                        }}> Add New Credit Card  </span>
                    </Box>
                    <div style={{
                        display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between",
                        marginBottom: "10px", marginTop: "20px", paddingRight: "42px"
                    }}>
                        <div style={{
                            fontSize: "16px",
                            fontWeight: "700",
                            color: "#1A1A1A",
                            lineHeight: "24px",
                        }}>
                            <span>
                                Credit Card
                            </span>
                        </div>

                        <div style={{
                            display: "flex", flexDirection: "row", alignItems: 'center',
                            width: "50px"
                        }}>
                            <img src={visa} alt="visa" style={{ width: "100%" }} />
                            <img src={mastercard} alt="mastercard" style={{ width: "100%" }} />
                        </div>


                    </div >
                    <Box>
                        <Grid container spacing={3}>
                            <Grid item md={6} xs={12}>
                                <CardNumberElement
                                    fullWidth
                                    id="card-num-input"
                                    style={inputStyle}
                                    options={{
                                        placeholder: "Card Number*",
                                        showIcon: true,
                                    }}
                                    onChange={(event) => {
                                        if (event.error) {
                                            setCardNumberError(event.error.message);
                                        } else {
                                            setCardNumberError("");
                                        }
                                    }}
                                />
                                {cardNumberError && (
                                    <Typography variant="caption" color="error">
                                        {cardNumberError}
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <CardExpiryElement
                                    fullWidth
                                    id="exp-date-input"
                                    options={{
                                        placeholder: "MM/YY*",
                                    }}
                                    style={inputStyle}
                                    onChange={(event) => {
                                        if (event.error) {
                                            setExpiryError(event.error.message);
                                        } else {
                                            setExpiryError("");
                                        }
                                    }}
                                />
                                {expiryError && (
                                    <Typography variant="caption" color="error">
                                        {expiryError}
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item md={6} xs={12} sx={{ mt: 2 }}>
                                <TextInput
                                    fullWidth
                                    id="card-holder-input"
                                    label={" Card Holder "}
                                    styles={inputStyle}
                                    name="card-holder"
                                    onChange={handleChange("name")}
                                    value={cardName}
                                    variant="outlined"
                                    placeholder="Please enter card holder name"
                                    filled="true"
                                    focused
                                    error={Boolean(errors.name)}
                                />
                                {errors.name && (
                                    <Typography variant="caption" style={{ color: "#d32f2f" }}>
                                        {errors.name}
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <CardCvcElement
                                    fullWidth
                                    id="cvc-input"
                                    options={{
                                        placeholder: "CVC*",
                                    }}
                                    style={inputStyle}
                                    onChange={(event) => {
                                        if (event.error) {
                                            setCvcError(event.error.message);
                                        } else {
                                            setCvcError("");
                                        }
                                    }}
                                />
                                {cvcError && (
                                    <Typography variant="caption" color="error">
                                        {cvcError}
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions
                    style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        marginBottom: "20px",
                        paddingLeft: "30px",
                        width: "45%"
                    }}
                >
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: 'white',
                            color: colorConfigs?.sidebar?.bg,
                            borderRadius: '10px',
                            width: "100%",
                            padding: '10px 20px',
                            '&:hover': {
                                backgroundColor: '#E6E9F2',
                            },
                        }}
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: process.env.REACT_APP_ENV === 'women_first' ? colorConfigs?.sidebar?.bg : '#005e8b',
                            color: 'white',
                            borderRadius: '10px',
                            width: "100%",
                            padding: '10px 20px',
                            '&:hover': {
                                backgroundColor: process.env.REACT_APP_ENV === 'women_first' ? colorConfigs?.sidebar?.bg : '#004b6e',
                            },
                        }}
                        onClick={handleSave}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
            <Box sx={{
                backgroundColor: "white",
                padding: "30px",
                borderBottomLeftRadius: "22px",
                borderBottomRightRadius: "22px"
            }}>
                {cards?.length !== 0 && (
                    <div style={{
                        display: "flex", flexDirection: "row", alignItems: "center",
                        justifyContent: "space-between",
                    }}>
                        <span style={{
                            fontFamily: "Lexend",
                            fontSize: "16px",
                            fontWeight: "700",
                            color: "#1A1A1A",
                            lineHeight: "24px",
                            marginBottom: "20px"
                        }}>
                            My Credit Cards
                        </span>
                    </div>)}
                <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'start', alignItems: 'center', gap: 2 }}>
                    {cards?.length === 0 ? (

                        <Box sx={{
                            display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'start', height: '56px',
                            width: "100%",
                        }}>
                            <span style={{
                                fontFamily: "Poppins",
                                fontWeight: "400",
                                fontSize: "18px",
                                lineHeight: "20px",
                                color: "#7A869A",
                            }}>No credit  cards have been added yet , please add one at least.</span>
                        </Box>
                    ) : (
                        cards && cards?.map((card) => (
                            <Box key={card.id} sx={{
                                display: 'flex', alignItems: 'center', justifyContent: "space-between", width: "100%",
                                padding: "30px",
                                height: "56px",
                                boxShadow: '0px 4px 28px rgba(0, 0, 0, 0.06)',
                                borderRadius: "12px",
                            }}>
                                <PaymentMethod
                                    img={
                                        card?.brand === 'American Express'
                                            ? amex
                                            : card?.brand === 'Diners Club'
                                                ? dinars
                                                : card?.brand === 'Discover'
                                                    ? discover
                                                    : card?.brand === 'JCB'
                                                        ? jbc
                                                        : card?.brand === 'Visa'
                                                            ? visa
                                                            : card?.brand === 'MasterCard'
                                                                ? mastercard
                                                                : card?.brand === 'UnionPay'
                                                                    ? unionpay
                                                                    : geniric
                                    }
                                    text={`**** **** **** ${card?.last4}`}
                                    imgStyle={{ width: '60px', height: '60px', objectFit: 'contain' }}
                                    textStyle={{
                                        fontFamily: "Poppins",
                                        fontWeight: "550",
                                        fontSize: "17px",
                                        lineHeight: "20px",
                                        color: "rgba(73, 89, 116, 1)"
                                    }}
                                />
                                <Box>
                                    <div
                                        style={{
                                            backgroundColor: "#ffd8d8",
                                            display: "flex",
                                            borderRadius: "22px",
                                            padding: "4px",
                                        }}>
                                        <DeleteIcon
                                            fontSize="medium"
                                            className="icon"
                                            style={{ color: "#E71D36" }}
                                            onClick={() => handleDelete(card.id)}
                                        />
                                    </div>
                                </Box>
                            </Box>
                        ))
                    )}
                </Box>
                <Button
                    variant="contained"
                    style={{
                        color: colorConfigs.sidebar.bg,
                        fontWeight: "bold",
                        backgroundColor: "white",
                        marginTop: "20px",
                        minWidth: "250px",
                        borderRadius: '10px',
                        padding: '10px 20px',
                        textTransform: 'none',
                    }}
                    onClick={() => {
                        setOpenDialog(true);
                    }}
                >
                    <span style={{ color: colorConfigs.sidebar.bg }}>Add New Credit Card</span>
                </Button>
            </Box>
            {bankAccStatus && licAndConfig?.licenses?.bank_account && (
                <Box sx={{
                    backgroundColor: "white",
                    padding: "30px",
                    borderRadius: "22px",
                    mt: 4
                }}>
                    <div style={{
                        display: "flex", flexDirection: "row", alignItems: "center",
                        justifyContent: "space-between", marginBottom: "5px"
                    }}>
                        <span style={{
                            fontFamily: "Lexend",
                            fontSize: "16px",
                            fontWeight: "700",
                            color: "#1A1A1A",
                            lineHeight: "24px",
                        }}>
                            Bank Account
                        </span>
                    </div>
                    {bankAccStatus === "add" && (<>
                        <div style={{
                            display: "flex", flexDirection: "row", alignItems: "center",
                            justifyContent: "space-between", marginBottom: "25px"
                        }}>
                            <span style={{
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                fontWeight: "400",
                                color: "#7A869A",
                                lineHeight: "17px",
                            }}>
                                Link your company bank account to receive payouts for your business.
                            </span>
                        </div>
                        <DialogActions style={{
                            display: "flex",
                            justifyContent: "left",
                            alignItems: "flex-start",
                            width: "30%",
                            padding: "0",
                            gap: "16px",
                            marginTop: "25px"
                        }}>
                            <Button
                                variant="contained"
                                sx={{
                                    textTransform: 'none',
                                    backgroundColor: process.env.REACT_APP_ENV === 'women_first' ? colorConfigs?.sidebar?.bg : '#005e8b',
                                    color: 'white',
                                    borderRadius: '10px',
                                    minWidth: "250px",
                                    padding: '10px 20px',
                                    '&:hover': {
                                        backgroundColor: process.env.REACT_APP_ENV === 'women_first' ? colorConfigs?.sidebar?.bg : '#004b6e',
                                    },
                                }}
                                onClick={handleAddBankAcc}
                            >
                                Add Bank Account
                            </Button>
                        </DialogActions>
                    </>
                    )}
                    {bankAccStatus === "Unverified" && (
                        <Box sx={{
                            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', color: "#721c24",
                            borderRadius: '25px', backgroundColor: "#f5c6cb", width: "250px", height: "44.5px", marginTop: "25px",
                            cursor: "pointer"
                        }}
                            onClick={handleAddBankAcc}>
                            <div>Account not verified </div><CiEdit style={{ fontSize: "28px", marginLeft: "5px" }} />
                        </Box>
                    )}
                    {bankAccStatus === "verified" && (

                        <Box sx={{
                            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', color: "#155724",
                            borderRadius: '25px', backgroundColor: "#c3e6cb", width: "250px", height: "44.5px", marginTop: "25px",
                            cursor: "pointer"
                        }}
                            onClick={handleAddBankAcc}>
                            <div>Account verified</div><CiEdit style={{ fontSize: "28px", marginLeft: "5px" }} />
                        </Box>)}
                </Box >
            )}

            <Snackbar
                open={openSaveCard}
                autoHideDuration={4000}
                onClose={handleCloseSaveCard}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <Alert
                    onClose={handleCloseSaveCard}
                    severity="success"
                    variant="filled"
                    sx={{ width: "100%" }}
                >
                    The new card is saved successfully!
                </Alert>
            </Snackbar>
            <Snackbar
                open={openDeleteCard}
                autoHideDuration={4000}
                onClose={handleCloseSaveCard}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <Alert
                    onClose={handleCloseSaveCard}
                    severity="success"
                    variant="filled"
                    sx={{ width: "100%" }}
                >
                    The card is deleted  successfully!
                </Alert>
            </Snackbar>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={4000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <Alert onClose={handleSnackbarClose} severity="error">
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            <Loading
                loading={!!loading}
                background="rgba(86, 100, 210, 0.1)"
                loaderColor={colorConfigs.sidebar?.bg}
            />
            {/* delete dialog */}
            <Dialog
                open={deleteConfirmationDialogOpen}
                onClose={() => setDeleteConfirmationDialogOpen(false)}
                keepMounted>
                <DialogContent style={{ padding: "30px" }}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <img src={deletee} alt="" />
                    </div>
                    <DialogContentText
                        id="alert-dialog-slide-description"
                        style={{ display: "flex", justifyContent: "center" }}>
                        <Typography
                            variant="h6"
                            style={{
                                fontWeight: "bold",
                                color: "black",
                                marginTop: "10px",
                                marginBottom: "8px",
                            }}>
                            Delete Card
                        </Typography>
                    </DialogContentText>
                    <Typography >
                        Are you sure you want to delete this card ?
                    </Typography>
                </DialogContent>
                <DialogActions
                    style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        marginBottom: "20px",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                    }}>
                    <Button
                        variant="outlined"
                        style={{
                            color: loading ? "gray" : "black",
                            borderColor: "lightgray",
                            width: "100%",
                        }}
                        onClick={() => handleDeleteConfirmation(false)}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        style={{
                            background: loading ? "gray" : "var(--Error, #FD4343)",
                            color: "white",
                            width: "100%",
                        }}
                        onClick={() => handleDeleteConfirmation(true)}>
                        Yes, Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

const PaymentMethod = ({ img, text, imgStyle, textStyle }) => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <img src={img} alt="Card Brand" style={imgStyle} />
            <Typography sx={textStyle}>{text}</Typography>
        </Box>
    );
};

const PaymentsWrapper = (getAllCards) => (
    <Elements stripe={stripePromise}>
        <Payments getAllCards={getAllCards} />
    </Elements>
);

export default PaymentsWrapper;
