import React, { useEffect, useState } from "react";
import { Alert, Snackbar } from "@mui/material";
import TrackingMap from "./TrackingMap";
import Error5xx from "../../components/common/HandelError5xx";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { db } from "../../configs/firebase";

const rideCache = new Map();

const createCacheKey = (rideID, status) => `${rideID}_${status}`;

const setCachedRide = (rideID, status, data) => {
  const cacheKey = createCacheKey(rideID, status);
  const now = new Date().getTime();
  rideCache.set(cacheKey, { data, timestamp: now });
};

const getCachedRide = (rideID, status) => {
  const cacheKey = createCacheKey(rideID, status);
  const cacheEntry = rideCache.get(cacheKey);
  if (!cacheEntry) return null;

  const now = new Date().getTime();
  const age = now - cacheEntry.timestamp;

  if (age > 300000) {
    rideCache.delete(cacheKey);
    return null;
  }

  return cacheEntry.data;
};

const clearCache = () => {
  rideCache.clear();
};

function RideMap({ rideID, activeRide }) {
  const [rideData, setRideData] = useState(activeRide || {});
  const [pickup, setPickup] = useState({
    lat: rideData?.pickup_address?.lat,
    lng: rideData?.pickup_address?.lng,
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleSnackbarClose = () => setSnackbarOpen(false);
  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  
  const [tryAgain, setTryAgain] = useState(null);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [errorCount, setErrorCount] = useState(0);
  const [errorDialogCounter, setErrorDialogCounter] = useState(0);

  const handleError5xx = () => {
    setIsErrorDialogOpen(true);
    setErrorCount((prevCount) => {
      const newCount = prevCount + 1;
      if (newCount === 1) {
        setErrorDialogCounter(5);
      } else if (newCount === 2) {
        setErrorDialogCounter(10);
      }
      return newCount;
    });
  };

  const handleCloseErrorDialog = () => {
    setIsErrorDialogOpen(false);
    if (errorCount <= 2) {
      handleErrorCountByClose();
    }
  };

  const handleErrorCountByClose = () => {
    setErrorCount((prevCount) => {
      const newCount = prevCount - 1;
      if (newCount === 1) {
        setErrorDialogCounter(5);
      } else if (newCount === 2) {
        setErrorDialogCounter(10);
      }
      return newCount;
    });
  };

  useEffect(() => {
    if (rideID && activeRide?.status) {
      const cachedRide = getCachedRide(activeRide._id, activeRide.status);
      if (cachedRide) {
        setRideData(cachedRide);
      } else {
        setRideData(activeRide);  
        setCachedRide(activeRide._id, activeRide.status, activeRide);  
      }
  
      const q = query(
        collection(db, "driver_dispatch"),
        where("status", "!=", "free")
      );

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          if (doc.id === activeRide?.driver_info?.uid) {
            const driverData = doc.data();
            setPickup({
              lat: driverData.location.lat,
              lng: driverData.location.lng,
            });
          }
        });
      }, (error) => {
        showSnackbar("Failed to fetch real-time driver location");
      });
      
      return () => unsubscribe();
    }
  }, [rideID, activeRide, activeRide?.status]);

  useEffect(() => {    
    const handleBeforeUnload = () => {
      clearCache();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);


  let dropoff = rideData?.status === "pickedup" ||  rideData?.status === "waiting_delivery"?
    {
      lat: rideData?.delivery_address?.lat,
      lng: rideData?.delivery_address?.lng,
    } : {
      lat: rideData?.pickup_address?.lat,
      lng: rideData?.pickup_address?.lng,
    }


  const wayPoints =
    rideData?.status === "pickedup" ? rideData?.stop_points?.map((point) => ({
      location: { lat: point.lat, lng: point.lng },
      stopover: true,
    })) : undefined

  return (
    <>
      <Error5xx
        open={isErrorDialogOpen}
        errCount={errorCount}
        count={errorDialogCounter}
        handleClose={handleCloseErrorDialog}
        tryAgain={tryAgain}
      />

      <div style={{ width: "100%", height: "100%" }}>
        <TrackingMap
          pickup={pickup}
          dropoff={dropoff}
          driverLocation={{
            lat: rideData?.driver_info?.location?.lat,
            lng: rideData?.driver_info?.location?.lng,
          }}
          wayPoints={wayPoints}
          activeRide={rideData}
        />
      </div>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleSnackbarClose} severity="error">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default React.memo(RideMap, (prevProps, nextProps) => {
  return (
    prevProps.rideID === nextProps.rideID &&
    prevProps.activeRide?._id === nextProps.activeRide?._id &&
    prevProps.activeRide?.status === nextProps.activeRide?.status
  );
});
