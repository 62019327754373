import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import moment from "moment";
import React, { useState } from "react";
import ReactBnbGallery from "react-bnb-gallery";
import "react-bnb-gallery/dist/style.css";
import { isEmojiOnly, isToday } from "../../utils/methods.utils";

import colorConfigs from "../../configs/colorConfigs";
import Audio from "../public-messaging/components/Audio";
import AttachmentHandler from "../public-messaging/components/attachment-handler";

const MessageListItemPrivate = ({
  message,
  enablerUid,
  nextUid,
  previous,
  next,
  admin_info,
}) => {
  let isMine = message.uid;
  let nextContinue =
    next?.uid == message.uid &&
    next != null &&
    moment(next.createAt)
      .subtract(moment(message.createAt).get("hours"), "hours")
      .get("hours") < 1;
  let prevContinue =
    previous?.uid == message.uid &&
    previous != null &&
    moment(message.createAt)
      .subtract(moment(previous.createAt).get("hours"), "hours")
      .get("hours") < 1;
  const useStyles = {
    backgroundTooltip: {
      borderRadius: "14px",
      background: "rgb(30,30,30)",
      padding: "6px 8px",
    },
  };

  const [galleryStatus, setGalleryStatus] = useState({
    isOpen: false,
    currentPhoto: null,
  });

  const onPhotoPress = (index) => {
    setGalleryStatus({
      isOpen: true,
      currentPhoto: index,
    });
  };
  const onGalleryClose = () => {
    setGalleryStatus({
      isOpen: false,
      currentPhoto: null,
    });
  };
  const { isOpen } = galleryStatus.isOpen;
  const photosToShow = message.images;
  const AdminCondition =  message.uid === enablerUid
  return (
    <Tooltip
      placement={isMine ? "left" : "right"}
      classes={{
        tooltip: useStyles.backgroundTooltip,
      }}
      title={
        <Typography
          variant={"caption"}
          medium
          style={{ color: "white", padding: "4px 8px" }}
        >
          {`${isToday(message.createAt)
            ? "Today"
            : moment(message.createAt).format("MMM DD")
            } at ${moment(message.createAt).format("hh:mm A")}`}
        </Typography>
      }
      disableFocusListener
      style={{ background: "black" }}
    >
      <div
        style={{
          maxWidth: "400px",
          width: "fit-content",
          marginTop: "2px",
          marginBottom: message.uid !== nextUid ? "12px" : "1px",
          marginRight: AdminCondition ? '10px' : '50px',
          marginLeft: AdminCondition ? '10px' : '50px',
          padding: "10px 14px",
          borderTopLeftRadius: "20px",
          borderTopRightRadius: "20px",
          borderBottomLeftRadius: "20px",
          borderBottomRightRadius: "20px",
          background: admin_info ? '#F8F8F8' : colorConfigs?.sidebar?.ligthBg,
          color: admin_info ? "#4F4F4F" : "#505470",
        }}
      >
        {message.type === "text" ? (
          <Typography
            variant={isEmojiOnly(message.text) ? "body1" : "body2"}
            regular
          >
            {message.text}
          </Typography>
        ) : message.type === "audio" ? (
          <Audio
            style={{
              padding: "7px 14px",
              borderTopLeftRadius: prevContinue && !isMine ? "4px" : "20px",
              borderTopRightRadius: prevContinue && isMine ? "4px" : "20px",
              borderBottomLeftRadius: nextContinue && !isMine ? "4px" : "20px",
              borderBottomRightRadius: nextContinue && isMine ? "4px" : "20px",
            }}
            mine={isMine}
            id={message.id}
            src={message?.audio}
            audioDuration={message?.audioDuration}
          />
        ) : message.type === "call" ? (
          <div>
            <Typography variant="body2">[Call]</Typography>
          </div>
        ) : (
          <>
            {message?.type == "image" && message?.images?.length > 0 && (
              <div style={{ margin: "12px 0", width: "300px" }}>
                <AttachmentHandler maxHeight="300px" images={message?.images} />
              </div>
            )}
          </>
        )}
        <ReactBnbGallery
          show={galleryStatus.isOpen}
          photos={photosToShow}
          activePhotoIndex={galleryStatus.currentPhoto}
          onClose={onGalleryClose}
          wrap={false}
          showThumbnails={false}
          backgroundColor="#000000"
        />
      </div>
    </Tooltip>
  );
};

export default MessageListItemPrivate;
