import DepartureBoardIcon from "@mui/icons-material/DepartureBoard";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import DirectionsCarFilledOutlinedIcon from "@mui/icons-material/DirectionsCarFilledOutlined";
import GroupsIcon from "@mui/icons-material/Groups";
import MapIcon from "@mui/icons-material/Map";
import MessageIcon from '@mui/icons-material/Message';
import PushPinRoundedIcon from "@mui/icons-material/PushPinRounded";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import SettingsIcon from '@mui/icons-material/Settings';
import SmsIcon from "@mui/icons-material/Sms";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import { ImMap2 } from "react-icons/im";
import { Navigate } from "react-router-dom";
import colorConfigs from "../configs/colorConfigs";
import RequestsDetails from "../pages/DriverRequests/RequestsDetails";
import AddRates from "../pages/Rates/AddRate";
import EditRates from "../pages/Rates/EditRates";
import RatesView from "../pages/Rates/ViewRates";
import RidersDetails from "../pages/Riders/RidersDetails";
import RiderPage from "../pages/Riders/RidersPage";
import AdminsList from "../pages/admins/ViewAdmin";
import ContactUS from "../pages/contactUs/ContactUs";
import DriverTicketManagment from "../pages/driverSupport/DriverTicketManagment";
import DriverHelpCenter from "../pages/driverSupport/DriverHelpCenter";
import RiderTicketManagment from "../pages/riderSupport/RiderTicketManagment";
import AddHelpCenter from "../components/support/AddHelpCenter";
import Settings from "../pages/adminSettings/Settings";
import DispatcherView from "../pages/dispatcher/DispatcherView";
import DriverPage from "../pages/drivers/DriverPage";
import DriversDetails from "../pages/drivers/DriversDetails";
import GoogleMaps from "../pages/googleMaps/googleMaps";
import Login from "../pages/login/login";
import MessagingPage from "../pages/private_messaging/Messaging";
import SupportMessagingPage from "../pages/public-messaging/Messaging";
import RidesDetails from "../pages/rides/ridesDetails";
import RidesList from "../pages/rides/ridesView";
import TrackRides from "../pages/trackRides/TrackRides";
import TicketDetails from "../components/support/TicketDetails"
// import SettingsPayout from "../pages/payouts/PayoutSettings";
// import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import { LuCalendarDays } from "react-icons/lu";
import BookingHistory from "../pages/bookingHistory/BookingHistory";
import CooperatesViewList from "../pages/cooperate/CooperatesViewList";
import PayoutDetails from "../pages/payouts/PayoutDetails";
import PayoutView from "../pages/payouts/PayoutView";
import HelpCenterDetails from "../components/support/HelpCenterDetails";
import RiderHelpCenter from "../pages/riderSupport/RiderHelpCenter";
import CorporateInfo from "../pages/cooperate/CorporateInfo";
import AddNewVoucher from "../pages/cooperate/components/corporateInfo/AddNewVoucher";
import VoucherDetails from "../pages/cooperate/components/corporateInfo/VoucherDetails";
import EmployeeDetails from "../pages/cooperate/components/corporateInfo/EmployeeDetails/MemberDetails";
import AddMember from "../pages/cooperate/components/corporateInfo/AddMember";
let isAuth = localStorage.getItem("isAuth");
const appRoutes = [
  {
    path: "/login",
    element: isAuth
      ? <RidesList /> && <Navigate to="/rides" />
      : <Login /> && <Navigate to="/rides" />,
    state: "login",
  },
  {
    path: "/Dashboard",
    element: <BookingHistory />,
    state: "Dashboard",
    sidebarProps: {
      displayText: "Dashboard",
      icon: <LuCalendarDays size={"24px"} color="white" />,
      activeIcon: (
        <LuCalendarDays
          size={"24px"}
          style={{ color: colorConfigs?.sidebar?.bg }}
        />
      ),
    },
  },
  {
    index: true,
    path: "/Dispatcher",
    element: <DispatcherView />,
    state: "Dispatcher",
    sidebarProps: {
      displayText: "Dispatch",
      icon: <ImMap2 size={"24px"} color="white" />,
      activeIcon: (
        <ImMap2
          size={"24px"}
          style={{ color: colorConfigs?.sidebar?.bg }}
        />
      ),
    },
  },

  {
    path: "/rides",
    element: <RidesList />,
    state: "rides",
    sidebarProps: {
      displayText: "Rides",
      icon: <DirectionsCarFilledOutlinedIcon size={"24px"} color="white" />,
      activeIcon: (
        <DirectionsCarFilledOutlinedIcon
          size={"24px"}
          style={{ color: colorConfigs?.sidebar?.bg }}
        />
      ),
    },
    children: [{
      path: "/rides",
      element: <RidesList />,
      state: "rides",
      sidebarProps: {
        displayText: "Rides",
        icon: <DirectionsCarFilledOutlinedIcon size={"24px"} color="white" />,
        activeIcon: (
          <DirectionsCarFilledOutlinedIcon
            size={"24px"}
            style={{ color: colorConfigs?.sidebar?.bg }}
          />
        ),
      },
    },
    {
      path: "/trackRides",
      element: <TrackRides />,
      state: "trackrides",
      sidebarProps: {
        displayText: "Track Rides",

        icon: <DepartureBoardIcon size={"24px"} color="white" />,
        activeIcon: (
          <DepartureBoardIcon
            size={"24px"}
            style={{ color: colorConfigs?.sidebar?.bg }}
          />
        ),
      },
    },
    ]
  },
  {
    path: "/rides/details/:id",
    element: <RidesDetails />,
    state: "rides",
  },

  {
    index: true,
    path: "/passengers",
    element: <RiderPage />,
    state: "Passengers",
    sidebarProps: {
      displayText: "Passengers",
      icon: <GroupsIcon size={"24px"} color="white" />,
      activeIcon: (
        <GroupsIcon
          size={"24px"}
          style={{ color: colorConfigs?.sidebar?.bg }}
        />
      ),
    },
  },
  // {
  //   index: true,
  //   path: "/riders",
  //   element: <RiderPage />,
  //   state: "riders",
  //   sidebarProps: {
  //     displayText: "Riders",
  //     icon: <GroupsIcon size={"24px"} color="white" />,
  //     activeIcon: (
  //       <GroupsIcon
  //         size={"24px"}
  //         style={{ color: colorConfigs?.sidebar?.bg }}
  //       />
  //     ),
  //   },
  //   children: [
  //     {
  //       path: "/riders",
  //       element: <RiderPage />,
  //       state: "Passengers",
  //       sidebarProps: {
  //         displayText: "Passengers",
  //       },
  //     },
  //     {
  //       path: "/cooperates",
  //       element: <CooperatesViewList />,
  //       state: "Corperates",
  //       sidebarProps: {
  //         displayText: "Corperates",
  //       },
  //     },
  //     {
  //       state: "Cooperates",
  //       path: "/cooperates/corporate-details",
  //       element: <CorporateInfo />,
  //     },
  //     {
  //       state: "Cooperates",
  //       path: "/cooperates/add-voucher",
  //       element: <AddNewVoucher />,
  //     },
  //     {
  //       state: "Cooperates",
  //       path: "/cooperates/edit-voucher",
  //       element: <AddNewVoucher />,
  //     },
  //     {
  //       state: "Cooperates",
  //       path: "/cooperates/voucher-details",
  //       element: <VoucherDetails />,
  //     },
  //     {
  //       state: "Cooperates",
  //       path: "/cooperates/add-employee",
  //       element: <AddMember />,
  //     },
  //     {
  //       state: "Cooperates",
  //       path: "/cooperates/add-customer",
  //       element: <AddMember />,
  //     },
  //     {
  //       state: "Cooperates",
  //       path: "/cooperates/employee_details",
  //       element: <EmployeeDetails />,
  //     },
  //     {
  //       state: "Cooperates",
  //       path: "/cooperates/customer_details",
  //       element: <EmployeeDetails />,
  //     },
  //   ],
  // },
  {
    path: "/passengers/details/:id",
    element: <RidersDetails />,
    state: "riders",
  },
  {
    index: true,
    path: "/drivers",
    element: <DriverPage />,
    state: "drivers",
    sidebarProps: {
      displayText: "Drivers",
      icon: <DirectionsCarIcon size={"24px"} color="white" />,
      activeIcon: (
        <DirectionsCarIcon
          size={"24px"}
          style={{ color: colorConfigs?.sidebar?.bg }}
        />
      ),
    },
    children: [
      {
        path: "/drivers",
        element: <DriverPage />,
        state: "drivers",
        sidebarProps: {
          displayText: "Drivers",
          icon: <DirectionsCarIcon size={"24px"} color="white" />,
          activeIcon: (
            <DirectionsCarIcon
              size={"24px"}
              style={{ color: colorConfigs?.sidebar?.bg }}
            />
          ),
        },
      },
      {
        path: "/tracking",
        element: <GoogleMaps />,
        state: "googlemaps",
        sidebarProps: {
          displayText: "Track Drivers",

          icon: <MapIcon size={"24px"} color="white" />,
          activeIcon: (
            <MapIcon size={"24px"} style={{ color: colorConfigs?.sidebar?.bg }} />
          ),
        },
      },
    ]
  },
  {
    path: "/admins",
    element: <AdminsList />,
    state: "admins",
    sidebarProps: {
      displayText: "Admins",
      icon: <SupervisorAccountIcon size={"24px"} color="white" />,
      activeIcon: (
        <SupervisorAccountIcon
          size={"24px"}
          style={{ color: colorConfigs?.sidebar?.bg }}
        />
      ),
    },
    children: [
      {
        path: "/admins",
        element: <AdminsList />,
        state: "admins",
        sidebarProps: {
          displayText: "Admins",
          icon: <SupervisorAccountIcon size={"24px"} color="white" />,
          activeIcon: (
            <SupervisorAccountIcon
              size={"24px"}
              style={{ color: colorConfigs?.sidebar?.bg }}
            />
          ),
        },
      },

      {
        index: true,
        path: "/rates",
        element: <RatesView />,
        state: "rates",
        sidebarProps: {
          displayText: "Rates & Zones",
          icon: <PushPinRoundedIcon size={"24px"} color="white" />,
          activeIcon: (
            <PushPinRoundedIcon
              size={"24px"}
              style={{ color: colorConfigs?.sidebar?.bg }}
            />
          ),
        },
      },
      {
        path: "/payment",
        element: <PayoutView />,
        state: "payment",
        sidebarProps: {
          displayText: "Payouts",
          icon: <ReceiptOutlinedIcon size={"24px"} color="white" />,
          activeIcon: (
            <ReceiptOutlinedIcon
              size={"24px"}
              style={{ color: colorConfigs?.sidebar?.bg }}
            />
          ),
        },
      },
    ]
  },
  {
    path: "/",
    element: <BookingHistory />,
    state: "Dashboard",
  },
  {
    path: "/drivers/details/:id",
    element: <DriversDetails />,
    state: "drivers",
  },
  {
    path: "/driversRequests/details/:id",
    element: <RequestsDetails />,
    state: "driversRequests",
  },
  {
    path: "/rates/add",
    element: <AddRates />,
    state: "rates",
  },
  {
    path: "/rates/edit/:id",
    element: <EditRates />,
    state: "rates",
  },

  {
    path: "/support",
    element: <SupportMessagingPage />,
    state: "support",
    sidebarProps: {
      displayText: "Support",

      icon: <QuestionMarkIcon size={"24px"} color="white" />,
      activeIcon: (
        <QuestionMarkIcon
          size={"24px"}
          style={{ color: colorConfigs?.sidebar?.bg }}
        />
      ),
    },
  },
  {
    path: "/chat",
    element: <MessagingPage />,
    state: "chat",
    sidebarProps: {
      displayText: "Ride's Chat",

      icon: <SmsIcon size={"24px"} color="white" />,
      activeIcon: (
        <SmsIcon size={"24px"} style={{ color: colorConfigs?.sidebar?.bg }} />
      ),
    },
  },
  {
    path: "support/driver/:msg_id",
    state: "support",
    element: <SupportMessagingPage />,
  },
  // {
  //   index: true,
  //   path: "/zones",
  //   element: <FeesView />,
  //   state: "zones",
  //   sidebarProps: {
  //     displayText: "Zones & Rides",
  //     icon: <PushPinRoundedIcon size={"24px"} color="white" />,
  //     activeIcon: (
  //       <PushPinRoundedIcon
  //         size={"24px"}
  //         style={{ color: colorConfigs?.sidebar?.bg }}
  //       />
  //     ),
  //   },
  // },
  // {
  //   path: "/zone/add",
  //   element: <AddNewFee />,
  //   state: "zone",
  // },
  // {
  //   path: "/zone/details/:id",
  //   element: <EditFee />,
  //   state: "zone",
  // },
  {
    path: "support/passenger/:msg_id",
    state: "support",
    element: <SupportMessagingPage />,
  },
  { path: "chat/:id", state: "chat", element: <MessagingPage /> },
  { path: "chat/:id", state: "chat", element: <MessagingPage /> },
  {
    index: true,
    sidebarProps: {
      displayText: "Driver Support",
      icon: <MessageIcon size={"24px"} color="white" />,
      activeIcon: (
        <MessageIcon
          size={"24px"}
          style={{ color: colorConfigs?.sidebar?.bg }}
        />
      ),
    },
    children: [

      {
        path: "/driverHelpCenter",
        element: <DriverHelpCenter />,
        state: "driverHelpCenter",
        sidebarProps: {
          displayText: "Help Center",
        },
      },

      {
        path: "/driverTicketManagment",
        element: <DriverTicketManagment />,
        state: "driverTicketManagment",
        sidebarProps: {
          displayText: "Ticket Managment",
        },
      },
      {
        path: "/driverHelpCenter/addHelpCenter",
        element: <AddHelpCenter />,
        state: "addHelpCenter",
      },
      {
        path: "/driverHelpCenter/editHelpCenter",
        element: <AddHelpCenter />,
        state: "editHelpCenter",
      },
      {
        path: "/driverHelpCenter/details/:id",
        element: <HelpCenterDetails />,
        state: "driverHelpCenter",
      },
      {
        path: "/driverTicketManagment/details/:id",
        element: <TicketDetails />,
        state: "driverTicket",
      },
    ],
  }
  ,
  {
    index: true,
    sidebarProps: {
      displayText: "Passenger Support",
      icon: <MessageIcon size={"24px"} color="white" />,
      activeIcon: (
        <MessageIcon
          size={"24px"}
          style={{ color: colorConfigs?.sidebar?.bg }}
        />
      ),
    },
    children: [
      {
        path: "/passengerHelpCenter",
        element: <RiderHelpCenter />,
        state: "riderHelpCenter",
        sidebarProps: {
          displayText: "Help Center",
        },
      },
      {
        path: "/passengerTicketManagment",
        element: <RiderTicketManagment />,
        state: "riderTicketManagment",
        sidebarProps: {
          displayText: "Ticket Managment",
        },
      },


      {
        path: "/passengerHelpCenter/addHelpCenter",
        element: <AddHelpCenter />,
        state: "addHelpCenter",
      },
      {
        path: "/passengerHelpCenter/editHelpCenter",
        element: <AddHelpCenter />,
        state: "editHelpCenter",
      },
      {
        path: "/passengerHelpCenter/details/:id",
        element: <HelpCenterDetails />,
        state: "riderHelpCenter",
      },
      {
        path: "/passengerTicketManagment/details/:id",
        element: <TicketDetails />,
        state: "riderTicket",
      },

    ],
  },

  {
    path: "/payment/:id",
    element: <PayoutDetails />,
  },
  {
    path: "/contactUs",
    element: <ContactUS />,
    state: "contactUs",
    sidebarProps: {
      displayText: "Contact Us",
      icon: <MessageIcon size={"24px"} color="white" />,
      activeIcon: (
        <MessageIcon
          size={"24px"}
          style={{ color: colorConfigs?.sidebar?.bg }}
        />
      ),
    },
  }
  , {
    path: "/settings",
    element: <Settings />,
    state: "settings",
    sidebarProps: {
      displayText: "Settings",
      icon: <SettingsIcon size={"24px"} color="white" />,
      activeIcon: (
        <SettingsIcon
          size={"24px"}
          style={{ color: colorConfigs?.sidebar?.bg }}
        />
      ),
    },
  },
];

export default appRoutes;
