import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  Typography,
} from "@mui/material";
import ErrorIcon from '@mui/icons-material/Error';
import { TextInput } from "@zetaton-dev/zetaton-components-bank";
import SendIcon from "../../assets/icons/sendicon.png"
import { SubmitButton } from "@zetaton-dev/zetaton-components-bank";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { auth } from "../../configs/firebase";
import {
  setSelectedUser,
  setDriverRequirementsList,
  setDriversList,
  setDriversReqList,
  setEnableEdit,
  setAllDriversList,
} from "../../redux/features/appStateSlice";
import DynamicTable from "../table/table";
import * as XLSX from 'xlsx';
import Error5xx from "../../components/common/HandelError5xx";
import SearchIcon from "@mui/icons-material/Search";
import StarIcon from "@mui/icons-material/Star";
import { InputAdornment, TextField } from "@mui/material";
import Loading from "react-fullscreen-loading";
import { AiFillEdit } from "react-icons/ai";
import colorConfigs from "../../configs/colorConfigs";
import { NewFormatPhoneNumber } from "../../utils/localization/NewPhoneUtils";
import { getMethod, postMethod, putMethod, } from "../Hook/UseCustomApi";
import Status from "../status/status";
import Reason from "../reason/reason";
import { Switch } from "antd";
import { formatDate } from "../../utils/localization/dateTimeUtils";

export default function DriverPage() {

  const [count, setCount] = useState(1);
  const [duration, setDuration] = useState(8);
  const [actionType, setActionType] = useState("");
  const [countDeiverReq, setCountDeiverReq] = useState(1);
  const [countBlockedDeivers, setCountBlockedDeivers] = useState(1);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState("all");
  const [keyword, setKeyword] = useState("");
  const [activeButton, setActiveButton] = useState("activeDrivers");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [dataToAdd, setDataToAdd] = useState({
    title: "",
    key: "",
    type: "Image",
    size: "25000",
    description: "",
    enable: ""
  });
  const [uniqueKey, setUniqueKey] = useState(false);
  const [dataToEdit, setDataToEdit] = useState({});
  const [fullData, setFullData] = useState(false);
  const countryCode = useSelector((state) => state.country.selectedCountry);
  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };
  const rowsLimits = [5, 10, 25, 100];
  const dispatch = useDispatch();
  let currentPage = useRef(1);
  let currentLimit = useRef(10);
  let currentKeyword = useRef("");
  let currentStatus = useRef("all");
  const navigate = useNavigate();
  const driversList = useSelector((state) => state.appState.driversList);
  const driversReqList = useSelector((state) => state.appState.driversReq);
  const driverRequirementsList = useSelector((state) => state.appState.driverRequirementsList);
  const allDrivers = useSelector((state) => state.appState.allDriversList);
  const blockedDriversList = useSelector((state) => state.appState.blockedDriversList);
  const [data, setData] = useState(driversList);
  const [allDriversData, setAllDriversData] = useState(allDrivers);
  const [dataDriverRequest, setDataDriverRequest] = useState(driversReqList);
  const [dataDriverRequirements, setDataDriverRequirements] = useState(driverRequirementsList);
  const [blockedDrivers, setBlockedDrivers] = useState(blockedDriversList);
  const [unBlockedDriver, setUnBlockedDriver] = useState({});
  const [blockedDate, setBlockedDate] = useState("");
  const [blockedForWallet, setBlockedForWallet] = useState([]);
  const [blockedForLimit, setBlockedForLimit] = useState([]);
  const [open, setOpen] = useState(false);
  const [openUnblockDialog, setOpenUnblockDialog] = useState(false);
  const [enabled, setEnabled] = useState(true);
  const [formErrors, setErrors] = useState({});


  //handle error5xx
  const [tryAgain, setTryAgain] = useState();
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [errorCount, setErrorCount] = useState(0);
  const [errorDialogCounter, setErrorDialogCounter] = useState(0);
  const handleError5xx = () => {
    setIsErrorDialogOpen(true);
    setErrorCount(prevCount => {
      const newCount = prevCount + 1;
      if (newCount === 1) {
        setErrorDialogCounter(5);
      } else if (newCount === 2) {
        setErrorDialogCounter(10);
      }
      return newCount;
    });
  };
  const handleCloseErrorDialog = () => {
    setIsErrorDialogOpen(false);
    if (errorCount <= 2) {
      handleErrorCountByClose()
    }

  };
  const handleErrorCountByClose = () => {
    setErrorCount(prevCount => {
      const newCount = prevCount - 1;
      if (newCount === 1) {
        setErrorDialogCounter(5);
      } else if (newCount === 2) {
        setErrorDialogCounter(10);
      }
      return newCount;
    });
  }
  const licAndConfig = useSelector((state) => state.licencesAndConfiguration);
  // console.log(licAndConfig);

  useEffect(() => {
    const currentTime = new Date();
    let updatedTime;

    if (duration === "EOD") {
      const endOfDay = new Date(currentTime);
      endOfDay.setHours(23, 59, 59, 999);
      updatedTime = endOfDay;
    } else {
      updatedTime = new Date(currentTime.getTime() + duration * 60 * 60 * 1000);
    }
    const timestamp = Math.floor(updatedTime.getTime());
    setBlockedDate(timestamp);

  }, [duration]);

  // unblock
  const handleConfirmUnblock = () => {

    // console.log(unBlockedDriver._id);
    // console.log(blockedDate);
    console.log("all is good");
    setLoading(true)
    putMethod({
      url: `driver/un/block`,
      body: {
        uid: unBlockedDriver._id,
        unblock: blockedDate
      },
      handleErrorMessage: showSnackbar,
    })
      .then((response) => {
        console.log(response);
        fetchDrivers()
        fetchAllDrivers()
      })
      .catch((error) => {
        console.error("Error updating Enable/Disable:", error);
        if (error === "Internal Server Error: Server error occurred.") {
          handleError5xx();
          setTryAgain(() => handleConfirmUnblock);
        }
      });

    setOpenUnblockDialog(false);
    setLoading(false)

  };
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    fetchAllDrivers();
    dispatch(setEnableEdit(false));
  }, []);

  useEffect(() => {
    currentPage.current = 1;
    currentLimit.current = 10;
    currentKeyword.current = "";
    currentStatus.current = "all";
    window.scrollTo({ top: 0 });
    fetchDrivers();
  }, [activeButton]);


  const handleButtonClick = (button) => {
    setActiveButton(button);
    setStatus("all");
    setKeyword("");
    setData(driversList);
    setDataDriverRequest(driversReqList);
    setDataDriverRequirements(driverRequirementsList);
    clearData();
  };



  const renderStars = (count) => {
    if (count === undefined) {
      return (
        <Box display="flex" alignItems="center">
          <Typography>-</Typography>
        </Box>
      );
    }
    return (
      <Box display="flex" alignItems="center">
        <StarIcon
          style={{ color: "#FFCC00", width: "19.91px", height: "19.49px" }}
        />
        <Typography style={{ marginLeft: "1px" }}>{count}</Typography>
      </Box>
    );
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    currentPage.current = newPage;
    fetchDrivers();
  };

  const handleRowsChange = (rows) => {
    currentPage.current = 1;
    currentLimit.current = rows;
    setPage(1);
    fetchDrivers();
  };

  const checkDriver = (data) => {
    if (activeButton === "activeDrivers") {
      setData(data);
    } else if (activeButton === "DriverRequest") {
      setDataDriverRequest(data);
    }
  };

  const handleSearch = (e) => {
    const newKeyword = e.target.value;
    if (newKeyword !== currentKeyword.current) {
      setKeyword(newKeyword);
      currentPage.current = 1;
      currentKeyword.current = newKeyword;
      fetchDrivers();
    }
  };

  const handleStatusChange = (e) => {

    if (activeButton === "blockedDrivers" && e.target.value === "wallet-balance") {
      setBlockedDrivers(blockedForWallet)
      setStatus(e.target.value);
      setPage(1);
      currentStatus.current = e.target.value;
      currentPage.current = 1;
    } else if (activeButton === "blockedDrivers" && e.target.value === "cancellation-limit") {
      setBlockedDrivers(blockedForLimit)
      setStatus(e.target.value);
      setPage(1);
      currentStatus.current = e.target.value;
      currentPage.current = 1;
    } else if (activeButton === "blockedDrivers" && e.target.value === "all") {
      setStatus(e.target.value);
      setPage(1);
      currentStatus.current = e.target.value;
      currentPage.current = 1;
      fetchDrivers()
    } else {
      setStatus(e.target.value);
      setPage(1);
      currentStatus.current = e.target.value;
      currentPage.current = 1;
      fetchDrivers();
    }
  };

  const clearData = () => {

    currentPage.current = 1;
    currentLimit.current = 10;
    currentKeyword.current = "";
    currentStatus.current = "all";
    setPage(1);
  };
  console.log(process.env.REACT_APP_ENV, 'test')
  const getParams = () => {
    const params = {
      page: currentPage.current,
      limit: currentLimit.current,
      search_first_name: currentKeyword.current,
      search_Last_name: currentKeyword.current,
      search_phone_number: currentKeyword.current,
      search_short_id: currentKeyword.current,
      search_email: currentKeyword.current,
    };
    if (activeButton === "activeDrivers") {
      params.status = currentStatus.current;
      params.request = false;
    } else if (activeButton === "DriverRequest") {
      params.account_status = currentStatus.current;
      params.request = true;
    }
    return params;
  };

  const getDriverRequirementsParams = () => {
    return {
      page: currentPage.current,
      limit: currentLimit.current,
      keyword: currentKeyword.current,
    };
  };
  const getBlockedDriverParams = () => {
    return {
      page: currentPage.current,
      limit: currentLimit.current,
      search_first_name: currentKeyword.current,
      search_last_name: currentKeyword.current,
      search_phone_number: currentKeyword.current,
      search_short_id: currentKeyword.current,
      search_email: currentKeyword.current,
    };
  };

  const fetchDrivers = async () => {
    if (
      (activeButton === "activeDrivers" && driversList?.length === 0) ||
      (activeButton === "DriverRequest" && driversReqList?.length === 0) ||
      (activeButton === "DriverRequirements" && driverRequirementsList?.length === 0) ||
      (activeButton === "blockedDrivers" && blockedDriversList?.length === 0)

    ) {
      setLoading(true);
    }

    getMethod({
      url: "driver",
      params: getParams(),
      handleErrorMessage: showSnackbar,
    })
      .then((response) => {
        console.log(response?.data);

        setLoading(false);
        setCount(response?.count);
        checkDriver(response?.data);
        if (activeButton === "activeDrivers") {
          dispatch(setDriversList(response?.data));
          console.log(response.data);
        } else if (activeButton === "DriverRequest") {
          dispatch(setDriversReqList(response?.data));
        }
      })
      .catch((error) => {
        console.error("Error fetching more stores:", error);
        setLoading(false);
        if (error === "Internal Server Error: Server error occurred.") {
          handleError5xx();
          setTryAgain(() => fetchDrivers);
        }
      });


    getMethod({
      url: "driver/document/driver-document",
      params: getDriverRequirementsParams(),
      handleErrorMessage: showSnackbar,
    })
      .then((response) => {
        setLoading(false);
        setCountDeiverReq(response?.count);
        setDataDriverRequirements(response?.data);
        dispatch(setDriverRequirementsList(response?.data));
      })
      .catch((error) => {
        console.error("Error fetching more stores:", error);
        setLoading(false);
        if (error === "Internal Server Error: Server error occurred.") {
          handleError5xx();
          setTryAgain(() => fetchDrivers);
        }
      });

    // http://localhost:8011/api/v1/driver/blocked?projectId=roadrunner
    getMethod({
      url: "driver/blocked",
      params: getBlockedDriverParams(),
      handleErrorMessage: showSnackbar,
    })
      .then((response) => {
        setLoading(false);
        setBlockedForWallet(response && response?.data?.filter(driver => driver.wallet_balance < 0));
        setBlockedForLimit(response && response?.data?.filter(driver => driver.wallet_balance >= 0));
        setBlockedDrivers(response && response?.data)
        setCountBlockedDeivers(response?.count);
      })
      .catch((error) => {
        console.error("Error fetching more stores:", error);
        setLoading(false);
        if (error === "Internal Server Error: Server error occurred.") {
          handleError5xx();
          setTryAgain(() => fetchDrivers);
        }
      });

    // fetchAllDrivers();

  };


  const handleEnableDisableChange = async (admin, newValue) => {
    let data = {
      enabled: newValue,
    };


    putMethod({
      url: `driver/byAdmin/${admin?._id}`,
      body: data,
      handleErrorMessage: showSnackbar,
    })
      .then((response) => {
        setData((prevData) => {
          const updatedData = prevData.map((item) => {
            if (item._id === admin?._id) {
              return {
                ...item,
                enabled: newValue,
              };
            }
            return item;
          });
          return updatedData;
        });
        fetchDrivers()
        fetchAllDrivers()
      })
      .catch((error) => {
        console.error("Error updating Active status:", error);
        if (error === "Internal Server Error: Server error occurred.") {
          handleError5xx();
          setTryAgain(() => () => handleEnableDisableChange(admin, newValue));
        }
      });
  };


  const handleDriverReqEnableDisableChange = async (admin, newValue) => {
    let data = {
      enable: newValue,
    };

    putMethod({
      url: `driver/document/driver-document/${admin?._id}`,
      body: data,
      handleErrorMessage: showSnackbar,
    })
      .then((response) => {
        setDataDriverRequirements((prevData) => {
          const updatedData = prevData.map((item) => {
            if (item._id === admin?._id) {
              return {
                ...item,
                enable: newValue,
              };
            }
            return item;
          });
          return updatedData;
        });

      })
      .catch((error) => {
        console.error("Error updating Enable/Disable:", error);
        if (error === "Internal Server Error: Server error occurred.") {
          handleError5xx();
          setTryAgain(() => () => handleDriverReqEnableDisableChange(admin, newValue));
        }
      });
  };


  const emailStyle = {
    color: "#696969",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "24px",
    letterSpacing: "0.17px",
  };

  const style = {
    color: "#696969",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "24px" /* 150% */,
    letterSpacing: "0.17px",
    textTransform: "capitalize",
  };

  const fieldStyle = {
    "& label.Mui-focused": {
      color: "#172B4D !important",
      fontSize: "17px !important",
      fontWeight: "500 !important",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#BBC1CE",
        borderWidth: 1,
        borderRadius: "8px",
      },
      "&:hover fieldset": {
        borderColor: "#BBC1CE",
        borderWidth: 1,
        borderRadius: "8px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#BBC1CE",
        borderWidth: 1,
        borderRadius: "8px",
      },
      input: {
        "&::placeholder": {
          color: "#3A3541DE",
          opacity: 1,
          fontSize: 14,
        },
      },
    },
  };

  const generateSlagetitle = (title) => {
    let words = title.split(' ');
    let slagetitleWords = words.map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
    return slagetitleWords.join('');
  }

  const checkAndGenerateKey = (title) => {
    let newKey = generateSlagetitle(title);
    return newKey;
  }


  const handleSave = async () => {

    if (actionType === "add") {

      if (
        dataToAdd.title &&
        dataToAdd.description
      ) {


        checkAndGenerateKey(dataToAdd.title)
        setFullData(false)
        setEnabled(true)



        const tokenId = await auth?.currentUser?.getIdToken();
        const headers = {
          Authorization: tokenId,
        };

        postMethod({
          url: `driver/document/driver-document`,
          body: dataToAdd,
          headers: headers,
          handleErrorMessage: showSnackbar,
        })
          .then((response) => {
            if (response) {
              handleClose();
              setDataToAdd({
                title: "",
                key: "",
                type: "Image",
                size: "25000",
                description: "",
                enable: ""
              })
              fetchDrivers()
            } else {
              console.log("Responce Error");
            }

            fetchDrivers()
          })
          .catch((error) => {
            console.error("Error fetching more stores: name already used ", error);
            setOpen(true);
            setUniqueKey(true)
            setLoading(false);
            if (error === "Internal Server Error: Server error occurred.") {
              handleError5xx();
              setTryAgain(() => handleSave);
            }
          });

      } else {
        setFullData(true)
      }


    } else if (actionType === "edit") {

      if (
        dataToEdit.title &&
        dataToEdit.description
      ) {

        checkAndGenerateKey(dataToEdit.title)
        setFullData(false)
        setEnabled(true)

        setDataToEdit({ ...dataToEdit, enable: enabled });
        setEnabled(true)
        handleClose();

        putMethod({
          url: `driver/document/driver-document/${dataToEdit?._id}`,
          body: dataToEdit,
          handleErrorMessage: showSnackbar,
        })
          .then((response) => {
            fetchDrivers()
          })
          .catch((error) => {
            console.error("Error updating Enable/Disable:", error);
            if (error === "Internal Server Error: Server error occurred.") {
              handleError5xx();
              setTryAgain(() => handleSave);
            }
          });


      } else {
        setFullData(true)
      }
    }
  };


  const fetchAllDrivers = async () => {

    setLoading(true);
    await getMethod({
      url: "driver/getDrivers",
      handleErrorMessage: showSnackbar,
    })
      .then((response) => {
        setLoading(false);
        // console.log("api res", response?.data);
        setAllDriversData(response?.data);
        dispatch(setAllDriversList(response?.data));
      })
      .catch((err) => {
        console.error("Error fetching all drivers:", err);
        setLoading(false);
        if (err === "Internal Server Error: Server error occurred.") {
          handleError5xx();
          setTryAgain(() => fetchAllDrivers);
        }
      });

  };


  const handleExportClick = () => {
    if (activeButton === "activeDrivers") {
      fetchAllDrivers();

      const allDrivers = allDriversData.map(({ short_id, first_name, last_name, phone_number, email, review_info, status, enabled }) =>
        ({ short_id, first_name, last_name, phone_number: NewFormatPhoneNumber(phone_number, countryCode), email, total_avg: review_info?.total_avg, status, enabled }));

      // Convert the data to a worksheet
      const worksheet = XLSX.utils.json_to_sheet(allDrivers);
      // handle columnWidths
      const columnWidths = Object.keys(allDrivers[0]).map(key => {
        return Math.max(
          key.length,// Length of the header
          ...allDrivers.map(driver => driver[key] ? driver[key].toString().length : 10)
        ) + 2;
      }).map(width => ({ wch: width }));
      // Set the column widths in the worksheet
      worksheet['!cols'] = columnWidths;

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Drivers_Data");

      XLSX.writeFile(workbook, "selected_data.xlsx");

    }

  };

  const handleClickOpen = () => {
    setDataToAdd({ ...dataToAdd, enable: enabled });
    setActionType("add")
    setOpen(true);
  };


  const handleEditClickOpen = (data) => {
    setDataToEdit(data)
    setEnabled(data.enable)
    setActionType("edit")
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenUnblockDialog(false)
    setActionType("add")
    setUniqueKey(false)
    setDataToAdd({
      title: "",
      key: "",
      type: "Image",
      size: "25000",
      description: "",
      enable: ""
    })
    setFullData(false)
  };

  const handleChange = (prop) => (event) => {
    setDataToAdd((prevData) => ({ ...prevData, [prop]: event.target.value }));
    if (prop === "title") {
      setDataToAdd(prevData => {
        const updatedData = { ...prevData, key: checkAndGenerateKey(event.target.value) };
        return updatedData;
      });
    }
  };



  const handleEditChange = (prop) => (event) => {
    setDataToEdit((prevData) => ({ ...prevData, [prop]: event.target.value }));
  };


  const calculateTime = (hoursToAdd) => {
    const now = new Date();
    now.setHours(now.getHours() + hoursToAdd);
    return now.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
  };


  let columns;
  if (activeButton === "activeDrivers") {
    columns = [
      {
        field: "short_id",
        headerName: "Driver ID",
        flex: 1,
        minWidth: 100,
        editable: true,
        align: "left",
        headerClassName: "super-app-theme--header",
        renderCell: (params) => (
          <span
            style={{ textDecoration: "underline", fontWeight: "bold", cursor: "pointer" }}
            onClick={() => { navigate(`/drivers/details/${params.row._id}`); dispatch(setSelectedUser(params.row)); }}
          >
            {params?.row?.short_id}
          </span>
        ),
      },
      {
        field: "full_name",
        headerName: "Name",
        flex: 1,
        minWidth: "190px",
        style: style,
        align: "left",
        headerClassName: "super-app-theme--header",
        renderCell: (params) => (
          <Box style={{ display: "flex", alignItems: "center", gap: "12px", textDecoration: "underline", fontWeight: "bold", cursor: "pointer" }}
            onClick={() => { navigate(`/drivers/details/${params.row._id}`); dispatch(setSelectedUser(params.row)); }}

          >
            <img
              style={{
                width: "34px",
                height: "34px",
                borderRadius: "50%",
              }}
              src={params.row.profile_picture ? params.row.profile_picture : "https://i.pinimg.com/736x/de/59/4e/de594ec09881da3fa66d98384a3c72ff.jpg"}
              alt=""
            />
            <span>{params.row.first_name + " " + params.row.last_name}</span>
          </Box>
        ),
      },
      {
        field: "phone_number",
        headerName: "Phone",
        minWidth: "150px",
        flex: 1,
        style: style,
        align: "left",
        renderCell: (params) => (
          <Typography>
            {NewFormatPhoneNumber(params.row.phone_number, countryCode)}
          </Typography>
        ),
        headerClassName: "super-app-theme--header",
      },
      {
        field: "creation_date",
        headerName: "Creation Date",
        minWidth: "200px",
        flex: 1,
        style: style,
        align: "left",
        headerClassName: "super-app-theme--header",
        renderCell: (params) => {
          const dateObject = new Date(params?.row?.createdAt);
          const formattedDate = formatDate(dateObject, countryCode);
          return (
            <span>
              {formattedDate}
            </span>
          );
        },
      },
      {
        field: "avgRating",
        headerName: "Rating",
        flex: 1,
        minWidth: "50px",
        editable: true,
        style: style,
        align: "left",
        headerClassName: "super-app-theme--header",
        renderCell: (params) => (
          <Box display="flex" justifyContent="center" mt={1}>
            {renderStars(params.row.review_info?.total_avg)}
          </Box>
        ),
      },
      {
        field: "status",
        headerName: "Status",
        flex: 1,
        minWidth: "50px",
        editable: true,
        style: style,
        align: "center",
        headerClassName: "super-app-theme--header",
        renderCell: (params) => <Status status={params.row.status} />,
      },
      {
        field: "enabled",
        headerName: "Enable/Disable",
        headerClassName: "super-app-theme--header",
        width: 150,
        renderCell: (params) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Switch
              checked={params.row.enabled}
              onChange={(value) =>
                handleEnableDisableChange(params.row, value)
              }
            />
          </div>
        ),
      },
      {
        field: "action",
        headerName: "Action",
        style: style,
        headerClassName: "super-app-theme--header",
        renderCell: (params) => (
          <Box
            style={{
              width: "30px",
              height: "30px",
              backgroundColor: "#F3F8FD",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => { navigate(`/drivers/details/${params.row._id}`); dispatch(setSelectedUser(params.row)); }}
          >
            <AiFillEdit size="20px" style={{ color: "#5B93FF" }} />
          </Box>
        ),
      },
    ];
  } else if (activeButton === "DriverRequest") {
    columns = [
      {
        field: "name",
        headerName: "Name",
        flex: 1,
        minWidth: "20px",
        editable: true,
        style: { ...style, color: "#172B4D", fontWeight: "600", },
        align: "left",
        headerClassName: "super-app-theme--header",
        renderCell: (params) => (
          <Box
            onClick={() => navigate(`/driversRequests/details/${params.row._id}`)}
            style={{ display: "flex", alignItems: "center", gap: "12px", textDecoration: "underline", cursor: "pointer" }}>
            <img
              style={{
                width: "34px",
                height: "34px",
                borderRadius: "50%",
              }}
              src="https://i.pinimg.com/736x/de/59/4e/de594ec09881da3fa66d98384a3c72ff.jpg"
              alt=""
            />
            <span>{params.row.first_name + " " + params.row.last_name}</span>
          </Box>
        ),
      },
      {
        field: "email",
        headerName: "Email",
        minWidth: "150px",
        flex: 1,
        style: emailStyle,
        align: "left",
        headerClassName: "super-app-theme--header",
      },
      {
        field: "dateOfBirth",
        headerName: "Date Of Birth",
        minWidth: "150px",
        flex: 1,
        style: style,
        align: "left",
        headerClassName: "super-app-theme--header",
        renderCell: (params) => {
          const dateObject = new Date(params?.row?.dateOfBirth);

          // Formatting the date to YYYY-MM-DD format
          const year = dateObject.getFullYear();
          const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Months are zero-based
          const day = String(dateObject.getDate()).padStart(2, '0');

          // Combining the date parts
          const formattedDate = `${year}-${month}-${day}`;

          return <Typography>{formattedDate != 'NaN-NaN-NaN' ? formattedDate : '-'}</Typography>;
        }

      },
      {
        field: "vehicle_info",
        headerName: "Vehicle Type",
        minWidth: "100px",
        flex: 1,
        style: style,
        align: "center",
        headerClassName: "super-app-theme--header",
        renderCell: (params) => (
          <Typography>{params?.row?.vehicle_info?.vehicle_type}</Typography>
        ),
      },
      {
        field: "status",
        headerName: "Status",
        flex: 1,
        minWidth: "50px",
        editable: true,
        style: style,
        align: "center",
        headerClassName: "super-app-theme--header",
        renderCell: (params) => {
          const incomplete =
            !params?.row?.first_name || params?.row?.first_name === '' ||
            !params?.row?.email || params?.row?.email === '' ||
            !params?.row?.profile_picture || params?.row?.profile_picture === '' ||
            !params?.row?.driver_license_info?.license_number || params?.row?.driver_license_info?.license_number === '' ||
            !params?.row?.vehicle_info?.vehicle_type || params?.row?.vehicle_info?.vehicle_type === '' ||
            !params?.row?.car_type || params?.row?.car_type?.length === 0;

          return !incomplete ? (
            <Status status={params.row.account_status} />
          ) : (
            <Status status={"incomplete"} />
          );
        },
      },
      {
        field: "action",
        headerName: "Action",
        style: style,
        align: "center",
        headerClassName: "super-app-theme--header",
        renderCell: (params) => {
          const incomplete =
            !params?.row?.first_name || params?.row?.first_name === '' ||
            !params?.row?.email || params?.row?.email === '' ||
            !params?.row?.profile_picture || params?.row?.profile_picture === '' ||
            !params?.row?.driver_license_info?.license_number || params?.row?.driver_license_info?.license_number === '' ||
            !params?.row?.vehicle_info?.vehicle_type || params?.row?.vehicle_info?.vehicle_type === '' ||
            !params?.row?.car_type || params?.row?.car_type?.length === 0;

          return (
            <Box
              style={{
                width: "30px",
                height: "30px",
                margin: '0 auto',
                backgroundColor: "#F3F8FD",
                borderRadius: "50%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => navigate(`/driversRequests/details/${params.row._id}`)}
            >
              <AiFillEdit size="20px" style={{ color: "#5B93FF" }} />
            </Box>
          )
        },
      }
    ];
  } else if (activeButton === "DriverRequirements") {
    columns = [

      {
        field: "Requirement_name",
        headerName: "Name",
        flex: 1,
        minWidth: "150px",
        style: style,
        align: "left",
        headerClassName: "super-app-theme--header",
        renderCell: (params) => (
          <Box style={{
            display: "flex", alignItems: "center", gap: "12px",
            fontWeight: "700",
            fontFamily: "poppins",
            fontSize: "14px",
            lineHeight: "16px",
            color: "#172B4D",
            textDecoration: "underline",

          }}>
            <span>{params.row.title}</span>
          </Box>
        ),
      },
      {
        field: "Description",
        headerName: "Description",
        flex: 1,
        minWidth: "150px",
        style: style,
        align: "left",
        headerClassName: "super-app-theme--header",
        renderCell: (params) => (
          <Box style={{
            display: "flex", alignItems: "center", gap: "12px",
            fontWeight: "500",
            fontFamily: "poppins",
            fontSize: "14px",
            lineHeight: "18px",
            color: "#495974",
          }}>
            <span>{params.row.description}</span>
          </Box>
        ),
      },
      // {
      //   field: "Type",
      //   headerName: "Type",
      //   flex: 1,
      //   minWidth: "150px",
      //   style: style,
      //   align: "left",
      //   headerClassName: "super-app-theme--header",
      //   renderCell: (params) => (
      //     <Box style={{
      //       display: "flex", alignItems: "center", gap: "12px",
      //       fontWeight: "500",
      //       fontFamily: "poppins",
      //       fontSize: "14px",
      //       lineHeight: "18px",
      //       color: "#495974",
      //     }}>
      //       <span>{params.row.type}</span>
      //     </Box>
      //   ),
      // },
      {
        field: "size",
        headerName: "Size",
        flex: 1,
        minWidth: "150px",
        style: style,
        align: "left",
        headerClassName: "super-app-theme--header",
        renderCell: (params) => (
          <Box style={{
            display: "flex", alignItems: "center", gap: "12px",
            fontWeight: "500",
            fontFamily: "poppins",
            fontSize: "14px",
            lineHeight: "18px",
            color: "#495974",
          }}>
            <span>{params.row.size}</span>
          </Box>
        ),
      },

      {
        field: "enabled",
        headerName: "Enable/Disable",
        headerClassName: "super-app-theme--header",
        width: 150,
        renderCell: (params) => (
          <div
            style={{
              display: "flex",
            }}>
            <Switch
              checked={params.row.enable}
              onChange={(value) =>
                handleDriverReqEnableDisableChange(params.row, value)
              }
            />
          </div>
        ),
      },
      {
        field: "action",
        headerName: "Action",
        style: style,
        headerClassName: "super-app-theme--header",
        renderCell: (params) => (
          <Box
            style={{
              width: "30px",
              height: "30px",
              backgroundColor: "#F3F8FD",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() =>
              handleEditClickOpen(params.row)
            }
          >
            <AiFillEdit size="20px" style={{ color: "#5B93FF" }} />
          </Box>
        ),
      },
    ];
  } else if (activeButton === "blockedDrivers") {
    columns = [
      {
        field: "short_id",
        headerName: "Driver ID",
        flex: 1,
        minWidth: "100px",
        editable: true,
        style: style,
        align: "left",
        headerClassName: "super-app-theme--header",
      },
      {
        field: "full_name",
        headerName: "Name",
        flex: 1,
        minWidth: "150px",
        style: style,
        align: "left",
        headerClassName: "super-app-theme--header",
        renderCell: (params) => (
          <Box style={{ display: "flex", alignItems: "center", gap: "12px" }}>
            <img
              style={{
                width: "34px",
                height: "34px",
                borderRadius: "50%",
              }}
              src={params.row.profile_picture ? params.row.profile_picture : "https://i.pinimg.com/736x/de/59/4e/de594ec09881da3fa66d98384a3c72ff.jpg"}

              alt=""
            />
            <span>{params.row.first_name + " " + params.row.last_name}</span>
          </Box>
        ),
      },
      {
        field: "phone_number",
        headerName: "Phone",
        minWidth: "160px",
        flex: 1,
        style: style,
        align: "left",
        renderCell: (params) => (
          <Typography>
            {NewFormatPhoneNumber(params.row.phone_number, countryCode)}
          </Typography>
        ),
        headerClassName: "super-app-theme--header",
      },
      // {
      //   field: "creation_date",
      //   headerName: "Creation Date",
      //   minWidth: "200px",
      //   flex: 1,
      //   style: style,
      //   align: "left",
      //   headerClassName: "super-app-theme--header",
      //   renderCell: (params) => {
      //     const dateObject = new Date(params?.row?.createdAt);
      //     const formattedDate = formatDate(dateObject, countryCode);
      //     return (
      //       <span>
      //         {formattedDate}
      //       </span>
      //     );
      //   },
      // },
      {
        field: "avgRating",
        headerName: "Rating",
        flex: 1,
        minWidth: "50px",
        editable: true,
        style: style,
        align: "left",
        headerClassName: "super-app-theme--header",
        renderCell: (params) => (
          <Box display="flex" justifyContent="center" mt={1}>
            {renderStars(params.row.review_info?.total_avg)}
          </Box>
        ),
      },
      {
        field: "reason",
        headerName: "Reason",
        minWidth: "100px",
        flex: 1,
        style: style,
        align: "center",
        overflow: "hidden",
        textOverflow: "ellipsis",
        headerClassName: "super-app-theme--header",
        renderCell: (params) => (<Reason reason={params.row.wallet_balance < 0 ? "wallet-balance" : "cancellation-limit"} />),
      },
      {
        field: "debt",
        headerName: "Dept",
        flex: 1,
        minWidth: "20px",
        editable: true,
        style: style,
        renderCell: (params) => (
          <Box display="flex" justifyContent="center" mt={1} style={{
            color: "rgba(73, 89, 116, 1)",
            fontSize: "14px",
            fontWeight: 700,
            lineHeight: "18px",
            fontFamily: "Poppins",
          }}>
            {params.row.wallet_balance < 0 ? "$" + params.row.wallet_balance * -1 : "-"}
          </Box>
        ),
      },
      {
        field: "status",
        headerName: "Status",
        flex: 1,
        minWidth: "50px",
        editable: true,
        style: style,
        align: "center",
        headerClassName: "super-app-theme--header",
        renderCell: (params) => <div
          onClick={() => {
            setDuration(8)
            setUnBlockedDriver(params.row)
            setOpenUnblockDialog(true);
          }} style={{
            cursor: 'pointer',
          }}>
          <Status
            status={"blocked"}
          />
        </div>
      },
      {
        field: "action",
        headerName: "Action",
        style: style,
        headerClassName: "super-app-theme--header",
        renderCell: (params) => (
          <Box
            style={{
              width: "30px",
              height: "30px",
              backgroundColor: "#F3F8FD",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => {
              navigate(`/drivers/details/${params.row._id}`);
              dispatch(setSelectedUser(params.row));
            }}
          >
            <AiFillEdit size="20px" style={{ color: "#5B93FF" }} />
          </Box>
        ),
      },

    ];
  }




  return (
    <>
      {/* unblock dialog */}
      <Dialog open={openUnblockDialog} onClose={handleClose} fullWidth maxWidth="xs">
        <DialogContent sx={{ padding: "30px" }}>
          <Box display="flex" justifyContent="space-between" mb={2}>
            <span
              style={{
                fontFamily: "poppins",
                fontWeight: "700",
                fontSize: "18px",
                lineHeight: "27px",
                color: "#172B4D",
              }}
            >
              Unblock driver account
            </span>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              mb: 2,
              width: "100%",
            }}
          >
            <span
              style={{
                fontFamily: "poppins",
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "26px",
                color: "#7A869A",
              }}
            >
              Select duration for temporarily unblocking driver.
            </span>
            <ErrorIcon
              style={{
                fontSize: "20px",
                color: "#7A869A",
              }}
            />
          </Box>
          {/* duration */}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="duration"
                  name="duration"
                  value={duration}
                  onChange={(e) => { setDuration(e.target.value); }}
                >
                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label={`2 hours until ${calculateTime(2)}`}
                  />
                  <FormControlLabel
                    value="4"
                    control={<Radio />}
                    label={`4 hours until ${calculateTime(4)}`}
                  />
                  <FormControlLabel
                    value="8"
                    control={<Radio />}
                    label={`8 hours until ${calculateTime(8)}`}
                  />
                  <FormControlLabel
                    value={"EOD"}
                    control={<Radio />}
                    label="End of day until 11:59 PM"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            marginBottom: "20px",
            paddingLeft: "20px",
            paddingRight: "20px",
            width: "100%",
          }}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: 'white',
              color: colorConfigs?.sidebar?.bg,
              borderRadius: '10px',
              width: "45%",
              padding: '10px 20px',
              "&:hover": {
                backgroundColor: "lightgray",
              },
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            sx={{
              backgroundColor: colorConfigs?.sidebar?.bg || "#005e8b",
              color: "white",
              borderRadius: "10px",
              width: "45%",
              padding: "10px 20px",
              "&:hover": {
                backgroundColor: "#004b6e",
              },
            }}
            onClick={handleConfirmUnblock}
          >
            Confirm Unblock
          </Button>
        </DialogActions>
      </Dialog>
      {/* handleError5xx */}
      < Error5xx open={isErrorDialogOpen} errCount={errorCount} count={errorDialogCounter} handleClose={handleCloseErrorDialog} tryAgain={tryAgain} />
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogContent>
          {/* Align Enable/Disable Switch */}
          <FormControl component="fieldset" fullWidth margin="dense">
            <Box display="flex" style={{ justifyContent: "space-between" }}>
              <span style={{
                fontFamily: "poppins",
                fontWeight: "700",
                fontSize: "18px",
                lineHeight: "27px",
                color: "#172B4D"
              }}>   {actionType === 'add' ? 'Add Driver Requirements' : 'Edit Driver Requirements'}</span>
              <Box>
                <SubmitButton
                  variant="outlined"
                  onClick={() => {
                    setEnabled(true);
                    setDataToAdd({ ...dataToAdd, enable: true })
                    setDataToEdit({ ...dataToEdit, enable: true })
                  }}
                  style={{
                    borderTopLeftRadius: "88px",
                    borderBottomLeftRadius: "88px",
                    borderRight: "1px solid black",
                    borderColor: colorConfigs?.sidebar?.bg,
                    color: enabled ? "white" : colorConfigs?.sidebar?.bg,
                    backgroundColor: enabled
                      ? colorConfigs?.sidebar?.bg
                      : "white",
                  }}
                  label={"Enable"}
                />
                <SubmitButton
                  variant="outlined"
                  onClick={() => {
                    setEnabled(false);
                    setDataToAdd({ ...dataToAdd, enable: false })
                    setDataToEdit({ ...dataToEdit, enable: false })
                  }}
                  style={{
                    borderTopRightRadius: "88px",
                    borderBottomRightRadius: "88px",
                    borderColor: colorConfigs?.sidebar?.bg,
                    color: !enabled ? "white" : "#42526E",
                    backgroundColor: !enabled
                      ? colorConfigs?.sidebar?.bg
                      : "white",
                  }}
                  label={"Disable"}
                />
              </Box>
            </Box>
          </FormControl>

          {/* Input Fields with Consistent Layout */}
          <Grid container spacing={2} >
            <Grid item xs={12}>
              <TextInput
                margin="dense"
                styles={fieldStyle}
                fullWidth
                label={"Name"}
                name="name"
                onChange={actionType === 'edit' ? handleEditChange('title') : handleChange('title')}
                required
                value={actionType === 'edit' ? dataToEdit.title : dataToAdd.title}
                variant="outlined"
                placeholder="Please enter name"
                filled="true"
                focused
              />

            </Grid>
            <Grid item xs={12}>
              <TextInput
                margin="dense"
                styles={fieldStyle}
                fullWidth
                label={"Description"}
                name="description"
                onChange={actionType === 'edit' ? handleEditChange('description') : handleChange('description')}
                required
                value={actionType === 'edit' ? dataToEdit.description : dataToAdd.description}
                variant="outlined"
                placeholder="Please describe the new requirement"
                filled="true"
                focused
                multiline
                style={{ height: '100px' }}
                InputProps={{
                  style: {
                    height: '100px',
                    whiteSpace: 'pre-wrap',
                    wordWrap: 'break-word',
                  },
                }}

              />
            </Grid>
          </Grid>
        </DialogContent>

        {/* Dialog Actions with Proper Button Placement */}
        {fullData && (
          <span style={{ display: "flex", color: "red", marginLeft: "25px" }}>
            Please fill in all the data
          </span>
        )}
        {uniqueKey && !fullData && actionType === "add" && (
          <span style={{ display: "flex", color: "red", marginLeft: "25px" }}>
            Name Already Used
          </span>
        )}
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            marginBottom: "20px",
            paddingLeft: "20px",
            paddingRight: "20px",
            width: "60%"
          }}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: 'white',
              color: colorConfigs?.sidebar?.bg,
              borderRadius: '10px',
              width: "100%",
              padding: '10px 20px',
              "&:hover": {
                backgroundColor: "lightgray",
              },
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            sx={{
              backgroundColor: process.env.REACT_APP_ENV === 'women_first' ? colorConfigs?.sidebar?.bg : '#005e8b',
              color: 'white',
              borderRadius: '10px',
              width: "100%",
              padding: '10px 20px',
              '&:hover': {
                backgroundColor: process.env.REACT_APP_ENV === 'women_first' ? colorConfigs?.sidebar?.bg : '#004b6e',
              },
            }}
            onClick={handleSave}
          >
            Save
          </Button>
        </DialogActions>

      </Dialog>


      <div>
        <Grid
          container
          spacing={1}
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: "10px",
            paddingTop: "18px",
          }}
        >
          <Grid
            item
            xs={!licAndConfig?.licenses?.driver_additional_doc ? 12 : 12}
            sm={!licAndConfig?.licenses?.driver_additional_doc ? 12 : 12}
            md={!licAndConfig?.licenses?.driver_additional_doc ? 12 : 12}
            lg={!licAndConfig?.licenses?.driver_additional_doc ? 6 : activeButton === "blockedDrivers" ? 7.5 : 7.3}
            xl={!licAndConfig?.licenses?.driver_additional_doc ? 5 : 6}
            style={{
              width: !licAndConfig?.licenses?.driver_additional_doc ? "35%" : "",
              border: `1.5px solid ${colorConfigs?.sidebar?.bg}`,
              height: "50px",
              borderRadius: "8px",
              marginTop: "8px",
              paddingBottom: "11px",
              paddingTop: "10px",
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
            sx={{
              marginBottom: { lg: "0", xs: "20px", sm: "20px" },
            }}
          >
            <Grid item xs={!licAndConfig?.licenses?.driver_additional_doc ? 4 : 3} style={{ display: "flex", justifyContent: "center", minWidth: "75px" }}>
              <Button
                variant={
                  activeButton === "activeDrivers" ? "contained" : "text"
                }
                style={{
                  color:
                    activeButton === "activeDrivers"
                      ? "white"
                      : colorConfigs?.sidebar?.bg,
                  backgroundColor:
                    activeButton === "activeDrivers"
                      ? colorConfigs?.sidebar?.bg
                      : "transparent",
                  fontWeight:
                    activeButton === "activeDrivers" ? "bold" : "normal",
                }}
                onClick={() => handleButtonClick("activeDrivers")}
              >
                Drivers
              </Button>
            </Grid>
            <Grid item xs={!licAndConfig?.licenses?.driver_additional_doc ? 4 : 3} style={{ display: "flex", justifyContent: "center", minWidth: "160px" }}>
              <Button
                variant={
                  activeButton === "blockedDrivers" ? "contained" : "text"
                }
                style={{
                  color:
                    activeButton === "blockedDrivers"
                      ? "white"
                      : colorConfigs?.sidebar?.bg,
                  backgroundColor:
                    activeButton === "blockedDrivers"
                      ? colorConfigs?.sidebar?.bg
                      : "transparent",
                  fontWeight:
                    activeButton === "blockedDrivers" ? "bold" : "normal",
                }}
                onClick={() => handleButtonClick("blockedDrivers")}
              >
                Blocked Drivers
              </Button>
            </Grid>

            <Grid item xs={!licAndConfig?.licenses?.driver_additional_doc ? 4 : 3} style={{ display: "flex", justifyContent: "center", minWidth: "75px" }}>
              <Button
                variant={
                  activeButton === "DriverRequest" ? "contained" : "text"
                }
                style={{
                  color:
                    activeButton === "DriverRequest"
                      ? "white"
                      : colorConfigs?.sidebar?.bg,
                  backgroundColor:
                    activeButton === "DriverRequest"
                      ? colorConfigs?.sidebar?.bg
                      : "transparent",
                  fontWeight: activeButton === "DriverRequest" ? "bold" : "normal",

                  whiteSpace: "normal",
                  lineHeight: 2,
                }}
                onClick={() => handleButtonClick("DriverRequest")}
              >
                Requests
              </Button>
            </Grid>
            {licAndConfig?.licenses?.driver_additional_doc && (

              <Grid item xs={6.5} style={{ display: "flex", justifyContent: "center", minWidth: "195px" }}>
                <Button
                  variant={
                    activeButton === "DriverRequirements" ? "contained" : "text"
                  }
                  style={{
                    color:
                      activeButton === "DriverRequirements"
                        ? "white"
                        : colorConfigs?.sidebar?.bg,
                    backgroundColor:
                      activeButton === "DriverRequirements"
                        ? colorConfigs?.sidebar?.bg
                        : "transparent",
                    fontWeight: activeButton === "DriverRequirements" ? "bold" : "normal",
                    whiteSpace: "normal",
                    lineHeight: 2,
                  }}
                  onClick={() => handleButtonClick("DriverRequirements")}
                >
                  Driver Requirements
                </Button>
              </Grid>
            )}
          </Grid>

          {activeButton !== "DriverRequirements" ? (
            <Grid
              item
              xs={!licAndConfig?.licenses?.driver_additional_doc ? 12 : 12}
              sm={!licAndConfig?.licenses?.driver_additional_doc ? 12 : 12}
              md={!licAndConfig?.licenses?.driver_additional_doc ? 12 : 12}
              lg={!licAndConfig?.licenses?.driver_additional_doc ? 6 : activeButton === "blockedDrivers" ? 4.5 : 4.7}
              xl={!licAndConfig?.licenses?.driver_additional_doc ? 7 : 6}
              style={{
                display: "flex",
                justifyContent: "end",
                gap: "14px",
                marginBottom: "14px",
                width: "100%",

              }}
            >
              <Grid item xs={7} sm={7} md={5} lg={activeButton === "blockedDrivers" ? 7 : 7} xl={6}>
                <TextField
                  fullWidth
                  label="Search by"
                  placeholder="Name, ID, Phone, or Email."
                  name="keyword"
                  value={keyword}
                  onChange={handleSearch}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ color: "gray" }} />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  sx={{
                    "& label.Mui-focused": { color: "rgba(0, 0, 0, 0.6)" },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#A0AEC0",
                      },
                      borderRadius: "10px",
                      height: "52px",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={4} sm={4}
                md={activeButton === "blockedDrivers" ? 4.5 : 3}
                lg={activeButton === "blockedDrivers" ? 7 : 4.5}
                xl={activeButton === "blockedDrivers" ? 4.5 : 3}
              >
                <Box sx={{ minWidth: activeButton === "blockedDrivers" ? 195 : 120 }}>
                  <FormControl fullWidth style={{ marginTop: "2px" }} sx={{
                    "& label.Mui-focused": {
                      color: "rgba(0, 0, 0, 0.6)",
                      bordercolor: "#BBC1CE",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#BBC1CE",
                      },
                      "&:hover fieldset": {
                        borderColor: "#BBC1CE",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#BBC1CE",
                        borderWidth: "1px",
                      },
                    },
                  }}>
                    <InputLabel id="demo-simple-select-label">{activeButton === "blockedDrivers" ? "Reason" : "Status"}</InputLabel>
                    {activeButton === "activeDrivers" ? (
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={status}
                        label="Status"
                        onChange={handleStatusChange}
                        style={{ borderRadius: "10px", height: "50px" }}
                        MenuProps={{
                          disableScrollLock: true,
                        }}
                      >
                        <MenuItem value={"all"}>All</MenuItem>
                        <MenuItem value={"offline"}>Offline</MenuItem>
                        <MenuItem value={"onJob"}>On Job</MenuItem>
                        <MenuItem value={"available"}>Available</MenuItem>
                      </Select>
                    ) : activeButton === "blockedDrivers" ? (
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={status}
                        label="Reason"
                        onChange={handleStatusChange}
                        style={{ borderRadius: "10px", height: "50px" }}
                        MenuProps={{
                          disableScrollLock: true,
                        }}
                      >
                        <MenuItem value={"all"}>All</MenuItem>
                        <MenuItem value={"wallet-balance"}>Wallet Balance</MenuItem>
                        <MenuItem value={"cancellation-limit"}>Cancellation Limit</MenuItem>
                      </Select>
                    ) : (
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={status}
                        label="Status"
                        onChange={handleStatusChange}
                        style={{ borderRadius: "10px", height: "50px" }}
                        MenuProps={{
                          disableScrollLock: true,
                        }}
                      >
                        <MenuItem value={"all"}>All</MenuItem>
                        <MenuItem value={"pending"}>Pending</MenuItem>
                        <MenuItem value={"reject"}>Rejected</MenuItem>
                      </Select>
                    )}
                  </FormControl>
                </Box>
              </Grid>
              {activeButton === "activeDrivers" && allDriversData && (
                <Button
                  variant="outlined"
                  style={{
                    borderRadius: "10px",
                    borderColor: '#cfd3d9',
                    color: "#7A869A",
                    fontWeight: '400',
                    height: "50px",
                    fontSize: "14px",
                    fontFamily: "Poppins",
                    lineHeight: "21px",
                    minWidth: "80px",
                    marginTop: "2px",
                    textTransform: 'none'
                  }}
                  onClick={handleExportClick}
                >
                  <div style={{ display: "flex", justifyContent: "center", width: "40%", height: '40%', marginRight: "3px" }}>
                    <img src={SendIcon} alt="" />
                  </div>
                  Export
                </Button>
              )}
            </Grid>
          ) : (
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={6}
              style={{
                display: "flex",
                justifyContent: "end",
                gap: "14px",
                marginBottom: "14px",
                width: "100%",
              }}
            >
              <Grid item xs={8} sm={8} md={6} lg={6} xl={6} style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: process.env.REACT_APP_ENV === 'women_first' ? colorConfigs?.sidebar?.bg : '#005e8b',
                    color: 'white',
                    minWidth: "max-content",
                    marginBottom: "14px",
                    marginTop: "6px",
                    borderRadius: "20px",
                    fontWeight: "bold",
                    '&:hover': {
                      backgroundColor: '#FFFFFF',
                      color: colorConfigs?.sidebar?.bg,

                    },
                  }}

                  onClick={handleClickOpen}
                >
                  +  Add New Driver Requirements
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </div>

      {
        activeButton === "DriverRequest" && (
          <DynamicTable
            columns={columns}
            data={dataDriverRequest}
            count={count}
            page={page}
            rowsLimits={rowsLimits}
            pageChange={handlePageChange}
            rowsChange={handleRowsChange}
            pageType="drivers"
          />
        )
      }
      {
        activeButton === "activeDrivers" && (
          <DynamicTable
            columns={columns}
            data={data}
            count={count}
            page={page}
            rowsLimits={rowsLimits}
            pageChange={handlePageChange}
            rowsChange={handleRowsChange}
            pageType="drivers"
          />
        )
      }
      {
        activeButton === "blockedDrivers" && (
          <DynamicTable
            columns={columns}
            data={blockedDrivers}
            count={countBlockedDeivers}
            page={page}
            rowsLimits={rowsLimits}
            pageChange={handlePageChange}
            rowsChange={handleRowsChange}
            pageType="drivers"
          />
        )
      }
      {
        activeButton === "DriverRequirements" && (
          <DynamicTable
            columns={columns}
            data={dataDriverRequirements}
            count={countDeiverReq}
            page={page}
            rowsLimits={rowsLimits}
            pageChange={handlePageChange}
            rowsChange={handleRowsChange}
            pageType="DriverRequirements"
          />
        )
      }


      <Loading
        loading={loading && (driversList?.length === 0 && driversReqList?.length === 0)}
        background="rgba(86, 100, 210, 0.1)"
        loaderColor={colorConfigs.sidebar?.bg}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleSnackbarClose} severity="error">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
