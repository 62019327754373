import { Box, Button, Grid, Typography, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import React from 'react';
import { FaPhone } from "react-icons/fa6";
import { useSelector } from 'react-redux';
import icon from "../../../assets/images/Icon.png";
import location from "../../../assets/images/location.png";
import amex from "../../../assets/paymentMethods/Amex.png";
import discover from "../../../assets/paymentMethods/diccover.png";
import dinars from "../../../assets/paymentMethods/diners.png";
import geniric from "../../../assets/paymentMethods/geniric.png";
import jbc from "../../../assets/paymentMethods/jcb.png";
import mastercard from "../../../assets/paymentMethods/mastercard.png";
import unionpay from "../../../assets/paymentMethods/unionPay.png";
import visa from "../../../assets/paymentMethods/visa.png";
import { PaymentMethod } from '../../../components/common/PaymentMethod';
import colorConfigs from '../../../configs/colorConfigs';
import { NewFormatPhoneNumber } from '../../../utils/localization/NewPhoneUtils';


const Section = styled(Grid)(({ theme }) => ({
  backgroundColor: '#f5f7f8',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1),
}));

const InfoBox = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: 0,
}));

const AddressText = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontFamily: "Poppins",
  fontSize: 12,
}));

const TimeText = styled(Typography)(({ theme }) => ({
  color: "#7A869A",
  fontSize: 13,
  fontWeight: 500,
  fontFamily: "Poppins",
}));

const DotDividerContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '& div': {
    width: 4,
    height: 4,
    borderRadius: '50%',
    backgroundColor: '#E0E0E0',
    margin: '1px 0',
  },
}));

const Card = styled(Box)(({ theme }) => ({
  background: 'white',
  padding: theme.spacing(1),
  boxShadow: 'rgba(0, 0, 0, 0.05) 0 4px 36px',
  borderRadius: theme.shape.borderRadius,
  margin: theme.spacing(1, 0, 0, 0),
}));

const CardHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  background: '#F4F4F4',
  padding: theme.spacing(1, 2),
  borderRadius: theme.shape.borderRadius,
}));

const ContactInfo = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: theme.spacing(0.7),
  '& svg': {
    marginRight: theme.spacing(0.5),
    color: colorConfigs?.sidebar?.bg,
  },
}));


const BoldText = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  color: '#172B4D',
  fontSize: 14,
  textTransform: 'capitalize',
}));

const DotDivider = () => (
  <DotDividerContainer>
    <div></div>
    <div></div>
    <div></div>
  </DotDividerContainer>
);

const Summary = ({ isLoading, handleSubmit, backStep }) => {
  const addNewRide = useSelector((state) => state.appState.addNewRide);
  const cardInfo = useSelector((state) => state.appState.addNewRide.cardInfo);
  const theme = useTheme();
  const countryCode = useSelector((state) => state.country.selectedCountry);

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box>
      <Box sx={{ mt: 2 }} >
        <Card>
          <Section container>
            <InfoBox item xs={12} sm={3}>
              <Typography variant="body1" sx={{ color: '#6C757D', fontSize: 12 }}>
                <Typography component={'span'} sx={{ color: '#6C757D', fontSize: 12, fontWeight: 400 }}>Ride Type:</Typography>
                <BoldText component="span" ml={1}>{addNewRide?.selectedCarType?.type}</BoldText>
              </Typography>
            </InfoBox>
            <InfoBox item xs={12} sm={3} sx={{ ml: 3 }}>
              <Typography variant="body1" sx={{ color: '#6C757D', fontSize: 12 }}>
                <Typography component={'span'} sx={{ color: '#6C757D', fontSize: 12, fontWeight: 400 }}>Payment Type:</Typography>
                <BoldText component="span" ml={1}>{addNewRide?.isCash ? 'Cash' : 'Card'}</BoldText>
              </Typography>
            </InfoBox>
          </Section>
          <Box sx={{ mt: 2 }}>
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}>
              {!isSmallScreen && (
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mr: 2 }}>
                  <img src={location} alt="loc" />
                  <DotDivider />
                  <img src={icon} alt="loc" />
                </Box>
              )}
              <Box>
                <Box sx={{ mt: 1 }}>
                  <AddressText sx={{ color: colorConfigs?.sidebar?.bg }}>
                    Pick up
                  </AddressText>
                  <TimeText>
                    {addNewRide.address?.primary_line}
                  </TimeText>
                </Box>
                <Box sx={{ mt: 2 }}>
                  <AddressText sx={{ color: '#03992D' }}>
                    Drop off
                  </AddressText>
                  <TimeText>
                    {addNewRide.addressDrop?.primary_line}
                  </TimeText>
                </Box>
              </Box>
            </Box>
          </Box>
        </Card>
      </Box>


      <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'stretch' }}>
        <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column' }}>
          <Card sx={{ flexGrow: 1 }}>
            <CardHeader>
              <Typography sx={{ fontWeight: '800', fontSize: 14, color: '#172B4D' }}>
                Passenger Info
              </Typography>
            </CardHeader>
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1, py: 1 }}>
              <Box sx={{ ml: 1 }}>
                <BoldText mr={1}>
                  {addNewRide?.first_name} {" "} {addNewRide?.last_name}
                </BoldText>
                <ContactInfo sx variant="body2">
                  <FaPhone />
                  {NewFormatPhoneNumber(addNewRide?.phone_number, countryCode)}
                </ContactInfo>
              </Box>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column' }}>
          <Card sx={{ flexGrow: 1 }}>
            <CardHeader>
              <Typography sx={{ fontWeight: '800', fontSize: 14, color: '#172B4D' }}>
                Payment Details
              </Typography>
            </CardHeader>
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1, py: 1 }}>
              <Box sx={{ ml: 1 }}>
                {addNewRide?.isCash ?
                  <BoldText mr={1}>
                    Cash
                  </BoldText>
                  :
                  <BoldText mr={1}>
                    <PaymentMethod
                      img={
                        cardInfo?.brand === 'American Express'
                          ? amex
                          : cardInfo?.brand === 'Diners Club'
                            ? dinars
                            : cardInfo?.brand === 'Discover'
                              ? discover
                              : cardInfo?.brand === 'JCB'
                                ? jbc
                                : cardInfo?.brand === 'Visa'
                                  ? visa
                                  : cardInfo?.brand === 'MasterCard'
                                    ? mastercard
                                    : cardInfo?.brand === 'UnionPay'
                                      ? unionpay
                                      : geniric
                      }
                      text={`**** **** **** ${cardInfo?.last4}`}
                    />
                  </BoldText>
                }
              </Box>
            </Box>
          </Card>
        </Grid>
        {addNewRide?.note && addNewRide?.note?.length > 0 && <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column' }}>
          <Card sx={{ flexGrow: 1 }}>
            <CardHeader>
              <Typography sx={{ fontWeight: '800', fontSize: 14, color: '#172B4D' }}>
                Note
              </Typography>
            </CardHeader>
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1, py: 1 }}>
              <Box sx={{ ml: 1 }}>
                <BoldText>
                  {addNewRide?.note}
                </BoldText>
              </Box>
            </Box>
          </Card>
        </Grid>
        }
      </Grid>
      <Grid item md={12} xs={12} sx={{ display: "flex", justifyContent: "end", mt: 2 }}>

        <Button sx={{
          ml: 1,
          color: colorConfigs?.sidebar?.bg,
          border: `1px solid ${colorConfigs?.sidebar?.bg}`,
          px: 5,
          borderRadius: "5px",
          '&:hover': {
            color: colorConfigs?.sidebar?.bg,
            border: `1px solid ${colorConfigs?.sidebar?.bg}`,
          }
        }}
          onClick={backStep}
          variant='outlined'>
          Back
        </Button>

        <Button
          variant='contained'
          sx={{
            ml: 1,
            background: colorConfigs?.sidebar?.bg,
            color: "white",
            px: 5,
            borderRadius: "5px",
            '&:hover': {
              background: colorConfigs?.sidebar?.bg,
              color: 'white',

            }
          }}
          disabled={isLoading}
          onClick={() => { handleSubmit(addNewRide) }}>
          {isLoading ? 'Adding...' : 'Add'}
        </Button>
      </Grid>
    </Box>
  );
};

export default Summary;
