import React, { useEffect, useRef, useState } from "react";
import { GoogleMap, Polygon, useLoadScript } from "@react-google-maps/api";
import { useDispatch, useSelector } from "react-redux";
import { saveCoordinates, saveLocationInfo } from "../../redux/features/appStateSlice";
import colorConfigs from "../../configs/colorConfigs";
import { GOOGLE_API_KEY, mapStyles } from "../../utils/constant";

const initializeDrawingManager = (map, handleNewPolygon, setPolygon, drawingManagerRef, fetchAddress) => {
  const drawingManager = new window.google.maps.drawing.DrawingManager({
    drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
    drawingControl: true,
    drawingControlOptions: {
      position: window.google.maps.ControlPosition.TOP_CENTER,
      drawingModes: ["polygon"],
    },
    polygonOptions: {
      fillColor: colorConfigs?.sidebar?.bg,
      strokeColor: colorConfigs?.sidebar?.bg,
      editable: true,
    },
  });

  drawingManager.setMap(map);
  drawingManagerRef.current = drawingManager;

  window.google.maps.event.addListener(drawingManager, "overlaycomplete", (event) => {
    if (event.type === window.google.maps.drawing.OverlayType.POLYGON) {
      const newPolygon = event.overlay; // Capture the newly drawn polygon

      // Remove previous polygon if it exists
      setPolygon((prevPolygon) => {
        if (prevPolygon) prevPolygon.setMap(null);
        return newPolygon;
      });

      const path = newPolygon.getPath();
      const coords = [];
      const bounds = new window.google.maps.LatLngBounds(); // To calculate the bounds

      path.forEach((latLng) => {
        coords.push({ lat: latLng.lat(), lng: latLng.lng() });
        bounds.extend(latLng); // Extend bounds for each point
      });

      map.fitBounds(bounds); // Adjust the map to fit the polygon bounds
      handleNewPolygon(coords);

      // Fetch address based on the center of the polygon
      const center = bounds.getCenter();
      fetchAddress(center.lat(), center.lng());

      // Disable drawing tools after the first polygon is created (optional)
      // drawingManager.setDrawingMode(null);
      // drawingManager.setOptions({ drawingControl: false });

      // Attach event listeners to handle editing
      window.google.maps.event.addListener(path, "set_at", () => {
        updatePolygonCoords(path, handleNewPolygon);
        map.fitBounds(bounds); // Adjust the map to fit the polygon bounds after editing
        fetchAddress(bounds.getCenter().lat(), bounds.getCenter().lng()); // Fetch address after editing
      });

      window.google.maps.event.addListener(path, "insert_at", () => {
        updatePolygonCoords(path, handleNewPolygon);
        map.fitBounds(bounds); // Adjust the map to fit the polygon bounds after editing
        fetchAddress(bounds.getCenter().lat(), bounds.getCenter().lng()); // Fetch address after editing
      });
    }
  });
};

// Function to update polygon coordinates after editing
const updatePolygonCoords = (path, handleNewPolygon) => {
  const newCoords = path.getArray().map((latLng) => ({
    lat: latLng.lat(),
    lng: latLng.lng(),
  }));
  handleNewPolygon(newCoords);
};

// Main component to render the map
const MapWithDraw = ({ options }) => {
  const dispatch = useDispatch();
  const dataUpdated = useSelector((state) => state.appState.formDataDoc);
  const initialCenter = options && options.length > 0 ? { lat: options[0].lat, lng: options[0].lng } : { lat: 51.505, lng: -0.09 };
  const [polygon, setPolygon] = useState(null);
  const drawingManagerRef = useRef(null);
  const mapRef = useRef(null);
  const [center, setCenter] = useState(initialCenter);
  const [showTraffic, setShowTraffic] = useState(
    localStorage.getItem("showTraffic") === "true"
  );
  const trafficLayerRef = useRef(null);
  const [mapTypeId, setMapTypeId] = useState(
    localStorage.getItem("mapTypeId") || "hybrid"
  );
  // Fetch address and dispatch location info
  const fetchAddress = (lat, lng) => {
    fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}`)
      .then((response) => response.json())
      .then((data) => {
        const address = data.display_name;
        const zipCode = data.address.postcode || "";
        dispatch(
          saveLocationInfo({
            lat,
            lng,
            address,
            zipCode,
          })
        );
      })
      .catch((error) => console.error("Error fetching address:", error));
  };

  // Update the location info and set the map center on component mount
  useEffect(() => {
    if (dataUpdated) {
      const { lat, lng, address, zip_code } = dataUpdated.address || {};
      dispatch(
        saveLocationInfo({
          lat,
          lng,
          address,
          zipCode: zip_code,
        })
      );
      if (lat && lng) {
        setCenter({ lat, lng }); // Center the map on the location from dataUpdated
      }
    }
  }, [dataUpdated, dispatch]);

  const handleNewPolygon = (newCoords) => {
    dispatch(saveCoordinates(newCoords));
  };

  useEffect(() => {
    if (mapRef.current && trafficLayerRef.current) {
      if (showTraffic) {
        trafficLayerRef.current.setMap(mapRef.current); // Show traffic layer if true
      } else {
        trafficLayerRef.current.setMap(null); // Hide traffic layer if false
      }
    }
  }, [showTraffic]);


  const initializePolygonAndMarkers = (map) => {
    if (options && options.length > 0) {
      const initialPolygon = new window.google.maps.Polygon({
        paths: options.map(point => ({ lat: point.lat, lng: point.lng })),
        editable: true,
        fillColor: colorConfigs?.sidebar?.bg,
        strokeColor: colorConfigs?.sidebar?.bg,
      });

      setPolygon(initialPolygon);

      // Set the polygon on the map
      initialPolygon.setMap(map);

      const bounds = new window.google.maps.LatLngBounds();
      options.forEach((point) => bounds.extend(new window.google.maps.LatLng(point.lat, point.lng)));
      map.fitBounds(bounds); // Adjust the map to fit the polygon bounds

      const path = initialPolygon.getPath();
      window.google.maps.event.addListener(path, "set_at", () => {
        updatePolygonCoords(path, handleNewPolygon);
        map.fitBounds(bounds); // Adjust the map to fit the polygon bounds after editing
        fetchAddress(bounds.getCenter().lat(), bounds.getCenter().lng()); // Fetch address after editing
      });

      window.google.maps.event.addListener(path, "insert_at", () => {
        updatePolygonCoords(path, handleNewPolygon);
        map.fitBounds(bounds); // Adjust the map to fit the polygon bounds after editing
        fetchAddress(bounds.getCenter().lat(), bounds.getCenter().lng()); // Fetch address after editing
      });

      // Initialize the drawing manager even when options are provided
      initializeDrawingManager(map, handleNewPolygon, setPolygon, drawingManagerRef, fetchAddress);
    } else {
      // If no options are provided, enable the drawing manager for new polygons
      initializeDrawingManager(map, handleNewPolygon, setPolygon, drawingManagerRef, fetchAddress);
    }
  };

  useEffect(() => {
    if (mapRef.current) {
      initializePolygonAndMarkers(mapRef.current);
    }
  }, [options]);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries: ["drawing"],
  });

  const addTrafficControl = (map) => {
    // Create a div to hold the control.
    const controlDiv = document.createElement("div");

    // Set the styles for the control to match default Google controls
    controlDiv.style.backgroundColor = "#fff";
    controlDiv.style.color = localStorage.getItem("showTraffic") === "true" ? "rgb(25,25,25)" : "rgb(86, 86, 86)";
    controlDiv.style.borderRadius = "3px";
    controlDiv.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
    controlDiv.style.cursor = "pointer";
    controlDiv.style.marginTop = "10px";
    controlDiv.style.marginBottom = "22px";
    controlDiv.style.textAlign = "center";
    controlDiv.style.height = "40px";
    controlDiv.style.marginLeft = "-12px";

    const controlText = document.createElement("div");
    // controlText.style.color = "rgb(25,25,25)";
    controlText.style.fontFamily = "poppins";
    controlText.style.fontSize = "15px";
    controlText.style.lineHeight = "38px";
    controlText.style.fontWeight = localStorage.getItem("showTraffic") === "true" ? "600" : "500";
    controlText.style.paddingLeft = "5px";
    controlText.style.paddingRight = "5px";
    controlText.innerHTML = "Traffic Mode";
    controlDiv.appendChild(controlText);

    controlDiv.addEventListener('mouseover', () => {
      controlDiv.style.backgroundColor = "#f0f0f0";
      controlDiv.style.color = "rgb(25,25,25)";
    });

    controlDiv.addEventListener('mouseout', () => {
      controlDiv.style.backgroundColor = "#fff";
      controlDiv.style.color = "rgb(86, 86, 86)";
    });

    // Append the control to the same container as the default map controls
    map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(controlDiv);

    // Set up the click event listener for the control.
    controlDiv.addEventListener("click", () => {
      setShowTraffic((prevShowTraffic) => {
        const newShowTraffic = !prevShowTraffic;
        if (newShowTraffic) {
          controlDiv.style.color = "rgb(25,25,25)";
          controlText.style.fontWeight = "600"
          trafficLayerRef.current.setMap(map); // Show traffic layer
        } else {
          controlDiv.style.color = "rgb(86, 86, 86)";
          controlText.style.fontWeight = "500"
          trafficLayerRef.current.setMap(null); // Remove traffic layer
        }
        localStorage.setItem("showTraffic", newShowTraffic);
        return newShowTraffic;
      });
    });
  };


  if (!isLoaded) return <div>Loading...</div>;

  return (

    <GoogleMap
      mapContainerStyle={{ height: "480px", width: "100%" }}
      center={center}
      zoom={5}
      options={{
        mapTypeControl: true,
        mapTypeId: mapTypeId,
        minZoom: 1,
        showTraffic: true,
        styles: mapStyles
      }}
      onLoad={(map) => {
        mapRef.current = map;
        initializePolygonAndMarkers(map);

        // Initialize the TrafficLayer but don't show it by default
        trafficLayerRef.current = new window.google.maps.TrafficLayer();

        // If showTraffic is true, display the traffic layer on map load
        if (showTraffic) {
          trafficLayerRef.current.setMap(map);
        }

        // Add the traffic toggle control to the map
        addTrafficControl(map);

        window.google.maps.event.addListener(map, "maptypeid_changed", () => {
          const newMapTypeId = map.getMapTypeId();
          setMapTypeId(newMapTypeId);
          localStorage.setItem("mapTypeId", newMapTypeId);
        });
      }}
    >
      {polygon && (
        <Polygon
          paths={polygon.getPath()}
          options={{
            fillColor: colorConfigs?.sidebar?.bg,
            strokeColor: colorConfigs?.sidebar?.bg,
            editable: true,
          }}
        />
      )}
    </GoogleMap>
  );
};

export default MapWithDraw;
