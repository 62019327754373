import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import CommentIcon from "@mui/icons-material/Comment";
import {
  Alert,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Snackbar,
  Typography,
  useMediaQuery,
  useTheme, Avatar, Paper
} from "@mui/material";
import { UserOutlined } from "@ant-design/icons";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { SubmitButton } from "@zetaton-dev/zetaton-components-bank";
import React, { useEffect, useState } from "react";
import Loading from "react-fullscreen-loading";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Calendar from "../../assets/images/Calendar.jpg";
import icon from "../../assets/images/Icon.png";
import emptyActive from "../../assets/images/emptyactive.jpg";
import fare from "../../assets/images/fare.jpg";
import game from "../../assets/images/game-icons_path-distance.jpg";
import location from "../../assets/images/location.png";
import walletMinus from "../../assets/images/wallet-minus.png";
import BestRoute from "../../components/googleMaps/bestRoute";
import colorConfigs from "../../configs/colorConfigs";
import { storageSdk } from "../../configs/firebase";
import { chat, selectChat, setLastSupportChat, setOpenChatFromDriver, updateOutChat, setSelectedUser } from "../../redux/features/appStateSlice";
import { getMethod, putMethod } from "../Hook/UseCustomApi";
import "../drivers/index.css";
import TextRating from "../rating/rate";
import RiderInfo from "./RiderInfo";
import RiderReview from "./RiderReview";
import RiderRideHistory from "./RiderRideHistory";
import RiderWallet from "./RiderWallet";
import Error5xx from "../../components/common/HandelError5xx";
import { setEnableEdit } from "../../redux/features/appStateSlice";
import { formatAddress } from "../../utils/localization/addressUtils";
import { formatCurrency } from "../../utils/localization/currencyUtils";
import { formatDate } from "../../utils/localization/dateTimeUtils";
import BlueButton from "../../components/common/Buttons/BlueButton";
import { openModal } from "../../redux/features/modalSlice";
import RiderBalanceDialog from "./RiderBalanceDialog";



const RidersDetails = () => {
  const params = useParams();
  const ridersList = useSelector((state) => state.appState.riders);
  const [selectedObject, setSelectedObject] = useState(null);
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const [valueTabs, setValueTabs] = React.useState("one");
  const [enabled, setEnabled] = useState(selectedObject?.enabled);
  const [inputKey, setInputKey] = useState("");
  const [loading, setLoading] = useState("");
  const [url, setUrl] = useState("");
  const [activeDelivery, setActiveDelivery] = useState(null);
  const [updatedData, setUpdatedData] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const enableEdit = useSelector((state) => state.appState.enableEdit);

  //handle error5xx
  const [tryAgain, setTryAgain] = useState();
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [errorCount, setErrorCount] = useState(0);
  const [errorDialogCounter, setErrorDialogCounter] = useState(0);
  const handleError5xx = () => {
    setIsErrorDialogOpen(true);
    setErrorCount(prevCount => {
      const newCount = prevCount + 1;
      if (newCount === 1) {
        setErrorDialogCounter(5);
      } else if (newCount === 2) {
        setErrorDialogCounter(10);
      }
      return newCount;
    });
  };
  const handleCloseErrorDialog = () => {
    setIsErrorDialogOpen(false);
    if (errorCount <= 2) {
      handleErrorCountByClose()
    }

  };
  const handleErrorCountByClose = () => {
    setErrorCount(prevCount => {
      const newCount = prevCount - 1;
      if (newCount === 1) {
        setErrorDialogCounter(5);
      } else if (newCount === 2) {
        setErrorDialogCounter(10);
      }
      return newCount;
    });
  }



  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const countryCode = useSelector((state) => state.country.selectedCountry);
  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  // Function to handle updating rider data
  const updateRiderData = (newData) => {
    setUpdatedData(newData);
  };
  useEffect(() => {
    console.log("updatedData", updatedData?.email);
  }, [updatedData]);

  const transformPayType = (payType) => {
    return payType === "cash" ? "Cash" : payType;
  };

  useEffect(() => {
    setEnabled(selectedObject?.enabled);
  }, [selectedObject?.enabled]);

  const getDateForamted = (date) => {
    const dateObject = new Date(date);

    // Formatting options
    const options = {
      day: "numeric",
      month: "short",
      year: "numeric",
    };

    const formattedDate = dateObject.toLocaleDateString("en-US", options);
    return formattedDate;
  };
  const requirementsList = [
    {
      key: "distance",
      icon: <img src={game} alt="loc" />,
      title: "Distance",
      caption: "3 KM",
    },
    {
      key: "date",
      icon: <img src={Calendar} alt="loc" />,
      title: "Date",
      caption: formatDate(getDateForamted(activeDelivery?.created_at), countryCode),
    },

    {
      key: "taxes",
      icon: <img src={walletMinus} alt="loc" />,
      title: "Taxes",
      caption: `- ${formatCurrency(activeDelivery?.tax, countryCode)}`,
    },
    {
      key: "fare",
      icon: <img src={fare} alt="loc" />,
      title: "Fare",
      caption: `${formatCurrency(activeDelivery?.total, countryCode)}`,
    },
    {
      key: "pay",
      icon: <img src={fare} alt="loc" />,
      title: "Paid with",
      caption: transformPayType(activeDelivery?.pay_type),
    },
  ];

  let pickup = {
    lat: activeDelivery?.pickup_address?.lat,
    lng: activeDelivery?.pickup_address?.lng,
  };
  let dropoff = {
    lat: activeDelivery?.delivery_address?.lat,
    lng: activeDelivery?.delivery_address?.lng,
  };
  useEffect(() => {
    const foundObject = ridersList?.find((obj) => obj._id === params["id"]);
    setSelectedObject(foundObject);
    dispatch(selectChat({
      ...foundObject, customer: `admin_${foundObject?.fb_uid}`, type: "customer", user_name:
        selectedObject?.first_name +
        " " +
        selectedObject?.last_name,
      user_phoneNumber: selectedObject?.phone_number,
      user_profile: selectedObject?.profile_picture,
    }));
    dispatch(setLastSupportChat({
      ...foundObject, customer: `admin_${foundObject?.fb_uid}`, type: "customer", user_name:
        selectedObject?.first_name +
        " " +
        selectedObject?.last_name,
      user_phoneNumber: selectedObject?.phone_number,
      user_profile: selectedObject?.profile_picture,
    }));
    window.scrollTo({ top: 0 });
  }, [params]);



  const handleChange = (e, newValue) => {
    setValueTabs(newValue);
    if (enableEdit === true && newValue === "one") {
      if (valueTabs === newValue) {

      } else {
        dispatch(setEnableEdit(false));
      }
    } else {
      dispatch(setEnableEdit(false));
    }
  };

  const style = {
    color: "#696969",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "24px" /* 150% */,
    letterSpacing: "0.17px",
    textTransform: "capitalize",
  };


  const handleImagrUrl = async (e) => {
    console.log("here");
    setLoading(true);
    try {
      let file = e.target?.files[0];
      if (
        (file && file.length !== 0) ||
        (file && file !== "" && typeof file !== "string")
      ) {
        setLoading(true);
        const uploadTask = storageSdk.ref(`/drivers/${file.name}`).put(file);
        uploadTask.on(
          "state_changed",
          (snapShot) => { },
          (err) => {
            //catches the errors
            console.log(err);
          },
          () => {
            storageSdk
              .ref("drivers")
              .child(file.name)
              .getDownloadURL()
              .then(async (fireBaseUrl) => {
                const dataToUpdate = {
                  profile_picture: fireBaseUrl,
                };

                setLoading(true);

                putMethod({
                  url: `customer/${selectedObject?._id}`,
                  body: dataToUpdate,
                  handleErrorMessage: showSnackbar,
                })
                  .then(() => {
                    setUrl(fireBaseUrl);
                  })
                  .catch((error) => {
                    console.error("Update API error:", error.message);
                    if (error === "Internal Server Error: Server error occurred.") {
                      handleError5xx();
                      setTryAgain(() => () => handleImagrUrl(e));
                    }
                  })
                  .finally(() => {
                    setLoading(false);
                  });
              })
              .catch((error) => {
                console.error("Error getting image URL:", error);
                setLoading(false);
              });
          }
        );
      } else {
        setUrl(file);
        setLoading(false);
      }
      setInputKey(Math.random());
    } catch (error) {
      console.error("Error handling image URL:", error);
    }
  };

  const getParams = () => {
    return {
    };
  };



  console.log("Profile Picture", selectedObject?.profile_picture);
  console.log("url", url);

  const fetchActiveDelivery = () => {
    if (selectedObject && selectedObject?.active_ride_id) {
      getMethod({
        url: `ride/${selectedObject?.active_ride_id}`,
        params: getParams(),
        handleErrorMessage: showSnackbar,
      })
        .then((response) => {
          console.log(response);
          setActiveDelivery(response?.data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          if (error === "Internal Server Error: Server error occurred.") {
            handleError5xx();
            setTryAgain(() => fetchActiveDelivery);
          }
        });
    }
  };

  useEffect(() => {
    if (selectedObject?.active_ride_id !== "") {
      fetchActiveDelivery();
    }
  }, [selectedObject?.active_ride_id]);
  return (
    <Box sx={{ position: 'relative' }}>
      {/* handleError5xx */}
      {valueTabs === "four" && (<Box sx={{ position: 'absolute', top: 3, right: 3 }}>
        <BlueButton sx={{ px: 3 }} onClick={() => dispatch(openModal(<RiderBalanceDialog />))}>
          + Add Transaction
        </BlueButton>
      </Box>)}
      <Error5xx open={isErrorDialogOpen} errCount={errorCount} count={errorDialogCounter} handleClose={handleCloseErrorDialog} tryAgain={tryAgain} />
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "85vh",
          py: 3,
        }}
      >
        {!smDown ? (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div>
              <Box>
                <div style={{ position: "relative" }}>
                  {selectedObject?.profile_picture || url === "" ? (
                    <>
                      {selectedObject?.profile_picture || url ? (
                        <img
                          src={url === "" ? selectedObject?.profile_picture : url}
                          alt="Profile"
                          loading="lazy"
                          style={{
                            width: "150px",
                            height: "135px",
                            border: "2px solid #EEEEEE7A",
                            borderRadius: 15,
                          }}
                        />
                      ) : (
                        <Paper style={{
                          width: "150px",
                          height: "135px",
                          border: "2px solid #EEEEEE7A",
                          borderRadius: 15,
                        }}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              textAlign: "center",
                              height: "100%",
                            }}
                          >
                            {" "}
                            <Avatar
                              style={{ height: "66px", width: "66px" }}
                              icon={<UserOutlined />}
                            />
                          </div>{" "}
                        </Paper>
                      )}
                      <div
                        style={{
                          position: "absolute",
                          bottom: 13,
                          right: 5,
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-end",
                        }}
                      >
                        <form onSubmit={() => { }} className="form">
                          <IconButton
                            component="label"
                            style={{
                              background: colorConfigs.sidebar.bg,
                              borderRadius: "10%",
                              width: "26px",
                              height: "26px",
                              marginBottom: "2px",
                            }}
                          >
                            <AddAPhotoIcon />
                            <input
                              type="file"
                              hidden
                              style={{ display: "none" }}
                              multiple
                              size="20971520"
                              accept="image/png, image/jpeg"
                              key={inputKey}
                              onChange={(e) => handleImagrUrl(e)}
                              onClick={(e) => (e.target.value = null)}
                            />
                          </IconButton>
                        </form>
                      </div>
                    </>
                  ) : (
                    <img
                      alt="driver"
                      loading="lazy"
                      style={styles?.companyImage}
                      src={url === "" ? selectedObject?.profile_picture : url}
                    />
                  )}
                </div>
              </Box>
              <Loading
                loading={!!loading}
                background="rgba(86, 100, 210, 0.1)"
                loaderColor={colorConfigs.mainColor}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                paddingLeft: 20,
                width: "100%",
              }}
            >
              <div
                style={{
                  paddingLeft: 7,
                }}
              >
                <Typography style={styles.title}>
                  {updatedData &&
                    updatedData?.first_name &&
                    updatedData?.last_name && (
                      <>
                        {updatedData?.first_name}{" "}
                        {updatedData?.last_name.charAt(0).toUpperCase() + "."}
                      </>
                    )}
                  {!updatedData ||
                    !updatedData.first_name ||
                    !updatedData.last_name ? (
                    <>
                      {selectedObject?.first_name}{" "}
                      {selectedObject?.last_name &&
                        selectedObject?.last_name.charAt(0).toUpperCase() + "."}
                    </>
                  ) : null}
                  {/* {selectedObject?.first_name}{" "}
                  {selectedObject?.last_name &&
                    selectedObject?.last_name.charAt(0).toUpperCase() + "."} */}
                </Typography>
                <div style={{ width: "10px" }} />
                <TextRating
                  value={
                    selectedObject?.review_info?.total_avg
                      ? selectedObject?.review_info?.total_avg
                      : 0
                  }
                />
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: colorConfigs?.sidebar?.bg,
                    color: "white",
                    fontSize: "16px",
                    lineHight: "20.16px",
                    fontWeight: "600",
                    borderRadius: "20px",
                    height: "42px",
                    width: "127px",
                    margin: "15px 0"
                  }}
                  onClick={() => {
                    navigate(`/support`);
                    dispatch(
                      chat({
                        user_name:
                          selectedObject?.first_name +
                          " " +
                          selectedObject?.last_name,
                        user_phoneNumber: selectedObject?.phone_number,
                        user_profile: selectedObject?.profile_picture,
                      })
                    );
                    dispatch(selectChat({
                      ...selectedObject, customer: `admin_${selectedObject?.fb_uid}`, type: "customer", user_name:
                        selectedObject?.first_name +
                        " " +
                        selectedObject?.last_name,
                      user_phoneNumber: selectedObject?.phone_number,
                      user_profile: selectedObject?.profile_picture,
                    }));
                    dispatch(setLastSupportChat({
                      ...selectedObject, customer: `admin_${selectedObject?.fb_uid}`, type: "customer", user_name:
                        selectedObject?.first_name +
                        " " +
                        selectedObject?.last_name,
                      user_phoneNumber: selectedObject?.phone_number,
                      user_profile: selectedObject?.profile_picture,
                    }));
                    dispatch(updateOutChat(false));
                    dispatch(setOpenChatFromDriver(true));
                  }}
                >
                  {" "}
                  <CommentIcon
                    style={{ width: "15px", marginRight: "10px" }}

                  />{" "}
                  Chat
                </Button>
              </div>
            </div>

            {enableEdit && (
              <Container style={{ justifyContent: "end" }}>
                <Box display="flex" style={{ justifyContent: "end" }}>
                  <SubmitButton
                    variant="outlined"
                    onClick={() => {
                      setEnabled(true);
                    }}
                    style={{
                      borderTopLeftRadius: "88px",
                      borderBottomLeftRadius: "88px",
                      borderRight: "1px solid black",
                      borderColor: colorConfigs?.sidebar?.bg,
                      color: enabled ? "white" : colorConfigs?.sidebar?.bg,
                      backgroundColor: enabled
                        ? colorConfigs?.sidebar?.bg
                        : "white",
                    }}
                    label={"Enable"}
                  />
                  <SubmitButton
                    variant="outlined"
                    onClick={() => {
                      setEnabled(false);
                    }}
                    style={{
                      borderTopRightRadius: "88px",
                      borderBottomRightRadius: "88px",
                      borderColor: colorConfigs?.sidebar?.bg,
                      color: !enabled ? "white" : "#42526E",
                      backgroundColor: !enabled
                        ? colorConfigs?.sidebar?.bg
                        : "white",
                    }}
                    label={"Disable"}
                  />
                </Box>
              </Container>
            )}

          </div>
        ) : (
          ""
        )}
        <div>
          <Box sx={{ width: "100%", background: "#E8E8E8" }}>
            <Tabs
              value={valueTabs}
              onChange={handleChange}
              textColor="inherit"
              sx={{ color: "black" }}
              TabIndicatorProps={{
                style: {
                  backgroundColor: "black",
                },
              }}
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
              variant="scrollable"
            >
              <Tab
                value="one"
                // label="Rider Details"
                label="Passenger Details"
                style={{ width: "182px" }}
              />
              <Tab value="three" label="Rides" style={{ width: "182px" }} />
              {/* <Tab value="two" label="Active Ride" style={{ width: "182px" }} /> */}
              <Tab value="four" label="Wallet" style={{ width: "182px" }} />
              <Tab value="five" label="Reviews" style={{ width: "182px" }} />
            </Tabs>
          </Box>
          {valueTabs === "one" && (
            <>
              <Box sx={{ width: "100%", marginTop: "10px" }}>
                <RiderInfo
                  selectedObject={selectedObject}
                  enabled={enabled}
                  params={params["id"]}
                  handleUpdate={updateRiderData}
                />
                {console.log("selectedObject", selectedObject)}
              </Box>
            </>
          )}
          {valueTabs === "two" && (
            <>
              {selectedObject?.active_ride_id !== "" ? (
                <>
                  {" "}
                  <div style={{ width: "100%" }}>
                    <Grid
                      container
                      spacing={3}
                      style={{
                        paddingTop: "30px",
                        width: "100%",
                        justifyContent: "center",
                      }}
                    >
                      {requirementsList.map((requirement) => (
                        <Grid key={requirement.key} item lg={2} sm={3}>
                          <div
                            style={{
                              border: `0.5px solid lightgray`,
                              borderRadius: "10px",
                              textAlign: "center",
                              padding: "16px 0",
                              boxSizing: "border-box",
                              transition: "0.3s",

                              "&:hover": {
                                boxShadow: "0 4px 6px 0 rgba(0,0,0,0.02)",
                              },
                            }}
                          >
                            {requirement.icon}
                            <Typography
                              variant={"body2"}
                              style={{ marginTop: "8px", color: "#7A869A" }}
                            >
                              {requirement.title}
                            </Typography>
                            <Typography
                              variant={"caption"}
                              style={{ color: "#495974", fontWeight: "bold" }}
                            >
                              {requirement.caption}
                            </Typography>
                          </div>
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                  <div style={{ position: "relative" }}>
                    <div
                      style={{
                        alignItems: "center",
                        display: "flex",
                        padding: "5px",
                      }}
                    >
                      <img src={location} alt="loc" />
                      <Typography
                        variant="body2"
                        style={{
                          color: colorConfigs.sidebar.bg,
                          opacity: "0.8",
                          height: "85%",
                          margin: "16px 0",
                          fontWeight: "bold",
                          marginLeft: "10px", // Add left margin for spacing
                        }}
                      >
                        Pick Up:
                        <br />
                        <Typography
                          key={activeDelivery?._id}
                          variant="caption"
                          style={{
                            color: "black",
                            opacity: "0.8",
                            height: "85%",
                            marginTop: "3px",
                          }}
                        >
                          {activeDelivery?.pickup_address?.address}
                        </Typography>
                      </Typography>
                    </div>

                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "2.5%",
                        transform: "translate(-50%, -45%)",
                        borderLeft: "1px solid gray", // Adjust the line style
                        height: "28px", // Adjust the height of the line
                      }}
                    ></div>

                    <div
                      style={{
                        alignItems: "center",
                        display: "flex",
                        padding: "5px",
                      }}
                    >
                      <img src={icon} alt="loc" />
                      <Typography
                        variant="body2"
                        style={{
                          color: colorConfigs.sidebar.bg,
                          opacity: "0.8",
                          margin: "16px 0",
                          fontWeight: "bold",
                          marginLeft: "10px",
                        }}
                      >
                        Drop Off:
                        <br />
                        <Typography
                          key={activeDelivery?._id}
                          variant="caption"
                          style={{
                            color: "black",
                            opacity: "0.8",
                            marginTop: "3px",
                          }}>
                          {formatAddress(activeDelivery?.delivery_address, countryCode)}
                        </Typography>
                      </Typography>
                    </div>
                  </div>
                  <div>
                    <div style={{ width: "100%" }}>
                      <BestRoute pickup={pickup} dropoff={dropoff} />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "20px",
                    }}
                  >
                    <img src={emptyActive} alt="active" />
                  </div>
                  <p
                    style={{
                      justifyContent: "center",
                      display: "flex",
                      fontWeight: "bold",
                    }}
                  >
                    No Active Ride{" "}
                  </p>
                </>
              )}
            </>
          )}
          {valueTabs === "three" && (
            <Box
              sx={{
                width: "100%",
                marginTop: !smDown ? "30px" : "10px",
                "& .super-app-theme--header": {
                  backgroundColor: "rgb(220,220,220)",
                },
              }}
            >
              <RiderRideHistory driverId={params["id"]} />
            </Box>
          )}
          {valueTabs === "five" && (
            <Box
              sx={{
                width: "100%",
                marginTop: !smDown ? "30px" : "10px",
                "& .super-app-theme--header": {
                  backgroundColor: "rgb(220,220,220)",
                },
              }}
            >
              <RiderReview driverId={params["id"]} />
            </Box>
          )}
          {valueTabs === "four" && (
            <Box
              sx={{
                width: "100%",
                marginTop: !smDown ? "30px" : "10px",
                "& .super-app-theme--header": {
                  backgroundColor: "rgb(220,220,220)",
                },
              }}
            >
              <RiderWallet selectedObject={selectedObject} driverId={params["id"]} isDriver={false} />
            </Box>
          )}
        </div>
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleSnackbarClose} severity="error">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

const styles = {
  title: {
    fontWeight: "bold",
    fontSize: "26px",
    marginTop: "5px",
  },
  subTitle: {
    fontWeight: "bold",
    fontSize: "16px",
    marginTop: "5px",
    marginBottom: "5px",
  },
  companyImage: {
    width: "150px",
    height: "135px",
    border: "2px solid #EEEEEE7A",
    borderRadius: 15,
  },
  companyImageMobileSize: {
    width: "90px",
    height: "90px",
    border: "2px solid #EEEEEE7A",
    borderRadius: 10,
    marginTop: "0px",
  },
};
export default RidersDetails;
