import * as React from "react"
import { memo } from "react"
const SvgComponent = (props) => (
    <svg
        width={27}
        height={28}
        fill="none"
        {...props}
    >
        <g clipPath="url(#a)">
            <path
                stroke="#FFF"
                strokeWidth={2.16}
                d="M10.729 13.619a5.303 5.303 0 1 0 0-10.606 5.303 5.303 0 0 0 0 10.606Zm0 0c2.135 0 4 .806 5.327 2.145M10.73 13.62c-4.242 0-7.424 3.182-7.424 7.424v5.303h7.424m15.909-8.485-3.182-3.182-6.363 6.364m3.712-3.712 3.181 3.182m-11.136 2.12a2.653 2.653 0 1 0 5.306-.001 2.653 2.653 0 0 0-5.306.002Z"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M.973.679h28v28h-28z" />
            </clipPath>
        </defs>
    </svg>
)
const WhiteAdmin = memo(SvgComponent)
export default WhiteAdmin
