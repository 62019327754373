import React, { useEffect, useRef, useState } from "react";
import { Alert, Box, Snackbar, Button } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import norides from "../../assets/images/norides.png";
import ridesIconRR from "../../assets/tableIconRoadRunner/rides.png";
import ridesIconWF from "../../assets/tableIconWomenFirst/rides.png";
import colorConfigs from "../../configs/colorConfigs";
import { getMethod } from "../Hook/UseCustomApi";
import RideCard from "./RideCard";
import RideDetails from "./RideDetails";
import RideMap from "./RideMap";
import Error5xx from "../../components/common/HandelError5xx";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../configs/firebase";
import BlueButton from "../../components/common/Buttons/BlueButton";
import { FaArrowUp } from "react-icons/fa6";
export default function TrackRides() {
  const [activeRides, setActiveRides] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRideId, setSelectedRideId] = useState("");
  const [activeRideId, setActiveRideId] = useState("");
  const [activeRide, setActiveRide] = useState("");
  const [openInfoDialog, setOpenInfoDialog] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [showFetchButton, setShowFetchButton] = useState(false);
  const [hasInitialLoad, setHasInitialLoad] = useState(false);


  const [lastAssigned, setLastAssigned] = useState(null);
  const [lastActive, setLastActive] = useState(null);


  const [tryAgain, setTryAgain] = useState(null);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [errorCount, setErrorCount] = useState(0);
  const [errorDialogCounter, setErrorDialogCounter] = useState(0);

  const listInnerRef = useRef();
  let currentPage = useRef(1);
  let currentStatus = useRef("all");
  let currentKeyword = useRef("");


  useEffect(() => {
    const unsubscribe = onSnapshot(
      doc(db, "rides_count", "rides_count"),
      (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          const assigned = data.assigned;
          const active = data.active;

          if (
            assigned !== undefined &&
            active !== undefined &&
            (assigned !== lastAssigned || active !== lastActive)
          ) {
            if (hasInitialLoad) {
              setShowFetchButton(true);
            }
            setLastAssigned(assigned);
            setLastActive(active);
          }
        }
      }
    );
    return () => unsubscribe();
  }, [lastAssigned, lastActive, hasInitialLoad]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleCardClick = (rideId, ride) => {
    setSelectedRideId(rideId);
    setActiveRideId(rideId);
    setActiveRide(ride);
  };

  const getParam = () => {
    return {
      page: currentPage.current,
    };
  };

  const hanleRideInfoClick = () => {
    setOpenInfoDialog((prev) => !prev);
  };

  const getActiveRides = () => {
    setLoading(true);
    getMethod({
      url: "ride/dispatch-view/active",
      params: getParam(),
      handleErrorMessage: showSnackbar,
    })
      .then((response) => {
        let result = response?.data;

        if (currentPage.current === 1) {
          setActiveRides(result);
          if (result.length > 0) {
            handleCardClick(result[0]._id, result[0]);
          }
        } else {
          setActiveRides((prevRides) => [...prevRides, ...result]);
        }
      })
      .catch((error) => {
        console.error("Error fetching active rides:", error);
        if (error.message.includes("Internal Server Error")) {
          handleError5xx();
          setTryAgain(() => getActiveRides);
        } else {
          showSnackbar("Failed to load active rides.");
        }
      })
      .finally(() => {
        setLoading(false);
        setHasInitialLoad(true);
      });
  };


  const fetchMoreActiveRides = () => {
    getMethod({
      url: "ride/dispatch-view/active",
      params: getParam(),
      handleErrorMessage: showSnackbar,
    })
      .then((response) => {
        let result = response?.data;
        if (currentPage.current === 1) {
          setActiveRides(result);
        } else {
          setActiveRides((prevRides) => [...prevRides, ...result]);
        }
      })
      .catch((error) => {
        console.error("Error fetching more active rides:", error);
        if (error.message.includes("Internal Server Error")) {
          handleError5xx();
          setTryAgain(() => fetchMoreActiveRides);
        } else {
          showSnackbar("Failed to load more active rides.");
        }
      });
  };

  useEffect(() => {
    currentPage.current = 1;
    currentStatus.current = "all";
    currentKeyword.current = "";
    getActiveRides();
    window.scrollTo({ top: 0 });
  }, []);

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight >= scrollHeight) {
        currentPage.current += 1;
        fetchMoreActiveRides();
      }
    }
  };

  const handleFetchButtonClick = () => {
    currentPage.current = 1;
    getActiveRides();
    window.scrollTo({ top: 0, behavior: "smooth" });
    setShowFetchButton(false);
  };


  const handleError5xx = () => {
    setIsErrorDialogOpen(true);
    setErrorCount((prevCount) => {
      const newCount = prevCount + 1;
      if (newCount === 1) {
        setErrorDialogCounter(5);
      } else if (newCount === 2) {
        setErrorDialogCounter(10);
      }
      return newCount;
    });
  };

  const handleCloseErrorDialog = () => {
    setIsErrorDialogOpen(false);
    if (errorCount <= 2) {
      handleErrorCountByClose();
    }
  };

  const handleErrorCountByClose = () => {
    setErrorCount((prevCount) => {
      const newCount = prevCount - 1;
      if (newCount === 1) {
        setErrorDialogCounter(5);
      } else if (newCount === 2) {
        setErrorDialogCounter(10);
      }
      return newCount;
    });
  };

  return (
    <Box sx={{ mt: -9, background: '#FFF', borderRadius: 4, p: 2 }}>
      {/* handleError5xx */}
      <Error5xx
        open={isErrorDialogOpen}
        errCount={errorCount}
        count={errorDialogCounter}
        handleClose={handleCloseErrorDialog}
        tryAgain={tryAgain}
      />

      {activeRides.length > 0 ? (
        <>
          <Box sx={{ display: "flex", gap: "20px" }}>
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "40%", position: 'relative' }}
            >
              <h3>Active Rides:</h3>
              {showFetchButton && (

                <Box sx={{ position: 'absolute', top: 40, left: '50%', transform: 'translateX(-50%)', zIndex: 1, backgroundColor: 'transparent', display: 'flex', justifyContent: 'center' }}>

                  <BlueButton variant="outlined" sx={{ px: 2, py: 1, mt: 1, fontSize: 13 }} onClick={handleFetchButtonClick}>
                    <FaArrowUp style={{ marginRight: 5 }} />   Update Rides
                  </BlueButton>
                </Box>
              )}
              <div
                onScroll={onScroll}
                ref={listInnerRef}
                style={{ overflowY: "auto", height: "70vh" }}
              >

                {activeRides?.map((ride) => (
                  <div
                    key={ride._id}
                    style={{
                      border:
                        selectedRideId === ride?._id
                          ? `2px solid ${colorConfigs?.sidebar?.bg}`
                          : "none",
                      marginBottom: "10px",
                      marginRight: "10px",
                      borderRadius: "6px",
                    }}
                    onClick={() => handleCardClick(ride._id, ride)}
                  >
                    <RideCard ride={ride} />
                  </div>
                ))}
                {loading && <p>Loading...</p>}
              </div>
            </Box>
            <Box sx={{ width: "60%", height: "80vh", position: "relative", mt: 8 }}>
              {/* {
                activeRide?.real_image || activeRide?.approximate_image ? (
                  <img
                    src={activeRide?.real_image ? activeRide?.real_image : activeRide?.approximate_image}
                    alt=''
                    style={{ width: '100%', height: 'auto' }}
                  />
                ) : ( */}
                  <RideMap activeRide={activeRide} rideID={activeRideId} />
                {/* )
              } */}

              <div
                style={{
                  width: "150px",
                  height: "30px",
                  backgroundColor: "white",
                  border: `3px solid ${colorConfigs?.sidebar?.bg}`,
                  borderRadius: "15px",
                  color: `colorConfigs?.sidebar?.bg`,
                  position: "absolute",
                  top: "20px",
                  right: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "8px",
                  cursor: "pointer",
                }}
                onClick={() => hanleRideInfoClick()}
              >
                <RemoveRedEyeIcon />
                <span style={{ fontSize: "14px", fontWeight: "500" }}>
                  {openInfoDialog ? "Hide Ride Info" : "Show Ride Info"}
                </span>
              </div>
              {openInfoDialog && <RideDetails activeRide={activeRide} rideID={selectedRideId} />}
            </Box>
          </Box>
        </>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              alignItems: "center",
              marginTop: "20px",
              position: 'relative',
              minHeight: '80vh',
              flexDirection: 'column',
            }}
          >
            <div>
              {showFetchButton && (

                <Box sx={{ position: 'absolute', top: 0, zIndex: 1, backgroundColor: 'transparent', display: 'flex', justifyContent: 'center' }}>
                  <BlueButton variant="outlined" sx={{ px: 1.5, py: 1, mt: 1, fontSize: 13 }} onClick={handleFetchButtonClick}>
                    <FaArrowUp style={{ marginRight: 5 }} />   Update Rides
                  </BlueButton>
                </Box>
              )}
              <img
                src={process.env.REACT_APP_ENV === "women_first"
                  ? ridesIconWF
                  : ridesIconRR}
                alt="active"
                style={{ width: "150px" }}
              />
            </div>
            <p
              style={{
                justifyContent: "center",
                display: "flex",
                fontWeight: "bold",
              }}
            >
              No Active Rides!
            </p>
          </div>
        </>
      )}


      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleSnackbarClose} severity="error">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
