function formatCurrency(amount,countryCode) {
  if(amount === 0) return amount 

  if(countryCode === 'USD'){
    return `$${amount}`
  }else if (countryCode === 'EG'){
    return `LE${amount}`
  }else if (countryCode === 'JOD'){
    return `JD${amount}`
  }
  else {
    return `$${amount}`
  }
  // return new Intl.NumberFormat(locale, {
  //   style: "currency",
  //   currency: currency,
  // }).format(amount);
}

export { formatCurrency };
