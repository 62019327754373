import React, { useState, useEffect } from 'react';
import { Box, IconButton, LinearProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { db } from '../configs/firebase';
import SecurityAlert from '../assets/svg/SecurityAlert';
import { postDataRedux } from '../redux/features/appStateSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function PanicButton() {
    const [notifications, setNotifications] = useState([]);
    const [pausedNotifications, setPausedNotifications] = useState({});
    const [isAudioEnabled, setIsAudioEnabled] = useState(false);
    const [audio] = useState(new Audio('panic_sound.mp3'));
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const enableAudio = () => {
            setIsAudioEnabled(true);
            window.removeEventListener('click', enableAudio);
            window.removeEventListener('keydown', enableAudio);
        };

        window.addEventListener('click', enableAudio);
        window.addEventListener('keydown', enableAudio);

        return () => {
            window.removeEventListener('click', enableAudio);
            window.removeEventListener('keydown', enableAudio);
        };
    }, []);

    useEffect(() => {
        const unsubscribe = db.collection('notifications')
            .orderBy('created_at', 'desc')
            .limit(1)
            .onSnapshot(snapshot => {
                snapshot.forEach(doc => {
                    const notification = doc.data();
                    if (
                        (notification?.object_type === 'panic_button_driver' || notification?.object_type === 'panic_button_customer')
                        && !notification?.open
                    ) {
                        const newNotification = {
                            id: doc.id,
                            object_id: notification.object_id,
                            type: notification.object_type === 'panic_button_driver' ? 'driver' : 'customer',
                            progress: 0,
                            isSoundAllowed: false,
                            name: notification?.option?.name
                        };
                        setNotifications(prevNotifications => [newNotification, ...prevNotifications]);
                    }
                });
            });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setNotifications(prevNotifications =>
                prevNotifications.map(notification => {
                    if (notification.progress < 100 && !pausedNotifications[notification.id]) {
                        return { ...notification, progress: notification.progress + 2 };
                    }
                    return notification;
                })
            );
        }, 100);

        return () => clearInterval(intervalId);
    }, [pausedNotifications]);

    useEffect(() => {
        if (isAudioEnabled) {
            notifications.forEach((notification) => {
                if (notification.progress === 0) {
                    console.log('Playing sound for notification:', notification);
                    audio.play().catch(error => {
                        console.error("Audio playback failed:", error);
                    });
                }
            });
        }
    }, [notifications, isAudioEnabled, audio]);

    useEffect(() => {
        notifications.forEach((notification, index) => {
            if (notification.progress === 100) {
                setTimeout(() => {
                    setNotifications(prevNotifications =>
                        prevNotifications.filter(notif => notif.id !== notification.id)
                    );
                }, 5000);
            }
        });
    }, [notifications]);

    const handleClick = (index) => {
        const notification = notifications[index];
        console.log(notification);

        // Update the read status in Firebase
        db.collection('notifications')
            .doc(notification.id)
            .update({ open: true })
            .then(() => {
                console.log(`Notification ${notification.id} marked as read`);
            })
            .catch(error => {
                console.error("Error updating document: ", error);
            });

        // Stop the audio when navigating
        audio.pause();
        audio.currentTime = 0;

        // Dispatch the action to the Redux store
        dispatch(postDataRedux({ stateName: 'rides', data: 'notification' }));

        // Navigate to the ride details page
        navigate(`/rides/details/${notification?.object_id}`);

        // Pause all notifications by setting them in pausedNotifications
        setPausedNotifications(prevPaused => {
            const newPaused = {};
            notifications.forEach(notif => {
                newPaused[notif.id] = true;
            });
            return newPaused;
        });

        // Hide all notifications after a delay unless hovered
        setTimeout(() => {
            setNotifications([]);
        }, 5000);
    };

    const handleMouseEnter = (id) => {
        // Resume the notification by removing it from pausedNotifications
        setPausedNotifications(prevPaused => {
            const newPaused = { ...prevPaused };
            delete newPaused[id];
            return newPaused;
        });
    };

    const handleMouseLeave = (id) => {
        // Pause the notification again when mouse leaves
        setPausedNotifications(prevPaused => ({
            ...prevPaused,
            [id]: true,
        }));
    };

    const handleClose = (id) => {
        // Remove the notification manually
        setNotifications(prevNotifications =>
            prevNotifications.filter(notification => notification.id !== id)
        );
    };

    return (
        <>
            {notifications.map((notification, index) => (
                notification.progress < 100 && (
                    <Box
                        key={notification.id}
                        onClick={() => handleClick(index)}
                        onMouseEnter={() => handleMouseEnter(notification.id)}
                        onMouseLeave={() => handleMouseLeave(notification.id)}
                        sx={{
                            position: 'fixed',
                            top: 20 + (index * 120),
                            left: '50%',
                            background: '#F9E0E0',
                            transform: 'translateX(-50%)',
                            zIndex: 999,
                            width: 450,
                            boxShadow: 'rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px',
                            height: 100,
                            cursor: 'pointer',
                            display: pausedNotifications[notification.id] ? 'none' : 'block', // Hide if in paused state
                        }}
                    >
                        <Box sx={{ padding: '20px', color: 'red', display: 'flex', justifyContent: 'start', alignItems: 'center', gap: 2, fontSize: 14, fontWeight: 700 }}>
                            <Box sx={{ background: '#FFF', width: 55, height: 55, borderRadius: 25, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <SecurityAlert />
                            </Box>
                            <Box sx={{ width: '80%' }}>
                                <Box sx={{ color: '#000', fontWeight: 700, fontSize: 16 }}>
                                    Security alert
                                </Box>
                                {notification.type === 'driver' ? 'Driver ' : 'Customer '}
                                {notification.name} triggered a security alert. Immediate action required.
                            </Box>
                            <IconButton
                                sx={{ position: 'absolute', top: 5, right: 5 }}
                                onClick={(e) => {
                                    e.stopPropagation(); // Prevent parent onClick
                                    handleClose(notification.id);
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>
                )
            ))}
        </>
    );
}

export default PanicButton;
