
import { Star } from '@mui/icons-material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Avatar, Box, Button, Grid, IconButton, Typography, useMediaQuery } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { styled, useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { FaPhone } from "react-icons/fa6";
import { IoMdClose } from 'react-icons/io';
import { IoChatbubbleEllipsesSharp } from 'react-icons/io5';
import { MdContentCopy, MdEmail } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux';
import icon from "../../../assets/images/Icon.png";
import location from "../../../assets/images/location.png";
import stopIcon from "../../../assets/images/stop.png";
import BlueButton from '../../../components/common/Buttons/BlueButton';
import colorConfigs from '../../../configs/colorConfigs';
import { selectChat, selectRide, setLastRideChat, setLastSupportChat, updateOutChat } from '../../../redux/features/appStateSlice';
import { closeModal, openModal } from '../../../redux/features/modalSlice';
import ClickablePhoneNumber from '../../../utils/ClickablePhoneNumber';
import distanceMeterToUnit from '../../../utils/distanceMeterToUnit';
import { getMethod } from '../../Hook/UseCustomApi';
import CancelRideModal from '../../rides/CancelRideModal';
import DriverAndCustomerChatModal from './DriverAndCustomerChatModal';
import RideChatModel from './RideChatModel';
const ModalContainer = styled(Box)(({ theme }) => ({
    background: '#FFFFFF',
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    width: '806px',
    maxWidth: '95%',
    height: '92vh',
    maxHeight: '94vh',
    overflow: 'auto',
    margin: 'auto',
}));

const Header = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '3px 10px',
}));

const CloseButton = styled(Box)(({ theme }) => ({
    background: '#F0F0F0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer',
}));

const Section = styled(Grid)(({ theme }) => ({
    backgroundColor: '#f5f7f8',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
}));

const InfoBox = styled(Grid)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    marginBottom: 2

}));

const AddressText = styled(Typography)(({ theme }) => ({
    fontWeight: 700,
    fontFamily: "Poppins",
    fontSize: 12,
}));

const TimeText = styled(Typography)(({ theme }) => ({
    color: "#7A869A",
    fontSize: 13,
    fontWeight: 500,
    fontFamily: "Poppins",
}));

const DotDividerContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& div': {
        width: 4,
        height: 4,
        borderRadius: '50%',
        backgroundColor: '#E0E0E0',
        margin: '1px 0',
    },
}));

const Card = styled(Box)(({ theme }) => ({
    background: 'white',
    padding: theme.spacing(1),
    boxShadow: 'rgba(0, 0, 0, 0.05) 0 4px 36px',
    borderRadius: theme.shape.borderRadius,
    margin: theme.spacing(1, 0, 0, 0),
}));

const CardHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#F4F4F4',
    padding: theme.spacing(1, 2),
    borderRadius: theme.shape.borderRadius,
}));

const ContactInfo = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(0.7),
    '& svg': {
        marginRight: theme.spacing(0.5),
        color: colorConfigs?.sidebar?.bg,
    },
}));

const InfoText = styled(Typography)(({ theme }) => ({
    color: '#495974',
    fontSize: 12,
    fontWeight: 400,
}));

const BoldText = styled(Typography)(({ theme }) => ({
    fontWeight: 700,
    color: '#172B4D',
    fontSize: 14,
}));

const GreenText = styled(Typography)(({ theme }) => ({
    fontWeight: 700,
    color: '#0FBE00',
    fontSize: 14,
}));

const SuspendButton = styled(Button)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(2),
    padding: theme.spacing(1),
    background: '#E11719',
    color: 'white',
    paddingX: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    '& svg': {
        marginRight: theme.spacing(1),
    },
    '&:hover': {
        background: '#c31517',
    },
}));

const DotDivider = () => (
    <DotDividerContainer>
        <div></div>
        <div></div>
        <div></div>
    </DotDividerContainer>
);

const RideDetailsModal = ({ active_ride_id, ride, status, updateWhenCancel }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const selectedRide = useSelector((state) => state.appState.selectedRide);
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [loading, setLoading] = useState(false);
    const countryCode = useSelector((state) => state.country.selectedCountry);
    const [showAll, setShowAll] = useState(false);

    const handleClose = () => {
        dispatch(closeModal());
        dispatch(updateOutChat(false));
        dispatch(selectChat({}));
        dispatch(selectRide({}));
    };

    const getRideDetails = () => {
        setLoading(true);
        dispatch(selectRide({}));
        getMethod({
            url: `ride/${active_ride_id}`,
        })
            .then((response) => {
                dispatch(selectRide(response.data));
                setLoading(false);
            })
            .catch((error) => {
                console.log("Error on getting Cancel Dnd Dispatch Rides:", error);
                setLoading(false);
            });
    };

    const openChatDriverAndCustomer = (type) => {
        dispatch(selectRide({ ...selectedRide, chat_type: type }));
        if (type === 'driver') {
            dispatch(setLastSupportChat({ ...selectedRide, type, [type]: `admin_${selectedRide?.driver_info?.fb_uid}` }));
        } else {
            dispatch(setLastSupportChat({ ...selectedRide, type, [type]: `admin_${selectedRide?.customer_details?.fb_uid}` }));
        }

        dispatch(updateOutChat(true));
        dispatch(openModal(<DriverAndCustomerChatModal active_ride_id={active_ride_id} status={status} handleClose={handleClose} />))
    }
    const openChatRide = () => {

        dispatch(selectRide({ ...selectedRide, chat_type: 'ride' }));
        dispatch(setLastRideChat({ ...selectedRide, chat_type: 'ride', rideId: selectedRide?._id }));
        dispatch(updateOutChat(true));
        dispatch(openModal(<RideChatModel status={status} active_ride_id={active_ride_id} handleClose={handleClose} />))
    }

    const getWaitingTime = (firstTime, secondTime) => {
        if (firstTime === undefined || secondTime === undefined || firstTime === null || secondTime === null) {
            return "-";
        }
        else {
            const firstTimestamp = new Date(firstTime).getTime();
            const secondTimestamp = new Date(secondTime).getTime();

            // Calculate the difference in milliseconds
            const timeDifference = secondTimestamp - firstTimestamp;
            // Convert milliseconds to seconds and minutes
            let waitingTimeInSeconds;
            if (timeDifference < 0) {
                waitingTimeInSeconds = 0;
            } else {
                waitingTimeInSeconds = Math.floor(timeDifference / 1000);
            }
            const minutes = Math.floor(waitingTimeInSeconds / 60);
            const seconds = waitingTimeInSeconds % 60;

            // Format the waiting time
            const formattedTime = `${minutes.toString().padStart(2, "0")}:${seconds
                .toString()
                .padStart(2, "0")} min`;

            return formattedTime;
        }
    };

    useEffect(() => {
        getRideDetails();
    }, [active_ride_id, ride, ride]);

    const handleCloseCancelRide = () => {
        dispatch(openModal(<RideDetailsModal active_ride_id={active_ride_id} status={status} />));
    }
    const afterSubmit = () => {
        updateWhenCancel()
        dispatch(closeModal());
    }

    const handleCancelRide = () => {
        dispatch(openModal(<CancelRideModal afterSubmit={afterSubmit} handleClose={handleCloseCancelRide} ride_id={ride?._id} />))
    };


    return (
        <ModalContainer>
            <Header>
                <Typography variant="h6" sx={{ color: '#172B4D', fontWeight: 700 }}>
                    Ride Details
                </Typography>
                <CloseButton onClick={handleClose}>
                    <IoMdClose size={20} />
                </CloseButton>
            </Header>
            <Card>
                <Section container>
                    <InfoBox item xs={12} sm={6}>
                        <Typography variant="body1" sx={{ color: '#6C757D', fontSize: 12, display: 'flex', alignItems: 'center' }}>
                            <Typography component={'span'} sx={{ color: '#6C757D', fontSize: 12, fontWeight: 400 }}>
                                Trip ID:
                            </Typography>
                            {loading ? (
                                <Skeleton sx={{ ml: 1 }} width={100} />
                            ) : (
                                <>
                                    <BoldText component="span" ml={1}>
                                        {selectedRide?.short_id}
                                    </BoldText>
                                    <IconButton onClick={() => navigator.clipboard.writeText(selectedRide?.short_id)} size="small" sx={{ ml: 1, p: 0 }}>
                                        <MdContentCopy />
                                    </IconButton>
                                </>
                            )}
                        </Typography>
                    </InfoBox>
                    <InfoBox item xs={12} sm={6}>
                        <Typography variant="body1" sx={{ color: '#6C757D', fontSize: 12, display: 'flex' }}>
                            <Typography component={'span'} sx={{ color: '#6C757D', fontSize: 12, fontWeight: 400 }}>Distance:</Typography>
                            {loading ? <Skeleton width={50} sx={{ ml: 1 }} /> : <BoldText component="span" ml={1}>{distanceMeterToUnit(selectedRide?.estimation_distance, true)}</BoldText>}
                        </Typography>
                    </InfoBox>
                    <InfoBox item xs={12} sm={6}>
                        <Typography variant="body1" sx={{ color: '#6C757D', fontSize: 12, display: 'flex' }}>
                            <Typography component={'span'} sx={{ color: '#6C757D', fontSize: 12, fontWeight: 400 }}> Driver Waiting Time:</Typography>
                            {loading ? <Skeleton width={50} sx={{ ml: 1 }} /> : <BoldText component="span" ml={1}>
                                {getWaitingTime(
                                    selectedRide?.arrival_pickup_at,
                                    selectedRide?.pickedup_at
                                )}</BoldText>}
                        </Typography>
                    </InfoBox>
                    <InfoBox item xs={12} sm={6}>
                        <Typography variant="body1" sx={{ color: '#6C757D', fontSize: 12, display: 'flex' }}>
                            <Typography component={'span'} sx={{ color: '#6C757D', fontSize: 12, fontWeight: 400 }}>Passenger Waiting Time:</Typography>
                            {loading ? <Skeleton width={50} sx={{ ml: 1 }} /> : <BoldText component="span" ml={1}>
                                {getWaitingTime(
                                    selectedRide?.assigned_at,
                                    selectedRide?.arrival_pickup_at
                                )}
                            </BoldText>}
                        </Typography>
                    </InfoBox>
                </Section>
                <Box sx={{ mt: 2 }}>
                    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}>
                        {!isSmallScreen && (
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mr: 2 }}>
                                <img src={location} alt="loc" />
                                <DotDivider />
                                {selectedRide?.stop_points && (
                                    <>
                                        {(selectedRide?.stop_points?.length <= 2) || (showAll) ? (
                                            selectedRide?.stop_points?.map((_, index) => (
                                                <React.Fragment key={index}>
                                                    <img src={stopIcon} alt="stop" style={{ height: "38px", width: "38px" }} />
                                                    {<DotDivider />}
                                                </React.Fragment>
                                            ))
                                        ) : (
                                            <>
                                                <img src={stopIcon} alt="stop" style={{ height: "38px", width: "38px" }} />
                                                <DotDivider />
                                            </>
                                        )}
                                    </>
                                )}
                                <img src={icon} alt="loc" />
                            </Box>
                        )}
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ mt: 1, width: '100%' }}>
                                <AddressText sx={{ color: colorConfigs?.sidebar?.bg }}>
                                    Pick up
                                </AddressText>
                                {loading ? (
                                    <Skeleton width="100%" />
                                ) : (
                                    <TimeText>{selectedRide?.pickup_address?.address}</TimeText>
                                )}
                            </Box>
                            {!showAll && selectedRide?.stop_points?.length > 0 && selectedRide?.stop_points?.length > 2 && (
                                <Box sx={{ mt: 2, width: '100%' }} style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'start',
                                }}>
                                    <AddressText sx={{ color: colorConfigs?.sidebar?.bg }}>
                                        Stop points : {selectedRide?.stop_points?.length}
                                    </AddressText>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        width: "20px",
                                        marginLeft: "25px"
                                    }}>
                                        <ExpandMoreIcon
                                            onClick={() => setShowAll(true)}
                                            sx={{
                                                fontSize: '26px',
                                                cursor: 'pointer',
                                                color: colorConfigs.sidebar.bg,
                                                transition: 'transform 0.2s ease-in-out',
                                                '&:hover': {
                                                    transform: 'scale(1.2)',
                                                }
                                            }}
                                        />                                    </div>
                                </Box>
                            )}
                            {(showAll || selectedRide?.stop_points?.length <= 2) && selectedRide?.stop_points?.map((stopPoint, index) => (
                                <Box sx={{ mt: 2, width: '100%' }} key={index}>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'start',
                                    }}>
                                        <AddressText sx={{ color: colorConfigs?.sidebar?.bg }}>
                                            Stop point:{index + 1}
                                        </AddressText>
                                        {index === 0 && (
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                width: "20px",
                                                marginLeft: "41px"
                                            }}>
                                                {showAll && (
                                                    <ExpandLessIcon
                                                        onClick={() => setShowAll(false)}
                                                        sx={{
                                                            fontSize: '26px',
                                                            cursor: 'pointer',
                                                            color: colorConfigs.sidebar.bg,
                                                            transition: 'transform 0.2s ease-in-out',
                                                            '&:hover': {
                                                                transform: 'scale(1.2)',
                                                            }
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        )}
                                    </div>

                                    {loading ? (
                                        <Skeleton width="100%" />
                                    ) : (
                                        <TimeText>{stopPoint?.address}</TimeText>
                                    )}
                                </Box>
                            ))}
                            <Box sx={{ mt: 2 }}>
                                <AddressText sx={{ color: '#03992D' }}>
                                    Drop off
                                </AddressText>
                                {loading ? (
                                    <Skeleton width="100%" />
                                ) : (
                                    <TimeText>{selectedRide?.delivery_address?.address}</TimeText>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Card>

            <Card>
                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'space-between', alignItems: 'start' }}>
                    <Typography variant="body1" sx={{ color: '#6C757D', fontSize: 12, mr: 3, display: 'flex' }}>
                        <Typography component={'span'} sx={{ color: '#6C757D', fontSize: 12, fontWeight: 400 }}>Estimated Fare:</Typography>
                        {loading ? <Skeleton width={50} sx={{ ml: 1 }} /> : <BoldText component="span" ml={1}>${selectedRide?.estimation_cost}</BoldText>}
                    </Typography>
                    <Typography variant="body1" sx={{ color: '#6C757D', fontSize: 12, fontWeight: 700, mr: 3, display: 'flex' }}>
                        <Typography component={'span'} sx={{ color: '#6C757D', fontSize: 12, fontWeight: 400 }}>Payment Type:</Typography>
                        {loading ? <Skeleton width={50} sx={{ ml: 1 }} /> : <BoldText component="span" ml={1}>{selectedRide?.pay_type && selectedRide?.pay_type === 'cash' ? 'Cash' : 'Card'}</BoldText>}
                    </Typography>
                    <Typography variant="body1" sx={{ color: '#6C757D', fontSize: 12, fontWeight: 700, mr: 3, display: 'flex' }}>
                        <Typography component={'span'} sx={{ color: '#6C757D', fontSize: 12, fontWeight: 400 }}>Payment Status:</Typography>
                        {loading ? <Skeleton width={50} sx={{ ml: 1 }} /> : <GreenText component="span" ml={1}>{selectedRide?.payment_status}</GreenText>}
                    </Typography>
                </Box>
            </Card>

            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Card sx={{ height: '100%' }}>
                        <CardHeader>
                            <Typography sx={{ fontWeight: '800', fontSize: 14, color: '#172B4D' }}>
                                Driver Info
                            </Typography>
                            <Box size="small" onClick={() => openChatDriverAndCustomer('driver')} sx={{ color: colorConfigs?.sidebar?.bg, cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', textDecoration: 'underline' }}>
                                <IoChatbubbleEllipsesSharp style={{ transform: 'rotateY(180deg)' }} size={20} />
                                <Typography variant="body2" sx={{ fontWeight: 'bold', ml: 0.5 }}>
                                    Send Message
                                </Typography>
                            </Box>
                        </CardHeader>
                        <Box sx={{ display: 'flex', alignItems: 'start', mt: 1 }}>
                            {loading ? (
                                <Skeleton sx={{ mr: 1 }} variant="rectangular" width={110} height={110} />
                            ) : (
                                <Avatar alt="Driver photo" src={selectedRide?.driver_info?.profile_picture} sx={{ width: 90, height: 90, mr: 2, borderRadius: 2 }} />
                            )}
                            <Box sx={{ width: '100%' }}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start' }}>
                                    {loading ? (
                                        <Skeleton width={100} />
                                    ) : (
                                        <BoldText mr={1}>
                                            {selectedRide?.driver_info?.first_name} {selectedRide?.driver_info?.last_name}
                                        </BoldText>
                                    )}
                                    {loading ? (
                                        <Skeleton width={50} />
                                    ) : (
                                        <Box sx={{ background: '#F4F4F4', borderRadius: 5, display: 'flex', alignItems: 'center', justifyContent: 'start', px: 1, textAlign: 'center', width: 'fit-content' }}>
                                            <Star sx={{ color: '#FFD700', fontSize: 20 }} /> <Typography sx={{ fontSize: 12, color: '#495974', fontWeight: 700 }}>{selectedRide?.driver_info?.review_avg}</Typography>
                                        </Box>
                                    )}
                                </Box>
                                {loading ? (
                                    <Skeleton width="100%" />
                                ) : (
                                    <InfoText>
                                        ID No. {selectedRide?.driver_info?.uid?.slice(0, 10)}...
                                        <IconButton onClick={() => navigator.clipboard.writeText(selectedRide?.driver_info?.uid)} size='small' sx={{ ml: 1, p: 0 }}>
                                            <MdContentCopy />
                                        </IconButton>
                                    </InfoText>
                                )}
                                {loading ? <Skeleton width="100%" /> : <ContactInfo variant="body2"><MdEmail /> {selectedRide?.driver_info?.email}</ContactInfo>}
                                {loading ? <Skeleton width="100%" /> : <ContactInfo variant="body2"><FaPhone /> {ClickablePhoneNumber(selectedRide?.driver_info?.code_phone_number, selectedRide?.driver_info?.phone_number)}</ContactInfo>}
                            </Box>
                        </Box>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Card sx={{ height: '100%' }}>
                        <CardHeader>
                            <Typography sx={{ fontWeight: '800', fontSize: 14, color: '#172B4D' }}>
                                Passenger Info
                            </Typography>
                            {selectedRide?.customer_details?.fb_uid && <Box size="small" onClick={() => openChatDriverAndCustomer('customer')} sx={{ color: colorConfigs?.sidebar?.bg, cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', textDecoration: 'underline' }}>
                                <IoChatbubbleEllipsesSharp style={{ transform: 'rotateY(180deg)' }} size={20} />
                                <Typography variant="body2" sx={{ fontWeight: 'bold', ml: 0.5 }}>
                                    Send Message
                                </Typography>
                            </Box>}
                        </CardHeader>
                        <Box sx={{ display: 'flex', alignItems: 'start', mt: 1 }}>
                            {loading ? (
                                <Skeleton sx={{ mr: 1 }} variant="rectangular" width={110} height={110} />
                            ) : (
                                <Avatar alt="Passenger" src={selectedRide?.customer_details?.profile_picture} sx={{ width: 90, height: 90, mr: 2, borderRadius: 2 }} />
                            )}
                            <Box sx={{ width: '100%' }}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start', width: '100%' }}>
                                    {loading ? (
                                        <Skeleton width={100} />
                                    ) : (
                                        <BoldText mr={1}>
                                            {selectedRide?.customer_details?.first_name} {selectedRide?.customer_details?.last_name}
                                        </BoldText>
                                    )}
                                    {loading ? (
                                        <Skeleton width={50} />
                                    ) : (
                                        <Box sx={{ background: '#F4F4F4', borderRadius: 5, display: 'flex', alignItems: 'center', justifyContent: 'start', px: 1, textAlign: 'center', width: 'fit-content' }}>
                                            <Star sx={{ color: '#FFD700', fontSize: 20 }} /> <Typography sx={{ fontSize: 12, color: '#495974', fontWeight: 700 }}>{selectedRide?.customer_details?.review_avg}</Typography>
                                        </Box>
                                    )}
                                </Box>
                                {loading ? (
                                    <Skeleton width="100%" />
                                ) : (
                                    <InfoText>
                                        ID No. {selectedRide?.customer_details?.fb_uid?.slice(0, 10)}...
                                        <IconButton onClick={() => navigator.clipboard.writeText(selectedRide?.customer_details?.fb_uid)} size='small' sx={{ ml: 1, p: 0 }}>
                                            <MdContentCopy />
                                        </IconButton>
                                    </InfoText>
                                )}
                                {loading ? <Skeleton width="100%" /> : selectedRide?.customer_details?.email && <ContactInfo variant="body2"><MdEmail /> {selectedRide?.customer_details?.email}</ContactInfo>}
                                {loading ? <Skeleton width="100%" /> : <ContactInfo variant="body2"><FaPhone /> {ClickablePhoneNumber(selectedRide?.customer_details?.code_phone_number, selectedRide?.customer_details?.phone_number)}</ContactInfo>}
                            </Box>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
            <Card>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1, backgroundColor: '#f5f7f8', borderRadius: 1, px: 2, mb: 1 }}>
                    <Typography variant="body1" sx={{ color: '#172B4D', fontWeight: 700, fontSize: 14 }}>
                        Vehicle Info
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'start', alignItems: 'start' }}>
                    {loading ? (
                        <Skeleton variant="rectangular" width={127} height={90} sx={{ mr: 2 }} />
                    ) : (
                        <>
                            {
                                selectedRide?.driver_info?.vehicle_info?.vehicle_images?.map((image, index) => {
                                    return <Avatar key={index} alt="Vehicle 1" src={image} sx={{ width: 127, height: 90, mr: 2, borderRadius: 2 }} />
                                })
                            }
                        </>
                    )}
                    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'start', alignItems: 'start', flexWrap: 'wrap', mt: 1, gap: { xs: 0, md: 2 } }}>
                        <Box>
                            <Typography variant="body2" sx={{ color: '##172B4D', fontWeight: 700, fontSize: 12, display: 'flex', gap: 1 }}>
                                <span style={{ color: '#7A869A', fontSize: 12, fontWeight: 400, textTransform: 'uppercase' }}>Vehicle NO: </span> {loading ? <Skeleton sx={{ ml: 1 }} width={50} /> : selectedRide?.driver_info?.vehicle_info?.license_plate}
                            </Typography>
                            <Typography variant="body2" sx={{ color: '##172B4D', fontWeight: 700, fontSize: 12, display: 'flex', gap: 1 }}>
                                <span style={{ color: '#7A869A', fontSize: 12, fontWeight: 400 }}>Vehicle Make: </span> {loading ? <Skeleton sx={{ ml: 1 }} width={50} /> : selectedRide?.driver_info?.vehicle_info?.vehicle_make}
                            </Typography>
                            <Typography variant="body2" sx={{ color: '##172B4D', fontWeight: 700, fontSize: 12, display: 'flex', gap: 1 }}>
                                <span style={{ color: '#7A869A', fontSize: 12, fontWeight: 400 }}>Vehicle Type: </span> {loading ? <Skeleton sx={{ ml: 1 }} width={50} /> : selectedRide?.driver_info?.vehicle_info?.license_plate}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant="body2" sx={{ color: '##172B4D', fontWeight: 700, fontSize: 12, display: 'flex', gap: 1 }}>
                                <span style={{ color: '#7A869A', fontSize: 12, fontWeight: 400 }}>Vehicle Model: </span>  {loading ? <Skeleton sx={{ ml: 1 }} width={50} /> : selectedRide?.driver_info?.vehicle_info?.vehicle_model}
                            </Typography>
                            <Typography variant="body2" sx={{ color: '##172B4D', fontWeight: 700, fontSize: 12, display: 'flex', gap: 1 }}>
                                <span style={{ color: '#7A869A', fontSize: 12, fontWeight: 400 }}>Year: </span> {loading ? <Skeleton sx={{ ml: 1 }} width={50} /> : selectedRide?.driver_info?.vehicle_info?.vehicle_year}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Card>

            <Box sx={{ display: 'flex', justifyContent: 'start', gap: 3, alignItems: 'center', mt: 2, flexWrap: 'wrap' }}>
                {selectedRide?.customer_details?.fb_uid && <BlueButton onClick={() => openChatRide()} variant="contained" sx={{ mt: 0, borderRadius: 1 }}>
                    <Typography variant="body1" sx={{ fontWeight: 500, fontSize: 16, textTransform: 'capitalize', px: 3 }}>
                        Chat
                    </Typography>
                </BlueButton>}
                {selectedRide && (selectedRide?.status !== 'pickedup' && selectedRide?.status !== 'enroute_to_delivery' && selectedRide?.status !== 'waiting_delivery' && selectedRide?.status !== 'delivered' && selectedRide?.status !== 'canceled') && <Button
                    variant="outlined"
                    sx={{
                        borderColor: 'red',
                        color: 'red',
                        fontWeight: 'bold',
                        borderRadius: 1,
                        textTransform: 'none',
                        '&:hover': {
                            borderColor: 'red',
                            backgroundColor: 'transparent',
                        },
                    }}
                    onClick={handleCancelRide}
                >
                    Cancel Ride
                </Button>}
                {/* <SuspendButton sx={{ mt: 0 }} variant="contained">
                    <IoMdClose size={20} />
                    <Typography variant="body1" sx={{ fontWeight: 300, fontSize: 16, textTransform: 'capitalize', ml: 1 }}>
                        Suspend Driver
                    </Typography>
                </SuspendButton> */}
            </Box>
        </ModalContainer>
    );
};

export default RideDetailsModal;