import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Typography
} from "@mui/material";
import { SubmitButton, TextInput } from "@zetaton-dev/zetaton-components-bank";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { storageSdk } from "../../configs/firebase";
// import Loading from "react-fullscreen-loading";
import { makeStyles } from "@mui/styles";
import { Image, Upload } from "antd";
import { FcAddImage } from "react-icons/fc";
const useStyles = makeStyles({
    customUpload: {
        "& .ant-upload": {
            "&.ant-upload-select": {
                width: "226px !important",
                height: "136px !important",
            },
        },
        "& .ant-upload-list-item-container": {
            width: "226px !important",
            height: "136px !important",
            marginTop: "5px",
        },
    },
});

const AddCarType = ({
    open,
    onClose,
    handleAddRideType,
    actionType,
    newRideType,
    setNewRideType,
    colorConfigs,
    showSnackbar,
    initialValues,
}) => {
    const [photoPreview, setPhotoPreview] = useState(null);
    const [formikInitialValues, setFormikInitialValues] = useState(initialValues);
    // const [loading, setLoading] = useState(false);
    const [temp, setTemp] = useState("");
    const [url, setUrl] = useState(null);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [formErrors, setErrors] = useState({});
    const classes = useStyles();
    const handlePreview = async (file) => {
        setPreviewImage(url);
        setPreviewOpen(true);
    };
    useEffect(() => {
        if (open) {
            setFormikInitialValues(initialValues);

        }
    }, [open, initialValues]);

    useEffect(() => {
        setNewRideType({ ...newRideType, enabled: true })
        console.log(newRideType);
    }, []);




    const handleNameChange = (e, handleChange) => {
        const { value } = e.target;
        // console.log(value);
        const filteredValue = value.replace(/[^a-zA-Z0-9\s{}@]/g, "");
        handleChange("name")(filteredValue);
        setTemp(value)
    };

    const handlePhotoChange = (e, setFieldValue) => {
        const file = e.target.files[0];
        if (file) {
            setPhotoPreview(URL.createObjectURL(file));
            setFieldValue("photo", file);
        }
    };

    const resetForm = () => {
        setFormikInitialValues(initialValues);
        setPhotoPreview(null);
        setUrl(null);
    };

    const handleClose = () => {
        onClose();
        resetForm();
        setTemp("");
        setErrors({});
    };

    const handleFormSubmit = async (values) => {
        // setLoading(true);  // Set loading to true
        let error = {};
        if (!url) {
            error.image = "Please upload car image";
        }
        if (Object.keys(error).length > 0) {
            setErrors(error);
        } else {
            let photoUrl = url;
            // let photoUrl = values.photo;
            // if (values.photo && typeof values.photo !== "string") {
            //     photoUrl = await handleImageUpload(values.photo);
            // }
            const newValues = { ...values, photo: photoUrl };
            setNewRideType(newValues);
            handleAddRideType(newValues);
            onClose();
            handleClose();
            // setLoading(false);  // Set loading to false
            setTemp("")
        }
    };

    const handleImageUpload = async (file) => {
        const uploadTask = storageSdk.ref(`/rides/${file.name}`).put(file);
        return new Promise((resolve, reject) => {
            uploadTask.on(
                "state_changed",
                (snapShot) => { },
                (err) => reject(err),
                async () => {
                    try {
                        const fireBaseUrl = await storageSdk
                            .ref("rides")
                            .child(file.name)
                            .getDownloadURL();
                        resolve(fireBaseUrl);
                        console.log(fireBaseUrl);
                    } catch (error) {
                        reject(error);
                    }

                }
            );
        });
    };
    const handleChangeInput = (file) => {
        if (file && typeof file === "object" && file.originFileObj) {
            const uploadTask = storageSdk
                .ref(`/rides/${file.name}`)
                .put(file.originFileObj);
            uploadTask.on(
                "state_changed",
                (snapShot) => {
                    //takes a snap shot of the process as it is happening
                    console.log(snapShot);
                },
                (err) => {
                    //catches the errors
                    console.log(err);
                },
                () => {
                    // gets the functions from storage references the image storage in firebase by the children
                    // gets the download URL then sets the image from firebase as the value for the imgUrl key:
                    storageSdk
                        .ref("rides")
                        .child(file.name)
                        .getDownloadURL()
                        .then((fireBaseUrl) => {
                            setUrl(fireBaseUrl);
                        });
                }
            );
        } else {
            setUrl("");
        }
    };

    return (
        <Dialog
            style={{ zIndex: 10, borderRadius: "30px" }}
            open={open}
            onClose={handleClose}
            keepMounted
            PaperProps={{
                style: {
                    width: '674px',
                    maxWidth: 'none',
                },
            }}
            fullWidth
            aria-describedby="alert-dialog-slide-description">
            <DialogContent
                style={{
                    padding: "30px",
                    width: "100%",
                    maxHeight: "100%",
                    overflowY: "auto",
                }}>
                <DialogContentText style={{ width: "100%" }}>
                    <Typography
                        variant="h5"
                        style={{
                            fontWeight: "600",
                            color: "#172B4D",
                            marginTop: "10px",
                            marginBottom: "20px",
                            // width: "165px",
                            heght: "27px",
                            size: "24px",
                            font: "Poppins",
                            lineHeight: "27px"
                        }}>
                        {"Add Ride Type"}
                    </Typography>

                    <Formik
                        style={{ height: "100%" }}
                        initialValues={formikInitialValues}
                        enableReinitialize
                        validate={(values) => {
                            const errors = {};
                            if (!values.name) {
                                errors.name = "Please enter the Ride type name";
                            }
                            // if (!values.photo) {
                            //     errors.photo = "Please upload car image";
                            // }
                            return errors;
                        }}
                        onSubmit={handleFormSubmit}>
                        {({
                            handleBlur,
                            handleChange,
                            values,
                            errors,
                            handleSubmit,
                            setFieldValue,
                            touched,
                            setFieldTouched,
                        }) => (
                            <form onSubmit={handleSubmit} >
                                <Box
                                    display="flex"
                                    style={{ width: "196px", heght: "40px", marginBottom: "20px" }}>
                                    <SubmitButton
                                        enabled="true"
                                        variant="outlined"
                                        onClick={() =>
                                            setNewRideType({ ...newRideType, enabled: true })
                                        }
                                        style={{
                                            width: "96px",
                                            heght: "43px",
                                            borderTopLeftRadius: "88px",
                                            borderBottomLeftRadius: "88px",
                                            borderRight: "1px solid black",
                                            borderColor: colorConfigs?.sidebar?.bg,
                                            color: newRideType.enabled
                                                ? "white"
                                                : colorConfigs?.sidebar?.bg,
                                            backgroundColor: newRideType.enabled
                                                ? colorConfigs?.sidebar?.bg
                                                : "white",
                                        }}
                                        label={"Enable"}
                                    />
                                    <SubmitButton
                                        variant="outlined"
                                        onClick={() =>
                                            setNewRideType({ ...newRideType, enabled: false })
                                        }
                                        style={{
                                            width: "96px",
                                            heght: "43px",
                                            borderTopRightRadius: "88px",
                                            borderBottomRightRadius: "88px",
                                            borderRight: "1px solid black",
                                            borderColor: colorConfigs?.sidebar?.bg,
                                            color: !newRideType.enabled ? "white" : "#42526E",
                                            backgroundColor: !newRideType.enabled
                                                ? colorConfigs?.sidebar?.bg
                                                : "white",
                                        }}
                                        label={"Disable"}
                                    />
                                </Box>
                                <Box style={{ marginBottom: "10px" }}>
                                    <TextInput
                                        label={<span style={{
                                            width: "53px",
                                            height: "17px",
                                            font: "Poppins",
                                            fontWeight: "400",
                                            size: "14px",
                                            lineHeight: "17px",
                                            paddingTop: "12px",

                                        }}>Name</span>}
                                        fullWidth
                                        name="name"
                                        value={temp}
                                        required
                                        onChange={(e) => handleNameChange(e, handleChange)}
                                        variant="outlined"
                                        onBlur={handleBlur("name")}
                                        placeholder={
                                            actionType === "edit"
                                                ? newRideType.name
                                                : ""
                                        }

                                        focused
                                        filled="true"
                                        style={{ marginBottom: "10px", marginTop: "5px", height: "61px", width: "620px" }}
                                        InputLabelProps={{
                                            style: {
                                                width: "69px",
                                                height: "23px",
                                                gap: "8px",
                                            },
                                        }}
                                        InputProps={{
                                            style: {
                                                borderColor: '#BBC1CE',
                                                borderWidth: '1px',
                                                borderRadius: "8px",
                                                height: "48px",
                                                width: "620px",
                                                padding: "13px 16px 13px 16px",
                                            },
                                        }}
                                    />
                                    {errors.name && (
                                        <Typography variant="caption" style={{ color: "red" }}>
                                            {errors.name}
                                        </Typography>
                                    )}
                                </Box>
                                <span style={{
                                    width: "182px",
                                    height: "17px",
                                    font: "Poppins",
                                    fontWeight: "400",
                                    size: "14px",
                                    lineHeight: "17px"
                                }}>
                                    Upload Ride Type Image<span style={{
                                        color: "red",
                                        font: "Poppins",
                                        fontWeight: "400",
                                        size: "14px",
                                        lineHeight: "17px"
                                    }}>*</span>
                                </span>
                                <Box

                                    display="flex"
                                    alignItems="left"
                                    justifyContent="left"
                                    style={{
                                        width: "266px",
                                        height: "136px",
                                        maxHeight: "300px",
                                        overflow: "none",
                                        marginBottom: "20px",
                                        position: "relative",
                                        radius: "8px",
                                        border: "1px",

                                    }}>
                                    {/* {photoPreview ? (
                                        <Box
                                            style={{
                                                width: "50%",
                                            }}>
                                            <img
                                                src={photoPreview}
                                                alt="Uploaded Preview"
                                                style={{
                                                    width: "100%",
                                                    height: "100%",
                                                    objectFit: "cover",
                                                    borderRadius: "10px",
                                                }}
                                            />
                                        </Box>
                                    ) : (
                                        <div
                                            style={{
                                                width: "226px",
                                                height: "136px",
                                                display: "flex",
                                                radius: "8px",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                border: "1px dashed  ",
                                                borderRadius: "8px",
                                                padding: "13px 16px 13px 16px",
                                                background: "#F5F5F5",
                                                marginTop: "5px"
                                            }}>
                                            <IconButton
                                                component="label"
                                                required
                                                style={{
                                                    background: 'colorConfigs.sidebar.bg',
                                                    borderRadius: "10%",
                                                    width: "26px",
                                                    height: "26px",
                                                    marginBottom: "15px",
                                                }}>
                                                <img
                                                    style={{ width: "40px", height: "40px" }}
                                                    src={UploadIcon}
                                                    alt="add-img"
                                                />
                                                <input
                                                    type="file"
                                                    hidden
                                                    style={{ display: "none" }}
                                                    multiple
                                                    size="20971520"
                                                    accept="image/png, image/jpeg"
                                                    required
                                                    onChange={(e) => {
                                                        handlePhotoChange(e, setFieldValue);
                                                        setFieldTouched("photo", true);
                                                    }}
                                                    onClick={(e) => (e.target.value = null)}
                                                />
                                            </IconButton>
                                            <span
                                                style={{
                                                    // width: "100px",
                                                    height: "18px",
                                                    fontWeight: "500",
                                                    color: "#7A869A",
                                                    fontFamily: "Poppins",
                                                    fontSize: "14px",
                                                    lineHeight: "18px"
                                                }}>
                                                Tap to Upload
                                            </span>
                                        </div>
                                    )} */}
                                    <Upload
                                        listType="picture-card"
                                        fileList={
                                            url
                                                ? [
                                                    {
                                                        uid: "1",
                                                        name: "image.png",
                                                        status: "done",
                                                        url: url,
                                                    },
                                                ]
                                                : []
                                        }
                                        onPreview={handlePreview}
                                        onRemove={() => setUrl("")}
                                        onChange={({ file }) => handleChangeInput(file)}
                                        name="file"
                                        accept="image/*"
                                        className={classes.customUpload}
                                    >
                                        {!url && (
                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                <button
                                                    style={{
                                                        border: 0,
                                                        background: "none",
                                                        color: colorConfigs.lightGrayText,
                                                    }}
                                                    type="button"
                                                >
                                                    <FcAddImage size={"50px"} />
                                                    <Typography
                                                        style={{
                                                            marginTop: 8,
                                                            color: colorConfigs.grayText,
                                                        }}
                                                    >
                                                        Upload your file here
                                                    </Typography>
                                                </button>
                                            </div>
                                        )}
                                    </Upload>
                                    {previewImage && (
                                        <Image
                                            wrapperStyle={{ display: "none" }}
                                            preview={{
                                                visible: previewOpen,
                                                onVisibleChange: (visible) => setPreviewOpen(visible),
                                                afterOpenChange: (visible) =>
                                                    !visible && setPreviewImage(""),
                                            }}
                                            src={previewImage}
                                        />
                                    )}
                                </Box>
                                {formErrors?.image && (
                                    <Typography variant="caption" style={{ color: "red" }}>
                                        {formErrors?.image}
                                    </Typography>
                                )}
                                <DialogActions
                                    style={{
                                        display: "flex",
                                        alignItems: "end",

                                        justifyContent: "end"

                                    }}>
                                    <Button
                                        variant="outlined"
                                        style={{
                                            color: colorConfigs.sidebar.bg,
                                            borderColor: "lightgray",
                                            width: "170px",
                                            height: "48px",
                                            radius: "8px",
                                            gap: "8px",
                                            fontWeight: "600",
                                            lineHeight: "24px",
                                            font: "Poppins"


                                        }}
                                        onClick={handleClose}>
                                        Cancel
                                    </Button>

                                    <Button
                                        variant="contained"
                                        style={{
                                            background: colorConfigs?.sidebar?.bg,
                                            color: "white",
                                            borderColor: "lightgray",
                                            width: "170px",
                                            height: "48px",
                                            radius: "8px",
                                            gap: "8px",
                                            fontWeight: "600",
                                            lineHeight: "24px",
                                            font: "Poppins"
                                        }}

                                        type="submit">
                                        Add
                                    </Button>
                                </DialogActions>
                            </form>
                        )}
                    </Formik>

                </DialogContentText>
            </DialogContent>
        </Dialog >
    );
};

export default AddCarType;
