import * as React from "react"
import { memo } from "react"
import colorConfigs from "../../configs/colorConfigs"
const SvgComponent = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={25}
        height={22}
        fill="none"
        {...props}
    >
        <path
            fill={colorConfigs?.sidebar?.bg}
            fillRule="evenodd"
            d="M17.205 0a3.75 3.75 0 0 1 3.354 2.072l1.732 3.464c.305-.125.605-.26.9-.405A1.25 1.25 0 1 1 24.31 7.37c-.3.133-.603.263-.907.39l1.202 2.403a3.75 3.75 0 0 1 .396 1.68V15a3.751 3.751 0 0 1-1.25 2.795v1.58a1.875 1.875 0 1 1-3.75 0v-.625H5v.625a1.875 1.875 0 1 1-3.75 0v-1.58A3.742 3.742 0 0 1 0 15v-3.16c0-.582.136-1.156.396-1.676l1.192-2.385c-.302-.127-.6-.263-.894-.409A1.262 1.262 0 0 1 .13 5.691a1.25 1.25 0 0 1 1.678-.56c.296.146.596.281.9.405L4.44 2.074A3.75 3.75 0 0 1 7.795 0h9.41ZM6.875 11.25a1.875 1.875 0 1 0 0 3.75 1.875 1.875 0 0 0 0-3.75Zm11.25 0a1.875 1.875 0 1 0 0 3.75 1.875 1.875 0 0 0 0-3.75Zm-.92-8.75h-9.41a1.25 1.25 0 0 0-1.04.556l-.077.135L5.09 6.364C7.025 6.944 9.633 7.5 12.5 7.5c2.678 0 5.126-.485 7.012-1.021l.397-.115-1.586-3.173a1.25 1.25 0 0 0-.963-.681l-.154-.01h-.001Z"
            clipRule="evenodd"
        />
    </svg>
)
const CarFill = memo(SvgComponent)
export default CarFill
