import Typography from "@mui/material/Typography";
import React from "react";
import { useSelector } from "react-redux";
import MessageViewerPrivate from "./message-viewer";

const useStyles = {
  drawer: {
    padding: "16px 24px",
    width: "360px",
    height: "calc(100vh - 134px)",
    boxSizing: "border-box",
    borderRight: "2px solid rgb(240,240,240)",
  },
  content: {
    flex: 1,
    // height: "calc(100vh - 134px)",
    boxSizing: "border-box",
    width: "100%",
  },
};

const MessagingPrivate = ({ allowSend = false }) => {
  const selectedChat = useSelector((state) => state.appState.selectedChat);
  const selectedRide = useSelector((state) => state.appState.selectedRide);
  const lastRideChat = useSelector((state) => state.appState.lastRideChat);
  const outChat = useSelector((state) => state.appState.outChat);
  return (
    <div
      style={{
        display: "flex",
        background: "white",
        borderRadius: "6px",
        boxShadow: "0 1px 2px rgb(0,0,0,0.1)",
        overflow: "hidden",
        marginTop: "0px",
      }}
    >
      <div style={useStyles.content}>
        {selectedRide?._id || selectedChat?.rideId || lastRideChat?.rideId || lastRideChat?._id ? (
          <MessageViewerPrivate allowSend={allowSend} />
        ) : (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography variant="body1" bold>
              You don’t have a message selected
            </Typography>
            <Typography
              variant="body2"
              regular
              style={{
                color: "grey",
                maxWidth: "350px",
                textAlign: "center",
                marginTop: "8px",
              }}
            >
              All messages are private conversations between Admins &
              Drivers,Customers on {process.env.REACT_APP_ENV==='women_first'?'women-s-first':"RideWyze"}.
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};

export default MessagingPrivate;
