import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Avatar,
  Snackbar,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Status from "../../../pages/status/status";
import location from "../../../assets/images/location.png";
import icon from "../../../assets/images/Icon.png";
import stopIcon from "../../../assets/images/stop.png";
import { getMethod } from "../../../pages/Hook/UseCustomApi";
import Error5xx from "../HandelError5xx";
import colorConfigs from "../../../configs/colorConfigs";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import SecurityAlertSmall from "../../../assets/svg/SecurityAlertSmall";

const styles = {
  taskCard: {
    display: "flex",
    flexDirection: "column",
    background: "white",
    position: "relative",
    borderRadius: "6px",
    cursor: "pointer",
    boxShadow: "0 1px 2px rgb(0,0,0,0.1)",
    transition: "0.3s",
    border: "1.5px solid rgb(245,245,245)",
    "&:hover": {
      boxShadow: "0 10px 20px -14px rgba(50,50,50,0.3)",
    },
  },
  avatar: {
    height: "50px",
    width: "50px",
  },
  typography: {
    fontWeight: "bold",
  },
  text: {
    cursor: "pointer",
  },
};

const DotDivider = ({ singleDot }) => (
  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <div style={{ width: "4px", height: "4px", borderRadius: "50%", backgroundColor: "#E0E0E0", margin: "4px 0" }}></div>
    {!singleDot && (
      <>
        <div style={{ width: "4px", height: "4px", borderRadius: "50%", backgroundColor: "#E0E0E0", margin: "4px 0" }}></div>
        <div style={{ width: "4px", height: "4px", borderRadius: "50%", backgroundColor: "#E0E0E0", margin: "4px 0" }}></div>
      </>
    )}
  </div>
);

const DirversCard = ({ driver, onDriverClick, handleCenter, selectedDriver }) => {
  const [expanded, setExpanded] = useState(false);
  const [ride, setRide] = useState({});
  const [showAll, setShowAll] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  // Handle error5xx
  const [tryAgain, setTryAgain] = useState();
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [errorCount, setErrorCount] = useState(0);
  const [errorDialogCounter, setErrorDialogCounter] = useState(0);
  const handleError5xx = () => {
    setIsErrorDialogOpen(true);
    setErrorCount(prevCount => {
      const newCount = prevCount + 1;
      if (newCount === 1) {
        setErrorDialogCounter(5);
      } else if (newCount === 2) {
        setErrorDialogCounter(10);
      }
      return newCount;
    });
  };
  const handleCloseErrorDialog = () => {
    setIsErrorDialogOpen(false);
    if (errorCount <= 2) {
      handleErrorCountByClose();
    }
  };
  const handleErrorCountByClose = () => {
    setErrorCount(prevCount => {
      const newCount = prevCount - 1;
      if (newCount === 1) {
        setErrorDialogCounter(5);
      } else if (newCount === 2) {
        setErrorDialogCounter(10);
      }
      return newCount;
    });
  };

  const full_name = driver.first_name + " " + driver.last_name;

  useEffect(() => {
    if (
      driver &&
      driver?.active_ride_id !== undefined &&
      driver?.active_ride_id !== ""
    ) {
      getRide();
    }
  }, [driver?.status]);

  const getParams = () => {
    return {};
  };

  const getRide = () => {
    getMethod({
      url: `ride/${driver?.active_ride_id}`,
      params: getParams(),
      handleErrorMessage: showSnackbar
    })
      .then((response) => {
        let result = response?.data;
        setRide(result);
      })
      .catch((error) => {
        console.log("Error on getting active ride:", error);
        if (error === "Internal Server Error: Server error occurred.") {
          handleError5xx();
          setTryAgain(() => getRide);
        }
      });
  };

  const handleChange = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  const hasStopPoints = ride?.stop_points?.length > 0;
  const stopPoints = ride?.stop_points || [];


  return (
    <>
      {/* handleError5xx */}
      <Error5xx open={isErrorDialogOpen} errCount={errorCount} count={errorDialogCounter} handleClose={handleCloseErrorDialog} tryAgain={tryAgain} />
      <Accordion
        sx={{
          boxShadow: "none",
          backgroundColor: selectedDriver === driver._id ? colorConfigs?.sidebar?.bg + 15 : "white",
          border: selectedDriver === driver._id ? `1px solid ${colorConfigs?.sidebar?.bg}` : "white",
          "&:before": {
            display: "none",
          },
        }}
        expanded={expanded}
        onChange={driver?.status !== 'free' ? handleChange : null}
      >
        <AccordionSummary
          expandIcon={ride && ride?.pickup_address && driver?.status !== 'free' && <ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <div onClick={handleCenter}>
            <div
              style={{
                display: "flex",
                padding: "8px",
                gap: "12px",
                alignItems: "center",
              }}
            >
              <div>
                <Avatar style={styles.avatar} src={driver?.profile_picture} />
              </div>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  gap: "8px",
                }}
              >
                <Typography
                  variant={"body2"}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    lineHeight: 1,
                    color: "#172B4D",
                    fontSize: "14px",
                    fontWeight: "700",
                    letterSpacing: "0.14px",
                    fontFamily: "Poppins",
                  }}
                >
                  {full_name}
                  {ride?.panic_button_driver && (<SecurityAlertSmall style={{ marginLeft: "15px" }} />)}
                </Typography>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    gap: "12px",
                  }}
                >
                  <Status status={driver.status} />
                </div>
              </div>
            </div>
          </div>
        </AccordionSummary>
        {ride && ride?.pickup_address && driver?.status !== 'free' && (
          <AccordionDetails>
            <>
              <Typography
                style={{
                  color: "#172B4D",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "700",
                }}
                variant="body1"
              >
                Trip ID: {ride?.short_id}
              </Typography>
              <div
                style={{
                  position: "relative",
                  fontFamily: "Poppins",
                  paddingTop: "12px",
                }}
              >
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    padding: "5px",
                  }}
                >
                  <img src={location} alt="loc" />
                  <Typography
                    variant="body2"
                    style={{
                      color: "#172B4D",
                      fontWeight: "600",
                      fontFamily: "Poppins",
                      marginLeft: "10px",
                    }}
                  >
                    {ride?.pickup_address.address}
                  </Typography>
                </div>


                <div>
                  {stopPoints && stopPoints?.length > 2 ? (
                    <div
                      style={{
                        display: "flex",
                        padding: "5px",
                        alignItems: "center",
                        justifyContent: "space-between",
                        margin: !showAll ? "20px 0" : "20px 0 0 0",
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img src={stopIcon} alt="loc" style={{ height: "38px", width: "38px" }} />
                        <Typography
                          variant="body2"
                          style={{
                            color: "#172B4D",
                            fontWeight: "600",
                            fontFamily: "Poppins",
                            marginLeft: "10px",
                          }}
                        >
                          Stop points : {stopPoints?.length}
                        </Typography>
                      </div>
                      <div>
                        {!showAll ? (
                          <ExpandMoreIcon onClick={() => setShowAll(true)} style={{ fontSize: '30px', cursor: 'pointer', color: colorConfigs.sidebar.bg }} />
                        ) : (
                          <ExpandLessIcon onClick={() => setShowAll(false)} style={{ fontSize: '30px', cursor: 'pointer', color: colorConfigs.sidebar.bg }} />
                        )}
                      </div>
                    </div>
                  ) : (
                    stopPoints && stopPoints?.map((stopPoint, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          padding: "5px",
                          alignItems: "center",
                        }}
                      >
                        <img src={stopIcon} alt="loc" style={{ height: "38px", width: "38px" }} />
                        <Typography
                          variant="body2"
                          style={{
                            color: "#172B4D",
                            fontWeight: "600",
                            fontFamily: "Poppins",
                            marginLeft: "10px",
                          }}
                        >
                          Stop point: {index + 1}
                          <br />
                          <Typography
                            variant="caption"
                            style={{
                              color: "black",
                              opacity: "0.8",
                              marginTop: "3px",
                            }}
                          >
                            {stopPoint.address}
                          </Typography>
                        </Typography>
                      </div>
                    ))
                  )}
                  {showAll && stopPoints && stopPoints.length > 2 && stopPoints?.map((stopPoint, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        padding: "10px",
                        alignItems: "center",
                        marginLeft: "60px",
                      }}
                    >
                      <img src={stopIcon} alt="loc" style={{ height: "38px", width: "38px" }} />
                      <Typography
                        variant="body2"
                        style={{
                          color: "#172B4D",
                          fontWeight: "600",
                          fontFamily: "Poppins",
                          marginLeft: "10px",
                        }}
                      >
                        Stop point: {index + 1}
                        <br />
                        <Typography
                          variant="caption"
                          style={{
                            color: "black",
                            opacity: "0.8",
                            marginTop: "3px",
                          }}
                        >
                          {stopPoint?.address}
                        </Typography>
                      </Typography>
                    </div>
                  ))}
                </div>

                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    padding: "5px",
                  }}
                >
                  <img src={icon} alt="loc" />
                  <Typography
                    variant="body2"
                    style={{
                      color: "#172B4D",
                      fontWeight: "600",
                      fontFamily: "Poppins",
                      marginLeft: "10px",
                    }}
                  >
                    {ride?.delivery_address.address}
                  </Typography>
                </div>
              </div>
            </>
          </AccordionDetails>
        )}
      </Accordion>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleSnackbarClose} severity="error">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default DirversCard;
