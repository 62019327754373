import { Star } from '@mui/icons-material';
import { Avatar, Box, Grid, IconButton, Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { FaPhone } from "react-icons/fa6";
import { IoMdClose } from 'react-icons/io';
import { IoChatbubbleEllipsesSharp } from 'react-icons/io5';
import { MdContentCopy, MdEmail } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux';
import colorConfigs from '../../../configs/colorConfigs';
import { selectDriver, selectRide, setLastSupportChat, updateOutChat } from '../../../redux/features/appStateSlice';
import { closeModal, openModal } from '../../../redux/features/modalSlice';
import { getMethod } from '../../Hook/UseCustomApi';
import DriverAndCustomerChatModal from './DriverAndCustomerChatModal';
import ClickablePhoneNumber from '../../../utils/ClickablePhoneNumber';

const ModalContainer = styled(Box)(({ theme }) => ({
    background: '#FFFFFF',
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    width: '706px',
    maxWidth: '95%',
    maxHeight: '94vh',
    overflow: 'auto',
    margin: 'auto',
    marginTop: '100px',
}));

const Header = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '3px 10px',
}));

const CloseButton = styled(Box)(({ theme }) => ({
    background: '#F0F0F0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer',
}));

const Card = styled(Box)(({ theme }) => ({
    background: 'white',
    padding: theme.spacing(1),
    boxShadow: 'rgba(0, 0, 0, 0.05) 0 4px 36px',
    borderRadius: theme.shape.borderRadius,
    margin: theme.spacing(1, 0, 0, 0),
}));

const CardHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#F4F4F4',
    padding: theme.spacing(1, 2),
    borderRadius: theme.shape.borderRadius,
}));

const ContactInfo = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(0.7),
    '& svg': {
        marginRight: theme.spacing(0.5),
        color: colorConfigs?.sidebar?.bg,
    },
}));

const InfoText = styled(Typography)(() => ({
    color: '#495974',
    fontSize: 12,
    fontWeight: 400,
}));

const BoldText = styled(Typography)(() => ({
    fontWeight: 700,
    color: '#172B4D',
    fontSize: 14,
}));

const DriverDetailsModal = ({ id, status }) => {
    const dispatch = useDispatch();
    const selectedDriver = useSelector((state) => state.appState.selectedDriver);
    const selectedRide = useSelector((state) => state.appState.selectedRide);
    const [loading, setLoading] = useState(false);

    const handleClose = () => {
        dispatch(closeModal());
    };

    const openChatDriver = () => {
        dispatch(openModal(<DriverAndCustomerChatModal id={id} status={status} handleClose={handleClose} />));
        dispatch(selectRide({ ...selectedRide, chat_type: 'driver' }));
        dispatch(setLastSupportChat({ ...selectedRide, type: 'driver',driver:`admin_${selectedRide?.driver_info?.fb_id}` }));
        dispatch(updateOutChat(true));
    };

    const getDriverDetails = async () => {
        try {
            setLoading(true);
            const response = await getMethod({ url: `driver/${id}` });
            dispatch(selectDriver(response.data));
            dispatch(selectRide({
                ...response.data,
                driver_info: {
                    fb_id: response.data?.fb_uid,
                    chat_type: 'driver',
                    code_phone_number: response.data?.code_phone_number,
                    phone_number: response.data?.phone_number,
                    profile_picture: response.data?.profile_picture,
                    first_name: response.data?.first_name,
                    last_name: response.data?.last_name,
                }
            }));
        } catch (error) {
            console.error("Error on getting drivers:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (id) {
            getDriverDetails();
        }
    }, [id]);

    return (
        <ModalContainer>
            <Header>
                <Typography variant="h6" sx={{ color: '#172B4D', fontWeight: 700 }}>
                    Car Details
                </Typography>
                <CloseButton onClick={handleClose}>
                    <IoMdClose size={20} />
                </CloseButton>
            </Header>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader>
                            <Typography sx={{ fontWeight: '800', fontSize: 14, color: '#172B4D' }}>
                                Driver Info
                            </Typography>
                            <Box onClick={openChatDriver} sx={{ color: colorConfigs?.sidebar?.bg, cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', textDecoration: 'underline' }}>
                                <IoChatbubbleEllipsesSharp style={{ transform: 'rotateY(180deg)' }} size={20} />
                                <Typography variant="body2" sx={{ fontWeight: 'bold', ml: 0.5 }}>
                                    Send Message
                                </Typography>
                            </Box>
                        </CardHeader>
                        <Box sx={{ display: 'flex', alignItems: 'start', mt: 1 }}>
                            {loading ? (
                                <Skeleton variant="rectangular" width={90} height={90} sx={{ mr: 2 }} />
                            ) : (
                                <Avatar alt="Driver" src={selectedDriver?.profile_picture} sx={{ width: 90, height: 90, mr: 2, borderRadius: 2 }} />
                            )}
                            <Box>
                                <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                    {loading ? (
                                        <Skeleton width={100} height={20} sx={{ mr: 1 }} />
                                    ) : (
                                        <BoldText mr={1}>
                                            {selectedDriver?.first_name} {selectedDriver?.last_name}
                                        </BoldText>
                                    )}
                                    {loading ? (
                                        <Skeleton width={50} height={20} />
                                    ) : (
                                        <Box sx={{ background: '#F4F4F4', borderRadius: 5, display: 'flex', alignItems: 'center', justifyContent: 'start', px: 1, textAlign: 'center', width: 'fit-content' }}>
                                            <Star sx={{ color: '#FFD700', fontSize: 20 }} /> <Typography sx={{ fontSize: 12, color: '#495974', fontWeight: 700 }}>5.0</Typography>
                                        </Box>
                                    )}
                                </Box>
                                {loading ? (
                                    <Skeleton width={200} height={20} />
                                ) : (
                                    <InfoText>
                                        ID No.{selectedDriver?._id?.slice(0, 10)}...
                                        <IconButton onClick={() => navigator.clipboard.writeText(selectedDriver?._id)} size="small" sx={{ ml: 1, p: 0 }}>
                                            <MdContentCopy />
                                        </IconButton>
                                    </InfoText>
                                )}
                                {loading ? (
                                    <Skeleton width={200} height={20} />
                                ) : (
                                    <ContactInfo variant="body2">
                                        <MdEmail /> {selectedDriver?.email}
                                    </ContactInfo>
                                )}
                                {loading ? (
                                    <Skeleton width={200} height={20} />
                                ) : (
                                    <ContactInfo variant="body2">
                                        <FaPhone /> {ClickablePhoneNumber(selectedDriver?.code_phone_number, selectedDriver?.phone_number)}
                                    </ContactInfo>
                                )}
                            </Box>
                        </Box>
                    </Card>
                </Grid>
            </Grid>

            <Card>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1, backgroundColor: '#f5f7f8', borderRadius: 1, px: 2, mb: 1 }}>
                    <Typography variant="body1" sx={{ color: '#172B4D', fontWeight: 700, fontSize: 14 }}>
                        Vehicle Info
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'start', alignItems: 'start' }}>
                    {loading ? (
                        <Skeleton variant="rectangular" width={127} height={90} sx={{ mr: 2, borderRadius: 2 }} />
                    ) : (
                        selectedDriver?.vehicle_info?.vehicle_images?.map((imageUrl, index) => (
                            <Avatar alt="Vehicle" key={index} src={imageUrl} sx={{ width: 127, height: 90, mr: 2, borderRadius: 2 }} />
                        ))
                    )}
                    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'start', alignItems: 'start', mt: 1, gap: { xs: 0, md: 2 } }}>
                        <Box>
                            <Typography variant="body2" sx={{ color: '#172B4D', fontWeight: 700, fontSize: 12, display: 'flex' }}>
                                <Box component={'span'} style={{ color: '#7A869A', fontSize: 12, fontWeight: 400 ,textTransform:'uppercase' }}>Vehicle NO:</Box> {loading ? <Skeleton width={50} sx={{ ml: 1 }} /> : selectedDriver?.vehicle_info?.license_plate}
                            </Typography>
                            <Typography variant="body2" sx={{ color: '#172B4D', fontWeight: 700, fontSize: 12, display: 'flex' }}>
                                <Box component={'span'} style={{ color: '#7A869A', fontSize: 12, fontWeight: 400 }}>Vehicle Make:</Box> {loading ? <Skeleton width={50} sx={{ ml: 1 }} /> : selectedDriver?.vehicle_info?.vehicle_make}
                            </Typography>
                            <Typography variant="body2" sx={{ color: '#172B4D', fontWeight: 700, fontSize: 12, display: 'flex' }}>
                                <Box component={'span'} style={{ color: '#7A869A', fontSize: 12, fontWeight: 400 }}>Vehicle Type:</Box> {loading ? <Skeleton width={50} sx={{ ml: 1 }} /> : selectedDriver?.vehicle_info?.vehicle_type}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant="body2" sx={{ color: '#172B4D', fontWeight: 700, fontSize: 12, display: 'flex' }}>
                                <Box component={'span'} style={{ color: '#7A869A', fontSize: 12, fontWeight: 400 }}>Vehicle Model:</Box> {loading ? <Skeleton width={50} sx={{ ml: 1 }} /> : selectedDriver?.vehicle_info?.vehicle_model}
                            </Typography>
                            <Typography variant="body2" sx={{ color: '#172B4D', fontWeight: 700, fontSize: 12, display: 'flex' }}>
                                <Box component={'span'} style={{ color: '#7A869A', fontSize: 12, fontWeight: 400 }}>Year:</Box> {loading ? <Skeleton width={50} sx={{ ml: 1 }} /> : selectedDriver?.vehicle_info?.vehicle_year}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Card>
        </ModalContainer>
    );
};

export default DriverDetailsModal;
