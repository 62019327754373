import { useContext, useEffect } from "react";
import { UNSAFE_NavigationContext } from 'react-router-dom';
import Swal from "sweetalert2";
import colorConfigs from "../../configs/colorConfigs";

function usePrompt(message, when) {
    const { navigator } = useContext(UNSAFE_NavigationContext);

    useEffect(() => {
        if (!when) return;

        const handleBeforeUnload = (event) => {
            event.preventDefault();
            event.returnValue = ''; // Required for some browsers

            return undefined; // Prevent the default browser dialog
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => window.removeEventListener("beforeunload", handleBeforeUnload);
    }, [message, when]);

    useEffect(() => {
        if (!when) return;

        const push = navigator.push;
        navigator.push = (...args) => {
            Swal.fire({
                title: "Message",
                text: message,
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: colorConfigs.sidebar.bg,
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
                cancelButtonText: "No"
            }).then((result) => {
                if (result.isConfirmed) {
                    push(...args);
                }
            });
        };

        return () => {
            navigator.push = push;
        };
    }, [message, when, navigator]);

    return null;
}

export default usePrompt;
