import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Card, CardContent, CircularProgress, Grid, IconButton, Skeleton, Typography, styled, useMediaQuery, useTheme } from "@mui/material";
import axios from 'axios';
import { GoogleApiWrapper, Map } from "google-maps-react";
import React, { useEffect, useRef, useState } from "react";
import { FaStar } from "react-icons/fa6";
import { IoIosArrowDown } from "react-icons/io";
import { MdContentCopy } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import logo from '../../assets/dashboard-removebg.png';
import DropImage from '../../assets/images/Icon.png';
import background from '../../assets/images/back.png';
import pickupImage from '../../assets/images/location.png';
import enRouteCar from '../../assets/mapCars/En route.png';
import waitingPickCar from '../../assets/mapCars/Waiting Pickup.png';
import busyCar from '../../assets/mapCars/busy.png';
import freeCar from '../../assets/mapCars/free.png';
import colorConfigs from "../../configs/colorConfigs";
import { db } from "../../configs/firebase";
import { openModal } from "../../redux/features/modalSlice";
import { GOOGLE_API_KEY, mapStyles } from "../../utils/constant";
import { getMethod } from "../Hook/UseCustomApi";
import CompletedRideMessage from "./CompletedRideMessage";
import Error5xx from '../../components/common/HandelError5xx';


const carIcons = {
    "free": freeCar,
    "en-route": enRouteCar,
    "waiting-pickup": waitingPickCar,
    "busy": busyCar
};

const Section = styled(Grid)(({ theme }) => ({
    backgroundColor: '#f5f7f8',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
}));

const InfoBox = styled(Grid)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    marginBottom: 2
}));

const AddressText = styled(Typography)(({ theme }) => ({
    fontWeight: 700,
    fontFamily: "Poppins",
    fontSize: 12,
}));

const TimeText = styled(Typography)(({ theme }) => ({
    color: "#7A869A",
    fontSize: 13,
    fontWeight: 500,
    fontFamily: "Poppins",
}));

const DotDividerContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& div': {
        width: 4,
        height: 4,
        borderRadius: '50%',
        backgroundColor: '#E0E0E0',
        margin: '1px 0',
    },
}));

const BoldText = styled(Typography)(({ theme }) => ({
    fontWeight: 700,
    color: '#172B4D',
    fontSize: 14,
}));

const DotDivider = () => (
    <DotDividerContainer>
        <div></div>
        <div></div>
        <div></div>
    </DotDividerContainer>
);

const calculateBearing = (startLat, startLng, endLat, endLng) => {
    const toRad = (angle) => angle * (Math.PI / 180);
    const toDeg = (angle) => angle * (180 / Math.PI);

    const startLatRad = toRad(startLat);
    const startLngRad = toRad(startLng);
    const endLatRad = toRad(endLat);
    const endLngRad = toRad(endLng);

    const dLng = endLngRad - startLngRad;
    const y = Math.sin(dLng) * Math.cos(endLatRad);
    const x = Math.cos(startLatRad) * Math.sin(endLatRad) -
        Math.sin(startLatRad) * Math.cos(endLatRad) * Math.cos(dLng);

    const bearing = toDeg(Math.atan2(y, x));
    return (bearing + 360) % 360;
};

const snapToRoad = async (path) => {
    const apiUrl = `https://roads.googleapis.com/v1/snapToRoads?path=${path}&interpolate=true&key=${GOOGLE_API_KEY}`;
    try {
        const response = await axios.get(apiUrl);
        return response.data.snappedPoints.map(point => ({
            lat: point.location.latitude,
            lng: point.location.longitude
        }));
    } catch (error) {
        console.error("Error snapping to road", error);
        return [{ lat: path.split(',')[0], lng: path.split(',')[1] }];
    }
};

const RideTrack = (props) => {
    const { id } = useParams();
    const [rideDetails, setRideDetails] = useState(null);
    const theme = useTheme();
    const [loading, setLoading] = useState(true);
    const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });
    const [iconRotation, setIconRotation] = useState(0);
    const directionsServiceRef = useRef(null);
    const directionsRendererRef = useRef(null);
    const markersRef = useRef([]);
    const polylinesRef = useRef([]);
    const dispatch = useDispatch();
    const [completed, setCompelted] = useState(false);
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
    const navigate = useNavigate();
    const trafficLayerRef = useRef(null);  // To store traffic layer
    const [showTraffic, setShowTraffic] = useState(
        localStorage.getItem("showTraffic") === "true"
    );  // Traffic state
    const [mapTypeId, setMapTypeId] = useState(
        localStorage.getItem("mapTypeId") || "hybrid"
    );  // Map type state
    const mapRef = useRef(null);


    const createTrafficControl = (map) => {
        const controlDiv = document.createElement("div");
        controlDiv.style.backgroundColor = "#fff";
        controlDiv.style.borderRadius = "3px";
        controlDiv.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
        controlDiv.style.cursor = "pointer";
        controlDiv.style.marginTop = "10px";
        controlDiv.style.marginBottom = "22px";
        controlDiv.style.textAlign = "center";
        controlDiv.style.height = "40px";
        controlDiv.style.marginLeft = "-12px";

        const controlText = document.createElement("div");
        controlText.style.color = "rgb(25,25,25)";
        controlText.style.fontFamily = "poppins";
        controlText.style.fontSize = "14px";
        controlText.style.lineHeight = "38px";
        controlText.style.fontWeight = "600";
        controlText.style.paddingLeft = "5px";
        controlText.style.paddingRight = "5px";
        controlText.innerHTML = "Traffic Mode";
        controlDiv.appendChild(controlText);

        controlDiv.addEventListener("click", () => {
            setShowTraffic((prevShowTraffic) => {
                const newShowTraffic = !prevShowTraffic;
                if (newShowTraffic) {
                    trafficLayerRef.current.setMap(map); // Show traffic layer
                } else {
                    trafficLayerRef.current.setMap(null); // Remove traffic layer
                }
                localStorage.setItem("showTraffic", newShowTraffic);
                return newShowTraffic;
            });
        });

        return controlDiv;
    };


    //handle error5xx
    const [tryAgain, setTryAgain] = useState();
    const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
    const [errorCount, setErrorCount] = useState(0);
    const [errorDialogCounter, setErrorDialogCounter] = useState(0);
    const handleError5xx = () => {
        setIsErrorDialogOpen(true);
        setErrorCount(prevCount => {
            const newCount = prevCount + 1;
            if (newCount === 1) {
                setErrorDialogCounter(5);
            } else if (newCount === 2) {
                setErrorDialogCounter(10);
            }
            return newCount;
        });
    };
    const handleCloseErrorDialog = () => {
        setIsErrorDialogOpen(false);
        if (errorCount <= 2) {
            handleErrorCountByClose()
        }

    };
    const handleErrorCountByClose = () => {
        setErrorCount(prevCount => {
            const newCount = prevCount - 1;
            if (newCount === 1) {
                setErrorDialogCounter(5);
            } else if (newCount === 2) {
                setErrorDialogCounter(10);
            }
            return newCount;
        });
    }

    const getRideDetails = async () => {
        setLoading(true);
        try {
            const response = await getMethod({
                url: `ride/${id}`,
            }).then((response) => {
                let result = response?.data;
                setRideDetails(result);
                setMapCenter({
                    lat: result?.location?.lat,
                    lng: result?.location?.lng,
                });
                setLoading(false);
            });
        } catch (error) {
            console.log("Error on getting ride details:", error);
            setLoading(false);
            if (error === "Internal Server Error: Server error occurred.") {
                handleError5xx();
                setTryAgain(() => getRideDetails);
            }
        }
    };

    useEffect(() => {
        if (!id) return;
        getRideDetails();
    }, [id]);

    useEffect(() => {
        if (!rideDetails) return;

        const unsubscribe = db.collection('ride')
            .doc(rideDetails?._id)
            .onSnapshot(async (doc) => {
                const data = doc.data();
                let origin = null;
                let destination = null;

                if (data.status === 'pickedup' || data.status === 'enroute_to_delivery' || data.status === 'delivered' || data.status === 'waiting_delivery' || data.status === 'canceled' || data.status === 'dispute') {
                    destination = new window.google.maps.LatLng(rideDetails?.delivery_address?.lat, rideDetails?.delivery_address?.lng);
                    origin = new window.google.maps.LatLng(data?.tracking_location?.lat, data?.tracking_location?.lng);
                } else {
                    origin = new window.google.maps.LatLng(data?.pickup_address?.lat, data?.pickup_address?.lng);
                    destination = new window.google.maps.LatLng(rideDetails?.delivery_address?.lat, rideDetails?.delivery_address?.lng);
                }

                if (data.status === 'delivered') {
                    dispatch(openModal(<CompletedRideMessage />));
                    setCompelted(true);
                }

                const [snappedOrigin] = await snapToRoad(`${origin.lat()},${origin.lng()}`);
                const [snappedDestination] = await snapToRoad(`${destination.lat()},${destination.lng()}`);

                origin = new window.google.maps.LatLng(snappedOrigin.lat, snappedOrigin.lng);
                destination = new window.google.maps.LatLng(snappedDestination.lat, snappedDestination.lng);

                const request = {
                    origin: origin,
                    destination: destination,
                    travelMode: window.google.maps.TravelMode.DRIVING,
                };

                if (directionsServiceRef.current && directionsRendererRef.current) {
                    directionsRendererRef.current.setOptions({
                        suppressMarkers: true,
                    });

                    const map = directionsRendererRef.current.getMap();

                    // Clear existing polylines
                    polylinesRef.current.forEach(polyline => polyline.setMap(null));
                    polylinesRef.current = [];

                    directionsServiceRef.current.route(request, (result, status) => {
                        if (status === window.google.maps.DirectionsStatus.OK) {
                            directionsRendererRef.current.setDirections(result);
                            setMapCenter(origin);

                            // Add new polyline
                            const directionsDisplayOptions = directionsRendererRef.current.get('directions').routes[0].overview_path;
                            const polyline = new window.google.maps.Polyline({
                                path: directionsDisplayOptions,
                                strokeColor: '#03992D',
                                strokeOpacity: 1.0,
                                strokeWeight: 4,
                            });
                            polyline.setMap(directionsRendererRef.current.getMap());
                            polylinesRef.current.push(polyline);

                            directionsRendererRef.current.setOptions({ suppressPolylines: true });

                            // Clear existing markers
                            markersRef.current.forEach(marker => marker.setMap(null));
                            markersRef.current = [];

                            const getSizeIconByStatus = (status) => {
                                switch (status) {
                                    case 'delivered':
                                        return new window.google.maps.Size(32, 32);
                                    default:
                                        return new window.google.maps.Size(25, 45)
                                }
                            };

                            const getAnchorIconByStatus = (status) => {
                                switch (status) {
                                    case 'busy':
                                        return new window.google.maps.Point(16, 16);
                                    case 'en-route':
                                        return new window.google.maps.Point(32, 32);
                                    case 'waiting-pickup':
                                        return new window.google.maps.Point(32, 32);
                                    default:
                                        return new window.google.maps.Point(16, 16);
                                }
                            };
                            const getIconByStatus = (status) => {
                                switch (status) {
                                    case 'delivered':
                                        return pickupImage
                                    default:
                                        return freeCar;
                                }
                            };


                            // Add start marker
                            const startMarker = new window.google.maps.Marker({
                                position: origin,
                                map: map,
                                icon: {
                                    url: getIconByStatus(data.status),
                                    scaledSize: getSizeIconByStatus(data.status),
                                    anchor: new window.google.maps.Point(16, 16),
                                    rotation: iconRotation
                                },
                                title: 'Start'
                            });
                            markersRef.current.push(startMarker);

                            // Add end marker
                            const endMarker = new window.google.maps.Marker({
                                position: destination,
                                map: map,
                                icon: {
                                    url: DropImage,
                                    anchor: getAnchorIconByStatus(data.status),
                                    scaledSize: new window.google.maps.Size(32, 32),
                                },
                                title: 'End',
                                rotation: iconRotation
                            });
                            markersRef.current.push(endMarker);
                        } else {
                            console.error(`Error fetching directions: ${status}`);
                        }
                    });
                }

                if (data.location && rideDetails?.delivery_address) {
                    const { lat: startLat, lng: startLng } = data.location;
                    const { lat: endLat, lng: endLng } = rideDetails.delivery_address;
                    const newRotation = calculateBearing(startLat, startLng, endLat, endLng);
                    setIconRotation(newRotation);
                }
            });

        return () => unsubscribe();
    }, [rideDetails]);

    useEffect(() => {
        if (trafficLayerRef.current && mapRef.current) {
            if (showTraffic) {
                trafficLayerRef.current.setMap(mapRef.current); // Show traffic layer if true
            } else {
                trafficLayerRef.current.setMap(null); // Hide traffic layer if false
            }
        }
    }, [showTraffic]);

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    if (!rideDetails) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <Typography variant="h5">No ride details found</Typography>
                <Map
                    google={props.google}
                    zoom={17}
                    center={mapCenter}
                    styles={mapStyles}
                    style={{ borderRadius: '10px', display: 'none', position: 'relative' }}
                    fullscreenControl={false}
                    mapTypeControl={false}
                    streetViewControl={false}
                    onReady={(mapProps, map) => {
                        if (directionsRendererRef.current) {
                            directionsRendererRef.current.setMap(map);
                            map.setMapTypeId(mapTypeId);
                        }
                        if (!directionsServiceRef.current) {
                            directionsServiceRef.current = new window.google.maps.DirectionsService();
                            map.setMapTypeId(mapTypeId);
                        }
                        if (!directionsRendererRef.current) {
                            directionsRendererRef.current = new window.google.maps.DirectionsRenderer({
                                suppressMarkers: true,
                            });
                            directionsRendererRef.current.setMap(map);
                            map.setMapTypeId(mapTypeId);
                        }
                    }}
                >
                </Map>
            </Box>
        );
    }
    return (
        <Box sx={{ height: "100vh", minHeight: '100vh', position: 'relative', p: 2, background: 'white' }}>
            {/* handleError5xx */}
            <Error5xx open={isErrorDialogOpen} errCount={errorCount} count={errorDialogCounter} handleClose={handleCloseErrorDialog} tryAgain={tryAgain} />
            <img src={background} alt="pickup" style={{ width: "100%", height: '30vh', position: 'absolute', zIndex: 0, top: 0, left: 0 }} />
            <img src={logo} alt="pickup" style={{ width: "150px", height: '75px', zIndex: 1, position: 'relative' }} />
            <Box sx={{ display: "flex", flexDirection: { xs: 'column', lg: 'row' }, height: "70%", zIndex: 1, gap: 3, mt: 2 }}>
                <Box sx={{ width: { xs: "100%", lg: "50%" }, zIndex: 1, minHeight: { xs: 'fit-content', lg: '600px' }, background: '#F9F9F9', borderRadius: '10px', p: 1 }}>
                    <Box>
                        <CardContent>

                            {completed ? <Box sx={{ padding: 1, px: 2, color: '#FFFFFF', borderRadius: 25, border: '1px solid #4caf50', background: '#4caf50', display: 'flex', justifyContent: 'start', gap: 3, alignItems: 'center', width: 'fit-content', mb: 2 }}>
                                Ride Completed
                                <CheckCircleIcon sx={{ fontSize: 25, color: '#FFFFFF' }} />
                            </Box> : ''}
                            <Box sx={{ display: "flex", flexDirection: { xs: 'column', lg: 'row', background: '#FFFFFF', p: 3, borderRadius: 3 } }} alignItems="center" justifyContent={'space-between'} >
                                <Box display="flex" alignItems="start" justifyContent={'space-between'} sx={{ background: '#FFFFFF', p: 1, borderRadius: 3 }}>
                                    <Avatar src={rideDetails?.driver_info?.profile_picture} />
                                    <Box ml={2}>
                                        <Box sx={{ display: 'flex', justifyContent: 'start', gap: 1, alignItems: 'center' }}>
                                            <Typography sx={{ fontSize: 18, color: '#172B4D', fontWeight: 700 }}>{rideDetails?.driver_info?.first_name} {rideDetails?.driver_info?.last_name}</Typography>
                                            <Box sx={{ display: 'flex', justifyContent: 'start', gap: 0.5, alignItems: 'center' }}>
                                                <FaStar color="#FFCA28" />
                                                {rideDetails?.driver_info?.review_avg}
                                            </Box>
                                        </Box>

                                        <Box sx={{ display: 'flex', justifyContent: 'start', gap: 0.5, alignItems: 'center' }}>
                                            <Typography sx={{ color: '#495974', fontSize: 13, fontWeight: 300 }}>{rideDetails?.driver_info?.vehicle_info?.vehicle_type} - </Typography>
                                            <Typography sx={{ color: '#495974', fontSize: 13, fontWeight: 600 }}> {rideDetails?.driver_info?.vehicle_info?.license_plate}</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Typography sx={{ color: '#495974', fontSize: 14, fontWeight: 600, mr: 3, mb: 1 }}>
                                    Ride Id :
                                    <Typography component={'span'} sx={{ color: '#172B4D', fontWeight: 700, fontSize: 16 }}>
                                        {' '}  # {rideDetails?.short_id}
                                        <IconButton onClick={() => navigator.clipboard.writeText(rideDetails?.short_id)} size='small' sx={{ ml: 1, p: 0 }}>
                                            <MdContentCopy />
                                        </IconButton>
                                    </Typography>
                                </Typography>
                            </Box>
                            <Accordion sx={{
                                p: 1, my: 1, borderTop: 'none', background: '#FFFFFF', boxShadow: 'none', borderRadius: 3,
                                '&:before': {
                                    display: 'none',
                                },
                                '& .MuiAccordionSummary-content': {
                                    marginBottom: theme.spacing(2),
                                }
                            }}>
                                <AccordionSummary sx={{
                                    px: 3, m: 0, background: '#FFFFFF', boxShadow: 'none', borderTop: 'none',
                                    '&.css-1ua1mkh-MuiPaper-root-MuiAccordion-root:before': {
                                        display: 'none'
                                    }
                                }}
                                    expandIcon={<IoIosArrowDown />}
                                    aria-controls="car-details-content"
                                    id="car-details-header"
                                >
                                    {
                                        rideDetails?.driver_info?.vehicle_info?.vehicle_images?.map((image, index) => (
                                            <Avatar
                                                key={index}
                                                sx={{
                                                    width: 64,
                                                    height: 'auto',
                                                    aspectRatio: '1.82',
                                                    objectFit: 'cover',
                                                    borderRadius: 1,
                                                    objectPosition: 'center',
                                                    mr: 1
                                                }}
                                                src={image}
                                                alt={rideDetails?.driver_info?.vehicle_info?.license_plate}
                                                variant="square"
                                            />
                                        ))
                                    }
                                    <Typography sx={{ fontSize: 18, color: '#172B4D', fontWeight: 600 }}>Car Details</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'start', alignItems: 'start' }}>
                                        {loading ? (
                                            <Skeleton variant="rectangular" width={127} height={90} sx={{ mr: 2, borderRadius: 2 }} />
                                        ) : (
                                            <>
                                                {rideDetails?.driver_info?.vehicle_info?.vehicle_images?.map((imageUrl, index) => (
                                                    <Avatar alt="Vehicle" key={index} src={imageUrl} sx={{ width: 127, height: 90, mr: 2, borderRadius: 2 }} />
                                                ))}
                                            </>
                                        )}
                                        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'start', alignItems: 'start', mt: 1, gap: { xs: 0, md: 2 } }}>
                                            <Box>
                                                <Typography variant="body2" sx={{ color: '#172B4D', fontWeight: 700, fontSize: 12, display: 'flex' }}>
                                                    <Box component={'span'} style={{ color: '#7A869A', fontSize: 12, fontWeight: 400 ,textTransform:'uppercase' }}>Vehicle NO:</Box> {loading ? <Skeleton width={50} sx={{ ml: 1 }} /> : rideDetails?.driver_info?.vehicle_info?.license_plate}
                                                </Typography>
                                                <Typography variant="body2" sx={{ color: '#172B4D', fontWeight: 700, fontSize: 12, display: 'flex' }}>
                                                    <Box component={'span'} style={{ color: '#7A869A', fontSize: 12, fontWeight: 400 }}>Vehicle Make:</Box> {loading ? <Skeleton width={50} sx={{ ml: 1 }} /> : rideDetails?.driver_info?.vehicle_info?.vehicle_make}
                                                </Typography>
                                                <Typography variant="body2" sx={{ color: '#172B4D', fontWeight: 700, fontSize: 12, display: 'flex' }}>
                                                    <Box component={'span'} style={{ color: '#7A869A', fontSize: 12, fontWeight: 400 }}>Vehicle Type:</Box> {loading ? <Skeleton width={50} sx={{ ml: 1 }} /> : rideDetails?.driver_info?.vehicle_info?.vehicle_type}
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Typography variant="body2" sx={{ color: '#172B4D', fontWeight: 700, fontSize: 12, display: 'flex' }}>
                                                    <Box component={'span'} style={{ color: '#7A869A', fontSize: 12, fontWeight: 400 }}>Vehicle Model:</Box> {loading ? <Skeleton width={50} sx={{ ml: 1 }} /> : rideDetails?.driver_info?.vehicle_info?.vehicle_model}
                                                </Typography>
                                                <Typography variant="body2" sx={{ color: '#172B4D', fontWeight: 700, fontSize: 12, display: 'flex' }}>
                                                    <Box component={'span'} style={{ color: '#7A869A', fontSize: 12, fontWeight: 400 }}>Year:</Box> {loading ? <Skeleton width={50} sx={{ ml: 1 }} /> : rideDetails?.driver_info?.vehicle_info?.vehicle_year}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                            <Card sx={{ boxShadow: 'none' }}>
                                <Box sx={{ my: 2, px: 3 }}>
                                    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', lg: 'row' } }}>
                                        {!isSmallScreen && (
                                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mr: 2 }}>
                                                <img src={pickupImage} alt="loc" />
                                                <DotDivider />
                                                <img src={DropImage} alt="loc" />
                                            </Box>
                                        )}
                                        <Box sx={{ width: '100%' }}>
                                            <Box sx={{ mt: 1, width: '100%' }}>
                                                <AddressText sx={{ color: colorConfigs?.sidebar?.bg }}>
                                                    Pick up
                                                </AddressText>
                                                {loading ? (
                                                    <Skeleton width="100%" />
                                                ) : (
                                                    <TimeText>{rideDetails?.pickup_address?.address}</TimeText>
                                                )}
                                            </Box>
                                            <Box sx={{ mt: 2 }}>
                                                <AddressText sx={{ color: '#03992D' }}>
                                                    Drop off
                                                </AddressText>
                                                {loading ? (
                                                    <Skeleton width="100%" />
                                                ) : (
                                                    <TimeText>{rideDetails?.delivery_address?.address}</TimeText>
                                                )}
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Card>
                        </CardContent>
                    </Box>
                </Box>
                <Box sx={{ flex: 1, height: "100%", position: "relative", zIndex: 1, minHeight: '600px', borderRadius: '10px' }}>
                    <Map
                        google={props.google}
                        zoom={17}
                        center={mapCenter}
                        styles={mapStyles}
                        style={{ borderRadius: '10px' }}
                        fullscreenControl={false}
                        mapTypeControl={false}
                        streetViewControl={false}
                        onReady={(mapProps, map) => {
                            if (directionsRendererRef.current) {
                                directionsRendererRef.current.setMap(map);
                                map.setMapTypeId(mapTypeId);
                                trafficLayerRef.current = new window.google.maps.TrafficLayer();
                                if (showTraffic) {
                                    trafficLayerRef.current.setMap(map);
                                    map.setMapTypeId(mapTypeId); // Show traffic layer if true
                                }
                                map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(
                                    createTrafficControl(map) // Add traffic control
                                );
                                map.setMapTypeId(mapTypeId);
                                window.google.maps.event.addListener(map, "maptypeid_changed", () => {
                                    const newMapTypeId = map.getMapTypeId();
                                    setMapTypeId(newMapTypeId);
                                    localStorage.setItem("mapTypeId", newMapTypeId);
                                });
                            }
                            if (!directionsServiceRef.current) {
                                directionsServiceRef.current = new window.google.maps.DirectionsService();
                                map.setMapTypeId(mapTypeId);
                                trafficLayerRef.current = new window.google.maps.TrafficLayer();
                                if (showTraffic) {
                                    trafficLayerRef.current.setMap(map); // Show traffic layer if true
                                }
                                map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(
                                    createTrafficControl(map) // Add traffic control
                                );
                                window.google.maps.event.addListener(map, "maptypeid_changed", () => {
                                    const newMapTypeId = map.getMapTypeId();
                                    setMapTypeId(newMapTypeId);
                                    localStorage.setItem("mapTypeId", newMapTypeId);
                                });
                            }
                            if (!directionsRendererRef.current) {
                                directionsRendererRef.current = new window.google.maps.DirectionsRenderer({
                                    suppressMarkers: true,
                                });
                                map.setMapTypeId(mapTypeId);
                                directionsRendererRef.current.setMap(map);
                                trafficLayerRef.current = new window.google.maps.TrafficLayer();
                                if (showTraffic) {
                                    trafficLayerRef.current.setMap(map); // Show traffic layer if true
                                }
                                map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(
                                    createTrafficControl(map) // Add traffic control
                                );
                                window.google.maps.event.addListener(map, "maptypeid_changed", () => {
                                    const newMapTypeId = map.getMapTypeId();
                                    setMapTypeId(newMapTypeId);
                                    localStorage.setItem("mapTypeId", newMapTypeId);
                                });
                            }
                        }}
                    >
                    </Map>
                </Box>
            </Box>
        </Box >
    );
};

export default GoogleApiWrapper({
    apiKey: GOOGLE_API_KEY,
})(RideTrack);
