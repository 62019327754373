import TicketManagment from "../../components/support/TicketManagment";


export default function RiderTicketManagment() {


    return (
        <>
            <TicketManagment status={"riders"} />
        </>
    );
}
