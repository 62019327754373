import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";
import React, { useEffect, useRef, useState } from "react"; // Import useRef
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { db } from "../../configs/firebase";
import { chat } from "../../redux/features/appStateSlice";
import Messaging from "./messaging-index";
import RR_empty_Chat from '../../assets/icons/chat_empty_RR.png';
import WM_empty_Chat from '../../assets/icons/chat_empty_WM.png';

const MessagingContainer = ({ openDialog, setActiveButton }) => {
  const [job, setJob] = useState(null);
  const [loading, setLoading] = useState(true);
  const chatData = useSelector((state) => state.appState.selectedChat);
  const dispatch = useDispatch();
  const selectedChat = useSelector((state) => state.appState.selectedChat);
  const params = useParams();
  const theme = useTheme();
  const selectedRide = useSelector((state) => state.appState.selectedRide);
  const outChat = useSelector((state) => state.appState.outChat);
  const uid =
    selectedChat?.type === "driver" ? selectedChat?.driver : selectedChat?.customer;
  const lastSupportChat = useSelector((state) => state.appState.lastSupportChat);

  const prevJobRef = useRef(); // Reference to store previous job data

  const useStyles = {
    container: {
      padding: 10,
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      padding: "12px 28px",
      borderRadius: "8px",
      margin: "10px",
      width: "80%",
    },
  };

  // Functions to handle chat IDs and user details
  const handleChatId = () => {
    if (selectedRide?.chat_type === "customer") {
      return `admin_${selectedRide?.customer_details?.fb_uid}`;
    } else {
      return `admin_${selectedRide?.driver_info?.fb_uid
          ? selectedRide?.driver_info?.fb_uid
          : selectedRide?.driver_info?.fb_id
        }`;
    }
  };

  const handleName = () => {
    if (selectedRide?.chat_type === "customer") {
      if (selectedRide?.customer_details?.first_name !== undefined)
        return ` ${selectedRide?.customer_details?.first_name} ${selectedRide?.customer_details?.last_name}`;
    } else {
      return `${selectedRide?.driver_info?.first_name} ${selectedRide?.driver_info?.last_name}`;
    }
  };

  const handleProfileImage = () => {
    if (selectedRide?.chat_type === "customer") {
      return selectedRide?.customer_details?.profile_picture;
    } else {
      return selectedRide?.driver_info?.profile_picture;
    }
  };

  const handlePhoneNumber = () => {
    if (selectedRide?.chat_type === "customer") {
      return `${selectedRide?.customer_details?.code_phone_number} ${selectedRide?.customer_details?.phone_number}`;
    } else {
      return `${selectedRide?.driver_info?.code_phone_number} ${selectedRide?.driver_info?.phone_number}`;
    }
  };

  const handleLastName = () => {
    if (lastSupportChat?.chat_type === "customer") {
      return `${lastSupportChat?.customer_details?.first_name} ${lastSupportChat?.customer_details?.last_name}`;
    } else {
      return `${lastSupportChat?.driver_info?.first_name} ${lastSupportChat?.driver_info?.last_name}`;
    }
  };

  const handleLastProfileImage = () => {
    if (lastSupportChat?.chat_type === "customer") {
      return lastSupportChat?.customer_details?.profile_picture;
    } else {
      return lastSupportChat?.driver_info?.profile_picture;
    }
  };

  const handleLastPhoneNumber = () => {
    if (lastSupportChat?.chat_type === "customer") {
      return `${lastSupportChat?.customer_details?.code_phone_number} ${lastSupportChat?.customer_details?.phone_number}`;
    } else {
      return `${lastSupportChat?.driver_info?.code_phone_number} ${lastSupportChat?.driver_info?.phone_number}`;
    }
  };

  useEffect(() => {
    setLoading(true);
    if (selectedChat?.driver || selectedRide?.chat_type || selectedChat?.customer) {
      let msgRef;
      const jobId = outChat ? handleChatId() : uid;
      msgRef = db.collection("messaging_with_admin").doc(jobId);

      const observer = msgRef.onSnapshot((documentSnapshot) => {
        if (documentSnapshot.exists) {
          const data = documentSnapshot.data();
          data.id = documentSnapshot.id;

          // Prepare updated data
          const updatedData = {
            ...data,
            user_name: outChat ? handleName() : chatData?.user_name ?? data.user_name,
            user_profile: outChat ? handleProfileImage() : chatData?.user_profile ?? data.user_profile,
          };

          setJob(updatedData); // Set the data to state without updating Firestore here
        } else {
          // Document does not exist, create it
          if (selectedChat?.type === "driver" || selectedRide?.chat_type === "driver") {
            const msgIdWithoutPrefix = outChat
              ? handleChatId()?.replace(/^admin_/, "")
              : selectedChat?.driver?.replace(/^admin_/, "") ?? "empty";
            const newData = {
              customer: null,
              driver: msgIdWithoutPrefix,
              expired: null,
              image: "",
              lastMessage: {
                message: "",
                uid: "",
              },
              lastRead: {
                admin: "",
              },
              type: "driver",
              title: "Support",
              users: ["admin", msgIdWithoutPrefix],
              unRead: {
                admin: 0,
              },
              typing: {
                admin: false,
              },
              user_name: outChat ? handleName() : chatData?.user_name ?? "",
              user_phoneNumber: outChat ? handlePhoneNumber() : chatData?.user_phoneNumber ?? "",
              user_profile: outChat ? handleProfileImage() : chatData?.user_profile ?? "",
              updatedAt: new Date(),
            };

            msgRef
              .set(newData)
              .then(() => {
                setJob(newData);
              })
              .catch((error) => {
                console.error("Error creating document: ", error);
              });
          } else if (selectedChat?.type === "customer" || selectedRide?.chat_type === "customer") {
            setActiveButton("customer");
            const msgIdWithoutPrefix = outChat
              ? handleChatId()?.replace(/^admin_/, "")
              : selectedChat?.customer?.replace(/^admin_/, "") ?? "empty";
            const newData = {
              customer: msgIdWithoutPrefix,
              driver: null,
              expired: null,
              image: "",
              lastMessage: {
                message: "",
                uid: "",
              },
              lastRead: {
                admin: "",
              },
              type: "customer",
              title: "Support",
              users: ["admin", msgIdWithoutPrefix],
              unRead: {
                admin: 0,
              },
              typing: {
                admin: false,
              },
              user_name: outChat ? handleName() : chatData?.user_name ?? "",
              user_phoneNumber: outChat ? handlePhoneNumber() : chatData?.user_phoneNumber ?? "",
              user_profile: outChat ? handleProfileImage() : chatData?.user_profile ?? "",
              updatedAt: new Date(),
            };

            msgRef
              .set(newData)
              .then(() => {
                setJob(newData);
              })
              .catch((error) => {
                console.error("Error creating document: ", error);
              });
          }
        }
        setLoading(false);
        dispatch(chat({}));
      });

      return () => {
        observer();
      };
    } else if (lastSupportChat) {
      const uid =
        lastSupportChat?.type === "driver"
          ? lastSupportChat?.driver
          : lastSupportChat?.customer;
      let msgRef;
      msgRef = db.collection("messaging_with_admin").doc(uid);

      const observer = msgRef.onSnapshot((documentSnapshot) => {
        if (documentSnapshot.exists) {
          const data = documentSnapshot.data();
          data.id = documentSnapshot.id;

          const updatedData = {
            ...data,
            user_name: outChat ? handleLastName() : chatData?.user_name ?? data.user_name,
            user_profile: outChat
              ? handleLastProfileImage()
              : chatData?.user_profile ?? data.user_profile,
          };

          setJob(updatedData); // Set the data to state without updating Firestore here
        }
        setLoading(false);
        dispatch(chat({}));
      });

      return () => {
        observer();
      };
    }
  }, [
    selectedRide?.chat_type,
    outChat,
    selectedRide,
    selectedChat,
    selectedChat?.type,
    lastSupportChat,
    chatData?.user_name,
    chatData?.user_profile,
    uid,
  ]);

  // Separate useEffect to handle updates
  useEffect(() => {
    if (job) {
      const prevJob = prevJobRef.current || {};
      let updatesNeeded = false;
      const updates = {};

      // Check if user_name or user_profile have changed
      if (job.user_name !== prevJob.user_name) {
        updates.user_name = job.user_name;
        updatesNeeded = true;
      }
      if (job.user_profile !== prevJob.user_profile) {
        updates.user_profile = job.user_profile;
        updatesNeeded = true;
      }

      // Check if unRead.admin needs to be updated
      if (job.unRead?.admin > 0) {
        updates["unRead.admin"] = 0;
        updates["lastRead.admin"] = new Date();
        updatesNeeded = true;
      }

      // Perform update if necessary
      if (updatesNeeded) {
        const jobId = outChat ? handleChatId() : uid;
        const msgRef = db.collection("messaging_with_admin").doc(jobId);

        msgRef.update(updates).catch((error) => {
          console.error("Error updating document: ", error);
        });
      }

      // Store current job data for next comparison
      prevJobRef.current = job;
    }
  }, [job, outChat, uid]);

  return (
    <div>
      {job ? (
        <div style={useStyles.container}>
          <Grid container direction="row">
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Messaging allowSend />
            </Grid>
          </Grid>
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            marginTop: "20%",
          }}
        >
          <img
            src={
              process.env.REACT_APP_ENV === "women_first"
                ? WM_empty_Chat
                : RR_empty_Chat
            }
            alt="No Chat"
          />
          <Typography sx={{ mt: 3 }} variant="body1" bold>
            You don’t have a message selected
          </Typography>
          <Typography
            variant="body2"
            style={{
              color: "grey",
              maxWidth: "350px",
              textAlign: "center",
              marginTop: "8px",
            }}
          >
            All messages are private conversations between Admins & Drivers or
            Customers on{" "}
            {process.env.REACT_APP_ENV === "women_first"
              ? "Women’s First"
              : "RideWyze"}
            .
          </Typography>
        </div>
      )}
    </div>
  );
};

export default MessagingContainer;
