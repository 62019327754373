import TicketManagment from "../../components/support/TicketManagment";


export default function DriverTicketManagment() {


    return (
        <>
            <TicketManagment status={"drivers"} />
        </>
    );
}
