import * as React from "react"
import { memo } from "react"
import colorConfigs from "../../configs/colorConfigs"

const SvgComponent = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={146}
        height={117}
        fill="none"
        {...props}
    >
        <g clipPath="url(#a)" opacity={0.7}>
            <path
                fill="#2F2E43"
                d="M3.629 116.714c0 .158.127.284.284.284h141.803a.283.283 0 1 0 0-.568H3.913a.283.283 0 0 0-.284.284Z"
            />
            <path
                fill="#B6B3C5"
                d="M77.161 88.648a4.986 4.986 0 0 1-4.912 4.166 4.986 4.986 0 0 1-4.911-4.166H54.772v27.229h34.956V88.648H77.16Z"
            />
            <path
                fill="#B6B3C5"
                d="M58.836 115.777H55.38v.61h3.455v-.61ZM89.422 115.879h-3.455v.61h3.455v-.61Z"
            />
            <path
                fill="#2F2E41"
                d="M142.55 89.363H1.949A1.95 1.95 0 0 1 0 87.415V7.187a1.95 1.95 0 0 1 1.949-1.949H142.55c1.074 0 1.949.874 1.949 1.949v80.228a1.951 1.951 0 0 1-1.949 1.948Z"
            />
            <path fill="#fff" d="M141.145 8.691H3.557v77.623h137.588V8.69Z" />
            <path
                fill="#D1D1D2"
                d="M124.798 38.502h-7.507c-.653 0-1.184-.53-1.184-1.182 0-.653.531-1.183 1.184-1.183h7.507c.652 0 1.183.53 1.183 1.183 0 .652-.531 1.182-1.183 1.182ZM133.433 38.502h-3.504c-.652 0-1.183-.53-1.183-1.182 0-.653.531-1.183 1.183-1.183h3.504c.652 0 1.183.53 1.183 1.183 0 .652-.531 1.182-1.183 1.182Z"
            />
            <path
                fill={colorConfigs.sidebar.bg}
                d="M49.633 39.406H26.782a2.118 2.118 0 0 1-2.116-2.115c0-1.166.949-2.115 2.116-2.115h22.851c1.167 0 2.116.949 2.116 2.115 0 1.166-.95 2.115-2.116 2.115Z"
            />
            <path
                fill="#D1D1D2"
                d="M37.244 45.047H26.23a1.29 1.29 0 0 1-1.29-1.289c0-.71.579-1.29 1.29-1.29h11.013c.71 0 1.29.58 1.29 1.29 0 .711-.58 1.29-1.29 1.29ZM97.952 50.141H25.956c-.711 0-1.29-.578-1.29-1.29 0-.71.579-1.288 1.29-1.288h71.996c.71 0 1.29.578 1.29 1.289 0 .71-.58 1.29-1.29 1.29ZM91.757 53.583H25.956c-.711 0-1.29-.579-1.29-1.29 0-.71.579-1.29 1.29-1.29h65.8c.712 0 1.29.58 1.29 1.29 0 .711-.578 1.29-1.29 1.29Z"
            />
            <path
                fill="#ED9DA0"
                d="m133.217 103.249-3.303 3.468-3.519-3.254 3.302-3.467 3.52 3.253Z"
            />
            <path
                fill="#090814"
                d="m135.856 106.8-7.021 7.37a5.91 5.91 0 0 1-2.167 1.439l-1.957.746a1.156 1.156 0 0 1-1.187-.227 1.11 1.11 0 0 1-.228-1.337l1.882-3.53 2.009-7.161.022.014c.546.336 1.375.841 1.434.862 1.041.037 1.785-.224 2.214-.777.748-.964.273-2.502.269-2.518l-.004-.012.009-.008c.218-.186.448-.26.683-.221.498.083.855.643.907.729.468-.02 1.904 1.105 2.02 1.196.705-.001 1.222.166 1.536.497.275.29.395.702.356 1.224a2.804 2.804 0 0 1-.777 1.714Z"
            />
            <path fill="#ED9DA0" d="M129.405 107.242h-4.827v4.755h4.827v-4.755Z" />
            <path
                fill="#090814"
                d="M128.581 116.321h-10.259a6 6 0 0 1-2.549-.572l-1.891-.896a1.123 1.123 0 0 1-.644-1.008c0-.502.343-.945.833-1.079l3.902-1.063 6.676-3.457.005.025c.124.623.318 1.564.342 1.621.685.772 1.388 1.128 2.091 1.058 1.225-.122 2.039-1.516 2.047-1.53l.007-.011.012.002c.287.029.499.143.631.338.279.416.109 1.055.081 1.151.334.323.485 2.124.496 2.27.484.505.714.991.684 1.444-.027.396-.25.763-.663 1.092a2.892 2.892 0 0 1-1.801.615ZM129.977 47.363l-17.102.009-5.701 35.558 17.103 20.325 5.7-5.081-13.516-15.244 13.516-35.567Z"
            />
            <path
                fill="#090814"
                d="m119.869 52.605 10.109-5.242v60.973l-5.701.156-4.408-55.887Z"
            />
            <path
                fill="#ED9DA0"
                d="m109.744 61.62.121-5.55-3.242-.07-.12 5.55c-.605.562-1.004 1.434-1.026 2.423-.037 1.73 1.093 3.157 2.525 3.189 1.433.03 2.624-1.347 2.661-3.077.022-.989-.339-1.877-.919-2.464Z"
            />
            <path
                fill="#090814"
                d="M125.112 7.416s4.653-.01 2.915-4.493c-1.738-4.482-4.975-2.475-4.975-2.475s-1.363.72-1.082 2.327"
            />
            <path
                fill="#ED9DA0"
                d="M125.578 9.566a6.223 6.223 0 0 0-6.224-6.222 6.223 6.223 0 0 0-1.864 12.16l1.203 7.95 6.133-5.11s-1.325-1.687-2.035-3.59a6.217 6.217 0 0 0 2.787-5.188Z"
            />
            <path
                fill="#090814"
                d="M122.346 14.824s.827-3.168-.525-4.625c-1.348-1.454-1.522.808-1.522.808l-.973-.212s.169-2.199-1.734-2.58l1.226-2.622s-4.696.912-4.826.457c-1.018-3.578 8.699-6.635 11.383-1.176 4.175 8.492-3.029 9.95-3.029 9.95Z"
            />
            <path
                fill={colorConfigs.sidebar.bg}
                d="M116.848 20.367a10.347 10.347 0 0 0-8.321 9.229l-2.812 31.354h4.935l6.198-40.583Z"
            />
            <path
                fill={colorConfigs.sidebar.bg}
                d="m112.183 45.348-.769 8.853 20.864-6.136-2.542-6.398 4.734-10.869-9.664-13.849-7.714.526-2.981 3.857"
            />
            <path
                fill="#ED9DA0"
                d="m129.196 53.755 2.91-4.728-2.761-1.699-2.91 4.728c-.806.179-1.591.73-2.11 1.572-.907 1.474-.653 3.277.567 4.028 1.219.75 2.944.164 3.851-1.31.518-.842.656-1.791.453-2.59Z"
            />
            <path
                fill={colorConfigs.sidebar.bg}
                d="m123.227 17.125 1.682-.07 2.876 2.173c3.408.581 4.397.744 5.441 2.995.439.946.73 1.954.892 2.983l2.434 15.409-6.117 14.725-4.052-4.558 4.332-11.512-.72-2.754"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 0h146v117H0z" />
            </clipPath>
        </defs>
    </svg>
)
const TicketHelpCenterNoData = memo(SvgComponent)
export default TicketHelpCenterNoData
