export const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;

export const emailRegex =
  /^(?!.*\.\.)(?!^[^a-zA-Z0-9])(?:[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?)$/;


export const zipCodeRegex = /^\d{5}(?:[-\s]\d{4})?$/;

export const isValidURL = (url) => {
  // Regular expression for a valid URL
  const urlPattern = new RegExp(
    /^(https?:\/\/)?(www\.)?[\w\.-]+\.[a-z]{2,}(\.[a-z]{2,})?(\S*)?$/
  );

  return urlPattern.test(url);
};
export const validateEIN = (ein) => {
  // Remove any non-digit characters from the input
  ein = ein.replace(/\D/g, "");

  // Check if the EIN has exactly 9 digits
  if (/^\d{9}$/.test(ein)) {
    return true; // Valid EIN
  } else {
    return false; // Invalid EIN
  }
};

export const validateCAGECode = (cageCode) => {
  //The Commercial and Government Entity (CAGE) code is a unique identifier assigned to suppliers to various government or defense agencies. CAGE codes typically consist of five alphanumeric characters.
  // Use a regular expression to check if the CAGE code matches the expected pattern
  const cageCodePattern = /^[0-9A-Za-z]{5}$/;
  return cageCodePattern.test(cageCode);
};

export const validateDnBNumber = (dnbNumber) => {
  //Dun & Bradstreet (D&B) is a company that provides business information and data. A D&B Number, also known as a D-U-N-S Number (Data Universal Numbering System), is a unique identifier for businesses. It typically consists of nine digits.
  // Use a regular expression to check if the D&B Number matches the expected pattern
  const dnbNumberPattern = /^\d{9}$/;
  return dnbNumberPattern.test(dnbNumber);
};

export const zoneName = (value) => {
  const regex = /^[a-zA-Z]+(?: ?[a-zA-Z]+)* ?$/;
  return regex.test(value) ? undefined : "Use only letters.";
};
