import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { formatPhoneNumber } from './phoneUtils';

export const NewFormatPhoneNumber = (phoneNumber) => {
    
    if (phoneNumber == null) {
        console.error('Invalid phone number input:', phoneNumber);
        return '';
    }
    const phoneStr = typeof phoneNumber === 'number' ? phoneNumber.toString() : phoneNumber;
    if (typeof phoneStr !== 'string' || phoneStr.trim() === '') {
        console.error('Phone number must be a non-empty string:', phoneStr);
        return '';
    }

    const countryCode = process.env.REACT_APP_ENV === 'women_first' ? 'CA' : 'US';
    console.log(phoneStr);

    const parsedNumber = parsePhoneNumberFromString(phoneStr);
    if (parsedNumber) {
        const countryCode = parsedNumber?.countryCallingCode;
        let nationalFormatted = parsedNumber?.formatNational();

        if (countryCode === "20" && nationalFormatted?.startsWith("0")) {
            nationalFormatted = nationalFormatted.slice(1);
            return `(+${countryCode}) ${nationalFormatted}`;
        } else if (countryCode === "20") {
            return `(+${countryCode}) ${nationalFormatted}`;
        } else {
            return `+${countryCode} ${nationalFormatted}`;
        }
    }
    return formatPhoneNumber(phoneStr, countryCode);
};
