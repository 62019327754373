


import React, { useState, useEffect, useRef } from "react";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  ThemeProvider,
  Typography,
} from "@mui/material";
import axios from "axios";
import Loading from "react-fullscreen-loading";
import colorConfigs from "../../../configs/colorConfigs";
import { TextInput } from "@zetaton-dev/zetaton-components-bank";
import { Formik } from "formik";
import { useTheme } from "@emotion/react";
import { auth } from "../../../configs/firebase";
import { getMethod, postMethod } from "../../Hook/UseCustomApi";
import { setAdminsList } from "../../../redux/features/appStateSlice";
import DashboardCards from "./dashboardCards";


export default function SettingsPayout() {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [role, setRole] = useState("");
  const [formErrors, setErrors] = useState({});
  const [error, setError] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("");
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const fieldStyle = {
    "& label.Mui-focused": {
      color: "#172B4D !important",
      fontSize: "17px !important",
      fontWeight: "500 !important",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#BBC1CE",
        borderWidth: 1,
        borderRadius: "8px",
      },
      "&:hover fieldset": {
        borderColor: "#BBC1CE",
        borderWidth: 1,
        borderRadius: "8px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#BBC1CE",
        borderWidth: 1,
        borderRadius: "8px",
      },
      input: {
        "&::placeholder": {
          color: "#3A3541DE",
          opacity: 1, // otherwise firefox shows a lighter color
          fontSize: 14,
        },
      },
    },
  };

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarErrorMessage, setSnackbarErrorMessage] = useState("");
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarErrorMessage(message);
    setSnackbarOpen(true);
  };



  let currentPage = useRef();
  useEffect(() => {
    currentPage.current = 1;
    fetchDrivers();
  }, []);


  const getParams = () => {
    return {
      page: currentPage.current,
      projectId:
        process.env.REACT_APP_ENV === "production" ? "ridewyze" : "roadrunner",
    };
  };

  const fetchDrivers = async () => {

    getMethod({
      url: "admin",
      params: getParams(),
      handleErrorMessage: showSnackbar,
    })
      .then((response) => {
    
      })
      .catch((err) => console.error("Error fetching more stores:", err))
      .finally(() => setLoading(false));
  };

  const handleChangeRole = (event) => {
    setRole(event.target.value);
    console.log(event);
  };
  const projectId =
    process.env.REACT_APP_ENV === "production" ? "ridewyze" : "roadrunner";
  const handleSubmit = async (values) => {
    setErrors({});
    let error = {};
    if (!values.name) {
      error.name = "Name is required.";
    } else if (values.name.length > 15) {
      error.name = "Name should not exceed 15 characters.";
    }

    // Validate Email
    if (!values.email) {
      error.email = "Email is required.";
    } else if (!emailRegex.test(values.email)) {
      error.email = "Invalid email address.";
    }

    // Validate Role
    if (role === "") {
      error.role = "Role is required.";
    }

    if (Object.keys(error).length > 0) {
      setErrors(error);
    } else if (auth && auth.currentUser) {
      const tokenId = await auth?.currentUser
        ?.getIdToken()
        .then((token) => token);
      const headers = {
        "Content-Type": "application/json",
        Authorization: tokenId,
      };

      setLoading(true);
      try {
        // Make the API request to add a new admin
        postMethod({
          url: `admin?projectId=${projectId}`,
          body: {
            name: values.name,
            profile_picture: "",
            email: values.email,
            phone_number: "",
            role: role,
          },
          headers: headers,
          handleErrorMessage: showSnackbar,
        }).then((res) => {
     
          setLoading(false);
          setIsAddOpen(false);
          fetchDrivers();
          setSnackbarMessage("Successfully adding new admin account!");
          setSnackbarSeverity("success");
          setOpenSnackbar(true);
        });
        // Fetch the updated list of admins
      } catch (error) {
        console.error("Error adding new admin:", error);
        setError(
          error?.response?.data?.code === "admin_Exist"
            ? "Admin already exist"
            : "Error adding new admin"
        );
        setLoading(false);
      }
    }
  };

  const originalHandleSubmit = async (values) => {
    setErrors({});
    let error = {};
    if (!values.name) {
      error.name = "Name is required.";
    } else if (values.name.length > 15) {
      error.name = "Name should not exceed 15 characters.";
    }

    // Validate Email
    if (!values.email) {
      error.email = "Email is required.";
    } else if (!emailRegex.test(values.email)) {
      error.email = "Invalid email address.";
    }

    // Validate Role
    if (role === "") {
      error.role = "Role is required.";
    }

    if (Object.keys(error).length > 0) {
      setErrors(error);
    } else if (auth && auth.currentUser) {
      const tokenId = await auth?.currentUser
        ?.getIdToken()
        .then((token) => token);
      const headers = {
        "Content-Type": "application/json",
        Authorization: tokenId,
      };

      setLoading(true);
      try {
        // Make the API request to add a new admin
        postMethod({
          url: `admin?projectId=${projectId}`,
          body: {
            name: values.name,
            profile_picture: "",
            email: values.email,
            phone_number: "",
            role: role,
          },
          headers: headers,
          handleErrorMessage: showSnackbar,
        }).then((res) => {

          setLoading(false);
          setIsAddOpen(false);
          fetchDrivers();
          setSnackbarMessage("Admin added successfully!");
          setSnackbarSeverity("success");
          setOpenSnackbar(true);
        });
        // Fetch the updated list of admins
      } catch (error) {
        console.error("Error adding new admin:", error);
        setError(
          error?.response?.data?.code === "admin_Exist"
            ? "Admin already exist"
            : "Error adding new admin"
        );
        setLoading(false);
      }
    }
  };

  const initialValues = {
    name: "",
    email: "",
  };

  return (
    <>
  
      <Dialog
        style={{ zIndex: 10, borderRadius: "30px", width: "100%" }}
        open={isAddOpen}
        onClose={() => {
          setIsAddOpen(false);
          setErrors({});
          setError("");
        }}>
        {/* keepMounted */}
        <DialogContent style={{ padding: "30px", width: "100%" }}>
          <DialogContentText style={{ width: "100%" }}>
            <Typography
              variant="h6"
              style={{
                fontWeight: "bold",
                color: "black",
              }}>
              Payout
            </Typography>
          </DialogContentText>
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
              {({
                handleBlur,
                handleChange,
                values,
                errors,
                handleSubmit,
                touched,
              }) => {
                const handleNameChange = (e) => {
                  const { value } = e.target;
                  const filteredValue = value.replace(/[^a-zA-Z0-9\s{}@]/g, "");
                  handleChange("name")(filteredValue);
                };

                const handleEmailChange = (e) => {
                  const { value } = e.target;
                  const trimmedValue = value.trim();
                  handleChange("email")(trimmedValue);
                };

                return (
                  <ThemeProvider theme={theme}>
                    <Box style={{ width: "100%" }}>
                      <Box
                        component="form"
                        style={{
                          fontFamily: "inter",
                          fontSize: "small",
                          marginTop: "24px",
                          width: "100%",
                        }}>
                        <Grid container spacing={3}>
                          <Grid item md={12} xs={12}>
                            <TextInput
                              styles={fieldStyle}
                              fullWidth
                              label={"Name"}
                              name="name"
                              onChange={handleNameChange}
                              onBlur={handleBlur("name")}
                              required
                              value={values?.name}
                              variant="outlined"
                              placeholder="Please enter name"
                              filled="true"
                              // Set it as a string "true"
                              focused
                              error={formErrors.name?.length > 0}
                            />
                            {formErrors.name?.length > 0 && (
                              <Typography
                                variant="caption"
                                style={{ color: "red" }}>
                                {formErrors.name}
                              </Typography>
                            )}
                          </Grid>
                          <Grid item md={12} xs={12}>
                           
                          </Grid>
                          <Grid item md={12} xs={12}>
                            <FormControl
                              fullWidth
                              style={{ marginTop: "2px" }}
                              sx={{
                                "& label.Mui-focused": {
                                  color: "rgba(0, 0, 0, 0.6)",
                                  bordercolor: "#BBC1CE",
                                },
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    borderColor: "#BBC1CE",
                                  },
                                  "&:hover fieldset": {
                                    borderColor: "#BBC1CE",
                                  },
                                  "&.Mui-focused fieldset": {
                                    borderColor: "#BBC1CE",
                                    borderWidth: "1px",
                                  },
                                  // borderRadius:"10px"
                                  height: "52px",
                                },
                              }}>
                              <InputLabel
                                id="demo-simple-select-label"
                                required
                                style={{
                                  color: "#172B4D",
                                  fontSize: "14.5px",
                                }}>
                                Method
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={role}
                                label="Role"
                                onChange={handleChangeRole}
                                style={{
                                  borderRadius: "10px",
                                  height: "50px",
                                }}>
                                <MenuItem value={"auto"}>Auto</MenuItem>
                                <MenuItem value={"manual"}>Manual</MenuItem>
                                <MenuItem value={"cash"}>Cash</MenuItem>
                              </Select>
                            </FormControl>
                            {formErrors.role?.length > 0 && (
                              <Typography
                                variant="caption"
                                style={{ color: "red" }}>
                                {formErrors.role}
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "center",
                            paddingTop: "12px",
                          }}></div>
                      </Box>
                      <p style={{ color: "red" }}>{error}</p>
                    </Box>
                    <DialogActions
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        marginBottom: "20px",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                      }}>
                      <Button
                        variant="outlined"
                        style={{
                          color: loading ? "gray" : colorConfigs?.sidebar?.bg,
                          borderColor: "lightgray",
                          width: "100%",
                        }}
                        onClick={() => {
                          setIsAddOpen(false);
                          setErrors({});
                          setError("");
                        }}>
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        style={{
                          background: loading
                            ? "gray"
                            : colorConfigs?.sidebar?.bg,
                          color: "white",
                          width: "100%",
                        }}
                        onClick={handleSubmit}
                        disabled={loading}>
                        {loading ? "...Loading" : "Add"}
                      </Button>
                    
                    </DialogActions>
                  </ThemeProvider>
                );
              }}
            </Formik>
            </DialogContent>
      </Dialog>
      <Loading
        loading={!!loading}
        background="rgba(86, 100, 210, 0.1)"
        loaderColor={colorConfigs.sidebar?.bg}
      />
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}>
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}>
        <Alert onClose={handleSnackbarClose} severity="error">
          {snackbarErrorMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

