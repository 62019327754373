import { Box, Grid, InputAdornment, MenuItem, Paper, TextField, Typography, Button, Select, Checkbox, ListItemText, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PolylineOutlinedIcon from '@mui/icons-material/PolylineOutlined';
import GpsFixedOutlinedIcon from '@mui/icons-material/GpsFixedOutlined';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import ShareLocationOutlinedIcon from '@mui/icons-material/ShareLocationOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { makeStyles } from '@mui/styles';
import colorConfigs from '../../../../configs/colorConfigs';
import distanceUnit from '../../../../utils/distanceUnit';
import { postDataRedux, putDataRedux, saveCoordinates, saveLocationInfo, setRadius } from '../../../../redux/features/appStateSlice';
import AddressAutoComplete from '../../../../components/common/AddressAutoComplete';
import MapWithDraw from '../../../../components/googleMaps/draw';
import SelectAdd from '../../../../components/googleMaps/selectAdd';
import { getMethod, postMethod, putMethod } from '../../../Hook/UseCustomApi';

const inputStyle = {
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#BBC1CE",
            borderWidth: 1,
            borderRadius: "8px",
        },
        "&:hover fieldset": {
            borderColor: "#BBC1CE",
            borderWidth: 1,
            borderRadius: "8px",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#BBC1CE",
            borderWidth: 1,
            borderRadius: "8px",
        },
        input: {
            "&::placeholder": {
                color: "#BBC1CE",
                opacity: 1,
                fontSize: 14,
            },
            padding: "10px",
        },
    },
    "& label": {
        color: "#7A869A",
        lineHeight: "0.8375em",
    },
    "& label.Mui-focused": {
        color: "#000",
        fontSize: "14px",
        fontWeight: "500",
    },
    "& .MuiSelect-select": {
        padding: "10px",
        backgroundColor: "#FFF",
    },
    "& .MuiSelect-icon": {
        color: "#BBC1CE",
    },
    "& .MuiMenuItem-root": {
        fontSize: "14px",
    },
};

const useStyles = makeStyles({
    input: {
        "& input[type=number]": {
            "-moz-appearance": "textfield",
        },
        "& input[type=number]::-webkit-outer-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
        },
        "& input[type=number]::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
        },
    },
});

function AddNewVoucher() {
    const [activeRequirement, setActiveRequirement] = useState("radius");
    const dispatch = useDispatch();
    const formDataa = useSelector((state) => state.appState.formData);
    const selectedVoucher = useSelector((state) => state.appState.selectedVoucher);

    const [isFormDirty, setIsFormDirty] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const [voucherDetails, setVoucherDetails] = useState({
        name: '',
        maxNumberOfRides: '',
        maxAmountPerVoucher: '',
        maxAmountPerRide: '',
        rideTypes: [],
    });

    const [rideOptions, setRideOptions] = useState([]);
    const [addressToShowZipCode, setAddressToShowZipCode] = useState("");
    const [addressToShowCity, setAddressToShowCity] = useState("");
    const [addressToShowAddress, setAddressToShowAddress] = useState("");
    const [zoneBy, setZoneBy] = useState("map");
    const [address, setAddress] = useState(selectedVoucher?.address || {});
    const [formErrors, setErrors] = useState({});
    const [mapView, setMapView] = useState(false);
    const classes = useStyles();
    const rad = useSelector((state) => state.appState.radius);
    const selectedCorporate = useSelector((state) => state.appState.selectedCorporate);
    const saveLocationInfoo = useSelector((state) => state.appState.saveLocationInfo);
    const saveCoordinatess = useSelector((state) => state.appState.saveCoordinates);

    const navigate = useNavigate();
    const location = useLocation();

    const isEditMode = location.pathname.includes("edit");

    useEffect(() => {
        if (isEditMode && selectedVoucher) {
            setVoucherDetails({
                name: selectedVoucher.voucher_name,
                maxNumberOfRides: selectedVoucher.max_rides_number,
                maxAmountPerVoucher: selectedVoucher.max_voucher_price,
                maxAmountPerRide: selectedVoucher.max_ride_price,
                rideTypes: selectedVoucher.ride_types,
            });
            console.log(selectedVoucher)
            setAddressToShowZipCode(selectedVoucher.address?.zip_code || "");
            setAddressToShowCity(selectedVoucher.address?.primary_line || "");
            setAddressToShowAddress(selectedVoucher.address?.primary_line || "");
            setZoneBy(selectedVoucher.zone_type === "radius" && selectedVoucher.zone_options.radius?.radius_length_show ? "map" : "address");
            setActiveRequirement(selectedVoucher.zone_type);
            dispatch(setRadius(selectedVoucher.zone_options.radius?.radius_length_show || 0));
            dispatch(saveLocationInfo(selectedVoucher.address));
            dispatch(saveCoordinates(selectedVoucher.zone_options.polygon_points || []));
        }
    }, [isEditMode, selectedVoucher]);

    const handleSelectzipcode = async (address, placeId, suggestion) => {
        try {
            const results = await geocodeByAddress(address);
            const latLng = await getLatLng(results[0]);

            const selectedPlaceData = {
                city: results[0]?.address_components.find((component) => component.types.includes("locality"))?.long_name || "",
                state: results[0]?.address_components.find((component) => component.types.includes("administrative_area_level_1"))?.short_name || "",
                country: results[0]?.address_components.find((component) => component.types.includes("country"))?.short_name || "",
                zip_code: results[0]?.address_components.find((component) => component.types.includes("postal_code"))?.long_name || "",
                lat: latLng.lat,
                lng: latLng.lng,
                route: results[0]?.address_components.find((component) => component.types.includes("route"))?.long_name || "",
                street_number: results[0]?.address_components.find((component) => component.types.includes("street_number"))?.long_name || "",
                primary_line: results[0]?.formatted_address || "",
                placeId: placeId || "",
                address: address || "",
            };
            setAddress(selectedPlaceData);
            setAddressToShowZipCode(selectedPlaceData?.zip_code);
            setAddressToShowCity(selectedPlaceData?.primary_line);
            setAddressToShowAddress(selectedPlaceData?.primary_line);
        } catch (error) {
            console.error("Error selecting address", error);
        }
    };

    const requirementsList = [
        {
            key: "zipCode",
            icon: <ShareLocationOutlinedIcon style={{ color: colorConfigs?.sidebar?.bg }} />,
            title: process.env.REACT_APP_ENV === 'women_first' ? 'Postal Code' : 'Zip Code',
        },
        {
            key: "city",
            icon: <PlaceOutlinedIcon style={{ color: colorConfigs?.sidebar?.bg }} />,
            title: "City",
        },
        {
            key: "radius",
            icon: <GpsFixedOutlinedIcon style={{ color: colorConfigs?.sidebar?.bg }} />,
            title: "Radius",
        },
        {
            key: "polygon",
            icon: <PolylineOutlinedIcon style={{ color: colorConfigs?.sidebar?.bg }} />,
            title: "Polygon",
        },
    ];

    const handleRequirementSelect = (key) => {
        setActiveRequirement(key);
        if (key === "city" || key === "zipCode") {
            setMapView(true);
        } else {
            setMapView(false);
        }
        setAddressToShowZipCode("");
        setAddressToShowCity("");
        setAddressToShowAddress("");
        dispatch(setRadius(0));
        // dispatch(saveLocationInfo({}));
        dispatch(saveCoordinates([]));
        setAddress({});
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setVoucherDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value,
        }));
    };

    const handleMultiSelectChange = (event) => {
        const { value } = event.target;
        console.log(value)
        setVoucherDetails((prevDetails) => ({
            ...prevDetails,
            rideTypes: value,
        }));
    };

    const handleSubmit = async () => {
        setErrors({});
        let error = {};

        const letterAndSpacePattern = /^[a-zA-Z\s]+$/;

        if (!voucherDetails.name) {
            error.name = "Name is required.";
        } else if (!letterAndSpacePattern.test(voucherDetails.name)) {
            error.name = "Name can only contain letters and spaces.";
        } else if (voucherDetails.name.length > 20) {
            error.name = "Name cannot exceed 20 characters.";
        } else if (!voucherDetails.maxNumberOfRides || isNaN(voucherDetails.maxNumberOfRides)) {
            error.maxNumberOfRides = "Max Number Of Rides is required and must be a number.";
        } else if (!voucherDetails.maxAmountPerVoucher || isNaN(voucherDetails.maxAmountPerVoucher)) {
            error.maxAmountPerVoucher = "Max Amount Per Voucher is required and must be a number.";
        } else if (!voucherDetails.maxAmountPerRide || isNaN(voucherDetails.maxAmountPerRide)) {
            error.maxAmountPerRide = "Max Amount Per Ride is required and must be a number.";
        } else if (!voucherDetails.rideTypes.length) {
            error.rideTypes = "At least one Ride Type must be selected.";
        } else if (rad <= 5 && activeRequirement === "radius") {
            error.radius = "Radius must be more than five.";
        } else if (activeRequirement === "zipCode" && address.lat === undefined) {
            error.zip_code = "Postal code is required.";
        } else if (activeRequirement === "city" && address.lat === undefined) {
            error.city = "City is required.";
        } else if (activeRequirement === "radius" && zoneBy === "address" && address.lat === undefined) {
            error.radiuss = "Address is required.";
        } else if (activeRequirement === "polygon" && (!saveCoordinatess || saveCoordinatess.length === 0)) {
            error.polygon = "Polygon is required. Please draw a polygon.";
        }

        if (Object.keys(error).length > 0) {
            setErrors(error);
        } else {
            setIsLoading(true);
            let options = {
                radius: { radius_length: 0, radius_length_show: 0 },
            };
            if (activeRequirement === "zipCode") {
                options.zip_code = addressToShowZipCode;
            } else if (activeRequirement === "radius") {
                options.radius.radius_length = Number(distanceUnit(rad, false));
                options.radius.radius_length_show = rad;
            } else if (activeRequirement === "city") {
                options.city = addressToShowCity;
            } else if (activeRequirement === "polygon") {
                options.polygon_points = saveCoordinatess;
            }
            let addressData = {};

            if (activeRequirement === "zipCode" || activeRequirement === "city") {
                addressData.lat = address?.lat;
                addressData.lng = address?.lng;
                addressData.street_number = address?.street_number;
                addressData.street_name = address?.route;
                addressData.city = address?.city;
                addressData.state = address?.state;
                addressData.country = "Anyland";
                addressData.zip_code = address?.zip_code;
                addressData.route = address?.route;
                addressData.primary_line = address?.primary_line;
                addressData.placeId = address?.placeId;
                addressData.address = address?.address;
            } else if (activeRequirement === "radius" && zoneBy === "address") {
                addressData.lat = address?.lat;
                addressData.lng = address?.lng;
                addressData.street_number = address?.street_number;
                addressData.street_name = address?.route;
                addressData.city = address?.city;
                addressData.state = address?.state;
                addressData.country = "Anyland";
                addressData.zip_code = address?.zip_code;
                addressData.route = address?.route;
                addressData.primary_line = address?.primary_line;
                addressData.placeId = address?.placeId;
                addressData.address = address?.address;
            } else if (activeRequirement === "radius" && zoneBy === "map") {
                addressData.lat = saveLocationInfoo?.lat;
                addressData.lng = saveLocationInfoo?.lng;
                addressData.street_number = "100";
                addressData.street_name = "Main St";
                addressData.city = "Anytown";
                addressData.state = "Anystate";
                addressData.country = "Anyland";
                addressData.zip_code = saveLocationInfoo?.zipCode;
                addressData.route = "Downtown Route";
                addressData.primary_line = "100 Main St, Downtown Route";
                addressData.placeId = "ChIJrTLr-GyuEmsRBfy61i59si0";
                addressData.address = saveLocationInfoo?.address;
            } else if (activeRequirement === "polygon") {
                addressData.lat = saveLocationInfoo?.lat;
                addressData.lng = saveLocationInfoo?.lng;
                addressData.street_number = "100";
                addressData.street_name = "Main St";
                addressData.city = "Anytown";
                addressData.state = "Anystate";
                addressData.country = "Anyland";
                addressData.zip_code = saveLocationInfoo?.zipCode;
                addressData.route = "Downtown Route";
                addressData.primary_line = "100 Main St, Downtown Route";
                addressData.placeId = "ChIJrTLr-GyuEmsRBfy61i59si0";
                addressData.address = saveLocationInfoo?.address;
            }

            const data = {
                voucher_name: voucherDetails.name,
                corporate_id: selectedCorporate?._id,
                max_rides_number: voucherDetails.maxNumberOfRides,
                max_voucher_price: voucherDetails.maxAmountPerVoucher,
                max_ride_price: voucherDetails.maxAmountPerRide,
                ride_types: voucherDetails.rideTypes,
                zone_type: activeRequirement,
                address: addressData,
                zone_options: options
            };

            if (isEditMode) {
                console.log(data)
                putMethod({
                    url: `admin/voucher/${selectedVoucher._id}`,
                    body: data,
                })
                    .then((response) => {
                        dispatch(putDataRedux({ stateName: 'vouchersList', key: '_id', value: selectedVoucher._id, data: response.data }));
                        navigate('/cooperates/corporate-details');
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            } else {
                postMethod({
                    url: `admin/voucher`,
                    body: data,
                })
                    .then((response) => {
                        dispatch(postDataRedux({ stateName: 'vouchersList', data: response.data, push: true }));
                        navigate('/cooperates/corporate-details');
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            }
            setIsFormDirty(false);
        }
    };

    const handleSave = () => {
        handleSubmit();
    };

    const handleCancel = () => {
        navigate('/cooperates/corporate-details');
    };

    useEffect(() => {
        const getCarTypes = async () => {
            try {
                const response = await getMethod({
                    url: `admin/car-type/all`,
                });
                if (response?.data) {
                    setRideOptions(response.data.map(carType => ({
                        name: carType.name,
                        value: carType,
                    })));
                }
            } catch (error) {
                console.error("Error fetching car types:", error);
            }
        };
        getCarTypes();
    }, []);



    return (
        <Box sx={{ background: '#FFF', p: 2, mt: -9, borderRadius: 4, minHeight: '100vh' }}>
            <Box
                style={{
                    fontFamily: "inter",
                    fontSize: "small",
                    borderRadius: "8px",
                    padding: "10px",
                    width: "100%",
                    marginBottom: "10px",
                    color: colorConfigs?.sidebar?.bg,
                    backgroundColor: "#F4F4F4",
                }}
            >
                <Typography variant="body1" style={{ fontWeight: "bold" }}>
                    {isEditMode ? "Edit Corporate Voucher" : "Add New Corporate Voucher"}
                </Typography>
            </Box>
            <Box display="flex" alignItems="center" style={{ marginBottom: "20px" }}>
                <Typography component="span" style={{ fontWeight: 700, fontSize: 18, marginRight: "8px" }}>
                    •
                </Typography>
                <Typography component="span" style={{ fontWeight: 700, fontSize: 18 }}>
                    Voucher Details
                </Typography>
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        sx={inputStyle}
                        fullWidth
                        label="Name"
                        placeholder='Please Enter a Name'
                        focused
                        name="name"
                        value={voucherDetails.name}
                        onChange={handleChange}
                        error={!!formErrors.name}
                        helperText={formErrors.name}
                        inputProps={
                            {
                                maxLength: 25
                            }
                        }

                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        sx={inputStyle}
                        fullWidth
                        label="Max Number Of Rides"
                        placeholder='Please Enter Max Number Of Rides'
                        focused
                        type='number'
                        name="maxNumberOfRides"
                        value={voucherDetails.maxNumberOfRides}
                        onChange={(e) => {
                            const value = e.target.value;
                            if (value.length <= 7) {
                                handleChange(e);
                            }
                        }}
                        error={!!formErrors.maxNumberOfRides}
                        helperText={formErrors.maxNumberOfRides}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        sx={inputStyle}
                        fullWidth
                        label="Max Amount Per Voucher"
                        placeholder='Please Enter Max Amount Per Voucher'
                        focused
                        type='number'
                        name="maxAmountPerVoucher"
                        value={voucherDetails.maxAmountPerVoucher}
                        onChange={(e) => {
                            const value = e.target.value;
                            if (value.length <= 7) {
                                handleChange(e);
                            }
                        }}
                        error={!!formErrors.maxAmountPerVoucher}
                        helperText={formErrors.maxAmountPerVoucher}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        sx={inputStyle}
                        fullWidth
                        label="Max Amount Per Ride"
                        placeholder='Please Enter Max Amount Per Ride'
                        focused
                        type='number'
                        name="maxAmountPerRide"
                        value={voucherDetails.maxAmountPerRide}
                        onChange={(e) => {
                            const value = e.target.value;
                            if (value.length <= 7) {
                                handleChange(e);
                            }
                        }}
                        error={!!formErrors.maxAmountPerRide}
                        helperText={formErrors.maxAmountPerRide}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Select
                        sx={inputStyle}
                        fullWidth
                        multiple
                        name="rideTypes"
                        value={voucherDetails.rideTypes}
                        onChange={handleMultiSelectChange}
                        displayEmpty
                        renderValue={(selected) => {
                            if (selected.length === 0) {
                                return <Box sx={{ color: '#bbc1ce', fontSize: 14, fontWeight: 400 }} >Select Ride Type</Box>;
                            }
                            return selected
                                .map(value => rideOptions.find(option => option?.value?._id === value?._id)?.name)
                                .join(', ');
                        }}
                        error={!!formErrors.rideTypes}
                    >
                        <MenuItem disabled value="">
                            <Box>Select Ride Type</Box>
                        </MenuItem>
                        {rideOptions.map((option) => (
                            <MenuItem key={option?.value?._id} value={option.value}>
                                <Checkbox checked={voucherDetails.rideTypes.some((type) => type?._id === option?.value?._id)} />
                                <ListItemText primary={option.name} />
                            </MenuItem>
                        ))}
                    </Select>

                    {formErrors.rideTypes && (
                        <Typography variant="caption" style={{ color: "red" }}>
                            {formErrors.rideTypes}
                        </Typography>
                    )}
                </Grid>
            </Grid>
            <Box display="flex" alignItems="center" style={{ margin: "10px 0" }}>
                <Typography component="span" style={{ fontWeight: 700, fontSize: 18, marginRight: "8px" }}>
                    •
                </Typography>
                <Typography component="span" style={{ fontWeight: 700, fontSize: 18 }}>
                    Zone
                </Typography>
            </Box>
            <Grid container spacing={3}>
                <Grid item lg={5} sx={{ position: 'relative' }}>
                    <Grid container spacing={3}>
                        <Grid
                            container
                            spacing={1}
                            style={{
                                paddingTop: "30px",
                                justifyContent: "center",
                                paddingLeft: "24px",
                                width: "100%",
                            }}
                        >
                            {requirementsList.map((requirement) => (
                                <Grid
                                    key={requirement.key}
                                    item
                                    lg={3}
                                    sm={4}
                                    onClick={() => (
                                        handleRequirementSelect(requirement.key),
                                        setErrors({})
                                    )}
                                    sx={{ cursor: "pointer" }}
                                >
                                    <div
                                        style={{
                                            border:
                                                activeRequirement !== requirement.key
                                                    ? `0.5px solid lightgray`
                                                    : `1px solid ${colorConfigs?.sidebar?.bg}`,
                                            borderRadius: "10px",
                                            textAlign: "center",
                                            padding: "16px 0",
                                            boxSizing: "border-box",
                                            transition: "0.3s",

                                            "&:hover": {
                                                boxShadow: "0 4px 6px 0 rgba(0,0,0,0.02)",
                                            },
                                        }}
                                    >
                                        {requirement.icon}
                                        <Typography
                                            variant={"body2"}
                                            style={{
                                                marginTop: "8px",
                                                color: "#7A869A",
                                            }}
                                        >
                                            {requirement.title}
                                        </Typography>
                                    </div>

                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid item md={12}>
                        {activeRequirement === "zipCode" && (
                            <>
                                <div style={{ marginTop: "30px" }}>
                                    <AddressAutoComplete
                                        address={addressToShowZipCode}
                                        setAddress={setAddressToShowZipCode}
                                        handleSelect={handleSelectzipcode}
                                        types={"postal_code"}
                                        lable="Postal code"
                                        placeholder={"Please Enter Zip code"}
                                    />
                                    {formErrors.zip_code?.length > 0 && (
                                        <Typography
                                            variant="caption"
                                            style={{ color: "red" }}
                                        >
                                            {formErrors.zip_code}
                                        </Typography>
                                    )}
                                </div>
                            </>
                        )}
                        {activeRequirement === "city" && (
                            <>
                                <div style={{ marginTop: "30px" }}>
                                    <AddressAutoComplete
                                        address={addressToShowCity}
                                        setAddress={setAddressToShowCity}
                                        handleSelect={handleSelectzipcode}
                                        types={"(cities)"}
                                        lable="City"
                                        placeholder={"Please Enter City"}
                                    />
                                    {formErrors.city?.length > 0 && (
                                        <Typography
                                            variant="caption"
                                            style={{ color: "red" }}
                                        >
                                            {formErrors.city}
                                        </Typography>
                                    )}
                                </div>
                            </>
                        )}
                        {activeRequirement === "radius" && (
                            <Grid item md={12} xs={12} style={{ marginTop: "20px" }}>
                                <TextField
                                    className={classes.input}
                                    fullWidth
                                    label={"Radius"}
                                    name="radius"
                                    onChange={(event) => {
                                        setIsFormDirty(true)
                                        let value = event.target.value;
                                        if (value.length > 1 && value.startsWith("0")) {
                                            value = value.replace(/^0+/, "");
                                        }
                                        dispatch(setRadius(value));
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">Km</InputAdornment>
                                        ),
                                    }}
                                    sx={inputStyle}
                                    inputProps={{ pattern: "[0-9]*" }}
                                    required
                                    type="number"
                                    value={rad}
                                    variant="outlined"
                                    placeholder="Please enter Radius Number"
                                    filled="true"
                                    focused
                                    error={formErrors.radius?.length > 0 ? true : false}
                                />
                                {formErrors.radius?.length > 0 && (
                                    <Typography variant="caption" style={{ color: "red" }}>
                                        {formErrors.radius}
                                    </Typography>
                                )}
                            </Grid>
                        )}
                    </Grid>
                    {activeRequirement === "radius" && (
                        <Grid item md={12}>
                            <Paper
                                style={{
                                    marginTop: "20px",
                                    paddingTop: "10px",
                                    display: "flex",
                                    justifyContent: "space-around",
                                    paddingBottom: "10px",
                                }}
                            >
                                <div>
                                    <input
                                        type="radio"
                                        id="address"
                                        name="zoneBy"
                                        value="address"
                                        checked={zoneBy === "address"}
                                        onChange={(e) => {
                                            setIsFormDirty(true)
                                            setZoneBy(e.target.value)
                                        }}
                                    />
                                    <label htmlFor="address">Address</label>
                                </div>
                                <div>
                                    <input
                                        type="radio"
                                        id="map"
                                        name="zoneBy"
                                        value="map"
                                        checked={zoneBy === "map"}
                                        onChange={(e) => {
                                            setIsFormDirty(true)
                                            setZoneBy(e.target.value)
                                        }}
                                    />
                                    <label htmlFor="map">Map</label>
                                </div>
                            </Paper>
                            {zoneBy === "address" && (
                                <div style={{ marginTop: "30px" }}>
                                    <AddressAutoComplete
                                        address={addressToShowAddress}
                                        setAddress={setAddressToShowAddress}
                                        handleSelect={handleSelectzipcode}
                                        types={"address"}
                                        lable="Address"
                                        placeholder={"Please Enter Address"}
                                    />
                                    {formErrors.radiuss?.length > 0 && (
                                        <Typography
                                            variant="caption"
                                            style={{ color: "red" }}
                                        >
                                            {formErrors.radiuss}
                                        </Typography>
                                    )}
                                </div>
                            )}
                        </Grid>
                    )}
                    <Box sx={{ marginTop: '30px', position: 'absolute', bottom: 3 }}>
                        <Button
                            variant="outlined"
                            onClick={handleCancel}
                            disabled={isLoading}
                            style={{
                                marginRight: 8,
                                color: colorConfigs?.sidebar?.bg,
                                borderColor: colorConfigs?.sidebar?.bg,
                                backgroundColor: "white",
                                width: "150px",
                                height: "42px"
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSave}
                            style={{
                                backgroundColor: colorConfigs?.sidebar?.bg,
                                color: "white",
                                width: "150px",
                                height: "42px"
                            }}
                            disabled={isLoading}
                            startIcon={isLoading && <CircularProgress size={24} />}
                        >
                            Save
                        </Button>
                    </Box>
                </Grid>
                <Grid item lg={7}>
                    {activeRequirement === "polygon" && (
                        <>
                            <MapWithDraw
                                options={selectedVoucher?.zone_options?.polygon_points}
                                type={selectedVoucher?.zone_type}
                            />
                            {formErrors?.polygon?.length > 0 && (
                                <Typography variant="caption" style={{ color: "red" }}>
                                    {formErrors?.polygon}
                                </Typography>
                            )}
                        </>
                    )}
                    {activeRequirement !== "polygon" && (
                        <>
                            <SelectAdd selectedZone={undefined} mapView={mapView} lat={address?.lat} lng={address.lng} />
                        </>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
}

export default AddNewVoucher;
