import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import { db } from "../../configs/firebase";
import NotificationCard from './NotificationCard';
import { Spin, Badge } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { IoNotificationsOutline } from 'react-icons/io5';
import Fade from "@mui/material/Fade";
import { Typography, IconButton, Box, SnackbarContent } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import colorConfigs from "../../configs/colorConfigs";
import { closeSnackbar, useSnackbar } from 'notistack'; // Importing useSnackbar
import { useLocation } from "react-router-dom";

const NotificationsList = () => {
    const [loading, setLoading] = useState(false);
    const [notificationsData, setNotificationsData] = useState([]);
    const [lastNotification, setLastNotification] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [open, setOpen] = useState(false);
    const [unreadCount, setUnreadCount] = useState(0);
    const listRef = useRef(null);
    const lastClosedRef = useRef(null); // Ref to track when the list was last closed
    const location = useLocation();

    const { enqueueSnackbar } = useSnackbar(); // Using the snackbar hook

    const userData = useSelector((state) => state.appState.userData);

    const antIcon = <LoadingOutlined style={{ fontSize: 24, color: "black" }} spin />;

    const fetchNotifications = useCallback(async (isInitial = false) => {
        if (loading || !hasMore) return;

        setLoading(true);

        let ref = db.collection("notifications")
            .orderBy("created_at", "desc")
            .limit(10);

        if (!isInitial && lastNotification) {
            ref = ref.startAfter(lastNotification);
        }

        const snapshot = await ref.get();

        if (snapshot.size > 0) {
            const newNotifications = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            const lastVisibleDoc = snapshot.docs[snapshot.docs.length - 1];

            // Append new notifications to the current list without affecting existing ones
            setNotificationsData((prev) => [...prev, ...newNotifications]);
            setLastNotification(lastVisibleDoc);
            setHasMore(snapshot.size === 10);
        } else {
            setHasMore(false);
        }

        setLoading(false);
    }, [lastNotification, loading, hasMore]);

    useEffect(() => {
        const unsubscribe = db.collection("notifications")
            .orderBy("created_at", "desc")
            .limit(1) // Listen to only the newest notification
            .onSnapshot((snapshot) => {
                if (!snapshot.empty) {
                    const newNotification = snapshot.docs[0];
                    setNotificationsData((prev) => {
                        // Check if the notification already exists in the list
                        if (prev.some((n) => n.id === newNotification.id)) return prev;
                        return [{ id: newNotification.id, ...newNotification.data() }, ...prev];
                    });
                }
            });

        return () => unsubscribe(); // Cleanup listener on unmount
    }, []);

    useEffect(() => {
        if (open) {
            setLastNotification(null);
            setNotificationsData([]);
            fetchNotifications(true);
        }
    }, [open]);


    useEffect(() => {
        const listElement = listRef.current;
        const handleScroll = () => {
            if (
                listElement.scrollTop + listElement.clientHeight >= listElement.scrollHeight - 5 &&
                hasMore &&
                !loading
            ) {
                fetchNotifications();
            }
        };

        if (listElement) {
            listElement.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (listElement) {
                listElement.removeEventListener('scroll', handleScroll);
            }
        };
    }, [fetchNotifications, hasMore, loading]);

    const handleClick = () => {
        if (open) {
            // Record the time when closing the list
            lastClosedRef.current = Date.now();
        }
        setOpen((prev) => !prev);
    };

    const markAsRead = async (notificationId) => {
        try {
            await db.collection("notifications").doc(notificationId).update({
                open: true,
            });
            setNotificationsData((prev) =>
                prev.map((n) =>
                    n.id === notificationId ? { ...n, open: true } : n
                )
            );
        } catch (error) {
            console.error("Error updating notification status: ", error);
        }
    };

    const markAllAsRead = async () => {
        try {
            const snapshot = await db.collection("notifications").where('open', '==', false).get();

            const batch = db.batch();
            snapshot.docs.forEach((doc) => {
                const notificationRef = db.collection("notifications").doc(doc.id);
                batch.update(notificationRef, { open: true });
            });

            await batch.commit();

            setNotificationsData((prev) =>
                prev.map((n) => ({ ...n, open: true }))
            );
        } catch (error) {
            console.error("Error marking all notifications as read: ", error);
        }
    };

    useEffect(() => {
        let snackbarDisplayed = false;
        const unsubscribe = db.collection("notifications")
            .where('open', '==', false)
            .orderBy("created_at", "desc")
            .onSnapshot((snapshot) => {
                const newNotifications = [];
                let newUnreadCount = 0;

                snapshot.forEach((doc) => {
                    const notification = { id: doc.id, ...doc.data() };
                    newNotifications.push(notification);

                    if (!notification.open) {
                        newUnreadCount += 1;
                    }
                });
                // Update the unread count
                if (newUnreadCount > 1 || newUnreadCount === 0)
                    setUnreadCount(newUnreadCount);
                else if (newNotifications.length === 1 && unreadCount === 0)
                    setUnreadCount(newUnreadCount);
                console.log(unreadCount)
                // Show a snackbar notification only if no snackbar is already open
                if (!snackbarDisplayed && !open && newNotifications?.length > 0 &&
                    (!lastClosedRef.current || Date.now() - lastClosedRef.current > 2000) &&
                    newNotifications[0]?.object_type !== 'panic_button_driver' &&
                    newNotifications[0]?.object_type !== 'panic_button_customer' && (newNotifications[0]?.object_type !== 'messaging_with_admin' && location?.pathname !== '/support')) {

                    snackbarDisplayed = true; // Mark snackbar as displayed

                    // Display snackbar notification for the first unread notification
                    const key = enqueueSnackbar(
                        <NotificationCard
                            markAsRead={markAsRead}
                            onClose={() => closeSnackbar(key)}
                            notification={newNotifications[0]}
                        />,
                        {
                            variant: 'info',
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'right',
                            },
                            autoHideDuration: 3000,
                            onExited: () => {
                                snackbarDisplayed = false; // Reset flag when snackbar exits
                            },
                            content: (key, message) => (
                                <SnackbarContent
                                    message={message}
                                    style={{
                                        backgroundColor: colorConfigs?.sidebar?.ligthBg,
                                        color: '#000',
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                        borderRadius: '8px',
                                        boxShadow: 'none',
                                        padding: '0',
                                        outline: 0,
                                        border: `1px solid ${colorConfigs?.sidebar?.bg}`
                                    }}
                                />
                            ),
                        }
                    );
                }
            });

        return () => unsubscribe();
    }, [enqueueSnackbar, open, closeSnackbar]);


    return (
        <Box sx={{ mt: 1.5, mr: 2, mb: 3, position: 'relative' }}>
            <IconButton
                aria-label="notifications"
                aria-controls="notification-menu"
                aria-haspopup="true"
                onClick={handleClick}
                style={{ backgroundColor: '#FFF', borderRadius: 25, width: 40, height: 40, display: 'flex', justifyContent: 'center' }}
            >
                <Badge count={unreadCount} color="primary">
                    <IoNotificationsOutline size={25} />
                </Badge>
            </IconButton>
            {open && <div
                style={{
                    position: "absolute",
                    top: "25px",
                    right: "8px",
                    width: 0,
                    height: 0,
                    borderRight: "14px solid transparent",
                    borderBottom: "14px solid #FFF",
                    borderLeft: "14px solid  transparent",
                    borderTop: "14px solid transparent ",
                    borderRadius: "0",
                    padding: "0",
                }}
            >
            </div>}
            {open && (
                <Fade in={open} style={{ padding: 0 }}>
                    <div
                        ref={listRef}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            position: "absolute",
                            top: "50px",
                            right: "-10px",
                            width: "470px",
                            maxHeight: "500px",
                            overflowY: "auto",
                            backgroundColor: "white",
                            borderRadius: "15px",
                            zIndex: 5,
                            boxShadow: "0px 4px 32px rgba(61, 70, 112, 0.08)",
                        }}
                    >
                        <Box sx={{ position: 'relative', mt: '0px', p: 0, width: '100%', zIndex: 9999, }}>
                            <Box
                                display="flex"
                                alignItems="center"
                                bgcolor="#FFF"
                                sx={{
                                    position: "sticky",
                                    top: 0,
                                    zIndex: 9999,
                                    width: "100%",
                                    justifyContent: "space-between",
                                    p: 1,
                                    px: 2,
                                    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                                }}
                            >
                                <Typography sx={{ color: '#222529', fontWeight: 600, fontSize: 16 }} >
                                    Notifications
                                </Typography>
                                <Box display="flex" alignItems="center">
                                    <Typography
                                        sx={{
                                            color: colorConfigs?.sidebar?.bg,
                                            background: colorConfigs?.sidebar?.ligthBg,
                                            fontWeight: 700,
                                            fontSize: 12,
                                            p: 1,
                                            borderRadius: 25,
                                            px: 2,
                                            cursor: 'pointer',
                                            ml: 1
                                        }}
                                        onClick={markAllAsRead}
                                    >
                                        Mark all as read
                                    </Typography>
                                    <IconButton onClick={handleClick} edge="end" color="default">
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                            </Box>

                            {notificationsData.map((n, index) => (
                                n.object_type !== 'outcoming_money' && n.object_type !== 'outcoming_money' ? (
                                    <div key={n.id + index}>
                                        <NotificationCard markAsRead={markAsRead} onClose={handleClick} notification={n} />
                                    </div>
                                ) : null
                            ))}

                            {loading && (
                                <div style={{ display: "flex", justifyContent: "center", padding: "20px" }}>
                                    <Spin indicator={antIcon} />
                                </div>
                            )}

                            {!loading && notificationsData.length === 0 && (
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        padding: "18px 0",
                                    }}
                                >
                                    <img
                                        loading={"lazy"}
                                        src={
                                            "https://ik.imagekit.io/wffo0jsx3/syde-dev/o/images%2Fnotifications_empty.png?alt=media&token=80e261c6-4034-46bb-bf8d-739301ae029d"
                                        }
                                        alt={"You're all caught up!"}
                                        width={100}
                                    />
                                    <div style={{ height: "16px" }} />
                                    <Typography style={{ color: "black" }} variant={"caption"}>
                                        You're all caught up!
                                    </Typography>
                                </div>
                            )}

                            {!loading && !hasMore && notificationsData.length > 0 && (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        padding: "16px 0",
                                        color: "gray",
                                    }}
                                >
                                    <Typography variant={"caption"}>No more notifications</Typography>
                                </div>
                            )}
                        </Box>
                    </div>
                </Fade>
            )}
        </Box>
    );
};

export default NotificationsList;
