const distanceMeterToUnit = (distance, unit = true) => {
    const env = process.env.REACT_APP_ENV;
    let distanceInMeters;
    let unitChar=''
    if (env === "production" || env === "development") {
        // Convert miles to meters
        unitChar='Mile'
        distanceInMeters = distance / 1609.34;
    } else if (env === "women_first") {
        // Convert kilometers to meters
        distanceInMeters = distance / 1000;
        unitChar='KM'
    } else {
        throw new Error(`Unknown environment: ${env}`);
    }

    return `${distanceInMeters.toFixed(2)}${unit ?  ` `+ unitChar : ''}`;
}

export default distanceMeterToUnit;
