import { Delete as DeleteIcon } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Popper,
  Select,
  Snackbar,
  TextField,
  Typography
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Loading from "react-fullscreen-loading";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import deletee from "../../assets/images/delete.png";
import colorConfigs from "../../configs/colorConfigs";
import {
  setRides,
  setRidesList,
  setRidesListHistory,
  setScheduledRidesList
} from "../../redux/features/appStateSlice";
import DynamicTable from "../table/table";
import Error5xx from "../../components/common/HandelError5xx";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { formatAddress } from "../../utils/localization/addressUtils";
import { formatCurrency } from "../../utils/localization/currencyUtils";
import { formatDate } from "../../utils/localization/dateTimeUtils";
import { DeleteMethod, getMethod } from "../Hook/UseCustomApi";
import Status from "../status/status";
import AddNewRide from "./AddRide/AddNewRide";
import SecurityAlertSmall from '../../assets/svg/SecurityAlertSmall';


export default function RidesList() {
  const navigate = useNavigate();
  const ridesList = useSelector((state) => state.appState.ridesList);
  const ridesListHistory = useSelector(
    (state) => state.appState.ridesListhistory
  );
  const ridesScheduledList = useSelector((state) => state.appState.ridesScheduledList);

  const [data, setData] = useState(ridesList);
  const [datahistory, setDataHistory] = useState(ridesListHistory);
  const [dataScheduled, setDataScheduled] = useState(ridesScheduledList);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(1);
  const [countHistory, setCountHistory] = useState(1);
  const [countDataScheduled, setCountDataScheduled] = useState(1);
  const [page, setPage] = useState(1);
  const [activeButton, setActiveButton] = useState("activeRides");
  const [status, setStatus] = useState("all");
  const [keyword, setKeyword] = useState("");
  const countryCode = useSelector((state) => state.country.selectedCountry);
  const rowsLimits = [5, 10, 25, 100];
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [scheduleRides, setScheduleRides] = useState(ridesScheduledList);

  const licAndConfig = useSelector((state) => state.licencesAndConfiguration);
  // console.log(licAndConfig);




  //handle error5xx
  const [tryAgain, setTryAgain] = useState();
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [errorCount, setErrorCount] = useState(0);
  const [errorDialogCounter, setErrorDialogCounter] = useState(0);
  const handleError5xx = () => {
    setIsErrorDialogOpen(true);
    setErrorCount(prevCount => {
      const newCount = prevCount + 1;
      if (newCount === 1) {
        setErrorDialogCounter(5);
      } else if (newCount === 2) {
        setErrorDialogCounter(10);
      }
      return newCount;
    });
  };
  const handleCloseErrorDialog = () => {
    setIsErrorDialogOpen(false);
    if (errorCount <= 2) {
      handleErrorCountByClose()
    }

  };
  const handleErrorCountByClose = () => {
    setErrorCount(prevCount => {
      const newCount = prevCount - 1;
      if (newCount === 1) {
        setErrorDialogCounter(5);
      } else if (newCount === 2) {
        setErrorDialogCounter(10);
      }
      return newCount;
    });
  }

  useEffect(() => {
    window.scrollTo({ top: 0 });
    currentPage.current = 1;
    currentLimit.current = 10;
    currentKeyword.current = "";
    currentStatus.current = "all";
    fetchDrivers();
  }, [activeButton]);
  useEffect(() => {
    window.scrollTo({ top: 0 });
    dispatch(setRides("activeRides"));
  }, []);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const dispatch = useDispatch();
  let currentPage = useRef(1);
  let currentLimit = useRef(10);
  let currentKeyword = useRef("");
  let currentStatus = useRef("all");
  const [deleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] =
    useState(false);
  const [adminToDelete, setAdminToDelete] = useState(null);

  const handleButtonClick = (button) => {

    setActiveButton(button);
    dispatch(setRides(button));
    setData(ridesList);
    setDataHistory(ridesListHistory);
    setDataScheduled(ridesScheduledList);
    // Reset filters
    setStatus("all");
    setKeyword("");

    clearData();
  };


  const handleDeleteClick = (admin) => {
    setAdminToDelete(admin);
    setDeleteConfirmationDialogOpen(true);
  };

  const clearData = () => {
    currentPage.current = 1;
    currentLimit.current = 10;
    currentKeyword.current = "";
    currentStatus.current = "all";
    setPage(1);
  };

  const getParams = () => {
    return {
      page: currentPage.current,
      history: activeButton === "activeRides" ? false : true,
      limit: currentLimit.current,
      status: currentStatus.current,
      "search_driver_info.first_name": currentKeyword.current,
      "search_driver_info.last_name": currentKeyword.current,
      "search_customer_details.first_name": currentKeyword.current,
      "search_customer_details.last_name": currentKeyword.current,
    }
  };

  const getScheduledParams = () => {
    const params = {
      page: currentPage.current,
      limit: currentLimit.current,
      "search_driver_info.first_name": currentKeyword.current,
      "search_driver_info.last_name": currentKeyword.current,
      "search_customer_details.first_name": currentKeyword.current,
      "search_customer_details.last_name": currentKeyword.current,
      status: "scheduled"
    };
    return params;
  };

  const checkRides = (data) => {
    if (activeButton && activeButton === "activeRides") {
      setData(data);
    } else if (activeButton && activeButton === "history") {
      setDataHistory(data);
    }
  };

  const fetchDrivers = async () => {
    if (
      (activeButton === "activeRides" && ridesList?.length === 0) ||
      (activeButton === "history" && ridesListHistory?.length === 0) ||
      (activeButton === "scheduled" && ridesScheduledList?.length === 0)
    ) {
      setLoading(true);
    }
    getMethod({
      url: "ride",
      params: getParams(),
      handleErrorMessage: showSnackbar,
    })
      .then((response) => {
        console.log(response);

        checkRides(response?.data);
        if (activeButton === "activeRides") {
          dispatch(setRidesList(response?.data));
        } else if (activeButton === "history") {
          dispatch(setRidesListHistory(response?.data));
        }
        setCount(response?.count);
        setCountHistory(response?.count);
      })
      .catch((err) => {
        console.error("Error fetching more stores:", err)
        if (err === "Internal Server Error: Server error occurred.") {
          handleError5xx();
          setTryAgain(() => fetchDrivers);
        }
      })
      .finally(() => setLoading(false));

    getMethod({
      url: "ride",
      params: getScheduledParams(),
      handleErrorMessage: showSnackbar,
    })
      .then((response) => {
        // console.log(response.data);
        dispatch(setScheduledRidesList(response?.data));
        setDataScheduled(response?.data);
        setCountDataScheduled(response?.count);
      })
      .catch((err) => {
        console.error("Error fetching more stores:", err)
        if (err === "Internal Server Error: Server error occurred.") {
          handleError5xx();
          setTryAgain(() => fetchDrivers);
        }
      })
      .finally(() => setLoading(false));
  };


  const handleDeleteConfirmation = async (confirmed) => {
    setDeleteConfirmationDialogOpen(false);
    if (confirmed) {
      try {
        DeleteMethod({
          url: `ride/${adminToDelete?._id}`,
          handleErrorMessage: showSnackbar,
        }).then((res) => {
          console.log(res, "res");
        });

        if (activeButton === "activeRides") {
          setData((prevData) =>
            prevData?.filter((item) => item._id !== adminToDelete?._id)
          );
        } else if (activeButton === "history") {
          setDataHistory((prevData) =>
            prevData?.filter((item) => item._id !== adminToDelete?._id)
          );
        } else if (activeButton === "scheduled") {
          setDataScheduled((prevData) =>
            prevData?.filter((item) => item._id !== adminToDelete?._id)
          );
        }

        console.log("Rides deleted successfully!");
      } catch (error) {
        console.error("Error deleting ride:", error);
        if (error === "Internal Server Error: Server error occurred.") {
          handleError5xx();
          setTryAgain(() => () => handleDeleteConfirmation(confirmed));
        }
      }
      fetchDrivers()
      setLoading(false);
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    currentPage.current = newPage;
    fetchDrivers();
  };

  const handleRowsChange = (rows) => {
    currentPage.current = 1;
    currentLimit.current = rows;
    setPage(1);
    fetchDrivers();
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
    setPage(1);
    currentStatus.current = e.target.value;

    currentPage.current = 1;
    fetchDrivers();
  };

  const handleSearch = (e) => {
    setKeyword(e.target.value);
    setPage(1);
    currentPage.current = 1;
    currentKeyword.current = e.target.value;

    fetchDrivers();
  };

  const style = {
    color: "#696969",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "24px" /* 150% */,
    letterSpacing: "0.17px",
  };

  const capitalizeFirstLetter = (str) => {
    return str?.charAt(0)?.toUpperCase() + str?.slice(1);
  };


  let columns;

  if (activeButton === "scheduled") {
    columns = [
      {
        field: "driver_info",
        headerName: "Driver Name",
        flex: 1,
        minWidth: "140px",
        editable: true,
        style: style,
        align: "left",
        headerClassName: "super-app-theme--header",
        renderCell: (params) => (
          <span>
            {params?.row?.driver_info?.first_name === "" ||
              params?.row?.driver_info?.first_name === undefined ? (
              <span>Not Assigned</span>
            ) : (
              <>
                {capitalizeFirstLetter(params?.row?.driver_info?.first_name)}{" "}
                {capitalizeFirstLetter(params?.row?.driver_info?.last_name)}{" "}
              </>
            )}
          </span>
        ),
      }

      ,
      {
        field: "rider_full_name",
        headerName: "Passenger Name",
        flex: 1,
        minWidth: "170px",
        editable: true,
        style: style,
        align: "left",
        headerClassName: "super-app-theme--header",
        renderCell: (params) => (
          <span>
            {params?.row?.customer_details?.first_name === "" ||
              params?.row?.customer_details?.first_name === undefined ? (
              <span>Not Assigned</span>
            ) : (
              <>
                {capitalizeFirstLetter(params?.row?.customer_details?.first_name)}{" "}
                {capitalizeFirstLetter(params?.row?.customer_details?.last_name)}{" "}
              </>
            )}
          </span>
        ),
      },
      {
        field: "ride_date",
        headerName: "Ride Date",
        flex: 1,
        minWidth: "150px",
        editable: true,
        style: style,
        align: "left",
        headerClassName: "super-app-theme--header",
        renderCell: (params) => {
          const dateObject = new Date(params?.row?.scheduled_at);
          const formattedDate = formatDate(dateObject, countryCode);
          return (
            <span >
              {formattedDate}
            </span>
          );
        },

      },
      {
        field: "pickup",
        headerName: "Pick Up",
        flex: 1,
        minWidth: "50px",
        editable: true,
        style: style,
        align: "left",
        headerClassName: "super-app-theme--header",
        renderCell: (params) => (
          <Typography>{params?.row?.pickup_address?.address}</Typography>
        ),
      },
      {
        field: "dropoff",
        headerName: "Drop Off",
        flex: 1,
        minWidth: "50px",
        editable: true,
        style: style,
        align: "left",
        headerClassName: "super-app-theme--header",
        renderCell: (params) => (
          <Typography>{params?.row?.delivery_address?.address}</Typography>
        ),
      },
      {
        field: "status",
        headerName: "Status",
        flex: 1,
        minWidth: "30px",
        editable: true,
        style: style,
        align: "left",
        headerClassName: "super-app-theme--header",
        renderCell: (params) => <Status status={params?.row?.status} />,
      },
      {
        field: "fare",
        headerName: "Fare",
        minWidth: "100px",
        flex: 1,
        style: style,
        align: "center",
        headerClassName: "super-app-theme--header",
        renderCell: (params) => <Typography>${params?.row?.pay_info?.pay_cash}</Typography>,
      },
      {
        field: "action",
        headerName: "Action",
        style: style,
        headerClassName: "super-app-theme--header",
        renderCell: (params) => {
          return (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <Box
                  style={{
                    width: "30px",
                    height: "30px",
                    backgroundColor: "#F3F8FD",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => navigate(`/rides/details/${params.row._id}`)}
                >
                  <VisibilityIcon
                    fontSize="small"
                    className="icon"
                    style={{ color: "#5B93FF" }}
                  />
                </Box>
                <IconButton
                  aria-label="edit"
                  onClick={() => handleDeleteClick(params.row)}
                >
                  <div
                    style={{
                      backgroundColor: "#ffd8d8",
                      display: "flex",
                      borderRadius: "22px",
                      padding: "4px",
                    }}
                  >
                    <DeleteIcon
                      fontSize="small"
                      className="icon"
                      style={{ color: "#E71D36" }}
                    />
                  </div>
                </IconButton>
              </div>
            </>
          );
        },
      },
    ];
  } else {
    columns = [
      {
        field: "short_id",
        headerName: "Ride ID",
        flex: 1,
        minWidth: 85,
        editable: true,
        align: "left",
        headerClassName: "super-app-theme--header",
        renderCell: (params) => (
          <span
            style={{ textDecoration: "underline", fontWeight: "bold", cursor: "pointer" }}
            onClick={() => navigate(`/rides/details/${params?.row?._id}`)}
          >
            {params?.row?.short_id}
          </span>
        ),
      },
      {
        field: "driver_info",
        headerName: "Driver Name",
        flex: 1,
        minWidth: "200px",
        editable: true,
        style: style,
        align: "left",
        headerClassName: "super-app-theme--header",
        renderCell: (params) => (
          <span>
            {params?.row?.driver_info?.first_name === "" ||
              params?.row?.driver_info?.first_name === undefined ? (
              <span>Not Assigned</span>
            ) : (
              <span style={{
                display: "flex",
                alignItems: "center",
              }}>
                {capitalizeFirstLetter(params?.row?.driver_info?.first_name)}{" "}
                {capitalizeFirstLetter(params?.row?.driver_info?.last_name)}{" "}
                {params?.row?.panic_button_driver && (<SecurityAlertSmall style={{ marginLeft: "5px" }} />)}
              </span>
            )}
          </span>
        ),
      },
      {
        field: "rider_name",
        headerName: "Passenger Name",
        flex: 1,
        minWidth: "200px",
        style: style,
        align: "left",
        headerClassName: "super-app-theme--header",
        renderCell: (params) => (
          <span span style={{
            display: "flex",
            alignItems: "center",
          }}>
            {capitalizeFirstLetter(params.row.customer_details?.first_name)}{" "}
            {capitalizeFirstLetter(params.row.customer_details?.last_name)}
            {params?.row?.panic_button_customer && (<SecurityAlertSmall style={{ marginLeft: "5px" }} />)}
          </span>
        ),
      },
      {
        field: "book_date",
        headerName: "Book Date",
        minWidth: "200px",
        flex: 1,
        style: style,
        align: "left",
        headerClassName: "super-app-theme--header",
        renderCell: (params) => {
          const dateObject = new Date(params?.row?.created_at);
          const formattedDate = formatDate(dateObject, countryCode);
          return (
            <span>
              {formattedDate}
            </span>
          );
        },
      },
      {
        field: "pickup",
        headerName: "Pickup",
        headerClassName: "super-app-theme--header",
        minWidth: "160px",
        width: 350,
        maxWidth: "350px",
        renderCell: (params) => {
          const address = formatAddress(params.row.pickup_address, countryCode);
          return (
            <span>
              {capitalizeFirstLetter(address)}
            </span>
          );
        },
      },
      {
        field: "delivery",
        headerName: "Delivery",
        headerClassName: "super-app-theme--header",
        minWidth: "160px",

        renderCell: (params) => {
          const address = formatAddress(params.row.delivery_address, countryCode);
          return (
            <span>
              {capitalizeFirstLetter(address)}
            </span>
          );
        },
      },
      {
        field: "status",
        headerName: "Status",
        headerClassName: "super-app-theme--header",
        width: 350,
        minWidth: "130px",
        renderCell: (params) => <Status status={params.row.status} />,
      },
      {
        field: "fare",
        headerName: "Fare",
        flex: 1,
        minWidth: "20px",
        editable: true,
        style: style,
        align: "left",
        headerClassName: "super-app-theme--header",
        renderCell: (params) => (
          <Typography display={"flex"}>
            {formatCurrency(
              params?.row?.pay_info?.total ? params?.row?.pay_info?.total : params?.row?.estimation_cost,

              countryCode
            )}
          </Typography>
        ),
      },
      {
        field: "action",
        headerName: "Action",
        style: style,
        headerClassName: "super-app-theme--header",
        renderCell: (params) => {
          return (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <Box
                  style={{
                    width: "30px",
                    height: "30px",
                    backgroundColor: "#F3F8FD",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => navigate(`/rides/details/${params.row._id}`)}
                >
                  <VisibilityIcon
                    fontSize="small"
                    className="icon"
                    style={{ color: "#5B93FF" }}
                  />
                </Box>
                {/* <IconButton
                  aria-label="edit"
                  onClick={() => handleDeleteClick(params.row)}
                >
                  <div
                    style={{
                      backgroundColor: "#ffd8d8",
                      display: "flex",
                      borderRadius: "22px",
                      padding: "4px",
                    }}
                  >
                    <DeleteIcon
                      fontSize="small"
                      className="icon"
                      style={{ color: "#E71D36" }}
                    />
                  </div>
                </IconButton> */}
              </div>
            </>
          );
        },
      },
    ];
  }

  const CustomPopper = (props) => {
    return (
      <Popper
        {...props}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 10], // Adjust this as needed
            },
          },
          {
            name: 'preventOverflow',
            options: {
              boundary: 'viewport',
            },
          },
        ]}
      />
    );
  };


  return (
    <>
      {/* handleError5xx */}
      <Error5xx open={isErrorDialogOpen} errCount={errorCount} count={errorDialogCounter} handleClose={handleCloseErrorDialog} tryAgain={tryAgain} />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6" sx={{ mt: 1 }}>
          Rides
        </Typography>
        <AddNewRide fetchDriverss={fetchDrivers} />

      </Box>
      <Dialog
        style={{ zIndex: 10, borderRadius: "30px" }}
        open={deleteConfirmationDialogOpen}
        onClose={() => setDeleteConfirmationDialogOpen(false)}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent style={{ padding: "30px" }}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img src={deletee} alt="" />
          </div>
          <DialogContentText
            id="alert-dialog-slide-description"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Typography
              variant="h6"
              style={{
                fontWeight: "bold",
                color: "black",
                marginTop: "10px",
                marginBottom: "8px",
              }}
            >
              Delete Ride
            </Typography>
          </DialogContentText>

          <Typography>Are you sure you want to delete this ride?</Typography>
        </DialogContent>

        <DialogActions
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            marginBottom: "20px",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
        >
          <Button
            variant="outlined"
            style={{
              color: loading ? "gray" : "black",
              borderColor: "lightgray",
              width: "100%",
            }}
            onClick={() => handleDeleteConfirmation(false)}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            style={{
              background: loading ? "gray" : "var(--Error, #FD4343)",
              color: "white",
              width: "100%",
            }}
            onClick={() => handleDeleteConfirmation(true)}
            disabled={loading}
          >
            {loading ? "...Loading" : "Yes, Delete"}
          </Button>
        </DialogActions>
      </Dialog>

      <div>
        <Grid
          container
          spacing={1}
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: "10px",
            paddingTop: "18px",
          }}
        >
          <Grid
            item
            xs={12} // Full width on extra small screens
            sm={6} // Full width on small screens
            md={12} // 3 columns on medium screens
            lg={5} // 3 columns on large screens
            xl={4} // 3 columns on extra large screens
            style={{
              border: `1.5px solid ${colorConfigs?.sidebar?.bg}`,
              width: "38vw",
              height: "53px",
              borderRadius: "8px",
              marginTop: "2px",
              display: "flex",
              justifyContent: "center",
            }}
            sx={{
              marginBottom: { lg: "0", xs: "20px", sm: "20px" },
            }}
          >
            <Grid item xs={8}>
              <Button
                variant={activeButton === "activeRides" ? "contained" : "text"}
                style={{
                  color:
                    activeButton === "activeRides"
                      ? "white"
                      : colorConfigs?.sidebar?.bg,
                  backgroundColor:
                    activeButton === "activeRides"
                      ? colorConfigs?.sidebar?.bg
                      : "transparent",
                  fontWeight:
                    activeButton === "activeRides" ? "bold" : "normal",
                }}
                onClick={() => handleButtonClick("activeRides")}
              >
                Active rides
              </Button>
            </Grid>

            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <Button
                variant={activeButton === "history" ? "contained" : "text"}
                style={{
                  color:
                    activeButton === "history"
                      ? "white"
                      : colorConfigs?.sidebar?.bg,
                  backgroundColor:
                    activeButton === "history"
                      ? colorConfigs?.sidebar?.bg
                      : "transparent",
                  fontWeight: activeButton === "history" ? "bold" : "normal",
                }}
                onClick={() => handleButtonClick("history")}
              >
                History
              </Button>
            </Grid>
            {licAndConfig?.licenses?.scheduled_ride && (
              <Grid
                item
                xs={7}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <Button
                  variant={
                    activeButton === "scheduled" ? "contained" : "text"
                  }
                  style={{
                    color:
                      activeButton === "scheduled"
                        ? "white"
                        : colorConfigs?.sidebar?.bg,
                    backgroundColor:
                      activeButton === "scheduled"
                        ? colorConfigs?.sidebar?.bg
                        : "transparent",
                    fontWeight:
                      activeButton === "scheduled" ? "bold" : "normal",

                  }}
                  onClick={() => handleButtonClick("scheduled")}
                >
                  Scheduled
                </Button>
              </Grid>
            )}




          </Grid>

          <Grid
            item
            xs={12} // Full width on extra small screens
            sm={12} // Full width on small screens
            md={12} // 9 columns on medium screens
            lg={7} // 8 columns on large screens
            xl={8} // 8 columns on extra large screens
            style={{
              display: "flex",
              justifyContent: "end",
              gap: "14px",
              marginBottom: "14px",
              width: "55%",
            }}
          >
            <Grid item xs={4} sm={4} md={3} lg={3} xl={3}>
              <Box sx={{ minWidth: 120 }}>
                {activeButton === "scheduled" ? (
                  ("")) : (
                  <FormControl fullWidth style={{ marginTop: "2px" }} sx={{
                    "& label.Mui-focused": {
                      color: "rgba(0, 0, 0, 0.6)",
                      bordercolor: "#BBC1CE",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#BBC1CE",
                      },
                      "&:hover fieldset": {
                        borderColor: "#BBC1CE",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#BBC1CE",
                        borderWidth: "1px",
                      },
                    },
                  }}>
                    <InputLabel id="demo-simple-select-label">Status</InputLabel>

                    {activeButton === "activeRides" ? (
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={status}
                        label="Status"
                        onChange={handleStatusChange}
                        style={{ borderRadius: "10px", height: "50px" }}
                        MenuProps={{
                          disableScrollLock: true,
                        }}
                      >
                        <MenuItem value={"all"}>All</MenuItem>
                        <MenuItem value={"pending"}>Pending</MenuItem>
                        {licAndConfig?.licenses?.scheduled_ride && (<MenuItem value={"scheduled"}>Scheduled</MenuItem>)}
                        <MenuItem value={"enroute_to_customer"}>On The Way</MenuItem>
                        <MenuItem value={"waiting_delivery"}>Waiting</MenuItem>
                      </Select>

                    ) : (
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={status}
                        label="Status"
                        onChange={handleStatusChange}
                        style={{ borderRadius: "10px", height: "50px" }}
                        MenuProps={{
                          disableScrollLock: true,
                        }}
                      >
                        <MenuItem value={"all"}>All</MenuItem>
                        <MenuItem value={"delivered"}>Delivered</MenuItem>
                        <MenuItem value={"canceled"}>Cancelled</MenuItem>
                      </Select>
                    )}
                  </FormControl>
                )}

              </Box>
            </Grid>
            <Grid item xs={8} sm={8} md={6} lg={7} xl={6}>
              <TextField
                fullWidth
                label="Search by"
                placeholder="Driver or Passenger Name"
                name="keyword"
                value={keyword}
                onChange={handleSearch}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{ color: "gray" }} />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                sx={{
                  "& label.Mui-focused": { color: "rgba(0, 0, 0, 0.6)" },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#A0AEC0",
                    },
                    borderRadius: "10px",
                    height: "52px",
                  },
                }}
              />
            </Grid>

          </Grid>
        </Grid>
      </div>

      {activeButton === "history" && (
        <DynamicTable
          columns={columns}
          data={datahistory}
          count={count}
          page={page}
          rowsLimits={rowsLimits}
          pageChange={handlePageChange}
          rowsChange={handleRowsChange}
          pageType="rides"
        />
      )}
      {activeButton === "activeRides" && (
        <DynamicTable
          columns={columns}
          data={data}
          count={countHistory}
          page={page}
          rowsLimits={rowsLimits}
          pageChange={handlePageChange}
          rowsChange={handleRowsChange}
          pageType="rides"
        />
      )}
      {activeButton === "scheduled" && (
        <DynamicTable
          columns={columns}
          data={dataScheduled}
          count={countDataScheduled}
          page={page}
          rowsLimits={rowsLimits}
          pageChange={handlePageChange}
          rowsChange={handleRowsChange}
          pageType="scheduled"
        />
      )}
      <Loading
        loading={!!loading}
        background="rgba(86, 100, 210, 0.1)"
        loaderColor={colorConfigs.sidebar?.bg}
      />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleSnackbarClose} severity="error">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
