import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Typography,
} from "@mui/material";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import React, { useEffect, useState } from "react";
import colorConfigs from "../../configs/colorConfigs"


const Error5xx = ({ open, errCount, count, tryAgain, handleClose }) => {


    const [isOpen, setIsOpen] = useState(false);
    const [errorCount, setErrorCount] = useState(0);
    const [counter, setCounter] = useState(0);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    useEffect(() => {
        setIsOpen(open);
        setErrorCount(errCount);
        setCounter(count);
    }, [open, errCount, count]);


    useEffect(() => {

        let timer;
        if (counter > 0) {
            setIsButtonDisabled(true);
            timer = setTimeout(() => {
                setCounter(prevCounter => {
                    const updatedCounter = prevCounter - 1;
                    if (updatedCounter <= 0) {
                        setIsButtonDisabled(false);
                    }
                    return updatedCounter;
                });
            }, 1000);
        } else {
            setIsButtonDisabled(false);
        }
        return () => clearTimeout(timer);
    }, [counter]);

    const handleTryAgain = () => {
        setIsOpen(false);
        setCounter(0);
        if (errorCount === 1) {
            setCounter(10);
        }
        tryAgain()
    };

    const getMessage = () => {
        if (errorCount === 1) {
            return "Something went wrong";
        }
        else if (errorCount === 2) {
            return "Thank you for your patience.  ";
        }
        else if (errorCount > 2) {
            return "Server error. Try again later.";
        }
        return "";
    };

    return (
        <Dialog
            style={{ zIndex: 10, borderRadius: "30px", }}
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"

        >
            <DialogContent style={{ padding: "30px" }}>

                <DialogContentText
                    id="alert-dialog-slide-description"
                    style={{ display: "flex", justifyContent: "center" }}
                >
                    <ErrorOutlineIcon style={{ fontSize: 40, color: 'red', margin: " auto 0" }} />
                    <Typography
                        variant="h6"
                        style={{
                            fontWeight: "bold",
                            color: "black",
                            marginTop: "10px",
                            marginBottom: "8px",
                        }}
                    >
                        Ooops !!
                    </Typography>
                </DialogContentText>
                <Typography style={{ display: "flex", justifyContent: "center" }}></Typography>
                {errorCount > 0 && (
                    <Typography style={{ display: "flex", alignItems: "center", justifyContent: "center", }} id="alert-dialog-description">
                        {getMessage()}
                    </Typography>
                )}
                {errorCount === 2 && (<Typography style={{ display: "flex", justifyContent: "center" }}>We are trying to solve the problem.</Typography>)}
                {errorCount <= 2 && (<Typography>please wait for the count down then try again</Typography>)}
            </DialogContent>
            <DialogActions
                style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    marginBottom: "20px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    width: "450px"
                }}>
                {errorCount <= 2 && (
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: process.env.REACT_APP_ENV === 'women_first' ? colorConfigs?.sidebar?.bg : '#005e8b',
                            color: 'white',
                            borderRadius: '10px',
                            width: "100%",
                            padding: '10px 20px',
                            '&:hover': {
                                backgroundColor: process.env.REACT_APP_ENV === 'women_first' ? colorConfigs?.sidebar?.bg : '#004b6e',
                            },
                        }}
                        onClick={handleTryAgain} disabled={isButtonDisabled} >
                        Try again ({counter > 0 ? counter : 'Ready'})
                    </Button>
                )}
                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: 'white',
                        color: colorConfigs?.sidebar?.bg,
                        borderRadius: '10px',
                        width: "100%",
                        padding: '10px 20px',
                        '&:hover': {
                            backgroundColor: '#D3D3D3',
                        },
                    }}
                    onClick={handleClose} >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default Error5xx;