import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Snackbar,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Switch } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import colorConfigs from "../../../configs/colorConfigs";
import { auth } from "../../../configs/firebase";
import {
  setRadius,
  updateFormData,
} from "../../../redux/features/appStateSlice";
import { putMethod } from "../../Hook/UseCustomApi";
import "./../index.css";
import usePrompt from "../../Hook/usePrompt";
import Error5xx from "../../../components/common/HandelError5xx";

const useStyles = makeStyles({
  rootBox: {
    fontFamily: "inter",
    fontSize: "small",
    marginTop: "10px",
    borderRadius: "8px",
    padding: "6px",
    width: "100%",
    marginBottom: "20px",
    color: colorConfigs?.sidebar?.bg,
    backgroundColor: "#F4F4F4",
  },
  flexContainer: {
    display: "flex",
    gap: "20px",
    fontSize: "16px",
    color: "var(--Dark, #172b4d)",
    fontWeight: 600,
    lineHeight: "140%",
    flexDirection: "row",
  },
  categoryBox: {
    borderRadius: "8px",
    backgroundColor: process.env.REACT_APP_ENV === 'women_first' ? '#F9F6FF' : "#F1FAFF",
    display: "flex",
    flexDirection: "row",
    padding: "12px",
    gap: "10px",
    marginTop: "10px",
  },
  buttonStyle: {
    backgroundColor: colorConfigs?.sidebar?.bg,
  },
  checkbox: {
    color: colorConfigs?.sidebar.bg,
    "&.Mui-checked": {
      color: colorConfigs?.sidebar.bg,
    },
  },
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  radioBox: {
    border: "1px solid lightgray",
    borderRadius: "8px",
    paddingLeft: "10px",
    marginTop: "10px",
    padding: "2px",
  },
  smallLabel: {
    fontSize: "0.875rem", // Adjust the font size as needed
  },
  customRadio: {
    color: colorConfigs?.sidebar?.bg,
    "&.Mui-checked": {
      color: colorConfigs?.sidebar?.bg,
    },
  },
  paymentMethodsBox: {
    borderRadius: "8px",
    backgroundColor: process.env.REACT_APP_ENV === 'women_first' ? '#F9F6FF' : "#F1FAFF",
    padding: "12px",
    marginTop: "20px",
  },
});

const EditPayment = ({ prev, next }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.appState.formDataDoc);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [tipping, setTipping] = useState(formData?.tipping);
  const [extra_cost_from_driver, setExtra_cost_from_driver] = useState(
    formData?.extra_cost_from_driver
  );
  const [total_charge_display, setTotal_charge_display] = useState(
    formData?.total_charge_display
  );
  const [post_pay, setPost_pay] = useState(formData?.post_pay);
  const [cash, setCash] = useState(formData?.cash);
  const [digital_payment, setDigital_payment] = useState(
    formData?.digital_payment
  );
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("error");
  useEffect(() => {
    setTipping(formData?.tipping);
    setExtra_cost_from_driver(formData?.extra_cost_from_driver);
    setTotal_charge_display(formData?.total_charge_display);
    setPost_pay(formData?.post_pay);
    setCash(formData?.cash);
    setDigital_payment(formData?.digital_payment);
  }, [formData]);
  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  //handle error5xx
  const [tryAgain, setTryAgain] = useState();
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [errorCount, setErrorCount] = useState(0);
  const [errorDialogCounter, setErrorDialogCounter] = useState(0);
  const handleError5xx = () => {
    setIsErrorDialogOpen(true);
    setErrorCount(prevCount => {
      const newCount = prevCount + 1;
      if (newCount === 1) {
        setErrorDialogCounter(5);
      } else if (newCount === 2) {
        setErrorDialogCounter(10);
      }
      return newCount;
    });
  };
  const handleCloseErrorDialog = () => {
    setIsErrorDialogOpen(false);
    if (errorCount <= 2) {
      handleErrorCountByClose()
    }

  };
  const handleErrorCountByClose = () => {
    setErrorCount(prevCount => {
      const newCount = prevCount - 1;
      if (newCount === 1) {
        setErrorDialogCounter(5);
      } else if (newCount === 2) {
        setErrorDialogCounter(10);
      }
      return newCount;
    });
  }


  const handleSubmit = async () => {
    if (auth && auth.currentUser) {
      const tokenId = await auth?.currentUser
        ?.getIdToken()
        .then((token) => token);
      const headers = {
        "Content-Type": "application/json",
        Authorization: tokenId,
      };
      dispatch(
        updateFormData({
          ...formData,
          tipping: tipping,
          extra_cost_from_driver: extra_cost_from_driver,
          total_charge_display: total_charge_display,
          post_pay: post_pay,
          cash: cash,
          digital_payment: digital_payment,
        })
      );

      setLoading(true);
      try {
        // Make the API request to edit a new admin
        putMethod({
          url: `zone/${formData?._id}`,
          body: {
            ...formData,
            tipping: tipping,
            extra_cost_from_driver: extra_cost_from_driver,
            total_charge_display: total_charge_display,
            post_pay: post_pay,
            cash: cash,
            digital_payment: digital_payment,
          },
          headers: headers,
          handleErrorMessage: showSnackbar,
        }).then((res) => {
          console.log({
            ...formData,
            tipping: tipping,
            extra_cost_from_driver: extra_cost_from_driver,
            total_charge_display: total_charge_display,
            post_pay: post_pay,
            cash: cash,
            digital_payment: digital_payment,
          })
          setLoading(false);
          setSeverity("success")
          showSnackbar("zone updated successfully");
          navigate("/rates", { replace: true });
          dispatch(updateFormData({}));
          dispatch(setRadius(0));
        });
        // Fetch the updated list of admins
      } catch (error) {
        console.error("Error updating zone:", error);
        setSeverity("error");
        showSnackbar("Failed to update zone");
        setLoading(false);
        if (error === "Internal Server Error: Server error occurred.") {
          handleError5xx();
          setTryAgain(() => handleSubmit);
        }
      }
    }
    // Include the additional fees in the form data
  };

  return (
    <>
      {/* handleError5xx */}
      <Error5xx open={isErrorDialogOpen} errCount={errorCount} count={errorDialogCounter} handleClose={handleCloseErrorDialog} tryAgain={tryAgain} />
      <Box className={classes.rootBox}>
        <Typography variant="body1" style={{ fontWeight: "bold" }}>
          Payment details
        </Typography>
      </Box>
      <Box className={classes.paymentMethodsBox}>
        <Typography variant="h6" style={{ fontWeight: "bold" }}>
          Select Payment Methods
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={post_pay}
              onChange={() => setPost_pay(!post_pay)}
              style={{ color: colorConfigs?.sidebar?.bg }}
            />
          }
          label="Post Pay"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={cash}
              onChange={() => setCash(!cash)}
              style={{ color: colorConfigs?.sidebar?.bg }}
            />
          }
          label="Cash"
        />
        <br />
        <FormControlLabel
          control={
            <Checkbox
              checked={digital_payment}
              onChange={() => setDigital_payment(!digital_payment)}
              style={{ color: colorConfigs?.sidebar?.bg }}
            />
          }
          label="Digital Payment"
        />
      </Box>
      <Grid container spacing={2}>
        <Grid item md={4} xs={12}>
          <Box className={classes.categoryBox}>
            <div style={{ display: "flex" }}>
              <Switch
                checked={total_charge_display}
                onChange={() => setTotal_charge_display(!total_charge_display)}
                className="custom-switch"
              />
              <span style={{ marginLeft: 8 }}>Total Charge Display</span>
            </div>
          </Box>
        </Grid>

        <Grid item md={4} xs={12}>
          <Box className={classes.categoryBox}>
            <div style={{ display: "flex" }}>
              <Switch
                checked={extra_cost_from_driver}
                onChange={() =>
                  setExtra_cost_from_driver(!extra_cost_from_driver)
                }
                className="custom-switch"
              />
              <span style={{ marginLeft: 8 }}>
                Allow Driver to Add Extra Cost
              </span>
            </div>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={4} xs={12}>
          <Box className={classes.categoryBox}>
            <div style={{ display: "flex" }}>
              <Switch
                checked={tipping}
                onChange={() => setTipping(!tipping)}
                className="custom-switch"
              />
              <span style={{ marginLeft: 8 }}>Allow Customer Tipping</span>
            </div>
          </Box>
        </Grid>
      </Grid>

      <div style={{ marginTop: 50, gap: 8 }}>
        <Button
          onClick={prev}
          variant="outlined"
          style={{
            marginRight: 8,
            color: colorConfigs?.sidebar?.bg,
            borderColor: colorConfigs?.sidebar?.bg,
            backgroundColor: "white",
            width: "12%",
          }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          loading={loading}
          style={{
            backgroundColor: colorConfigs?.sidebar?.bg,
            color: "white",
            width: "12%",
          }}
        >
          {loading ? "Loading..." : "Save"}
        </Button>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}>
        <Alert onClose={handleSnackbarClose} severity={severity} >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default EditPayment;
