import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  Button,
  Card, CardContent, CardMedia,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
  TextField,
  Typography,
  useTheme, Avatar
} from "@mui/material";
import { LuUpload } from "react-icons/lu";
import ImageGallery from 'react-image-gallery';

import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import Loading from "react-fullscreen-loading";
import Zoom from 'react-medium-image-zoom'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import React, { useEffect, useState } from "react";
import { getMethod } from "../Hook/UseCustomApi";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { border, display, height, Stack } from "@mui/system";
import { TextInput } from "@zetaton-dev/zetaton-components-bank";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { putMethod } from "../Hook/UseCustomApi";
import Error5xx from "../../components/common/HandelError5xx";
import colorConfigs from "../../configs/colorConfigs";
import "./index.css";
import { NewFormatPhoneNumber } from "../../utils/localization/NewPhoneUtils";
import DriveFileRenameOutlineSharpIcon from "@mui/icons-material/DriveFileRenameOutlineSharp";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Modal } from "antd";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { addYears } from 'date-fns';
import { storageSdk } from "../../configs/firebase";
import { putDataRedux } from "../../redux/features/appStateSlice";
import { UserOutlined } from "@ant-design/icons";
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import { FaIdCard } from "react-icons/fa";

const RequestsDetails = () => {
  const params = useParams();
  const theme = useTheme();
  const [selectedObject, setSelectedObject] = useState(null);
  const openDrawer = useSelector((state) => state.appState.openDrawer);
  const [isOpened, setIsOpened] = useState(openDrawer);
  const [valueTabs, setValueTabs] = useState("one"); // Set a valid initial value
  const [isRejectDialogOpen, setRejectDialogOpen] = useState(false);
  const [rejectItemId, setRejectItemId] = useState(null);
  const [rejectMsg, setRejectMsg] = useState(selectedObject?.reject_msg); // State for managing reject message
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [attemptedSubmit, setAttemptedSubmit] = useState(false);
  const [caTypeList, setCarTypeList] = useState([]);
  const [selectedCarTypes, setSelectedCarTypes] = useState([]);
  const [vehicleType, setVehicleType] = useState("");
  const activeApproveButton = selectedObject?.first_name === '' || !selectedObject?.vehicle_info?.license_plate || selectedObject?.vehicle_info?.license_plate === '' || !selectedObject?.driver_license_info?.license_number || selectedObject?.driver_license_info?.license_number === ''
  const [errors, setErrors] = useState({});
  const [url, setUrl] = useState("");
  const [inputKey, setInputKey] = useState("");
  const dispatch = useDispatch();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [startIndex, setStartIndex] = useState(0);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [driverDocs, setDriverDocs] = useState({})

  const [view, setView] = useState(true);
  const [requestData, setRequestData] = useState({});


  console.log(selectedObject);


  const handleChangeData = (e) => {
    const { name, value } = e.target;
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));

    console.log(name);
    if (name === "license_number" || name === "class") {
      setSelectedObject({
        ...selectedObject,
        driver_license_info: {
          ...selectedObject.driver_license_info,
          [name]: value
        }
      });
    } else if (name === "vehicle_color" || name === "vehicle_make" || name === "vehicle_model" || name === "vehicle_year" || name === "license_plate") {
      setSelectedObject({
        ...selectedObject,
        vehicle_info: {
          ...selectedObject.vehicle_info,
          [name]: value
        }
      });
    } else {
      setSelectedObject({
        ...selectedObject,
        [name]: value,
      });

    }



  };

  const today = new Date();
  const minDate = addYears(today, -65);
  const maxDate = addYears(today, -18);

  const handleChangeDate = (e, name) => {
    const timestamp = e.getTime();

    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));

    if (name === "exp" || name === "iss") {
      setSelectedObject({
        ...selectedObject,
        driver_license_info: {
          ...selectedObject.driver_license_info,
          [name]: timestamp
        }
      });
    } else {
      setSelectedObject({
        ...selectedObject,
        dateOfBirth: timestamp,
      });
    }
  };

  const emailRegex = /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/;

  const showModal = () => {
    const newErrors = {};

    if (!activeApproveButton && valueTabs === 'one') {
      if (!selectedObject?.first_name || selectedObject?.first_name === "") {
        newErrors.first_name = "First Name is Required.";
      }
      if (!selectedObject?.last_name || selectedObject?.last_name === "") {
        newErrors.last_name = "Last Name is Required.";
      }
      if (!selectedObject?.email || selectedObject?.email === "") {
        newErrors.email = "Email is Required.";
      } else if (!emailRegex.test(selectedObject.email)) {
        newErrors.email = "Invalid email address.";
      }
      if (!selectedObject?.dateOfBirth || selectedObject?.dateOfBirth === "") {
        newErrors.dateOfBirth = "Date of Birth is Required.";
      }
    }


    if (!activeApproveButton && valueTabs === 'two') {
      if (!selectedObject?.driver_license_info?.license_number || selectedObject?.driver_license_info?.license_number === "") {
        newErrors.license_number = "license Number is Required.";
      } else if (selectedObject?.driver_license_info?.license_number?.length > 15) {
        newErrors.license_number = "license Number Should be Less Than 15.";
      }
      if (!selectedObject?.driver_license_info?.iss || selectedObject?.driver_license_info?.iss === "") {
        newErrors.iss = "Iss Date is Required.";
      }
      if (!selectedObject?.driver_license_info?.exp || selectedObject?.driver_license_info?.exp === "") {
        newErrors.exp = "Exp Date is Required.";
      } else if (parseInt(selectedObject?.driver_license_info?.exp) <= parseInt(selectedObject?.driver_license_info?.iss)) {
        newErrors.exp = "Expiration date must be later than issuance date.";
      }
    }

    if (!activeApproveButton && valueTabs === 'three') {
      if (!selectedObject?.vehicle_info?.vehicle_images || selectedObject?.vehicle_info?.vehicle_images?.length === 0) {
        newErrors.vehicle_images = "Uploade One Vehicle Image at Least.";
      }
      if (!selectedObject?.vehicle_info?.license_plate || selectedObject?.vehicle_info?.license_plate === "") {
        newErrors.license_plate = "Registration Number is Required.";
      }
      if (!selectedObject?.vehicle_info?.vehicle_color || selectedObject?.vehicle_info?.vehicle_color === "") {
        newErrors.vehicle_color = "Vehicle Color is Required.";
      }
      if (!selectedObject?.vehicle_info?.vehicle_make || selectedObject?.vehicle_info?.vehicle_make === "") {
        newErrors.vehicle_make = "Vehicle Make is Required.";
      }
      if (!selectedObject?.vehicle_info?.vehicle_model || selectedObject?.vehicle_info?.vehicle_model === "") {
        newErrors.vehicle_model = "Vehicle Model is Required.";
      }
      if (!selectedObject?.car_type || selectedObject?.car_type?.length === 0) {
        newErrors.car_type = "Ride Type is Required.";
      }
      if (!selectedObject?.vehicle_info?.vehicle_year || selectedObject?.vehicle_info?.vehicle_year === "") {
        newErrors.vehicle_year = "Vehicle Year is Required.";
      }
      if (!selectedObject?.driver_license_info?.class || selectedObject?.driver_license_info?.exp === "") {
        newErrors.class = "Vehicle Class is Required.";
      }
    }
    if (!activeApproveButton && valueTabs === 'four') {
      if (!selectedObject?.documents || Object.keys(selectedObject?.documents).length === 0) {
        newErrors.docs = "Uploade One Document at Least.";
      } if (!selectedObject?.profile_picture || selectedObject?.profile_picture === "") {
        newErrors.profile_picture = "Profile Picture is Required.";
      } if (!selectedObject?.driver_license_back || selectedObject?.driver_license_back === "") {
        newErrors.driver_license_back = "Driver license Back is Required.";
      } if (!selectedObject?.driver_license_front || selectedObject?.driver_license_front === "") {
        newErrors.driver_license_front = "Driver license Front is Required.";
      }
    }
    setErrors(newErrors);
    console.log(Object.keys(newErrors).length);
    console.log(newErrors);


    if (Object.keys(newErrors).length > 0) {
      return;
    } else {
      setIsModalVisible(true);
    }
  };

  const handleSave = () => {

    console.log(selectedObject);

    setLoading(true);
    putMethod({
      url: `driver/byAdmin/${selectedObject._id}`,
      body: selectedObject,
      handleErrorMessage: showSnackbar,
    })
      .then((r) => {
        console.log(r);
        setSelectedObject(r.data)
        setRequestData(r.data)
        dispatch(putDataRedux({ key: "_id", value: selectedObject._id, stateName: "driversReq", data: r.data }))

      })
      .catch((error) => {
        if (error === "Internal Server Error: Server error occurred.") {
          handleError5xx();
          setTryAgain(() => handleSave());
        }
        console.error("Update API error:", error.message);
      })
      .finally(() => {
        setLoading(false);
      });
    setIsModalVisible(false);
    setOpen(true);
    handleCancel()

  }


  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleCarTypeChange = (event) => {
    const { target: { value } } = event;

    const selectedItems = value.map((name) => {
      const item = caTypeList.find((type) => type.name === name);
      return item ? { _id: item._id, name: item.name, image: item.image, enabled: item.enabled } : null;
    }).filter(item => item !== null);

    setSelectedCarTypes(selectedItems);
    setSelectedObject({
      ...selectedObject,
      car_type: selectedItems
    });
  };


  const handleVehicleTypeChange = (event) => {
    setVehicleType(event?.target?.value)
    setSelectedObject({
      ...selectedObject,
      vehicle_info: {
        ...selectedObject.vehicle_info,
        vehicle_type: event?.target?.value
      }
    });
  };


  const getRidesParams = () => {
    return {
      limit: 50,
    };
  };
  const fetchCarType = () => {
    if (
      (caTypeList?.length === 0)
    ) {
    }
    const url = "admin/car-type/all";
    getMethod({
      url: url,
      params: getRidesParams(),
      handleErrorMessage: showSnackbar,
    })
      .then((response) => {
        let result = response?.data;
        setCarTypeList(result);
      })
      .catch((error) => {
        if (error === "Internal Server Error: Server error occurred.") {
          handleError5xx();
          setTryAgain(() => fetchCarType);
        }
        console.error("Error fetching items:", error);
      })

  };

  useEffect(() => {
    fetchCarType()
    window.scrollTo({ top: 0 });
  }, []);


  useEffect(() => {
    setIsOpened(openDrawer);
  }, [openDrawer]);




  useEffect(() => {
    setIsOpened(openDrawer);
  }, [openDrawer]);

  //handle error5xx
  const [tryAgain, setTryAgain] = useState();
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [errorCount, setErrorCount] = useState(0);
  const [errorDialogCounter, setErrorDialogCounter] = useState(0);
  const handleError5xx = () => {
    setIsErrorDialogOpen(true);
    setErrorCount(prevCount => {
      const newCount = prevCount + 1;
      if (newCount === 1) {
        setErrorDialogCounter(5);
      } else if (newCount === 2) {
        setErrorDialogCounter(10);
      }
      return newCount;
    });
  };
  const handleCloseErrorDialog = () => {
    setIsErrorDialogOpen(false);
    if (errorCount <= 2) {
      handleErrorCountByClose()
    }

  };
  const handleErrorCountByClose = () => {
    setErrorCount(prevCount => {
      const newCount = prevCount - 1;
      if (newCount === 1) {
        setErrorDialogCounter(5);
      } else if (newCount === 2) {
        setErrorDialogCounter(10);
      }
      return newCount;
    });
  }

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };
  const countryCode = useSelector((state) => state.country.selectedCountry);
  const [personalInfo, setPersonalInfo] = useState([]);
  const [driverInfo, setDriverInfo] = useState({
    profile_picture: [],
    driver_license: [],
    vehicle_details: [],
    driver_record: [],
  });

  const vehicleTypes = [
    { value: "car", label: "Sedan" },
    { value: "truck", label: "Truck" },
    { value: "van", label: "Van" },
    { value: "motorcycle", label: "Motorcycle" },
    { value: "bicycle", label: "Bicycle" },
    { value: "scooter", label: "Scooter" },
    { value: "electric vehicle", label: "Electric Vehicle" },
  ];


  // Status to track sent values
  const [sentPersonalInfo, setSentPersonalInfo] = useState([]);
  const [sentDriverInfo, setSentDriverInfo] = useState({
    profile_picture: [],
    driver_license: [],
    vehicle_details: [],
    driver_record: [],
  });

  const driversReq = useSelector((state) => state.appState.driversReq);
  useEffect(() => {
    const foundObject = driversReq?.find((obj) => obj._id === params["id"]);
    setSelectedObject(foundObject);
    setRequestData(foundObject);
    setSelectedCarTypes(foundObject?.car_type)
    setVehicleType(foundObject?.vehicle_info?.vehicle_type)
  }, [params]);

  const dateObject = new Date(selectedObject?.dateOfBirth);
  const issDate = new Date(selectedObject?.driver_license_info?.iss);
  const expDate = new Date(selectedObject?.driver_license_info?.exp);
  // Formatting options
  const options = { day: "numeric", month: "short", year: "numeric" };

  const handleOpenSnackbar = () => {
    setOpen(true);
  };

  // Function to handle closing the Snackbar
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  // Function to open the link
  const openLink = () => {
    if (selectedObject?.driver_record) {
      window.open(
        selectedObject.driver_record,
        "_blank",
        "noopener,noreferrer"
      );
    } else {
      handleOpenSnackbar(); // If driver record is empty, open the Snackbar
    }
  };

  const fieldStyle = {
    "& label.Mui-focused": {
      color: "#172B4D !important",
      fontSize: "17px !important",
      fontWeight: "500 !important",
    },
    "& .MuiOutlinedInput-root": {

      "& fieldset": {
        color: "#172B4D !important",
        borderColor: "#BBC1CE",
        borderWidth: 1,
        borderRadius: "8px",
      },
      "&:hover fieldset": {
        color: "#172B4D !important",
        borderColor: "#BBC1CE",
        borderWidth: 1,
        borderRadius: "8px",
      },
      "&.Mui-focused fieldset": {
        color: "#172B4D !important",
        borderColor: "#BBC1CE",
        borderWidth: 1,
        borderRadius: "8px",
      },
      input: {
        "&::placeholder": {
          color: "#172B4D !important",
          opacity: 1,
          fontSize: 14,
        },
      },
    },
  };

  // Applying formatting and getting the formatted date string
  const formattedDate = dateObject.toLocaleDateString("en-US", options);
  const formattedIssDate = issDate.toLocaleDateString("en-US", options);
  const formattedExpDate = expDate.toLocaleDateString("en-US", options);

  const handleChange = (e, newValue) => {
    handleCancel()
    setValueTabs(newValue);
  };

  const openRejectDialog = (id) => {
    setRejectItemId(id);
    setRejectDialogOpen(true);
    setRejectMsg(selectedObject?.reject_msg);

    setPersonalInfo((prevPersonalInfo) => [
      ...prevPersonalInfo,
      ...(selectedObject?.reject_values_personal_info || []),
    ]);

    const updatedDriverInfo = selectedObject?.reject_values_driver_info.reduce(
      (acc, item) => {
        acc[item.type] = item.reject_values;
        return acc;
      },
      {}
    );

    setDriverInfo((prevDriverInfo) => ({
      profile_picture: [
        ...(prevDriverInfo.profile_picture || []),
        ...(updatedDriverInfo.profile_picture || []),
      ],
      driver_license: [
        ...(prevDriverInfo.driver_license || []),
        ...(updatedDriverInfo.driver_license || []),
      ],
      vehicle_details: [
        ...(prevDriverInfo.vehicle_details || []),
        ...(updatedDriverInfo.vehicle_details || []),
      ],
      driver_record: [
        ...(prevDriverInfo.driver_record || []),
        ...(updatedDriverInfo.driver_record || []),
      ],
    }));
  };

  const closeRejectDialog = () => {
    setRejectDialogOpen(false);
    setRejectItemId(null);
    setRejectMsg(selectedObject?.reject_msg);
  };

  const handleReject = () => {
    openRejectDialog();
  };

  const styles = {
    buttonStyle: {
      textTransform: "capitalize",
      fontSize: "16px",
      fontWeight: "600",
      fontFamily: "Poppins",
    },
    tabStyle: {
      width: "182px",
      textTransform: "capitalize",
      fontWeight: "bold",
      fontFamily: "Poppins",
      fontSize: "16px",
    },
    imageTitle: {
      color: "#172B4D",
      fontSize: "16px",
      fontWeight: "600",
    },
    rejectText: {
      color: " red",
      marginLeft: "auto",
      display: "flex",
      alignItems: "center",
      paddingRight: "30px",
      fontWeight: "600",
    },
  };

  const dataToUpdate = {
    account_status: "accept",
    reject_msg: "",
    car_type: selectedCarTypes,
    vehicle_info: {
      vehicle_make: selectedObject?.vehicle_info?.vehicle_make,
      vehicle_type: vehicleType,
      vin: selectedObject?.vehicle_info?.vin,
      vehicle_model: selectedObject?.vehicle_info?.vehicle_model,
      vehicle_year: selectedObject?.vehicle_info?.vehicle_year,
      vehicle_color: selectedObject?.vehicle_info?.vehicle_color,
      license_plate: selectedObject?.vehicle_info?.license_plate,
      vehicle_images: selectedObject?.vehicle_info?.vehicle_images,
    }
  };



  const handleSubmit = () => {
    setAttemptedSubmit(true);
    if (selectedCarTypes?.length === 0) {
      console.log("u must choose one at least");
    } else {
      setLoading(true);
      putMethod({
        url: `driver/byAdmin/${selectedObject?._id}`,
        body: dataToUpdate,
        handleErrorMessage: showSnackbar,
      })
        .then((response) => {
          console.log("Update API response:", response.data);
          navigate("/drivers");
        })
        .catch((error) => {
          console.error("Update API error:", error.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const handlePersonalInfoChange = (value) => (event) => {
    const newValues = event.target.checked
      ? [...personalInfo, value]
      : personalInfo.filter((v) => v !== value);
    setPersonalInfo(newValues);
  };

  const handleDriverInfoChange = (type, value) => (event) => {
    const newValues = event.target.checked
      ? [...driverInfo[type], value]
      : driverInfo[type].filter((v) => v !== value);
    setDriverInfo({ ...driverInfo, [type]: newValues });
  };

  const handleRejectSubmit = () => {
    if (
      personalInfo.length === 0 &&
      driverInfo.profile_picture.length === 0 &&
      driverInfo.driver_license.length === 0 &&
      driverInfo.vehicle_details.length === 0 &&
      driverInfo.driver_record.length === 0 &&
      (rejectMsg === undefined || rejectMsg === "")
    ) {
      setSnackbar({
        open: true,
        message: "Please select a reason to reject or add reject message.",
        severity: "error",
      });
      return;
    }

    setLoading(true);

    const newPersonalInfo = personalInfo.filter(
      (item) => !sentPersonalInfo.includes(item)
    );

    const newDriverInfo = {};
    Object.keys(driverInfo).forEach((key) => {
      newDriverInfo[key] = Array.from(
        new Set(
          driverInfo[key].filter((item) => !sentDriverInfo[key].includes(item))
        )
      );
    });

    const requestBody = {
      account_status: "reject",
      reject_msg: rejectMsg === undefined ? "" : rejectMsg,
      reject_values_personal_info: newPersonalInfo,
      reject_values_driver_info: [
        {
          type: "profile_picture",
          reject_values: newDriverInfo.profile_picture,
        },
        { type: "driver_license", reject_values: newDriverInfo.driver_license },
        {
          type: "vehicle_details",
          reject_values: newDriverInfo.vehicle_details,
        },
        { type: "driver_record", reject_values: newDriverInfo.driver_record },
      ],
    };

    putMethod({
      url: `driver/byAdmin/${selectedObject?._id}`,
      body: requestBody,
      handleErrorMessage: showSnackbar,
    })
      .then((r) => {
        setLoading(false);
        closeRejectDialog();
        setSelectedObject((prev) => ({
          ...prev,
          account_status: "reject",
          reject_msg: rejectMsg,
          reject_values_personal_info: personalInfo,
          reject_values_driver_info: [
            {
              type: "profile_picture",
              reject_values: driverInfo.profile_picture,
            },
            {
              type: "driver_license",
              reject_values: driverInfo.driver_license,
            },
            {
              type: "vehicle_details",
              reject_values: driverInfo.vehicle_details,
            },
            {
              type: "driver_record",
              reject_values: driverInfo.driver_record,
            },
          ],
        }));

        setSnackbar({
          open: true,
          message: "The driver is rejected successfully.",
          severity: "success",
        });
      })
      .catch((error) => {
        console.error("Reject API error:", error.message);
        setLoading(false);
        closeRejectDialog();
        setSnackbar({
          open: true,
          message: "An error occurred. Please try again.",
          severity: "error",
        });
      });
  };

  const determineFileType = (url) => {
    const extension = url.split('.').pop().split('?')[0];
    if (['jpg', 'jpeg', 'png', 'gif'].includes(extension.toLowerCase())) {
      return 'Image';
    } else if (extension.toLowerCase() === 'pdf') {
      return 'PDF';
    } else if (['xls', 'xlsx'].includes(extension.toLowerCase())) {
      return 'Excel';
    } else {
      return 'Unknown';
    }
  };

  const capitalizeFirstLetter = (str) => {
    return str?.charAt(0)?.toUpperCase() + str?.slice(1);
  };

  const handleCloseSnnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };
  const getDriverRequirementsParams = () => {
    return {
      limit: 50,
    };
  };

  useEffect(() => {
    getMethod({
      url: "driver/document/driver-document",
      params: getDriverRequirementsParams(),
      handleErrorMessage: showSnackbar,
    })
      .then((response) => {
        setDriverDocs(response.data)
        console.log(response.data);

      })
      .catch((error) => {
        console.error("Error fetching more stores:", error);
      });
  }, []);


  const [imageFiles, setImageFiles] = useState([]);
  const [otherFiles, setOtherFiles] = useState([]);

  useEffect(() => {
    if (selectedObject && selectedObject?.documents) {
      const imageResults = [];
      const otherResults = [];
      Object.entries(selectedObject.documents).forEach(([key, url]) => {
        const fileType = determineFileType(url);
        if (fileType === 'Image') {
          imageResults.push({ key, url, fileType });
        } else {
          otherResults.push({ key, url, fileType });
        }
      });

      setImageFiles(imageResults);
      setOtherFiles(otherResults);
    }
  }, [selectedObject]);

  const renderFiles = (files) => {
    return files.map(({ key, url, fileType }) => (
      <Grid item xs={12} sm={6} md={4} key={key}>
        <Typography style={{
          marginBottom: '10px',
          fontFamily: "poppins",
          fontWeight: "600",
          size: "16px",
          lineHeight: '16px',
          color: '#172B4D',
          display: "flow",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "start"
        }}>{capitalizeFirstLetter(key)}
          {!view && (
            <button
              onClick={() => handleDeleteDoc(key)}
              style={{
                background: "none",
                border: "none",
                cursor: "pointer",
                color: "red",
              }}
            >
              &#x2716;
            </button>
          )}
        </Typography>
        <Card style={{ borderRadius: "8px", border: `1px solid ${colorConfigs.sidebar.bg}` }}>
          <CardContent>
            {fileType === 'Image' ? (
              <CardMedia component="img" image={url} alt={key} style={{ width: '100%', height: '300px' }} />
            ) : (
              <a href={url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                <div style={{
                  display: 'flex', alignItems: 'center', justifyContent: 'center', height: "20px",
                }}>
                  <img
                    src={"https://cdn.builder.io/api/v1/image/assets/TEMP/8fc56007e5f105368c7fab63a4262b0902902878ea5f93087925200ae34d4a4f?"}
                    alt={`${fileType} Icon`}
                    style={{ width: 35, height: 35 }}
                  />
                  <Typography style={{
                    fontFamily: "poppins",
                    fontWeight: "600",
                    size: "14px",
                    lineHeight: '21px',
                    color: colorConfigs.sidebar.bg
                  }}  >{key}</Typography>
                  <Typography style={{
                    fontFamily: "poppins",
                    fontWeight: "600",
                    size: "14px",
                    lineHeight: '21px',
                    color: colorConfigs.sidebar.bg
                  }} >{`.${fileType}`}</Typography>
                </div>
              </a>
            )}
          </CardContent>
        </Card>
      </Grid>
    ));
  };

  const [lightboxIndex, setLightboxIndex] = useState(-1);

  const images = [
    selectedObject?.profile_picture,
    selectedObject?.driver_license_front,
    selectedObject?.driver_license_back,
  ];

  const handleOpenLightbox = (index) => {
    setLightboxIndex(index);
  };

  const handleCloseLightbox = () => {
    setLightboxIndex(-1);
  };

  const handleMovePrev = () => {
    setLightboxIndex((lightboxIndex + images.length - 1) % images.length);
  };

  const handleMoveNext = () => {
    setLightboxIndex((lightboxIndex + 1) % images.length);
  };
  const handleCancelSave = (event, reason) => {
    setIsModalVisible(false);
  };


  const imgsBox = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    alignItems: "center",
  }


  const handleCancel = () => {
    setView(true);
    setErrors({});
    setSelectedObject(requestData)
  }

  const handleDelete = (index) => {
    const updatedImages = [...selectedObject?.vehicle_info?.vehicle_images];
    updatedImages.splice(index, 1);
    setSelectedObject({
      ...selectedObject,
      vehicle_info: {
        ...selectedObject.vehicle_info,
        vehicle_images: updatedImages
      }
    });
  };

  const handleDeleteDoc = (key) => {
    const updatedDocuments = Object.keys(selectedObject.documents).reduce((acc, currKey) => {
      if (currKey !== key) {
        acc[currKey] = selectedObject.documents[currKey];
      }
      return acc;
    }, {});
    setSelectedObject({
      ...selectedObject,
      documents: updatedDocuments
    });
  };


  const handleImagrUrl = async (e, target) => {

    setLoading(true);
    let file = e.target?.files[0];
    if ((file && file.length !== 0) || (file && file !== "" && typeof file !== "string")) {
      setLoading(true);
      const uploadTask = storageSdk.ref(`/drivers/${file.name}`).put(file);
      uploadTask.on(
        "state_changed",
        (snapShot) => {
          //takes a snap shot of the process as it is happening
        },
        (err) => {
          setLoading(false); // Set loading to false in case of an error
        },
        async () => {
          try {
            const fireBaseUrl = await storageSdk
              .ref("drivers")
              .child(file.name)
              .getDownloadURL();

            let dataToUpdate = {}
            if (target === "profile_picture") {
              dataToUpdate = {
                profile_picture: fireBaseUrl,
              };
            } else if (target === "driver_license_front") {
              dataToUpdate = {
                driver_license_front: fireBaseUrl,
              };
            } else if (target === "driver_license_back") {
              dataToUpdate = {
                driver_license_back: fireBaseUrl,
              };
            } else if (target === "vehicle_images") {
              dataToUpdate = {
                vehicle_info: {
                  ...selectedObject.vehicle_info,
                  vehicle_images: [...selectedObject.vehicle_info.vehicle_images, fireBaseUrl],
                },
              };
            } else {
              dataToUpdate = {
                documents: {
                  ...selectedObject.documents,
                  [target]: fireBaseUrl
                },
              };
            }


            try {
              setLoading(true);
              putMethod({
                url: `driver/byAdmin/${selectedObject._id}`,
                body: dataToUpdate,
                handleErrorMessage: showSnackbar,
              })
                .then((response) => {
                  setErrors((prevErrors) => ({ ...prevErrors, [target]: "" }));
                  setSelectedObject(response.data)
                  setRequestData(response.data)
                  dispatch(putDataRedux({ key: "_id", value: selectedObject._id, stateName: "driversReq", data: response.data }))
                  setOpen(true);
                })
            } catch (error) {
              console.error("Update API error:", error.message);
            } finally {
              setLoading(false);
            }
            setUrl(fireBaseUrl);
          } catch (error) {
            console.error("Download URL error:", error.message);
            setLoading(false);
          } finally {
            setLoading(false);
          }
        }
      );
    } else {
      setUrl(file);
      setLoading(false);
    }
    setInputKey(Math.random());
  };

  const handleImageClick = (index) => {
    setStartIndex(index);
    setIsDialogOpen(true);
  };

  const handleCloseGalary = () => {
    setIsDialogOpen(false);
  };

  const galaryImages = selectedObject?.vehicle_info?.vehicle_images?.map((image) => ({
    original: image,
    thumbnail: image,
  }));

  return (
    <>
      {/* handleError5xx */}
      <Error5xx open={isErrorDialogOpen} errCount={errorCount} count={errorDialogCounter} handleClose={handleCloseErrorDialog} tryAgain={tryAgain} />
      {/* reject dialog */}
      <Dialog
        fullWidth
        maxWidth="md"
        sx={{
          "& .MuiDialog-container": {
            justifyContent: "center",
          },
          "& .MuiPaper-root": {
            borderRadius: "30px",
            position: isOpened ? "none" : "relative",
            left: isOpened ? "11%" : "0",
          },
        }}
        style={{
          zIndex: 10,
          padding: "20px",
        }}
        open={isRejectDialogOpen}
        onClose={() => {
          closeRejectDialog();
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Stack direction="row" justifyContent="space-between">
              <Typography
                variant="h6"
                style={{
                  fontWeight: "bold",
                  color: "black",
                  marginBottom: "8px",
                }}
              >
                {isRejectDialogOpen ? " Reasons of Rejected" : ""}
              </Typography>
              <IconButton
                aria-label="close"
                onClick={() => {
                  closeRejectDialog();
                }}
                sx={{
                  bgcolor: "#EEEEEE",
                  width: "32px",
                  height: "32px",
                  borderRadius: "8px !important",
                }}
              >
                <CloseIcon
                  sx={{ color: "black", width: "24px", height: "24px" }}
                />
              </IconButton>
            </Stack>
            <Stack>
              <ul
                style={{
                  backgroundColor: "#F6F6F6",
                  borderRadius: "10px",
                }}
              >
                <li
                  style={{
                    color: colorConfigs.sidebar.bg,
                    fontWeight: "700",
                    fontSize: "18px",
                    padding: "10px 0",
                  }}
                >
                  Driver Info test
                </li>
              </ul>

              <Grid
                container
                direction="row"
                justifyContent="left"
                style={{
                  border: "1px solid #F6F6F6",
                  borderRadius: "10px",
                }}
              >
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Stack direction="row" alignItems="center">
                    <Checkbox
                      sx={{
                        "&.Mui-checked": {
                          color: colorConfigs.sidebar.bg,
                        },
                      }}
                      checked={personalInfo.includes("first_name")}
                      onChange={handlePersonalInfoChange("first_name")}
                    />
                    <Typography
                      style={{
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "15.36px",
                        color: "#172B4D",
                      }}
                    >
                      Name
                    </Typography>
                    <span
                      style={{
                        color: "rgb(122 134 154 / 32%)",
                        marginLeft: "10px",
                      }}
                    >
                      |
                    </span>
                  </Stack>
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Stack direction="row" alignItems="center" marginRight="25px">
                    <Checkbox
                      sx={{
                        "&.Mui-checked": {
                          color: colorConfigs.sidebar.bg,
                        },
                      }}
                      checked={driverInfo.driver_license.includes("gender")}
                      onChange={handleDriverInfoChange("driver_license", "gender")}
                    />
                    <Typography
                      style={{
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "15.36px",
                        color: "#172B4D",
                      }}
                    >
                      Gender
                    </Typography>
                    <span
                      style={{
                        color: "rgb(122 134 154 / 32%)",
                        marginLeft: "10px",
                      }}
                    >
                      |
                    </span>
                  </Stack>
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Stack direction="row" alignItems="center" marginRight="25px">
                    <Checkbox
                      sx={{
                        "&.Mui-checked": {
                          color: colorConfigs.sidebar.bg,
                        },
                      }}
                      checked={personalInfo.includes("email")}
                      onChange={handlePersonalInfoChange("email")}
                    />
                    <Typography
                      style={{
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "15.36px",
                        color: "#172B4D",
                      }}
                    >
                      Email
                    </Typography>
                    <span
                      style={{
                        color: "rgb(122 134 154 / 32%)",
                        marginLeft: "10px",
                      }}
                    >
                      |
                    </span>
                  </Stack>
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Stack direction="row" alignItems="center" marginRight="10px">
                    <Checkbox
                      sx={{
                        "&.Mui-checked": {
                          color: colorConfigs.sidebar.bg,
                        },
                      }}
                      checked={personalInfo.includes("DOB")}
                      onChange={handlePersonalInfoChange("DOB")}
                    />
                    <Typography
                      style={{
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "15.36px",
                        color: "#172B4D",
                      }}
                    >
                      Date of Birth
                    </Typography>
                    <span
                      style={{
                        color: "rgb(122 134 154 / 32%)",
                        marginLeft: "10px",
                      }}
                    >
                      |
                    </span>
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
            <Stack>
              <ul
                style={{
                  backgroundColor: "#F6F6F6",
                  borderRadius: "10px",
                }}
              >
                <li
                  style={{
                    color: colorConfigs.sidebar.bg,
                    fontWeight: "700",
                    fontSize: "18px",
                    padding: "10px 0",
                  }}
                >
                  Driver Documents
                </li>
              </ul>

              <Grid
                container
                direction="row"
                flexWrap="wrap"
                justifyContent="space-between"
                style={{
                  border: "1px solid #F6F6F6",
                  borderRadius: "10px",
                }}
              >
                <Grid item xs={12} sm={6} md={4} lg={3} style={{ flexBasis: '25%' }}>
                  <Stack direction="row" alignItems="center" marginRight="25px">
                    <Checkbox
                      sx={{
                        "&.Mui-checked": {
                          color: colorConfigs.sidebar.bg,
                        }
                      }}
                      checked={driverInfo.profile_picture.includes("image")}
                      onChange={handleDriverInfoChange("profile_picture", "image")}
                    />
                    <Typography
                      style={{
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "15.36px",
                        color: "#172B4D",
                      }}
                    >
                      Profile Photo
                    </Typography>
                    <span
                      style={{
                        color: "rgb(122 134 154 / 32%)",
                        marginLeft: "10px",
                      }}
                    >
                      |
                    </span>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} style={{ flexBasis: '25%' }}>
                  <Stack direction="row" alignItems="center" marginRight="25px">
                    <Checkbox
                      sx={{
                        "&.Mui-checked": {
                          color: colorConfigs.sidebar.bg,
                        }
                      }}
                      checked={driverInfo.driver_license.includes("license_front")}
                      onChange={handleDriverInfoChange("driver_license", "license_front")}
                    />
                    <Typography
                      style={{
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "15.36px",
                        color: "#172B4D",
                      }}
                    >
                      Driver license - Front
                    </Typography>
                    <span
                      style={{
                        color: "rgb(122 134 154 / 32%)",
                        marginLeft: "10px",
                      }}
                    >
                      |
                    </span>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} style={{ flexBasis: '25%' }}>
                  <Stack direction="row" alignItems="center" marginRight="25px">
                    <Checkbox
                      sx={{
                        "&.Mui-checked": {
                          color: colorConfigs.sidebar.bg,
                        }
                      }}
                      checked={driverInfo.driver_license.includes("license_back")}
                      onChange={handleDriverInfoChange("driver_license", "license_back")}
                    />
                    <Typography
                      style={{
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "15.36px",
                        color: "#172B4D",
                      }}
                    >
                      Driver license - Back
                    </Typography>
                    <span
                      style={{
                        color: "rgb(122 134 154 / 32%)",
                        marginLeft: "10px",
                      }}
                    >
                      |
                    </span>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} style={{ flexBasis: '25%' }}>
                  <Stack direction="row" alignItems="center" marginRight="25px">
                    <Checkbox
                      sx={{
                        "&.Mui-checked": {
                          color: colorConfigs.sidebar.bg,
                        }
                      }}
                      checked={driverInfo.driver_record.includes("pdf")}
                      onChange={handleDriverInfoChange("driver_record", "pdf")}
                    />
                    <Typography
                      style={{
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "15.36px",
                        color: "#172B4D",
                      }}
                    >
                      Driving record
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                alignItems="left"
                flexWrap="wrap"
                marginTop="10px"
                style={{
                  border: "1px solid #F6F6F6",
                  borderRadius: "10px",
                }}
              >
                <Grid item xs={12} sm={6} md={4} lg={3} style={{ flexBasis: '25%' }}>
                  <Stack direction="row" alignItems="center" marginRight="25px">
                    <Checkbox
                      sx={{
                        "&.Mui-checked": {
                          color: colorConfigs.sidebar.bg,
                        },
                      }}
                      checked={driverInfo.driver_license.includes("license_number")}
                      onChange={handleDriverInfoChange("driver_license", "license_number")}
                    />
                    <Typography
                      style={{
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "15.36px",
                        color: "#172B4D",
                      }}
                    >
                      License Number
                    </Typography>
                    <span
                      style={{
                        color: "rgb(122 134 154 / 32%)",
                        marginLeft: "10px",
                      }}
                    >
                      |
                    </span>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} style={{ flexBasis: '25%' }}>
                  <Stack direction="row" alignItems="center" marginRight="25px">
                    <Checkbox
                      sx={{
                        "&.Mui-checked": {
                          color: colorConfigs.sidebar.bg,
                        },
                      }}
                      checked={driverInfo.driver_license.includes("iss")}
                      onChange={handleDriverInfoChange("driver_license", "iss")}
                    />
                    <Typography
                      style={{
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "15.36px",
                        color: "#172B4D",
                      }}
                    >
                      Iss Date
                    </Typography>
                    <span
                      style={{
                        color: "rgb(122 134 154 / 32%)",
                        marginLeft: "10px",
                      }}
                    >
                      |
                    </span>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} style={{ flexBasis: '25%' }}>
                  <Stack direction="row" alignItems="center" marginRight="25px">
                    <Checkbox
                      sx={{
                        "&.Mui-checked": {
                          color: colorConfigs.sidebar.bg,
                        },
                      }}
                      checked={driverInfo.driver_license.includes("class")}
                      onChange={handleDriverInfoChange("driver_license", "class")}
                    />
                    <Typography
                      style={{
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "15.36px",
                        color: "#172B4D",
                      }}
                    >
                      Class
                    </Typography>
                    <span
                      style={{
                        color: "rgb(122 134 154 / 32%)",
                        marginLeft: "10px",
                      }}
                    >
                      |
                    </span>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} style={{ flexBasis: '25%' }}>
                  <Stack direction="row" alignItems="center" marginRight="25px">
                    <Checkbox
                      sx={{
                        "&.Mui-checked": {
                          color: colorConfigs.sidebar.bg,
                        },
                      }}
                      checked={driverInfo.driver_license?.includes("exp")}
                      onChange={handleDriverInfoChange("driver_license", "exp")}
                    />
                    <Typography
                      style={{
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "15.36px",
                        color: "#172B4D",
                      }}
                    >
                      Exp Date
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
            <Stack>
              <ul
                style={{
                  backgroundColor: "#F6F6F6",
                  borderRadius: "10px",
                }}
              >
                <li
                  style={{
                    color: colorConfigs.sidebar.bg,
                    fontWeight: "700",
                    fontSize: "18px",
                    padding: "10px 0",
                  }}
                >
                  Vehicle Details
                </li>
              </ul>
              <Grid
                container
                direction="row"
                flexWrap="wrap"
                justifyContent="space-between"
                style={{
                  border: "1px solid #F6F6F6",
                  borderRadius: "20px",
                  marginBottom: "20px",
                }}
              >
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Stack direction="row" alignItems="center" marginRight="25px">
                    <Checkbox
                      sx={{
                        "&.Mui-checked": {
                          color: colorConfigs.sidebar.bg,
                        },

                      }}
                      checked={driverInfo.vehicle_details?.includes("image")}
                      onChange={handleDriverInfoChange(
                        "vehicle_details",
                        "image"
                      )}
                    />
                    <Typography
                      style={{
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "15.36px",
                        color: "#172B4D",
                      }}
                    >
                      Car Image
                    </Typography>
                    <span
                      style={{
                        color: "rgb(122 134 154 / 32%)",
                        marginLeft: "10px",
                      }}
                    >
                      |
                    </span>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Stack direction="row" alignItems="center" marginRight="25px">
                    <Checkbox
                      sx={{
                        "&.Mui-checked": {
                          color: colorConfigs.sidebar.bg,
                        },

                      }}
                      checked={driverInfo.vehicle_details?.includes("type")}
                      onChange={handleDriverInfoChange(
                        "vehicle_details",
                        "type"
                      )}
                    />
                    <Typography
                      style={{
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "15.36px",
                        color: "#172B4D",
                      }}
                    >
                      Vehicle Type
                    </Typography>
                    <span
                      style={{
                        color: "rgb(122 134 154 / 32%)",
                        marginLeft: "10px",
                      }}
                    >
                      |
                    </span>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Stack direction="row" alignItems="center" marginRight="25px">
                    <Checkbox
                      sx={{
                        "&.Mui-checked": {
                          color: colorConfigs.sidebar.bg,
                        },

                      }}
                      checked={driverInfo.vehicle_details?.includes("make")}
                      onChange={handleDriverInfoChange(
                        "vehicle_details",
                        "make"
                      )}
                    />
                    <Typography
                      style={{
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "15.36px",
                        color: "#172B4D",
                      }}
                    >
                      Vehicle Make
                    </Typography>
                    <span
                      style={{
                        color: "rgb(122 134 154 / 32%)",
                        marginLeft: "10px",
                      }}
                    >
                      |
                    </span>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Stack direction="row" alignItems="center" marginRight="25px">
                    <Checkbox
                      sx={{
                        "&.Mui-checked": {
                          color: colorConfigs.sidebar.bg,
                        },

                      }}
                      checked={driverInfo.vehicle_details?.includes("modal")}
                      onChange={handleDriverInfoChange(
                        "vehicle_details",
                        "modal"
                      )}
                    />
                    <Typography
                      style={{
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "15.36px",
                        color: "#172B4D",
                      }}
                    >
                      Vehicle Model
                    </Typography>
                    <span
                      style={{
                        color: "rgb(122 134 154 / 32%)",
                        marginLeft: "10px",
                      }}
                    >
                      |
                    </span>
                  </Stack>
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                alignItems="center"
                marginBottom="10px"
                style={{
                  border: "1px solid #F6F6F6",
                  borderRadius: "10px",
                }}
              >
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Stack direction="row" alignItems="center" marginRight="25px">
                    <Checkbox
                      sx={{
                        "&.Mui-checked": {
                          color: colorConfigs.sidebar.bg,
                        },

                      }}
                      checked={driverInfo.vehicle_details?.includes("color")}
                      onChange={handleDriverInfoChange(
                        "vehicle_details",
                        "color"
                      )}
                    />
                    <Typography
                      style={{
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "15.36px",
                        color: "#172B4D",
                      }}
                    >
                      Vehicle Color
                    </Typography>
                    <span
                      style={{
                        color: "rgb(122 134 154 / 32%)",
                        marginLeft: "10px",
                      }}
                    >
                      |
                    </span>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Stack direction="row" alignItems="center" marginRight="25px">
                    <Checkbox
                      sx={{
                        "&.Mui-checked": {
                          color: colorConfigs.sidebar.bg,
                        },

                      }}
                      checked={driverInfo.vehicle_details?.includes(
                        "registraion_number"
                      )}
                      onChange={handleDriverInfoChange(
                        "vehicle_details",
                        "registraion_number"
                      )}
                    />
                    <Typography
                      style={{
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "15.36px",
                        color: "#172B4D",
                      }}
                    >
                      Registration Number
                    </Typography>
                    <span
                      style={{
                        color: "rgb(122 134 154 / 32%)",
                        marginLeft: "10px",
                      }}
                    >
                      |
                    </span>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Stack direction="row" alignItems="center" marginRight="25px">
                    <Checkbox
                      sx={{
                        "&.Mui-checked": {
                          color: colorConfigs.sidebar.bg,
                        },

                      }}
                      checked={driverInfo.vehicle_details?.includes("year")}
                      onChange={handleDriverInfoChange(
                        "vehicle_details",
                        "year"
                      )}
                    />
                    <Typography
                      style={{
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "15.36px",
                        color: "#172B4D",
                      }}
                    >
                      Vehicle Year
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
            <TextField
              label="Note Or Add Other reason......"
              multiline
              rows={4}
              defaultValue=""
              style={{
                color: "#959FA3",
                lineHight: "20px",
                fontSize: "14px",
                fontWeight: "400px",
                marginTop: "20px",
                marginBottom: "20px",
              }}
              value={rejectMsg}
              onChange={(e) => setRejectMsg(e.target.value)}
            />
          </DialogContentText>
        </DialogContent>

        <DialogActions
          style={{
            display: "flex",
            justifyContent: "flex-start",
            marginBottom: "20px",
            padding: "0 20px",
            marginTop: "10px",
          }}
        >
          {isRejectDialogOpen && (
            <Button
              variant="outlined"
              color="error"
              style={{
                width: "250px",
                ...styles.buttonStyle,
              }}
              disabled={loading}
              onClick={handleRejectSubmit}
            >
              {loading
                ? "...Loading"
                : selectedObject?.account_status === "pending"
                  ? "Reject Driver"
                  : "Update Reject"}
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <Modal
        title="Confirm Changes"
        visible={isModalVisible}
        onOk={handleSave}
        onCancel={handleCancelSave}
        okText="Yes"
        okButtonProps={{
          style: {
            backgroundColor: colorConfigs?.sidebar?.bg,
            color: "white",
          },
        }}
      >
        <p>Are you sure you want to save the changes?</p>
      </Modal>

      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "85vh",
        }}
      >
        <div>
          <Box
            sx={{ width: "100%", backgroundColor: "rgba(242, 242, 242, 1)" }}
          >
            <Tabs
              value={valueTabs}
              onChange={handleChange}
              textColor="inherit"
              sx={{ color: colorConfigs.sidebar.bg, }}
              TabIndicatorProps={{
                style: {
                  backgroundColor: colorConfigs.sidebar.bg,
                },
              }}
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
              variant="scrollable"
            >
              <Tab value="one" label="Driver Info" style={styles.tabStyle} />
              <Tab
                value="two"
                label="Driver Documents"
                style={styles.tabStyle}
              />
              <Tab
                value="three"
                label="Vehicle Details"
                style={styles.tabStyle}
              />
              <Tab
                value="four"
                label="Additional Requirements"
                style={{
                  width: "250px",
                  textTransform: "capitalize",
                  fontWeight: "bold",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                }}
              />
              {selectedObject && selectedObject?.account_status === "reject" ? (
                <Typography style={styles.rejectText}>Rejected</Typography>
              ) : null}
            </Tabs>
          </Box>
          {valueTabs === "one" && (
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "25ch" },
                "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {},
              }}
              style={{
                padding: "30px",
                display: "flex",
                flexDirection: "column",
                gap: "14px",
              }}
              noValidate
              autoComplete="off"
            >
              <Box
                display="flex"
                style={{ cursor: "pointer", marginLeft: "10px" }}
              >
                <Button
                  variant="outlined"
                  onClick={() => {
                    setView(false);

                  }}
                  style={{
                    borderTopLeftRadius: "88px",
                    borderBottomLeftRadius: "88px",
                    borderColor: colorConfigs?.sidebar?.bg,
                    color: !view ? "white" : "#42526E",
                    backgroundColor: !view ? colorConfigs?.sidebar?.bg : "white",
                    cursor: "pointer",
                  }}
                >
                  <DriveFileRenameOutlineSharpIcon fontSize="small" /> Edit{" "}
                </Button>

                <Button
                  variant="outlined"
                  onClick={() => {
                    handleCancel();
                  }}
                  style={{
                    borderTopRightRadius: "88px",
                    borderBottomRightRadius: "88px",
                    borderRight: "1px solid black",
                    borderColor: colorConfigs?.sidebar?.bg,
                    color: view ? "white" : colorConfigs?.sidebar?.bg,
                    backgroundColor: view ? colorConfigs?.sidebar?.bg : "white",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <VisibilityOutlinedIcon fontSize="small" /> View
                </Button>
              </Box>

              <div
                style={{
                  display: "flex",
                  columnGap: "14px",
                }}
              >
                <TextInput
                  name="first_name"
                  styles={fieldStyle}
                  style={{ flex: "1" }}
                  focused
                  placeholder={selectedObject?.first_name ? "" : "Please Enter Driver First Name."}
                  label="First Name"
                  value={selectedObject?.first_name}
                  disabled={view}
                  onChange={(e) => handleChangeData(e)}
                  error={Boolean(errors.first_name)}
                />

                <TextInput
                  name="last_name"
                  styles={fieldStyle}
                  style={{ flex: "1" }}
                  placeholder={selectedObject?.last_name ? "" : "Please Enter Driver Last Name."}
                  focused
                  label="Last Name"
                  value={selectedObject?.last_name}
                  disabled={view}
                  onChange={(e) => handleChangeData(e)}
                  error={Boolean(errors.last_name)}
                />

              </div>
              <div style={{
                marginLeft: "10px",
                marginTop: "-15px",
                display: "flex",
                alignItems: 'center',
                justifyContent: "space-between"
              }}>
                {errors.first_name && (
                  <Typography variant="caption" style={{ color: "red", width: "50%", }}>
                    {errors.first_name}
                  </Typography>
                )}
                {errors.last_name && (
                  <Typography variant="caption" style={{
                    color: "red", width: "50%", marginLeft: errors.first_name ? "20px" : '51%'
                  }}>
                    {errors.last_name}
                  </Typography>
                )}
              </div>

              <div style={{ display: "flex", alignItems: "center", columnGap: "14px" }}>
                <TextInput
                  name="email"
                  styles={fieldStyle}
                  style={{ flex: "1", background: "white" }}
                  label={"Phone Number"}
                  focused
                  value={NewFormatPhoneNumber((selectedObject?.code_phone_number + selectedObject?.phone_number), countryCode)}
                  disabled={true}
                />
                <TextInput
                  name="email"
                  styles={fieldStyle}
                  style={{ flex: "1", background: "white" }}
                  label={"Email"}
                  focused
                  placeholder={selectedObject?.email ? "" : "Please Enter Email Address."}
                  value={selectedObject?.email}
                  disabled={view || (view === false && selectedObject?.is_email_verified)}
                  onChange={(e) => handleChangeData(e)}
                  error={Boolean(errors.email)}
                />
              </div>
              <div style={{
                marginLeft: "10px",
                marginTop: "-15px",
                display: "flex",
                alignItems: 'center',
                justifyContent: "space-between"
              }}>
                {errors.email && (
                  <Typography variant="caption" style={{ color: "red", width: "50%", }}>
                  </Typography>
                )}
                {errors.email && (
                  <Typography variant="caption" style={{ color: "red", width: "50%", marginLeft: "20px" }}>
                    {errors.email}
                  </Typography>
                )}
              </div>
              <div style={{ display: "flex", width: "100%", flexDirection: 'column', alignItems: "start", columnGap: "14px" }}>
                <Grid sx={{ width: "50%", paddingRight: "25px" }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      name="dateOfBirth"
                      label={'Date of Birth'}
                      disabled={view}
                      value={selectedObject?.dateOfBirth ? new Date(selectedObject?.dateOfBirth) : null}
                      sx={{ ...fieldStyle, width: "100% !important" }}
                      onChange={(e) => { handleChangeDate(e, "dateOfBirth") }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{ ...fieldStyle, width: "100% !important" }}
                          label="Date of Birth"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            readOnly: view,
                          }}
                        />
                      )}
                      minDate={minDate}
                      maxDate={maxDate}
                    />
                  </LocalizationProvider>
                </Grid>

                {errors.dateOfBirth && (
                  <Typography variant="caption" style={{ color: "red", width: "50%", marginLeft: "10px" }}>
                    {errors.dateOfBirth}
                  </Typography>
                )}
              </div>


              {!view ? (
                <DialogActions
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    width: "100%",
                    gap: "14px",
                    marginTop: "28px",
                    padding: "8px",
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      textTransform: 'none',
                      backgroundColor: 'white',
                      color: colorConfigs?.sidebar?.bg,
                      borderRadius: '10px',
                      width: "150px",
                      padding: '10px 20px',
                      '&:hover': {
                        backgroundColor: '#E6E9F2',
                      },
                    }}
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>

                  <Button
                    variant="contained"
                    sx={{
                      textTransform: 'none',
                      backgroundColor: process.env.REACT_APP_ENV === 'women_first' ? colorConfigs?.sidebar?.bg : '#005e8b',
                      color: 'white',
                      borderRadius: '10px',
                      width: "150px",
                      padding: '10px 20px',
                      '&:hover': {
                        backgroundColor: process.env.REACT_APP_ENV === 'women_first' ? colorConfigs?.sidebar?.bg : '#004b6e',
                      },
                    }}
                    onClick={showModal}
                  >
                    Save
                  </Button>
                </DialogActions>
              ) : (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    gap: "14px",
                    marginTop: "28px",
                    padding: "8px",
                  }}
                >
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: !activeApproveButton ? colorConfigs.sidebar.bg : 'gray',
                      color: "white",
                      width: "250px",
                      ...styles.buttonStyle,
                    }}
                    disabled={activeApproveButton}
                    onClick={handleSubmit}
                  >
                    Approve Driver
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    style={{
                      width: "200px",
                      ...styles.buttonStyle,
                    }}
                    onClick={handleReject}
                  >
                    {selectedObject?.account_status === "pending"
                      ? "Reject"
                      : "Update Reject"}
                  </Button>
                </div>
              )}
              {attemptedSubmit && selectedCarTypes?.length === 0 && (
                <span style={{ color: "red", marginLeft: "8px" }}>
                  You Must Choose One Ride Type From Vehicle Details
                </span>
              )}
            </Box>
          )}
          {valueTabs === "two" && (
            <Box component="div" sx={{ padding: "28px" }}>
              <Box
                display="flex"
                style={{ cursor: "pointer", marginBottom: '15px' }}
              >
                <Button
                  variant="outlined"
                  onClick={() => {
                    setView(false);

                  }}
                  style={{
                    borderTopLeftRadius: "88px",
                    borderBottomLeftRadius: "88px",
                    borderColor: colorConfigs?.sidebar?.bg,
                    color: !view ? "white" : "#42526E",
                    backgroundColor: !view ? colorConfigs?.sidebar?.bg : "white",
                    cursor: "pointer",
                  }}
                >
                  <DriveFileRenameOutlineSharpIcon fontSize="small" /> Edit{" "}
                </Button>

                <Button
                  variant="outlined"
                  onClick={() => {
                    handleCancel();
                  }}
                  style={{
                    borderTopRightRadius: "88px",
                    borderBottomRightRadius: "88px",
                    borderRight: "1px solid black",
                    borderColor: colorConfigs?.sidebar?.bg,
                    color: view ? "white" : colorConfigs?.sidebar?.bg,
                    backgroundColor: view ? colorConfigs?.sidebar?.bg : "white",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <VisibilityOutlinedIcon fontSize="small" /> View
                </Button>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  gap: "14px",
                  height: "270px",
                  justifyContent: "flex-start",
                  marginBottom: "25px",
                  paddingLeft: "3px",
                  paddingRight: '55px',
                  overflowX: "auto",
                  paddingBottom: "5px"
                }}
              >
                <style>
                  {`
                       ::-webkit-scrollbar {
                       height: 10px; 
                        }
                       `}
                </style>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                    width: "400px",
                    height: "200px",
                  }}
                >
                  <span style={styles.imageTitle}>Profile Photo</span>
                  <div style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                    width: "200px",
                    height: "200px",
                  }} >
                    {selectedObject?.profile_picture ?
                      (
                        <img
                          style={{ width: '100%', height: '100%', cursor: 'pointer', objectFit: 'fill' }}
                          src={selectedObject?.profile_picture}
                          alt=""
                          key={inputKey}
                          onClick={() => handleOpenLightbox(0)}
                        />
                      ) : (
                        <Box style={{
                          height: "100%",
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          border: "2px solid #BBBBBB7A ",

                        }}>
                          <Avatar
                            style={{ height: "90px", width: "90px" }}
                            icon={<UserOutlined />}
                          />
                        </Box>
                      )}


                    {!view && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-end",
                          marginTop: "-32px",
                          marginRight: "3px"
                        }}
                      >
                        <form onSubmit={() => { }} className="form">
                          <IconButton
                            component="label"
                            style={{
                              borderRadius: "10%",
                              width: "26px",
                              height: "26px",
                            }}
                          >
                            <AddAPhotoIcon style={{ color: colorConfigs.sidebar.bg }} />
                            <input
                              type="file"
                              hidden
                              style={{ display: "none" }}
                              multiple
                              size="20971520"
                              accept="image/png, image/jpeg"
                              onChange={(e) => handleImagrUrl(e, "profile_picture")}
                              onClick={(e) => (e.target.value = null)}
                            />
                          </IconButton>
                        </form>
                      </div>
                    )}
                    {errors.profile_picture && (
                      <Typography variant="caption" style={{ color: "red", marginBottom: "10px", marginTop: "5px" }}>
                        {errors.profile_picture}
                      </Typography>
                    )}
                  </div>

                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                    width: "400px",
                    height: "200px",
                  }}
                >
                  <span style={styles.imageTitle}>Driver license - Front </span>
                  <div style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                    width: "350px",
                    height: "200px",
                  }}>
                    {selectedObject?.driver_license_front ? (
                      <img
                        style={{ width: '100%', height: '100%', objectFit: 'fill', cursor: 'pointer' }}
                        src={selectedObject?.driver_license_front}
                        alt=""
                        onClick={() => handleOpenLightbox(1)}
                      />
                    ) : (
                      <Box style={{
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border: "2px solid #BBBBBB7A ",

                      }}>
                        <FaIdCard style={{ height: "100px", width: "100px", color: "#9999997A " }} />
                      </Box>
                    )}


                    {!view && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-end",
                          marginTop: "-32px",
                          marginRight: "3px"
                        }}
                      >
                        <form onSubmit={() => { }} className="form">
                          <IconButton
                            component="label"
                            style={{
                              borderRadius: "10%",
                              width: "26px",
                              height: "26px",
                            }}
                          >
                            <AddAPhotoIcon style={{ color: colorConfigs.sidebar.bg }} />
                            <input
                              type="file"
                              hidden
                              style={{ display: "none" }}
                              multiple
                              size="20971520"
                              accept="image/png, image/jpeg"
                              key={inputKey}
                              onChange={(e) => handleImagrUrl(e, "driver_license_front")}
                              onClick={(e) => (e.target.value = null)}
                            />
                          </IconButton>
                        </form>
                      </div>
                    )}
                    {errors.driver_license_front && (
                      <Typography variant="caption" style={{ color: "red", marginBottom: "10px", marginTop: "5px" }}>
                        {errors.driver_license_front}
                      </Typography>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                    width: "400px",
                    height: "200px",
                    marginLeft: "25px"
                  }}
                >
                  <span style={styles.imageTitle}>Driver license - Back</span>
                  <div style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                    width: "350px",
                    height: "200px",
                  }}>
                    {selectedObject?.driver_license_front ? (
                      <img
                        style={{ width: '100%', height: '100%', objectFit: 'fill', cursor: 'pointer' }}
                        src={selectedObject?.driver_license_back}
                        alt=""
                        onClick={() => handleOpenLightbox(2)}
                      />
                    ) : (
                      <Box style={{
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border: "2px solid #BBBBBB7A ",

                      }}>
                        <CreditCardOutlinedIcon style={{ height: "110px", width: "110px", color: "#9999997A" }} />
                      </Box>
                    )}

                    {!view && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-end",
                          marginTop: "-32px",
                          marginRight: "3px"
                        }}
                      >
                        <form onSubmit={() => { }} className="form">
                          <IconButton
                            component="label"
                            style={{
                              borderRadius: "10%",
                              width: "26px",
                              height: "26px",
                            }}
                          >
                            <AddAPhotoIcon style={{ color: colorConfigs.sidebar.bg }} />
                            <input
                              type="file"
                              hidden
                              style={{ display: "none" }}
                              multiple
                              size="20971520"
                              accept="image/png, image/jpeg"
                              key={inputKey}
                              onChange={(e) => handleImagrUrl(e, "driver_license_back")}
                              onClick={(e) => (e.target.value = null)}
                            />
                          </IconButton>
                        </form>
                      </div>
                    )}
                    {errors.driver_license_back && (
                      <Typography variant="caption" style={{ color: "red", marginBottom: "10px", marginTop: "5px" }}>
                        {errors.driver_license_back}
                      </Typography>
                    )}
                  </div>
                  {lightboxIndex >= 0 && (
                    <Lightbox
                      mainSrc={images[lightboxIndex]}
                      nextSrc={images[(lightboxIndex + 1) % images.length]}
                      prevSrc={images[(lightboxIndex + images.length - 1) % images.length]}
                      onCloseRequest={handleCloseLightbox}
                      onMovePrevRequest={handleMovePrev}
                      onMoveNextRequest={handleMoveNext}
                      reactModalStyle={{
                        overlay: {
                          zIndex: 1000,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '80%',
                          height: '90%',
                          // marginRight: "19%",
                          marginLeft: "19%",
                          marginTop: "35px"
                        },
                        content: {
                          display: 'flex',
                          width: '100%',
                          height: '100%',
                          justifyContent: 'center',
                          alignItems: 'center',
                        },
                      }}
                    />
                  )}
                </div>
              </Box>

              <Grid container spacing={3}>
                <Grid item lg={6} sm={12}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <TextInput
                    disabled={view}
                    name="license_number"
                    style={{ width: "90%", }}
                    label="ID (License Number)"
                    styles={fieldStyle}
                    value={selectedObject?.driver_license_info?.license_number}
                    onChange={(e) => { handleChangeData(e) }}
                  />
                  {errors.license_number && (
                    <Typography variant="caption" style={{ color: "red", marginBottom: "10px", marginTop: "5px" }}>
                      {errors.license_number}
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={6} sm={12} style={{ display: "flex", flexDirection: "column" }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      name="iss"
                      label="Iss Date" disabled={view}
                      value={selectedObject?.driver_license_info?.iss ? new Date(selectedObject?.driver_license_info?.iss) : null}
                      sx={{ ...fieldStyle, width: "90% !important" }}
                      onChange={(e) => { handleChangeDate(e, "iss") }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{ ...fieldStyle, width: "90% !important" }}
                          label="Iss Date"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            readOnly: view,
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                  {errors.iss && (
                    <Typography variant="caption" style={{ color: "red", marginBottom: "10px", marginTop: "5px" }}>
                      {errors.iss}
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={6} sm={12} style={{ display: "flex", flexDirection: "column" }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      name="exp"
                      label="Exp Date" disabled={view}
                      value={selectedObject?.driver_license_info?.exp ? new Date(selectedObject?.driver_license_info?.exp) : null}
                      sx={{ ...fieldStyle, width: "90% !important" }}
                      onChange={(e) => { handleChangeDate(e, "exp") }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{ ...fieldStyle, width: "90% !important" }}
                          label="Exp Date"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            readOnly: view,
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                  {errors.exp && (
                    <Typography variant="caption" style={{ color: "red", marginBottom: "10px", marginTop: "5px" }}>
                      {errors.exp}
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={6} sm={12} style={{ display: "flex", flexDirection: "column" }}>
                  <TextInput
                    onChange={(e) => { handleChangeData(e) }}
                    disabled={view}
                    name="class"
                    style={{ width: "90%" }}
                    label="Class"
                    value={selectedObject?.driver_license_info?.class}
                    styles={fieldStyle}
                  />
                  {errors.class && (
                    <Typography variant="caption" style={{ color: "red", marginBottom: "10px", marginTop: "5px" }}>
                      {errors.class}
                    </Typography>
                  )}
                </Grid>
              </Grid>
              {!view ? (
                <DialogActions
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    width: "100%",
                    gap: "14px",
                    marginTop: "28px",
                    padding: "8px",
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      textTransform: 'none',
                      backgroundColor: 'white',
                      color: colorConfigs?.sidebar?.bg,
                      borderRadius: '10px',
                      width: "150px",
                      padding: '10px 20px',
                      '&:hover': {
                        backgroundColor: '#E6E9F2',
                      },
                    }}
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>

                  <Button
                    variant="contained"
                    sx={{
                      textTransform: 'none',
                      backgroundColor: process.env.REACT_APP_ENV === 'women_first' ? colorConfigs?.sidebar?.bg : '#005e8b',
                      color: 'white',
                      borderRadius: '10px',
                      width: "150px",
                      padding: '10px 20px',
                      '&:hover': {
                        backgroundColor: process.env.REACT_APP_ENV === 'women_first' ? colorConfigs?.sidebar?.bg : '#004b6e',
                      },
                    }}
                    onClick={showModal}
                  >
                    Save
                  </Button>
                </DialogActions>
              ) : (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    gap: "14px",
                    marginTop: "28px",
                    padding: "8px",
                  }}
                >
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: !activeApproveButton ? colorConfigs.sidebar.bg : 'gray',
                      color: "white",
                      width: "250px",
                      ...styles.buttonStyle,
                    }}
                    disabled={activeApproveButton}
                    onClick={handleSubmit}
                  >
                    Approve Driver
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    style={{
                      width: "200px",
                      ...styles.buttonStyle,
                    }}
                    onClick={handleReject}
                  >
                    {selectedObject?.account_status === "pending"
                      ? "Reject"
                      : "Update Reject"}
                  </Button>
                </div>
              )}
              {attemptedSubmit && selectedCarTypes.length === 0 && (
                <span style={{ color: "red" }}>
                  You Must Choose One Ride Type From Vehicle Details
                </span>
              )}
            </Box>
          )}
          {valueTabs === "three" && (

            <Box component="div" sx={{ padding: "28px" }}>
              <Box
                display="flex"
                style={{ cursor: "pointer", marginBottom: '15px', marginLeft: "10px" }}
              >
                <Button
                  variant="outlined"
                  onClick={() => {
                    setView(false);

                  }}
                  style={{
                    borderTopLeftRadius: "88px",
                    borderBottomLeftRadius: "88px",
                    borderColor: colorConfigs?.sidebar?.bg,
                    color: !view ? "white" : "#42526E",
                    backgroundColor: !view ? colorConfigs?.sidebar?.bg : "white",
                    cursor: "pointer",
                  }}
                >
                  <DriveFileRenameOutlineSharpIcon fontSize="small" /> Edit{" "}
                </Button>

                <Button
                  variant="outlined"
                  onClick={() => {
                    handleCancel();
                  }}
                  style={{
                    borderTopRightRadius: "88px",
                    borderBottomRightRadius: "88px",
                    borderRight: "1px solid black",
                    borderColor: colorConfigs?.sidebar?.bg,
                    color: view ? "white" : colorConfigs?.sidebar?.bg,
                    backgroundColor: view ? colorConfigs?.sidebar?.bg : "white",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <VisibilityOutlinedIcon fontSize="small" /> View
                </Button>
              </Box>
              <Grid item xs={12}>
                <Box style={{ ...imgsBox, display: "flex", overflowX: "auto", paddingBottom: "5px" }}>
                  <style>
                    {`
                       ::-webkit-scrollbar {
                       height: 10px; 
                        }
                       `}
                  </style>
                  {selectedObject?.vehicle_info?.vehicle_images &&
                    selectedObject?.vehicle_info?.vehicle_images.map((src, index) => (
                      <Grid
                        item
                        xs={4}
                        key={index}
                        style={{
                          position: "relative",
                          marginRight: "10px",
                          display: "flex",
                          marginLeft: "10px",
                          marginTop: "10px",
                        }}
                      >
                        <img
                          src={src}
                          alt={`alt-${index}`}
                          style={{
                            width: "240px",
                            height: "200px",
                            borderRadius: "8px",
                            cursor: "pointer",
                            marginRight: "10px",
                          }}
                          onClick={() =>
                            selectedObject?.vehicle_info?.vehicle_images[index] &&
                            handleImageClick(index)
                          }
                        />
                        {!view && (
                          <button
                            onClick={() => handleDelete(index)}
                            style={{
                              position: "absolute",
                              top: "5px",
                              left: "5px",
                              background: "none",
                              border: "none",
                              cursor: "pointer",
                              color: "red",
                            }}
                          >
                            &#x2716;
                          </button>
                        )}
                      </Grid>
                    ))}
                  <Dialog
                    open={isDialogOpen}
                    onClose={handleCloseGalary}
                    sx={{
                      "& .MuiDialog-paper": {
                        width: "800px",
                        height: "500px",
                        margin: "auto",
                        maxWidth: "unset",
                        overflow: "hidden",
                      }
                    }}
                  >
                    <ImageGallery
                      items={galaryImages}
                      startIndex={startIndex}
                      showThumbnails={false}
                      showPlayButton={false}
                      showFullscreenButton={false}
                      additionalClass="custom-image-gallery"
                    />
                  </Dialog>
                  {selectedObject?.vehicle_info?.vehicle_images?.length < 4 && !view && (
                    <Box style={{ display: 'flex', flexDirection: "column" }}>
                      <Grid
                        item
                        xs={4}
                        style={{
                          width: "240px",
                          height: "200px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          border: "1px dashed black",
                          gap: "12px",
                          marginLeft: "10px",
                          borderRadius: "8px",
                          backgroundColor: "#F5F5F5",
                          marginTop: "10px",
                        }}
                      >
                        <IconButton
                          component="label"
                          style={{
                            color: colorConfigs.sidebar.bg,
                          }}
                        >
                          <LuUpload size={40} />
                          <input
                            type="file"
                            hidden
                            style={{ display: "none" }}
                            multiple
                            size="20971520"
                            accept="image/png, image/jpeg"
                            key={inputKey}
                            onChange={(e) => handleImagrUrl(e, "vehicle_images")}
                            onClick={(e) => (e.target.value = null)}
                          />
                        </IconButton>
                        <Typography color={"#7A869A"}>Tap To Upload</Typography>
                      </Grid>
                      {errors.vehicle_images && (
                        <Typography variant="caption" style={{ color: "red", marginTop: "5px", marginLeft: "10px" }}>
                          {errors.vehicle_images}
                        </Typography>
                      )}
                    </Box>
                  )}
                </Box>
              </Grid>
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: 1, width: "97%" },
                }}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "14px",
                }}
                noValidate
                autoComplete="off"
              >
                <div
                  style={{
                    display: "flex",
                    columnGap: "14px",
                    paddingTop: "15px"
                  }}
                >
                  <FormControl style={{ flex: "1.035", paddingTop: "8px", paddingBottom: "8px", width: "100%" }} sx={{
                    "& label.Mui-focused": {
                      color: "rgba(0, 0, 0, 0.6)",
                      bordercolor: "#BBC1CE",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#BBC1CE",
                      },
                      "&:hover fieldset": {
                        borderColor: "#BBC1CE",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#BBC1CE",
                        borderWidth: "1px",
                      },
                    },
                  }}>
                    <InputLabel
                      id="vehicle-type-selector-label"
                      style={{
                        paddingTop: "5px",
                        fontFamily: "poppins",
                        fontWeight: "400",
                        fontSize: "17px",
                        lineHeight: "32px",
                        marginLeft: "5px"

                      }}
                    >
                      Vehicle Type
                    </InputLabel>
                    <Select
                      label="Vehicle Type"
                      style={{
                        marginLeft: "8px",
                        marginRight: "8px",
                        borderColor: "#BBC1CE",
                        borderWidth: 1,
                        borderRadius: "8px",

                      }}
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      disabled={view}
                      labelId="vehicle-type-selector-label"
                      id="vehicle-type-selector2"
                      // value={vehicleType}
                      value={selectedObject?.vehicle_info?.vehicle_type}
                      onChange={handleVehicleTypeChange}
                      input={<OutlinedInput label="Vehicle Type" />}

                    >
                      {vehicleTypes.map((type) => (
                        <MenuItem key={type.value} value={type.value}>
                          {type.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.vehicle_type && (
                      <Typography variant="caption" style={{ color: "red", marginLeft: "10px", marginTop: "5px" }}>
                        {errors.vehicle_type}
                      </Typography>
                    )}

                  </FormControl>
                  <FormControl style={{ flex: "1.035", paddingTop: "8px", paddingBottom: "8px", width: "100%" }} sx={{
                    "& label.Mui-focused": {
                      color: "rgba(0, 0, 0, 0.6)",
                      bordercolor: "#BBC1CE",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#BBC1CE",
                      },
                      "&:hover fieldset": {
                        borderColor: "#BBC1CE",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#BBC1CE",
                        borderWidth: "1px",
                      },
                    },
                  }}>
                    <InputLabel
                      id="vehicle-type-selector-label"
                      style={{
                        paddingTop: "5px",
                        fontFamily: "poppins",
                        fontWeight: "400",
                        fontSize: "17px",
                        lineHeight: "32px",
                        marginLeft: "5px"
                      }}
                    >
                      Allowed Ride Types
                    </InputLabel>
                    <Select
                      label="Allowed Ride Types"
                      style={{
                        marginLeft: "8px",
                        marginRight: "8px",
                        borderColor: "#BBC1CE",
                        borderWidth: 1,
                        borderRadius: "8px",
                      }}
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      labelId="vehicle-type-selector-label"
                      id="vehicle-type-selector2"
                      multiple
                      // value={selectedCarTypes.map(item => item.name)}
                      value={selectedObject?.car_type?.map(item => item.name)}
                      onChange={handleCarTypeChange}
                      input={<OutlinedInput label="Allowed Ride Types" />}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return <em style={{ color: colorConfigs.sidebar.bg }}>Select Types</em>;
                        }
                        return selected.join(', ');
                      }}
                      disabled={caTypeList.filter(caType => caType.enabled).length === 0 || view}
                    >
                      {caTypeList
                        .filter(caType => caType.enabled)
                        .map((caType) => (
                          <MenuItem key={caType._id} value={caType.name}>
                            <Checkbox
                              style={{
                                color: selectedObject?.car_type?.map(item => item.name).indexOf(caType.name) > -1 ? colorConfigs.sidebar.bg : 'default',
                              }}
                              sx={{
                                '&.Mui-checked': {
                                  color: colorConfigs.sidebar.bg,
                                },
                              }}
                              checked={selectedObject?.car_type?.map(item => item.name).indexOf(caType.name) > -1}
                            />
                            <ListItemText primary={caType.name} />
                          </MenuItem>
                        ))}
                    </Select>
                    {errors.car_type && (
                      <Typography variant="caption" style={{ color: "red", marginLeft: "10px", marginTop: "5px" }}>
                        {errors.car_type}
                      </Typography>
                    )}
                    {caTypeList.filter(caType => caType.enabled).length === 0 && (
                      <span style={{ color: "red", marginLeft: "8px" }}>
                        No Active Ride types
                      </span>
                    )}
                    {attemptedSubmit && selectedCarTypes.length === 0 && caTypeList.filter(caType => caType.enabled).length > 0 && (
                      <span style={{ color: "red", marginLeft: "8px" }}>
                        You must choose at least one ride type
                      </span>
                    )}
                  </FormControl>
                </div>
                <Grid container spacing={2} >
                  <Grid item xs={6} >
                    <TextInput
                      name="license_plate"
                      style={{ flex: "1", background: "white" }}
                      label="Registration Number"
                      value={selectedObject?.vehicle_info?.license_plate}
                      disabled={view}
                      styles={fieldStyle}
                      onChange={(e) => handleChangeData(e, "license_plate")}
                    />
                    {errors.license_plate && (
                      <Typography variant="caption" style={{ color: "red", marginLeft: "10px", marginTop: "5px" }}>
                        {errors.license_plate}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <TextInput
                      name="vehicle_make"
                      onChange={(e) => handleChangeData(e, "vehicle_make")}
                      style={{ flex: "1", background: "white" }}
                      label="Vehicle Make"
                      value={selectedObject?.vehicle_info?.vehicle_make}
                      disabled={view}
                      styles={fieldStyle}
                    /> {errors.vehicle_make && (
                      <Typography variant="caption" style={{ color: "red", marginLeft: "10px", marginTop: "5px" }}>
                        {errors.vehicle_make}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <TextInput
                      name="vehicle_model"
                      onChange={(e) => handleChangeData(e, "vehicle_model")}
                      style={{ flex: "1", background: "white" }}
                      label="Vehicle Model"
                      value={selectedObject?.vehicle_info?.vehicle_model}
                      disabled={view}
                      styles={fieldStyle}
                    />{errors.vehicle_model && (
                      <Typography variant="caption" style={{ color: "red", marginLeft: "10px", marginTop: "5px" }}>
                        {errors.vehicle_model}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <TextInput
                      name="vehicle_year"
                      onChange={(e) => handleChangeData(e, "vehicle_year")}
                      style={{ flex: "1", background: "white" }}
                      label="Vehicle Year"
                      value={selectedObject?.vehicle_info?.vehicle_year}
                      disabled={view}
                      styles={fieldStyle}
                    />{errors.vehicle_year && (
                      <Typography variant="caption" style={{ color: "red", marginLeft: "10px", marginTop: "5px" }}>
                        {errors.vehicle_year}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <TextInput
                      name="vehicle_color"
                      onChange={(e) => handleChangeData(e, "vehicle_color")}
                      style={{ flex: "1", background: "white" }}
                      label="Vehicle Color"
                      value={selectedObject?.vehicle_info?.vehicle_color}
                      disabled={view}
                      styles={fieldStyle}
                    />{errors.vehicle_color && (
                      <Typography variant="caption" style={{ color: "red", marginLeft: "10px", marginTop: "5px" }}>
                        {errors.vehicle_color}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                {!view ? (
                  <DialogActions
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      width: "100%",
                      gap: "14px",
                      marginTop: "28px",
                      padding: "8px",
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        textTransform: 'none',
                        backgroundColor: 'white',
                        color: colorConfigs?.sidebar?.bg,
                        borderRadius: '10px',
                        width: "150px",
                        padding: '10px 20px',
                        '&:hover': {
                          backgroundColor: '#E6E9F2',
                        },
                      }}
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>

                    <Button
                      variant="contained"
                      sx={{
                        textTransform: 'none',
                        backgroundColor: process.env.REACT_APP_ENV === 'women_first' ? colorConfigs?.sidebar?.bg : '#005e8b',
                        color: 'white',
                        borderRadius: '10px',
                        width: "150px",
                        padding: '10px 20px',
                        '&:hover': {
                          backgroundColor: process.env.REACT_APP_ENV === 'women_first' ? colorConfigs?.sidebar?.bg : '#004b6e',
                        },
                      }}
                      onClick={showModal}
                    >
                      Save
                    </Button>
                  </DialogActions>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      gap: "14px",
                      marginTop: "28px",
                      padding: "8px",
                    }}
                  >
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: !activeApproveButton ? colorConfigs.sidebar.bg : 'gray',
                        color: "white",
                        width: "250px",
                        ...styles.buttonStyle,
                      }}
                      disabled={activeApproveButton}
                      onClick={handleSubmit}
                    >
                      Approve Driver
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      style={{
                        width: "200px",
                        ...styles.buttonStyle,
                      }}
                      onClick={handleReject}
                    >
                      {selectedObject?.account_status === "pending"
                        ? "Reject"
                        : "Update Reject"}
                    </Button>
                  </div>
                )}
              </Box>
            </Box>
          )}
          {valueTabs === "four" && (
            <>
              <Box
                display="flex"
                style={{ cursor: "pointer", marginTop: "15px" }}
              >
                <Button
                  variant="outlined"
                  onClick={() => {
                    setView(false);

                  }}
                  style={{
                    borderTopLeftRadius: "88px",
                    borderBottomLeftRadius: "88px",
                    borderColor: colorConfigs?.sidebar?.bg,
                    color: !view ? "white" : "#42526E",
                    backgroundColor: !view ? colorConfigs?.sidebar?.bg : "white",
                    cursor: "pointer",
                  }}
                >
                  <DriveFileRenameOutlineSharpIcon fontSize="small" /> Edit{" "}
                </Button>

                <Button
                  variant="outlined"
                  onClick={() => {
                    handleCancel();
                  }}
                  style={{
                    borderTopRightRadius: "88px",
                    borderBottomRightRadius: "88px",
                    borderRight: "1px solid black",
                    borderColor: colorConfigs?.sidebar?.bg,
                    color: view ? "white" : colorConfigs?.sidebar?.bg,
                    backgroundColor: view ? colorConfigs?.sidebar?.bg : "white",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <VisibilityOutlinedIcon fontSize="small" /> View
                </Button>
              </Box>
              {!view && (
                <Box style={{ display: 'flex', flexDirection: "row" }}>
                  {driverDocs && driverDocs.length > 0 ? (
                    driverDocs
                      .filter(doc => doc.enable && doc.type === "Image") // Check both enable and type
                      .filter(doc => {
                        const selectedDocs = selectedObject.documents;

                        return !selectedDocs || !Object.keys(selectedDocs).some(key => key === doc.key);
                      }).length > 0 ? (
                      driverDocs
                        .filter(doc => doc.enable && doc.type === "Image") // Check both enable and type
                        .filter(doc => {
                          const selectedDocs = selectedObject.documents;
                          return !selectedDocs || !Object.keys(selectedDocs).some(key => key === doc.key);
                        })
                        .map((doc, index) => (
                          <Grid
                            key={index}
                            item
                            xs={4}
                            style={{
                              width: "200px",
                              height: "150px",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              border: "1px dashed black",
                              gap: "12px",
                              borderRadius: "8px",
                              backgroundColor: "#F5F5F5",
                              marginTop: "15px",
                              marginRight: "10px"
                            }}
                          >
                            <IconButton
                              component="label"
                              style={{
                                color: colorConfigs.sidebar.bg,
                              }}
                            >
                              <LuUpload size={30} />
                              <input
                                type="file"
                                hidden
                                style={{ display: "none" }}
                                multiple
                                size="20971520"
                                accept="image/png, image/jpeg"
                                key={inputKey}
                                onChange={(e) => handleImagrUrl(e, doc.key)}
                                onClick={(e) => (e.target.value = null)}
                              />
                            </IconButton>
                            <Typography color={"#7A869A"}>Tap To Upload</Typography>
                            <Typography color={"#7A869A"}>{doc.key}</Typography>
                          </Grid>
                        ))
                    ) : (
                      <Typography color={"#7A869A"} style={{ margin: "20px auto" }}>
                        No documents available to add.
                      </Typography>
                    )
                  ) : (
                    <Typography color={"#7A869A"} style={{ margin: "20px auto" }}>
                      No driver documents found.
                    </Typography>
                  )}
                </Box>

              )}

              <Grid container spacing={2} sx={{ marginTop: '25px' }}>
                {imageFiles.length > 0 && (
                  <Grid container item spacing={2}>
                    {renderFiles(imageFiles)}
                  </Grid>
                )}
                {otherFiles.length > 0 && (
                  <Grid container item spacing={2}>
                    {renderFiles(otherFiles)}
                  </Grid>
                )}
                {imageFiles.length === 0 && otherFiles.length === 0 && (
                  <Grid item xs={12}>
                    <Typography
                      style={{
                        marginBottom: '10px',
                        fontFamily: "Poppins",
                        fontWeight: "600",
                        fontSize: "16px",
                        lineHeight: '16px',
                        color: '#172B4D'
                      }}
                    >
                      No Additional Requirements For This Driver.
                    </Typography>
                  </Grid>
                )}
              </Grid>
              {!view && (
                <DialogActions
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    width: "100%",
                    gap: "14px",
                    marginTop: "28px",
                    padding: "8px",
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      textTransform: 'none',
                      backgroundColor: 'white',
                      color: colorConfigs?.sidebar?.bg,
                      borderRadius: '10px',
                      width: "150px",
                      padding: '10px 20px',
                      '&:hover': {
                        backgroundColor: '#E6E9F2',
                      },
                    }}
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>

                  <Button
                    variant="contained"
                    sx={{
                      textTransform: 'none',
                      backgroundColor: process.env.REACT_APP_ENV === 'women_first' ? colorConfigs?.sidebar?.bg : '#005e8b',
                      color: 'white',
                      borderRadius: '10px',
                      width: "150px",
                      padding: '10px 20px',
                      '&:hover': {
                        backgroundColor: process.env.REACT_APP_ENV === 'women_first' ? colorConfigs?.sidebar?.bg : '#004b6e',
                      },
                    }}
                    onClick={showModal}
                  >
                    Save
                  </Button>
                  {errors.docs && (
                    <Typography variant="caption" style={{ color: "red", marginTop: "5px", marginLeft: "10px" }}>
                      {errors.docs}
                    </Typography>
                  )}
                </DialogActions>
              )}
            </>
          )}
        </div>
      </Box>

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          The data is saved successfully!
        </Alert>
      </Snackbar>
      <div style={{ height: "30px" }} />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleSnackbarClose} severity="error">
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Loading
        loading={!!loading}
        background="rgba(86, 100, 210, 0.1)"
        loaderColor={colorConfigs.sidebar?.bg}
      />
    </>
  );
};
export default RequestsDetails;
