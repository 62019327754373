
import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ThemeProvider,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  tooltipClasses,
} from "@mui/material";
import { makeStyles, styled } from "@mui/styles";
import { Modal, Switch } from "antd";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { FaCircleInfo, FaInfinity } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import speed from "../../../assets/speed.png";
import colorConfigs from "../../../configs/colorConfigs";
import { auth } from "../../../configs/firebase";
import {
  setCarType,
  setDivisions,
  setPlan,
  updateForm,
  updateFormData,
} from "../../../redux/features/appStateSlice";
import { getMethod, postMethod } from "../../Hook/UseCustomApi";
import AddCarType from "./../AddCarType";
import "./../index.css";
import usePrompt from "../../Hook/usePrompt";
import { MdDelete } from "react-icons/md";
import Swal from "sweetalert2";
import * as XLSX from 'xlsx';
import { LuUpload } from "react-icons/lu";
import { IoMdAdd } from "react-icons/io";
import BlueButton from "../../../components/common/Buttons/BlueButton";
import { useLocation } from "react-router-dom";
import Error5xx from "../../../components/common/HandelError5xx";
const Input = styled('input')({
  display: 'none',
});
const useStyles = makeStyles({
  headerCell: {
    backgroundColor: process.env.REACT_APP_ENV === 'women_first' ? '#F4E8F7' : colorConfigs.sidebar.ligthBg, // Light purple background for header cells
    color: colorConfigs.sidebar.bg, // Dark purple text color for header cells
    fontWeight: 'bold',
    padding: '7px 10px',
    minWidth: 130,
    borderTopLeftRadius: 7,
    borderBottomLeftRadius: 7
  },
  oddCell: {
    backgroundColor: process.env.REACT_APP_ENV === 'women_first' ? '#F6F2FE' : colorConfigs.sidebar.ligthBg, // Light purple background for odd cells
    color: '#495974', // Black text color for body cells
    borderRadius: 2,
    fontSize: 14,
    fontWeight: 700,
    textAlign: 'start',
    padding: '5px 8px',
    minWidth: 70,

  },
  evenCell: {
    backgroundColor: '#F5F5F5', // White background for even cells
    color: '#495974', // Black text color for body cells
    borderRadius: 2,
    fontSize: 14,
    fontWeight: 700,
    textAlign: 'start',
    padding: '5px 8px',
    minWidth: 70,
  },
  tableContainer: {
    borderCollapse: 'separate',
    borderSpacing: '0 5px', // Gap between rows
  },
  rootBox: {
    fontFamily: "inter",
    fontSize: "small",
    marginTop: "10px",
    borderRadius: "8px",
    padding: "6px",
    width: "100%",
    marginBottom: "20px",
    color: colorConfigs?.sidebar?.bg,
    backgroundColor: "#F4F4F4",
  },
  flexContainer: {
    display: "flex",
    gap: "20px",
    fontSize: "16px",
    color: "var(--Dark, #172b4d)",
    fontWeight: 600,
    lineHeight: "140%",
    flexDirection: "row", // Ensure items are displayed in a row
    flexWrap: "wrap"
  },
  categoryBox: {
    borderRadius: "8px",
    backgroundColor: process.env.REACT_APP_ENV === 'women_first' ? '#F9F6FF' : "#F1FAFF",

    display: "flex",
    flexDirection: "row", // Align items in a row
    padding: "2px 8px",
    gap: "10px", // Space between checkboxes
  },
  buttonStyle: {
    backgroundColor: colorConfigs?.sidebar?.bg,
  },
  checkbox: {
    color: colorConfigs?.sidebar.bg,
    "&.Mui-checked": {
      color: colorConfigs?.sidebar.bg,
    },
  },
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  speedCircle: {
    backgroundColor: "#E59700",
    color: "white",
    borderRadius: "50%",
    padding: "8px",
    textAlign: "center",
    width: "35px",
    height: "35px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "8px",
    marginLeft: "30px",
  },
  item: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },
  dot: {
    height: 10,
    width: 10,
    borderRadius: "50%",
  },
  orangeDot: {
    backgroundColor: "#E59700",
  },
  blueDot: {
    backgroundColor: colorConfigs?.sidebar?.bg,
  },
  customTooltip: {
    background: colorConfigs?.sidebar?.bg,
    [`& .${tooltipClasses.arrow}`]: {
      color: colorConfigs?.sidebar?.bg,
    },
  },

});

const EditPlane = ({ handleCancelEdit, handleClear, setEditVisible }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.appState.formDataDoc);
  const formDataAdd = useSelector((state) => state.appState.formData);
  const carType = useSelector((state) => state.appState.carType);
  const divisions = useSelector((state) => state.appState.divisions);
  const plan = useSelector((state) => state.appState.plan);
  const [isFormDirty, setIsFormDirty] = useState(true);
  const theme = useTheme();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const enableEdit = location.pathname.includes('edit')
  const [isDriverPreReq, setIsDriverPreReq] = useState(
    carType?.is_schedule_ride
  );
  const [taxType, setTaxType] = useState(carType?.service?.type);
  const [selectedCarTypes, setSelectedCarTypes] = useState(carType?.type);
  const [genderMale, setGenderMale] = useState(process.env.REACT_APP_ENV === 'women_first' ? false : carType?.male);
  const [genderFemale, setGenderFemale] = useState(process.env.REACT_APP_ENV === 'women_first' ? true : carType?.fmale);
  const [actionType, setActionType] = useState("");
  const [jsonResult, setJsonResult] = useState(carType?.flatRate.length > 0 ? carType?.flatRate : []);
  const [formErrors, setErrors] = useState({});
  const [file, setFile] = useState(null);
  const [open, setOpen] = useState(false);
  const [newLabel, setNewLabel] = useState("");
  const [currentIndex, setCurrentIndex] = useState(null);
  const [error, setError] = useState("");
  const [openSpeedDialog, setOpenSpeedDialog] = useState(false);
  const [currentSpeedLabel, setCurrentSpeedLabel] = useState("");
  const [currentSpeedIndex, setCurrentSpeedIndex] = useState(-1);
  const [currentDivisionIndex, setCurrentDivisionIndex] = useState(null);
  const [speedError, setSpeedError] = useState("");
  const [costPerMin, setCostPerMin] = useState("");
  const [costPerMil, setCostPerMil] = useState("");
  const [isInfiniteSpeed, setIsInfiniteSpeed] = useState(false);
  const [carTypes, setCarTypes] = useState([]);
  useEffect(() => {
    fetchCarTypeList();
  }, []);
  useEffect(() => {
    dispatch(setPlan(carType?.plans));
    return () => {
      dispatch(
        setPlan([
          {
            start_distance: 0,
            end_distance: 9999,
            speeds: [],
            value: 9999,
          },
        ])
      );
    };
  }, [carType]);
  // useEffect(() => {
  //   let updatedDivisions = [...divisions];
  //   carType?.plans.forEach((divisionPlan) => {
  //     if (divisionPlan?.start_distance !== 0) {
  //       // let updatedDivisions = divisions.slice();
  //       const distance = divisionPlan?.start_distance;
  //       const newKey = `div-${updatedDivisions.length}`;
  //       updatedDivisions.splice(updatedDivisions.length - 1, 0, {
  //         key: newKey,
  //         value: 0,
  //         label: distance,
  //       });
  //       const interval = 9999 / (updatedDivisions.length - 1);
  //       updatedDivisions = updatedDivisions.map((division, index) => ({
  //         ...division,
  //         value: Math.round(interval * index),
  //       }));
  //     }
  //   });
  //   // Dispatch setDivisions with the final updated divisions
  //   if (updatedDivisions.length > 2) {
  //     dispatch(setDivisions(updatedDivisions));
  //   }
  //   return () => {
  //     dispatch(
  //       setDivisions([
  //         { key: "start", value: 0, label: "0" },
  //         { key: "end", value: 9999, label: "9999" },
  //       ])
  //     );
  //   };
  // }, [carType]);
  const handleCarTypeChange = (carType) => {
    if (selectedCarTypes?.some((type) => type._id === carType?._id)) {
      setSelectedCarTypes(
        selectedCarTypes?.filter((type) => type?._id !== carType?._id)
      );
    } else {
      setSelectedCarTypes([...selectedCarTypes, carType]);
    }
  };

  const handleChangPreReq = (checked) => {
    setIsDriverPreReq(checked);
  };

  //handle error5xx
  const [tryAgain, setTryAgain] = useState();
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [errorCount, setErrorCount] = useState(0);
  const [errorDialogCounter, setErrorDialogCounter] = useState(0);
  const handleError5xx = () => {
    setIsErrorDialogOpen(true);
    setErrorCount(prevCount => {
      const newCount = prevCount + 1;
      if (newCount === 1) {
        setErrorDialogCounter(5);
      } else if (newCount === 2) {
        setErrorDialogCounter(10);
      }
      return newCount;
    });
  };
  const handleCloseErrorDialog = () => {
    setIsErrorDialogOpen(false);
    if (errorCount <= 2) {
      handleErrorCountByClose()
    }

  };
  const handleErrorCountByClose = () => {
    setErrorCount(prevCount => {
      const newCount = prevCount - 1;
      if (newCount === 1) {
        setErrorDialogCounter(5);
      } else if (newCount === 2) {
        setErrorDialogCounter(10);
      }
      return newCount;
    });
  }

  const handleSubmit = async (values) => {
    setErrors({});
    let error = {};
    if (values.base_cost <= 0) {
      error.base_cost = "Base cost should be 1 or more.";
    } else if (values?.min_cost <= 0) {
      error.min_cost = "Min cost should be 1 or more.";
    } else if (values.min_cost <= values.base_cost) {
      error.min_cost = "Min cost should be more than Base cost.";
    } else if (values.waiting_time < 0 || values.waiting_time < 0) {
      error.waiting_time = "Waiting time should be zero or more.";
    } else if (values.company_percentage < 0) {
      error.company_percentage = "Company Percentage should be more than zero.";
    } else if (values.company_percentage > 100) {
      error.company_percentage = "Company Percentage should be less than 100";
    } else if (values.waiting_time_cost < 0) {
      error.waiting_time_cost = "Waiting time cost should be zero or more.";
    } else if (isDriverPreReq && values.schedule_ride_time < 0) {
      error.schedule_ride_time =
        "Before ride time pickup should be zero or more.";
    } else if (values.plan_cost_distance <= 0) {
      error.plan_cost_distance = "Distance cost should be more than zero.";
    }
    // else if (values.plan_cost_time <= 0) {
    //   error.plan_cost_time = "Time cost should be more than zero.";
    // }

    if (selectedCarTypes.length === 0) {
      error.car_type = "Please select at least one ride type.";
    }

    if (!genderMale && !genderFemale && process.env.REACT_APP_ENV !== 'women_first') {
      error.gender = "Please select at least one gender (Male or Female).";
    }

    // Check if each plan has infinity speed and cost per mile set
    // plan.forEach((divisionPlan, index) => {
    //   const hasInfinitySpeed = divisionPlan.speeds.some(
    //     (speed) => speed.end_speed === 9999
    //   );
    //   if (!hasInfinitySpeed) {
    //     error.plan = "Each plan must have at least one speed set to max.";
    //   }
    // });

    if (Object.keys(error).length > 0) {
      setErrors(error);
      console.warn(Object.keys(error));
    } else {
      const newCarType = {
        type: selectedCarTypes,
        male: process.env.REACT_APP_ENV === 'women_first' ? false : genderMale,
        fmale: process.env.REACT_APP_ENV === 'women_first' ? true : genderFemale,
        waiting_time: values.waiting_time,
        is_waiting_time: values.waiting_time > 0 ? true : false,
        waiting_time_cost: values.waiting_time_cost,
        service: {
          tax: values.service,
          type: taxType,
        },
        is_schedule_ride: isDriverPreReq,
        schedule_ride_time: values.schedule_ride_time,
        all_time: false,
        schedule: {},
        // plans: plan,
        flatDefault: values.flatDefault,
        basicDefault: values.basicDefault,
        basicFlatRate: {
          cost_per_min: values?.plan_cost_time,
          cost_per_distance: values?.plan_cost_distance
        },
        base_cost: values.base_cost || 0,
        min_cost: values.min_cost || 0,
        company_percentage: values.company_percentage / 100 || 0,
        isFlatRate: true,
        flatRate: jsonResult

      };
      if (checkDuplicateOrOverlap(newCarType)) {
        console.log(values)

        setErrors({
          ...formErrors,
          general:
            "Duplicate or overlapping combination of ride type and gender is not allowed.",
        });
      } else {
        const updatedCarTypes = enableEdit ? [...formData.car_type] : [...formDataAdd.car_type];
        if (carType?.index !== undefined) {
          // Update the existing car type at the specified index
          updatedCarTypes[carType?.index] = {
            ...updatedCarTypes[carType?.index], // Existing data
            ...newCarType, // New values
            service: {
              ...updatedCarTypes[carType?.index].service,
              ...newCarType.service,
            },

          };

          if (formErrors.flat_plan === 'Invalid File Please Try Again') {
            Swal.fire({
              title: "Message",
              text: "Do you want to save without flat plan?",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: colorConfigs.sidebar.bg,
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes"
            }).then((result) => {
              if (result.isConfirmed) {
                dispatch(
                  updateFormData({
                    ...formData,
                    car_type: updatedCarTypes,
                  })
                );
                dispatch(
                  updateForm({
                    ...formData,
                    car_type: updatedCarTypes,
                  })
                );
                handleClear();
                setEditVisible(false);
              }
            })
          } else {
            dispatch(
              updateFormData({
                ...formData,
                car_type: updatedCarTypes,
              })
            );
            dispatch(
              updateForm({
                ...formData,
                car_type: updatedCarTypes,
              })
            );
            handleClear();
            setEditVisible(false);
          }
        }

      }
    }
  };

  const checkDuplicateOrOverlap = (newCarType) => {
    if (formData.car_type.length <= 3) {
      return false;
    }
    const carTypeIndex = carType?.index;
    const filteredCarTypes = formData.car_type.filter(
      (_, index) => index !== carTypeIndex
    );
    return filteredCarTypes.some((carType) => {
      //   const typeOverlap = carType.type.some((type) =>
      //     newCarType.type.includes(type)
      //   );
      const typeOverlap = carType.type.some((carTypeObj) =>
        newCarType.type.some(
          (newCarTypeObj) => carTypeObj?._id === newCarTypeObj?._id
        )
      );
      const maleOverlap = carType?.male && newCarType?.male;
      const fmaleOverlap = carType?.fmale && newCarType?.fmale;
      return typeOverlap && (maleOverlap || fmaleOverlap);
    });
  };

  const fieldStyle = {
    "& label.Mui-focused": {
      color: "#172B4D !important",
      fontSize: "16px !important",
      fontWeight: "500 !important",
    },
    marginTop: "10px",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#BBC1CE",
        borderWidth: 1,
        borderRadius: "8px",
      },
      "&:hover fieldset": {
        borderColor: "#BBC1CE",
        borderWidth: 1,
        borderRadius: "8px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#BBC1CE",
        borderWidth: 1,
        borderRadius: "8px",
      },
      input: {
        "&::placeholder": {
          color: "#3A3541DE",
          opacity: 1,
          fontSize: 14,
        },
        padding: "12.5px 14px",
      },
    },
  };

  const handleAddClick = () => {
    setCurrentIndex(divisions.length - 1); // Set to last division point before 'End'
    setNewLabel("");
    setOpen(true);
  };

  const handleLabelClick = (index) => {
    setCurrentIndex(index);
    setNewLabel(divisions[index].label);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setNewLabel("");
    setError("");
  };

  const handleSaveDivision = () => {
    const labelNumber = parseInt(newLabel, 10);

    if (isNaN(labelNumber) || labelNumber < 0) {
      setError("Label must be a non-negative number.");
      return;
    }

    if (
      currentIndex !== 0 &&
      labelNumber <= parseInt(divisions[currentIndex - 1].label, 10)
    ) {
      setError("Each label must be greater than the previous label.");
      return;
    }

    if (
      currentIndex !== divisions.length - 1 &&
      labelNumber >= parseInt(divisions[currentIndex + 1].label, 10)
    ) {
      setError("Each label must be smaller than the next label.");
      return;
    }

    if (
      divisions.some(
        (division, index) =>
          division.label === newLabel && currentIndex !== index
      )
    ) {
      setError("Each label must be unique.");
      return;
    }

    let updatedDivisions = divisions.slice();
    if (currentIndex === divisions.length - 1) {
      // New division being added
      const newKey = `div-${updatedDivisions.length}`;
      updatedDivisions.splice(updatedDivisions.length - 1, 0, {
        key: newKey,
        value: 0,
        label: newLabel,
      });
    } else {
      // Updating existing division
      updatedDivisions[currentIndex] = {
        ...updatedDivisions[currentIndex],
        label: newLabel,
      };
    }

    const interval = 9999 / (updatedDivisions.length - 1);
    updatedDivisions = updatedDivisions.map((division, index) => ({
      ...division,
      value: Math.round(interval * index),
    }));

    const newPlan = updatedDivisions.slice(1).map((division, index) => ({
      start_distance: parseFloat(updatedDivisions[index].label),
      end_distance: parseFloat(division.label),
      speeds: plan[index] ? plan[index].speeds : [],
      value: division.value,
    }));

    dispatch(setDivisions(updatedDivisions));
    dispatch(setPlan(newPlan));
    handleClose();
  };



  const handleSaveSpeed = (edit) => {
    setSpeedError("");
    if (plan?.speeds?.length === 0) {
      setSpeedError("Please add at least one plan");
      return;
    }

    if (!currentSpeedLabel && !isInfiniteSpeed) {
      setSpeedError("Speed cannot be empty.");
      return;
    }
    if (currentSpeedLabel <= 0 && !isInfiniteSpeed) {
      setSpeedError("Speed should be more than zero.");
      return;
    }
    const speedValue = isInfiniteSpeed ? 9999 : parseInt(currentSpeedLabel, 10);
    if (isNaN(speedValue)) {
      setSpeedError("Please enter a valid number for speed.");
      return;
    }

    const costMinValue = parseFloat(costPerMin);
    const costMilValue = parseFloat(costPerMil);

    if (isNaN(costMinValue) || isNaN(costMilValue)) {
      setSpeedError("Please enter valid numbers for cost per minute and mile.");
      return;
    }
    if (costMinValue <= 0) {
      setSpeedError("Cost per minute should be more than zero");
      return;
    }
    if (costMilValue <= 0) {
      setSpeedError("Cost per mile should be more than zero");
      return;
    }

    if (plan[currentDivisionIndex]?.speeds?.some(speed => speed.end_speed === speedValue) && !edit && speedValue === 9999) {
      setSpeedError("Not allowed to have two infinity speeds");
      return;
    }
    if (plan[currentDivisionIndex]?.speeds?.some(speed => speed.end_speed === speedValue) && !edit && speedValue !== 9999) {
      setSpeedError("This speed already exists");
      return;
    }

    const updatedPlans = [...plan];
    const currentDivisionSpeeds = [
      ...updatedPlans[currentDivisionIndex].speeds,
    ];

    const newSpeed = {
      label: isInfiniteSpeed ? "infinity" : currentSpeedLabel,
      start_speed: 0, // temporary start_speed, will be adjusted after sorting
      end_speed: speedValue,
      cost_per_min: costMinValue,
      cost_per_mil: costMilValue,
    };

    if (currentSpeedIndex === -1) {
      currentDivisionSpeeds.push(newSpeed);
    } else {
      currentDivisionSpeeds[currentSpeedIndex] = newSpeed;
    }

    currentDivisionSpeeds.sort((a, b) => a.end_speed - b.end_speed);

    // Clone the speeds array and update start_speed values
    const clonedSpeeds = currentDivisionSpeeds.map((speed, index) => {
      const clonedSpeed = { ...speed };
      clonedSpeed.start_speed = index === 0 ? 0 : currentDivisionSpeeds[index - 1].end_speed;
      return clonedSpeed;
    });

    updatedPlans[currentDivisionIndex] = {
      ...updatedPlans[currentDivisionIndex],
      speeds: clonedSpeeds,
    };

    dispatch(setPlan(updatedPlans));
    setOpenSpeedDialog(false);
    setCurrentSpeedLabel("");
    setCostPerMin("");
    setCostPerMil("");
    setSpeedError("");
    setIsInfiniteSpeed(false);
  };



  const handleAddSpeedClick = (index) => {
    setCurrentDivisionIndex(index);
    setCurrentSpeedLabel("");
    setCurrentSpeedIndex(-1); // New speed
    setCostPerMin("");
    setCostPerMil("");
    setOpenSpeedDialog(true);
    setSpeedError("");
    setIsInfiniteSpeed(false); // Reset switch
  };

  const handleEditSpeedClick = (divisionIndex, speedIndex) => {
    const speed = plan[divisionIndex].speeds[speedIndex];
    setCurrentDivisionIndex(divisionIndex);
    setCurrentSpeedIndex(speedIndex);
    setCurrentSpeedLabel(speed.end_speed === 9999 ? "" : speed.label ? speed.label : speed.end_speed);
    setCostPerMin(speed.cost_per_min.toString());
    setCostPerMil(speed.cost_per_mil.toString());
    setOpenSpeedDialog(true);
    setIsInfiniteSpeed(speed.end_speed === 9999); // Set switch based on speed
  };

  const handleDeleteDivision = (index) => {
    if (index === 0 || index === divisions.length - 1) {
      // Prevent deletion of the start and end points
      return;
    }

    const updatedDivisions = divisions.filter((_, i) => i !== index);
    const interval = 9999 / (updatedDivisions.length - 1);
    const newDivisions = updatedDivisions.map((division, index) => ({
      ...division,
      value: Math.round(interval * index),
    }));

    const newPlan = newDivisions.slice(1).map((division, index) => ({
      start_distance: parseFloat(newDivisions[index].label),
      end_distance: parseFloat(division.label),
      speeds: plan[index] ? plan[index].speeds : [],
      value: division.value,
    }));

    dispatch(setDivisions(newDivisions));
    dispatch(setPlan(newPlan));
  };

  const handleDeleteSpeed = (divisionIndex, speedIndex) => {
    const updatedPlans = plan.slice();
    const updatedSpeeds = updatedPlans[divisionIndex].speeds.slice();

    updatedSpeeds.splice(speedIndex, 1);
    updatedPlans[divisionIndex] = {
      ...updatedPlans[divisionIndex],
      speeds: updatedSpeeds,
    };

    dispatch(setPlan(updatedPlans));
  };

  const [modalOpen, setModalOpen] = useState(false);
  const handleModalClose = () => {
    setModalOpen(false);
  };

  const [severity, setSeverity] = useState("error");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const handleModalOpen = (rideType = null) => {
    if (rideType) {
      setNewRideType({
        name: rideType.name,
        photo: rideType.photo,
        enabled: rideType.enabled,
        createdDate: rideType.createdDate,
      });
    } else {
      setNewRideType({
        name: "",
        photo: null,
        enabled: true,
        createdDate: new Date(),
      });
    }
    setModalOpen(true);
  };

  const getRidesParams = () => {
    return {
      limit: 50,
    };
  };

  const fetchCarTypeList = () => {
    const url = "admin/car-type/all";
    getMethod({
      url: url,
      params: getRidesParams(),
    })
      .then((response) => {
        let result = response?.data;
        console.log("Ride Types", result)
        setCarTypes(result);
      })
      .catch((error) => {
        console.error("Error fetching items:", error);
        if (error === "Internal Server Error: Server error occurred.") {
          handleError5xx();
          setTryAgain(() => fetchCarTypeList);
        }
      });
  };
  const handleAddRideType = async (values) => {
    try {
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("enabled", values.enabled);
      if (values.photo) {
        formData.append("photo", values.photo);
      }

      const tokenId = await auth?.currentUser?.getIdToken();
      const headers = {
        Authorization: tokenId,
      };

      await postMethod({
        url: `admin/car-type`,
        body: {
          name: values.name,
          image: values.photo,
          enabled: newRideType.enabled,
        },
        handleErrorMessage: showSnackbar,
        headers: headers,
      }).then(() => {
        setNewRideType({
          enabled: true,
          name: "",
          photo: null,
          createdDate: new Date(),
        });
      });
      fetchCarTypeList();
    } catch (error) {
      console.error("Error adding/updating ride type:", error);
      setSeverity("error");
      showSnackbar("Failed to add/update ride type");
      if (error === "Internal Server Error: Server error occurred.") {
        handleError5xx();
        setTryAgain(() => () => handleAddRideType(values));
      }
    } finally {
      setModalOpen(false);
    }
  };

  const [newRideType, setNewRideType] = useState({
    enabled: true,
    name: "",
    photo: null,
    createdDate: new Date(),
  });
  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const carTypeinitialValues = {
    name: "",
    photo: null,
    enabled: false,
    createdDate: new Date(),
  };

  const removeFlatPlan = (_id) => {
    setJsonResult([]);
    const updatedCarTypes = formData?.car_type.map((item, index) => {
      if (item._id === carType._id) {
        return {
          ...item,
          ...item.plans,
          flatRate: [],
          basicDefault: true,
          flatDefault: false,
          isFlatRate: true

        };
      }
      return item;
    });

    dispatch(setCarType(
      {
        ...carType,
        flatRate: [],
        basicDefault: true,
        flatDefault: false,
        isFlatRate: true

      }));
    dispatch(
      updateFormData({
        ...formData,
        car_type: updatedCarTypes,
      })
    );
  }
  const handleRemoveFile = () => {
    setFile(null);
    setErrors((prev) => ({ ...prev, flat_plan: undefined }));
    setJsonResult([]); // Reset the jsonResult state
    setLoading(false); // Reset the loading state
  };
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (
      selectedFile &&
      (selectedFile.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        selectedFile.type === "application/vnd.ms-excel")
    ) {
      setLoading(true);
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
        const rawJson = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });

        // Check headers
        const headers = rawJson[0];
        setErrors((prev) => ({ ...prev, flat_plan: undefined }));

        // Process rawJson to the desired format
        let result = [];
        let mileageSet = new Set();

        for (let i = 0; i < headers.length; i += 2) {
          let lastRate = null;

          for (let j = 1; j < rawJson.length; j++) {
            // Skip the first item for each column
            let mileage = rawJson[j][i];
            let rate = rawJson[j][i + 1];

            if (mileage !== undefined) {
              if (rate !== undefined) {
                if (typeof rate === 'string' && rate.includes("$")) {
                  rate = rate.replace("$", "");
                }

                if (typeof rate === 'string' && rate.includes("-")) {
                  rate = rate.split("-").map((r) => parseFloat(r));
                  if (rate.length === 1) {
                    rate = rate[0];
                  }
                }

                lastRate = rate;
              }

              // Handle mileage range
              if (typeof mileage === "string" && mileage.includes("-")) {
                const [start, end] = mileage.split("-").map(Number);
                for (let m = start; m <= end; m++) {
                  if (mileageSet.has(m)) {
                    setErrors((prev) => ({
                      ...prev,
                      flat_plan: `Invalid File: Redundant mileage found (${mileage}) at row : ${j + 1
                        }`,
                    }));
                    setLoading(false);
                    return;
                  }
                  mileageSet.add(m);
                  result.push({
                    distance: m,
                    cost: lastRate,
                  });
                }
              } else {
                if (mileageSet.has(mileage)) {
                  setErrors((prev) => ({
                    ...prev,
                    flat_plan: `Invalid File: Redundant mileage found (${mileage}) at row : ${j + 1
                      }`,
                  }));
                  setLoading(false);
                  return;
                }
                mileageSet.add(mileage);
                result.push({
                  distance: mileage,
                  cost: lastRate,
                });
              }
            }
          }
        }

        setJsonResult(result);
        setLoading(false);
        event.target.value = ""; // Reset the file input value
      };
      reader.readAsArrayBuffer(selectedFile);
      setFile(selectedFile);
    } else {
      Swal.fire({
        title: "Message",
        text: "Please upload a valid Excel file.",
        icon: "warning",
      });
    }
  };



  return (
    <>
      {/* handleError5xx */}
      <Error5xx open={isErrorDialogOpen} errCount={errorCount} count={errorDialogCounter} handleClose={handleCloseErrorDialog} tryAgain={tryAgain} />
      {actionType === "add" && (
        <AddCarType
          open={modalOpen}
          onClose={handleModalClose}
          handleAddRideType={handleAddRideType}
          newRideType={newRideType}
          setNewRideType={setNewRideType}
          colorConfigs={colorConfigs}
          showSnackbar={showSnackbar}
          initialValues={carTypeinitialValues}
        />
      )}
      <Box className='rootBox' sx={{ mb: 1 }}>
        <Typography variant="body1" style={{ fontWeight: "700", color: colorConfigs?.sidebar.bg, padding: '0 10px' }}>
          Ride Types
        </Typography>
      </Box>

      <Box className='flexContainer'>
        <Box className='categoryBox'>
          {carTypes?.map((carType) => (
            <FormControlLabel
              key={carType?._id}
              control={
                <Checkbox
                  checked={selectedCarTypes?.some(
                    (type) => type?._id === carType?._id
                  )}
                  onChange={() => handleCarTypeChange(carType)}
                  className={"checkbox"}
                />
              }
              label={carType?.name}
            />
          ))}

          <BlueButton
            onClick={() => {
              setActionType("add");
              handleModalOpen();
            }}
            sx={{
              backgroundColor: 'transparent',
              color: colorConfigs.sidebar.bg,
              borderRadius: "5px",
              border: `1px solid ${colorConfigs.sidebar.bg}`,
              padding: 0,
              minWidth: '30px',
              height: '30px',
              marginTop: '5px'
            }}
          >
            <IoMdAdd />
          </BlueButton>
        </Box>

        {process.env.REACT_APP_ENV !== 'women_first' && <Box className='categoryBox'>
          <FormControlLabel
            control={
              <Checkbox
                checked={genderMale}
                onChange={(e) => setGenderMale(e.target.checked)}
                className={"checkbox"}
              />
            }
            label="Male"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={genderFemale}
                onChange={(e) => setGenderFemale(e.target.checked)}
                color="primary"
                className='checkbox'
              />
            }
            label="Female"
          />
          {/* {formErrors.gender && (
            <Typography variant="caption" style={{ color: "red" }}>
              {formErrors.gender}
            </Typography>
          )} */}
        </Box>}
      </Box>
      <Formik
        initialValues={{
          base_cost: carType?.base_cost,
          min_cost: carType?.min_cost,
          waiting_time: carType?.waiting_time,
          waiting_time_cost: carType?.waiting_time_cost,
          company_percentage: carType?.company_percentage * 100,
          is_schedule_ride: carType?.is_schedule_ride,
          schedule_ride_time: carType?.schedule_ride_time,
          all_time: carType?.all_time,
          schedule: carType?.schedule,
          plan_cost_distance: carType?.basicFlatRate?.cost_per_distance,
          plan_cost_time: carType?.basicFlatRate?.cost_per_min,
          plans: carType?.plans,
          type: carType?.type,
          male: process.env.REACT_APP_ENV === 'women_first' ? false : carType?.male,
          fmale: process.env.REACT_APP_ENV === 'women_first' ? true : carType?.fmale,
          service: carType?.service?.tax,
          index: carType?.index,
          flatDefault: carType?.flatDefault,
          basicDefault: carType?.basicDefault,
          basicFlatRate: {
            cost_per_min: carType?.basicFlatRate?.cost_per_min,
            cost_per_distance: carType?.basicFlatRate?.cost_per_distance
          },
          isFlatRate: true,
          flatRate: carType.flatRate
        }}
        onSubmit={handleSubmit}
      >
        {({
          handleBlur,
          handleChange,
          values,
          errors,
          handleSubmit,
          setFieldValue,
          touched,
        }) => (
          <ThemeProvider theme={theme}>
            <Grid container spacing={1.2} style={{ marginTop: "5px" }}>
              <Box className="rootBox" sx={{ mb: 1 }}>
                <Typography
                  variant="body1"
                  style={{
                    fontWeight: "700",
                    color: colorConfigs?.sidebar.bg,
                    padding: "0 10px",
                  }}
                >
                  Shared Fees
                </Typography>
              </Box>
              <Grid item md={3} xs={12}>
                <TextField
                  fullWidth
                  label={
                    <Box
                      component={"span"}
                      sx={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >

                      Waiting charge time *
                    </Box>
                  }
                  name="waiting_time"
                  onChange={(event) => {
                    setIsFormDirty(true);
                    handleChange("waiting_time")(event);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {"Min"}
                      </InputAdornment>
                    ),
                  }}
                  onWheel={(e) => e.target.blur()}
                  onBlur={handleBlur("waiting_time")}
                  className="input"
                  sx={fieldStyle}
                  inputProps={{ pattern: "[0-9]*" }}
                  type="number"
                  value={values?.waiting_time}
                  variant="outlined"
                  placeholder="Waiting charge time starts after"
                  filled="true" // Set it as a string "true"
                  focused
                  error={formErrors.waiting_time?.length > 0 ? true : false}
                />
                {formErrors.waiting_time?.length > 0 && (
                  <Typography variant="caption" style={{ color: "red" }}>
                    {formErrors.waiting_time}
                  </Typography>
                )}
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  fullWidth
                  label={
                    <Box
                      component={"span"}
                      sx={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >

                      Cost per Minute *
                    </Box>
                  }
                  name="waiting_time_cost"
                  onChange={(event) => {
                    setIsFormDirty(true);
                    handleChange("waiting_time_cost")(event);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">{"$"}</InputAdornment>
                    ),
                  }}
                  onBlur={handleBlur("waiting_time_cost")}
                  className="input"
                  sx={fieldStyle}
                  inputProps={{ pattern: "[0-9]*" }}
                  type="number"
                  value={values?.waiting_time_cost}
                  variant="outlined"
                  placeholder="Enter a waiting time cost"
                  filled="true" // Set it as a string "true"
                  focused
                  error={
                    formErrors.waiting_time_cost?.length > 0 ? true : false
                  }
                />
                {formErrors.waiting_time_cost?.length > 0 && (
                  <Typography variant="caption" style={{ color: "red" }}>
                    {formErrors.waiting_time_cost}
                  </Typography>
                )}
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  fullWidth
                  label={
                    <Box
                      component={"span"}
                      sx={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >

                      Company percentage *
                    </Box>
                  }
                  name="company_percentage"
                  onChange={(event) => {
                    setIsFormDirty(true);
                    handleChange("company_percentage")(event);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">{"%"}</InputAdornment>
                    ),
                  }}
                  onWheel={(e) => e.target.blur()}
                  onBlur={handleBlur("company_percentage")}
                  className="input"
                  sx={fieldStyle}
                  inputProps={{ pattern: "[0-9]*" }}
                  type="number"
                  value={values?.company_percentage}
                  variant="outlined"
                  placeholder="Enter a company percentage"
                  filled="true" // Set it as a string "true"
                  focused
                  error={
                    formErrors.company_percentage?.length > 0 ? true : false
                  }
                />
                {formErrors.company_percentage?.length > 0 && (
                  <Typography variant="caption" style={{ color: "red" }}>
                    {formErrors.company_percentage}
                  </Typography>
                )}
              </Grid>
              <Box className="rootBox" sx={{ mb: 0.5 }}>
                <Typography
                  variant="body1"
                  style={{
                    fontWeight: "700",
                    color: colorConfigs?.sidebar.bg,
                    padding: "0 10px",
                  }}
                >
                  Pricing
                </Typography>
              </Box>
              <Grid item xs={12}>
                <Box sx={{ mb: 1 }}>
                  <Typography
                    style={{
                      fontWeight: "600",
                      color: "#172B4D",
                      fontSize: 14,
                    }}
                  >
                    Basic Pricing
                  </Typography>
                  <Typography
                    style={{
                      fontWeight: "400",
                      color: "#7A869A",
                      fontSize: 14,
                    }}
                  >
                    This pricing will be applied by default if no other
                    pricing is available{" "}
                  </Typography>
                </Box>
              </Grid>

              <Grid item md={3} xs={12}>
                <TextField
                  fullWidth
                  label={
                    <Box
                      component={"span"}
                      sx={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >

                      Base cost *
                    </Box>
                  }
                  name="base_cost"
                  onChange={(event) => {
                    setIsFormDirty(true);
                    handleChange("base_cost")(event);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">{"$"}</InputAdornment>
                    ),
                  }}
                  onWheel={(e) => e.target.blur()}
                  onBlur={handleBlur("base_cost")}
                  className="input"
                  sx={fieldStyle}
                  inputProps={{ pattern: "[0-9]*" }}
                  type="number"
                  value={values?.base_cost}
                  variant="outlined"
                  placeholder="Enter cost"
                  filled="true"
                  focused
                  error={formErrors.base_cost?.length > 0 ? true : false}
                />
                {formErrors.base_cost?.length > 0 && (
                  <Typography variant="caption" style={{ color: "red" }}>
                    {formErrors.base_cost}
                  </Typography>
                )}
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  fullWidth
                  label={
                    <Box
                      component={"span"}
                      sx={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >

                      Min cost *
                    </Box>
                  }
                  name="min_cost"
                  onChange={(event) => {
                    setIsFormDirty(true);
                    handleChange("min_cost")(event);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">{"$"}</InputAdornment>
                    ),
                  }}
                  onWheel={(e) => e.target.blur()}
                  onBlur={handleBlur("min_cost")}
                  className="input"
                  sx={fieldStyle}
                  inputProps={{ pattern: "[0-9]*" }}
                  type="number"
                  value={values?.min_cost}
                  variant="outlined"
                  placeholder="Enter a min cost"
                  filled="true" // Set it as a string "true"
                  focused
                />

                {formErrors.min_cost?.length > 0 && (
                  <Typography variant="caption" style={{ color: "red" }}>
                    {formErrors.min_cost}
                  </Typography>
                )}
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  label={
                    <Box
                      component={"span"}
                      sx={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >

                      Service *
                    </Box>
                  }
                  name="service"
                  onChange={(event) => {
                    setIsFormDirty(true);
                    handleChange("service")(event);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="end">
                        {taxType == "amount" ? "$" : "%"}
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <ToggleButtonGroup
                          size="small"
                          value={taxType}
                          exclusive
                          onChange={(event, newTaxType) => {
                            setIsFormDirty(true);
                            if (newTaxType !== null) {
                              // Prevents deselecting both options
                              setTaxType(newTaxType);
                            }
                          }}
                          aria-label="Tax type"
                        >
                          <ToggleButton
                            value="amount"
                            aria-label="amount"
                            style={{
                              width: "40px",
                              backgroundColor:
                                taxType === "amount"
                                  ? colorConfigs?.sidebar?.bg
                                  : "#E7E7E7",
                              color: taxType === "amount" ? "#fff" : "#000",
                            }}
                          >
                            $
                          </ToggleButton>
                          <ToggleButton
                            value="tax_percentage"
                            aria-label="percantage"
                            style={{
                              width: "40px",
                              backgroundColor:
                                taxType === "tax_percentage"
                                  ? colorConfigs?.sidebar?.bg
                                  : "#E7E7E7",
                              color:
                                taxType === "tax_percentage"
                                  ? "#fff"
                                  : "#000",
                            }}
                          >
                            %
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </InputAdornment>
                    ),
                  }}
                  onWheel={(e) => e.target.blur()}
                  onBlur={handleBlur("service")}
                  className="input"
                  sx={fieldStyle}
                  inputProps={{ pattern: "[0-9]*" }}
                  type="number"
                  value={values?.service}
                  variant="outlined"
                  placeholder="Enter a service"
                  filled="true" // Set it as a string "true"
                  focused
                  error={formErrors.service?.length > 0 ? true : false}
                />
                {formErrors.service?.length > 0 && (
                  <Typography variant="caption" style={{ color: "red" }}>
                    {formErrors.service}
                  </Typography>
                )}
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  fullWidth
                  label={
                    <Box
                      component={"span"}
                      sx={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >

                      Cost Per KM *
                    </Box>
                  }
                  name="plan_cost_distance"
                  onChange={(event) => {
                    setIsFormDirty(true);
                    handleChange("plan_cost_distance")(event);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">{"$"}</InputAdornment>
                    ),
                  }}
                  onWheel={(e) => e.target.blur()}
                  onBlur={handleBlur("plan_cost_distance")}
                  className="input"
                  sx={fieldStyle}
                  inputProps={{ pattern: "[0-9]*" }}
                  type="number"
                  value={values?.plan_cost_distance}
                  variant="outlined"
                  placeholder="Plan Cost  With Distance"
                  filled="true" // Set it as a string "true"
                  focused
                  error={
                    formErrors.plan_cost_distance?.length > 0 ? true : false
                  }
                />
                {formErrors.plan_cost_distance?.length > 0 && (
                  <Typography variant="caption" style={{ color: "red" }}>
                    {formErrors.plan_cost_distance}
                  </Typography>
                )}
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  fullWidth
                  label={
                    <Box
                      component={"span"}
                      sx={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >

                      Cost Per Min *
                    </Box>
                  }
                  name="plan_cost_time"
                  onChange={(event) => {
                    setIsFormDirty(true);
                    handleChange("plan_cost_time")(event);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">{"$"}</InputAdornment>
                    ),
                  }}
                  onWheel={(e) => e.target.blur()}
                  onBlur={handleBlur("plan_cost_time")}
                  className="input"
                  sx={fieldStyle}
                  inputProps={{ pattern: "[0-9]*" }}
                  type="number"
                  value={values?.plan_cost_time}
                  variant="outlined"
                  placeholder="Plan Cost  With Minute"
                  filled="true" // Set it as a string "true"
                  focused
                  error={
                    formErrors.plan_cost_time?.length > 0 ? true : false
                  }
                />
                {formErrors.plan_cost_time?.length > 0 && (
                  <Typography variant="caption" style={{ color: "red" }}>
                    {formErrors.plan_cost_time}
                  </Typography>
                )}
              </Grid>
            </Grid>

            <Grid item xs={12} my={2}>
              <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                <Typography
                  variant="body1"
                  style={{ fontWeight: "700", paddingLeft: "2px", display: 'flex', justifyContent: 'start', alignItems: 'center' }}
                >
                  <div
                    style={{
                      height: "5px",
                      width: "5px",
                      backgroundColor: "black",
                      borderRadius: "50%",
                      display: "inline-block",
                      marginRight: "6px",
                    }}
                  />
                  Flat Pricing Plan
                </Typography>
                {carType?.flatRate.length > 0 && <Box onClick={() => { removeFlatPlan(carType._id); setFieldValue('flatDefault', false); setFieldValue('basicDefault', true); }} component={'span'} mt={0.5} ml={1} sx={{ cursor: 'pointer' }}>
                  <MdDelete color="red" size={20} />
                </Box>}
              </Box>
            </Grid>

            {carType?.flatRate.length === 0 && <Box>
              <Typography sx={{ color: '#172B4D', fontSize: 16, fontWeight: 600, my: 2 }} gutterBottom>
                Upload file for new Plan
              </Typography>
              <label
                style={{
                  width: '300px',
                  height: '160px',
                  wordWrap: 'break-word',
                  display: 'inline-block'
                }} htmlFor="upload-file">
                <Input
                  accept=".xlsx,.xls"
                  id="upload-file"
                  type="file"
                  style={{
                    width: '300px',
                    height: '160px',
                    wordWrap: 'break-word',
                  }}
                  onChange={handleFileChange}
                />
                <Box
                  sx={{
                    border: "2px dashed #ccc",
                    borderRadius: "4px",
                    padding: "20px",
                    textAlign: "center",
                    width: "300px",
                    height: "160px",
                    background: "#F6F6F6",
                    cursor: "pointer",
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    wordWrap: "break-word",
                  }}
                >
                  {!loading ? (
                    <Typography
                      sx={{
                        wordWrap: "break-word",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      {file ? (
                        <Typography
                          sx={{ wordWrap: "break-word", width: "100%" }}
                        >
                          {file.name}
                        </Typography>
                      ) : (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          <LuUpload
                            size={25}
                            color={colorConfigs.sidebar.bg}
                          />
                          <Typography color={"#7A869A"}>
                            {" "}
                            Upload File
                          </Typography>
                        </Box>
                      )}
                    </Typography>
                  ) : (
                    <Typography
                      sx={{
                        wordWrap: "break-word",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      Please Wait......
                    </Typography>
                  )}
                </Box>
              </label>
            </Box>}
            {carType?.flatRate.length === 0 && file && (

              <Button
                variant="contained"
                color="primary"
                onClick={handleRemoveFile}
                className='buttonStyle'
                style={{
                  backgroundColor: colorConfigs?.sidebar?.bg,
                  color: "white",
                  width: "12%",
                  display: 'block',
                  margin: '5px 0'
                }}
              >
                Remove File
              </Button>
            )}
            {carType?.flatRate.length === 0 && formErrors.flat_plan?.length > 0 && (
              <Typography variant="caption" style={{ color: "red" }}>
                {formErrors.flat_plan}
              </Typography>
            )}

            {carType?.flatRate.length > 0 && <Box className={classes.costContainer} >
              <TableContainer className={classes.tableContainer}>
                <Table sx={{ borderCollapse: 'separate', borderSpacing: '4px' }}>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.headerCell}>Mileage (kM)</TableCell>
                      {carType?.flatRate.map((row, index) => (
                        <TableCell
                          key={index}
                          className={index % 2 === 0 ? classes.evenCell : classes.oddCell}
                        >
                          {row.distance}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell className={classes.headerCell}>Rate</TableCell>
                      {carType?.flatRate.map((row, index) => (
                        <TableCell
                          key={index}
                          className={index % 2 === 0 ? classes.evenCell : classes.oddCell}
                        >
                          ${row.cost}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>}
            {/* <Box className={classes.rootBox} style={{ display: "flex", gap: "70px", marginTop: 20 }}>
              <Typography variant="body1" style={{ fontWeight: "bold" }}>
                Plan
              </Typography>
              <Box style={{ display: "flex", gap: "15px" }}>
                <Box className={classes.item}>
                  <span className={`${classes.dot} ${classes.orangeDot}`} />
                  <Typography variant="body2">Speed</Typography>
                </Box>
                <Box className={classes.item}>
                  <span className={`${classes.dot} ${classes.blueDot}`} />
                  <Typography variant="body2">Distance</Typography>
                </Box>
              </Box>
            </Box>
            <Box>
              <Button
                variant="contained"
                onClick={handleAddClick}
                style={{
                  backgroundColor: colorConfigs?.sidebar?.bg,
                  color: "white",
                  marginBottom: "10px",
                  fontWeight: "bold",
                }}
              >
                Add Distance
              </Button>
            </Box> */}
            {/* <Box mt={7}> */}
            {/* <Box position="relative" width="100%" height="50px" mt={2}> */}
            {/* <Box
                  position="absolute"
                  top="50%"
                  left="0"
                  right="0"
                  height="2px"
                  bgcolor="#000"
                  transform="translateY(-50%)"
                />
                {divisions.map((division, index) => (
                  <Box
                    key={division.key}
                    position="absolute"
                    top="0"
                    left={`${(division.value / 9999) * 100}%`}
                    height="100%"
                    style={{ borderLeft: `1px solid ${colorConfigs?.sidebar?.bg}` }}
                    textAlign="center"
                    transform="translateX(-50%)"
                  >
                    {index === 0 ? (
                      <Typography
                        style={{
                          position: "absolute",
                          top: "-35px",
                          transform: "translateX(-7px)",
                          whiteSpace: "nowrap",
                          fontSize: 25,
                          fontWeight: 600,
                          color: colorConfigs?.sidebar?.bg
                          // cursor: "pointer",
                        }}
                      // onClick={() => handleLabelClick(index)}
                      >
                        0
                      </Typography>
                    ) : index === divisions.length - 1 ? (
                      <Typography
                        style={{
                          position: "absolute",
                          top: "-30px",
                          transform: "translateX(-15px)",
                          whiteSpace: "nowrap",
                          fontSize: 30,
                          fontWeight: 700,
                          color: colorConfigs?.sidebar?.bg
                          // cursor: "pointer",
                        }}
                      // onClick={() => handleLabelClick(index)}
                      >
                        <FaInfinity />
                      </Typography>
                    ) : (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            maxWidth: "37px",
                            paddingTop: "7px",
                            flexDirection: "column",
                            fontSize: "16px",
                            color: "var(--White, #fff)",
                            fontWeight: 700,
                            whiteSpace: "nowrap",
                            position: "absolute",
                            top: "-20px",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            cursor: "pointer",
                            lineHeight: "137%",
                          }}
                        >
                          <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/af89f12c43fd5a564333f208a02236ab7a5768ab0a2b67f352962d1204f7b349?"
                            alt="icon"
                            style={{
                              aspectRatio: "1",
                              objectFit: "auto",
                              objectPosition: "center",
                              width: "14px",
                              fill: "#a6a6a6",
                              position: "absolute",
                              top: "0px",
                              transform: "translate(32%, 40%)",
                              right: "0px",
                              cursor: "pointer",
                            }}
                            onClick={() => handleDeleteDivision(index)}
                          />
                          <Box
                            sx={{
                              fontFamily: "Poppins, sans-serif",
                              justifyContent: "center",
                              borderRadius: "8px",
                              backgroundColor: `var(--Main-Color, ${colorConfigs?.sidebar?.bg})`,
                              marginTop: "7px",
                              padding: "2px 8px",
                              textAlign: "center",
                              width: "fit-content",
                            }}
                          >
                            <Typography
                              onClick={() => handleLabelClick(index)}
                            >
                              {" "}
                              {division.label}
                            </Typography>
                          </Box>
                        </Box>
                      </>
                    )}
                  </Box>
                ))} */}
            {/* {plan.map((divisionPlan, index) => {
                  const divisionStart = divisions[index]?.value;
                  const divisionEnd = divisions[index + 1]?.value;
                  const speeds = divisionPlan.speeds;
                  const gap = 500; // Define a gap constant

                  const positionedSpeeds = speeds.map((speed, sIndex) => ({
                    ...speed,
                    position:
                      divisionStart +
                      ((sIndex + 1) * (divisionEnd - divisionStart + gap)) /
                      (speeds.length + 1),
                  }));

                  return (
                    <Box
                      key={`division-speed-${index}`}
                      position="absolute"
                      top="0"
                      left={`${((divisionStart - (gap - 180)) / 9999) * 100}%`}
                      width={`${((divisionEnd - divisionStart) / 9999) * 100}%`}
                      height="100%"
                      transform="translateX(-20%)"
                    >
                      {positionedSpeeds.map((speed, sIndex) => (
                        <Box
                          key={`speed-info-${sIndex}`}
                          position="absolute"
                          left={`${((speed.position - divisionStart) /
                            (divisionEnd - divisionStart)) *
                            100
                            }%`}
                          style={{
                            transform: "translateX(-50%)",
                            cursor: "pointer",
                          }}
                        >
                          <Box
                            sx={{
                              justifyContent: "center",
                              borderRadius: "8px",
                              backgroundColor: "#f2f2f2",
                              display: "flex",
                              marginTop: "-30px",
                              gap: "2px",
                              padding: "6px",
                            }}
                          >
                            <img
                              loading="lazy"
                              src="https://cdn.builder.io/api/v1/image/assets/TEMP/af89f12c43fd5a564333f208a02236ab7a5768ab0a2b67f352962d1204f7b349?"
                              alt="icon"
                              style={{
                                aspectRatio: "1",
                                objectFit: "auto",
                                objectPosition: "center",
                                width: "14px",
                                fill: "#a6a6a6",
                                position: "absolute",
                                top: "0px",
                                transform: "translate(50%, -250%)",
                                right: "0px",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                handleDeleteSpeed(index, sIndex)
                              }
                            />
                            <Typography
                              sx={{
                                // fontFamily: "Poppins, sans-serif",
                                letterSpacing: "-0.6px",
                                fontSize: "12px",
                                fontWeight: "bold",
                              }}
                              onClick={() =>
                                handleEditSpeedClick(index, sIndex)
                              }
                            >
                              ${speed?.cost_per_mil}/ml
                            </Typography>
                            <Typography
                              sx={{
                                fontFamily: "Poppins, sans-serif",
                                fontWeight: 800,
                                fontSize: "12px",
                              }}
                            >
                              ,
                            </Typography>
                            <Typography
                              sx={{
                                // fontFamily: "Poppins, sans-serif",
                                letterSpacing: "-0.6px",
                                fontSize: "12px",
                                fontWeight: "bold",
                              }}
                              onClick={() =>
                                handleEditSpeedClick(index, sIndex)
                              }
                            >
                              ${speed?.cost_per_min}/min
                            </Typography>
                          </Box>
                          <Box className={classes.speedCircle}
                            onClick={() =>
                              handleEditSpeedClick(index, sIndex)
                            }>
                            <Typography>
                              {speed.end_speed === 9999
                                ? "∞"
                                : speed.end_speed}
                            </Typography>
                          </Box>
                        </Box>
                      ))}
                      <img
                        src={speed}
                        alt="Add Speed"
                        style={{
                          position: "absolute",
                          bottom: "-30px",
                          left: "55%",
                          transform: "translateX(-50%)",
                          cursor: "pointer",
                        }}
                        onClick={() => { handleAddSpeedClick(index) }}
                      />
                    </Box>
                  );
                })} */}
            {/* </Box>
            </Box> */}



            <div style={{ marginTop: "30px" }}>
              {formErrors.plan?.length > 0 && (
                <Typography variant="caption" style={{ color: "red" }}>
                  {formErrors.plan}
                </Typography>
              )}
              {formErrors.gender && (
                <Typography variant="caption" style={{ color: "red" }}>
                  {formErrors.gender}
                </Typography>
              )}
              <br />
              {formErrors.car_type && (
                <Typography variant="caption" style={{ color: "red" }}>
                  {formErrors.car_type}
                </Typography>
              )}
              <br />
              {formErrors.general && (
                <Typography variant="caption" style={{ color: "red" }}>
                  {formErrors.general}
                </Typography>
              )}
            </div>

            <div style={{ marginTop: 16, gap: 8 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleCancelEdit}
                className={classes.buttonStyle}
                style={{
                  marginRight: 8,
                  color: colorConfigs?.sidebar?.bg,
                  borderColor: colorConfigs?.sidebar?.bg,
                  backgroundColor: "white",
                  width: "12%",
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                className={classes.buttonStyle}
                style={{
                  backgroundColor: colorConfigs?.sidebar?.bg,
                  color: "white",
                  width: "12%",
                }}
              >
                Save
              </Button>
            </div>
          </ThemeProvider>
        )}
      </Formik>

      <Modal
        title="Distance"
        open={open}
        onCancel={handleClose}
        onOk={handleSaveDivision}
        okButtonProps={{
          style: { backgroundColor: colorConfigs?.sidebar?.bg, color: "white" },
        }}
      >
        <TextField
          fullWidth
          className={classes.input}
          sx={fieldStyle}
          label="Distance"
          inputProps={{ pattern: "[0-9]*" }}

          type="number"
          variant="outlined"
          filled="true" // Set it as a string "true"
          focused
          autoFocus
          margin="dense"
          placeholder="Distance"
          value={newLabel}
          onChange={(e) => setNewLabel(e.target.value)}
          error={!!error}
          helperText={error}
        />
      </Modal>
      <Modal
        title={currentSpeedIndex === -1 ? "Add Speed" : "Edit Speed"}
        open={openSpeedDialog}
        onCancel={() => {
          setOpenSpeedDialog(false);
          setSpeedError("");
        }}
        onOk={() => { handleSaveSpeed(currentSpeedIndex === -1 ? false : true) }}
        okButtonProps={{
          style: { backgroundColor: colorConfigs?.sidebar?.bg, color: "white" },
        }}
      >
        <Box
          className={classes.categoryBox}
          style={{ padding: "12px", marginTop: "10px" }}
        >
          <div style={{ display: "flex" }}>
            <Switch
              checked={isInfiniteSpeed}
              onChange={(checked) => setIsInfiniteSpeed(checked)}
              className="custom-switch"
            />
            <span style={{ marginLeft: 8 }}>Set speed to max</span>
          </div>
        </Box>

        <TextField
          fullWidth
          className={classes.input}
          sx={fieldStyle}
          autoFocus
          margin="dense"
          label="Speed"
          value={isInfiniteSpeed ? "∞" : currentSpeedLabel}
          onChange={(e) => setCurrentSpeedLabel(e.target.value)}
          variant="outlined"
          inputProps={{ pattern: "[0-9]*" }}
          required
          type="number"
          filled="true" // Set it as a string "true"
          focused
          placeholder={isInfiniteSpeed ? "∞" : "Speed"}
          disabled={isInfiniteSpeed} // Disable input when switch is on
        />

        <TextField
          margin="dense"
          label="Cost per Minute"
          fullWidth
          value={costPerMin}
          onChange={(e) => setCostPerMin(e.target.value)}
          variant="outlined"
          className={classes.input}
          sx={fieldStyle}
          inputProps={{ pattern: "[0-9]*" }}
          required
          type="number"
          filled="true" // Set it as a string "true"
          focused
          autoFocus
          placeholder="Cost per min"
        />
        <TextField
          margin="dense"
          label="Cost per Mile"
          type="number"
          fullWidth
          value={costPerMil}
          onChange={(e) => setCostPerMil(e.target.value)}
          variant="outlined"
          className={classes.input}
          sx={fieldStyle}
          inputProps={{ pattern: "[0-9]*" }}
          required
          filled="true" // Set it as a string "true"
          focused
          autoFocus
          placeholder="Cost per mile"
        />
        {speedError && (
          <Typography variant="caption" style={{ color: "red" }}>
            {speedError}
          </Typography>
        )}
      </Modal>
    </>
  );
};

export default EditPlane;