// src/components/GlobalModal.js
import { Box, Modal } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from '../../redux/features/modalSlice';
const GlobalModal = () => {
    const dispatch = useDispatch();
    const { isOpen, content } = useSelector((state) => state.dynamicModal);
    const CloseModal = () => {
        dispatch(closeModal());
    };

    return (
        <Modal
            open={isOpen}
            onClose={content?.props?.handleClose ? content?.props?.handleClose: CloseModal}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '4vh',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    maxWidth: '95%',
                }}
            >
                {content}
            </Box>
        </Modal>
    );
};

export default GlobalModal;
