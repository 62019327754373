import {
    Alert,
    Box,
    CircularProgress,
    Snackbar,
    Tab,
    Tabs,
    Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FaPhone } from 'react-icons/fa6';
import { IoChatbubbleEllipsesSharp, IoMailSharp } from "react-icons/io5";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import StatusBadge from '../../components/common/BadgeWrapper';
import BlueButton from '../../components/common/Buttons/BlueButton';
import colorConfigs from '../../configs/colorConfigs';
import { setPayrollList, setPayrollTabIndex } from '../../redux/features/appStateSlice';
import { closeModal, openModal } from '../../redux/features/modalSlice';
import { getMethod, postMethod } from '../Hook/UseCustomApi';
import DynamicTable from '../table/table';
import PayRollDetails from './component/PayRollDetails';
import PayDriverDialog from './component/PayDriverDialog';
import Loading from "react-fullscreen-loading";

const style = {
    color: "#495974",
    fontWeight: "500",
    fontSize: "14px",
    fontFamily: "poppins",
    lineHeight: "18px" /* 150% */,
    padding: "8px 15px ",
};

const columns = [
    {
        field: 'name', headerName: 'Name', flex: 1, style: style, minWidth: "100px",
        renderCell: (params) => <Typography sx={{ fontSize: 14, width: 'max-content' }}> {params?.row?.first_name} {params?.row?.last_name}  </Typography>,
    },
    { field: 'total_ride', headerName: 'Total Rides', flex: 1 },
    {
        field: 'amount', style: style, headerName: 'Amount', flex: 1,
        renderCell: (params) => <Typography>${params?.row?.amount} </Typography>,
    },
    {
        field: "status",
        headerName: "Status",
        minWidth: "120px",
        flex: 1,
        style: style,
        align: "center",
        headerClassName: "super-app-theme--header",
        renderCell: (params) => <StatusBadge status={params.row.status} />,
    },
    {
        field: "Contact",
        headerName: "contact",
        minWidth: "120px",
        flex: 1,
        style: style,
        align: "center",
        headerClassName: "super-app-theme--header",
        renderCell: (params) =>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
                <Box component={'a'} href={`tel:${params?.row?.code_phone_number + params?.row?.phone_number}`} sx={{ background: colorConfigs?.sidebar?.bg, p: 1, borderRadius: 25, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
                    <FaPhone color='white' />
                </Box>
                <Box component={'a'} href={`mailto: ${params?.row?.email}`} sx={{ background: colorConfigs?.sidebar?.bg, p: 1, borderRadius: 25, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
                    <IoMailSharp color='white' />
                </Box>
                {/* <Box sx={{ background: colorConfigs?.sidebar?.bg, p: 1, borderRadius: 25, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
                    <IoChatbubbleEllipsesSharp color='white' />
                </Box> */}
            </Box>
        ,
    },
];

export default function PayoutDetails() {
    const dispatch = useDispatch();
    const payrollList = useSelector((state) => state.appState.payrollList);
    const payrollTabIndex = useSelector((state) => state.appState.payrollTabIndex);
    const [data, setData] = useState([]);
    const [count, setCount] = useState(1);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [tabIndex, setTabIndex] = useState(0);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("error");
    const { id } = useParams()
    const rowsLimits = [5, 10, 25, 100];

    const showSnackbar = (message, severity = 'error') => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setOpenSnackbar(true);
    };

    const fetchData = async () => {
        setLoading(true);
        setData([]);
        let currentIndex = tabIndex
        console.log(currentIndex)
        const type = currentIndex === 0 ? 'stripe' : 'manual'; // Change endpoints based on tabIndex
        getMethod({
            url: `payment/payout/billing-cycles/payment-details/${id}?type=${type}`,
            params: { page, limit: 5 }, // Adjust params as needed
            handleErrorMessage: (msg) => showSnackbar(msg),
        })
            .then((response) => {
                setLoading(false);
                dispatch(setPayrollList(response?.data));
                setData(response?.data);
                setCount(response?.count);
            })
            .catch((err) => {
                setLoading(false);
                showSnackbar("Error fetching data");
            });
    };

    const updateRowStatus = (rowId, newStatus) => {
        setData((prevData) => {
            return prevData.map((row) => {
                if (row._id === rowId) {
                    return { ...row, status: newStatus };
                }
                return row;
            });
        });
    };

    const payMethod = (row) => {
        postMethod({
            url: `payment/payout/pay-cash/payment-details/${row?._id}`,
            handleErrorMessage: (msg) => showSnackbar(msg),
        })
            .then((response) => {
                updateRowStatus(row._id, 'paid');
                handleBack();
            })
            .catch((err) => {
                setLoading(false);
                showSnackbar("Error fetching data");
            });
    }

    const handleBack = () => {
        dispatch(closeModal(true))
    }

    useEffect(() => {
        fetchData();
    }, [page, id, tabIndex]);

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleRowsChange = (rows) => {
        setPage(1);
        fetchData(); // Fetch data for the current tab
    };

    const handleTabChange = (event, newValue) => {
        setLoading(true);
        if (newValue === 1) {
            columns[6] = {
                field: "action",
                headerName: "Action",
                minWidth: "120px",
                flex: 1,
                style: style,
                align: "center",
                headerClassName: "super-app-theme--header",
                renderCell: (params) => {
                    if (params.row.status !== 'paid')
                        return <BlueButton onClick={() => { dispatch(openModal(<PayDriverDialog handlePaid={() => payMethod(params.row)} handleBack={handleBack} />)) }} sx={{ p: 0, background: 'transparent', border: `1px solid ${colorConfigs?.sidebar?.bg}`, color: colorConfigs?.sidebar?.bg, height: '35px' }}>
                            Pay out
                        </BlueButton>
                    else {
                        return '-'
                    }
                }
            }
        } else {
            columns.pop()
        }
        setTabIndex(newValue);
        setPage(1);
    };

    return (
        <>
            <Box>
                <>
                    <PayRollDetails />
                    <Tabs
                        value={tabIndex}
                        onChange={handleTabChange}
                        // indicatorColor="primary"
                        // textColor="primary"
                        variant="fullWidth"
                        sx={{
                            border: `1px solid ${colorConfigs?.sidebar?.bg}`,
                            borderRadius: 1,
                            width: '25%',
                            p: 1,
                            mb: 2,
                            fontSize: 12,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '45px',
                            maxHeight: '45px',
                            minHeight: '45px',
                            '& .MuiTab-root': {
                                transition: 'background-color 0.3s',
                            },
                            '& .Mui-selected': {
                                backgroundColor: colorConfigs?.sidebar?.bg,
                                color: 'white',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                maxHeight: '35px',
                            },
                            '& .MuiTabs-flexContainer': {
                                color: 'white',
                                maxHeight: '35px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: 0,
                                textTransform: 'capitalize',
                            },
                        }}
                    >
                        <Tab 
                            style={{ 
                                fontSize: 14, 
                                textTransform: 'capitalize', 
                                p: 1,
                                color: tabIndex === 0 ? '#FFF' : 'black',
                            }} 
                            label="Auto" 
                        />
                        <Tab 
                            style={{ 
                                fontSize: 14, 
                                textTransform: 'capitalize', 
                                p: 1,
                                color: tabIndex === 1 ? '#FFF' : 'black',
                            }} 
                            label="Cash" 
                        />
                    </Tabs>
                    {loading ? (
                        <Loading
                            loading={loading}
                            background="rgba(86, 100, 210, 0.1)"
                            loaderColor={colorConfigs.sidebar?.bg}
                        />
                    ) : (
                        <DynamicTable
                            columns={columns}
                            data={data}
                            count={count}
                            page={page}
                            rowsLimits={rowsLimits}
                            pageChange={handlePageChange}
                            rowsChange={handleRowsChange}
                        />
                    )}
                </>
            </Box>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={() => setOpenSnackbar(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
}
