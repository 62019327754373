import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
  Stack,
} from "@mui/material";
import { useLocation } from "react-router-dom"; // Import useLocation
import { Switch } from "antd";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import { CiEdit } from "react-icons/ci";
import { useDispatch } from "react-redux";
import { DeleteMethod, putMethod } from "../Hook/UseCustomApi";
import { deleteDataRedux, putDataRedux } from "../../redux/features/appStateSlice";
import { DropDown } from "@zetaton-dev/zetaton-components-bank";
import deletee from "../../assets/images/delete.png";
import contactUsIconWF from "../../assets/tableIconWomenFirst/contactUS.png";
import customersIconWF from "../../assets/tableIconWomenFirst/customer.png";
import driversIconWF from "../../assets/tableIconWomenFirst/drivers.png";
import zonesIconWF from "../../assets/tableIconWomenFirst/zones.png";
import payoutsIconWF from "../../assets/tableIconWomenFirst/payouts.png";
import taxesIconWF from "../../assets/tableIconWomenFirst/taxes.png";
import ridesIconWF from "../../assets/tableIconWomenFirst/rides.png";
import contactUsIconRR from "../../assets/tableIconRoadRunner/contactUS.png";
import customersIconRR from "../../assets/tableIconRoadRunner/customer.png";
import driversIconRR from "../../assets/tableIconRoadRunner/drivers.png";
import zonesIconRR from "../../assets/tableIconRoadRunner/zones.png";
import payoutsIconRR from "../../assets/tableIconRoadRunner/payouts.png";
import taxesIconRR from "../../assets/tableIconRoadRunner/taxes.png";
import ridesIconRR from "../../assets/tableIconRoadRunner/rides.png";
import colorConfigs from "../../configs/colorConfigs";
import Voucher from "../../assets/svg/Voucher";
import TicketHelpCenterNoData from "../../assets/svg/TicketHelpCenterNoData";


const DynamicTable = ({
  columns,
  data,
  page,
  count,
  rowsLimits,
  pageChange,
  rowsChange,
  pageType,
}) => {
  const [limit, setLimit] = useState(10);
  const [deleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const [highlightedRowId, setHighlightedRowId] = useState(null); // State to track highlighted row

  useEffect(() => {
    if (location.state?.id) {
      setHighlightedRowId(location.state.id);

      setTimeout(() => {
        setHighlightedRowId(null);
      }, 2000);
    }
  }, [location.state]);

  const handleRowsChange = (e) => {
    setLimit(e.target.value);
    rowsChange(e.target.value);
  };

  const handleEnableDisableChange = async (row, value, enabledFunc) => {
    dispatch(
      putDataRedux({
        stateName: enabledFunc.stateName,
        key: enabledFunc.key,
        value: row[enabledFunc.key],
        data: { ...row, enabled: value },
      })
    );
    try {
      await putMethod({
        url: `${enabledFunc.url}/${row[enabledFunc.key]}`,
        body: { enabled: value },
      }).then((response) => {
        if (enabledFunc.stateName)
          dispatch(
            putDataRedux({
              stateName: enabledFunc.stateName,
              key: enabledFunc.key,
              value: row[enabledFunc.key],
              data: response?.data,
            })
          );
      });
    } catch (error) {
      console.error("Error enabling/disabling:", error);
    }
  };

  const handleDeleteClick = (row, deleteFunc) => {
    setRowToDelete({ row, deleteFunc });
    setDeleteConfirmationDialogOpen(true);
  };

  const handleDeleteConfirmation = async (confirm) => {
    if (confirm && rowToDelete) {
      setIsDeleting(true);
      try {
        await DeleteMethod({
          url: `${rowToDelete.deleteFunc.url}/${rowToDelete.row[rowToDelete.deleteFunc.key]}`,
        });

        if (rowToDelete.deleteFunc.stateName)
          dispatch(
            deleteDataRedux({
              stateName: rowToDelete.deleteFunc.stateName,
              key: rowToDelete.deleteFunc.key,
              value: rowToDelete.row[rowToDelete.deleteFunc.key],
            })
          );
      } catch (error) {
        console.error("Error deleting:", error);
      } finally {
        setIsDeleting(false);
        setDeleteConfirmationDialogOpen(false);
        setRowToDelete(null);
      }
    } else {
      setDeleteConfirmationDialogOpen(false);
      setRowToDelete(null);
    }
  };

  const getPageMessage = () => {
    switch (pageType) {
      case "rides":
        return "No Rides Here";
      case "employees":
        return "No Employees Here";
      case "customers":
        return "No Customers Here";
      case "passengers":
        return "No Passengers Here";
      case "admin":
        return "No Admins Here";
      case "drivers":
        return "No Drivers Here";
      case "zone":
        return "No Zones Here";
      case "contactUs":
        return "No Contacts Here";
      case "scheduled":
        return "No Scheduled Rides Here";
      case "DriverRequirements":
        return "No Driver Requirements Here";
      case "payment":
        return "No Payments Here";
      case "wallet":
        return "No Transactions Here";
      case "corporate":
        return "No Corporates Here";
      case "Voucher":
        return "No Vouchers Here";
      case "tickets":
        return "No Tickets Here";
      case "helpCenter":
        return "No Help Center Here";
      default:
        return "No Updates Here";
    }
  };

  const getPageIcon = () => {
    switch (pageType) {
      case "customers":
        return process.env.REACT_APP_ENV === "women_first"
          ? customersIconWF
          : customersIconRR;
      case "employees":
        return process.env.REACT_APP_ENV === "women_first"
          ? customersIconWF
          : customersIconRR;
      case "rides":
        return process.env.REACT_APP_ENV === "women_first"
          ? ridesIconWF
          : ridesIconRR;
      case "passengers":
        return process.env.REACT_APP_ENV === "women_first"
          ? customersIconWF
          : customersIconRR;
      case "admin":
        return process.env.REACT_APP_ENV === "women_first"
          ? customersIconWF
          : customersIconRR;
      case "drivers":
        return process.env.REACT_APP_ENV === "women_first"
          ? driversIconWF
          : driversIconRR;
      case "zone":
        return process.env.REACT_APP_ENV === "women_first"
          ? zonesIconWF
          : zonesIconRR;
      case "contactUs":
        return process.env.REACT_APP_ENV === "women_first"
          ? contactUsIconWF
          : contactUsIconRR;
      case "scheduled":
        return process.env.REACT_APP_ENV === "women_first"
          ? ridesIconWF
          : ridesIconRR;
      case "DriverRequirements":
        return process.env.REACT_APP_ENV === "women_first"
          ? driversIconWF
          : driversIconRR;
      case "payment":
        return process.env.REACT_APP_ENV === "women_first"
          ? payoutsIconWF
          : payoutsIconRR;
        return process.env.REACT_APP_ENV === 'women_first' ? payoutsIconWF : payoutsIconRR;
      case "tickets":
        return "tickets";
      case "helpCenter":
        return "helpCenter";
      default:
        return process.env.REACT_APP_ENV === "women_first"
          ? ridesIconWF
          : ridesIconRR;
    }
  };

  const renderIcon = () => {
    if (getPageIcon() === "tickets" || getPageIcon() === "helpCenter") {
      return <TicketHelpCenterNoData style={{
        marginTop: "20px",
        marginBottom: "10px"
      }} />;
    }
    else {
      return (
        <img
          style={{
            color: colorConfigs?.sidebar?.bg,
            width: "60px",
            height: "60px",
            marginTop: "20px",
          }}
          src={getPageIcon()}
          alt={pageType}
        />
      );
    }

  };

  return (
    <Box
      sx={{
        width: "100%",
        "& .super-app-theme--header": {
          backgroundColor: "rgb(220,220,220)",
          boxShadow: "none",
        },
      }}
    >
      <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: "none" }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.field}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      backgroundColor: "#f5f5f5",
                      border: "none",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#172B4D",
                        fontWeight: "600",
                        fontSize: "14px",
                      }}
                    >
                      {column.headerName}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {/* Apply background color to the entire row */}
            <TableBody>
              {data && data.length > 0 ? (
                data.map((row) => (
                  // <TableRow hover role="checkbox" tabIndex={-1} key={row._id}
                  //   style={{ backgroundColor: pageType === "contactUs" && row?.read === false ? colorConfigs.sidebar.ligthBg : "" }}>
                  //   {columns.map((column) => (
                  //     <TableCell
                  //       style={{ ...column.style, minWidth: column.minWidth, border: "none", p: 0, fontWeight: pageType === "contactUs" && row?.read === false ? 'bold' : "" }}
                  //       key={column.field}
                  //       align={column.align}
                  //     // align= "center"
                  //     >
                  //       {column.renderCell
                  //         ? column.renderCell({ row })
                  //         : row[column.field]}
                  //     </TableCell>
                  //   ))}
                  // </TableRow>
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row._id}
                    sx={{
                      color: row._id === highlightedRowId ? '#FFF' : "inherit",
                      backgroundColor:
                        row._id === highlightedRowId ? colorConfigs?.sidebar?.bg + 40 : "inherit",
                      transition: "all 1s ease-in",
                    }}
                    style={{ backgroundColor: pageType === "contactUs" && row?.read === false ? colorConfigs.sidebar.ligthBg : "" }}

                  >
                    {columns.map((column) => {
                      if (column.field === "enabled" && !column.renderCell) {
                        return (
                          <TableCell
                            style={{ ...column.style, minWidth: column.minWidth, border: "none", p: 0, fontWeight: pageType === "contactUs" && row?.read === false ? 'bold' : "" }}

                            key={column.field}
                            align={column.align}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "start",
                              }}
                            >
                              <Switch
                                checked={row.enabled}
                                onChange={(value) =>
                                  handleEnableDisableChange(
                                    row,
                                    value,
                                    column?.enabledFunc
                                  )
                                }
                              />
                            </div>
                          </TableCell>
                        );
                      } else if (column.field === "action" && !column.renderCell) {
                        return (
                          <TableCell
                            style={{
                              ...column.style,
                              minWidth: column.minWidth,
                              border: "none",
                            }}
                            key={column.field}
                            align={column.align}
                          >
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center",
                                gap: 8,
                              }}
                            >
                              {column?.viewFunc && (
                                <IconButton
                                  sx={{ p: 0 }}
                                  onClick={() => column?.viewFunc(row)}
                                >
                                  <div
                                    style={{
                                      backgroundColor: "#F3F8FD",
                                      display: "flex",
                                      borderRadius: "22px",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      padding: "4px",
                                    }}
                                  >
                                    <VisibilityIcon
                                      fontSize="small"
                                      className="icon"
                                      style={{ color: "#5B93FF" }}
                                    />
                                  </div>
                                </IconButton>
                              )}
                              {column?.editFunc && (
                                <IconButton
                                  sx={{ p: 0 }}
                                  onClick={() => column?.editFunc(row)}
                                >
                                  <div
                                    style={{
                                      backgroundColor: "#F3FDF7",
                                      display: "flex",
                                      borderRadius: "22px",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      padding: "4px",
                                    }}
                                  >
                                    <CiEdit size={22} color="#00C371" />
                                  </div>
                                </IconButton>
                              )}
                              {column?.deleteFunc?.key && column?.deleteFunc?.url && (
                                <IconButton
                                  sx={{ p: 0 }}
                                  onClick={() =>
                                    handleDeleteClick(row, column?.deleteFunc)
                                  }
                                >
                                  <div
                                    style={{
                                      backgroundColor: "#ffd8d8",
                                      display: "flex",
                                      borderRadius: "22px",
                                      padding: "4px",
                                    }}
                                  >
                                    <DeleteIcon
                                      fontSize="small"
                                      className="icon"
                                      style={{ color: "#E71D36" }}
                                    />
                                  </div>
                                </IconButton>
                              )}
                              {column.customDelete && column.customDelete(row)}
                            </Box>
                          </TableCell>
                        );
                      }

                      return (
                        <TableCell
                          style={{
                            ...column.style,
                            minWidth: column.minWidth,
                            border: "none",
                            textTransform:
                              column?.field !== "email" ? "capitalize" : "",
                          }}
                          key={column.field}
                          align={column.align}
                        >
                          {column.renderCell
                            ? column.renderCell({ row })
                            : row[column.field]}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    style={{
                      textAlign: "center",
                      padding: "8px",
                      fontFamily: "poppins",
                    }}
                    colSpan={columns.length}
                  >
                    {renderIcon()}
                    <Typography variant="body1">
                      {getPageMessage()}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          style={{
            height: "60px",
            display: "flex",
            padding: "0 0px",
            gap: "12px",
            backgroundColor: "#f5f5f554",
          }}
        >
          <Box
            style={{
              height: "60px",
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              gap: "12px",
            }}
          >
            <Typography
              style={{
                paddingTop: "8px",
                color: "#9A9EA5",
                fontSize: 14,
              }}
            >
              Rows per page :{" "}
            </Typography>
            <DropDown
              data={rowsLimits}
              value={limit}
              onChange={handleRowsChange}
              variant="standard"
              style={{
                width: "60px",
                textAlign: "center",
                borderBottom: "none",
              }} // Center the text
            />
          </Box>
          <Stack
            style={{
              marginTop: "12px",
              display: "flex",
              alignItems: "center",
              margin: "auto",
            }}
          >
            <Pagination
              count={count}
              page={page}
              onChange={(event, newPage) => {
                pageChange(event, newPage);
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
              pageType="drivers"
            />
          </Stack>
        </Box>
      </Paper>

      <Dialog
        style={{ zIndex: 10, borderRadius: "30px" }}
        open={deleteConfirmationDialogOpen}
        onClose={() => setDeleteConfirmationDialogOpen(false)}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent style={{ padding: "30px" }}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img src={deletee} alt="" />
          </div>
          <DialogContentText
            id="alert-dialog-slide-description"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Typography
              variant="h6"
              style={{
                fontWeight: "bold",
                color: "black",
                marginTop: "10px",
                marginBottom: "8px",
              }}
            >
              Delete Item
            </Typography>
          </DialogContentText>
          {rowToDelete && (
            <Typography>
              Are you sure you want to delete this item?
            </Typography>
          )}
        </DialogContent>

        <DialogActions
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            marginBottom: "20px",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
        >
          <Button
            variant="outlined"
            style={{
              color: "black",
              borderColor: "lightgray",
              width: "100%",
            }}
            onClick={() => handleDeleteConfirmation(false)}
            disabled={isDeleting}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            style={{
              background: "var(--Error, #FD4343)",
              color: "white",
              width: "100%",
            }}
            onClick={() => handleDeleteConfirmation(true)}
            disabled={isDeleting}
          >
            {isDeleting ? "Deleting..." : "Yes, Delete"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DynamicTable;
