import { useTheme } from "@emotion/react";
import { Delete as DeleteIcon } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Switch,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { SubmitButton, TextInput } from "@zetaton-dev/zetaton-components-bank";
import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import Loading from "react-fullscreen-loading";
import { AiFillEdit } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import deletee from "../../assets/images/delete.png";
import colorConfigs from "../../configs/colorConfigs";
import { auth } from "../../configs/firebase";
import { emailRegex } from "../../configs/validation";
import { setAdminsList } from "../../redux/features/appStateSlice";
import { DeleteMethod, getMethod, putMethod } from "../Hook/UseCustomApi";
import DynamicTable from "../table/table";
import AddNewAdmin from "./AddNewAdmin";
import Error5xx from "../../components/common/HandelError5xx";
import { useLocation } from "react-router-dom";

export default function AdminsList() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const admins = useSelector((state) => state.appState.admins);
  const [data, setData] = useState(admins);
  const [count, setCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [adminToUpdate, setAdminToUpdate] = useState(null);
  const [editData, setEditData] = useState(null);
  const [enabled, setEnabled] = useState(editData?.enabled || true);
  const [role, setRole] = useState(editData?.role || "");
  const [error, setError] = useState("");
  let currentPage = useRef();
  let currentLimit = useRef(10);
  let currentKeyword = useRef("");
  let currentType = useRef("all");
  const [deleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] =
    useState(false);
  const [adminToDelete, setAdminToDelete] = useState(null);
  const [formErrors, setErrors] = useState({});
  const [type, setType] = useState("all");
  const [query, setQuery] = useState("");
  const rowsLimits = [5, 10, 25, 100];
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarErrorMessage, setSnackbarErrorMessage] = useState("");
  const location = useLocation();



  //handle error5xx
  const [tryAgain, setTryAgain] = useState();
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [errorCount, setErrorCount] = useState(0);
  const [errorDialogCounter, setErrorDialogCounter] = useState(0);
  const handleError5xx = () => {
    setIsErrorDialogOpen(true);
    setErrorCount(prevCount => {
      const newCount = prevCount + 1;
      if (newCount === 1) {
        setErrorDialogCounter(5);
      } else if (newCount === 2) {
        setErrorDialogCounter(10);
      }
      return newCount;
    });
  };
  const handleCloseErrorDialog = () => {
    setIsErrorDialogOpen(false);
    if (errorCount <= 2) {
      handleErrorCountByClose()
    }

  };
  const handleErrorCountByClose = () => {
    setErrorCount(prevCount => {
      const newCount = prevCount - 1;
      if (newCount === 1) {
        setErrorDialogCounter(5);
      } else if (newCount === 2) {
        setErrorDialogCounter(10);
      }
      return newCount;
    });
  }





  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarErrorMessage(message);
    setSnackbarOpen(true);
  };

  const handleDeleteClick = (admin) => {
    setAdminToDelete(admin);
    setDeleteConfirmationDialogOpen(true);
  };






  const getParams = () => {
    return {
      page: currentPage.current,
      limit: currentLimit.current,
      search_name: currentKeyword.current,
      search_email: currentKeyword.current,
      role: currentType.current,
    };
  };

  useEffect(() => {
    currentPage.current = 1;
    currentLimit.current = 10;
    currentKeyword.current = "";
    currentType.current = "all";
    window.scrollTo({ top: 0 });
    fetchAdmins();

  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        if (admins?.length === 0) {
          fetchAdmins();
        } else {
          setData(admins);
        }
      } else {
        console.log("No user logged in.");
      }
    });

    return () => unsubscribe(); // Clean up the subscription
  }, [admins]);


  const fetchAdmins = async () => {
    if (admins?.length === 0) {
      setLoading(true);
    } else {
      setLoading(false);
    }

    getMethod({
      url: "admin",
      params: getParams(),
      handleErrorMessage: showSnackbar,
    })
      .then((response) => {
        setLoading(false);
        dispatch(setAdminsList(response?.data));
        console.log(response?.data);
        setData(response?.data);
        setCount(response?.count);
      })
      .catch((err) => {
        console.error("Error fetching more stores:", err);
        setLoading(false);
        if (error === "Internal Server Error: Server error occurred.") {
          handleError5xx();
          setTryAgain(() => fetchAdmins);
        }
      }).finally(() => setLoading(false));
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    currentPage.current = newPage;
    fetchAdmins();
  };

  const handleRowsChange = (rows) => {
    currentPage.current = 1;
    currentLimit.current = rows;
    setPage(1);
    fetchAdmins();
  };

  const handleTypeChange = (event) => {
    setType(event.target.value);
    currentPage.current = 1;
    currentType.current = event.target.value;
    fetchAdmins();
  };

  const handleSearch = (event) => {
    setQuery(event.target.value);
    currentKeyword.current = event.target.value;
    currentPage.current = 1;
    setPage(1);
    fetchAdmins();
  };

  const fieldStyle = {
    "& label.Mui-focused": {
      color: "#172B4D !important",
      fontSize: "17px !important",
      fontWeight: "500 !important",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#BBC1CE",
        borderWidth: 1,
        borderRadius: "8px",
      },
      "&:hover fieldset": {
        borderColor: "#BBC1CE",
        borderWidth: 1,
        borderRadius: "8px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#BBC1CE",
        borderWidth: 1,
        borderRadius: "8px",
      },
      input: {
        "&::placeholder": {
          color: "#3A3541DE",
          opacity: 1, // otherwise firefox shows a lighter color
          fontSize: 14,
        },
      },
    },
  };
  const handleDeleteConfirmation = async (confirmed) => {
    setDeleteConfirmationDialogOpen(false);

    if (confirmed) {
      setLoading(true);

      DeleteMethod({
        url: `admin/${adminToDelete?._id}`,
        handleErrorMessage: showSnackbar,
      })
        .then((res) => {
          setData((prevData) =>
            prevData?.filter((item) => item._id !== adminToDelete?._id)
          );
          setSnackbarMessage("Admin deleted successfully!");
          setSnackbarSeverity("success");
          setOpenSnackbar(true);
        })
        .catch((error) => console.error("Error deleting admin:", error))
        .finally(() => setLoading(false));
    }
  };

  const handleEnableDisableChange = (admin, newValue) => {
    setAdminToUpdate(admin);
    setDialogOpen(true);
  };

  const handleChangeRole = (event) => {
    setRole(event.target.value);
  };

  const handleConfirmation = async (confirmed) => {
    if (confirmed) {
      const newValue = !adminToUpdate?.enabled;
      const body = JSON.stringify({ enabled: newValue });
      putMethod({
        url: `admin/${adminToUpdate?._id}`,
        body,
        handleErrorMessage: showSnackbar,
      })
        .then(() => {
          setData((prevData) => {
            const updatedData = prevData?.map((item) => {
              if (item._id === adminToUpdate?._id) {
                return {
                  ...item,
                  enabled: newValue,
                };
              }
              return item;
            });
            return updatedData;
          });
          setSnackbarMessage(
            adminToUpdate.enabled
              ? "Successfully disabling the admin account"
              : "Successfully enabling the admin account"
          );
          setSnackbarSeverity("success");
          setOpenSnackbar(true);
          setDialogOpen(false);
        })
        .catch((error) => {
          console.error(error);
          setSnackbarMessage("Failed to update the admin account");
          setSnackbarSeverity("error");
          setOpenSnackbar(true);
          setDialogOpen(false);
          if (error === "Internal Server Error: Server error occurred.") {
            handleError5xx();
            setTryAgain(() => () => handleConfirmation(confirmed));
          }
        });
    } else {
      setDialogOpen(false);
    }
  };

  const handleSubmit = async (values) => {
    setErrors({});
    let error = {};
    if (!values.name) {
      error.name = "Name is required.";
    } else if (values.name.length > 40) {
      error.name = "Name should not exceed 40 characters.";
    }

    if (!values.email) {
      error.email = "Email is required.";
    } else if (!emailRegex.test(values.email)) {
      error.email = "Invalid email address.";
    }

    if (Object.keys(error).length > 0) {
      setErrors(error);
    } else {
      setLoading(true);

      putMethod({
        url: `admin/${editData?._id}`,
        body: {
          name: values.name,
          email: values.email,
          enabled: enabled,
          role: role,
        },
        handleErrorMessage: showSnackbar,
      })
        .then((res) => {
          setLoading(false);
          fetchAdmins();
          setSnackbarMessage("Admin edited successfully!");
          setSnackbarSeverity("success");
          setOpenSnackbar(true);
        })
        .catch((error) => {
          console.error("Error update new admin:", error);
          setError(
            error?.response?.data?.code === "admin_Exist"
              ? "Admin already exist"
              : "Error adding new admin"
          );
          setSnackbarMessage("Error editing admin!");
          if (error === "Internal Server Error: Server error occurred.") {
            handleError5xx();
            setTryAgain(() => () => handleConfirmation(values));
          }
        })
        .finally(() => {
          setLoading(false);
          setEditDialog(false);
        });
    }
  };

  function handleRowClick(rowData) {
    setEditData(rowData);
    setEditDialog(true);
    setEnabled(rowData?.enabled);
    setRole(rowData?.role);
  }

  function handleCloseDialog() {
    setEditDialog(false);
    setEditData(null);
    setErrors({});
  }

  const initialValues = {
    name: editData?.name || "",
    email: editData?.email || "",
  };

  const style = {
    color: "#696969",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "24px" /* 150% */,
    letterSpacing: "0.17px",
  };

  const capitalizeFirstLetter = (str) => {
    return str?.charAt(0)?.toUpperCase() + str?.slice(1);
  };

  useEffect(() => {
    dispatch(setAdminsList(data));
  }, [data]);

  useEffect(() => {
    if (location.state?.adminNotificationData) {
      handleRowClick(location.state?.adminNotificationData)
    }
  }, [location.state]);

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: "20px",
      editable: true,
      style: style,
      align: "left",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <span
          style={{ textDecoration: "underline", fontWeight: "bold", cursor: "pointer" }}
          onClick={() => handleRowClick(params.row)}
        >
          {params.row.name.toLowerCase() === "ola tahat"
            ? "Ola T"
            : capitalizeFirstLetter(params.row.name)}
        </span>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: "150px",
      style: style,
      align: "left",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "role",
      headerName: "Roles",
      minWidth: "150px",
      flex: 1,
      style: style,
      align: "left",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <span>{capitalizeFirstLetter(params.row.role)}</span>
      ),
    },
    {
      field: "enabled",
      headerName: "Enable/Disable",
      headerClassName: "super-app-theme--header",
      width: 150,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Switch
            sx={{
              color: colorConfigs?.mainColor,
              "& .MuiSwitch-switchBase.Mui-checked": {
                color: "green",
              },
              "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                backgroundColor: "green",
              },
            }}
            disabled={auth?.currentUser?.uid === params?.row?.fb_uid}
            checked={params.row.enabled}
            onChange={(event) =>
              handleEnableDisableChange(params.row, event.target.checked)
            }
          />
        </div>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      style: style,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        auth?.currentUser?.uid !== params?.row?.fb_uid && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <Box
              style={{
                width: "30px",
                height: "30px",
                backgroundColor: "#F3F8FD",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => handleRowClick(params.row)}
            >
              <AiFillEdit size="20px" style={{ color: "#5B93FF" }} />
            </Box>
            <IconButton
              aria-label="edit"
              onClick={() => handleDeleteClick(params.row)}
            >
              <div
                style={{
                  backgroundColor: "#ffd8d8",
                  display: "flex",
                  borderRadius: "22px",
                  padding: "4px",
                }}
              >
                <DeleteIcon
                  fontSize="small"
                  className="icon"
                  style={{ color: "#E71D36" }}
                />
              </div>
            </IconButton>
          </div>
        )
      ),
    },
  ];





  return (
    <>



      <Error5xx open={isErrorDialogOpen} errCount={errorCount} count={errorDialogCounter} handleClose={handleCloseErrorDialog} tryAgain={tryAgain} />

      <AddNewAdmin fetchDriverss={fetchAdmins} />

      <Dialog
        style={{ zIndex: 10, borderRadius: "30px" }}
        open={deleteConfirmationDialogOpen}
        onClose={() => {
          setDeleteConfirmationDialogOpen(false);
          setErrors({});
        }}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent style={{ padding: "30px" }}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img src={deletee} alt="" />
          </div>
          <DialogContentText
            id="alert-dialog-slide-description"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Typography
              variant="h6"
              style={{
                fontWeight: "bold",
                color: "black",
                marginTop: "10px",
                marginBottom: "8px",
              }}
            >
              Delete Admin
            </Typography>
          </DialogContentText>
          {adminToDelete && (
            <Typography>
              Are you sure you want to delete {adminToDelete?.name}?
            </Typography>
          )}
        </DialogContent>

        <DialogActions
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            marginBottom: "20px",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
        >
          <Button
            variant="outlined"
            style={{
              color: loading ? "gray" : "black",
              borderColor: "lightgray",
              width: "100%",
            }}
            onClick={() => handleDeleteConfirmation(false)}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            style={{
              background: loading ? "gray" : "var(--Error, #FD4343)",
              color: "white",
              width: "100%",
            }}
            onClick={() => handleDeleteConfirmation(true)}
            disabled={loading}
          >
            {loading ? "...Loading" : "Yes, Delete"}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        style={{ zIndex: 10, borderRadius: "30px" }}
        open={isDialogOpen}
        onClose={() => setDialogOpen(false)}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent style={{ padding: "30px" }}>
          <DialogContentText
            id="alert-dialog-slide-description"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Typography
              variant="h6"
              style={{
                fontWeight: "bold",
                color: "black",
                marginTop: "10px",
                marginBottom: "8px",
              }}
            >
              {!adminToUpdate?.enabled
                ? "Enabling Admin Account"
                : "Disabling Admin Account"}
            </Typography>
          </DialogContentText>
          <DialogContentText id="alert-dialog-slide-description">
            {!adminToUpdate?.enabled
              ? `Are you sure you want to enable ${adminToUpdate?.name} as an admin?`
              : `Are you sure you want to disable ${adminToUpdate?.name}?`}
          </DialogContentText>
        </DialogContent>

        <DialogActions
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            marginBottom: "20px",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
        >
          <Button
            variant="outlined"
            style={{
              color: loading ? "gray" : colorConfigs?.sidebar?.bg,
              borderColor: "lightgray",
              width: "100%",
            }}
            onClick={() => handleConfirmation(false)}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            style={{
              background: loading ? "gray" : colorConfigs?.sidebar?.bg,
              color: "white",
              width: "100%",
            }}
            onClick={() => handleConfirmation(true)}
            disabled={loading}
          >
            {loading ? "...Loading" : "Continue"}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        style={{ zIndex: 10, borderRadius: "30px" }}
        open={editDialog}
        onClose={handleCloseDialog}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent style={{ padding: "30px", width: "100%" }}>
          <DialogContentText style={{ width: "100%" }}>
            <Typography
              variant="h6"
              style={{
                fontWeight: "bold",
                color: "black",
                marginTop: "10px",
                marginBottom: "8px",
                width: "500px",
              }}
            >
              Edit Admin
            </Typography>

            <Box display="flex" style={{ width: "100%" }}>
              <SubmitButton
                variant="outlined"
                onClick={() => {
                  setEnabled(true);
                }}
                style={{
                  borderTopLeftRadius: "88px",
                  borderBottomLeftRadius: "88px",
                  borderRight: "1px solid black",
                  borderColor: colorConfigs?.sidebar?.bg,
                  color: enabled ? "white" : colorConfigs?.sidebar?.bg,
                  backgroundColor: enabled
                    ? colorConfigs?.sidebar?.bg
                    : "white",
                }}
                label={"Enable"}
              />
              <SubmitButton
                variant="outlined"
                onClick={() => {
                  setEnabled(false);
                }}
                style={{
                  borderTopRightRadius: "88px",
                  borderBottomRightRadius: "88px",
                  borderColor: colorConfigs?.sidebar?.bg,
                  color: !enabled ? "white" : "#42526E",
                  backgroundColor: !enabled
                    ? colorConfigs?.sidebar?.bg
                    : "white",
                }}
                label={"Disable"}
              />
            </Box>
          </DialogContentText>
          <DialogContentText id="alert-dialog-slide-description">
            {editData && (
              <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={handleSubmit}
              >
                {({
                  handleBlur,
                  handleChange,
                  values,
                  errors,
                  handleSubmit,
                  touched,
                }) => (
                  <ThemeProvider theme={theme}>
                    <Box style={{ width: "100%" }}>
                      <Box
                        component="form"
                        style={{
                          fontFamily: "inter",
                          fontSize: "small",
                          marginTop: "24px",
                          width: "100%",
                        }}
                      >
                        <Grid container spacing={3}>
                          <Grid item md={12} xs={12}>
                            <TextInput
                              fullWidth
                              label={"Name"}
                              styles={fieldStyle}
                              name="name"
                              onChange={handleChange("name")}
                              onBlur={handleBlur("name")}
                              required
                              value={values?.name}
                              variant="outlined"
                              placeholder="Please enter name"
                              filled="true"
                              focused
                              error={formErrors.name?.length > 0}
                            />
                            {formErrors.name?.length > 0 && (
                              <Typography
                                variant="caption"
                                style={{ color: "red" }}
                              >
                                {formErrors.name}
                              </Typography>
                            )}
                          </Grid>
                          <Grid item md={12} xs={12}>
                            <TextInput
                              fullWidth
                              label={"Email"}
                              styles={fieldStyle}
                              name="email"
                              onChange={handleChange("email")}
                              onBlur={handleBlur("email")}
                              required
                              value={values?.email}
                              disabled={true}
                              variant="outlined"
                              placeholder="Please enter email"
                              filled="true"
                              focused
                            />
                          </Grid>
                          <Grid item md={12} xs={12}>
                            <FormControl
                              fullWidth
                              style={{ marginTop: "2px" }}
                              sx={{
                                "& label.Mui-focused": {
                                  color: "rgba(0, 0, 0, 0.6)",
                                  bordercolor: "#BBC1CE",
                                },
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    borderColor: "#BBC1CE",
                                  },
                                  "&:hover fieldset": {
                                    borderColor: "#BBC1CE",
                                  },
                                  "&.Mui-focused fieldset": {
                                    borderColor: "#BBC1CE",
                                    borderWidth: "1px",
                                  },
                                  height: "52px",
                                },
                              }}
                            >
                              <InputLabel
                                id="demo-simple-select-label"
                                style={{
                                  color: "#172B4D",
                                  fontSize: "14.5px",
                                }}
                              >
                                Role
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={role}
                                label="Age"
                                onChange={handleChangeRole}
                                style={{
                                  borderRadius: "10px",
                                  height: "50px",
                                }}
                              >
                                <MenuItem value={"admin"}>Admin</MenuItem>
                                <MenuItem value={"dispatch"}>Dispatch</MenuItem>
                                <MenuItem value={"manager"}>Manager</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>

                        <Box sx={{ marginTop: "50px", mb: "3px" }}></Box>
                      </Box>

                      <p style={{ color: "red" }}>{error}</p>
                      <DialogActions
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                          marginBottom: "20px",
                          paddingLeft: "20px",
                          paddingRight: "20px",
                        }}
                      >
                        <Button
                          variant="outlined"
                          style={{
                            color: loading ? "gray" : colorConfigs?.sidebar?.bg,
                            borderColor: "lightgray",
                            width: "100%",
                          }}
                          onClick={handleCloseDialog}
                        >
                          Cancel
                        </Button>

                        <Button
                          variant="contained"
                          style={{
                            background: loading
                              ? "gray"
                              : colorConfigs?.sidebar?.bg,
                            color: "white",
                            width: "100%",
                          }}
                          onClick={handleSubmit}
                          disabled={loading}
                        >
                          {loading ? "...Loading" : "Update"}
                        </Button>
                      </DialogActions>
                    </Box>
                  </ThemeProvider>
                )}
              </Formik>
            )}
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h1
          style={{
            color: "#000",
            fontSize: "26px",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "137%",
            letterSpacing: "-0.8px",
          }}
        >
          <SupervisorAccountIcon size={"24px"} color="white" /> Admins
        </h1>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "14px",
            marginBottom: "14px",

            width: "55%",
          }}
        >
          <TextField
            fullWidth
            label="Search"
            name="search"
            placeholder="Search By Name"
            value={query}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: "gray" }} />
                </InputAdornment>
              ),
            }}
            variant="outlined"
            sx={{
              "& label.Mui-focused": { color: "rgba(0, 0, 0, 0.6)" },
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: "#A0AEC0",
                  borderWidth: "1px",
                },
                borderRadius: "10px",
                height: "52px",
              },
            }}
          />
          <Box sx={{ minWidth: 120 }}>
            <FormControl
              fullWidth
              style={{ marginTop: "2px" }}
              sx={{
                "& label.Mui-focused": { color: "rgba(0, 0, 0, 0.6)" },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#A0AEC0",
                  },
                  borderRadius: "10px",
                  height: "52px",
                },
              }}
            >
              <InputLabel id="demo-simple-select-label">
                Roles
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={type}
                label="Status"
                onChange={handleTypeChange}
                style={{ borderRadius: "10px", height: "50px" }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      position: 'fixed',
                    },
                  },
                  disableScrollLock: true,
                }}
              >
                <MenuItem value={"all"}>All</MenuItem>
                <MenuItem value={"admin"}>Admin</MenuItem>
                <MenuItem value={"manager"}>Manager</MenuItem>
                <MenuItem value={"dispatch"}>Dispatch</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </div>
      </div>

      <DynamicTable
        columns={columns}
        data={data}
        count={count}
        page={page}
        rowsLimits={rowsLimits}
        pageChange={handlePageChange}
        rowsChange={handleRowsChange}
        pageType="admin"
      />

      <Loading
        loading={loading && admins.length === 0}
        background="rgba(86, 100, 210, 0.1)"
        loaderColor={colorConfigs.sidebar?.bg}
      />

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleSnackbarClose} severity="error">
          {snackbarErrorMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
