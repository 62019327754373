import { Box, ThemeProvider } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import GlobalModal from "./components/common/GlobalModal";
import MainLayout from "./components/layout/MainLayout";
import colorConfigs from "./configs/colorConfigs";
import { auth } from "./configs/firebase";
import theme from "./configs/theme";
import RideTrack from "./pages/Ride-Tracking/RideTrack";
import CheckEmail from "./pages/login/checkEmail";
import ForgotPassword from "./pages/login/forgotPassword";
import Login from "./pages/login/login";
import SetPassword from "./pages/setPassword/setPassword";
import { loggedIn } from "./redux/features/appStateSlice";
import { persistor } from "./redux/store";
import { routes } from "./routes";
import { setCountry } from "./redux/features/countrySlice";
import { setlicAndConfigSlice } from "./redux/features/licAndConfigSlice";
import { db } from "./configs/firebase";
import PanicButton from "./components/PanicButton";
import { GOOGLE_API_KEY } from "./utils/constant";
import LoadGoogleMaps from "./components/common/LoadGoogleMaps";
import L from "leaflet";
import "leaflet-draw";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import { SnackbarProvider } from "notistack";
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const dispatch = useDispatch();
  const logIn = useSelector((state) => state.appState.isLoggedIn);

  useEffect(() => {
    document.addEventListener('wheel', { passive: false });

    if (colorConfigs && colorConfigs?.sidebar?.bg) {
      document.documentElement.style.setProperty('--colorConfigs', colorConfigs?.sidebar?.bg);
      document.documentElement.style.setProperty('--category-box-bg', colorConfigs?.sidebar?.ligthBg);
    }

    if (process.env.REACT_APP_ENV === 'women_first') {
      dispatch(setCountry('CA'));
    } else {
      dispatch(setCountry('US'));
    }

    auth.onAuthStateChanged((user) => {
      if (user) {
        localStorage.setItem("userID", user.uid);
        if (localStorage.getItem("isAuth") == null) {
          localStorage.setItem("isAuth", true);
          dispatch(loggedIn({ login: user?.id }));
        }
      } else {
        localStorage.removeItem("isAuth");
        localStorage.removeItem("userID");
      }
    });
  }, [auth, dispatch]);

  const isAuth = localStorage.getItem("isAuth");

  useEffect(() => {
    if (logIn) {
      const unsubscribe = db.collection('licenses_and_config')
        .doc("licenses_and_config")
        .onSnapshot(async (doc) => {
          const licAndConfig = doc.data();
          dispatch(setlicAndConfigSlice(licAndConfig));
        });
      return () => unsubscribe();
    }
  }, [logIn, dispatch]);

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <SnackbarProvider
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          autoHideDuration={1000}
          maxSnack={3}
        >
          <Box sx={{ backgroundColor: colorConfigs.mainBg }}>
            <LoadGoogleMaps apiKey={GOOGLE_API_KEY} />
            <GlobalModal />
            <PanicButton />
            <PersistGate persistor={persistor} loading={null}>
              <ScrollToTop /> {/* ScrollToTop component to handle scrolling */}
              <Routes>
                <Route path="/" element={isAuth ? <MainLayout /> : <Login />}>
                  {routes}
                </Route>
                <Route path="/setPassword/:token" element={<SetPassword />} />
                <Route path="/forgotPassword" element={<ForgotPassword />} />
                <Route path="/checkEmail" element={<CheckEmail />} />
                <Route path="/track-location/:id" element={<RideTrack />} />
              </Routes>
            </PersistGate>
          </Box>
        </SnackbarProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
