import React, { useState } from 'react';
import {
    Alert,
    Avatar,
    Box,
    Button,
    ButtonGroup,
    CircularProgress,
    Grid,
    Snackbar,
    TextField,
    Typography
} from '@mui/material';
import { styled } from '@mui/system';
import { FiPlus } from "react-icons/fi";
import { IoArrowDownSharp } from 'react-icons/io5';
import { VscArrowSwap } from "react-icons/vsc";
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import logoo from "../../assets/dashboard.png";
import logowhite from "../../assets/womens-first/logowhite.png";
import BlueButton from '../../components/common/Buttons/BlueButton';
import colorConfigs from '../../configs/colorConfigs';
import { closeModal } from '../../redux/features/modalSlice';
import { postMethod } from '../Hook/UseCustomApi';
import { setAddNewBalance, setWalletBalance } from '../../redux/features/appStateSlice';

const StyledButton = styled(Button)({
    margin: '8px',
});

const validationSchema = yup.object({
    amount: yup
        .number('Enter a valid amount')
        .required('Amount is required')
        .positive('Amount must be greater than zero'),
    chargeType: yup
        .string()
        .oneOf(['add', 'deduct'], 'Invalid amount type')
        .required('Amount type is required'),
    note: yup.string().optional(),
});

const RiderBalanceDialog = () => {
    const [isAdding, setIsAdding] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [apiError, setApiError] = useState(""); // New state for API error message
    const selectedCustomer = useSelector((state) => state.appState.selectedChat);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");

    const showSnackbar = (message) => {
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };
    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };
    const adminData = useSelector((state) => state.appState.userData);
    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            uid: '',
            created_by: '',
            amount: '',
            chargeType: isAdding ? 'add' : 'deduct',
            userType: 'customer',
            reason: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            setIsLoading(true);
            const data = {
                uid: selectedCustomer?._id,
                created_by: adminData?._id,
                amount: values.amount,
                chargeType: isAdding ? 'add' : 'deduct',
                userType: 'customer',
                reason: values.note,
            };
            try {
                await postMethod({
                    url: `payment/chargeWallet/chargeWalletWithAdmin`,
                    body: data,
                })
                const payload = {
                    amount: values.amount,
                    transaction: values.chargeType
                };
                dispatch(setWalletBalance(payload))
                setIsLoading(false);
                dispatch(setAddNewBalance())
                dispatch(closeModal());
            } catch (error) {
                console.log(error.response.data.message)
                setApiError(error.response.data.message || 'Error submitting form'); // Set API error message
                console.error('Error submitting form:', error);
                setIsLoading(false);
            }
        },
    });

    return (
        <Box
            sx={{
                width: 650,
                maxWidth: '95%',
                margin: 'auto',
                padding: 3,
                backgroundColor: 'white',
                borderRadius: 2,
                boxShadow: 3,
                minHeight: '60vh'
            }}
        >
            <Typography sx={{ color: '#172B4D', fontSize: '20', fontWeight: '700' }} mb={2}>
                New Transaction
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 5 }}>
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Avatar src={process.env.REACT_APP_ENV === 'women_first' ? logowhite : logoo} sx={{ width: 100, height: 100, background: colorConfigs?.sidebar?.bg }} />
                    <Typography sx={{ fontSize: 12, fontWeight: 400, color: '#7A869A', mt: 0.5 }}>Company</Typography>
                    <Typography sx={{ color: '#172B4D', fontSize: 14, fontWeight: 700 }}>{process.env.REACT_APP_ENV === 'women_first' ? 'Women First' : 'Ride Wyze'}</Typography>
                </Box>
                <Grid item xs={6} display="flex" flexDirection="column" alignItems="center">
                    <VscArrowSwap size={20} color={colorConfigs?.sidebar.bg} />
                </Grid>
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Avatar src={selectedCustomer?.profile_picture} sx={{ width: 100, height: 100 }} />
                    <Typography sx={{ fontSize: 12, fontWeight: 400, color: '#7A869A', mt: 0.5 }}>Customer</Typography>
                    <Typography sx={{ color: '#172B4D', fontSize: 14, fontWeight: 700 }}>{selectedCustomer?.first_name + " " + selectedCustomer?.last_name}</Typography>
                </Box>
            </Box>
            <form onSubmit={formik.handleSubmit}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'start', gap: 2, width: '100%', mt: 2 }}>
                    <TextField
                        label="Amount * "
                        type="number"
                        fullWidth
                        variant="outlined"
                        name="amount"
                        value={formik.values.amount}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.amount && Boolean(formik.errors.amount)}
                        helperText={formik.touched.amount && formik.errors.amount}
                        InputProps={{
                            startAdornment: (
                                <Typography mr={1} sx={{ fontSize: '1rem', color: '#2c3e50' }}>
                                    $
                                </Typography>
                            ),
                            sx: {
                                borderRadius: '8px',
                                height: '40px',
                                width: '100%',
                                '& .MuiOutlinedInput-root': {
                                    fontSize: '1rem',
                                    '& fieldset': {
                                        borderColor: '#c0c0c0',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: colorConfigs.sidebar.bg,
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: colorConfigs.sidebar.bg,
                                    },
                                },
                                '& input': {
                                    padding: '10px 12px',
                                },
                            },
                        }}
                        InputLabelProps={{
                            sx: {
                                fontSize: '1rem',
                                color: '#808080',
                            },
                        }}
                    />

                    <ButtonGroup sx={{ border: `1px solid ${colorConfigs.sidebar.bg}`, borderRadius: 25, height: '40px', width: '100%', p: 0 }}>
                        <Button
                            onClick={() => {
                                setIsAdding(true);
                                formik.setFieldValue('chargeType', 'add');
                            }}
                            sx={{
                                backgroundColor: isAdding ? colorConfigs.sidebar.bg : 'transparent',
                                borderColor: colorConfigs.sidebar.bg,
                                color: isAdding ? '#FFFFFF' : '#7A869A',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '50%',
                                borderRadius: '25px 0 0 25px',
                                textTransform: 'capitalize',
                                '&:hover': {
                                    backgroundColor: isAdding ? colorConfigs.sidebar.bg : 'transparent',
                                    color: !isAdding ? colorConfigs.sidebar.bg : '#FFF'
                                },
                            }}
                        >
                            <FiPlus size={18} />
                            Add Money
                        </Button>
                        <Button
                            onClick={() => {
                                setIsAdding(false);
                                formik.setFieldValue('chargeType', 'deduct');
                            }}
                            sx={{
                                backgroundColor: !isAdding ? colorConfigs.sidebar.bg : 'transparent',
                                borderColor: colorConfigs.sidebar.bg,
                                color: !isAdding ? '#FFFFFF' : '#7A869A',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '51%',
                                padding: '0 2px 0 0',
                                textTransform: 'capitalize',
                                borderRadius: '0 25px 25px 0',
                                '&:hover': {
                                    backgroundColor: !isAdding ? colorConfigs.sidebar.bg : 'transparent',
                                    color: isAdding ? colorConfigs.sidebar.bg : '#FFF'
                                },
                            }}
                        >
                            <IoArrowDownSharp size={18} />
                            Deduct Money
                        </Button>
                    </ButtonGroup>
                </Box>

                <TextField
                    InputProps={{
                        style: { borderRadius: '8px' }
                    }}
                    label="Note"
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    multiline
                    rows={3}
                    name="note"
                    value={formik.values.note}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.note && Boolean(formik.errors.note)}
                    helperText={formik.touched.note && formik.errors.note}
                />

                {/* Conditionally render the API error message */}
                {apiError && (
                    <Box mt={2}>
                        <Alert severity="error">{apiError}</Alert>
                    </Box>
                )}

                <Box display="flex" justifyContent="start" mt={2} gap={2}>
                    <Button onClick={() => dispatch(closeModal())} variant="outlined" color="primary" sx={{ width: 150, borderRadius: 2 }}>
                        Cancel
                    </Button>
                    <BlueButton
                        type="submit"
                        sx={{
                            width: 150,
                            borderRadius: 2,
                            '&.Mui-disabled': {
                                color: 'white',
                                backgroundColor: colorConfigs?.sidebar?.bg,
                            },

                        }}
                        disabled={isLoading}
                    >
                        {isLoading ? 'processing...' : 'Submit'}
                    </BlueButton>
                </Box>
            </form>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <Alert onClose={handleSnackbarClose} severity="error">
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box >
    );
};

export default RiderBalanceDialog;
