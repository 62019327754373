import * as React from "react"
import { memo } from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={180}
    height={164}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M127.199 46.476c1.279-2.401 2.373-4.393 3.651-6.794.197-.369.685-1.064 1.118-.766.31.213.232 1.351-.91 3.132 1.099-1.917 1.639-2.755 2.738-4.672.139-.243.324-.515.582-.514.265.002.451.316.444.607-.007.291-.15.557-.288.808-.796 1.438-.727 1.607-1.522 3.045.995-1.887 1.022-2.143 2.232-3.873.2-.286.497-.605.803-.488.689.374-.098 1.604-.69 2.573-.339.555-.668 1.08-1.514 2.367.893-1.252 1.193-1.81 2.088-3.06.168-.234.351-.48.608-.576.256-.095.598.044.631.337.021.187-.085.362-.185.517-.929 1.434-1.148 2.033-2.077 3.467.69-1.03.763-1.151 1.624-2.013.256-.255.669-.502.902-.23.215.251.022.661-.171.94-1.91 2.746-3.494 4.628-5.404 7.374"
    />
    <path
      stroke="#010101"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M127.199 46.476c1.279-2.401 2.373-4.393 3.651-6.794.197-.369.685-1.064 1.118-.766.31.213.232 1.351-.91 3.132 1.099-1.917 1.639-2.755 2.738-4.672.139-.243.324-.515.582-.514.265.002.451.316.444.607-.007.291-.15.557-.288.808-.796 1.438-.727 1.607-1.522 3.045.995-1.887 1.022-2.143 2.232-3.873.2-.286.497-.605.803-.488.689.374-.098 1.604-.69 2.573-.339.555-.668 1.08-1.514 2.367.893-1.252 1.193-1.81 2.088-3.06.168-.234.351-.48.608-.576.256-.095.598.044.631.337.021.187-.085.362-.185.517-.929 1.434-1.148 2.033-2.077 3.467.69-1.03.763-1.151 1.624-2.013.256-.255.669-.502.902-.23.215.251.022.661-.171.94-1.91 2.746-3.494 4.628-5.404 7.374"
    />
    <path
      fill="#fff"
      d="M131.059 42.048c.381.381.731.802.96 1.29.229.486.329 1.045.2 1.567"
    />
    <path
      stroke="#010101"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M131.059 42.048c.381.381.731.802.96 1.29.229.486.329 1.045.2 1.567"
    />
    <path
      fill="#fff"
      stroke="#010101"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m76.45 149.134 46.06-39.502s-29.229-20.063-45.091-28.51c-15.971 8.847-31.06 18.615-44.93 30.278 1.126 2.396 43.961 37.734 43.961 37.734h0Z"
    />
    <path
      fill="#006496"
      stroke="#010101"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M89.36 59.26c2.618-.583 5.481-1.424 8.162-1.32 2.334.092 4.611.704 6.88 1.254 3.982.965 8.009 1.749 12.064 2.348a863.824 863.824 0 0 0 10.741-17.623c2.485 1.608 4.902 2.879 7.315 4.148-1.609 4.212-3.637 8.373-5.787 12.47-2.125 4.053-4.477 8.17-8.169 10.902-.666.493-1.379.94-2.165 1.21-1.016.347-2.112.385-3.188.37-5.684-.08-11.246-1.54-16.74-2.991-.906 9.37-1.458 18.666-2.01 27.962-10.035.906-20.188 1.92-30.31 3.183-.1-2.687.02-5.45-.016-8.211-2.818.494-10.095 2.992-13.74 1.539-.695-.277-1.356-.682-1.818-1.266-.682-.86-.865-2.016-.801-3.108.1-1.73.753-3.38 1.483-4.953 2.393-5.158 5-9.572 8.277-14.228.687-.975 1.377-1.955 2.2-2.82 3.43-3.61 8.656-4.786 13.388-5.621a27.359 27.359 0 0 0 8.233 3.343c.359.082.737.155 1.092.055.411-.116.72-.445 1.004-.762 2.024-2.253 3.726-4.35 3.906-5.882h0Z"
    />
    <path
      stroke="#010101"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M67.716 77.46s-3.157 4.816-4.735 6.878l4.99-1.22-.255-5.658Z"
    />
    <path
      fill="#010101"
      stroke="#010101"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M87.362 45.304c-.327-.8-.663-1.606-1.142-2.328-.48-.722-1.117-1.364-1.912-1.72-1.19-.53-2.568-.363-3.847-.108-.82.163-1.64.36-2.417.668a8.875 8.875 0 0 0-4.469 3.932c-.972 1.769-1.301 3.877-.907 6.076-.15-.28-2.36-.384-3.417.664-1.11 1.101-1.693 2.254-1.5 3.799.194 1.545 1.186 2.963 2.56 3.717s3.093.839 4.552.26c.645-.257 1.252-.646 1.675-1.193.48-.62.698-1.402.816-2.175.182-1.197.277-1.884-.336-2.937 1.138-.31 2.264-.696 3.29-1.276 1.025-.58 1.952-1.363 2.58-2.354.207-.327.384-.69.308-.971.798-1.752 2.31-3.17 4.166-4.053h0Z"
    />
    <path
      fill="#fff"
      stroke="#010101"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M77.909 51.917a.987.987 0 0 0-1.003-.603c-.464.052-.826.433-1.046.842-.336.624-.431 1.396-.157 2.05.275.653.953 1.148 1.667 1.118.397-.017.8-.208 1.012-.472.077 2.29.154 4.58.152 6.853-1.132.233-2.257.504-3.522.714l1.134 5.589c.131.646.302 1.355.845 1.735.337.236.762.302 1.172.345 3.563.377 7.133-.459 10.598-1.364.46-.12.939-.252 1.292-.567.586-.524.668-1.393.695-2.174.084-2.354.046-4.711-.172-6.98-1.692.32-3.025.728-4.82 1.233a29.222 29.222 0 0 1 0-3.309c1.534-.642 2.656-1.766 3.086-3.36.209-.772.157-1.589.063-2.382a21.41 21.41 0 0 0-1.718-6.276c-.982-.067-1.968-.195-2.9-.51a7.961 7.961 0 0 1-2.56-1.454c.232 1.684-.293 3.377-.929 4.955-.574 1.424-1.302 2.878-2.889 4.017h0Z"
    />
    <path
      stroke="#010101"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M85.422 47.78c.405.606.806 1.215 1.204 1.827.037.058.077.124.062.192a.254.254 0 0 1-.075.115 2.167 2.167 0 0 1-.957.566M82.684 56.96c.954.172 2.287.15 3.233-.065"
    />
    <path
      fill="#fff"
      stroke="#010101"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M77.745 94.267a829.831 829.831 0 0 1 32.884-7.018c.58.194 1.117.407 1.649.618l3.553 1.41a650.35 650.35 0 0 0-4.262-20.742c-11.775 1.606-23.912 3.91-36.3 6.306a93.722 93.722 0 0 1 2.477 19.426h0Z"
    />
    <path
      stroke="#010101"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M82.555 78.976a829.91 829.91 0 0 1 26.174-4.662M83.508 82.938c8.514-1.389 16.432-3.05 24.946-4.44M83.508 87.197c4.377-.523 11.202-2.006 15.511-2.931"
    />
    <path
      fill="#010101"
      stroke="#010101"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M66.65 99.655c-1.675 8.962-3.954 19.547-5.822 28.372a290.682 290.682 0 0 0 13.791 8.247c2.174-7.05 3.294-11.703 5.828-21.436 1.95 6.011 3.588 12.122 5.063 18.226l9.37-5.764c1.208-.743 1.808-1.085 2.845-2.051-.099.11-1.087-16.754-1.457-28.203-8.782.612-29.453 2.976-29.619 2.609Z"
    />
    <path
      fill="#D1E4EE"
      stroke="#010101"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m26.73 162.957 86.373-.12 9.403-53.205L77.538 137.8s-39.69-22.867-45.053-26.4c-1.013 10.961-5.754 51.557-5.754 51.557h0Z"
    />
    <path
      fill="#55D087"
      stroke="#010101"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M19.355 162.764c-.958-3.527-2.185-6.73-3.11-10.266-.926-3.537-1.326-7.333-.201-10.812.93-2.878 3.38-5.644 6.427-5.528 1.39.053 2.687.698 3.862 1.435 4.26 2.674 7.522 6.698 9.972 11.067 2.45 4.368 3.804 9.539 5.365 14.297-6.45.15-12.758-.135-22.314-.193h0Z"
    />
    <path
      fill="#FF8B37"
      stroke="#010101"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M158.401 6.805a71.835 71.835 0 0 1-1.104 7.32c2.921-1.64 5.863-3.24 8.823-4.8a17.92 17.92 0 0 1 .662-4.632c-2.382.442-4.726 1.105-8.381 2.112Z"
    />
    <path
      stroke="#010101"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M137.578 33.412c3.102-6.522 8.126-12.131 14.292-15.956M142.496 18.736a31.987 31.987 0 0 1 7.88-7.04"
    />
    <path
      fill="#55D087"
      stroke="#010101"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M19.882 162.747c-1.49-3.912-4.222-12.445-7.085-15.516-1.563-1.677-3.3-3.27-5.418-4.169-.964-.41-2.015-.669-3.06-.574-1.044.095-2.08.578-2.689 1.425-.552.767-.707 1.741-.79 2.679-.41 4.656.498 11.874 3.1 16.366 3.909-.139 11.056-.037 15.942-.21v-.001Z"
    />
    <path
      stroke="#010101"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M20.988 136.275c1.909 9.182 6.51 17.608 8.883 26.683M3.977 142.587a112.007 112.007 0 0 0 6.599 20.04"
    />
    <path
      fill="#FF8B37"
      stroke="#010101"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M159.248 16.304s1.889-6.008 3.088-8.908a61.603 61.603 0 0 1-8.793-2.223 971.384 971.384 0 0 1 25.263-4.93c-2.605 3.024-19.558 16.06-19.558 16.06Z"
    />
    <path
      fill="#FF8B37"
      d="M162.16 7.268c5.593-2.636 9.494-4.14 16.649-7.026L162.16 7.268Z"
    />
    <path
      stroke="#010101"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M162.16 7.268c5.593-2.636 9.494-4.14 16.649-7.026"
    />
    <path
      fill="#55D087"
      d="M7.484 153.607a55.576 55.576 0 0 1 5.15-5.966l-5.15 5.966Z"
    />
    <path
      stroke="#010101"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M7.484 153.607a55.576 55.576 0 0 1 5.15-5.966"
    />
    <path
      fill="#55D087"
      d="M27.023 153.54c1.585-3.276 3.613-7.344 5.46-10.481l-5.46 10.481Z"
    />
    <path
      stroke="#010101"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M27.023 153.54c1.585-3.276 3.613-7.344 5.46-10.481"
    />
    <path
      fill="#55D087"
      d="M16.805 154.289a145.31 145.31 0 0 1 11.485 3.503l-11.485-3.503Z"
    />
    <path
      stroke="#010101"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M16.805 154.289a145.31 145.31 0 0 1 11.485 3.503"
    />
    <path
      fill="#fff"
      stroke="#010101"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M80.602 84.926c-.758.19-.879.289-2.688.69 1.821-.342 1.78-.383 3.6-.719.343-.062.709-.124 1.013-.015.304.11.492.462.28.715-.136.16-.376.21-.59.271-1.499.431-1.927.581-3.792 1.012 1.307-.279 1.581-.441 3.007-.493.436-.018 1.006.085.985.462-.015.351-.525.532-.927.612-3.948.777-4.925.803-8.876 1.58l-.03-.152c-.103-2.037-.357-3.364-.428-5.639 2.394-.723 1.647-.392 4.486-1.36.035 0 .218 1.154.236 1.327.003.013-.018.082-.33.217.69-.248 1.678-.55 4.15-1.12.328-.075.805-.034.97.17.184.228.138.608-.11.83-.156.143-2.414.667-4.23 1.123.475-.132 3.02-.784 4.23-1.019.413-.08 1.102-.038 1.276.272.317.718-.91.902-2.232 1.236h0Z"
    />
    <path
      stroke="#010101"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M58.16 85.475c5.719-1.377 10.968-2.655 13.978-3.501 0 4.06.463 5.38.463 9.287-2.436.491-3.816 1.046-6.604 1.726M8.293 123.39l2.665-1.636"
    />
    <path
      fill="#010101"
      d="M127.257 94.053a.979.979 0 0 0-.926.42.96.96 0 0 0-.016 1.008c.332.54 1.243.565 1.62.056.376-.51.103-1.38-.678-1.484Z"
    />
    <path
      stroke="#010101"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m113.793 38.418 1.175-.958c-.049.027-.097.054-1.175.958ZM27.488 62.94l1.223.706"
    />
    <path
      fill="#010101"
      d="M34.027 88.383a.699.699 0 0 0-.661.3.685.685 0 0 0-.011.72c.237.386.887.404 1.156.04.27-.364.074-.985-.484-1.06ZM57.524 44.675a.419.419 0 0 0-.397.18.41.41 0 0 0-.006.432c.142.232.532.242.694.024a.409.409 0 0 0-.29-.636Z"
    />
  </svg>
)
const WalletVector = memo(SvgComponent)
export default WalletVector
