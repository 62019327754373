import SearchIcon from "@mui/icons-material/Search";
import {
    Alert,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    ListSubheader,
    MenuItem,
    Select,
    Snackbar,
    TextField,
    Typography
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import DynamicTable from "../table/table";

import { Delete as DeleteIcon } from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Loading from "react-fullscreen-loading";
import { useDispatch, useSelector } from "react-redux";
import deletee from "../../assets/images/delete.png";
import colorConfigs from "../../configs/colorConfigs";
import { setContactUsList } from "../../redux/features/appStateSlice";
import { formatDate } from "../../utils/localization/dateTimeUtils";
import { NewFormatPhoneNumber } from "../../utils/localization/NewPhoneUtils";
import { DeleteMethod, getMethod, putMethod } from "../Hook/UseCustomApi";
import Error5xx from "../../components/common/HandelError5xx";

export default function ContactUS() {

    const dispatch = useDispatch();
    const list = useSelector((state) => state.appState.contactUsList);
    const [data, setData] = useState(list);
    const [count, setCount] = useState(1);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    let currentPage = useRef();
    let currentLimit = useRef(10);
    let currentKeyword = useRef("");
    let currentType = useRef("all");
    let currentReadType = useRef("all");
    const [deleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] = useState(false);
    const [unReadConfirmationDialogOpen, setUnReadConfirmationDialogOpen] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [itemToUpdate, setItemToUpdate] = useState(null);
    const [query, setQuery] = useState("");
    const [readedFilterType, setReadedFilterType] = useState("all");
    const rowsLimits = [5, 10, 25, 100];
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarErrorMessage, setSnackbarErrorMessage] = useState("");
    const countryCode = useSelector((state) => state.country.selectedCountry);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [cardData, setCardData] = useState({})
    const [type, setType] = useState("all");
    const [openFilter, setOpenFilter] = useState(false);
    const [filterValue, setFilterValue] = useState("Filter By :");

    //handle error5xx
    const [tryAgain, setTryAgain] = useState();
    const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
    const [errorCount, setErrorCount] = useState(0);
    const [errorDialogCounter, setErrorDialogCounter] = useState(0);
    const handleError5xx = () => {
        setIsErrorDialogOpen(true);
        setErrorCount(prevCount => {
            const newCount = prevCount + 1;
            if (newCount === 1) {
                setErrorDialogCounter(5);
            } else if (newCount === 2) {
                setErrorDialogCounter(10);
            }
            return newCount;
        });
    };
    const handleCloseErrorDialog = () => {
        setIsErrorDialogOpen(false);
        if (errorCount <= 2) {
            handleErrorCountByClose()
        }

    };
    const handleErrorCountByClose = () => {
        setErrorCount(prevCount => {
            const newCount = prevCount - 1;
            if (newCount === 1) {
                setErrorDialogCounter(5);
            } else if (newCount === 2) {
                setErrorDialogCounter(10);
            }
            return newCount;
        });
    }





    const handleOpenFilter = () => {
        setOpenFilter(true);
    };

    const handleClearFilter = () => {
        setType("all");
        setReadedFilterType("all");
        currentType.current = "all";
        currentReadType.current = "all";
        currentKeyword.current = "";
        currentPage.current = 1;
        setQuery("");
        setFilterValue("Filter By :");
        fetchList();
    };

    const handleCloseFilter = () => {

        if (type === "all" && readedFilterType !== "all") {
            if (readedFilterType === "false") {
                setFilterValue("Unread")
            } else {
                setFilterValue("Read")
            }
        } else if (type !== "all" && readedFilterType === "all") {
            setFilterValue(capitalizeFirstLetter(type))
        } else if (type !== "all" && readedFilterType !== "all") {
            if (readedFilterType === "false") {
                setFilterValue(`${capitalizeFirstLetter(type)} & Unread `)
            } else {
                setFilterValue(`${capitalizeFirstLetter(type)} & Read `)
            }
        } else {
            setFilterValue("Filter by :")
        }
        setOpenFilter(false);

    };



    const handleVisibilityOffIconClick = (data) => {
        setItemToUpdate(data);
        setCardData(data)
        if (data?.read) {
            setUnReadConfirmationDialogOpen(true)
        }
    }

    const handleUnReadConfirmation = (confirmed) => {
        setUnReadConfirmationDialogOpen(false);
        if (confirmed) {
            handleReadStatuse(false)
        }
    };



    const handleClose = () => {
        setDialogOpen(false);
        handleReadStatuse(true)
    };

    const handleTypeChange = (event) => {
        setType(event.target.value);
        currentType.current = event.target.value;
        currentPage.current = 1;
        fetchList();
    };


    const handleReadedFilter = (event) => {
        setReadedFilterType(event.target.value);
        currentReadType.current = event.target.value;
        currentPage.current = 1;
        fetchList();
    };

    const handleSearch = (event) => {
        setQuery(event.target.value);
        currentKeyword.current = event.target.value;
        currentPage.current = 1;
        setPage(1);
        fetchList();
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const showSnackbar = (message) => {
        setSnackbarErrorMessage(message);
        setSnackbarOpen(true);
    };

    const handleDeleteClick = (item) => {
        setItemToDelete(item);
        setDeleteConfirmationDialogOpen(true);
    };

    const handleClickOpen = (open, data) => {
        setItemToUpdate(data);
        setCardData(data);
        setDialogOpen(open)

    };



    const getParams = () => {
        return {
            page: currentPage.current,
            limit: currentLimit.current,
            search_name: currentKeyword.current,
            search_phone_number: currentKeyword.current,
            search_email: currentKeyword.current,
            created_by: currentType.current,
            read: currentReadType.current,
        };
    };



    useEffect(() => {
        currentPage.current = 1;
        currentLimit.current = 10;
        currentKeyword.current = "";
        currentType.current = "all";
        currentReadType.current = "all"
        fetchList();
        window.scrollTo({ top: 0 });
    }, []);





    const fetchList = async () => {

        setLoading(true);
        getMethod({
            url: "admin/contact-us",
            params: getParams(),
            handleErrorMessage: showSnackbar,
        })
            .then((response) => {
                setLoading(false);
                dispatch(setContactUsList(response?.data));
                setData(response?.data);
                setCount(response?.count);
            })
            .catch((err) => {
                console.error("Error fetching more stores:", err);
                setLoading(false);
                if (err === "Internal Server Error: Server error occurred.") {
                    handleError5xx();
                    setTryAgain(() => fetchList);
                }
            });
    };

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
        currentPage.current = newPage;
        fetchList();
    };

    const handleRowsChange = (rows) => {
        currentPage.current = 1;
        currentLimit.current = rows;
        setPage(1);
        fetchList();
    };

    const handleReadStatuse = async (data) => {


        const newValue = data;
        const body = JSON.stringify({ read: newValue });

        putMethod({
            url: `admin/contact-us/${cardData?._id}`,
            body,
            handleErrorMessage: showSnackbar,
        })
            .then((res) => {
                if (res) {
                    setData((prevData) => {
                        const updatedData = prevData?.map((item) => {
                            if (item._id === itemToUpdate?._id) {
                                return {
                                    ...item,
                                    read: newValue,
                                };
                            }
                            return item;
                        });
                        return updatedData;
                    });
                }
                else {
                    console.log("Response Error");
                }

            })
            .catch((error) => {
                console.error(error);
                if (error === "Internal Server Error: Server error occurred.") {
                    handleError5xx();
                    setTryAgain(() => () => handleReadStatuse(data));
                }
            });


    };



    const handleDeleteConfirmation = async (confirmed) => {
        setDeleteConfirmationDialogOpen(false);
        if (confirmed) {
            try {
                DeleteMethod({
                    url: `admin/contact-us/${itemToDelete?._id}`,
                    handleErrorMessage: showSnackbar,
                }).then((res) => {
                });

                setContactUsList((prevData) =>
                    prevData?.filter((item) => item._id !== itemToDelete?._id)
                );
                console.log("Item deleted successfully!");
            } catch (error) {
                console.error("Error deleting ride:", error);
                if (error === "Internal Server Error: Server error occurred.") {
                    handleError5xx();
                    setTryAgain(() => () => handleDeleteConfirmation(confirmed));
                }
            }
            fetchList()
            setLoading(false);
        }
    };









    const style = {
        color: "#495974",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "18px" /* 150% */,
        letterSpacing: "0.17px",

    };

    const capitalizeFirstLetter = (str) => {
        return str?.charAt(0)?.toUpperCase() + str?.slice(1);
    };


    const columns = [
        {
            field: "info",
            headerName: "Name",
            flex: 1,
            minWidth: "150px",
            editable: true,
            style: style,
            align: "left",
            headerClassName: "super-app-theme--header",
            renderCell: (params) => (
                <span style={{
                    color: "#172B4D",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "18px" /* 150% */, textDecoration: "underline", cursor: "pointer"
                }}
                    onClick={() => (handleClickOpen(true, params.row))}
                >
                    {params?.row?.name === "" ||
                        params?.row?.name === undefined ? (
                        <span>Not Assigned</span>
                    ) : (
                        <>
                            {capitalizeFirstLetter(params?.row?.name)}{" "}

                        </>
                    )}
                </span>
            ),
        },
        {
            field: "email",
            headerName: "Email",
            flex: 1,

            style: style,
            align: "left",
            headerClassName: "super-app-theme--header",
            renderCell: (params) => {
                return <Box>
                    <a
                        style={{ color: "#495974", textDecoration: "none" }}
                        href={`mailto:${params?.row?.email}`} >
                        {params?.row?.email}
                    </a>                </Box>;
            },

        },
        {
            field: "phone_number",
            headerName: "Phone",
            minWidth: "180px",
            width: "180px",
            flex: 1,
            style: style,
            align: "left",

            headerClassName: "super-app-theme--header",
            renderCell: (params) => {
                const formattedPhoneNumber = NewFormatPhoneNumber(
                    params.row.phone_number
                );
                return <Box>
                    <a
                        style={{ color: "#495974", textDecoration: "none" }}
                        href={`tel:${formattedPhoneNumber}`} >
                        {formattedPhoneNumber}
                    </a>                  </Box>;
            },
        },
        {
            field: "type",
            headerName: "Type",
            flex: 1,
            minWidth: "20px",
            editable: true,
            style: style,
            align: "left",
            headerClassName: "super-app-theme--header",
            renderCell: (params) => (
                <span
                    onClick={() => (handleClickOpen(true, params.row))}
                >
                    {capitalizeFirstLetter(params?.row?.created_by)}
                </span>
            ),
        },
        {
            field: "Subject",
            headerName: "Subject",
            flex: 1,
            minWidth: "20px",
            editable: true,
            style: style,
            align: "left",
            headerClassName: "super-app-theme--header",
            renderCell: (params) => (
                <span
                    onClick={() => (handleClickOpen(true, params.row))}
                >{params?.row?.subject === "" ||
                    params?.row?.subject === undefined ? (
                    <span>default</span>
                ) : (
                    <>
                        {capitalizeFirstLetter(params?.row?.subject)}{" "}

                    </>
                )}
                </span>
            ),
        },
        {
            field: "date",
            headerName: "Date Received",
            minWidth: "155px",
            flex: 1,
            style: style,
            align: "left",
            headerClassName: "super-app-theme--header",
            renderCell: (params) => {
                const dateObject = new Date(params?.row?.created_at);
                const formattedDate = formatDate(dateObject, countryCode);
                // const formattedDateSplit = formattedDate?.split(",")[0];
                return (
                    <span >
                        {formattedDate}
                    </span>
                );
            },
        },

        {
            field: "message",
            headerName: "Message",
            minWidth: "150px",
            flex: 1,
            style: style,
            align: "left",
            overflow: "hidden",
            textOverflow: "ellipsis",
            headerClassName: "super-app-theme--header",
            renderCell: (params) => (
                <span style={{
                    display: "block",
                    whiteSpace: "nowrap",
                    minWidth: "100%",
                    maxWidth: "150px",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                }}
                    onClick={() => (handleClickOpen(true, params.row))}
                >{capitalizeFirstLetter(params.row.message
                )}</span>
            ),
        },

        {
            field: "action",
            headerName: "Action",
            style: style,
            headerClassName: "super-app-theme--header",
            renderCell: (params) => {
                return (
                    <>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "start",
                                alignItems: "center",
                            }}
                        >
                            <Box
                                style={{
                                    width: "30px",
                                    height: "30px",
                                    backgroundColor: "#F3F8FD",
                                    borderRadius: "50%",
                                    display: "flex",
                                    justifyContent: "center",
                                    margin: "8px",
                                    alignItems: "center",

                                }}
                                onClick={() => (handleClickOpen(true, params.row))}
                            >
                                <VisibilityIcon
                                    fontSize="small"
                                    className="icon"
                                    style={{ color: "#5B93FF" }}
                                />
                            </Box>
                            <Box
                                style={{
                                    width: "30px",
                                    height: "30px",
                                    backgroundColor: "#F3F8FD",
                                    borderRadius: "50%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    padding: "4px",

                                }}
                                onClick={() => (handleVisibilityOffIconClick(params.row))}
                            >
                                <VisibilityOffIcon
                                    fontSize="small"
                                    className="icon"
                                    style={{ color: "#FFA800" }}
                                />
                            </Box>

                            <IconButton
                                aria-label="edit"
                                onClick={() => handleDeleteClick(params.row)}
                            >
                                <div
                                    style={{
                                        backgroundColor: "#ffd8d8",
                                        display: "flex",
                                        borderRadius: "22px",
                                        padding: "4px",
                                    }}
                                >
                                    <DeleteIcon
                                        fontSize="small"
                                        className="icon"
                                        style={{ color: "#E71D36" }}
                                    />
                                </div>
                            </IconButton>
                        </div>
                    </>
                );
            },
        },
    ];

    return (
        <>
            {/* Error5xx dialog */}
            <Error5xx open={isErrorDialogOpen} errCount={errorCount} count={errorDialogCounter} handleClose={handleCloseErrorDialog} tryAgain={tryAgain} />

            {/* delete dialog */}
            <Dialog
                style={{ zIndex: 10, borderRadius: "30px" }}
                open={deleteConfirmationDialogOpen}
                onClose={() => setDeleteConfirmationDialogOpen(false)}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent style={{ padding: "30px" }}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <img src={deletee} alt="" />
                    </div>
                    <DialogContentText
                        id="alert-dialog-slide-description"
                        style={{ display: "flex", justifyContent: "center" }}
                    >
                        <Typography
                            variant="h6"
                            style={{
                                fontWeight: "bold",
                                color: "black",
                                marginTop: "10px",
                                marginBottom: "8px",
                            }}
                        >
                            Delete Contact
                        </Typography>
                    </DialogContentText>

                    <Typography>Are you sure you want to delete this Contact?</Typography>
                </DialogContent>

                <DialogActions
                    style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        marginBottom: "20px",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                    }}
                >
                    <Button
                        variant="outlined"
                        style={{
                            color: loading ? "gray" : "black",
                            borderColor: "lightgray",
                            width: "100%",
                        }}
                        onClick={() => handleDeleteConfirmation(false)}
                    >
                        Cancel
                    </Button>

                    <Button
                        variant="contained"
                        style={{
                            background: loading ? "gray" : "var(--Error, #FD4343)",
                            color: "white",
                            width: "100%",
                        }}
                        onClick={() => handleDeleteConfirmation(true)}
                        disabled={loading}
                    >
                        {loading ? "...Loading" : "Yes, Delete"}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* set unread dialog */}
            <Dialog
                style={{ zIndex: 10, borderRadius: "30px" }}
                open={unReadConfirmationDialogOpen}
                onClose={() => setUnReadConfirmationDialogOpen(false)}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent style={{ padding: "30px" }}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <VisibilityOffIcon
                            fontSize="large"
                            className="icon"
                            style={{ color: "#FFA800" }}
                        />
                    </div>
                    <DialogContentText
                        id="alert-dialog-slide-description"
                        style={{ display: "flex", justifyContent: "center" }}
                    >
                        <Typography
                            variant="h6"
                            style={{
                                fontWeight: "bold",
                                color: "black",
                                marginTop: "10px",
                                marginBottom: "8px",
                            }}
                        >
                            Unread Contact
                        </Typography>
                    </DialogContentText>

                    <Typography>Are you sure you want to unread this contact ?</Typography>
                </DialogContent>

                <DialogActions
                    style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        marginBottom: "20px",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                    }}
                >
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: 'white',
                            color: colorConfigs?.sidebar?.bg,
                            borderRadius: '10px',
                            width: "100%",
                            padding: '10px 20px',
                            '&:hover': {
                                backgroundColor: '#D3D3D3',
                            },
                        }}
                        onClick={() => handleUnReadConfirmation(false)}
                    >
                        Cancel
                    </Button>

                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: process.env.REACT_APP_ENV === 'women_first' ? colorConfigs?.sidebar?.bg : '#005e8b',
                            color: 'white',
                            borderRadius: '10px',
                            width: "100%",
                            padding: '10px 20px',
                            '&:hover': {
                                backgroundColor: process.env.REACT_APP_ENV === 'women_first' ? colorConfigs?.sidebar?.bg : '#004b6e',
                            },
                        }}
                        onClick={() => handleUnReadConfirmation(true)}
                    >
                        Yes , Set As Unread
                    </Button>
                </DialogActions>
            </Dialog>

            {/* card dialog */}
            <Dialog
                open={dialogOpen}
                onClose={handleClose}
                maxWidth="sm"
                fullWidth
                sx={{
                    '& .MuiDialog-paper': {
                        padding: '0',
                        margin: '0',
                        borderRadius: '12px',
                        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.2)',
                        border: '1px solid #e0e0e0',
                        position: 'relative',
                        overflow: 'hidden',
                    },
                }}
            >
                <DialogContent sx={{

                    padding: '0',
                    '&:first-of-type': {
                        paddingTop: 0,
                    },
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'stretch',
                    width: "100%"
                }}>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 16,
                            top: 16,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>

                    <Box sx={{
                        padding: "15px",
                        backgroundColor: 'white',
                        borderRadius: '8px',
                        margin: '16px',
                    }}>
                        <Typography variant="h5" component="div" sx={{
                            textAlign: 'left',
                            marginBottom: '40px',
                            color: "#172B4D",
                            fontFamily: "Poppins",
                            fontSize: "20px",
                            fontWeight: "700",
                            lineHeight: "30px",
                            width: "219px",
                            height: "30px"
                        }}>
                            Contact US
                        </Typography>

                        <Box sx={{ marginBottom: '16px' }}>
                            <Typography variant="body1" sx={{
                                marginBottom: '4px',
                                color: "#7A869A",
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                fontWeight: "400",
                                lineHeight: "17px",

                            }}>
                                Name :
                            </Typography>
                            <Typography variant="body2" sx={{
                                color: "#495974",
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                fontWeight: "600",
                                lineHeight: "18px",
                            }}>
                                {cardData?.name}
                            </Typography>
                        </Box>

                        <Box sx={{ marginBottom: '16px' }}>
                            <Typography variant="body1" sx={{
                                marginBottom: '4px',
                                color: "#7A869A",
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                fontWeight: "400",
                                lineHeight: "17px",

                            }}>
                                Email :
                            </Typography>
                            <Typography variant="body2" sx={{
                                color: "#495974",
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                fontWeight: "600",
                                lineHeight: "18px",
                                textDecoration: "underline"

                            }}
                            >
                                <a
                                    style={{ color: "#495974" }}
                                    href={`mailto:${cardData?.email}`} >
                                    {cardData?.email}
                                </a>

                            </Typography>
                        </Box>

                        <Box sx={{ marginBottom: '16px' }}>
                            <Typography variant="body1" sx={{
                                marginBottom: '4px',
                                color: "#7A869A",
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                fontWeight: "400",
                                lineHeight: "17px",

                            }}>
                                Phone :
                            </Typography>
                            <Typography variant="body2" sx={{
                                color: "#495974",
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                fontWeight: "600",
                                lineHeight: "18px",
                            }}
                            >

                                <a
                                    style={{ color: "#495974", textDecoration: "none" }}
                                    href={`tel:${cardData?.phone_number_code}${cardData?.phone_number}`} >
                                    {NewFormatPhoneNumber(cardData?.phone_number, countryCode)}
                                </a>
                            </Typography>
                        </Box>

                        <Box sx={{ marginBottom: '16px' }}>
                            <Typography variant="body1" sx={{
                                marginBottom: '4px',
                                color: "#7A869A",
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                fontWeight: "400",
                                lineHeight: "17px",

                            }}>
                                Subject :
                            </Typography>
                            <Typography variant="body2" sx={{
                                color: "#495974",
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                fontWeight: "600",
                                lineHeight: "18px",
                            }}>
                                {capitalizeFirstLetter(cardData?.subject) ? (capitalizeFirstLetter(cardData?.subject)) : ("defult")}
                            </Typography>
                        </Box>

                        <Box>
                            <Typography variant="body1" sx={{
                                marginBottom: '4px',
                                color: "#7A869A",
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                fontWeight: "400",
                                lineHeight: "17px",

                            }}>
                                Message :
                            </Typography>
                            <Typography variant="body2" sx={{
                                color: "#172B4D",
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                fontWeight: "500",
                                lineHeight: "24px",
                            }}
                            >
                                {cardData?.message}
                            </Typography>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>


            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h1
                    style={{
                        color: "#172B4D",
                        fontSize: "24px",
                        fontStyle: "normal",
                        fontWeight: "600",
                        lineHeight: "27px",
                        letterSpacing: "-0.8px",
                    }}
                >
                    Contact Us
                </h1>

                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "14px",
                        marginBottom: "14px",
                        width: "60%",
                    }}
                >
                    <TextField
                        fullWidth
                        label="Search"
                        name="search"
                        placeholder="Search By Name , Phone or Email."
                        value={query}
                        onChange={handleSearch}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon sx={{ color: "gray" }} />
                                </InputAdornment>
                            ),
                        }}
                        variant="outlined"
                        sx={{
                            "& label.Mui-focused": { color: "rgba(0, 0, 0, 0.6)" },
                            "& .MuiOutlinedInput-root": {
                                "&.Mui-focused fieldset": {
                                    borderColor: "#A0AEC0",
                                    borderWidth: "1px",
                                },
                                borderRadius: "10px",
                                height: "52px",
                            },
                        }}
                    />
                    <FormControl
                        style={{ marginTop: "2px", }}
                        sx={{
                            minWidth: 210,
                            "& label.Mui-focused": { color: "rgba(0, 0, 0, 0.6)" },
                            "& .MuiOutlinedInput-root": {
                                "&.Mui-focused fieldset": {
                                    borderColor: "#A0AEC0",
                                },
                                borderRadius: "10px",
                                height: "52px",
                            },
                        }}>
                        <InputLabel id="demo-simple-select-label" >Filter</InputLabel>
                        <Select defaultValue="default" id="grouped-select" label="Filter"
                            open={openFilter}
                            onOpen={handleOpenFilter}
                            MenuProps={{
                                disableScrollLock: true,
                            }}
                            value={filterValue}
                        >

                            <MenuItem value={filterValue} disabled style={{ width: "0", height: "0" }}
                                sx={{ display: openFilter ? 'none' : 'block' }}>{filterValue}</MenuItem>

                            <ListSubheader>Type</ListSubheader>
                            <Box sx={{ minWidth: 250 }} style={{ padding: "5px", }}>
                                <FormControl
                                    fullWidth
                                    style={{ marginTop: "2px", }}
                                    sx={{
                                        "& label.Mui-focused": { color: "rgba(0, 0, 0, 0.6)" },
                                        "& .MuiOutlinedInput-root": {
                                            "&.Mui-focused fieldset": {
                                                borderColor: "#A0AEC0",
                                            },
                                            borderRadius: "10px",
                                            height: "52px",
                                        },
                                    }}
                                >
                                    <InputLabel id="demo-simple-select-label" >
                                        Type
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={type}
                                        label="Type"
                                        onChange={handleTypeChange}
                                        style={{ borderRadius: "10px", height: "50px" }}
                                    >
                                        <MenuItem value={"all"}>All</MenuItem>
                                        <MenuItem value={"customer"}>Customer</MenuItem>
                                        <MenuItem value={"driver"}>Driver</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                            <ListSubheader>Read</ListSubheader>
                            <Box sx={{ minWidth: 250 }} style={{ padding: "5px" }}>
                                <FormControl
                                    fullWidth
                                    style={{ marginTop: "2px" }}
                                    sx={{
                                        "& label.Mui-focused": { color: "rgba(0, 0, 0, 0.6)" },
                                        "& .MuiOutlinedInput-root": {
                                            "&.Mui-focused fieldset": {
                                                borderColor: "#A0AEC0",
                                            },
                                            borderRadius: "10px",
                                            height: "52px",
                                        },
                                    }}
                                >
                                    <InputLabel id="demo-simple-select-label">
                                        Message
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={readedFilterType}
                                        label="Message"
                                        onChange={handleReadedFilter}
                                        style={{ borderRadius: "10px", height: "50px" }}
                                    >
                                        <MenuItem value={"all"}>All</MenuItem>
                                        <MenuItem value={"true"}>Read</MenuItem>
                                        <MenuItem value={"false"}>Unread</MenuItem>
                                    </Select>
                                </FormControl>

                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: "5px", marginTop: "5px" }}>
                                <Box sx={{ minWidth: "50%" }} style={{ padding: "5px", marginTop: "5px" }}>
                                    <Button
                                        variant="contained"
                                        sx={{
                                            backgroundColor: 'white',
                                            color: colorConfigs.sidebar?.bg,
                                            borderRadius: '10px',
                                            width: "100%",
                                            padding: '10px 20px',
                                            '&:hover': {
                                                backgroundColor: '#D3D3D3',
                                            },
                                        }}
                                        onClick={handleClearFilter}
                                    >
                                        Clear
                                    </Button>
                                </Box>
                                <Box sx={{ minWidth: "50%" }} style={{ padding: "5px", marginTop: "5px", }}>
                                    <Button
                                        variant="contained"
                                        sx={{
                                            backgroundColor: colorConfigs.sidebar?.bg,
                                            color: 'white',
                                            borderRadius: '10px',
                                            width: "100%",
                                            padding: '10px 20px',
                                            '&:hover': {
                                                backgroundColor: colorConfigs.sidebar?.bg,
                                            },
                                        }}
                                        onClick={handleCloseFilter}
                                    >
                                        Close
                                    </Button>
                                </Box>

                            </Box>

                        </Select>

                    </FormControl>

                </div>

            </div>

            <DynamicTable
                columns={columns}
                data={data}
                count={count}
                page={page}
                rowsLimits={rowsLimits}
                pageChange={handlePageChange}
                rowsChange={handleRowsChange}
                pageType="contactUs"
            />

            <Loading
                loading={loading && list.length === 0}
                background="rgba(86, 100, 210, 0.1)"
                loaderColor={colorConfigs.sidebar?.bg}
            />

            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={() => setOpenSnackbar(false)}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <Alert onClose={handleSnackbarClose} severity="error">
                    {snackbarErrorMessage}
                </Alert>
            </Snackbar>
        </>
    );
}
