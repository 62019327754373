import { Box, ListItemIcon, MenuItem, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { IoChevronForward } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import colorConfigs from '../../configs/colorConfigs';
import NotificationBody from './NotificationBody';
import { notificationClick } from './NotificationClick';
import NotificationIcon from './NotificationIcon';

const NotificationItem = styled(MenuItem)(({ theme, open }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    backgroundColor: open ? '#FFF' : colorConfigs?.sidebar?.ligthBg,
    position: 'relative',
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    },
    borderBottom: !open ? '1px solid #e4e7eb' : 'none',
}));

const NotificationContent = styled('div')(({ theme }) => ({
    marginLeft: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    maxWidth: '70%',
    whiteSpace: 'wrap',
    textOverflow: 'ellipsis',
    // overflow: 'hidden',
}));

const DotWrapper = styled(ListItemIcon)(({ theme, read }) => ({
    position: 'relative',
    marginLeft: theme.spacing(2),
    '&::before': {
        content: '""',
        display: read ? 'none' : 'block',
        width: '8px',
        height: '8px',
        backgroundColor: theme.palette.primary.main,
        borderRadius: '50%',
        position: 'absolute',
        right: '20px',
        top: '50%',
        transform: 'translateY(-50%)',
    },
}));

const formatTimeSince = (timestamp) => {
    const now = new Date();
    const notificationTime = timestamp?.toDate();
    const diffInSeconds = Math.floor((now - notificationTime) / 1000);

    const minutes = Math.floor(diffInSeconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
        return `${days} day${days > 1 ? 's' : ''} ago`;
    } else if (hours > 0) {
        return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else if (minutes > 0) {
        return `${minutes} min${minutes > 1 ? 's' : ''} ago`;
    } else {
        return 'Just now';
    }
};




const NotificationCard = ({ notification, onClose, markAsRead }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const reduxData = useSelector((state) => state.appState);

    return (
        <NotificationItem
            onClick={(event) => { notificationClick(notification, onClose, navigate, dispatch, reduxData, markAsRead, location) }}
            open={notification?.open ? 1 : 0}
        >
            <Box sx={{pl: 1, borderRadius: 25, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <NotificationIcon notification={notification} />
            </Box>
            <NotificationContent>
                <Typography variant="body1" fontWeight="bold">
                    {notification?.title}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mt: 0.2 }}>
                    <NotificationBody notification={notification} />
                </Typography>
                <Typography variant="caption" color="text.secondary">
                    {formatTimeSince(notification?.created_at)}
                </Typography>
            </NotificationContent>
            <DotWrapper read={notification?.open ? 1 : 0}>
            </DotWrapper>
            <IoChevronForward size={16} />
        </NotificationItem>
    );
};

export default NotificationCard;
