import React from 'react'
import ChatMessage from './../../assets/svg/notificcation/ChatMessage';
import CarFill from './../../assets/svg/CarFill';
import CancelRide from './../../assets/svg/notificcation/CancelRide';
import SecurityAlert from './../../assets/svg/SecurityAlert';
import CreateAdmin from './../../assets/svg/notificcation/CreateAdmin';
import IncomingMoney from './../../assets/svg/notificcation/IncomingMoney';
import OutComingMoney from './../../assets/svg/notificcation/OutComingMoney';
import Star from './../../assets/svg/Star';
import NewRide from '../../assets/svg/notificcation/NewRide';
import RideAssigned from '../../assets/svg/notificcation/RideAssigned';
import RideCompleted from '../../assets/svg/notificcation/RideCompleted';

function NotificationIcon({ notification }) {
    const icon = (type) => {
        switch (type) {
            case 'new_message':
                return <ChatMessage />
            case 'messaging':
                return <ChatMessage />
            case 'messaging_with_admin':
                return <ChatMessage />
            case 'new_customer_ride':
                return <NewRide />
            case 'new_admin_ride':
                return <NewRide />
            case 'reminder_scheduled_ride':
                return <NewRide />
            case 'reminder_scheduled_ride_before_start':
                return <NewRide />
            case 'ride_assigned':
                return <RideAssigned />
            case 'driver_arrived':
                return <CarFill />
            case 'ride_completed':
                return <RideCompleted />
            case 'ride_cancel':
                return <CancelRide />
            case 'panic_button_driver':
                return <SecurityAlert />;
            case 'panic_button_customer':
                return <SecurityAlert />;
            case 'create_new_admin':
                return <CreateAdmin />
            case 'incoming_money':
                return <IncomingMoney />
            case 'support':
                return <ChatMessage />
            case 'outcoming_money':
                return <OutComingMoney />
            default:
                return ''
        }
    }
    return (
        <>
            {icon(notification?.object_type)}
        </>
    )
}

export default NotificationIcon