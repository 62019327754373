import CarIcon from "@mui/icons-material/DirectionsCar";
import PersonIcon from "@mui/icons-material/Person";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { db } from "../../configs/firebase";
import MessageListItemPrivate from "./message-list-item";
import LogoWF from '../../assets/womens-first/logopurple.png'
import LogoRR from '../../assets/logo.png'
import colorConfigs from "../../configs/colorConfigs";
const Fade = require("react-reveal/Fade");

const MessageViewerListPrivate = ({
  allowSend,
  admin,
  customerData,
  driverData,
}) => {
  const params = useParams();
  const sentinel = useRef(null);
  const [messages, setMessages] = useState([]);
  const [last, setLast] = useState(null);
  const [flag, setFlag] = useState(false);
  const [enabler, setEnabler] = useState(null);
  const selectedChat = useSelector((state) => state.appState.selectedChat);
  const selectedRide = useSelector((state) => state.appState.selectedRide);
  const outChat = useSelector((state) => state.appState.outChat);
  const lastRideChat = useSelector((state) => state.appState.lastRideChat);

  const messagesRef = useRef([]);
  messagesRef.current = messages;

  const lastRef = useRef();
  lastRef.current = last;

  const flagRef = useRef(false);
  flagRef.current = flag;

  useEffect(() => {
    setMessages([]);
    if (selectedRide?._id || selectedChat?.rideId) {
      const jobId = outChat ? selectedRide?._id : selectedChat?.rideId;

      const messageStream = db
        .collection("messaging")
        .doc(jobId)
        .collection("messages")
        .orderBy("createAt", "desc")
        .onSnapshot((snapshot) => {
          updateMessages(snapshot);
        });

      return () => {
        setMessages([]);
        messageStream();
      };
    } else if (lastRideChat) {
      const jobId = outChat ? lastRideChat?._id : lastRideChat?.rideId;

      const messageStream = db
        .collection("messaging")
        .doc(jobId)
        .collection("messages")
        .orderBy("createAt", "desc")
        .onSnapshot((snapshot) => {
          updateMessages(snapshot);
        });

      return () => {
        setMessages([]);
        messageStream();
      };
    }
  }, [selectedChat?.rideId, selectedRide, lastRideChat?.rideId, lastRideChat]);

  const updateMessages = (snapshot) => {
    if (snapshot.docChanges().length === 0) {
      setMessages([]);
    } else {
      let lastDocumentSnapshot = null;
      const messagesList = [...messagesRef.current];
      snapshot.docChanges().forEach((d) => {
        switch (d.type) {
          case "added":
            const data = d.doc.data();
            data.id = d.doc.id;
            data.createAt = data?.createAt
              ? typeof data?.createAt === "string"
                ? new Date(data.createAt)
                : data.createAt instanceof Date
                  ? data.createAt
                  : new Date(data?.createAt?.toDate()?.toString())
              : null;
            if (
              messagesList.length > 0 &&
              messagesList[messagesList.length - 1].createAt < data.createAt
            ) {
              messagesList.push(data);
            } else {
              messagesList.splice(0, 0, data);
            }
            if (!lastRef.current) {
              lastDocumentSnapshot = d.doc;
            }
            break;
          case "removed":
          case "modified":
        }
      });
      let jobId;
      if (selectedRide?._id || selectedChat?.rideId) {
        jobId = outChat ? selectedRide?._id : selectedChat?.rideId;
      } else if (lastRideChat) {
        jobId = outChat ? lastRideChat?._id : lastRideChat?.rideId;
      }
      db.collection("messaging")
        .doc(jobId)
        .update({
          [`unRead.admin`]: 0,
          [`lastRead.admin`]: new Date(),
        })
        .then();
      setMessages(messagesList);

      if (!lastRef.current && !flagRef.current) {
        setLast(lastDocumentSnapshot);
        setFlag(true);
      }
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(onSentinelIntersection);
    if (sentinel.current) {
      observer.observe(sentinel.current);
    }

    return function cleanup() {
      observer.disconnect();
    };
  });

  const onSentinelIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        if (lastRef.current) {
          const jobId = outChat ? selectedRide?._id?.split("_")[0] : selectedChat?.rideId?.split("_")[0];
          db.collection("messaging")
            .doc(jobId)
            .collection("messages")
            .orderBy("createAt", "desc")
            .startAfter(lastRef.current)
            .limit(20)
            .onSnapshot((snapshot) => {
              let lastDocumentSnapshot = null;
              const messagesList = [...messagesRef.current];

              if (snapshot.docs.length != 0) {
                snapshot.docs.forEach((d) => {
                  const data = d.data();
                  data.id = d.id;
                  data.createAt = data?.createAt
                    ? typeof data?.createAt === "string"
                      ? new Date(data?.createAt)
                      : data.createAt instanceof Date
                        ? data.createAt
                        : new Date(data?.createAt.toDate()?.toString())
                    : null;
                  messagesList.splice(0, 0, data);
                  lastDocumentSnapshot = d;
                });
              }
              setMessages(messagesList);
              setLast(lastDocumentSnapshot);
            });
        }
      }
    });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column-reverse",
        height: "480px",
        overflowY: "auto",
        padding:10
      }}
    >
      {messages.length > 0 && (
        <div>
          <div style={{ height: "150px" }} />
          {messages.map((message, index) => {
            const isFirstInSequence =
              index === 0 || messages[index - 1].uid !== message.uid;
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems:
                    message.isSupport ||
                      message?.text ===
                      `Hello! Thanks for joining the ${process.env.REACT_APP_ENV === 'women_first' ? 'women-s-first' : "RideWyze"} Community.`
                      ? "flex-end"
                      : "flex-start",
                  wordBreak: "break-word",
                }}
              >
                <div ref={sentinel} />
                {(index === 0 ||
                  (index !== 0 &&
                    message.createAt.getTime() -
                    messages[index - 1].createAt.getTime() >
                    3600000)) && (
                    <div
                      style={{
                        margin: "8px 0",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        variant="caption"
                        style={{ color: "grey" }}
                        regular
                      >
                        {moment(message.createAt).format("MMM DD, hh:mm A")}
                      </Typography>
                    </div>
                  )}
                {isFirstInSequence && message.uid === "admin" && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "10px",
                    }}
                  >
                    <Avatar
                      style={{ marginBottom: -40, height: 30, width: 30 }}
                      alt={`${message?.admin_info?.name} ${message?.admin_info?.name}`}
                      src={process.env.REACT_APP_ENV === 'women_first' ? LogoWF : LogoRR}
                      size={30}
                    />
                  </div>
                )}
                {isFirstInSequence && message.uid === customerData?.fb_uid && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "start",
                      flexDirection: 'column',
                      gap: 1
                    }}
                  >
                    <Typography variant="caption" sx={{ mb: 0.5, ml: 1 }}>Customer</Typography>

                    <PersonIcon
                      style={{ marginLeft: 10, marginBottom: -30, height: 30, width: 30, color: colorConfigs?.sidebar?.bg, background: colorConfigs.sidebar.ligthBg, borderRadius: 25 }}

                    />
                    {/* <Typography
                      variant="body2"
                      style={{ marginLeft: "20px" }}
                    >
                      {customerData?.first_name
                        ? `${customerData?.first_name} `
                        : "Loading..."}{" "}
                      &#8226;
                    </Typography> */}
                  </div>
                )}
                {isFirstInSequence && message.uid === driverData?.fb_uid && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "start",
                      flexDirection: 'column',
                      gap: 1
                    }}
                  >
                    <Typography variant="caption" sx={{ mb: 0.5, ml: 1 }}>Driver</Typography>

                    <CarIcon
                      style={{ marginLeft: 10, marginBottom: -30, height: 30, width: 30, color: colorConfigs?.sidebar?.bg, background: colorConfigs.sidebar.ligthBg, borderRadius: 25 }}
                    />
                    {/* <Typography
                      variant="body2"
                      style={{ marginLeft: "12px" }}
                    >
                      {driverData?.first_name
                        ? `${driverData?.first_name} `
                        : "Loading..."}{" "}
                      &#8226;
                    </Typography> */}
                  </div>
                )}
                <Fade bottom distance="12px" key={index} duration={300}>
                  <MessageListItemPrivate
                    message={message}
                    nextUid={
                      index >= messages.length - 1
                        ? null
                        : messages[index + 1]?.uid
                    }
                    enablerUid={enabler?.uid}
                    admin_info={message?.admin_info}
                  />
                </Fade>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default MessageViewerListPrivate;
