import {
    Box,
    Button,
    Grid,
    Select, MenuItem,
    FormControl, InputLabel,
    TextField, InputAdornment, Typography
} from "@mui/material";
import Subject from "../../pages/subject/Subject";
import DynamicTable from "../../pages/table/table";
import SearchIcon from "@mui/icons-material/Search";
import colorConfigs from "../../configs/colorConfigs";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Switch } from "antd";
import Loading from "react-fullscreen-loading";
import useTableData from '../../pages/Hook/useTableData';
import { useDispatch, useSelector } from "react-redux";
import { postDataRedux } from '../../redux/features/appStateSlice';
import styled from 'styled-components';
import parse from 'html-react-parser';
import { height } from "@mui/system";


const CustomTypography = styled(Typography)`
    .ql-size-small {
        font-size: 0.75em;
    }
    .ql-size-large {
        font-size: 1.5em;
    }
    .ql-size-huge {
        font-size: 2em;
    }
`;

export default function HelpCenter({ status }) {


    const rowsLimits = [5, 10, 25, 700];
    const helpCenterList = useSelector((state) => state.appState.helpCenterList);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    dispatch(postDataRedux({ stateName: 'driverRiderStatus', data: status }));

    useEffect(() => {
        window.scrollTo({ top: 0 });
    }, []);

    const capitalizeFirstLetter = (str) => {
        return str?.charAt(0)?.toUpperCase() + str?.slice(1);
    };


    const { data, page, count, filters, loading, handlePageChange, handleRowsChange, updateFilters } = useTableData(
        "admin/help/knowledge-base",
        { keyword: "", type: "all", user_type: status && status === "drivers" ? "driver" : "customer", },
        'helpCenterList'
    );


    console.log(helpCenterList);


    const handleSearch = (e) => {
        console.log(e?.target?.value)
        updateFilters({ keyword: e?.target?.value });
        updateFilters({ search_title: e?.target?.value });
    };

    const handleStatusChange = (e) => {
        updateFilters({ type: e.target.value });
    };

    const style = {
        color: "#495974",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "16px",

    };
    const styleb = {
        color: "#495974",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: "600",
        lineHeight: "18px",

    };
    const columns = [
        {
            field: "title",
            headerName: "Title",
            flex: 1,
            minWidth: "200px",
            editable: true,
            style: styleb,
            align: "left",
            headerClassName: "super-app-theme--header",
            renderCell: (params) => (
                <span
                    style={{ textDecoration: "underline", fontWeight: "bold", cursor: "pointer" }}
                    onClick={() => {
                        navigate(status && status === "drivers" ? `/driverHelpCenter/details/${params?.row?._id}` : `/passengerHelpCenter/details/${params?.row?._id}`)
                        dispatch(postDataRedux({ stateName: 'details', data: params?.row }));
                    }}
                >
                    {params?.row?.title}
                </span>
            ),
        },
        {
            field: "category",
            headerName: "Category",
            flex: 1,
            minWidth: "50px",
            editable: true,
            style: {
            },
            align: "center",
            headerClassName: "super-app-theme--header",
            renderCell: (params) => <Subject subject={params.row.type} />,
        },
        {
            field: "priority",
            headerName: "Priority",
            flex: 1,
            minWidth: "50px",
            editable: true,
            style: styleb,
            align: "center",
            headerClassName: "super-app-theme--header",
            renderCell: (params) => capitalizeFirstLetter(params.row.priority),
        },
        {
            field: "description",
            headerName: "Description",
            flex: 1,
            editable: true,
            width: "150px",
            style: styleb,
            align: "center",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",

            headerClassName: "super-app-theme--header",
            renderCell: (params) => (
                <span style={{
                    ...style,
                    display: "block",
                    whiteSpace: "nowrap",
                    minWidth: "100%",
                    maxWidth: "270px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    wordBreak: "keep-all",
                    height: "40px"
                }}>
                    {parse(params?.row?.description_html || '')}
                </span>
            ),
        },
        {
            field: "suggested_actions",
            headerName: "Suggested Actions",
            flex: 1,
            minWidth: "180px",
            editable: true,
            style: style,
            align: "center",
            headerClassName: "super-app-theme--header",
            renderCell: (params) => (
                <span style={{
                    display: "block",
                    whiteSpace: "nowrap",
                    minWidth: "100%",
                    maxWidth: "270px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    wordBreak: "keep-all",
                }}>
                    {params?.row?.suggested_actions}
                </span>
            ),
        },
        // {
        //     field: "reasone",
        //     headerName: "Reason",
        //     flex: 1,
        //     minWidth: "180px",
        //     editable: true,
        //     style: style,
        //     align: "start",
        //     headerClassName: "super-app-theme--header",
        // },
        // {
        //     field: "sup_reasone",
        //     headerName: "Sup Reason",
        //     flex: 1,
        //     minWidth: "180px",
        //     editable: true,
        //     style: style,
        //     align: "start",
        //     headerClassName: "super-app-theme--header",
        // },
        // {
        //     field: "enabled",
        //     headerName: "Enable/Disable",
        //     minWidth: "50px",
        //     style: {
        //         display: "flex",
        //         alignItems: "center",
        //         justifyContent: "center",
        //     },
        //     align: "center",
        //     headerClassName: "super-app-theme--header",
        //     enabledFunc: { key: '_id', stateName: 'helpCenterList', url: 'admin/help/knowledge-base' }
        // },
        {
            field: "action",
            headerName: "Action",
            style: style,
            headerClassName: "super-app-theme--header",
            // deleteFunc: { key: '_id', stateName: 'helpCenterList', url: 'admin/help/knowledge-base' },
            editFunc: (row) => {
                dispatch(postDataRedux({ stateName: 'details', data: row }));
                dispatch(postDataRedux({ stateName: 'addEditStatus', data: "edit" }));
                navigate(status && status === "drivers" ? `/driverHelpCenter/editHelpCenter/` : `/passengerHelpCenter/editHelpCenter/`)
            },
            viewFunc: (row) => {
                navigate(status && status === "drivers" ? `/driverHelpCenter/details/${row?._id}` : `/passengerHelpCenter/details/${row?._id}`)
                dispatch(postDataRedux({ stateName: 'details', data: row }));
            }
        },
    ];

    return (
        <>
            {/* titel and add btn */}
            <Grid
                item
                xs={12}
                sm={12}
                md={9}
                lg={9}
                xl={9}
                style={{
                    display: "flex",
                    gap: "14px",
                    marginTop: "-70px",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                }}>
                <Box style={{
                    display: "flex",
                    flexDirection: 'row',

                    gap: "14px",
                }}>
                    <span style={{
                        color: "#FFFFFF",
                        fontFamily: "Outfit",
                        fontSize: "24px",
                        fontWeight: "700",
                        lineHeight: "33px",

                    }}>
                        Help Center
                    </span>
                </Box>
                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: "#FFFFFF",
                        borderRadius: "20px",
                        fontWeight: "bold",
                        color: colorConfigs?.sidebar?.bg,
                        '&:hover': {
                            backgroundColor: '#FFFFFF',
                        },
                    }}
                    onClick={() => {
                        navigate(status && status === "drivers" ? `/driverHelpCenter/addHelpCenter` : `/passengerHelpCenter/addHelpCenter`);
                        dispatch(postDataRedux({ stateName: 'driverRiderStatus', data: status }));
                        dispatch(postDataRedux({ stateName: 'addEditStatus', data: "add" }));


                    }}
                >
                    +{" "}{"Add New Help Center"}
                </Button>

            </Grid>

            {/* search and filter */}
            <Grid
                container
                spacing={1}
                style={{
                    display: "flex",
                    flexDirection: 'row',
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingLeft: "10px",
                    marginTop: "30px",
                    marginBottom: "15px",
                }}>
                <Grid style={{
                    minWidth: '150px',

                }}>
                    <span style={{
                        color: "#172B4D",
                        fontFamily: "Poppins",
                        fontSize: "24px",
                        fontWeight: "600",
                        lineHeight: "27px",
                    }}> {status === "drivers" ? "Drivers" : "Passengers"} </span>
                </Grid>
                <Grid style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}>
                    <TextField
                        label="Search"
                        name="search"
                        placeholder="Search By Title"
                        value={filters.keyword}
                        onChange={handleSearch}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon sx={{ color: "gray" }} />
                                </InputAdornment>
                            ),
                        }}
                        variant="outlined"
                        sx={{
                            "& label.Mui-focused": { color: "rgba(0, 0, 0, 0.6)" },
                            "& .MuiOutlinedInput-root": {
                                "&.Mui-focused fieldset": {
                                    borderColor: "#A0AEC0",
                                    borderWidth: "1px",
                                },
                                borderRadius: "10px",
                                height: "52px",
                                minWidth: "300px"
                            },
                        }}
                    />
                    <FormControl style={{ minWidth: "150px", marginLeft: "5px", height: "52px", }} sx={{
                        "& label.Mui-focused": {
                            color: "rgba(0, 0, 0, 0.6)",
                            bordercolor: "#BBC1CE",
                        },
                        "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                                borderColor: "#BBC1CE",
                            },
                            "&:hover fieldset": {
                                borderColor: "#BBC1CE",
                            },
                            "&.Mui-focused fieldset": {
                                borderColor: "#BBC1CE",
                                borderWidth: "1px",
                            },
                        },
                    }}>
                        <InputLabel id="demo-simple-select-label">Filter</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={filters.type || "all"}
                            onChange={handleStatusChange}
                            label="Status"
                            style={{ borderRadius: "10px", height: "50px" }}
                            MenuProps={{
                                disableScrollLock: true,
                            }}
                        >
                            <MenuItem value={"all"}>All</MenuItem>
                            <MenuItem value={"ride"}>Ride</MenuItem>
                            <MenuItem value={"payment"}>Payment</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

            {/* table */}
            {status === "drivers" && (
                <DynamicTable
                    columns={columns}
                    data={helpCenterList}
                    count={count}
                    page={page}
                    rowsLimits={rowsLimits}
                    pageChange={handlePageChange}
                    rowsChange={handleRowsChange}
                    pageType="helpCenter"
                />
            )}
            {status === "riders" && (
                <DynamicTable
                    columns={columns}
                    data={helpCenterList}
                    count={count}
                    page={page}
                    rowsLimits={rowsLimits}
                    pageChange={handlePageChange}
                    rowsChange={handleRowsChange}
                    pageType="helpCenter"
                />
            )}
            {/* <Loading
                loading={loading}
                background="rgba(86, 100, 210, 0.1)"
                loaderColor={colorConfigs.sidebar?.bg}
            /> */}
        </>
    );
}

