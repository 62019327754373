/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
import CarIcon from "@mui/icons-material/DirectionsCar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import location from "../../assets/images/location.png";
import colorConfigs from "../../configs/colorConfigs";
import { auth, db } from "../../configs/firebase";
import MessageViewerBottom from "./message-viewer-bottom";
import MessageViewerListPrivate from "./message-viewer-list";
// import icon from "../../../assets/images/Icon.png";
import icon from "../../assets/images/Icon.png";

import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import StarIcon from "@mui/icons-material/Star";
import {
  Alert,
  Button,
  Grid,
  Snackbar,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import { getMethod } from "../Hook/UseCustomApi";
import { NewFormatPhoneNumber } from "../../utils/localization/NewPhoneUtils";

const MessageViewerPrivate = ({ allowSend }) => {
  const theme = useTheme();
  const params = useParams();
  const [enabler, setEnabler] = useState([]);
  const [admin, setAdmin] = useState(null);
  const sentinel = useRef(null);
  const userData = auth?.currentUser;
  const [job, setJob] = useState(null);
  const [last, setLast] = useState(null);
  const [flag, setFlag] = useState(false);
  const [messages, setMessages] = useState([]);
  const [view, setView] = useState(false);
  const messagesRef = useRef([]);
  const lgDown = useMediaQuery(theme.breakpoints.down("lg"));
  messagesRef.current = messages;
  const [driverData, setDriverData] = useState();
  const [rideData, setRideData] = useState();
  const [customerData, setCutomerData] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const selectedChat = useSelector((state) => state.appState.selectedChat);
  const selectedRide = useSelector((state) => state.appState.selectedRide)
  const outChat = useSelector((state) => state.appState.outChat);
  const lastRideChat = useSelector((state) => state.appState.lastRideChat);
  const countryCode = useSelector((state) => state.country.selectedCountry);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const lastRef = useRef();
  lastRef.current = last;

  const flagRef = useRef(false);
  flagRef.current = flag;


  const fetchDriver = async (messages) => {
    getMethod({
      url: `driver/getOneDriver/${messages?.driver}`,
      handleErrorMessage: showSnackbar,
    })
      .then((response) => {
        setDriverData(response?.data); // Assuming `setData` updates the state to reflect filtered drivers
        console.log("setDriverData", response?.data);
      })
      .catch((err) => console.error("Error fetching more stores:", err))
      .finally(() => console.log("done"));
  };

  const fetchCustomer = async (messages) => {
    getMethod({
      url: `customer/getOneCustomer/${messages?.customer}`,
      handleErrorMessage: showSnackbar,
    })
      .then((response) => {
        setCutomerData(response?.data); // Assuming `setData` updates the state to reflect filtered drivers
        console.log("setCutomerData", response?.data);
      })
      .catch((err) => console.error("Error fetching more stores:", err))
      .finally(() => console.log("done"));
  };


  const fetchRides = async (messages) => {
    console.log(messages)
    getMethod({
      url: `ride/${messages?.rideId}`,
      handleErrorMessage: showSnackbar,
    })
      .then((response) => {
        setRideData(response?.data); // Assuming `setData` updates the state to reflect filtered drivers
      })
      .catch((err) => console.error("Error fetching more stores:", err))
      .finally(() => console.log("done"));
  };

  useEffect(() => {

    if (selectedRide?._id || selectedChat?.driver || selectedChat?.customer) {
      const getMessages = async () => {
        const jobId = outChat ? selectedRide?._id : selectedChat?.rideId;

        db.collection("messaging")
          .doc(jobId)
          .get()
          .then((doc) => {
            const messagesFirebase = doc.data();
            setMessages(messagesFirebase);
            fetchRides(messagesFirebase);
            fetchDriver(messagesFirebase);
            fetchCustomer(messagesFirebase);
          })
          .catch((error) => {
            console.error("Error getting messages:", error);
          });
      };
      getMessages();
    } else if (lastRideChat) {
      const getMessages = async () => {
        const jobId = lastRideChat?._id ? lastRideChat?._id : lastRideChat?.rideId;

        db.collection("messaging")
          .doc(jobId)
          .get()
          .then((doc) => {
            const messagesFirebase = doc.data();
            setMessages(messagesFirebase);
            fetchRides(messagesFirebase);
            fetchDriver(messagesFirebase);
            fetchCustomer(messagesFirebase);
          })
          .catch((error) => {
            console.error("Error getting messages:", error);
          });
      };
      getMessages();
    }
  }, [selectedChat?.rideId, selectedRide?._id, selectedChat, selectedRide, lastRideChat?._id, lastRideChat?.rideId]);


  const handleMailToClick = (mailto, e) => {
    e.preventDefault();
    window.open(mailto, "_blank");
  };

  const renderStars = (count) => {
    return Array.from({ length: count }, (_, index) => (
      <StarIcon
        key={index}
        style={{ color: "#FFCC00", width: "14.91px", height: "14.49px" }}
      />
    ));
  };

  return (
    <>
      <Grid container direction="row" justifyContent="space-between" >
        {!view ? <Grid
          item
          xs={12}
          md={12}
          lg={(selectedChat?.rideId || selectedRide?._id || lastRideChat?.rideId) && view ? 7 : 12}
          style={{ boxShadow: "2px 0px 10px -6px black", marginRight: "10px", height: "fit-content", paddingBottom: 20 }}
        >
          <div style={{ position: "relative", width: "100%" }}>
            <div
              style={{
                zIndex: 3,
                // padding: "0 24px",
                padding: "16px",
                boxSizing: "border-box",
                backdropFilter: "blur(10px)",
                background: "rgba(254, 254, 254, 0.8)",
                borderBottom: "2px solid rgb(240,240,240,1)",
                height: "64px",
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",

              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                {/* Driver Information */}
                <Avatar
                  alt={`${driverData && driverData?.first_name
                    ? `${driverData?.first_name} ${driverData?.last_name}`
                    : "Loading..."
                    }`}
                  src={driverData && driverData?.profile_picture}
                  style={{
                    width: "22px",
                    height: "22px",
                    borderColor: colorConfigs?.sidebar?.bg,
                    borderWidth: 2,
                    borderStyle: "solid",
                  }}
                />
                <Typography
                  variant="body2"
                  style={{
                    padding: "0 10px",
                    fontWeight: 700,
                    fontSize: "16px",
                  }}
                >
                  <CarIcon sx={{ mr: 0.5, verticalAlign: "bottom" }} />

                  {driverData && driverData?.first_name
                    ? `${driverData?.first_name} ${driverData?.last_name}`
                    : "Loading..."}
                </Typography>

                {/* Customer Information */}
                {/* <Avatar
                  alt={`${
                    customerData && customerData?.first_name
                      ? `${customerData?.first_name} ${customerData?.last_name}`
                      : "Loading..."
                  }`}
                  src={customerData && customerData?.profile_picture}
                  style={{
                    width: "22px",
                    height: "22px",
                    borderRadius: "50%",
                    borderColor: "green",
                    borderWidth: 2,
                    borderStyle: "solid",
                  }}
                />
                <Typography
                  variant="body2"
                  style={{
                    paddingLeft: "10px",
                    fontWeight: 700,
                    fontSize: "16px",
                  }}
                >
                  <PersonIcon sx={{ mr: 0.5, verticalAlign: "bottom" }} />
                  {customerData && customerData?.first_name
                    ? `${customerData?.first_name} ${customerData?.last_name}`
                    : "Loading..."}
                </Typography> */}
              </div>
              <Box display="flex" style={{ cursor: "pointer" }}>
                {/* <Button
                  variant="outlined"
                  onClick={() => {
                    setView(true);
                  }} */}

                  {/* style={{
                    borderTopLeftRadius: "88px",
                    borderBottomLeftRadius: "88px",
                    // borderTopRightRadius: "88px",
                    // borderBottomRightRadius: "88px",

                    borderColor: colorConfigs?.sidebar?.bg,
                    color: view ? "white" : "#42526E",
                    backgroundColor: view ? colorConfigs?.sidebar?.bg : "white",
                    cursor: "pointer",
                  }}
                > */}
                  {/* <DriveFileRenameOutlineSharpIcon fontSize="small" /> */}
                  {/* Show{" "}
                </Button> */}


                {/* <Button
                  variant="outlined"
                  onClick={() => {
                    setView(false);
                  }}
                  style={{
                    borderTopRightRadius: "88px",
                    borderBottomRightRadius: "88px",
                    // borderTopLeftRadius: "88px",
                    // borderBottomLeftRadius: "88px",
                    borderRight: "1px solid black",
                    borderColor: colorConfigs?.sidebar?.bg,
                    color: !view ? "white" : colorConfigs?.sidebar?.bg,
                    backgroundColor: !view
                      ? colorConfigs?.sidebar?.bg
                      : "white",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  Hide
                </Button> */}
              </Box>

              {/* </Dialog> */}
            </div>
            <MessageViewerListPrivate
              allowSend={allowSend}
              admin={admin}
              customerData={customerData && customerData}
              driverData={driverData && driverData}
            />

            {allowSend && (
              <MessageViewerBottom
                admin={admin}
                job={job}
                customerData={customerData && customerData}
                driverData={driverData && driverData}
                messages={messages}
              />
            )}
          </div>
        </Grid> : ''}
        {view && (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{
              boxShadow: "2px 0px 13px -6px black",
              padding: "0px 15px 30px 15px",
              height: "550px",
              overflowY: "auto",
              marginTop: lgDown ? "20px" : "0px",
            }}
          >

            {/**Customer Info */}
            <Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                style={{
                  padding: "16px 0",

                }}
              >
                <Typography
                  variant="h6"
                  style={{
                    lineHeight: "19.6px",
                    fontSize: "16px",
                    fontWeight: "700",
                    color: "#121826",
                  }}
                >
                  Customer
                </Typography>
                <Box display="flex" style={{ cursor: "pointer" }}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setView(true);
                    }}
                    style={{
                      borderTopLeftRadius: "88px",
                      borderBottomLeftRadius: "88px",
                      // borderTopRightRadius: "88px",
                      // borderBottomRightRadius: "88px",

                      borderColor: colorConfigs?.sidebar?.bg,
                      color: view ? "white" : "#42526E",
                      backgroundColor: view ? colorConfigs?.sidebar?.bg : "white",
                      cursor: "pointer",
                    }}
                  >
                    {/* <DriveFileRenameOutlineSharpIcon fontSize="small" /> */}
                    Show{" "}
                  </Button>

                  <Button
                    variant="outlined"
                    onClick={() => {
                      setView(false);
                    }}
                    style={{
                      borderTopRightRadius: "88px",
                      borderBottomRightRadius: "88px",
                      // borderTopLeftRadius: "88px",
                      // borderBottomLeftRadius: "88px",
                      borderRight: "1px solid black",
                      borderColor: colorConfigs?.sidebar?.bg,
                      color: !view ? "white" : colorConfigs?.sidebar?.bg,
                      backgroundColor: !view
                        ? colorConfigs?.sidebar?.bg
                        : "white",
                      cursor: "pointer",
                    }}
                  >
                    {" "}
                    Hide
                  </Button>
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="start"
                style={{ padding: "16px" }}
              >
                <Avatar
                  alt={`${customerData?.first_name || ""} ${customerData?.last_name || ""
                    }`}
                  src={customerData && customerData?.profile_picture}
                  style={{
                    width: "100px",
                    height: "100px",
                    borderRadius: "50%",
                    marginBottom: "16px",
                  }}
                />
                <Typography
                  variant="h6"
                  style={{
                    fontSize: "18px",
                    lineHeight: "14px",
                    fontWeight: "700",
                    color: "#172B4D",
                  }}
                >
                  {customerData && customerData?.first_name}{" "}
                  {customerData && customerData?.last_name}
                </Typography>
                <Box style={{ marginTop: "6px" }}>
                  <Box
                    display="flex"
                    alignItems="center"
                    style={{ marginTop: "8px" }}
                  >
                    <EmailIcon
                      style={{
                        marginRight: "6px",
                        color: colorConfigs.sidebar.bg,
                        height: "13.5px",
                        width: "15px",
                      }}
                    />
                    {customerData && (
                      <Link
                        href="#"
                        onClick={(e) =>
                          handleMailToClick(`mailto:${customerData.email}`, e)
                        }
                        style={{ textDecoration: "none", color: "#172B4D" }}
                      >
                        <Typography
                          variant="body1"
                          style={{
                            fontSize: "14px",
                            lineHeight: "14px",
                            color: "#172B4D",
                          }}
                        >
                          {customerData && customerData.email}
                        </Typography>
                      </Link>
                    )}
                  </Box>

                  <Box
                    display="flex"
                    alignItems="center"
                    style={{ marginTop: "7px" }}
                  >
                    <PhoneIcon
                      style={{
                        marginRight: "6px",
                        color: colorConfigs.sidebar.bg,
                        height: "15px",
                        width: "15px",
                      }}
                    />
                    {customerData && (
                      <Link
                        to={`tel:${customerData && customerData.phone_number}`}
                        style={{ textDecoration: "none", color: "#172B4D" }}
                      >
                        <Typography
                          variant="body1"
                          style={{
                            fontSize: "14px",
                            lineHeight: "14px",
                            color: "#172B4D",
                          }}
                        >
                          {customerData &&
                            NewFormatPhoneNumber(customerData.phone_number, countryCode)
                          }
                        </Typography>
                      </Link>
                    )}
                  </Box>
                </Box>

                <Box display="flex" justifyContent="center" mt={1}>
                  {renderStars(
                    customerData && customerData?.review_info?.total_avg
                  )}{" "}
                </Box>
              </Box>
            </Box>
            {/**End Customer Info */}
            {/**Driver Info */}
            <Box style={{ borderTop: "2px solid rgb(240,240,240,1)", marginBottom: '10px' }}>
              <Typography
                variant="h6"
                style={{
                  lineHeight: "19.6px",
                  fontSize: "16px",
                  fontWeight: "700",
                  color: "#121826",
                  marginTop: '10px'
                }}
              >
                Driver
              </Typography>
              <Box
                style={{
                  background: "#F9F9F9",
                  display: "flex",
                  alignItems: "center",
                  padding: "20px 8px",
                  margin: "10px 0 20px 0",
                  borderRadius: "5px",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Avatar
                    alt={`${driverData?.first_name || ""} ${driverData?.last_name || ""
                      }`}
                    src={driverData?.profile_picture}
                    style={{
                      width: "44.49px",
                      height: "44.49px",
                      borderRadius: "50%",
                      marginRight: "15px",
                    }}
                  />
                  <Box>
                    <Typography
                      style={{
                        fontSize: "17px",
                        lineHeight: "14px",
                        fontWeight: "700",
                        color: "#172B4D",
                      }}
                    >
                      {driverData?.first_name} {driverData?.last_name}
                    </Typography>
                    <Box style={{ marginTop: "15px" }}>
                      <Box
                        display="flex"
                        alignItems="center"
                        style={{ marginTop: "8px" }}
                      >
                        <EmailIcon
                          style={{
                            marginRight: "6px",
                            color: colorConfigs.sidebar.bg,
                            height: "13.5px",
                            width: "15px",
                          }}
                        />
                        {driverData && (
                          <Link
                            to="#"
                            onClick={(e) => {
                              window.location.href = `mailto:${driverData.email}`;
                              e.preventDefault();
                            }}
                            style={{ textDecoration: "none", color: "#172B4D" }}
                          >
                            <Typography
                              variant="body1"
                              style={{
                                fontSize: "14px",
                                lineHeight: "14px",
                                color: "#172B4D",
                              }}
                            >
                              {driverData && driverData.email}
                            </Typography>
                          </Link>
                        )}
                      </Box>

                      <Box
                        display="flex"
                        alignItems="center"
                        style={{ marginTop: "7px" }}
                      >
                        <PhoneIcon
                          style={{
                            marginRight: "6px",
                            color: colorConfigs.sidebar.bg,
                            height: "15px",
                            width: "15px",
                          }}
                        />
                        {driverData && (
                          <a
                            href={`tel:${driverData && driverData.phone_number
                              }`}
                            style={{ textDecoration: "none", color: "#172B4D" }}
                          >
                            <Typography
                              variant="body1"
                              style={{
                                fontSize: "14px",
                                lineHeight: "14px",
                                color: "#172B4D",
                              }}
                            >
                              {driverData &&
                                NewFormatPhoneNumber(driverData.phone_number, countryCode)
                              }
                            </Typography>
                          </a>
                        )}
                      </Box>
                    </Box>
                    <Box display="flex" justifyContent="center" mt={1}>
                      {renderStars(
                        driverData && driverData?.review_info?.total_avg
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            {/**End Driver Info */}

            {/**Ride Info */}
            <Box>
              <Typography
                style={{
                  lineHeight: "19.6px",
                  fontSize: "16px",
                  fontWeight: "700",
                  color: "#121826",
                  marginBottom: "15px",
                }}
              >
                Ride Details
              </Typography>
              {/*Pickup*/}
              <Box>
                <Box
                  style={{
                    display: "flex",

                    alignItems: "center",
                  }}
                >

                  <img src={location} alt="loc" />
                  <Typography
                    style={{
                      lineHeight: "18px",
                      fontSize: "12px",
                      fontWeight: "700",
                      color: colorConfigs.sidebar.bg,
                      marginLeft: "10px",
                    }}
                  >
                    Pick Up:
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    style={{
                      fontSize: "12px",
                      lineHeight: "16px",
                      color: "#7A869A",
                      padding: "5px 25px",
                      marginBottom: "25px",
                    }}
                  >
                    {rideData && rideData?.pickup_address.city},{" "}
                    {rideData && rideData?.pickup_address.state},{" "}
                    {rideData && rideData?.pickup_address.country}
                  </Typography>
                </Box>
              </Box>
              {/*End Pickup*/}

              {/*Dropof*/}
              <Box>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {/* <Box
                    sx={{
                      marginRight: "15px",
                      width: "22.11px",
                      height: "22.11px",
                      backgroundColor: "#03992D",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        width: "6.32px",
                        height: "6.32px",
                        backgroundColor: "white",
                        borderRadius: "50%",
                      }}
                    />

                  </Box> */}
                  <img src={icon} alt="loc" />
                  <Typography
                    style={{
                      lineHeight: "18px",
                      fontSize: "12px",
                      fontWeight: "700",
                      color: "#03992D",
                      marginLeft: "10px",
                    }}
                  >
                    Drop Off:
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    style={{
                      fontSize: "12px",
                      lineHeight: "16px",
                      color: "#7A869A",
                      padding: "5px 25px",
                    }}
                  >
                    {rideData && rideData?.delivery_address?.city},{" "}
                    {rideData && rideData?.delivery_address?.state},{" "}
                    {rideData && rideData?.delivery_address?.country}
                  </Typography>
                </Box>
              </Box>
              {/*End Drop of*/}
              {/** Veiw Details Btn */}
              <Box display="flex" justifyContent="center" mt={4}>

              </Box>
            </Box>

            {/**End Rides Info */}
          </Grid>
        )}
      </Grid>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleSnackbarClose} severity="error">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default MessageViewerPrivate;
