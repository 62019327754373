import {
  Avatar,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { CardCvcElement, CardExpiryElement, CardNumberElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Switch } from "antd";
import { Formik } from "formik";
import React, { useState } from "react";
import { GoDotFill } from 'react-icons/go';
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import Error5xx from "../../../components/common/HandelError5xx";
import amex from "../../../assets/paymentMethods/Amex.png";
import discover from "../../../assets/paymentMethods/diccover.png";
import dinars from "../../../assets/paymentMethods/diners.png";
import geniric from "../../../assets/paymentMethods/geniric.png";
import jbc from "../../../assets/paymentMethods/jcb.png";
import mastercard from "../../../assets/paymentMethods/mastercard.png";
import unionpay from "../../../assets/paymentMethods/unionPay.png";
import visa from "../../../assets/paymentMethods/visa.png";
import { PaymentMethod } from "../../../components/common/PaymentMethod";
import colorConfigs from '../../../configs/colorConfigs';
import { auth } from "../../../configs/firebase";
import { setAddNewRide } from "../../../redux/features/appStateSlice";
import { postMethod } from "../../Hook/UseCustomApi";
import { stripePromise } from './stripeConfig';
import '../index.css';
import { IoCloseCircleSharp } from "react-icons/io5";

const Title = styled(Typography)({
  fontSize: "14px",
  fontWeight: "600",
  color: colorConfigs?.sidebar?.bg,
  marginBottom: "10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: "5px",
  marginBottom: "12px",
});

const CarTypeBox = styled(Box)(({ selected }) => ({
  borderRadius: '8px',
  borderColor: selected ? colorConfigs?.sidebar?.bg : "#ECECEC",
  borderStyle: 'solid',
  borderWidth: '1px',
  backgroundColor: selected ? colorConfigs?.sidebar?.ligthBg : "#FFFFFF",
  display: 'flex',
  width: '300px',
  maxWidth: '300px',
  fontSize: '14px',
  color: 'var(--Black, #172b4d)',
  whiteSpace: 'nowrap',
  justifyContent: 'space-between',
  padding: '16px',
  cursor: 'pointer',
  margin: '0 auto'
}));

const inputStyle = {
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#BBC1CE",
      borderWidth: 1,
      borderRadius: "8px",
    },
    "&:hover fieldset": {
      borderColor: "#BBC1CE",
      borderWidth: 1,
      borderRadius: "8px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#BBC1CE",
      borderWidth: 2,
      borderRadius: "8px",
    },
    input: {
      "&::placeholder": {
        color: "#BBC1CE",
        opacity: 1,
        fontSize: 14,
      },
      padding: "10px",
    },
  },
  "& label": {
    color: "#7A869A",
  },
  "& label.Mui-focused": {
    color: "#7A869A",
    fontSize: "17px",
    fontWeight: "500",
  },
};

const CreditCardForm = ({ handleNext, carTypes, backStep, activeStep, setFieldAddress }) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [cardNumberError, setCardNumberError] = useState("");
  const [expiryError, setExpiryError] = useState("");
  const [cvcError, setCvcError] = useState("");
  const addNewRide = useSelector((state) => state.appState.addNewRide);
  const [selectedCar, setSelectedCar] = useState(addNewRide?.selectedCarType ? addNewRide?.selectedCarType : null);
  const [isCash, setIsCash] = useState(addNewRide?.isCash ? addNewRide?.isCash : false);
  const [isScheduled, setIsScheduled] = useState(addNewRide?.isScheduled ? addNewRide?.isScheduled : false);
  const [selectedDateTime, setSelectedDateTime] = useState(addNewRide?.selectedDateTime ? addNewRide?.selectedDateTime : null);
  const cardInfo = useSelector((state) => state.appState.addNewRide?.cardInfo);
  const [note, setNote] = useState(addNewRide?.note ? addNewRide?.note : null);

  // handle error5xx
  const [tryAgain, setTryAgain] = useState();
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [errorCount, setErrorCount] = useState(0);
  const [errorDialogCounter, setErrorDialogCounter] = useState(0);

  const handleError5xx = () => {
    setIsErrorDialogOpen(true);
    setErrorCount(prevCount => {
      const newCount = prevCount + 1;
      if (newCount === 1) {
        setErrorDialogCounter(5);
      } else if (newCount === 2) {
        setErrorDialogCounter(10);
      }
      return newCount;
    });
  };

  const handleCloseErrorDialog = () => {
    setIsErrorDialogOpen(false);
    if (errorCount <= 2) {
      handleErrorCountByClose()
    }
  };

  const handleErrorCountByClose = () => {
    setErrorCount(prevCount => {
      const newCount = prevCount - 1;
      if (newCount === 1) {
        setErrorDialogCounter(5);
      } else if (newCount === 2) {
        setErrorDialogCounter(10);
      }
      return newCount;
    });
  }

  const handleChangeCash = (checked) => {
    setIsCash(checked);
    dispatch(setAddNewRide({ isCash: checked }));
  };

  const handleChangeSchedule = (checked) => {
    setIsScheduled(checked);
    if (!checked) setSelectedDateTime(null);
    dispatch(setAddNewRide({ isScheduled: checked }));
  };

  const validationSchema = Yup.object().shape({
    ...(isCash ? {} : { name: Yup.string().min(3).required("Required") }),
    selectedCar: Yup.object().required("Please select a ride type"),
    note: Yup.string().optional(),
  });

  const handleSubmit = async (values) => {
    setError("");
    if (error) return;
    if (isScheduled && (!selectedDateTime || selectedDateTime === ' ')) {
      setError("Please select a date and time");
      return;
    }

    const paymentDetails = {
      selectedValues: values,
      selectedCarType: selectedCar,
      isCash,
      isScheduled,
      selectedDateTime,
      note: values.note,
    };

    // Dispatch note and other details to Redux
    dispatch(setAddNewRide(paymentDetails));

    if (isCash) {
      handleNext();
      return;
    }

    if (!stripe || !elements || !selectedCar) return;

    try {
      if (!addNewRide?.cardInfo?.name) {
        setLoading(true);
        const cardElement = elements.getElement(CardNumberElement);
        const result = await stripe.createToken(cardElement, { name: values.name });

        if (result.error) {
          setError(result.error.message);
          setLoading(false);
          return;
        }

        const tokenId = await auth?.currentUser?.getIdToken();
        const headers = {
          "Content-Type": "application/json",
          Authorization: tokenId,
        };

        try {
          const response = await postMethod({
            url: `payment/customer/creditCard/addPaymentMethod`,
            body: { token: result.token.id },
            headers: headers,
          });

          const cardDataRedux = {
            card: response.data,
            selectedValues: values,
            selectedCarType: selectedCar,
            isCash,
            isScheduled,
            selectedDateTime,
            note: values.note,
          };

          dispatch(setAddNewRide({ cardInfo: result.token?.card }));
          dispatch(setAddNewRide(cardDataRedux));
          handleNext();
        } catch (error) {
          console.error("Error adding new card:", error);
          setLoading(false);
        }
      } else {
        handleNext();
      }
    } catch (e) {
      setError(e.message);
      setLoading(false);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          name: cardInfo?.name || '',
          selectedCar: addNewRide?.selectedCarType || null,
          selectedDateTime: addNewRide?.selectedDateTime || null,
          note: addNewRide?.note || '', // Include note in the initial values
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ handleBlur, handleChange, values, errors, handleSubmit, touched, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1} sx={{ p: 1 }}>
              {/* Ride type selection */}
              <Box>
                <Title>
                  <GoDotFill size={12} />
                  Select Ride Type
                </Title>
                <Grid container spacing={2}>
                  {carTypes?.map((carType, index) => (
                    <Grid sx={{ p: 0 }} item md={6} xs={12} key={index} onClick={() => {
                      setSelectedCar(carType);
                      setFieldValue("selectedCar", carType);
                    }}>
                      <CarTypeBox selected={selectedCar?.carTypeNameId === carType?.carTypeNameId}>
                        <Box sx={{ display: 'flex', gap: '10px', fontWeight: 700 }}>
                          <Avatar
                            sx={{
                              width: 64,
                              height: 'auto',
                              aspectRatio: '1.82',
                              objectFit: 'cover',
                              objectPosition: 'center'
                            }}
                            src={carType.image}
                            alt={carType.type}
                            variant="square"
                          />
                          <Typography sx={{ margin: 'auto 0', fontSize: 14, fontWeight: 700, color: '#172B4D' }}>
                            {carType.type}
                          </Typography>
                        </Box>
                        <Typography sx={{ textAlign: 'right', margin: 'auto 0', fontSize: 14, fontWeight: 600, color: '#172B4D' }}>
                          {carType?.maxRideAmount ? (`${carType?.amount?.toFixed(2)} - ${carType?.maxRideAmount?.toFixed(2)}`) : (`${carType?.amount?.toFixed(2)}`)}
                        </Typography>
                      </CarTypeBox>
                    </Grid>
                  ))}
                </Grid>
                {errors.selectedCar && touched.selectedCar && (
                  <Typography variant="caption" color="error">
                    {errors.selectedCar}
                  </Typography>
                )}
              </Box>

              {/* Payment details */}
              <Grid item md={12} xs={12}>
                <Title sx={{ mt: 1 }}>
                  <GoDotFill size={12} />
                  Payment Details
                  <Box
                    className="categoryBox"
                    sx={{ ml: 3 }}
                  >
                    <div style={{ display: "flex" }}>
                      <Switch
                        checked={isCash}
                        onChange={handleChangeCash}
                        className="custom-switch"
                      />
                      <span style={{ marginLeft: 8 }}>
                        {"Cash"}
                      </span>
                    </div>
                  </Box>
                </Title>
                {!isCash && (
                  <>
                    {!addNewRide?.cardInfo?.name ? (
                      <>
                        <Grid item md={12} xs={12} sx={{ mt: 2 }}>
                          <TextField
                            fullWidth
                            label="Name on Card"
                            name="name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                            value={values.name}
                            variant="outlined"
                            placeholder="Please enter your name"
                            focused
                            error={!!errors.name}
                            sx={inputStyle}
                            inputProps={{ maxLength: 20 }}
                          />
                          {errors.name && touched.name && (
                            <Typography variant="caption" color="error">
                              {errors.name}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <CardNumberElement
                            id="cardNumber"
                            options={{
                              placeholder: "Card Number*",
                              showIcon: true,
                            }}
                            onChange={(event) => {
                              if (event.error) {
                                setCardNumberError(event.error.message);
                              } else {
                                setCardNumberError("");
                              }
                            }}
                            style={inputStyle}
                          />
                          {cardNumberError && (
                            <Typography variant="caption" color="error">
                              {cardNumberError}
                            </Typography>
                          )}
                        </Grid>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                          <Grid item md={6} xs={12}>
                            <CardExpiryElement
                              options={{
                                placeholder: "MM/YY*",
                              }}
                              id="expiry"
                              onChange={(event) => {
                                if (event.error) {
                                  setExpiryError(event.error.message);
                                } else {
                                  setExpiryError("");
                                }
                              }}
                              style={inputStyle}
                            />
                            {expiryError && (
                              <Typography variant="caption" color="error">
                                {expiryError}
                              </Typography>
                            )}
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <CardCvcElement
                              options={{
                                placeholder: "CVC*",
                              }}
                              id="cvc"
                              onChange={(event) => {
                                if (event.error) {
                                  setCvcError(event.error.message);
                                } else {
                                  setCvcError("");
                                }
                              }}
                              style={inputStyle}
                            />
                            {cvcError && (
                              <Typography variant="caption" color="error">
                                {cvcError}
                              </Typography>
                            )}
                          </Grid>
                        </Box>
                      </>
                    ) : (
                      <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center', gap: 5 }}>
                        <PaymentMethod
                          img={
                            cardInfo?.brand === 'American Express'
                              ? amex
                              : cardInfo?.brand === 'Diners Club'
                                ? dinars
                                : cardInfo?.brand === 'Discover'
                                  ? discover
                                  : cardInfo?.brand === 'JCB'
                                    ? jbc
                                    : cardInfo?.brand === 'Visa'
                                      ? visa
                                      : cardInfo?.brand === 'MasterCard'
                                        ? mastercard
                                        : cardInfo?.brand === 'UnionPay'
                                          ? unionpay
                                          : geniric
                          }
                          text={`**** **** **** ${cardInfo?.last4}`}
                        />
                        <Box onClick={() => { dispatch(setAddNewRide({ cardInfo: {}, card: null })) }} sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center', gap: 1, cursor: 'pointer' }} >
                          <IoCloseCircleSharp style={{ color: '#ff3333' }} size={25} />
                        </Box>
                      </Box>
                    )}
                  </>
                )}
              </Grid>

              {/* Note for driver */}
              <Grid item md={12} xs={12}>
                <Title sx={{ mt: 1,mb:2.1 }}>
                  <GoDotFill size={12} />
                  Note for Driver (Optional)
                </Title>
                <TextField
                  fullWidth
                  label="Note for Driver"
                  name="note"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.note}
                  focused
                  variant="outlined"
                  placeholder="Note for driver"
                  sx={inputStyle}
                />
              </Grid>

              {/* Submit and Back buttons */}
              <Grid item md={12} xs={12} sx={{ display: "flex", justifyContent: "end", mt: 1 }}>
                <Button
                  sx={{
                    ml: 1,
                    color: colorConfigs?.sidebar?.bg,
                    border: `1px solid ${colorConfigs?.sidebar?.bg}`,
                    px: 5,
                    borderRadius: "5px",
                    '&:hover': {
                      color: colorConfigs?.sidebar?.bg,
                      border: `1px solid ${colorConfigs?.sidebar?.bg}`,
                    }
                  }}
                  onClick={() => { backStep() }}
                  variant='outlined'
                >
                  Back
                </Button>
                <Button
                  sx={{
                    ml: 1,
                    background: colorConfigs?.sidebar?.bg,
                    color: "white",
                    px: 5,
                    borderRadius: "5px",
                    '&:hover': {
                      background: colorConfigs?.sidebar?.bg,
                      color: 'white',
                    }
                  }}
                  type="submit"
                  disabled={loading}
                  variant="contained"
                >
                  {(loading) ? "Processing..." : "Next"}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
      <Error5xx open={isErrorDialogOpen} errCount={errorCount} count={errorDialogCounter} handleClose={handleCloseErrorDialog} tryAgain={tryAgain} />
    </>
  );
};

const CarTypeAndCreditCardForm = ({ handleNext, activeStep, carTypes, setCardData, backStep, isLoading, setFieldAddress }) => {
  return (
    <Elements stripe={stripePromise}>
      <CreditCardForm setFieldAddress={setFieldAddress} backStep={backStep} activeStep={activeStep} handleNext={handleNext} isLoading={isLoading} carTypes={carTypes} setCardData={setCardData} />
    </Elements>
  );
};

export default CarTypeAndCreditCardForm;
