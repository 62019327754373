import {
  Alert,
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Snackbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Error5xx from "../../components/common/HandelError5xx";
import { Email as EmailIcon } from "@mui/icons-material";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import avatar from "../../assets/icons/Group 1261156281.png";
import driverIcon from "../../assets/icons/driver.png";
import driverIconWf from "../../assets/women first icons/healthicons_truck-driver-outline.png";
import colorConfigs from "../../configs/colorConfigs";
import { NewFormatPhoneNumber } from "../../utils/localization/NewPhoneUtils";
import { getMethod, putMethod } from "../Hook/UseCustomApi";
import TextRating from "../rating/rate";
import RideDetails from "./RideDetails";
import RideRating from "./RideRating";
import "./index.css";
import RideTimeline from "./rideTimeline";
import { useDispatch, useSelector } from "react-redux";
import SecurityAlertSmall from '../../assets/svg/SecurityAlertSmall';
import { closeModal, openModal } from "../../redux/features/modalSlice";
import CancelRideModal from "./CancelRideModal";
import { postDataRedux } from "../../redux/features/appStateSlice";

const RidesDetails = () => {
  const params = useParams();
  const ridesList = useSelector((state) => state.appState.ridesList);
  const ridesListHistory = useSelector(
    (state) => state.appState.ridesListhistory
  );
  const rides = useSelector((state) => state.appState.rides);
  const ridesScheduledList = useSelector(
    (state) => state.appState.ridesScheduledList
  );

  const [selectedObject, setSelectedObject] = useState(null);
  console.log(selectedObject, "selectedObject");
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const [valueTabs, setValueTabs] = React.useState("one");
  const [enabled, setEnabled] = useState(selectedObject?.enabled);
  const [loading, setLoading] = useState("");
  const [cancelConfirmationDialogOpen, setCancelConfirmationDialogOpen] =
    useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("");
  const userDataa = useSelector((state) => state.appState.userData);
  const [is_canceled, setIs_canceled] = useState(false);
  const countryCode = useSelector((state) => state.country.selectedCountry);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [snackbarOpenErrorMessage, setSnackbarOpenErrorMessage] =
    useState(false);
  const [snackbarErrorMessage, setSnackbarErrorMessage] = useState("");
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarErrorMessage(message);
    setSnackbarOpenErrorMessage(true);
  };



  const [tryAgain, setTryAgain] = useState();
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [errorCount, setErrorCount] = useState(0);
  const [errorDialogCounter, setErrorDialogCounter] = useState(0);
  const [refetchRide, setRefetchRide] = useState(false);
  const handleError5xx = () => {
    setIsErrorDialogOpen(true);
    setErrorCount(prevCount => {
      const newCount = prevCount + 1;
      if (newCount === 1) {
        setErrorDialogCounter(5);
      } else if (newCount === 2) {
        setErrorDialogCounter(10);
      }
      return newCount;
    });
  };
  const handleCloseErrorDialog = () => {
    setIsErrorDialogOpen(false);
    if (errorCount <= 2) {
      handleErrorCountByClose()
    }

  };
  const handleErrorCountByClose = () => {
    setErrorCount(prevCount => {
      const newCount = prevCount - 1;
      if (newCount === 1) {
        setErrorDialogCounter(5);
      } else if (newCount === 2) {
        setErrorDialogCounter(10);
      }
      return newCount;
    });
  }



  useEffect(() => {
    setEnabled(selectedObject?.enabled);
  }, [selectedObject?.enabled]);

  useEffect(() => {
    if (rides === "activeRides") {
      getMethod({
        url: `ride/dispatch-view/ride/${params["id"]}`,
      }).then((response) => {
        setSelectedObject(response?.data);
      }).catch(() => {
        console.log('error in get ride')
      })
    } else if (rides === "history") {
      getMethod({
        url: `ride/dispatch-view/ride/${params["id"]}`,
      }).then((response) => {
        setSelectedObject(response?.data);
      }).catch(() => {
        console.log('error in get ride')
      })
    } else if (rides === "scheduled") {
      getMethod({
        url: `ride/dispatch-view/ride/${params["id"]}`,
      }).then((response) => {
        setSelectedObject(response?.data);
      }).catch(() => {
        console.log('error in get ride')
      })
    } else {
      getMethod({
        url: `ride/dispatch-view/ride/${params["id"]}`,
      }).then((response) => {
        setSelectedObject(response?.data);
      })
    }
    window.scrollTo({ top: 0 });
  }, [params, refetchRide]);

  const handleChange = (e, newValue) => {
    setValueTabs(newValue);
  };

  const handleClose = () => {
    dispatch(closeModal())
  }

  const afterSubmit = () => {
    setSelectedObject(prev => ({ ...prev, status: 'canceled' }));
    dispatch(postDataRedux({ stateName: 'rides', data: 'cancel-ride' }))
    setIs_canceled(true)
  }

  const handleCancelRide = () => {
    dispatch(openModal(<CancelRideModal afterSubmit={afterSubmit} handleClose={handleClose} ride_id={params["id"]} />))
  };


  const handleCancelConfirmation = (confirmed, type) => {
    setCancelConfirmationDialogOpen(false);

    if (confirmed) {
      setLoading(true);

      putMethod({
        url: `ride/cancel/${type}/${selectedObject?._id}`,
        body: {
          reason: "Cancel by admin",
          uid: userDataa?._id,
        },
        handleErrorMessage: showSnackbar,
      })
        .then((res) => {
          console.log(res, "res");
          if (res.status === 200) {
            setSnackbarOpen(true);
            setSnackbarSeverity("success");
            setSnackbarMessage("Ride canceled successfully!");
            setIs_canceled(true);
          } else {
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            setSnackbarMessage("Error canceling ride.");
            setIs_canceled(false);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error canceling ride:", error);
          setSnackbarOpen(true);
          setSnackbarSeverity("error");
          setSnackbarMessage("Error canceling ride.");
          setLoading(false);
          if (error === "Internal Server Error: Server error occurred.") {
            handleError5xx();
            setTryAgain(() => () => handleCancelConfirmation(confirmed, type));
          }
        });
    }
  };

  const isDeliveredOrAssigned =
    selectedObject?.status !== "new" ||
    selectedObject?.status !== "scheduled" ||
    selectedObject?.status !== "pending";

  console.log(setSelectedObject)
  return (
    <>
      {/* handleError5xx */}

      <Error5xx open={isErrorDialogOpen} errCount={errorCount} count={errorDialogCounter} handleClose={handleCloseErrorDialog} tryAgain={tryAgain} />
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "85vh",
          py: 3,
        }}
      >
        {selectedObject && selectedObject?.status !== "new" ? (
          <div>
            {!selectedObject?.driver_info?.first_name ? (
              ""
            ) : (
              <>
                {!smDown ? (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div>
                      <Box>
                        <div style={{ position: "relative" }}>
                          <img
                            alt="driver"
                            loading="lazy"
                            style={styles?.companyImage}
                            src={selectedObject?.driver_info?.profile_picture}
                          />
                        </div>
                      </Box>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        paddingLeft: 20,
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          paddingLeft: 7,
                        }}
                      >
                        <>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <div style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}>
                              <Typography
                                style={styles.title}
                              >
                                {selectedObject?.driver_info?.first_name}{" "}
                                {selectedObject?.driver_info?.last_name}
                              </Typography>
                              {isDeliveredOrAssigned && (
                                <div
                                  style={{
                                    marginTop: "10px",
                                    display: "flex",
                                    marginLeft: "20px",
                                    padding: "4px 10px",
                                    borderRadius: "3px",
                                    backgroundColor: process.env.REACT_APP_ENV === 'women_first' ? "#F9F6FF" : "#D5F1FF",
                                  }}
                                >
                                  <img
                                    src={process.env.REACT_APP_ENV === 'women_first' ? driverIconWf : driverIcon}
                                    alt="driverIcon"
                                    loading="lazy"
                                    style={{
                                      width: "20.4px",
                                      height: "21px",
                                      marginRight: "7px",
                                    }}
                                  />
                                  <span
                                    style={{
                                      color: colorConfigs?.sidebar?.bg,
                                      fontSize: "15px",

                                      fontWeight: "600",
                                    }}
                                  >
                                    Driver
                                  </span>
                                </div>
                              )}
                            </div>
                            {selectedObject?.status && (selectedObject?.status !== 'pickedup' && selectedObject?.status !== 'enroute_to_delivery' && selectedObject?.status !== 'waiting_delivery' && selectedObject?.status !== 'delivered' && selectedObject?.status !== 'canceled') && <Button
                              variant="outlined"
                              sx={{
                                borderColor: 'red',
                                color: 'red',
                                fontWeight: 'bold',
                                borderRadius: '30px',
                                padding: '10px 30px',
                                textTransform: 'none',
                                '&:hover': {
                                  borderColor: 'red',
                                  backgroundColor: 'transparent',
                                },
                              }}
                              onClick={handleCancelRide}
                            >
                              Cancel Ride
                            </Button>}
                            {selectedObject && (selectedObject?.panic_button_customer || selectedObject?.panic_button_driver) && <Box sx={{
                              display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', color: "#F71500",
                              borderRadius: '25px', backgroundColor: "#FFF1F1", height: "44.5px", marginTop: "25px",
                              cursor: "pointer",
                              fontFamily: "Outfit",
                              fontSize: "17px",
                              fontWeight: "600",
                              lineHeight: "20px",
                              padding: "0 10px"
                            }}>
                              <SecurityAlertSmall style={{ marginRight: "5px" }} />
                              {selectedObject?.panic_button_customer && selectedObject?.panic_button_driver ? "Panic from Driver and Passenger" :
                                selectedObject?.panic_button_customer ? "Panic from Passenger" : "Panic from Driver"}

                            </Box>}
                          </div>
                        </>

                        <div style={{ width: "10px" }} />
                        {/* <TextRating value={4} /> */}
                      </div>
                    </div>
                  </div>
                ) : (
                  <Grid container>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div>
                        <img
                          alt="driver"
                          loading="lazy"
                          style={styles.companyImageMobileSize}
                          src={selectedObject?.driver_info?.profile_picture}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingLeft: 10,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            paddingLeft: 10,
                          }}
                        >
                          <Typography style={styles.title}>
                            {selectedObject?.driver_info?.first_name}{" "}
                            {selectedObject?.driver_info?.last_name}
                          </Typography>
                          <div style={{ width: "10px" }} />
                        </div>
                      </div>
                    </div>
                  </Grid>
                )}
              </>
            )}
          </div>
        ) : (
          ""
        )}


        {selectedObject && (selectedObject?.status === "pending" || selectedObject?.status === "new" || selectedObject?.status === "scheduled") && (
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end' }}>

            <Button
              variant="outlined"
              sx={{
                borderColor: 'red',
                color: 'red',
                fontWeight: 'bold',
                borderRadius: '30px',
                padding: '10px 30px',
                mb: 2,
                textTransform: 'none',
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'center',
                '&:hover': {
                  borderColor: 'red',
                  backgroundColor: 'transparent',
                },
              }}
              onClick={handleCancelRide}
            >
              Cancel Ride
            </Button>
          </Box>

        )}

        <div>
          <Box
            sx={{
              width: "100%",
              background: "#E8E8E8",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Tabs
              value={valueTabs}
              onChange={handleChange}
              textColor={colorConfigs?.sidebar?.bg}
              sx={{ color: "#7A869A" }}
              TabIndicatorProps={{
                style: {
                  backgroundColor: colorConfigs?.sidebar?.bg,
                },
              }}
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
              variant="scrollable"
            >
              <Tab
                value="one"
                label="Ride Details"
                style={{
                  width: "182px",
                  fontWeight: "600",
                  color: valueTabs === "one" ? colorConfigs?.sidebar?.bg : "#7A869A",
                }}
              />

              <Tab
                value="three"
                label="Ride Timeline"
                style={{
                  width: "182px",
                  fontWeight: "600",
                  color: valueTabs === "three" ? colorConfigs?.sidebar?.bg : "#7A869A",
                }}
              />
              <Tab
                value="four"
                label="Passenger Details"
                style={{
                  width: "182px",
                  fontWeight: "600",
                  color: valueTabs === "four" ? colorConfigs?.sidebar?.bg : "#7A869A",
                }}
              />
              {selectedObject?.status === "delivered" ? (
                <Tab
                  value="six"
                  label="Ride Rating"
                  style={{
                    width: "182px",
                    fontWeight: "600",
                    color: valueTabs === "six" ? colorConfigs?.sidebar?.bg : "#7A869A",
                  }}
                />
              ) : (
                ""
              )}
              {!selectedObject?.driver_info?.first_name ? (
                ""
              ) : selectedObject?.status !== "new" &&
                selectedObject?.status !== "canceled" ? (
                <Tab
                  value="five"
                  label="Vehicle Details"
                  style={{
                    width: "182px",
                    fontWeight: "600",
                    color: valueTabs === "five" ? colorConfigs?.sidebar?.bg : "#7A869A",
                  }}
                />
              ) : (
                ""
              )}
            </Tabs>

            <div style={{ marginTop: "6px", marginRighr: "10px" }}>
              {is_canceled ? (
                <></>
              ) : (
                <>
                  {selectedObject?.status === "new" ||
                    selectedObject?.status === "assigned" ||
                    selectedObject?.status === "pending" ? (
                    <></>

                  ) : (
                    ""
                  )}
                </>
              )}
            </div>
            <Dialog
              style={{ zIndex: 10, borderRadius: "30px" }}
              open={cancelConfirmationDialogOpen}
              onClose={() => setCancelConfirmationDialogOpen(false)}
              keepMounted
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogContent style={{ padding: "30px" }}>
                <div
                  style={{ display: "flex", justifyContent: "center" }}
                ></div>
                <DialogContentText
                  id="alert-dialog-slide-description"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Typography
                    variant="h6"
                    style={{
                      fontWeight: "bold",
                      color: "black",
                      marginTop: "10px",
                      marginBottom: "8px",
                    }}
                  >
                    Cancel Ride
                  </Typography>
                </DialogContentText>

                <Typography>
                  Are you sure you want to cancel this ride?
                </Typography>
              </DialogContent>

              <DialogActions
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  marginBottom: "20px",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                }}
              >
                <Button
                  variant="outlined"
                  style={{
                    color: loading ? "gray" : "black",
                    borderColor: "lightgray",
                    width: "100%",
                  }}
                  onClick={() => handleCancelConfirmation(false)}
                >
                  No
                </Button>

                <Button
                  variant="contained"
                  style={{
                    background: loading ? "gray" : "var(--Error, #FD4343)",
                    color: "white",
                    width: "100%",
                  }}
                  onClick={() => handleCancelConfirmation(true, "admin")}
                  disabled={loading}
                >
                  {loading ? "...Loading" : "Yes, Cancel"}
                </Button>
              </DialogActions>
            </Dialog>
            <div style={{ height: "30px" }} />
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={snackbarOpen}
              autoHideDuration={4000}
              onClose={() => setSnackbarOpen(false)}
            >
              <Alert
                severity={snackbarSeverity}
                variant="filled"
                sx={{ width: "100%" }}
              >
                {snackbarMessage}
              </Alert>
            </Snackbar>
          </Box>
          {valueTabs === "one" && (
            <>
              <Box sx={{ width: "100%", marginTop: "10px" }}>
                <RideDetails
                  selectedObjectData={selectedObject}
                  is_canceled={is_canceled}
                />
              </Box>
            </>
          )}

          {valueTabs === "three" && (
            <>
              <Box
                sx={{
                  width: "100%",
                  marginTop: "10px",
                  display: "flex",
                  gap: "20px",
                }}
              >
                <RideTimeline selectedObject={selectedObject} />
              </Box>
            </>
          )}
          {valueTabs === "four" && (
            <Box
              sx={{ width: "100%", background: "#F9F9F9", marginTop: "10px" }}
            >
              <div style={{ padding: "20px" }}>
                <Typography
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    color: colorConfigs?.sidebar?.bg,
                  }}
                >
                  Passenger Details:
                </Typography>
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div>
                  <Box>
                    {selectedObject?.customer_details?.profile_picture === "" ? (
                      ""
                    ) : (
                      <div style={{ position: "relative" }}>
                        <img
                          alt="passenger"
                          loading="lazy"
                          style={styles?.companyImage}
                          src={
                            selectedObject?.customer_details?.profile_picture
                          }
                        />
                      </div>
                    )}
                  </Box>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    paddingLeft: 20,
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                    }}
                  >
                    <Typography style={styles.title}>
                      {selectedObject?.customer_details?.first_name}{" "}
                      {selectedObject?.customer_details?.last_name &&
                        selectedObject.customer_details.last_name
                          .charAt(0)
                          .toUpperCase() + "."}
                    </Typography>

                    <div style={{ width: "10px" }} />
                    {/* <TextRating
                      value={
                        selectedObject?.customer_details?.review_avg
                          ? selectedObject?.customer_details?.review_avg
                          : 0
                      }
                    /> */}
                  </div>
                </div>
              </div>

              <div className="">
                <div className="column">
                  <div className="row">
                    <span style={{ color: "#696969" }}>First Name: </span>
                    <span style={{ color: "#535353", fontWeight: "600" }}>
                      {selectedObject?.customer_details?.first_name}
                    </span>
                  </div>
                  <div className="row">
                    <span style={{ color: "#696969" }}>Last Name: </span>{" "}
                    <span
                      style={{
                        color: "#535353",
                        fontWeight: "600",
                        textTransform: "capitalize",
                      }}
                    >
                      {selectedObject?.customer_details?.last_name}
                    </span>
                  </div>
                </div>
                <div className="column">
                  {selectedObject?.customer_details?.email != "" ||
                    (selectedObject?.customer_details?.email != undefined && (
                      <div className="row" style={{ display: "flex" }}>
                        <EmailIcon
                          style={{ color: "#696969", marginRight: "12px" }}
                        />

                        <span style={{ color: "#535353", fontWeight: "600" }}>
                          {selectedObject?.customer_details?.email}
                        </span>
                      </div>
                    ))}
                  <a
                    href={`tel:${selectedObject?.customer_details?.phone_number}`}
                    style={{ textDecoration: 'none' }}
                  >
                    <div className="row" style={{ display: "flex" }}>
                      <LocalPhoneIcon
                        style={{ color: "#696969", marginRight: "12px" }}
                      />

                      <span style={{ color: "#535353", fontWeight: "600" }}>
                        {NewFormatPhoneNumber(selectedObject?.customer_details?.phone_number, countryCode)}
                      </span>
                    </div>
                  </a>
                </div>
              </div>
            </Box>
          )}
          {valueTabs === "six" && (
            <>
              {selectedObject?.rated_customer ||
                selectedObject?.rated_driver ? (
                <Box sx={{ padding: "20px", marginTop: "10px" }}>
                  {selectedObject?.rated_customer ? (
                    <>
                      <Typography
                        sx={{

                          height: "22px",
                          fontFamily: "Poppins",
                          fontSize: "18px",
                          fontWeight: "700",
                          lineHeight: "21.6px",
                          textAlign: "left",
                          color: "#42526E",
                          mb: 2,
                        }}
                      >
                        Passenger Rate for this Ride
                      </Typography>
                      <RideRating
                        name={`${selectedObject?.customer_details?.first_name} ${selectedObject?.customer_details?.last_name}`}
                        avatar={
                          selectedObject?.customer_details?.profile_picture
                        }
                        rating={selectedObject?.rated_customer_info?.rated}
                        review={selectedObject?.rated_customer_info?.text}
                      />
                    </>
                  ) : (
                    ""
                  )}
                  {selectedObject?.rated_driver ? (
                    <>
                      <Typography
                        sx={{

                          height: "22px",
                          fontFamily: "Poppins",
                          fontSize: "18px",
                          fontWeight: "700",
                          lineHeight: "21.6px",
                          textAlign: "left",
                          color: "#42526E",
                          mb: 2,
                        }}
                      >
                        Driver Rate for this Ride
                      </Typography>
                      <RideRating
                        name={`${selectedObject?.driver_info?.first_name} ${selectedObject?.driver_info?.last_name}`}
                        avatar={selectedObject?.driver_info?.profile_picture}
                        rating={selectedObject?.rated_driver_info?.rated}
                        review={selectedObject?.rated_driver_info?.text}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </Box>
              ) : (
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                  color="text.secondary"
                  marginTop="50px"
                  sx={{ p: 4, borderRadius: 2 }}
                >
                  <Avatar src={avatar} sx={{ mr: 2, width: 56, height: 56 }} />
                  <Typography variant="h6" sx={{ mt: 2 }}>
                    No Reviews Here
                  </Typography>
                  <Typography variant="body2" sx={{ mt: 1, color: "#8b97a6" }}>
                    This ride does not have any reviews yet.
                  </Typography>
                </Box>
              )}
            </>
          )}

          {valueTabs === "five" && (
            <>
              <div>
                <div
                  style={{
                    marginLeft: "10px",
                    display: "flex",
                    paddingTop: "16px",
                  }}
                >
                  {selectedObject?.driver_info?.vehicle_info?.vehicle_images?.map(
                    (src, index) => (
                      <div
                        key={index}
                        style={{ position: "relative", marginRight: "20px" }}
                      >
                        <img
                          src={src}
                          alt={`alt-${index}`}
                          style={{ width: "250px", borderRadius: "10px" }}
                        />
                      </div>
                    )
                  )}
                </div>
              </div>
              <Box>
                <h3 style={{ color: colorConfigs?.sidebar?.bg, fontWeight: "700" }}>
                  Vehicle Details:
                </h3>
                <Box
                  sx={{
                    width: "100%",
                    background: "#F9F9F9",
                    marginTop: "10px",
                    display: "flex",
                    gap: "200px",
                    padding: "24px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div>
                      <span style={{ color: "#696969" }}>Vehicle NO:</span>
                      <span
                        style={{
                          color: "#535353",
                          fontWeight: "600",
                          paddingLeft: "12px"
                          , textTransform: 'uppercase'
                        }}
                      >
                        {
                          selectedObject?.driver_info?.vehicle_info
                            ?.license_plate
                        }
                      </span>
                    </div>
                    <div>
                      <span style={{ color: "#696969" }}>Vehicle Year:</span>
                      <span
                        style={{
                          color: "#535353",
                          fontWeight: "600",
                          paddingLeft: "12px",
                        }}
                      >
                        {
                          selectedObject?.driver_info?.vehicle_info
                            ?.vehicle_year
                        }
                      </span>
                    </div>
                    <div>
                      <span style={{ color: "#696969" }}>Vehicle Type:</span>
                      <span
                        style={{
                          color: "#535353",
                          fontWeight: "600",
                          paddingLeft: "12px",
                        }}
                      >
                        {selectedObject?.driver_info?.vehicle_info?.vehicle_type || 'Sedan'}
                      </span>
                    </div>
                    <div>
                      <span style={{ color: "#696969" }}>license plate:</span>
                      <span
                        style={{
                          color: "#535353",
                          fontWeight: "600",
                          paddingLeft: "12px",
                        }}
                      >
                        {
                          selectedObject?.driver_info?.vehicle_info
                            ?.license_plate
                        }
                      </span>
                    </div>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div>
                      <span style={{ color: "#696969" }}>Vehicle Make:</span>
                      <span
                        style={{
                          color: "#535353",
                          fontWeight: "600",
                          paddingLeft: "12px",
                        }}
                      >
                        {
                          selectedObject?.driver_info?.vehicle_info
                            ?.vehicle_make
                        }
                      </span>
                    </div>

                    <div>
                      <span style={{ color: "#696969" }}>Vehicle Model:</span>
                      <span
                        style={{
                          color: "#535353",
                          fontWeight: "600",
                          paddingLeft: "12px",
                        }}
                      >
                        {
                          selectedObject?.driver_info?.vehicle_info
                            ?.vehicle_model
                        }
                      </span>
                    </div>
                    <div>
                      <span style={{ color: "#696969" }}>Vehicle Color:</span>
                      <span
                        style={{
                          color: "#535353",
                          fontWeight: "600",
                          paddingLeft: "12px",
                        }}
                      >
                        {
                          selectedObject?.driver_info?.vehicle_info
                            ?.vehicle_color
                        }
                      </span>
                    </div>
                  </div>
                </Box>
              </Box>
            </>
          )}
        </div>
      </Box>

      <Snackbar
        open={snackbarOpenErrorMessage}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleSnackbarClose} severity="error">
          {snackbarErrorMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

const styles = {
  title: {
    fontWeight: "bold",
    fontSize: "26px",

    textTransform: "capitalize",
  },
  subTitle: {
    fontWeight: "bold",
    fontSize: "16px",

    marginBottom: "5px",
  },
  companyImage: {
    width: "150px",
    height: "135px",
    border: "2px solid #EEEEEE7A",
    borderRadius: 15,
  },
  companyImageMobileSize: {
    width: "90px",
    height: "90px",
    border: "2px solid #EEEEEE7A",
    borderRadius: 10,
    marginTop: "0px",
  },
};
export default RidesDetails;
