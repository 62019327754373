import React, { useState } from "react";
import StarIcon from "@mui/icons-material/Star";
import { Avatar, Box, Card, Typography } from "@mui/material";
import { format } from 'date-fns';
import icon from "../../../assets/images/Icon.png";
import location from "../../../assets/images/location.png";
import stopIcon from "../../../assets/images/stop.png";
import BlueButton from "../../../components/common/Buttons/BlueButton";
import colorConfigs from "../../../configs/colorConfigs";
import distanceMeterToUnit from "../../../utils/distanceMeterToUnit";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { display, maxHeight } from "@mui/system";

const styles = {
    card: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "16px",
        borderRadius: "8px",
        boxShadow: "rgba(176, 176, 176, 0.15) 0 1px 25px",
        marginTop: "16px",
        flexWrap: "wrap",
        overflowY: "auto",
        // maxHeight: "200px"
    },
    addressContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexWrap: "wrap",
        minWidth: "0",
    },
    iconContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginRight: "10px",
    },
    addressText: {
        color: "#172B4D",
        fontWeight: "600",
        fontFamily: "Poppins",
        fontSize: "14px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        maxWidth: "200px",
    },
    timeText: {
        color: "#7A869A",
        fontSize: "12px",
        fontFamily: "Poppins",
    },
    avatarContainer: {
        display: "flex",
        alignItems: "center",
        gap: "30px",
        flexDirection: "column",
    },
    ratingText: {
        display: "flex",
        alignItems: "center",
        color: "#172B4D",
        justifyContent: "center",
        fontSize: "12px",
        fontWeight: "600",
        fontFamily: "Poppins",
    },
    dot: {
        width: "4px",
        height: "4px",
        borderRadius: "50%",
        backgroundColor: "#E0E0E0",
        margin: "4px 0",
    },
};

const DotDivider = ({ singleDot }) => (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div style={styles.dot}></div>
        {!singleDot && (
            <>
                <div style={styles.dot}></div>
                <div style={styles.dot}></div>
            </>
        )}
    </div>
);

const convertTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    if (minutes >= 1440) { // 1440 minutes in a day
        const days = Math.floor(minutes / 1440);
        return `${days} day${days > 1 ? 's' : ''}`;
    } else if (minutes >= 60) { // 60 minutes in an hour
        const hours = Math.floor(minutes / 60);
        return `${hours} hour${hours > 1 ? 's' : ''}`;
    } else if (minutes > 0) {
        return `${minutes} min`;
    } else {
        return `${seconds} sec`;
    }
};

const RideCard = ({ cardData, selected, onClick, status, handleRideDetailsClick }) => {
    const createdAt = format(new Date(cardData?.createdAt), "hh:mm aa");
    const [showAll, setShowAll] = useState(false);
    const hasStopPoints = cardData?.stop_points?.length > 0;
    return (
        <Card
            style={{
                ...styles.card,
                border: selected ? `2px solid ${colorConfigs?.sidebar?.bg}` : 'none',
                background: selected ? colorConfigs?.sidebar?.ligthBg : 'none',
                cursor: 'pointer',
            }}
            onClick={onClick}
        >
            <div style={styles.addressContainer}>
                <div style={styles.iconContainer}>
                    <img src={location} alt="location" />
                    <DotDivider singleDot={hasStopPoints} />
                    {cardData?.stop_points && (
                        <>
                            {(cardData?.stop_points.length <= 2) || (showAll) ? (
                                cardData?.stop_points?.map((_, index) => (
                                    <React.Fragment key={index}>
                                        <img src={stopIcon} alt="stop" style={{ height: "38px", width: "38px" }} />
                                        {<DotDivider singleDot={true} />}
                                    </React.Fragment>
                                ))
                            ) : (
                                <>
                                    <img src={stopIcon} alt="stop" style={{ height: "38px", width: "38px" }} />
                                    <DotDivider singleDot={true} />
                                </>
                            )}
                        </>
                    )}
                    <img src={icon} alt="icon" />
                </div>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                    height: cardData?.stop_points?.length === 2 ? "188px" : ((cardData?.stop_points?.length === 1) || (cardData?.stop_points?.length > 2)) ? showAll ? `${80 + (40 * cardData?.stop_points?.length) + (cardData?.stop_points?.length) * 12}px` : "138px" : "112px",
                    width: "200px",


                }}>
                    <Box>
                        <Typography style={styles.addressText}>
                            {cardData?.pickup_address?.address}
                        </Typography>

                        <Typography sx={{ visibility: status !== 'unassigned' ? 'visible' : 'hidden', color: '#888', fontSize: '14px', ...styles.timeText }}>
                            {convertTime(cardData?.estimation_time)} . {distanceMeterToUnit(cardData?.estimation_distance, true)}
                        </Typography>
                    </Box>
                    {cardData?.stop_points && cardData.stop_points.length > 2 ? (
                        !showAll && (<div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "start",
                                justifyContent: "space-between",
                                width: "200px",
                                marginBottom: "2px",
                                minHeight: "40px",
                            }}
                        >
                            <div>
                                <Typography
                                    variant="body2"
                                    style={{
                                        color: "#172B4D",
                                        fontWeight: "600",
                                        fontFamily: "Poppins",
                                        fontSize: "14px",
                                        marginTop: "5px"
                                    }}
                                >
                                    Stop points: {cardData.stop_points.length}
                                </Typography>
                            </div>
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "20px",
                            }}>
                                <ExpandMoreIcon
                                    onClick={(e) => { e.stopPropagation(); setShowAll(true) }}
                                    style={{
                                        fontSize: '26px',
                                        cursor: 'pointer',
                                        color: colorConfigs.sidebar.bg,
                                        transition: 'transform 0.2s ease-in-out',
                                    }}
                                    onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.2)'}
                                    onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
                                />
                            </div>
                        </div>
                        )

                    ) : (
                        cardData?.stop_points?.map((stopPoint, index) => (
                            <div
                                key={index}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: ((cardData?.stop_points?.length) - 1) === index ? "10px" : "5px",
                                    width: "200px",
                                    minHeight: "40px",
                                }}
                            >
                                <Typography
                                    variant="caption"
                                    style={{
                                        color: "#172B4D",
                                        fontWeight: "500",
                                        fontFamily: "Poppins",
                                        fontSize: "14px",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                    }}
                                >
                                    {stopPoint.address}
                                </Typography>
                            </div>
                        ))
                    )}
                    {showAll && cardData?.stop_points?.map((stopPoint, index) => (
                        <div
                            key={index}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: ((cardData?.stop_points?.length) - 1) === index ? "10px" : "5px",
                                width: "200px",
                                minHeight: "40px",
                            }}
                        >
                            <Typography
                                variant="caption"
                                style={{
                                    color: "#172B4D",
                                    fontWeight: "500",
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                }}
                            >
                                {stopPoint.address}
                            </Typography>
                        </div>
                    ))}


                    <Box style={{ marginBottom: "8px" }}>
                        <Typography style={styles.addressText}>
                            {cardData?.delivery_address?.address}
                        </Typography>
                    </Box>
                </div>
            </div>
            <div style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                height: cardData?.stop_points?.length === 2 ? "188px" : ((cardData?.stop_points?.length === 1) || (cardData?.stop_points?.length > 2)) ? "138px" : "112px"
            }} >
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                    <Typography
                        onClick={handleRideDetailsClick}
                        sx={{
                            color: colorConfigs.sidebar.bg,
                            textDecoration: "underline",
                            fontSize: '17px',
                            cursor: 'pointer',
                            fontWeight: "600",
                            fontFamily: "Poppins",
                            transition: 'transform 0.2s ease-in-out',
                            '&:hover': {
                                transform: 'scale(1.1)',
                            }
                        }}
                    >
                        show Details
                    </Typography>
                </Box>
                <Box style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",

                }}>
                    {showAll && <ExpandLessIcon
                        onClick={(e) => { e.stopPropagation(); setShowAll(false) }}
                        sx={{
                            fontSize: '26px',
                            cursor: 'pointer',
                            color: colorConfigs.sidebar.bg,
                            marginRight: "35px",
                            transition: 'transform 0.2s ease-in-out',
                            '&:hover': {
                                transform: 'scale(1.2)',
                            }
                        }}
                    />}
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            background: '#F5F5F5',
                            borderRadius: '50px',
                        }}
                    >
                        <Avatar sx={{ height: '26px', width: '26px' }} src="path/to/avatar.jpg" />
                        <div style={styles.ratingText}>
                            <StarIcon style={{ color: "#FFD700", marginLeft: "4px" }} />
                            {cardData?.customer_details?.review_avg}
                        </div>
                    </Box>
                </Box>

                <Typography style={styles.timeText} align="right" sx={{ marginTop: '8px' }}>
                    {createdAt}
                </Typography>
            </div>
        </Card >
    );
};

export default RideCard;
