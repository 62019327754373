import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box, Container, FormControl, IconButton, InputAdornment, InputLabel,
  OutlinedInput
} from "@mui/material";
import { SubmitButton } from "@zetaton-dev/zetaton-components-bank";
import React, { useState } from "react";
import assets from "../../assets";
import colorConfigs from "../../configs/colorConfigs";

const SetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const handleSavePassword = () => {
    if (password !== "" && password === confirmPassword) {
      setPasswordsMatch(true);
    } else {
      setPasswordsMatch(false);
    }
  };

  return (
    <div>
      <div
        style={{
          background: `url(${assets.images.background})`,
          height: "50vh",
        }}
      ></div>
      <div
        style={{
          height: "50vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Container
          component="main"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "15px",
            boxShadow:
              "0px 7px 23px 0px rgba(0, 0, 0, 0.05),0px 7px 23px 0px rgba(0, 0, 0, 0.05)",
            backgroundColor: "white",
            marginTop: "-250px",
            padding: "14px 0",
            width: "400px",
          }}
        >
      
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              fontSize: "20px",
              fontStyle: "normal",
              gap: "8px",
              marginBottom: "14px",
            }}
          >
            <span
              style={{
                color: "#42526E",
                fontWeight: "700",
              }}
            >
              Set Password
            </span>
            <span
              style={{
                color: "#7A869A",
                fontWeight: "400",
                fontSize: "14px",
                letterSpacing: "-0.5px",
                fontFamily: "Poppins",
                textAlign: "center",
              }}
            >
              Please set A password to access the dashboard Account.
            </span>
          </div>

          <form
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <FormControl
              sx={{
                m: 1,
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  borderColor:colorConfigs?.sidebar?.bg, // Initial border color
                },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#BBC1CE", // Change this to the color you want
                  },
              }}
              variant="outlined"
            >
              <InputLabel
                style={{ color: "#BBC1CE" }}
                htmlFor="outlined-adornment-password"
              >
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={handlePasswordChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>

            <FormControl
              sx={{
                m: 1,
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  borderColor: colorConfigs?.sidebar?.bg, // Initial border color
                },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#BBC1CE", // Change this to the color you want
                  },
              }}
              variant="outlined"
            >
              <InputLabel
                style={{ color: "#BBC1CE" }}
                htmlFor="outlined-adornment-confirm-password"
              >
                Confirm Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-confirm-password"
                type={showConfirmPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle confirm password visibility"
                      onClick={handleToggleConfirmPasswordVisibility}
                      edge="end"
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Confirm Password"
              />
            </FormControl>
            {!passwordsMatch && (
              <div style={{ color: "red", marginTop: "10px" }}>
                Passwords do not match. Please try again.
              </div>
            )}
            <Box
              sx={{
                marginTop: "30px",
                mb: "3px",
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <SubmitButton
                variant="contained"
                style={{
                  width: "50%",
                  backgroundColor: colorConfigs?.sidebar?.bg,
                  cursor: "pointer",
                  color: "white",
                  fontWeight: "bold",
                  fontSize: "20px",
                }}
                onClick={handleSavePassword}
                label={"Save"}
                loading={loading}
              />
              <p style={{ color: "red" }}> {error}</p>
            </Box>
          </form>
        </Container>
      </div>
    </div>
  );
};

export default SetPassword;
