import { UserOutlined } from "@ant-design/icons";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import CommentIcon from "@mui/icons-material/Comment";
import {
  Alert,
  Box,
  Button,
  Card, CardContent,
  CardMedia,
  Container,
  Grid,
  IconButton,
  Paper,
  Snackbar,
  Typography,
  useMediaQuery,
  useTheme,
  TextField,
  DialogActions,

} from "@mui/material";
import { Modal } from "antd";

import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { SubmitButton } from "@zetaton-dev/zetaton-components-bank";
import { Avatar } from "antd";
import React, { useEffect, useState } from "react";
import Loading from "react-fullscreen-loading";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Calendar from "../../assets/images/Calendar.jpg";
import icon from "../../assets/images/Icon.png";
import emptyActive from "../../assets/images/emptyactive.jpg";
import emptyActiveWf from "../../assets/women first icons/Online taxi booking.png";
import fare from "../../assets/images/fare.jpg";
import location from "../../assets/images/location.png";
import BestRoute from "../../components/googleMaps/bestRoute";
import colorConfigs from "../../configs/colorConfigs";
import { storageSdk } from "../../configs/firebase";
import { chat, selectChat, setEnableEdit, setLastSupportChat, setOpenChatFromDriver, updateOutChat } from "../../redux/features/appStateSlice";
import { formatCurrency } from "../../utils/localization/currencyUtils";
import { formatDate } from "../../utils/localization/dateTimeUtils";
import { getMethod, putMethod } from "../Hook/UseCustomApi";
import RiderWallet from "../Riders/RiderWallet";
import TextRating from "../rating/rate";
import DriverInfo from "./DriverInfo";
import DriverReviews from "./DriverReviews";
import DriverViachle from "./DriverViachle";
import RidesHistory from "./RidesHistory";
import "./index.css";
import Error5xx from "../../components/common/HandelError5xx";
import BlueButton from "../../components/common/Buttons/BlueButton";
import { openModal } from "../../redux/features/modalSlice";
import DriverBalanceDialog from './DriverBalanceDialog';
import DriveFileRenameOutlineSharpIcon from "@mui/icons-material/DriveFileRenameOutlineSharp";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { TextInput } from "@zetaton-dev/zetaton-components-bank";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { putDataRedux } from "../../redux/features/appStateSlice";
import stopIcon from "../../assets/images/stop.png";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const DriversDetails = () => {
  const params = useParams();
  const driversList = useSelector((state) => state.appState.allDriversList);
  const [selectedObject, setSelectedObject] = useState(null);
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const [valueTabs, setValueTabs] = React.useState("one");
  const [enabled, setEnabled] = useState(selectedObject?.enabled);
  const [inputKey, setInputKey] = useState("");
  const [loading, setLoading] = useState("");
  const [url, setUrl] = useState("");
  const [updatedData, setUpdatedData] = useState(null);
  const [activeDelivery, setActiveDelivery] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isDriver, setIsDriver] = useState(true);
  const countryCode = useSelector((state) => state.country.selectedCountry);
  const enableEdit = useSelector((state) => state.appState.enableEdit);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [view, setView] = useState(true);
  const [lightboxIndex, setLightboxIndex] = useState(-1);
  const [errors, setErrors] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const stopPoints = activeDelivery?.stop_points || [];
  const [showAll, setShowAll] = useState(false);




  //handle error5xx
  const [tryAgain, setTryAgain] = useState();
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [errorCount, setErrorCount] = useState(0);
  const [errorDialogCounter, setErrorDialogCounter] = useState(0);
  const handleError5xx = () => {
    setIsErrorDialogOpen(true);
    setErrorCount(prevCount => {
      const newCount = prevCount + 1;
      if (newCount === 1) {
        setErrorDialogCounter(5);
      } else if (newCount === 2) {
        setErrorDialogCounter(10);
      }
      return newCount;
    });
  };
  const handleCloseErrorDialog = () => {
    setIsErrorDialogOpen(false);
    if (errorCount <= 2) {
      handleErrorCountByClose()
    }

  };
  const handleErrorCountByClose = () => {
    setErrorCount(prevCount => {
      const newCount = prevCount - 1;
      if (newCount === 1) {
        setErrorDialogCounter(5);
      } else if (newCount === 2) {
        setErrorDialogCounter(10);
      }
      return newCount;
    });
  }
  const licAndConfig = useSelector((state) => state.licencesAndConfiguration);
  // console.log(licAndConfig);


  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  useEffect(() => {
    setEnabled(selectedObject?.enabled);
  }, [selectedObject?.enabled]);

  // Function to handle updating rider data
  const updateRiderData = (newData) => {
    setUpdatedData(newData);
  };
  useEffect(() => {
    console.log("updatedData", updatedData?.email);
  }, [updatedData]);

  const transformPayType = (payType) => {
    return payType === "cash" ? "Cash" : "Credit Card";
  };

  const getDateForamted = (date) => {
    const dateObject = new Date(date);

    // Formatting options
    const options = {
      day: "numeric",
      month: "short",
      year: "numeric",
    };

    // Applying formatting and getting the formatted date string
    const formattedDate = dateObject.toLocaleDateString("en-US", options);
    return formattedDate;
  };
  const requirementsList = [
    // {
    //   key: "distance",
    //   icon: <img src={game} alt="loc" />,
    //   title: "Tips",
    //   caption: `${
    //     formatCurrency(activeDelivery?.pay_info?.tip
    //       ? activeDelivery?.pay_info?.tip
    //       : 0, countryCode)
    //   }`,
    // },
    {
      key: "date",
      icon: <img src={Calendar} alt="loc" />,
      title: "Date",
      caption: getDateForamted(formatDate(activeDelivery?.created_at, countryCode)),
    },

    // {
    //   key: "taxes",
    //   icon: <img src={walletMinus} alt="loc" />,
    //   title: "Taxes",
    //   caption: ` ${
    //    formatCurrency( activeDelivery?.pay_info?.tax ? activeDelivery?.pay_info?.tax : 0, countryCode)
    //   }`,
    // },
    {
      key: "fare",
      icon: <img src={fare} alt="loc" />,
      title: "Fare",
      caption: `${formatCurrency(activeDelivery?.pay_info?.total ? activeDelivery?.pay_info?.total : activeDelivery?.estimation_cost, countryCode)
        }`,
    },
    {
      key: "pay",
      icon: <img src={fare} alt="loc" />,
      title: "Paid with",
      // caption: activeDelivery?.pay_type,
      caption: transformPayType(activeDelivery?.pay_type),
    },
  ];

  let pickup = {
    lat: activeDelivery?.pickup_address?.lat,
    lng: activeDelivery?.pickup_address?.lng,
  };
  let dropoff = {
    lat: activeDelivery?.delivery_address?.lat,
    lng: activeDelivery?.delivery_address?.lng,
  };
  useEffect(() => {
    const foundObject = driversList?.find((obj) => obj._id === params["id"]);
    setSelectedObject(foundObject);
    window.scrollTo({ top: 0 });
  }, [params]);

  const handleChange = (e, newValue) => {
    setValueTabs(newValue);
    if (enableEdit === true && (newValue === "one" || newValue === "four")) {
      if (valueTabs === newValue) {

      } else {
        dispatch(setEnableEdit(false));

      }
    } else {
      dispatch(setEnableEdit(false));
    }
  };



  const handleImagrUrl = async (e, target) => {

    setLoading(true);
    let file = e.target?.files[0];
    if ((file && file?.length !== 0) || (file && file !== "" && typeof file !== "string")) {
      setLoading(true);
      const uploadTask = storageSdk.ref(`/drivers/${file.name}`).put(file);
      uploadTask.on(
        "state_changed",
        (snapShot) => {
          //takes a snap shot of the process as it is happening
        },
        (err) => {
          setLoading(false); // Set loading to false in case of an error
        },
        async () => {
          try {
            const fireBaseUrl = await storageSdk
              .ref("drivers")
              .child(file.name)
              .getDownloadURL();

            let dataToUpdate = {}
            if (target === "profile_picture") {
              dataToUpdate = {
                profile_picture: fireBaseUrl,
              };
            } else if (target === "driver_license_front") {
              dataToUpdate = {
                driver_license_front: fireBaseUrl,
              };
            } else if (target === "driver_license_back") {
              dataToUpdate = {
                driver_license_back: fireBaseUrl,
              };
            } else if (target === "vehicle_images") {
              dataToUpdate = {
                vehicle_info: {
                  ...selectedObject.vehicle_info,
                  vehicle_images: [...selectedObject.vehicle_info.vehicle_images, fireBaseUrl],
                },
              };
            } else {
              dataToUpdate = {
                documents: {
                  ...selectedObject.documents,
                  [target]: fireBaseUrl
                },
              };
            }
            try {
              setLoading(true);
              putMethod({
                url: `driver/byAdmin/${selectedObject._id}`,
                body: dataToUpdate,
                handleErrorMessage: showSnackbar,
              })
                .then((response) => {
                  setSelectedObject(response.data)
                  dispatch(putDataRedux({ key: "_id", value: selectedObject?._id, stateName: "allDriversList", data: response?.data }))
                })
            } catch (error) {
              console.error("Update API error:", error.message);
            } finally {
              setLoading(false);
            }
            setUrl(fireBaseUrl);
          } catch (error) {
            console.error("Download URL error:", error.message);
            setLoading(false);
          } finally {
            setLoading(false);
          }
        }
      );
    } else {
      setUrl(file);
      setLoading(false);
    }
    setInputKey(Math.random());
  };

  const getParams = () => {
    return {
    };
  };


  const fetchActiveDelivery = () => {
    if (selectedObject && selectedObject?.active_ride_id) {
      getMethod({
        url: `ride/${selectedObject.active_ride_id}`,
        params: getParams(),
        handleErrorMessage: showSnackbar,
      })
        .then((response) => {
          console.log(response);
          setActiveDelivery(response?.data);
          console.log(response?.data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          if (error === "Internal Server Error: Server error occurred.") {
            handleError5xx();
            setTryAgain(() => fetchActiveDelivery);
          }
        });
    }
  };
  useEffect(() => {
    if (selectedObject?.active_ride_id !== "") {
      fetchActiveDelivery();
    }
  }, [selectedObject?.active_ride_id]);

  const capitalizeFirstLetter = (str) => {
    return str?.charAt(0)?.toUpperCase() + str?.slice(1);
  };


  const determineFileType = (url) => {
    const extension = url.split('.').pop().split('?')[0];
    if (['jpg', 'jpeg', 'png', 'gif'].includes(extension.toLowerCase())) {
      return 'Image';
    } else if (extension.toLowerCase() === 'pdf') {
      return 'PDF';
    } else if (['xls', 'xlsx'].includes(extension.toLowerCase())) {
      return 'Excel';
    } else {
      return 'Unknown';
    }
  };

  const [imageFiles, setImageFiles] = useState([]);
  const [otherFiles, setOtherFiles] = useState([]);

  useEffect(() => {
    if (selectedObject && selectedObject.documents) {
      const imageResults = [];
      const otherResults = [];

      Object.entries(selectedObject.documents).forEach(([key, url]) => {
        const fileType = determineFileType(url);
        if (fileType === 'Image') {
          imageResults.push({ key, url, fileType });
        } else {
          otherResults.push({ key, url, fileType });
        }
      });

      setImageFiles(imageResults);
      setOtherFiles(otherResults);
    }
  }, [selectedObject]);

  const renderFiles = (files) => {
    return files.map(({ key, url, fileType }) => (
      <Grid item xs={12} sm={6} md={4} key={key} >
        <Typography style={{
          marginBottom: '10px',
          fontFamily: "poppins",
          fontWeight: "600",
          size: "16px",
          lineHeight: '16px',
          color: '#172B4D'
        }}>{capitalizeFirstLetter(key)}</Typography>
        <Card style={{ borderRadius: "8px", border: `1px solid ${colorConfigs.sidebar.bg}` }}>
          <CardContent>
            {fileType === 'Image' ? (
              <CardMedia component="img" image={url} alt={key} style={{ maxWidth: '100%', height: 'auto' }} />
            ) : (
              <a href={url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                <div style={{
                  display: 'flex', alignItems: 'center', justifyContent: 'center', height: "20px",
                }}>
                  < img
                    // src={fileType === 'PDF' ? "https://cdn.builder.io/api/v1/image/assets/TEMP/8fc56007e5f105368c7fab63a4262b0902902878ea5f93087925200ae34d4a4f?" : FileOpenIcon }
                    src={"https://cdn.builder.io/api/v1/image/assets/TEMP/8fc56007e5f105368c7fab63a4262b0902902878ea5f93087925200ae34d4a4f?"}
                    alt={`${fileType} Icon`}
                    style={{ width: 35, height: 35 }}
                  />
                  <Typography style={{
                    fontFamily: "poppins",
                    fontWeight: "600",
                    size: "14px",
                    lineHeight: '21px',
                    color: colorConfigs.sidebar.bg
                  }}  >{key}</Typography>
                  <Typography style={{
                    fontFamily: "poppins",
                    fontWeight: "600",
                    size: "14px",
                    lineHeight: '21px',
                    color: colorConfigs.sidebar.bg
                  }} >{`.${fileType}`}</Typography>
                </div>
              </a>
            )}
          </CardContent>
        </Card>
      </Grid >
    ));
  };

  const handleCancel = () => {
    setView(true);
    setErrors({});
  }
  const handleOpenLightbox = (index) => {
    setLightboxIndex(index);
  };

  const images = [
    selectedObject?.profile_picture,
    selectedObject?.driver_license_front,
    selectedObject?.driver_license_back,
  ];

  const handleCloseLightbox = () => {
    setLightboxIndex(-1);
  };

  const handleMovePrev = () => {
    setLightboxIndex((lightboxIndex + images?.length - 1) % images?.length);
  };

  const handleMoveNext = () => {
    setLightboxIndex((lightboxIndex + 1) % images?.length);
  };
  const handleCancelSave = (event, reason) => {
    setIsModalVisible(false);
  };
  const fieldStyle = {
    "& label.Mui-focused": {
      color: "#172B4D !important",
      fontSize: "17px !important",
      fontWeight: "500 !important",
    },
    "& .MuiOutlinedInput-root": {

      "& fieldset": {
        color: "#172B4D !important",
        borderColor: "#BBC1CE",
        borderWidth: 1,
        borderRadius: "8px",
      },
      "&:hover fieldset": {
        color: "#172B4D !important",
        borderColor: "#BBC1CE",
        borderWidth: 1,
        borderRadius: "8px",
      },
      "&.Mui-focused fieldset": {
        color: "#172B4D !important",
        borderColor: "#BBC1CE",
        borderWidth: 1,
        borderRadius: "8px",
      },
      input: {
        "&::placeholder": {
          color: "#172B4D !important",
          opacity: 1,
          fontSize: 14,
        },
      },
    },
  };
  const handleChangeData = (e) => {
    const { name, value } = e.target;
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));

    console.log(name);
    if (name === "license_number" || name === "class") {
      setSelectedObject({
        ...selectedObject,
        driver_license_info: {
          ...selectedObject.driver_license_info,
          [name]: value
        }
      });
    } else if (name === "vehicle_color" || name === "vehicle_make" || name === "vehicle_model" || name === "vehicle_year" || name === "license_plate") {
      setSelectedObject({
        ...selectedObject,
        vehicle_info: {
          ...selectedObject.vehicle_info,
          [name]: value
        }
      });
    } else {
      setSelectedObject({
        ...selectedObject,
        [name]: value,
      });

    }
  };

  const handleChangeDate = (e, name) => {
    const timestamp = e.getTime();

    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));

    if (name === "exp" || name === "iss") {
      setSelectedObject({
        ...selectedObject,
        driver_license_info: {
          ...selectedObject.driver_license_info,
          [name]: timestamp
        }
      });
    } else {
      setSelectedObject({
        ...selectedObject,
        dateOfBirth: timestamp,
      });
    }
  };

  const showModal = () => {


    const newErrors = {};

    if (!selectedObject?.first_name || selectedObject?.first_name === "") {
      newErrors.first_name = "First Name is Required.";
    }
    if (!selectedObject?.last_name || selectedObject?.last_name === "") {
      newErrors.last_name = "Last Name is Required.";
    }
    if (!selectedObject?.dateOfBirth || selectedObject?.dateOfBirth === "") {
      newErrors.dateOfBirth = "Date of Birth is Required.";
    }
    if (!selectedObject?.driver_license_info?.license_number || selectedObject?.driver_license_info?.license_number === "") {
      newErrors.license_number = "license Number is Required.";
    } else if (selectedObject?.driver_license_info?.license_number?.length > 15) {
      newErrors.license_number = "license Number Should be Less Than 15.";
    }
    if (!selectedObject?.driver_license_info?.iss || selectedObject?.driver_license_info?.iss === "") {
      newErrors.iss = "Iss Date is Required.";
    }
    if (!selectedObject?.driver_license_info?.exp || selectedObject?.driver_license_info?.exp === "") {
      newErrors.exp = "Exp Date is Required.";
    } else if (parseInt(selectedObject?.driver_license_info?.exp) <= parseInt(selectedObject?.driver_license_info?.iss)) {
      newErrors.exp = "Expiration date must be later than issuance date.";
    }
    if (!selectedObject?.vehicle_info?.vehicle_images || selectedObject?.vehicle_info?.vehicle_images?.length === 0) {
      newErrors.vehicle_images = "Uploade One Vehicle Image at Least.";
    }
    if (!selectedObject?.vehicle_info?.license_plate || selectedObject?.vehicle_info?.license_plate === "") {
      newErrors.license_plate = "Registration Number is Required.";
    }
    if (!selectedObject?.vehicle_info?.vehicle_color || selectedObject?.vehicle_info?.vehicle_color === "") {
      newErrors.vehicle_color = "Vehicle Color is Required.";
    }
    if (!selectedObject?.vehicle_info?.vehicle_make || selectedObject?.vehicle_info?.vehicle_make === "") {
      newErrors.vehicle_make = "Vehicle Make is Required.";
    }
    if (!selectedObject?.vehicle_info?.vehicle_model || selectedObject?.vehicle_info?.vehicle_model === "") {
      newErrors.vehicle_model = "Vehicle Model is Required.";
    }
    if (!selectedObject?.car_type || selectedObject?.car_type?.length === 0) {
      newErrors.car_type = "Ride Type is Required.";
    }
    if (!selectedObject?.vehicle_info?.vehicle_year || selectedObject?.vehicle_info?.vehicle_year === "") {
      newErrors.vehicle_year = "Vehicle Year is Required.";
    }
    if (!selectedObject?.driver_license_info?.class || selectedObject?.driver_license_info?.exp === "") {
      newErrors.class = "Vehicle Class is Required.";
    }
    if (!selectedObject?.documents || Object.keys(selectedObject?.documents)?.length === 0) {
      newErrors.docs = "Uploade One Document at Least.";
    } if (!selectedObject?.profile_picture || selectedObject?.profile_picture === "") {
      newErrors.profile_picture = "Profile Picture is Required.";
    } if (!selectedObject?.driver_license_back || selectedObject?.driver_license_back === "") {
      newErrors.driver_license_back = "Driver license Back is Required.";
    } if (!selectedObject?.driver_license_front || selectedObject?.driver_license_front === "") {
      newErrors.driver_license_front = "Driver license Front is Required.";
    }


    setErrors(newErrors);



    if (Object.keys(newErrors)?.length > 0) {
      return;
    } else {
      setIsModalVisible(true);
    }
  };

  const handleSave = () => {

    console.log(selectedObject);

    setLoading(true);
    putMethod({
      url: `driver/byAdmin/${selectedObject._id}`,
      body: selectedObject,
      handleErrorMessage: showSnackbar,
    })
      .then((r) => {
        console.log(r);
        setSelectedObject(r.data)
        dispatch(putDataRedux({ key: "_id", value: selectedObject._id, stateName: "driversReq", data: r.data }))

      })
      .catch((error) => {
        if (error === "Internal Server Error: Server error occurred.") {
          handleError5xx();
          setTryAgain(() => handleSave());
        }
        console.error("Update API error:", error.message);
      })
      .finally(() => {
        setLoading(false);
      });
    setIsModalVisible(false);
    handleCancel()
  }

  const wayPoints = activeDelivery && activeDelivery?.stop_points?.map(point => ({
    location: { lat: point.lat, lng: point.lng },
    stopover: true
  }));

  console.log(activeDelivery);


  return (
    <>
      <Modal
        title="Confirm Changes"
        visible={isModalVisible}
        onOk={handleSave}
        onCancel={handleCancelSave}
        okText="Yes"
        okButtonProps={{
          style: {
            backgroundColor: colorConfigs?.sidebar?.bg,
            color: "white",
          },
        }}
      >
        <p>Are you sure you want to save the changes?</p>
      </Modal>
      {/* handleError5xx */}
      <Box sx={{ position: 'relative' }}>
        {valueTabs === "six" && (<Box sx={{ position: 'absolute', top: 3, right: 3 }}>
          <BlueButton sx={{ px: 3 }} onClick={() => dispatch(openModal(<DriverBalanceDialog />))}>
            + Add Transaction
          </BlueButton>
        </Box>)}
        <Error5xx open={isErrorDialogOpen} errCount={errorCount} count={errorDialogCounter} handleClose={handleCloseErrorDialog} tryAgain={tryAgain} />
        <Box
          sx={{
            backgroundColor: "background.default",
            minHeight: "85vh",
            py: 3,
          }}
        >
          {!smDown ? (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div>
                <Box>
                  {/* Profile picture with camera and delete icons */}
                  <div style={{ position: "relative" }}>
                    {selectedObject?.profile_picture || url ? (
                      <img
                        src={selectedObject?.profile_picture}
                        alt="Profile"
                        loading="lazy"
                        style={{
                          width: "150px",
                          height: "135px",
                          border: "2px solid #EEEEEE7A",
                          borderRadius: 15,
                        }}
                      />
                    ) : (
                      <Paper style={{ width: "120px", height: "102px" }}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: "center",
                            height: "100%",
                          }}
                        >
                          {" "}
                          <Avatar
                            style={{ height: "66px", width: "66px" }}
                            icon={<UserOutlined />}
                          />
                        </div>{" "}
                      </Paper>
                    )}
                  </div>
                </Box>
                <Loading
                  loading={!!loading}
                  background="rgba(86, 100, 210, 0.1)"
                  loaderColor={colorConfigs.mainColor}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  paddingLeft: 20,
                  width: "100%",
                }}
              >
                <div
                  style={{
                    paddingLeft: 7,
                  }}
                >
                  {/* <Typography style={styles.title}>
                  {selectedObject?.first_name}{" "}
                  {selectedObject?.last_name &&
                    selectedObject?.last_name.charAt(0).toUpperCase() + "."}
                </Typography> */}
                  <Typography style={styles.title}>
                    {updatedData &&
                      updatedData?.first_name &&
                      updatedData?.last_name && (
                        <>
                          {updatedData?.first_name}{" "}
                          {updatedData?.last_name.charAt(0).toUpperCase() + "."}
                        </>
                      )}
                    {!updatedData ||
                      !updatedData.first_name ||
                      !updatedData.last_name ? (
                      <>
                        {selectedObject?.first_name}{" "}
                        {selectedObject?.last_name &&
                          selectedObject?.last_name.charAt(0).toUpperCase() + "."}
                      </>
                    ) : null}
                    {/* {selectedObject?.first_name}{" "}
                  {selectedObject?.last_name &&
                    selectedObject?.last_name.charAt(0).toUpperCase() + "."} */}
                  </Typography>
                  <div style={{ width: "10px" }} />
                  {/* <TextRating value={+selectedObject?.avgRating} /> */}
                  <TextRating
                    value={
                      selectedObject?.review_info?.total_avg
                        ? selectedObject?.review_info?.total_avg
                        : 0
                    }
                  />

                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: colorConfigs.sidebar.bg,
                      color: "white",
                      fontSize: "16px",
                      lineHight: "20.16px",
                      fontWeight: "600",
                      margin: "15px 0",
                      borderRadius: "20px",
                    }}
                    onClick={() => {
                      navigate(`/support`);
                      dispatch(
                        chat({
                          user_name:
                            selectedObject?.first_name +
                            " " +
                            selectedObject?.last_name,
                          user_phoneNumber: selectedObject?.phone_number,
                          user_profile: selectedObject?.profile_picture,
                          type: 'driver'
                        })
                      );
                      dispatch(selectChat({
                        ...selectedObject, driver: `admin_${selectedObject?.fb_uid}`, type: "driver", user_name:
                          selectedObject?.first_name +
                          " " +
                          selectedObject?.last_name,
                        user_phoneNumber: selectedObject?.phone_number,
                        user_profile: selectedObject?.profile_picture,
                      }));
                      dispatch(setLastSupportChat({
                        ...selectedObject, driver: `admin_${selectedObject?.fb_uid}`, type: "driver", user_name:
                          selectedObject?.first_name +
                          " " +
                          selectedObject?.last_name,
                        user_phoneNumber: selectedObject?.phone_number,
                        user_profile: selectedObject?.profile_picture,
                      }));
                      dispatch(updateOutChat(false));
                      dispatch(setOpenChatFromDriver(true));
                    }}
                  >
                    {" "}
                    <CommentIcon
                      style={{ width: "15px", marginRight: "10px" }}
                    />{" "}
                    Chat
                  </Button>
                </div>
              </div>

              {enableEdit && (
                <Container style={{ justifyContent: "end" }}>
                  <Box display="flex" style={{ justifyContent: "end" }}>
                    <SubmitButton
                      variant="outlined"
                      onClick={() => {
                        setEnabled(true);
                      }}
                      style={{
                        borderTopLeftRadius: "88px",
                        borderBottomLeftRadius: "88px",
                        borderRight: "1px solid black",
                        borderColor: colorConfigs?.sidebar?.bg,
                        color: enabled ? "white" : colorConfigs?.sidebar?.bg,
                        backgroundColor: enabled
                          ? colorConfigs?.sidebar?.bg
                          : "white",
                      }}
                      label={"Enable"}
                    />
                    <SubmitButton
                      variant="outlined"
                      onClick={() => {
                        setEnabled(false);
                      }}
                      style={{
                        borderTopRightRadius: "88px",
                        borderBottomRightRadius: "88px",
                        borderColor: colorConfigs?.sidebar?.bg,
                        color: !enabled ? "white" : "#42526E",
                        backgroundColor: !enabled
                          ? colorConfigs?.sidebar?.bg
                          : "white",
                      }}
                      label={"Disable"}
                    />
                  </Box>
                </Container>
              )}
            </div>
          ) : (
            <Grid container>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div>
                  {selectedObject?.profile_picture ? (
                    <img src={selectedObject.profile_picture} alt="Profile" />
                  ) : (
                    <Avatar icon={<UserOutlined />} />
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    paddingLeft: 10,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      paddingLeft: 10,
                    }}
                  >
                    <Typography style={styles.title}>
                      {selectedObject?.full_name}
                    </Typography>
                    <div style={{ width: "10px" }} />
                  </div>
                </div>
              </div>
            </Grid>
          )}
          <div>
            <Box sx={{ width: "100%", background: "#E8E8E8" }}>
              <Tabs
                value={valueTabs}
                onChange={handleChange}
                textColor="inherit"
                sx={{ color: colorConfigs.sidebar.bg }}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: colorConfigs.sidebar.bg,
                  },
                }}
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                variant="scrollable"
              >
                <Tab
                  value="one"
                  label="Driver Details"
                  style={{ width: "182px" }}
                />
                <Tab value="two" label="Active Ride" style={{ width: "182px" }} />
                <Tab value="three" label="Rides" style={{ width: "182px" }} />
                <Tab
                  value="four"
                  label="Vehicle Details"
                  style={{ width: "182px" }}
                />
                <Tab
                  value="eight"
                  label="Driver Documents"
                  style={{ width: "182px" }}
                />
                <Tab value="five" label="Reviews" style={{ width: "182px" }} />
                <Tab value="six" label="Wallet" style={{ width: "182px" }} />
                {licAndConfig?.licenses?.driver_additional_doc && (<Tab value="seven" label="Additional Requirements" style={{ width: "230px" }} />)}
              </Tabs>
            </Box>

            {valueTabs === "eight" && (
              <Box component="div" sx={{ padding: "28px" }}>
                <Box
                  display="flex"
                  style={{ cursor: "pointer", marginBottom: '15px' }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setView(false);

                    }}
                    style={{
                      borderTopLeftRadius: "88px",
                      borderBottomLeftRadius: "88px",
                      borderColor: colorConfigs?.sidebar?.bg,
                      color: !view ? "white" : "#42526E",
                      backgroundColor: !view ? colorConfigs?.sidebar?.bg : "white",
                      cursor: "pointer",
                    }}
                  >
                    <DriveFileRenameOutlineSharpIcon fontSize="small" /> Edit{" "}
                  </Button>

                  <Button
                    variant="outlined"
                    onClick={() => {
                      handleCancel();
                    }}
                    style={{
                      borderTopRightRadius: "88px",
                      borderBottomRightRadius: "88px",
                      borderRight: "1px solid black",
                      borderColor: colorConfigs?.sidebar?.bg,
                      color: view ? "white" : colorConfigs?.sidebar?.bg,
                      backgroundColor: view ? colorConfigs?.sidebar?.bg : "white",
                      cursor: "pointer",
                    }}
                  >
                    {" "}
                    <VisibilityOutlinedIcon fontSize="small" /> View
                  </Button>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    gap: "14px",
                    height: "250px",
                    justifyContent: "flex-start",
                    marginBottom: "25px",
                    paddingLeft: "3px",
                    paddingRight: '55px',
                    overflowX: "auto",
                    paddingBottom: "5px"
                  }}
                >
                  <style>
                    {`
                       ::-webkit-scrollbar {
                       height: 10px; 
                        }
                       `}
                  </style>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                      width: "400px",
                      height: "200px",
                    }}
                  >
                    <span style={styles.imageTitle}>Profile Photo</span>
                    <div style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                      width: "200px",
                      height: "200px",
                    }} >
                      <img
                        style={{ width: '100%', height: '100%', cursor: 'pointer', objectFit: 'fill' }}
                        src={selectedObject?.profile_picture}
                        alt=""
                        key={inputKey}
                        onClick={() => handleOpenLightbox(0)}
                      />
                      {!view && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-end",
                            marginTop: "-32px",
                            marginRight: "3px"
                          }}
                        >
                          <form onSubmit={() => { }} className="form">
                            <IconButton
                              component="label"
                              style={{
                                borderRadius: "10%",
                                width: "26px",
                                height: "26px",
                              }}
                            >
                              <AddAPhotoIcon style={{ color: colorConfigs.sidebar.bg }} />
                              <input
                                type="file"
                                hidden
                                style={{ display: "none" }}
                                multiple
                                size="20971520"
                                accept="image/png, image/jpeg"
                                onChange={(e) => handleImagrUrl(e, "profile_picture")}
                                onClick={(e) => (e.target.value = null)}
                              />
                            </IconButton>
                          </form>
                        </div>
                      )}
                    </div>

                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                      width: "400px",
                      height: "200px",
                    }}
                  >
                    <span style={styles.imageTitle}>Driver license - Front</span>
                    <div style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                      width: "350px",
                      height: "200px",
                    }}>
                      <img
                        style={{ width: '100%', height: '100%', objectFit: 'fill', cursor: 'pointer' }}
                        src={selectedObject?.driver_license_front}
                        alt=""
                        onClick={() => handleOpenLightbox(1)}
                      />
                      {!view && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-end",
                            marginTop: "-32px",
                            marginRight: "3px"
                          }}
                        >
                          <form onSubmit={() => { }} className="form">
                            <IconButton
                              component="label"
                              style={{
                                borderRadius: "10%",
                                width: "26px",
                                height: "26px",
                              }}
                            >
                              <AddAPhotoIcon style={{ color: colorConfigs.sidebar.bg }} />
                              <input
                                type="file"
                                hidden
                                style={{ display: "none" }}
                                multiple
                                size="20971520"
                                accept="image/png, image/jpeg"
                                key={inputKey}
                                onChange={(e) => handleImagrUrl(e, "driver_license_front")}
                                onClick={(e) => (e.target.value = null)}
                              />
                            </IconButton>
                          </form>
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                      width: "400px",
                      height: "200px",
                      marginLeft: "25px"
                    }}
                  >
                    <span style={styles.imageTitle}>Driver license - Back</span>
                    <div style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                      width: "350px",
                      height: "200px",
                    }}>
                      <img
                        style={{ width: '100%', height: '100%', objectFit: 'fill', cursor: 'pointer' }}
                        src={selectedObject?.driver_license_back}
                        alt=""
                        onClick={() => handleOpenLightbox(2)}
                      />
                      {!view && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-end",
                            marginTop: "-32px",
                            marginRight: "3px"
                          }}
                        >
                          <form onSubmit={() => { }} className="form">
                            <IconButton
                              component="label"
                              style={{
                                borderRadius: "10%",
                                width: "26px",
                                height: "26px",
                              }}
                            >
                              <AddAPhotoIcon style={{ color: colorConfigs.sidebar.bg }} />
                              <input
                                type="file"
                                hidden
                                style={{ display: "none" }}
                                multiple
                                size="20971520"
                                accept="image/png, image/jpeg"
                                key={inputKey}
                                onChange={(e) => handleImagrUrl(e, "driver_license_back")}
                                onClick={(e) => (e.target.value = null)}
                              />
                            </IconButton>
                          </form>
                        </div>
                      )}
                    </div>
                    {lightboxIndex >= 0 && (
                      <Lightbox
                        mainSrc={images[lightboxIndex]}
                        nextSrc={images[(lightboxIndex + 1) % images?.length]}
                        prevSrc={images[(lightboxIndex + images?.length - 1) % images?.length]}
                        onCloseRequest={handleCloseLightbox}
                        onMovePrevRequest={handleMovePrev}
                        onMoveNextRequest={handleMoveNext}
                        reactModalStyle={{
                          overlay: {
                            zIndex: 1000,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '80%',
                            height: '90%',
                            // marginRight: "19%",
                            marginLeft: "19%",
                            marginTop: "35px"
                          },
                          content: {
                            display: 'flex',
                            width: '100%',
                            height: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                          },
                        }}
                      />
                    )}
                  </div>
                </Box>

                <Grid container spacing={3}>
                  <Grid item lg={6} sm={12}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <TextInput
                      disabled={view}
                      name="license_number"
                      style={{ width: "90%", }}
                      label="ID (License Number)"
                      styles={fieldStyle}
                      value={selectedObject?.driver_license_info?.license_number}
                      onChange={(e) => { handleChangeData(e) }}
                      error={Boolean(errors.license_number)}
                    />
                    {errors.license_number && (
                      <Typography variant="caption" style={{ color: "red", marginBottom: "10px", marginTop: "5px" }}>
                        {errors.license_number}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item lg={6} sm={12} >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        name="iss"
                        label="Iss Date" disabled={view}
                        value={selectedObject?.driver_license_info?.iss ? new Date(selectedObject?.driver_license_info?.iss) : null}
                        sx={{ ...fieldStyle, width: "90% !important" }}
                        onChange={(e) => { handleChangeDate(e, "iss") }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{ ...fieldStyle, width: "90% !important" }}
                            label="Iss Date"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              readOnly: view,
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item lg={6} sm={12} >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        name="exp"
                        label="Exp Date" disabled={view}
                        value={selectedObject?.driver_license_info?.exp ? new Date(selectedObject?.driver_license_info?.exp) : null}
                        sx={{ ...fieldStyle, width: "90% !important" }}
                        onChange={(e) => { handleChangeDate(e, "exp") }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{ ...fieldStyle, width: "90% !important" }}
                            label="Exp Date"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              readOnly: view,
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                    {errors.exp && (
                      <Typography variant="caption" style={{ color: "red", marginBottom: "10px", marginTop: "5px" }}>
                        {errors.exp}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item lg={6} sm={12}>
                    <TextInput
                      onChange={(e) => { handleChangeData(e) }}
                      disabled={view}
                      name="class"
                      style={{ width: "90%" }}
                      label="Class"
                      value={selectedObject?.driver_license_info?.class}

                      styles={fieldStyle}
                    />
                  </Grid>
                </Grid>
                {!view && (
                  <DialogActions
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      width: "100%",
                      gap: "14px",
                      marginTop: "28px",
                      padding: "8px",
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        textTransform: 'none',
                        backgroundColor: process.env.REACT_APP_ENV === 'women_first' ? colorConfigs?.sidebar?.bg : '#005e8b',
                        color: 'white',
                        borderRadius: '10px',
                        width: "150px",
                        padding: '10px 20px',
                        '&:hover': {
                          backgroundColor: process.env.REACT_APP_ENV === 'women_first' ? colorConfigs?.sidebar?.bg : '#004b6e',
                        },
                      }}
                      onClick={showModal}
                    >
                      Save
                    </Button>
                  </DialogActions>
                )}
              </Box>
            )}
            {valueTabs === "seven" && (
              <Grid container spacing={2} sx={{ marginTop: '25px' }}>
                {imageFiles?.length > 0 && (
                  <Grid container item spacing={2}>
                    {renderFiles(imageFiles)}
                  </Grid>
                )}
                {otherFiles?.length > 0 && (
                  <Grid container item spacing={2}>
                    {renderFiles(otherFiles)}
                  </Grid>
                )}
                {imageFiles?.length === 0 && otherFiles?.length === 0 && (
                  <Grid item xs={12}>
                    <Typography
                      style={{
                        marginBottom: '10px',
                        fontFamily: "Poppins",
                        fontWeight: "600",
                        fontSize: "16px",
                        lineHeight: '16px',
                        color: '#172B4D'
                      }}
                    >
                      No Additional Requirements For This Driver.
                    </Typography>
                  </Grid>
                )}
              </Grid>
            )}
            {valueTabs === "six" && (
              <Box
                sx={{
                  width: "100%",
                  marginTop: !smDown ? "30px" : "10px",
                  "& .super-app-theme--header": {
                    backgroundColor: "rgb(220,220,220)",
                  },
                }}
              >
                <RiderWallet driverId={params["id"]} isDriver={isDriver} />
              </Box>
            )}
            {valueTabs === "one" && (
              <>
                <Box sx={{ width: "100%", marginTop: "10px" }}>
                  <DriverInfo
                    selectedObject={selectedObject}
                    enabled={enabled}
                    params={params["id"]}
                    handleUpdate={updateRiderData}
                  />
                </Box>
              </>
            )}
            {valueTabs === "two" && (
              <>
                {selectedObject?.active_ride_id !== "" ? (
                  <>
                    {" "}
                    <div style={{ width: "100%" }}>
                      <Grid
                        container
                        spacing={3}
                        style={{
                          paddingTop: "30px",
                          width: "100%",
                          justifyContent: "center",
                        }}
                      >
                        {requirementsList?.map((requirement) => (
                          <Grid key={requirement.key} item lg={2} sm={3}>
                            <div
                              style={{
                                border: `0.5px solid lightgray`,
                                borderRadius: "10px",
                                textAlign: "center",
                                padding: "16px 0",
                                boxSizing: "border-box",
                                transition: "0.3s",

                                "&:hover": {
                                  boxShadow: "0 4px 6px 0 rgba(0,0,0,0.02)",
                                },
                              }}
                            >
                              {requirement.icon}
                              <Typography
                                variant={"body2"}
                                style={{ marginTop: "8px", color: "#7A869A" }}
                              >
                                {requirement.title}
                              </Typography>
                              <Typography
                                variant={"caption"}
                                style={{ color: "#495974", fontWeight: "bold" }}
                              >
                                {requirement.caption}
                              </Typography>
                            </div>
                          </Grid>
                        ))}
                      </Grid>
                    </div>
                    <div style={{ position: "relative" }}>
                      <div
                        style={{
                          alignItems: "center",
                          display: "flex",
                          padding: "5px",
                        }}
                      >
                        <img src={location} alt="loc" />
                        <Typography
                          variant="body2"
                          style={{
                            color: colorConfigs.sidebar.bg,
                            opacity: "0.8",
                            height: "85%",
                            margin: "16px 0",
                            fontWeight: "bold",
                            marginLeft: "10px", // Add left margin for spacing
                          }}
                        >
                          Pick Up:
                          <br />
                          <Typography
                            key={activeDelivery?._id}
                            variant="caption"
                            style={{
                              color: "black",
                              opacity: "0.8",
                              height: "85%",
                              marginTop: "3px",
                            }}
                          >
                            {activeDelivery?.pickup_address?.address}
                          </Typography>
                        </Typography>
                      </div>

                      {/* Connecting line */}
                      {/* <div
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "2.5%",
                          transform: "translate(-50%, -45%)",
                          borderLeft: "1px solid gray", // Adjust the line style
                          height: "28px", // Adjust the height of the line
                        }}
                      ></div> */}

                      <div>
                        {stopPoints && stopPoints?.length > 2 ? (
                          <div
                            style={{
                              display: "flex",
                              padding: "5px",
                              alignItems: "center",
                              justifyContent: "space-between",
                              margin: !showAll ? "20px 0" : "20px 0 0 0",
                            }}
                          >
                            <div style={{ display: "flex", alignItems: "center" }}>
                              <img src={stopIcon} alt="loc" style={{ height: "38px", width: "38px" }} />
                              <Typography
                                variant="body2"
                                style={{
                                  color: colorConfigs.sidebar.bg,
                                  opacity: "0.8",
                                  marginTop: "3px",
                                  fontWeight: "bold",
                                  marginLeft: "10px",
                                }}
                              >
                                Stop points : {stopPoints?.length}
                              </Typography>
                            </div>
                            <div>
                              {!showAll ? (
                                <ExpandMoreIcon onClick={() => setShowAll(true)} style={{ fontSize: '30px', cursor: 'pointer', color: colorConfigs.sidebar.bg }} />
                              ) : (
                                <ExpandLessIcon onClick={() => setShowAll(false)} style={{ fontSize: '30px', cursor: 'pointer', color: colorConfigs.sidebar.bg }} />
                              )}
                            </div>
                          </div>
                        ) : (
                          stopPoints && stopPoints?.map((stopPoint, index) => (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                padding: "5px",
                                alignItems: "center",
                              }}
                            >
                              <img src={stopIcon} alt="loc" style={{ height: "38px", width: "38px" }} />
                              <Typography
                                variant="body2"
                                style={{
                                  color: colorConfigs.sidebar.bg,
                                  opacity: "0.8",
                                  marginTop: "3px",
                                  fontWeight: "bold",
                                  marginLeft: "10px",
                                }}
                              >
                                Stop point: {index + 1}
                                <br />
                                <Typography
                                  variant="caption"
                                  style={{
                                    color: "black",
                                    opacity: "0.8",
                                    marginTop: "3px",
                                  }}
                                >
                                  {stopPoint.address}
                                </Typography>
                              </Typography>
                            </div>
                          ))
                        )}
                        {showAll && stopPoints && stopPoints.length > 2 && stopPoints?.map((stopPoint, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              padding: "10px",
                              alignItems: "center",
                              marginLeft: "60px",
                            }}
                          >
                            <img src={stopIcon} alt="loc" style={{ height: "38px", width: "38px" }} />
                            <Typography
                              variant="body2"
                              style={{
                                color: colorConfigs.sidebar.bg,
                                opacity: "0.8",
                                marginTop: "3px",
                                fontWeight: "bold",
                                marginLeft: "10px",
                              }}
                            >
                              Stop point: {index + 1}
                              <br />
                              <Typography
                                variant="caption"
                                style={{
                                  color: "black",
                                  opacity: "0.8",
                                  marginTop: "3px",
                                }}
                              >
                                {stopPoint?.address}
                              </Typography>
                            </Typography>
                          </div>
                        ))}
                      </div>

                      <div
                        style={{
                          alignItems: "center",
                          display: "flex",
                          padding: "5px",
                        }}
                      >
                        <img src={icon} alt="loc" />
                        <Typography
                          variant="body2"
                          style={{
                            color: colorConfigs.sidebar.bg,
                            opacity: "0.8",
                            margin: "16px 0",
                            fontWeight: "bold",
                            marginLeft: "10px",
                          }}
                        >
                          Drop Off:
                          <br />
                          <Typography
                            key={activeDelivery?._id}
                            variant="caption"
                            style={{
                              color: "black",
                              opacity: "0.8",
                              marginTop: "3px",
                            }}
                          >
                            {activeDelivery?.delivery_address?.address}
                          </Typography>
                        </Typography>
                      </div>
                    </div>
                    <div>
                      <div style={{ width: "100%" }}>
                        {
                          activeDelivery?.real_image || activeDelivery?.approximate_image ? (
                            <img
                              src={activeDelivery?.real_image ? activeDelivery?.real_image : activeDelivery?.approximate_image}
                              alt=''
                              style={{ width: '100%', height: 'auto' }}
                            />
                          ) : (
                            <BestRoute pickup={pickup} dropoff={dropoff} wayPoints={wayPoints} />
                          )
                        }
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "20px",
                      }}
                    >
                      <img src={process.env.REACT_APP_ENV === 'women_first' ? emptyActiveWf : emptyActive} alt="active" />
                    </div>
                    <p
                      style={{
                        justifyContent: "center",
                        display: "flex",
                        fontWeight: "bold",
                      }}
                    >
                      No Active Ride{" "}
                    </p>
                  </>
                )}
              </>
            )}
            {valueTabs === "three" && (
              <Box
                sx={{
                  width: "100%",
                  marginTop: !smDown ? "30px" : "10px",
                  "& .super-app-theme--header": {
                    backgroundColor: "rgb(220,220,220)",
                  },
                }}
              >
                <RidesHistory driverId={params["id"]} />
              </Box>
            )}
            {valueTabs === "four" && (
              <Box
                sx={{
                  width: "100%",
                  marginTop: !smDown ? "30px" : "10px",
                  "& .super-app-theme--header": {
                    backgroundColor: "rgb(220,220,220)",
                  },
                }}
              >
                <DriverViachle selectedObject={selectedObject} />
              </Box>
            )}
            {valueTabs === "five" && (
              <Box
                sx={{
                  width: "100%",
                  marginTop: !smDown ? "30px" : "10px",
                  "& .super-app-theme--header": {
                    backgroundColor: "rgb(220,220,220)",
                  },
                }}
              >
                <DriverReviews driverId={params["id"]} />
              </Box>
            )}
          </div>
        </Box>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={handleSnackbarClose} severity="error">
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </>
  );
};

const styles = {
  title: {
    fontWeight: "bold",
    fontSize: "26px",
    marginTop: "5px",
  },
  subTitle: {
    fontWeight: "bold",
    fontSize: "16px",
    marginTop: "5px",
    marginBottom: "5px",
  },
  companyImage: {
    width: "150px",
    height: "135px",
    border: "2px solid #EEEEEE7A",
    borderRadius: 15,
  },
  companyImageMobileSize: {
    width: "90px",
    height: "90px",
    border: "2px solid #EEEEEE7A",
    borderRadius: 10,
    marginTop: "0px",
  },
};
export default DriversDetails;
