import { Box, Typography } from '@mui/material';
import React from 'react';
import Bill from '../../../assets/svg/Bill';
import BlueButton from '../../../components/common/Buttons/BlueButton';
import colorConfigs from '../../../configs/colorConfigs';

function PayDriverComponent({ handleBack, handlePaid }) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 1,
                border: '1px solid #ddd',
                borderRadius: 2,
                margin: '0 auto',
                textAlign: 'center',
                height: '350px',
                background: '#FFFFFF',
                mt: 7,
                width: '600px',
                maxWidth: '90%'
            }}
        >
            <Box sx={{ mt: 2 }}>
                <Bill />
            </Box>
            <Box sx={{ mt: 2 }}>
                <Typography sx={{ fontWeight: 600, fontSize: 18 }} gutterBottom>
                    Are You sure you Paid for this drivers Cash?
                </Typography>
                <Typography sx={{ fontWeight: 400, fontSize: 14, color: '#828282' }} gutterBottom>
                    When you click on paid it will convert from <br></br>
                    unpaid to paid cas
                </Typography>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', mt: 3, px: 5, gap: 2 }}>
                <BlueButton onClick={handleBack} sx={{ borderRadius: 2,background:'transparent',border:`1px solid ${colorConfigs?.sidebar?.bg}`,color:colorConfigs?.sidebar?.bg }}>
                    close
                </BlueButton>
                <BlueButton onClick={handlePaid} sx={{ borderRadius: 2 }}>
                    Paid
                </BlueButton>
            </Box>
        </Box>
    );
}

export default PayDriverComponent;
