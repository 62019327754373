import React from "react";
import MapDrivers from "../../components/googleMaps/trackDrivers.js";
import { Box } from "@mui/material";

export default function GoogleMaps() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        position: "relative",
        p:2,
        background:'#FFF',
        mt:-9,
        borderRadius: 2,
        minHeight: "90vh",
  
      }}
    >
      
      <MapDrivers />
    </Box>
  );
}
