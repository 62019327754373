/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { auth, db } from "../../configs/firebase";
import MessageViewerBottom from "./message-viewer-bottom";
import MessageViewerList from "./message-viewer-list";
import { Divider } from "@mui/material";

const MessageViewer = ({ allowSend }) => {
  const params = useParams();
  const [enabler, setEnabler] = useState([]);
  const [admin, setAdmin] = useState(null);
  const sentinel = useRef(null);
  const userData = auth?.currentUser;
  const [job, setJob] = useState(null);
  const [last, setLast] = useState(null);
  const [flag, setFlag] = useState(false);
  const selectedChat = useSelector((state) => state.appState.selectedChat);
  const selectedRide = useSelector((state) => state.appState.selectedRide);
  const outChat = useSelector((state) => state.appState.outChat);
  const [messages, setMessages] = useState([]);
  const messagesRef = useRef([]);
  const uid = selectedChat?.type === 'driver' ? selectedChat?.driver : selectedChat?.customer
  const lastSupportChat = useSelector((state) => state.appState.lastSupportChat);

  messagesRef.current = messages;

  const lastRef = useRef();
  lastRef.current = last;

  const flagRef = useRef(false);
  flagRef.current = flag;
  const handleChatId = () => {
    if (selectedRide?.chat_type === "customer") {
      return `admin_${selectedRide?.customer_details?.fb_uid}`
    } else {
      return `admin_${selectedRide?.driver_info?.fb_uid ? selectedRide?.driver_info?.fb_uid : selectedRide?.driver_info?.fb_id}`
    }
  }
  const handleName = () => {
    if (selectedRide?.chat_type === "customer") {
      return selectedRide?.customer_details?.first_name + " " + selectedRide?.customer_details?.last_name
    } else {
      return selectedRide?.driver_info?.first_name + " " + selectedRide?.driver_info?.last_name
    }
  }
  const handleProfileImage = () => {
    if (selectedRide?.chat_type === "customer") {
      return selectedRide?.customer_details?.profile_picture
    } else {
      return selectedRide?.driver_info?.profile_picture
    }
  }
  const handleLastChatId = () => {
    if (lastSupportChat?.chat_type === "customer") {
      return `admin_${lastSupportChat?.customer_details?.fb_uid}`
    } else {
      return `admin_${lastSupportChat?.driver_info?.fb_uid ? lastSupportChat?.driver_info?.fb_uid : lastSupportChat?.driver_info?.fb_id}`
    }
  }
  const handleLastName = () => {
    if (lastSupportChat?.chat_type === "customer") {
      return lastSupportChat?.customer_details?.first_name + " " + lastSupportChat?.customer_details?.last_name
    } else {
      return lastSupportChat?.driver_info?.first_name + " " + lastSupportChat?.driver_info?.last_name
    }
  }
  const handleLastProfileImage = () => {
    if (lastSupportChat?.chat_type === "customer") {
      return lastSupportChat?.customer_details?.profile_picture
    } else {
      return lastSupportChat?.driver_info?.profile_picture
    }
  }

  useEffect(() => {
    if (lastSupportChat) {
      const uid = lastSupportChat?.type === 'driver' ? lastSupportChat?.driver : lastSupportChat?.customer
      const jobId = uid;
      db.collection("messaging_with_admin")
        .doc(jobId)
        .get()
        .then((doc) => {
          setMessages(doc.data());
        });
    } else if (selectedRide?.chat_type || selectedChat?.driver || selectedChat?.customer) {
      const jobId = outChat ? handleChatId() : uid;
      db.collection("messaging_with_admin")
        .doc(jobId)
        .get()
        .then((doc) => {
          setMessages(doc.data());
        });
    }
  }, [selectedChat?.driver, selectedChat?.customer, selectedRide, selectedChat, lastSupportChat]);

  return (
    <div style={{ position: "relative", height: "100%" }}>
      <div
        style={{
          zIndex: 3,
          height: "64px",
          top: "0",
          left: "0",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "2px solid rgb(240,240,240,1)",
        }}
      >
        <div style={{ display: "flex" }}>
          <Avatar
            name={(outChat ? handleName() : messages?.user_name ?? "Loading...")}
            src={outChat ? handleProfileImage() : messages?.user_profile}
            size={22}
          />
          <Typography
            variant="body2"
            style={{ padding: "10px", fontWeight: 700, fontSize: "16px" }}
          >
            {(outChat ? handleName() : messages?.user_name) ?? "Loading..."}
          </Typography>
        </div>
      </div>
      <MessageViewerList allowSend={allowSend} admin={admin} />

      {allowSend && <MessageViewerBottom admin={admin} job={job} />}
    </div>
  );
};

export default MessageViewer;
