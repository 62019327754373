import React, { useState, useEffect } from 'react';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { db } from '../../configs/firebase';
import { Box } from '@mui/material';

const UnreadDocument = ({ collectionName, conditions = [] }) => {
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        let unsubscribe;

        if (collectionName && conditions.length > 0) {
            const fetchDocuments = async () => {
                try {
                    let q = collection(db, collectionName);

                    conditions.forEach(condition => {
                        q = query(q, where(condition.key, condition.operator, condition.value));
                    });

                    unsubscribe = onSnapshot(q, (snapshot) => {
                        setCount(snapshot.size);
                        setLoading(false);
                    }, (err) => {
                        setError(err.message);
                        setLoading(false);
                    });
                } catch (err) {
                    setError(err.message);
                    setLoading(false);
                }
            };

            fetchDocuments();
        }

        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, [collectionName, conditions]);

    if (loading) return null;
    if (error) return null;
    if (count <= 0) return null;
    return (
        <Box
            sx={{
                background: '#FF4D4F',
                border: '2px solid #FFF',
                color: '#FFF',
                height: 20,
                minWidth: 30,
                borderRadius: 30,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                ml: 1,
                textAlign: 'center',
                fontSize: 14,
                fontWeight: '500',
                px: 0.5
            }}
        >
            {count?.length > 99 ? '+99' : count}
        </Box>
    );
};

export default UnreadDocument;
