import SearchIcon from "@mui/icons-material/Search";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel, MenuItem, Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import Loading from "react-fullscreen-loading";
import { useDispatch, useSelector } from "react-redux";
import deletee from "../../assets/images/delete.png";
import colorConfigs from "../../configs/colorConfigs";
import { formatCurrency } from "../../utils/localization/currencyUtils";
import { formatDate } from "../../utils/localization/dateTimeUtils";
import { DeleteMethod, getMethod } from "../Hook/UseCustomApi";
import Status from "../status/status";
import DynamicTable from "../table/table";
import Error5xx from "../../components/common/HandelError5xx";
import { useNavigate } from "react-router-dom";

export default function RidesHistory({ driverId }) {
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [status, setStatus] = useState("all");
  const [query, setQuery] = useState("");
  const countryCode = useSelector((state) => state.country.selectedCountry);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const navigate = useNavigate();

  //handle error5xx
  const [tryAgain, setTryAgain] = useState();
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [errorCount, setErrorCount] = useState(0);
  const [errorDialogCounter, setErrorDialogCounter] = useState(0);
  const handleError5xx = () => {
    setIsErrorDialogOpen(true);
    setErrorCount(prevCount => {
      const newCount = prevCount + 1;
      if (newCount === 1) {
        setErrorDialogCounter(5);
      } else if (newCount === 2) {
        setErrorDialogCounter(10);
      }
      return newCount;
    });
  };
  const handleCloseErrorDialog = () => {
    setIsErrorDialogOpen(false);
    if (errorCount <= 2) {
      handleErrorCountByClose()
    }

  };
  const handleErrorCountByClose = () => {
    setErrorCount(prevCount => {
      const newCount = prevCount - 1;
      if (newCount === 1) {
        setErrorDialogCounter(5);
      } else if (newCount === 2) {
        setErrorDialogCounter(10);
      }
      return newCount;
    });
  }


  const licAndConfig = useSelector((state) => state.licencesAndConfiguration);
  // console.log(licAndConfig);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const rowsLimits = [5, 10, 25, 100];
  let currentPage = useRef();
  let currentLimit = useRef(10);
  let currentKeyword = useRef();
  let currentStatus = useRef();



  const getParamFetchDriver = () => {
    return {
      page: currentPage.current,
      limit: currentLimit.current,
    };
  };


  const getParamFetchMoreDriver = () => {
    return {
      page: currentPage.current,
      limit: currentLimit.current,
      status: currentStatus.current,
      "search_customer_details.first_name": currentKeyword.current,
      "search_customer_details.last_name": currentKeyword.current,
      search_short_id: currentKeyword.current,
    };
  };

  const openDeleteDialog = (id) => {
    setDeleteItemId(id);
    setDeleteDialogOpen(true);
  };
  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setDeleteItemId(null);
  };


  const deleteData = () => {
    // Perform the deletion
    DeleteMethod({
      url: `delivery/${deleteItemId}`,
      handleErrorMessage: showSnackbar,
    })
      .then(() => {
        fetchDrivers();
        // Perform any additional actions after deletion if needed
      })
      .catch((error) => {
        console.error("Error deleting data:", error);
        // Handle error scenarios
        if (error === "Internal Server Error: Server error occurred.") {
          handleError5xx();
          setTryAgain(() => deleteData);
        }
      })
      .finally(() => {
        closeDeleteDialog();
      });
  };

  useEffect(() => {
    currentPage.current = 1;
    currentLimit.current = 10;
    currentKeyword.current = "";
    currentStatus.current = "all";
    fetchDrivers();
    fetchMoreDrivers()
  }, []); // Run once on component mount
  // fetch driver

  const fetchDrivers = () => {
    setLoading(true);

    getMethod({
      url: `ride?driver=${driverId}`,
      params: getParamFetchDriver(),
      handleErrorMessage: showSnackbar,
    })
      .then((response) => {
        let result = response?.data;
        setData(result);
        setCount(response?.count);
        console.log(response);
      })
      .catch((error) => {
        console.error("Error fetching more stores:", error);
        // Handle error scenarios
        if (error === "Internal Server Error: Server error occurred.") {
          handleError5xx();
          setTryAgain(() => fetchDrivers);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // fetch more drivers
  const fetchMoreDrivers = () => {
    setLoading(true);

    getMethod({
      url: `ride?driver=${driverId}`,
      params: getParamFetchMoreDriver(),
      handleErrorMessage: showSnackbar,
    })
      .then((response) => {
        let result = response?.data;
        setData(result);
        setCount(response?.count);
      })
      .catch((error) => {
        console.error("Error fetching more stores:", error);
        // Handle error scenarios
        if (error === "Internal Server Error: Server error occurred.") {
          handleError5xx();
          setTryAgain(() => fetchMoreDrivers);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
    currentStatus.current = event.target.value;
    currentPage.current = 1;
    setPage(1);
    fetchMoreDrivers();
  };

  const handleSearch = (e) => {
    const inputValue = e.target.value;
    setQuery(inputValue);
    setPage(1);
    currentPage.current = 1;
    currentKeyword.current = inputValue;
    fetchMoreDrivers();
  };
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    currentPage.current = newPage;
    fetchMoreDrivers();
  };
  const handleRowsChange = (rows) => {
    currentLimit.current = rows;
    currentPage.current = 1;
    setPage(1);
    fetchMoreDrivers();
  };
  const style = {
    color: "#696969",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "24px" /* 150% */,
    letterSpacing: "0.17px",
    textTransform: "capitalize",
  };
  const columns = [
    {
      field: "short_id",
      headerName: "Trip ID",
      flex: 1,
      minWidth: 85,
      editable: true,
      align: "left",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <span
          style={{ textDecoration: "underline", fontWeight: "bold", cursor: "pointer" }}
          onClick={() => navigate(`/rides/details/${params?.row?._id}`)}
        >
          {params?.row?.short_id}
        </span>
      ),
    },
    {
      field: "createdAt",
      headerName: "Booking Date",
      flex: 1,
      minWidth: "20px",
      editable: true,
      style: style,
      align: "left",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        const dateObject = new Date(params?.row?.createdAt);

        const options = { day: "numeric", month: "short", year: "numeric" };

        const formattedDate = dateObject.toLocaleDateString("en-US", options);

        return <Typography>{formatDate(params?.row?.createdAt, countryCode)}</Typography>;
      },
    },
    {
      field: "rider",
      headerName: "Passenger Name",
      flex: 1,
      minWidth: "20px",
      editable: true,
      style: style,
      align: "left",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <Typography>
          {params?.row?.customer_details?.first_name}{" "}
          {params?.row?.customer_details?.last_name}
        </Typography>
      ),
    },
    {
      field: "pickup_address",
      headerName: "Pickup",
      flex: 1,
      minWidth: "20px",
      editable: true,
      style: style,
      align: "left",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <Typography>{params?.row?.pickup_address?.address}</Typography>
      ),
    },
    {
      field: "delivery_address",
      headerName: "Drop Off",
      flex: 1,
      minWidth: "20px",
      editable: true,
      style: style,
      align: "left",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <Typography>{params?.row?.delivery_address?.address}</Typography>
      ),
    },
    {
      field: "fare",
      headerName: "Fare",
      flex: 1,
      minWidth: "20px",
      editable: true,
      style: style,
      align: "left",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <Typography>
          {formatCurrency(params?.row?.pay_info?.total ? params?.row?.pay_info?.total : params?.row?.estimation_cost, countryCode)}
        </Typography>
      ),
    },

    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: "50px",
      editable: true,
      style: style,
      align: "left",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => <Status status={params?.row?.status} />,
    },
  ];
  return (
    <>
      {/* handleError5xx */}
      <Error5xx open={isErrorDialogOpen} errCount={errorCount} count={errorDialogCounter} handleClose={handleCloseErrorDialog} tryAgain={tryAgain} />
      <Dialog
        style={{ zIndex: 10, borderRadius: "30px" }}
        open={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent style={{ padding: "30px" }}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {isDeleteDialogOpen && <img src={deletee} alt="" />}
          </div>
          <DialogContentText
            id="alert-dialog-slide-description"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Typography
              variant="h6"
              style={{
                fontWeight: "bold",
                color: "black",
                marginTop: "10px",
                marginBottom: "8px",
              }}
            >
              Delete Ride
            </Typography>
          </DialogContentText>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to Delete Driver Ride?
          </DialogContentText>
        </DialogContent>

        <DialogActions
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            marginBottom: "20px",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
        >
          <Button
            variant="outlined"
            style={{
              color: loading ? "gray" : "black",
              borderColor: "lightgray",
              width: "100%",
            }}
            onClick={closeDeleteDialog}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            style={{
              background: loading ? "gray" : "var(--Error, #FD4343)",
              color: "white",
              width: "100%",
            }}
            onClick={deleteData}
            disabled={loading}
          >
            {loading ? "...Loading" : "Yes, Delete"}
          </Button>
        </DialogActions>
      </Dialog>
      <div style={{ display: "flex", justifyContent: "end" }}>
        <Grid
          container
          spacing={1}
          style={{
            display: "flex",
            justifyContent: "end",
            justifySelf: "end",
            gap: "14px",
            marginBottom: "14px",
            // width: "55%",
          }}
        >
          <Grid item xs={7} md={6} lg={5}>
            <TextField
              fullWidth
              label="Search"
              name="search"
              placeholder="Search By Passenger Name or Trip No."
              value={query}
              onChange={handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: "gray" }} />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              sx={{
                "& label.Mui-focused": { color: "rgba(0, 0, 0, 0.6)" },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#A0AEC0",
                  },
                  borderRadius: "10px",
                  height: "52px",
                },
              }}
            />
          </Grid>
          <Grid
            item
            xs={4}
            md={3}
            lg={2}

          >
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth style={{ marginTop: "2px" }} sx={{
                "& label.Mui-focused": {
                  color: "rgba(0, 0, 0, 0.6)",
                  bordercolor: "#BBC1CE",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#BBC1CE",
                  },
                  "&:hover fieldset": {
                    borderColor: "#BBC1CE",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#BBC1CE",
                    borderWidth: "1px",
                  },
                },
              }}>
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={status}
                  label="Status"
                  onChange={handleStatusChange}
                  style={{ borderRadius: "10px", height: "50px" }}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                >
                  <MenuItem value={"all"}>All</MenuItem>
                  {licAndConfig?.licenses?.scheduled_ride && (<MenuItem value={"scheduled"}>Scheduled</MenuItem>)}
                  <MenuItem value={"assigned"}>Assigned</MenuItem>
                  <MenuItem value={"delivered"}>Delivered</MenuItem>
                  <MenuItem value={"canceled"}>Canceled</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
      </div>
      <DynamicTable
        columns={columns}
        data={data}
        count={count}
        page={page}
        rowsLimits={rowsLimits}
        pageChange={handlePageChange}
        rowsChange={handleRowsChange}
        pageType="rides"
      />
      <Loading
        loading={!!loading}
        background="rgba(86, 100, 210, 0.1)"
        loaderColor={colorConfigs.sidebar?.bg}
      />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleSnackbarClose} severity="error">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
