import {
  Alert,
  Box,
  Container,
  Snackbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { isIOS } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import assets from "../../assets";
import womenbg from "../../assets/womens-first/womenbg.png";
import { auth } from "../../configs/firebase";
import sizeConfigs from "../../configs/sizeConfigs";
import { getMethod } from "../../pages/Hook/UseCustomApi";
import { userData } from "../../redux/features/appStateSlice";
import Sidebar from "../common/Sidebar";
import Topbar from "../common/Topbar";
import Error5xx from "../common/HandelError5xx";

const MainLayout = () => {
  const theme = useTheme();
  const openDrawer = useSelector((state) => state.appState.openDrawer);
  const [isOpened, setIsOpened] = useState(openDrawer);
  const xsDown = useMediaQuery(theme.breakpoints.down("xs"));
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const lgDown = useMediaQuery(theme.breakpoints.down("lg"));
  const xlDown = useMediaQuery(theme.breakpoints.down("xl"));
  const dispatch = useDispatch();
  const location = useLocation();

  const fullWidthPaths = [
    '/cooperates/customer_details',
    '/cooperates/employee_details',
    '/tracking',
    '/',
    '/support',
    '/chat',
    '/Dashboard',
    '/trackRides',
    '/booking-history', '/settings', '/cooperates/corporate-details', '/cooperates/add-voucher', '/cooperates/edit-voucher', '/cooperates/voucher-details',
    '/cooperates/add-employee', '/cooperates/edit-employee', '/cooperates/add-customer', '/cooperates/edit-customer'
  ];
  const isFullWidth = fullWidthPaths.includes(location.pathname);



  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  //handle error5xx
  const [tryAgain, setTryAgain] = useState();
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [errorCount, setErrorCount] = useState(0);
  const [errorDialogCounter, setErrorDialogCounter] = useState(0);
  const handleError5xx = () => {
    setIsErrorDialogOpen(true);
    setErrorCount(prevCount => {
      const newCount = prevCount + 1;
      if (newCount === 1) {
        setErrorDialogCounter(5);
      } else if (newCount === 2) {
        setErrorDialogCounter(10);
      }
      return newCount;
    });
  };
  const handleCloseErrorDialog = () => {
    setIsErrorDialogOpen(false);
    if (errorCount <= 2) {
      handleErrorCountByClose()
    }

  };
  const handleErrorCountByClose = () => {
    setErrorCount(prevCount => {
      const newCount = prevCount - 1;
      if (newCount === 1) {
        setErrorDialogCounter(5);
      } else if (newCount === 2) {
        setErrorDialogCounter(10);
      }
      return newCount;
    });
  }

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };
  useEffect(() => {
    setIsOpened(openDrawer);
  }, [openDrawer]);
  const getParams = () => {
    return {
    };
  };

  const fetchUserData = async () => {
    if (!auth || !auth?.currentUser || !auth?.currentUser?.uid) {
      return;
    }

    getMethod({
      url: `admin/by-firebase-id/${auth?.currentUser?.uid}`,
      params: getParams(),
      handleErrorMessage: showSnackbar,
    })
      .then((response) => {
        const result = response?.data;

        dispatch(userData(result));
      })
      .catch((err) => {
        console.error("Error fetching admin data:", err);
        if (err === "Internal Server Error: Server error occurred.") {
          handleError5xx();
          setTryAgain(() => fetchUserData);
        }
      });
  };

  useEffect(() => {
    fetchUserData();
  }, [auth?.currentUser?.uid]);
  return (
    <>
      {/* handleError5xx */}
      <Error5xx open={isErrorDialogOpen} errCount={errorCount} count={errorDialogCounter} handleClose={handleCloseErrorDialog} tryAgain={tryAgain} />
      <Container maxWidth={"xl"}>
        <div
          style={{
            minHeight: isIOS ? "-webkit-fill-available" : "100vh",
            width: "100%",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >

          <div
            style={{
              position: "absolute",
              top: "-5%",
              width: "100%",
              height: "40%",
              background: xlDown
                ? `url(${process.env.REACT_APP_ENV === 'women_first' ? womenbg : assets.images.background}) -148px center / cover`
                : `url(${process.env.REACT_APP_ENV === 'women_first' ? womenbg : assets.images.background}) 200% bottom / contain`,
            }}
          >
            <Topbar isFullWidth={isFullWidth} />

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingLeft: !isFullWidth || !isOpened ? (mdDown || !isOpened ? "0px" : "290px") : '280px',

              }}
            >
              <Box
                component="main"
                sx={{
                  flexGrow: 1,
                  p: isFullWidth ? 3 : 3,
                  maxWidth: !isFullWidth ? (xsDown || smDown ? '100%' : '95%') : '100%',
                  minHeight: "85vh",
                  backgroundColor: isFullWidth ? '#f5f7f8' : "white",
                  borderRadius: isFullWidth ? 0 : 5,
                  mb: 5,
                }}
              >
                <Outlet />
              </Box>
            </div>
          </div>
        </div>
        <Box sx={{ display: "flex" }}>
          <Box
            component="nav"
            sx={{
              width: sizeConfigs.sidebar.width,
              flexShrink: 0,
            }}
          >
            <Sidebar isSidebarOpen={isOpened} />
          </Box>
        </Box>
      </Container>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleSnackbarClose} severity="error">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default MainLayout;