import {
    Box,
    Button,
    ButtonGroup,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Typography
} from '@mui/material';
import React, { useState } from 'react';
import { BiEditAlt } from "react-icons/bi";
import { IoEyeSharp } from 'react-icons/io5';
import colorConfigs from '../../../configs/colorConfigs';
import BlueButton from '../../../components/common/Buttons/BlueButton';
import { putMethod } from "../../Hook/UseCustomApi";

function PayoutPlanDialog({ settingsData, onUpdate }) {
    const [frequency, setFrequency] = useState(settingsData?.payout_type || 'Weekly');
    const [dayStart, setDayStart] = useState(settingsData?.crated_payout);
    const [monthStart, setMonthStart] = useState(1);
    const [payDay, setPayDay] = useState(28);
    const [isEditing, setIsEditing] = useState(false);

    const handleSave = async () => {
        const body = {
            payout_type: frequency,
            crated_payout: dayStart,
            day_payout: "",
        };

        try {
            // const headers = await getTokenAndHeaders();
            await putMethod({
                url: "admin/setting/project",
                body: body,

                handleErrorMessage: (message) => {
                    // Handle error message here if needed
                    console.error(message);
                },
            });
            setIsEditing(false);
            onUpdate(); // Call the onUpdate callback to refresh the settings data
        } catch (error) {
            console.error("Error saving payout plan:", error);
        }
    };

    const handleCancel = () => {
        setIsEditing(false);
    };

    return (
        <Box p={3} border={1} borderRadius={4} borderColor="grey.400" sx={{ background: '#FFFFFF', width: '570px', maxWidth: '95%', mx: 'auto' }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h6">Payout Plan</Typography>
                <ButtonGroup sx={{ border: `1px solid ${colorConfigs.sidebar.bg}`, borderRadius: 25, p: .5 }}>
                    <Button
                        onClick={() => setIsEditing(false)}
                        style={{
                            backgroundColor: !isEditing ? colorConfigs.sidebar.bg : 'transparent',
                            border: !isEditing ? colorConfigs.sidebar.bg : 'none',
                            borderColor: colorConfigs.sidebar.bg,
                            color: !isEditing ? '#FFFFFF' : colorConfigs.sidebar.bg,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: 4,
                            borderRadius: 25
                        }}
                    >
                        <IoEyeSharp size={18} />
                        View
                    </Button>
                    <Button
                        onClick={() => setIsEditing(true)}
                        style={{
                            backgroundColor: isEditing ? colorConfigs.sidebar.bg : 'transparent',
                            borderColor: colorConfigs.sidebar.bg,
                            border: isEditing ? colorConfigs.sidebar.bg : 'none',
                            color: isEditing ? '#FFFFFF' : colorConfigs.sidebar.bg,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: 4,
                            borderRadius: 25
                        }}
                    >
                        <BiEditAlt size={18} />
                        Edit
                    </Button>
                </ButtonGroup>
            </Box>
            <FormControl
                fullWidth
                margin="normal"
                sx={{
                    "& label.Mui-focused": {
                        color: "rgba(0, 0, 0, 0.6)",
                    },
                    "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                            borderColor: "#BBC1CE",
                        },
                        "&:hover fieldset": {
                            borderColor: "#BBC1CE",
                        },
                        "&.Mui-focused fieldset": {
                            borderColor: "#BBC1CE",
                            borderWidth: "1px",
                        },
                        height: "52px",
                    },
                }}
            >
                <InputLabel shrink>Frequency</InputLabel>
                <Select
                    value={frequency}
                    defaultValue='Weekly'
                    onChange={(e) => setFrequency(e.target.value)}
                    disabled={true}
                    label="Frequency"
                >
                    <MenuItem value="Weekly">Weekly</MenuItem>
                </Select>
            </FormControl>


            {frequency === 'Monthly' && (
                <>
                    <FormControl
                        fullWidth
                        margin="normal"
                        sx={{
                            "& label.Mui-focused": {
                                color: "rgba(0, 0, 0, 0.6)",
                            },
                            "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                    borderColor: "#BBC1CE",
                                },
                                "&:hover fieldset": {
                                    borderColor: "#BBC1CE",
                                },
                                "&.Mui-focused fieldset": {
                                    borderColor: "#BBC1CE",
                                    borderWidth: "1px",
                                },
                                height: "52px",
                            },
                        }}
                    >
                        <InputLabel shrink>Month Start</InputLabel>
                        <Select
                            value={monthStart}
                            onChange={(e) => setMonthStart(e.target.value)}
                            disabled={!isEditing}
                            label="Month Start"
                        >
                            {[...Array(12).keys()].map(day => (
                                <MenuItem key={day + 1} value={day + 1}>{day + 1}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl
                        fullWidth
                        margin="normal"
                        sx={{
                            "& label.Mui-focused": {
                                color: "rgba(0, 0, 0, 0.6)",
                            },
                            "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                    borderColor: "#BBC1CE",
                                },
                                "&:hover fieldset": {
                                    borderColor: "#BBC1CE",
                                },
                                "&.Mui-focused fieldset": {
                                    borderColor: "#BBC1CE",
                                    borderWidth: "1px",
                                },
                                height: "52px",
                            },
                        }}
                    >
                        <InputLabel shrink>Pay Day</InputLabel>
                        <Select
                            value={payDay}
                            onChange={(e) => setPayDay(e.target.value)}
                            disabled={!isEditing}
                            label="Pay Day"
                        >
                            {[...Array(31).keys()].map(day => (
                                <MenuItem key={day + 1} value={day + 1}>{day + 1}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                </>
            )}

            {frequency === 'Weekly' && (
                <>
                    <FormControl
                        fullWidth
                        margin="normal"
                        sx={{
                            "& label.Mui-focused": {
                                color: "rgba(0, 0, 0, 0.6)",
                            },
                            "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                    borderColor: "#BBC1CE",
                                },
                                "&:hover fieldset": {
                                    borderColor: "#BBC1CE",
                                },
                                "&.Mui-focused fieldset": {
                                    borderColor: "#BBC1CE",
                                    borderWidth: "1px",
                                },
                                height: "52px",
                            },
                        }}
                    >
                        <InputLabel shrink>Week Start</InputLabel>
                        <Select
                            value={dayStart}
                            onChange={(e) => setDayStart(e.target.value)}
                            disabled={!isEditing}
                            label="Week Start"
                        >
                            <MenuItem value="Mon">Monday</MenuItem>
                            <MenuItem value="Tue">Tuesday</MenuItem>
                            <MenuItem value="Wed">Wednesday</MenuItem>
                            <MenuItem value="Thu">Thursday</MenuItem>
                            <MenuItem value="Fri">Friday</MenuItem>
                            <MenuItem value="Sat">Saturday</MenuItem>
                            <MenuItem value="Sun">Sunday</MenuItem>
                        </Select>
                    </FormControl>

                </>
            )}

            {isEditing && (
                <Box display="flex" justifyContent="flex-start" gap={1} mt={3}>
                    <BlueButton sx={{ borderRadius: 2, background: 'white', color: colorConfigs.sidebar.bg, border: '1px solid #E2E8F0' }} onClick={handleCancel} style={{ marginRight: 8 }}>Cancel</BlueButton>
                    <BlueButton sx={{ borderRadius: 2 }} onClick={handleSave}>Save</BlueButton>
                </Box>
            )}
        </Box>
    );
}

export default PayoutPlanDialog;
