import { Button, styled } from '@mui/material';
import colorConfigs from '../../../configs/colorConfigs';

const BlueButton = styled(Button)(() => ({
    backgroundColor: colorConfigs?.sidebar?.bg,
    color: '#FFFFFF',
    borderRadius: '60px',
    height: '40px',
    minWidth: '96px',
    textTransform: 'capitalize',
    '&:hover': {
        backgroundColor: '#FFFFFF',
        color: colorConfigs?.sidebar?.bg,
        border: `1px solid ${colorConfigs?.sidebar?.bg}`,
    },
}));

export default BlueButton