import DriveFileRenameOutlineSharpIcon from "@mui/icons-material/DriveFileRenameOutlineSharp";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {
  Alert,
  Box,
  Button,
  CardContent,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
  Typography
} from "@mui/material";
import { SubmitButton, TextInput } from "@zetaton-dev/zetaton-components-bank";
import { Modal } from "antd";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import addImgIcon from "../../assets/images/imageIcon.jpg";
import colorConfigs from "../../configs/colorConfigs";
import { storageSdk } from "../../configs/firebase";
import {
  setEnableEdit
} from "../../redux/features/appStateSlice";
import { getMethod, putMethod } from "../Hook/UseCustomApi";
import "./index.css";
import Error5xx from "../../components/common/HandelError5xx";

const DriverViachle = ({ selectedObject }) => {
  const [objData, setObjData] = useState(selectedObject);
  const [formErrors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [view, setView] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [selectedRideType, setSelectedRideType] = useState(objData.car_type);
  const [caTypeList, setCarTypeList] = useState([]);
  const [attemptedSubmit, setAttemptedSubmit] = useState(false);
  const dispatch = useDispatch();


  //handle error5xx
  const [tryAgain, setTryAgain] = useState();
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [errorCount, setErrorCount] = useState(0);
  const [errorDialogCounter, setErrorDialogCounter] = useState(0);
  const handleError5xx = () => {
    setIsErrorDialogOpen(true);
    setErrorCount(prevCount => {
      const newCount = prevCount + 1;
      if (newCount === 1) {
        setErrorDialogCounter(5);
      } else if (newCount === 2) {
        setErrorDialogCounter(10);
      }
      return newCount;
    });
  };
  const handleCloseErrorDialog = () => {
    setIsErrorDialogOpen(false);
    if (errorCount <= 2) {
      handleErrorCountByClose()
    }

  };
  const handleErrorCountByClose = () => {
    setErrorCount(prevCount => {
      const newCount = prevCount - 1;
      if (newCount === 1) {
        setErrorDialogCounter(5);
      } else if (newCount === 2) {
        setErrorDialogCounter(10);
      }
      return newCount;
    });
  }


  const handleSelectedRideTypeChange = (event) => {
    const { target: { value } } = event;

    const selectedItems = value.map((name) => {
      const item = caTypeList.find((type) => type.name === name);
      return item ? { _id: item._id, name: item.name, image: item.image, enabled: item.enabled } : null;
    }).filter(item => item !== null);

    setSelectedRideType(selectedItems);
  };


  const getRidesParams = () => {
    return {
      limit: 50,
    };
  };
  const fetchCarType = () => {
    if (
      (caTypeList?.length === 0)
    ) {
    }
    const url = "admin/car-type/all";
    getMethod({
      url: url,
      params: getRidesParams(),
      handleErrorMessage: showSnackbar,
    })
      .then((response) => {
        let result = response?.data;
        setCarTypeList(result);
      })
      .catch((error) => {
        console.error("Error fetching items:", error);
        if (error === "Internal Server Error: Server error occurred.") {
          handleError5xx();
          setTryAgain(() => fetchCarType);
        }
      })

  };
  useEffect(() => {
    fetchCarType()

  }, []);


  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const showModal = () => {

    if (selectedRideType.length === 0) {
      setIsModalVisible(false);
    } else {
      setIsModalVisible(true);
    }

  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const [images, setImages] = useState(
    objData?.vehicle_info?.vehicle_images || []
  );
  const [inputKey, setInputKey] = useState("");
  const [url, setUrl] = useState("");
  const [imageModalVisible, setImageModalVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const vehicleTypes = [
    { value: "car", label: "Sedan" },
    { value: "truck", label: "Truck" },
    { value: "van", label: "Van" },
    { value: "motorcycle", label: "Motorcycle" },
    { value: "bicycle", label: "Bicycle" },
    { value: "scooter", label: "Scooter" },
    { value: "electric vehicle", label: "Electric Vehicle" },
  ];

  const fieldStyle = {
    "& label.Mui-focused": {
      color: "#172B4D !important",
      fontSize: "17px !important",
      fontWeight: "500 !important",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#BBC1CE",
        borderWidth: 1,
        borderRadius: "8px",
      },
      "&:hover fieldset": {
        borderColor: "#BBC1CE",
        borderWidth: 1,
        borderRadius: "8px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#BBC1CE",
        borderWidth: 1,
        borderRadius: "8px",
      },
      input: {
        "&::placeholder": {
          color: "#3A3541DE",
          opacity: 1,
          fontSize: 14,
        },
      },
    },
  };

  const handleImagrUrl = (e) => {
    setLoading(true);
    let file = e.target?.files[0];
    const validTypes = ["image/png", "image/jpeg"];

    if (file && validTypes.includes(file.type)) {
      const uploadTask = storageSdk.ref(`/drivers/${file.name}`).put(file);
      uploadTask.on(
        "state_changed",
        (snapShot) => { },
        (err) => {
          console.log(err);
          setLoading(false);
        },
        () => {
          storageSdk
            .ref("drivers")
            .child(file.name)
            .getDownloadURL()
            .then(async (fireBaseUrl) => {
              const updatedImages = [...images, fireBaseUrl];
              setImages(updatedImages);
              setLoading(false);
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
            });
        }
      );
    } else {
      setLoading(false);
    }
    setInputKey(Math.random());
  };



  const handleSubmit = async (values) => {
    setAttemptedSubmit(true);
    if (selectedRideType.length === 0) {
      setIsModalVisible(false);
      console.log("You Need To Choose A Ride Type");
    } else {

      setIsModalVisible(false);
      setErrors({});
      let error = {};
      if (!values.vehicle_make) {
        error.vehicle_make = "Vehicle make is required.";
      } else if (values.vehicle_make.length > 25) {
        error.vehicle_make = "Vehicle make should not exceed 25 characters.";
      }
      if (!values.vehicle_type) {
        error.vehicle_type = "Vehicle type is required.";
      } else if (values.vehicle_type.length > 25) {
        error.vehicle_type = "Vehicle type should not exceed 15 characters.";
      }
      if (!values.vehicle_color) {
        error.vehicle_color = "Vehicle color is required.";
      } else if (values.vehicle_color.length > 25) {
        error.vehicle_color = "Vehicle color should not exceed 15 characters.";
      }
      if (!values.vehicle_model) {
        error.vehicle_model = "Vehicle model is required.";
      } else if (values.vehicle_model.length > 25) {
        error.vehicle_model = "Vehicle model should not exceed 25 characters.";
      }
      if (!values.vehicle_year) {
        error.vehicle_year = "Vehicle year is required.";
      } else if (!/^\d{4}$/.test(values.vehicle_year)) {
        error.vehicle_year = "Vehicle year must be a 4-digit number.";
      }

      if (Object.keys(error).length > 0) {
        setErrors(error);
        setOpenDialog(false);
        console.warn(Object.keys(error));
      } else {
        const dataToUpdate = {
          car_type: selectedRideType,
          vehicle_info: {
            vehicle_make: values?.vehicle_make,
            vehicle_type: values?.vehicle_type,
            vin: values?.vin,
            vehicle_model: values?.vehicle_model,
            vehicle_year: values?.vehicle_year,
            vehicle_color: values?.vehicle_color,
            license_plate: values?.license_plate,
            vehicle_images: images,
          }
        };

        setLoading(true);
        putMethod({
          url: `driver/byAdmin/${objData?._id}`,
          body: dataToUpdate,
          handleErrorMessage: showSnackbar,
        })
          .then((response) => {
            setObjData(response)
            // Handle successful response here if needed
          })
          .catch((error) => {
            console.error("Update API error:", error);
            // Handle error scenarios
            if (error === "Internal Server Error: Server error occurred.") {
              handleError5xx();
              setTryAgain(() => () => handleSubmit(values));
            }
          })
          .finally(() => {
            setLoading(false);
            setOpenDialog(false);
            setOpen(true);
          });

      }


    }


  };

  const handleDelete = (index) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };

  const handleImageClick = (src, index) => {
    setSelectedImage(src);
    setCurrentImageIndex(index);
    setImageModalVisible(true);
  };

  const handleImageModalClose = () => {
    setImageModalVisible(false);
    setSelectedImage(null);
  };

  const handleNextImage = () => {
    const nextIndex = (currentImageIndex + 1) % images.length;
    setCurrentImageIndex(nextIndex);
    setSelectedImage(images[nextIndex]);
  };

  const handlePreviousImage = () => {
    const prevIndex = (currentImageIndex - 1 + images.length) % images.length;
    setCurrentImageIndex(prevIndex);
    setSelectedImage(images[prevIndex]);
  };

  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const startYear = 1900;
    let years = [];
    for (let year = currentYear; year >= startYear; year--) {
      years.push(
        <MenuItem key={year} value={year}>
          {year}
        </MenuItem>
      );
    }
    return years;
  };

  return (
    objData &&
    Object.values(objData)?.length > 0 && (
      <>
        {/* handleError5xx */}
        <Error5xx open={isErrorDialogOpen} errCount={errorCount} count={errorDialogCounter} handleClose={handleCloseErrorDialog} tryAgain={tryAgain} />
        <Box
          display="flex"
          style={{ width: "100%", padding: 16, cursor: "pointer" }}
        >
          <Button
            variant="outlined"
            onClick={() => {
              setView(false);
              dispatch(setEnableEdit(true));

            }}
            style={{
              // borderTopRightRadius: "88px",
              // borderBottomRightRadius: "88px",
              borderTopLeftRadius: "88px",
              borderBottomLeftRadius: "88px",
              borderColor: colorConfigs?.sidebar?.bg,
              color: !view ? "white" : "#42526E",
              backgroundColor: !view ? colorConfigs?.sidebar?.bg : "white",
              cursor: "pointer",
            }}
          >
            <DriveFileRenameOutlineSharpIcon fontSize="small" /> Edit{" "}
          </Button>

          <Button
            variant="outlined"
            onClick={() => {
              setView(true);
              dispatch(setEnableEdit(false));

            }}
            style={{
              borderTopRightRadius: "88px",
              borderBottomRightRadius: "88px",

              // borderTopLeftRadius: "88px",
              // borderBottomLeftRadius: "88px",
              borderRight: "1px solid black",
              borderColor: colorConfigs?.sidebar?.bg,
              color: view ? "white" : colorConfigs?.sidebar?.bg,
              backgroundColor: view ? colorConfigs?.sidebar?.bg : "white",
              cursor: "pointer",
            }}
          >
            {" "}
            <VisibilityOutlinedIcon fontSize="small" /> View
          </Button>
        </Box>
        <div style={{ height: "150px" }}>
          <div style={{ marginLeft: "25px", display: "flex", gap: "12px" }}>
            {images &&
              images?.map((src, index) => (
                <div
                  key={index}
                  style={{
                    position: "relative",
                    marginRight: "10px",
                    display: "flex",
                  }}
                >
                  <img
                    src={src}
                    alt={`alt-${index}`}
                    style={{
                      width: "220px",
                      height: "185px",
                      borderRadius: "8px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleImageClick(src, index)}
                  />

                  {!view && (
                    <button
                      onClick={() => handleDelete(index)}
                      style={{
                        position: "absolute",
                        top: "5px",
                        left: "5px",
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                        color: "red",
                      }}
                    >
                      &#x2716;
                    </button>
                  )}
                </div>
              ))}
            {!view && images.length < 3 && (
              <div
                style={{
                  width: "220px",
                  height: "185px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "1px dashed black",
                  gap: "12px",
                  borderRadius: "8px",
                }}
              >
                <IconButton
                  component="label"
                  style={{
                    background: colorConfigs.sidebar.bg,
                    borderRadius: "10%",
                    width: "26px",
                    height: "26px",
                    marginBottom: "2px",
                  }}
                >
                  <img
                    style={{ width: "40px", height: "40px" }}
                    src={addImgIcon}
                    alt="add-img"
                  />
                  <input
                    type="file"
                    hidden
                    style={{ display: "none" }}
                    multiple
                    size="20971520"
                    accept="image/png, image/jpeg"
                    key={inputKey}
                    onChange={(e) => handleImagrUrl(e)}
                    onClick={(e) => (e.target.value = null)}
                  />{" "}
                </IconButton>
                <span
                  style={{
                    color: "#9E9E9E",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                  }}
                >
                  Upload Image here
                </span>
              </div>
            )}
          </div>
        </div>
        <Formik
          initialValues={{
            vehicle_type: objData?.vehicle_info?.vehicle_type,
            vehicle_make: objData?.vehicle_info?.vehicle_make,
            vin: objData?.vehicle_info?.vin,
            vehicle_model: objData?.vehicle_info?.vehicle_model,
            vehicle_year: objData?.vehicle_info?.vehicle_year,
            vehicle_color: objData?.vehicle_info?.vehicle_color,
            license_plate: objData?.vehicle_info?.license_plate,

          }}
          onSubmit={handleSubmit}
        >
          {({ handleBlur, handleChange, values, errors, handleSubmit }) => (
            <div style={{ width: "100%" }}>
              <div>
                <div style={{ height: "30px" }} />
                <CardContent>
                  <Grid container spacing={3} style={{ marginTop: "10px" }}>
                    <Grid item md={6} xs={12}>
                      <FormControl fullWidth variant="outlined" sx={fieldStyle}>
                        <InputLabel>Vehicle Type</InputLabel>
                        <Select
                          label="Vehicle Type"
                          name="vehicle_type"
                          value={values.vehicle_type}
                          onChange={handleChange("vehicle_type")}
                          onBlur={handleBlur("vehicle_type")}
                          disabled={view}
                          placeholder="Please select Vehicle Type"
                          error={formErrors.vehicle_type?.length > 0}
                        >
                          {vehicleTypes.map((type) => (
                            <MenuItem key={type.value} value={type.value}>
                              {type.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {formErrors.vehicle_type?.length > 0 && (
                        <Typography variant="caption" style={{ color: "red" }}>
                          {formErrors.vehicle_type}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormControl fullWidth variant="outlined" sx={{
                        "& label.Mui-focused": {
                          color: "rgba(0, 0, 0, 0.6)",
                          bordercolor: "#BBC1CE",
                          borderRadius: "8px",
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#BBC1CE",
                            borderRadius: "8px",
                          },
                          "&:hover fieldset": {
                            borderColor: "#BBC1CE",
                            borderRadius: "8px",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#BBC1CE",
                            borderWidth: "1px",
                            borderRadius: "8px",
                          },
                        },
                      }}>
                        <InputLabel>Ride Types</InputLabel>
                        <Select
                          label="Ride Types"
                          name="ride_type"
                          multiple
                          value={selectedRideType.map(item => item.name)}
                          onChange={handleSelectedRideTypeChange}
                          disabled={view}
                          placeholder="Please select Ride Type"
                          input={<OutlinedInput label="Select Types" />}
                          renderValue={(selected) => {
                            if (selected.length === 0) {
                              return <em style={{ color: colorConfigs.sidebar.bg }}>Select Types</em>;
                            }
                            return selected.join(', ');
                          }}
                        >
                          {caTypeList
                            .filter(caType => caType.enabled)
                            .map((ride) => (
                              <MenuItem key={ride._id} value={ride.name}>
                                <Checkbox
                                  style={{
                                    color: selectedRideType.map(item => item.name).indexOf(ride.name) > -1 ? colorConfigs.sidebar.bg : 'default',
                                  }}
                                  sx={{
                                    '&.Mui-checked': {
                                      color: colorConfigs.sidebar.bg,
                                    },
                                  }}
                                  checked={selectedRideType.map(item => item.name).indexOf(ride.name) > -1}
                                />
                                <ListItemText primary={ride.name} />
                              </MenuItem>
                            ))}
                        </Select>
                        {selectedRideType.length === 0 && (
                          <span style={{ color: "red", marginLeft: "8px" }}>
                            You Must Choose One Ride Type At Least
                          </span>
                        )}
                      </FormControl>

                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextInput
                        styles={fieldStyle}
                        fullWidth
                        label={"Vehicle Plate"}
                        name="license_plate"
                        onChange={handleChange("license_plate")}
                        onBlur={handleBlur("license_plate")}
                        required
                        value={values?.license_plate}
                        variant="outlined"
                        placeholder="Please enter Vehicle Plate"
                        filled="true" // Set it as a string "true"
                        focused
                        disabled={true}
                        error={
                          formErrors.license_plate?.length > 0 ? true : false
                        }
                      />
                      {formErrors.license_plate?.length > 0 && (
                        <Typography variant="caption" style={{ color: "red" }}>
                          {formErrors.license_plate}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextInput
                        styles={fieldStyle}
                        fullWidth
                        label={"Vehicle make"}
                        name="vehicle_make"
                        onChange={handleChange("vehicle_make")}
                        onBlur={handleBlur("vehicle_make")}
                        required
                        value={values?.vehicle_make}
                        variant="outlined"
                        placeholder="Please enter last name"
                        filled="true" // Set it as a string "true"
                        focused
                        InputProps={{
                          readOnly: view ? true : false,
                        }}
                        error={
                          formErrors.vehicle_make?.length > 0 ? true : false
                        }
                      />
                      {formErrors.vehicle_make?.length > 0 && (
                        <Typography variant="caption" style={{ color: "red" }}>
                          {formErrors.vehicle_make}
                        </Typography>
                      )}
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextInput
                        styles={fieldStyle}
                        fullWidth
                        label={"Vehicle model"}
                        name="vehicle_model"
                        onChange={handleChange("vehicle_model")}
                        onBlur={handleBlur("vehicle_model")}
                        required
                        value={values?.vehicle_model}
                        variant="outlined"
                        placeholder="Please enter vehicle model"
                        filled="true" // Set it as a string "true"
                        focused
                        InputProps={{
                          readOnly: view ? true : false,
                        }}
                        error={
                          formErrors.vehicle_model?.length > 0 ? true : false
                        }
                      />
                      {formErrors.vehicle_model?.length > 0 && (
                        <Typography variant="caption" style={{ color: "red" }}>
                          {formErrors.vehicle_model}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormControl fullWidth variant="outlined" sx={fieldStyle}>
                        <InputLabel>Vehicle Year</InputLabel>
                        <Select
                          label="Vehicle Year"
                          name="vehicle_year"
                          value={values.vehicle_year}
                          onChange={handleChange("vehicle_year")}
                          onBlur={handleBlur("vehicle_year")}
                          disabled={view}
                          placeholder="Please select Vehicle Year"
                          error={formErrors.vehicle_year?.length > 0}
                        >
                          {generateYearOptions()}
                        </Select>
                      </FormControl>
                      {formErrors.vehicle_year?.length > 0 && (
                        <Typography variant="caption" style={{ color: "red" }}>
                          {formErrors.vehicle_year}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextInput
                        styles={fieldStyle}
                        fullWidth
                        label={"Vehicle Color"}
                        name="vehicle_color"
                        onChange={handleChange("vehicle_color")}
                        onBlur={handleBlur("vehicle_color")}
                        required
                        value={values?.vehicle_color}
                        variant="outlined"
                        placeholder="Please enter Vehicle Color"
                        filled="true" // Set it as a string "true"
                        focused
                        disabled={true}
                        InputProps={{
                          readOnly: view ? true : false,
                        }}
                        error={
                          formErrors.vehicle_color?.length > 0 ? true : false
                        }
                      />
                      {formErrors.vehicle_color?.length > 0 && (
                        <Typography variant="caption" style={{ color: "red" }}>
                          {formErrors.vehicle_color}
                        </Typography>
                      )}
                    </Grid>


                    <Grid item md={6} xs={12} style={{ zIndex: 0 }}></Grid>
                  </Grid>
                </CardContent>
                <Modal
                  title="Confirm Changes"
                  visible={isModalVisible}
                  onOk={handleSubmit}
                  onCancel={handleCancel}
                  okText="Yes"
                  okButtonProps={{
                    style: {
                      backgroundColor: colorConfigs?.sidebar?.bg,
                      color: "white",
                    },
                  }}
                >
                  <p>Are you sure you want to save the changes?</p>
                </Modal>
              </div>
            </div>
          )}
        </Formik>

        <div style={{ height: "10px" }} />
        {!view && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              p: 2,
            }}
            style={{ marginLeft: "20px" }}
          >
            <SubmitButton
              style={{
                color: "white",
                backgroundColor: colorConfigs?.sidebar?.bg,
                width: "250px",
                height: "50px",
                borderRadius: "8px",
                fontSize: "18px",
              }}
              type="submit"
              variant="contained"
              label={"Save"}
              loading={loading}
              onClick={showModal}
            />
          </Box>
        )}
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            variant="filled"
            sx={{ width: "100%" }}
          >
            The data is saved successfully!
          </Alert>
        </Snackbar>
        <div style={{ height: "30px" }} />

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={handleSnackbarClose} severity="error">
            {snackbarMessage}
          </Alert>
        </Snackbar>

        <Modal
          title="Vehicle Images"
          visible={imageModalVisible}
          onCancel={handleImageModalClose}
          footer={null}
          bodyStyle={{ maxHeight: "70vh", overflowY: "auto" }}
        >
          {selectedImage && (
            <div style={{ position: "relative", textAlign: "center" }}>
              <img
                src={selectedImage}
                alt="vehicle-img"
                style={{
                  width: "100%",
                  height: "auto",
                  borderRadius: "8px",
                }}
              />
              <Button
                onClick={handlePreviousImage}
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "10px",
                  transform: "translateY(-50%)",
                  backgroundColor: "rgba(128, 128, 128, 0.5)",
                  color: "white",
                  fontSize: "24px",
                  fontWeight: "bold",
                  border: "none",
                  cursor: "pointer",
                  padding: 0,
                }}
              >
                &#8592;
              </Button>
              <Button
                onClick={handleNextImage}
                style={{
                  position: "absolute",
                  top: "50%",
                  right: "10px",
                  transform: "translateY(-50%)",
                  backgroundColor: "rgba(128, 128, 128, 0.5)",
                  color: "white",
                  fontSize: "24px",
                  fontWeight: "bold",
                  border: "none",
                  cursor: "pointer",
                  padding: 0,
                }}
              >
                &#8594;
              </Button>
            </div>
          )}
        </Modal>
      </>
    )
  );
};

export default DriverViachle;
