import * as React from "react"
import { memo } from "react"
const SvgComponent = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={50}
        height={50}
        fill="none"
        {...props}
    >
        <circle cx={25} cy={25} r={25} fill="#fff" />
        <path
            fill="#0FBE00"
            fillRule="evenodd"
            d="M29.205 15c.696 0 1.379.192 1.97.553.593.362 1.072.88 1.383 1.495l1.733 3.423a14.6 14.6 0 0 0 .9-.4c.296-.147.64-.17.954-.067.315.104.575.327.723.62.148.294.173.633.068.944a1.24 1.24 0 0 1-.628.714c-.3.132-.602.26-.906.386l1.201 2.375A3.67 3.67 0 0 1 37 26.702v3.121c0 .522-.11 1.037-.326 1.513-.216.476-.53.902-.924 1.25v1.561c0 .492-.197.963-.549 1.31a1.887 1.887 0 0 1-2.652 0 1.842 1.842 0 0 1-.548-1.31v-.618H17v.618c0 .492-.198.963-.55 1.31a1.887 1.887 0 0 1-2.652 0 1.842 1.842 0 0 1-.548-1.31v-1.561A3.679 3.679 0 0 1 12 29.824V26.7c0-.575.136-1.143.396-1.657l1.191-2.357c-.301-.125-.6-.26-.893-.404a1.252 1.252 0 0 1-.625-.715c-.105-.31-.083-.65.062-.944a1.239 1.239 0 0 1 .723-.62 1.263 1.263 0 0 1 .955.067c.296.144.596.277.9.4l1.732-3.422a3.723 3.723 0 0 1 1.382-1.495A3.783 3.783 0 0 1 19.795 15h9.41Zm-10.33 11.118c-.497 0-.974.195-1.326.542-.352.348-.55.82-.55 1.31 0 .492.198.963.55 1.31a1.887 1.887 0 0 0 2.652 0c.351-.347.549-.818.549-1.31 0-.49-.198-.962-.55-1.31a1.887 1.887 0 0 0-1.325-.542Zm11.25 0c-.498 0-.975.195-1.326.542-.352.348-.55.82-.55 1.31 0 .492.198.963.55 1.31.351.348.828.543 1.326.543.497 0 .974-.195 1.325-.542.352-.348.55-.819.55-1.31 0-.492-.198-.963-.55-1.31a1.886 1.886 0 0 0-1.325-.543Zm-.92-8.647h-9.41a1.262 1.262 0 0 0-1.04.55l-.078.133-1.587 3.135c1.935.573 4.542 1.123 7.41 1.123 2.677 0 5.126-.48 7.012-1.01l.396-.113-1.586-3.135a1.24 1.24 0 0 0-.962-.674l-.154-.01h-.001Z"
            clipRule="evenodd"
        />
    </svg>
)
const NewRide = memo(SvgComponent)
export default NewRide
