import StarIcon from '@mui/icons-material/Star';
import { Avatar, Box, Rating, Typography } from '@mui/material';
import React from 'react';

const RideRating = ({ name, avatar, rating, review }) => {

    const isReviewEmpty = !review;
    return (

        <Box
            sx={{
                width: "100%",
                height: isReviewEmpty ? "100px" : "173px",
                background: "#F9F9F9",
                marginTop: "10px",
                marginBottom: "15px",
                padding: "20px",
                borderRadius: "8px",
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                <Avatar src={avatar} alt={name} sx={{ mr: 2, width: 56, height: 56 }} />
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <Typography sx={{
                        width: "100%",
                        height: "27px",
                        // fontFamily: "outfit",
                        fontSize: "20px",
                        fontWeight: "600",
                        lineHeight: "27px",
                        textAlign: "center",
                        color: "#172B4D",
                    }}>
                        {name}
                    </Typography>
                    <Rating
                        value={rating}
                        readOnly
                        precision={0.5}
                        icon={<StarIcon fontSize="inherit" style={{ color: 'orange' }} />}
                        emptyIcon={<StarIcon fontSize="inherit" style={{ opacity: 0.55, color: 'grey' }} />}
                    />
                </Box>
            </Box>
            <Typography sx={{
                width: "100%",
                height: "56px",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "28px",
                textAlign: "left",
                color: "#7E7E7E",
                overflowWrap: 'break-word',
                paddingTop: "15px"
            }}>
                {review}
            </Typography>
        </Box>
    );
};

export default RideRating;
