import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import colorConfigs from "../../configs/colorConfigs";
import { AiFillEdit } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { setCarType, updateForm, updateFormData } from "../../redux/features/appStateSlice";
import { FaInfinity } from "react-icons/fa6";
import { borderRadius, fontSize, fontWeight, padding, textAlign } from "@mui/system";
import { Divider, Switch, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import distanceUnit from "../../utils/distanceUnit";
import { TableHead } from "@material-ui/core";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  headerCell: {
    backgroundColor: process.env.REACT_APP_ENV === 'women_first' ? '#F4E8F7' : colorConfigs.sidebar.ligthBg, // Light purple background for header cells
    color: colorConfigs.sidebar.bg, // Dark purple text color for header cells
    fontWeight: 'bold',
    padding: '7px 10px',
    minWidth: 130,
    borderTopLeftRadius: 7,
    borderBottomLeftRadius: 7
  },
  oddCell: {
    backgroundColor: process.env.REACT_APP_ENV === 'women_first' ? '#F6F2FE' : colorConfigs.sidebar.ligthBg, // Light purple background for odd cells
    color: '#495974', // Black text color for body cells
    borderRadius: 2,
    fontSize: 14,
    fontWeight: 700,
    textAlign: 'start',
    padding: '5px 8px',
    minWidth: 70,

  },
  evenCell: {
    backgroundColor: '#F5F5F5', // White background for even cells
    color: '#495974', // Black text color for body cells
    borderRadius: 2,
    fontSize: 14,
    fontWeight: 700,
    textAlign: 'start',
    padding: '5px 8px',
    minWidth: 70,
  },
  tableContainer: {
    borderCollapse: 'separate',
    borderSpacing: '0 5px', // Gap between rows
  },
  container: {
    justifyContent: "center !important",
    borderRadius: '8px !important',
    backgroundColor: "#F6F6F6",
    display: "flex !important",
    flexDirection: "column  !important",
    padding: "16px !important",
    margin: '10px 0 !important',
  },
  paper: {
    justifyContent: "space-between !important",
    borderRadius: '8px !important',
    backgroundColor: "#fff",
    display: "flex !important",
    width: "100% !important",
    gap: '8px !important',
    padding: "8px 16px !important",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100% !important",
      flexWrap: "wrap !important",
    },
    boxShadow: 'none'
  },
  planTitle: {
    color: colorConfigs?.sidebar?.bg,
    fontFamily: "poppins, sans-serif !important",
    fontWeight: "bold !important",
    fontSize: "15px !important",
  },
  luxuryText: {
    color: "#161616",
    fontFamily: "poppins, sans-serif",
    fontWeight: "bold !important",
    marginLeft: "15px !important",
    fontSize: "15px !important",
  },
  imageContainer: {
    justifyContent: "space-between !important",
    display: "flex !important",
    gap: 8,
  },
  smallImages: {
    alignSelf: "start !important",
    display: "flex !important",
    gap: 8,
    height: "auto !important",
  },
  img: {
    aspectRatio: "1 !important",
    objectFit: "cover !important",
    width: 32,
    height: 32,
    cursor: "pointer !important",
  },
  imgSmall: {
    aspectRatio: "1 !important",
    objectFit: "cover !important",
    width: 24,
  },
  costContainer: {
    justifyContent: "start",
    alignItems: "start !important",
    display: "flex !important",
    marginTop: 8,
    width: "100% !important",
    background: "#FFFFFF",
    borderRadius: '8px !important',
    padding: "16px !important",
    gap: 15,
    flexWrap: "wrap !important",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100% !important",
    },
  },
  costDetails: {
    // alignSelf: "stretch !important",
    display: "flex !important",
    // flexDirection: "column !important",
    lineHeight: "24px !important",
    margin: "auto 0 !important",
    justifyContent: 'start',
    gap: '10',
    border: '1px solid #E3E3E3',
    padding: '6px 8px',
    borderRadius: 8
  },
  costText: {
    color: "#7A869A !important",
    fontFamily: "poppins !important",
    fontWeight: 500,
    fontSize: '14px',
    marginRight: 5,
  },
  costValue: {
    color: "#495974 !important",
    fontFamily: "poppins !important",
    fontWeight: 600,
    fontSize: 14,
  },
  distanceContainer: {
    alignSelf: "start !important",
    display: "flex !important",
    gap: 8,
    color: colorConfigs?.sidebar?.bg,
    fontWeight: 700,
    lineHeight: "137% !important",
    margin: "14px 0 0 12px !important",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 10,
    },
  },
  distanceText: {
    backgroundColor: colorConfigs?.sidebar?.bg,
    borderRadius: "50% !important",
    width: 8,
    height: 8,
    margin: "auto 0 !important",
  },
  distanceValueContainer: {
    alignSelf: "start !important",
    display: "flex !important",
    gap: 12,
    marginTop: 8,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 10,
    },
  },
  distanceLabel: {
    color: "#7a869a !important",
    fontFeatureSettings: "'clig' off, 'liga' off !important",
    font: "500 14px/24px poppins, -apple-system, Roboto, Helvetica, sans-serif !important",
    display: "flex !important",
    justifyContent: "center !important",
    textAlign: "center !important",
    alignItems: "center !important",
  },
  distanceValue: {
    justifyContent: "center !important",
    borderRadius: 8,
    backgroundColor: "#f2f2f2 !important",
    display: "flex !important",
    gap: 18,
    fontWeight: 700,
    padding: "6px 12px !important",
  },
  distanceValueText: {
    color: colorConfigs?.sidebar?.bg,
    font: "14px/24px poppins, sans-serif !important",
    fontWeight: "bold !important",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  costPerMile: {
    justifyContent: "center !important",
    display: "flex !important",
    gap: 2,
    color: "#172b4d !important",
    lineHeight: "137% !important",
    margin: "auto 0 !important",
  },
  costPerMileText: {
    font: "14px poppins, sans-serif !important",
    fontWeight: 700,
  },
  costPerMinute: {
    fontFamily: "poppins, sans-serif !important",
    fontWeight: 700,
  },
}));

function PlanCard({ formData, type, index, removeCarType, editPlane = false, handleEditClick }) {
  const classes = useStyles();
  const formDataEdit = useSelector((state) => state.appState.formDataDoc);
  const formDataAdd = useSelector((state) => state.appState.formData);
  const location = useLocation();
  const enableEdit = location.pathname.includes('edit')
  const valueWithMode = enableEdit ? formDataEdit : formDataAdd
  const dispatch = useDispatch();
  return (
    <>
      <Container className={classes.container}>
        <Paper className={classes.paper}>
          <Box className={classes.header}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                flexWrap: "wrap"
              }}
            >
              <Typography className={classes.planTitle} variant="body1">
                {" "}

                Shared Fees
              </Typography>
              {/* {type &&
                type?.type?.map((item, index) => (
                  <Typography className={classes.luxuryText} variant="body1" key={index}>
                    {item.name}
                    {process.env.REACT_APP_ENV !== 'women_first' ? '( ' : ''}
                    {type.male && "Male"}
                    {type.male && type.fmale ? "&" : ""}
                    {type.fmale && process.env.REACT_APP_ENV !== 'women_first' && "Female"}
                    {process.env.REACT_APP_ENV !== 'women_first' ? ' )' : ''}
                  </Typography>
                ))} */}
            </div>
          </Box>
        </Paper>
        <Box className={classes.costContainer} >
          <Box className={classes.costDetails} sx={{ minWidth: '45%' }}  >
            <Box display={'flex'}>
              <Typography className={classes.costText}>Waiting charge time starts after:</Typography>
              <Typography className={classes.costValue}>
                {type?.waiting_time} Min
              </Typography>
              <Divider orientation="vertical" flexItem sx={{ height: '100%', width: '5px', color: '#BCC2C8', mr: 1 }} />
            </Box>
            <Box display={'flex'}>
              <Typography className={classes.costText}>Cost per Minute:</Typography>
              <Typography className={classes.costValue}>
                ${type?.waiting_time_cost}
              </Typography>
            </Box>

          </Box>


          <Box className={classes.costDetails} sx={{ width: '45%' }} >
            <Box display={'flex'}>
              <Typography className={classes.costText}>Company percentage:</Typography>
              <Typography className={classes.costValue}>
                {type?.company_percentage * 100}%
              </Typography>
            </Box>
          </Box>
        </Box>
        {/* {type?.plans.map((plan, planIndex) => (
          <React.Fragment key={planIndex}>
            <Box className={classes.distanceContainer}>
              <Box className={classes.distanceText} />
              <Typography>Distance {planIndex + 1}</Typography>
            </Box>
            <Box className={classes.distanceValueContainer}>
              <Typography className={classes.distanceLabel}>Distance:</Typography>
              <Box className={classes.distanceValue}>
                <Typography className={classes.distanceValueText}>
                  {plan?.start_distance} - {plan?.end_distance === 9999 ? <FaInfinity style={{ marginLeft: 3 }} size={18} fontWeight={900} /> : plan?.end_distance}
                </Typography>
                {plan.speeds.map((speed, speedIndex) => (
                  <Box className={classes.costPerMile} key={speedIndex}>
                    <Typography className={classes.costPerMinute}>
                      <span style={{ fontSize: 14 }}>  ${speed.cost_per_mil}/mi , </span>
                    </Typography>
                    <Typography className={classes.costPerMinute}>
                      <span style={{ fontSize: 14 }}>${speed.cost_per_min}/</span>min
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          </React.Fragment>
        ))} */}
      </Container>
      <Container className={classes.container}>
        <Paper className={classes.paper} sx={{ display: 'flex', justifyContent: 'space-between' }} >
          <Box className={classes.header} style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }} >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                flexWrap: "wrap"
              }}
            >
              <Typography className={classes.planTitle} variant="body1">
                {" "}

                Basic Pricing
              </Typography>
              {/* {type &&
                type?.type?.map((item, index) => (
                  <Typography className={classes.luxuryText} variant="body1" key={index}>
                    {item.name}
                    {process.env.REACT_APP_ENV !== 'women_first' ? '( ' : ''}
                    {type.male && "Male"}
                    {type.male && type.fmale ? "&" : ""}
                    {type.fmale && process.env.REACT_APP_ENV !== 'women_first' && "Female"}
                    {process.env.REACT_APP_ENV !== 'women_first' ? ' )' : ''}
                  </Typography>
                ))} */}
            </div>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1, border: `1px solid ${colorConfigs.sidebar.bg}`, borderRadius: 25 }}>
              <Typography style={{ fontSize: 14, fontWeight: 600, color: colorConfigs?.sidebar?.bg, marginLeft: 10 }}>
                Default Pricing
              </Typography>
              <Switch
                checked={valueWithMode.car_type[index]?.basicDefault}
                onChange={() => {
                  if (valueWithMode.car_type[index]?.flatDefault || type?.flatRate?.length > 0) {
                    let updateData = valueWithMode;
                    // Ensure updateData is mutable by creating a deep clone
                    updateData = JSON.parse(JSON.stringify(updateData));

                    if (updateData?.car_type?.[index]) {
                      if (updateData.car_type[index].basicDefault === true) {
                        updateData.car_type[index].basicDefault = false;
                        updateData.car_type[index].flatDefault = true;
                      }
                      else {
                        updateData.car_type[index].basicDefault = true;
                        updateData.car_type[index].flatDefault = false;
                      }
                    }
                    dispatch(updateForm(
                      updateData
                    ))
                    dispatch(updateFormData(
                      updateData
                    ))
                  }

                }
                }
                className="custom-switch"
              />
            </Box>
          </Box>
        </Paper>
        <Box className={classes.costContainer} >
          <Box className={classes.costDetails} sx={{ width: '45%' }} >
            <Box display={'flex'}>
              <Typography className={classes.costText}>Cost per Min:</Typography>
              <Typography className={classes.costValue}>
                ${type?.basicFlatRate?.cost_per_min}
              </Typography>
            </Box>

            <Box display={'flex'}>
              <Divider orientation="vertical" flexItem sx={{ height: '100%', width: '5px', color: '#BCC2C8', mx: 4 }} />
              <Typography className={classes.costText}>Cost Per KM:</Typography>
              <Typography className={classes.costValue}>
                ${type?.basicFlatRate?.cost_per_distance}
              </Typography>
            </Box>

          </Box>
          <Box className={classes.costDetails} sx={{ width: '45%' }} >
            <Box display={'flex'}>
              <Typography className={classes.costText}>Base Cost:</Typography>
              <Typography className={classes.costValue}>
                ${type?.base_cost}
              </Typography>
            </Box>
            <Box display={'flex'}>
              <Divider orientation="vertical" flexItem sx={{ height: '100%', width: '5px', color: '#BCC2C8', mx: 4 }} />
              <Typography className={classes.costText}>Min Cost:</Typography>
              <Typography className={classes.costValue}>
                ${type?.min_cost}
              </Typography>
            </Box>
            <Box display={'flex'}>
              <Divider orientation="vertical" flexItem sx={{ height: '100%', width: '5px', color: '#BCC2C8', mx: 4 }} />
              <Typography className={classes.costText}>Service :</Typography>
              <Typography className={classes.costValue}>
                {type?.service?.type === 'amount' ? `$${type?.service?.tax}` : `${type?.service?.tax}%`}
              </Typography>
            </Box>

          </Box>

        </Box>
        {/* {type?.plans.map((plan, planIndex) => (
          <React.Fragment key={planIndex}>
            <Box className={classes.distanceContainer}>
              <Box className={classes.distanceText} />
              <Typography>Distance {planIndex + 1}</Typography>
            </Box>
            <Box className={classes.distanceValueContainer}>
              <Typography className={classes.distanceLabel}>Distance:</Typography>
              <Box className={classes.distanceValue}>
                <Typography className={classes.distanceValueText}>
                  {plan?.start_distance} - {plan?.end_distance === 9999 ? <FaInfinity style={{ marginLeft: 3 }} size={18} fontWeight={900} /> : plan?.end_distance}
                </Typography>
                {plan.speeds.map((speed, speedIndex) => (
                  <Box className={classes.costPerMile} key={speedIndex}>
                    <Typography className={classes.costPerMinute}>
                      <span style={{ fontSize: 14 }}>  ${speed.cost_per_mil}/mi , </span>
                    </Typography>
                    <Typography className={classes.costPerMinute}>
                      <span style={{ fontSize: 14 }}>${speed.cost_per_min}/</span>min
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          </React.Fragment>
        ))} */}
      </Container>
      {type?.flatRate?.length > 0 && <Container className={classes.container}>
        <Paper className={classes.paper}>
          <Box className={classes.header} style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                flexWrap: "wrap"
              }}
            >
              <Typography className={classes.planTitle} variant="body1">
                {" "}

                Flat Rate Pricing
              </Typography>
              {/* {type &&
                type?.type?.map((item, index) => (
                  <Typography className={classes.luxuryText} variant="body1" key={index}>
                    {item.name}
                    {process.env.REACT_APP_ENV !== 'women_first' ? '( ' : ''}
                    {type.male && "Male"}
                    {type.male && type.fmale ? "&" : ""}
                    {type.fmale && process.env.REACT_APP_ENV !== 'women_first' && "Female"}
                    {process.env.REACT_APP_ENV !== 'women_first' ? ' )' : ''}
                  </Typography>
                ))} */}
            </div>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1, border: `1px solid ${colorConfigs.sidebar.bg}`, borderRadius: 25 }}>
              <Typography style={{ fontSize: 14, fontWeight: 600, color: colorConfigs?.sidebar?.bg, marginLeft: 10 }}>
                Default Pricing
              </Typography>
              <Switch
                checked={valueWithMode.car_type[index]?.flatDefault}
                onChange={() => {
                  let updateData = valueWithMode;

                  // Ensure updateData is mutable by creating a deep clone
                  updateData = JSON.parse(JSON.stringify(updateData));

                  if (updateData?.car_type?.[index]) {
                    if (updateData.car_type[index].flatDefault === true) {
                      updateData.car_type[index].flatDefault = false;
                      updateData.car_type[index].basicDefault = true;
                    }
                    else {
                      updateData.car_type[index].flatDefault = true;
                      updateData.car_type[index].basicDefault = false;
                    }
                  }
                  dispatch(updateForm(
                    updateData
                  ))
                  dispatch(updateFormData(
                    updateData
                  ))
                }
                }
                className="custom-switch"
              />
            </Box>
          </Box>

        </Paper>


        <Box className={classes.costContainer} >
          <TableContainer className={classes.tableContainer}>
            <Table sx={{ borderCollapse: 'separate', borderSpacing: '4px' }}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.headerCell}>Mileage (kM)</TableCell>
                  {type?.flatRate?.map((row, index) => (
                    <TableCell
                      key={index}
                      className={index % 2 === 0 ? classes.evenCell : classes.oddCell}
                    >
                      {row.distance}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.headerCell}>Rate</TableCell>
                  {type?.flatRate?.map((row, index) => (
                    <TableCell
                      key={index}
                      className={index % 2 === 0 ? classes.evenCell : classes.oddCell}
                    >
                      ${row.cost}
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Container>
      }
    </>
  );
}

export default PlanCard;
