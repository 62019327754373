import React, { useEffect } from 'react'
import CardsSections from './component/CardsSections'
import { Box } from '@mui/material'
import StatisticsSection from './component/StatisticsSection'
import { useSelector } from 'react-redux';

function BookingHistory() {
    return (
        <Box sx={{ position: 'relative', minHeight: '100vh' }}>
            <CardsSections />
            <StatisticsSection/>
        </Box>
    )
}

export default BookingHistory