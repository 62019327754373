function formatDate(date, countryCode, includeTime = true) {
  if (date !== null && date !== undefined) {
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };

    let locale = "en";
    if (countryCode === "US" || countryCode === "CA") {
      locale = "en-US";
    } else {
      locale = "ar";
    }

    const formattedDate = new Date(date).toLocaleDateString(locale, options);

    // Extract parts and reformat to YYYY-MM-DD
    const year = formattedDate.slice(6, 10);
    const month = formattedDate.slice(0, 2);
    const day = formattedDate.slice(3, 5);

    let dateWithoutTime;
    if (countryCode === 'CA') {
      dateWithoutTime = `${year}-${month}-${day}`;
    } else {
      dateWithoutTime = `${month}-${day}-${year}`;
    }

    if (includeTime) {
      const timeOptions = {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true, // Use 12-hour time format
        hourCycle: 'h12' // Ensure 12-hour clock
      };

      const formattedTime = new Date(date).toLocaleTimeString(locale, timeOptions);

      const hours = formattedTime.slice(0, 2);
      const minutes = formattedTime.slice(3, 5);
      const ampm = formattedTime.slice(-2); // Extract AM/PM part

      return (
        <span>
          <span style={{ fontWeight: "bold" }}>{hours}:{minutes} {ampm}</span>  {dateWithoutTime}
        </span>
      );
    } else {
      return dateWithoutTime;
    }
  }

  return null;
}

export { formatDate };
