import { useSelector } from "react-redux";
import { NewFormatPhoneNumber } from "./localization/NewPhoneUtils";

const ClickablePhoneNumber = (countryKey, phoneNumber) => {
    const countryCode = process.env.REACT_APP_ENV === 'women_first' ? 'CA' : 'US'

    // Create a clickable link
    return <a style={{ cursor: 'pointer', textDecoration: 'none', color: 'black' }} href={`tel:${countryKey + phoneNumber}`}>
        {NewFormatPhoneNumber(`${countryKey + phoneNumber}`)}
    </a>;
}

export default ClickablePhoneNumber