import React, { useState } from 'react';
import {
    Radio,
    RadioGroup,
    FormControlLabel,
    TextField,
    Button,
    Typography,
    IconButton,
    CircularProgress,
    Snackbar,
    Alert
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import colorConfigs from '../../configs/colorConfigs';
import { putMethod } from './../Hook/UseCustomApi';
import { useDispatch } from 'react-redux';
import { closeModal } from '../../redux/features/modalSlice';

function CancelRideModal({ handleClose, ride_id, afterSubmit }) {
    const [selectedReason, setSelectedReason] = useState('');
    const [otherReason, setOtherReason] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);  // Snackbar state
    const dispatch = useDispatch();

    const handleReasonChange = (event) => {
        setSelectedReason(event.target.value);
        setErrorMessage('');
    };

    const handleOtherReasonChange = (event) => {
        setOtherReason(event.target.value);
    };

    const handleSubmit = async () => {
        if (!selectedReason) {
            setErrorMessage('Please select a reason for cancellation.');
            return;
        }

        if (selectedReason === 'Other' && !otherReason.trim()) {
            setErrorMessage('Please provide a reason in the "Other" field.');
            return;
        }

        const finalReason = selectedReason === 'Other' ? otherReason : selectedReason;

        try {
            setIsLoading(true);
            await putMethod({
                url: `ride/cancel/adminCancel/${ride_id}`,
                body: {
                    reason: finalReason,
                }
            });
            afterSubmit()
            dispatch(closeModal());
            setSnackbarOpen(true);  // Show success alert
        } catch (error) {
            setErrorMessage('An error occurred while canceling the ride.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);  // Close the Snackbar
    };

    return (
        <div style={styles.container}>
            <div style={styles.header}>
                <Typography variant="h6" sx={{ fontWeight: '700', color: '#172B4D' }}>
                    Cancel Ride
                </Typography>
                <IconButton onClick={handleClose} style={styles.closeButton}>
                    <CloseIcon />
                </IconButton>
            </div>
            <div style={styles.content}>
                <Typography style={styles.questionText}>
                    Why do you want to cancel this Ride?
                </Typography>
                <RadioGroup value={selectedReason} onChange={handleReasonChange}>
                    <FormControlLabel
                        value="Passenger requested cancellation"
                        control={<Radio />}
                        label="Passenger requested cancellation"
                        sx={styles.radioOption}
                    />
                    <FormControlLabel
                        value="Technical issues with the platform"
                        control={<Radio />}
                        label="Technical issues with the platform"
                        sx={styles.radioOption}
                    />
                    <FormControlLabel
                        value="Driver delayed for too long"
                        control={<Radio />}
                        label="Driver delayed for too long"
                        sx={styles.radioOption}
                    />
                    <FormControlLabel
                        value="Payment issues"
                        control={<Radio />}
                        label="Payment issues"
                        sx={styles.radioOption}
                    />
                    <FormControlLabel
                        value="Incorrect ride details"
                        control={<Radio />}
                        label="Incorrect ride details"
                        sx={styles.radioOption}
                    />
                    <FormControlLabel
                        value="Duplicate booking"
                        control={<Radio />}
                        label="Duplicate booking"
                        sx={styles.radioOption}
                    />
                    <FormControlLabel
                        value="Driver is unavailable"
                        control={<Radio />}
                        label="Driver is unavailable"
                        sx={styles.radioOption}
                    />
                    <FormControlLabel
                        value="Other"
                        control={<Radio />}
                        label="Other Reason"
                        sx={styles.radioOption}
                    />
                </RadioGroup>
                {selectedReason === 'Other' && (
                    <TextField
                        fullWidth
                        placeholder="Please provide a reason..."
                        value={otherReason}
                        onChange={handleOtherReasonChange}
                        multiline
                        rows={2}
                        variant="outlined"
                        style={styles.textField}
                        error={!otherReason.trim() && !!errorMessage}
                    />
                )}
                {errorMessage && (
                    <Typography style={styles.errorText}>
                        {errorMessage}
                    </Typography>
                )}
            </div>
            <div style={styles.actions}>
                <Button
                    onClick={handleSubmit}
                    style={styles.confirmButton}
                    disabled={isLoading}
                >
                    {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Confirm Cancelation'}
                </Button>
            </div>

            {/* Snackbar for Success Alert */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'rigth' }}
            >
                <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                    Ride has been successfully canceled!
                </Alert>
            </Snackbar>
        </div>
    );
}

const styles = {
    container: {
        backgroundColor: '#fff',
        borderRadius: '8px',
        width: '675px',
        minHeight: '400px',
        padding: '20px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        margin: '20px auto',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '25px',
    },
    closeButton: {
        position: 'absolute',
        right: '8px',
        top: '30px',
    },
    content: {
        marginBottom: '16px',
        color: '#030919',
        fontWeight: 600,
        fontSize: '14px',
    },
    questionText: {
        fontWeight: 'bold',
        marginBottom: '5px',
    },
    radioOption: {
        margin: 0,
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'row-reverse',
        width: '100%',
        fontWeight: '400',
        color: ' #74787D',
        fontSize: '14px',
    },
    textField: {
        backgroundColor: '#f9f9f9',
        borderRadius: '4px',
        marginBottom: '10px',
    },
    actions: {
        display: 'flex',
        justifyContent: 'start',
    },
    confirmButton: {
        backgroundColor: colorConfigs?.sidebar?.bg,
        padding: '10px 24px',
        textTransform: 'none',
        borderRadius: '15px',
        fontWeight: '600',
        fontSize: '14px',
        color: '#FFF',
    },
    errorText: {
        color: 'red',
        fontSize: '12px',
        marginTop: '8px',
    },
};

export default CancelRideModal;
