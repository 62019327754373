import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import { Alert, Box, Snackbar, Typography } from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import icon from "../../assets/images/Icon.png";
import location from "../../assets/images/location.png";
import taxi from "../../assets/taxi-booking.png";
import colorConfigs from "../../configs/colorConfigs";
import TextRating from "../../pages/rating/rate";
import Error5xx from "../../components/common/HandelError5xx";
import { NewFormatPhoneNumber } from "../../utils/localization/NewPhoneUtils";
import taxi1 from "../../assets/taxiwomen.png";
import stopIcon from "../../assets/images/stop.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { formatCurrency } from "../../utils/localization/currencyUtils";

export default function RideDetails({ onClose, activeRide }) {
  const [valueTabs, setValueTabs] = useState("one");
  const [ride] = useState(activeRide);
  const countryCode = useSelector((state) => state.country.selectedCountry);
  const [showAll, setShowAll] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };
  const handleChange = (e, newValue) => {
    setValueTabs(newValue);
  };
  const [tryAgain, setTryAgain] = useState();
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [errorCount, setErrorCount] = useState(0);
  const [errorDialogCounter, setErrorDialogCounter] = useState(0);

  const handleError5xx = () => {
    setIsErrorDialogOpen(true);
    setErrorCount((prevCount) => {
      const newCount = prevCount + 1;
      if (newCount === 1) {
        setErrorDialogCounter(5);
      } else if (newCount === 2) {
        setErrorDialogCounter(10);
      }
      return newCount;
    });
  };

  const handleCloseErrorDialog = () => {
    setIsErrorDialogOpen(false);
    if (errorCount <= 2) {
      handleErrorCountByClose();
    }
  };

  const handleErrorCountByClose = () => {
    setErrorCount((prevCount) => {
      const newCount = prevCount - 1;
      if (newCount === 1) {
        setErrorDialogCounter(5);
      } else if (newCount === 2) {
        setErrorDialogCounter(10);
      }
      return newCount;
    });
  };

  const formatWaitingTime = (ride) => {
    const assignedAt = new Date(ride.assigned_at);
    const arrivalPickupAt = new Date(ride.arrival_pickup_at);
    const timeDifferenceInMilliseconds = arrivalPickupAt - assignedAt;
    let minutes = timeDifferenceInMilliseconds < 0 ? 0 : Math.floor((timeDifferenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
    return `${minutes} min`;
  };

  return (
    <div
      style={{
        position: "absolute",
        bottom: "120px",
        right: "50px",
        backgroundColor: "white",
        padding: "10px",
        border: "1px solid #ccc",
        color: "black",
        width: "85%",
        minHeight: "250px",
        borderRadius: "12px",
        fontFamily: "sans-serif",
        overflowY: "auto",
      }}
    >
      <Error5xx open={isErrorDialogOpen} errCount={errorCount} count={errorDialogCounter} handleClose={handleCloseErrorDialog} tryAgain={tryAgain} />
      <Box sx={{ width: "100%", background: "#F0F0F0" }}>
        <Tabs
          value={valueTabs}
          onChange={handleChange}
          textColor="inherit"
          sx={{ color: colorConfigs?.sidebar?.bg }}
          TabIndicatorProps={{ style: { backgroundColor: colorConfigs?.sidebar?.bg } }}
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          variant="scrollable"
        >
          <Tab value="one" label="Trip Details" style={{ width: "150px", textTransform: "capitalize", fontWeight: "bold", fontFamily: "Poppins" }} />
          {ride?.status !== "new" && ride.status !== "pending" && (
            <Tab value="two" label="Driver" style={{ width: "150px", textTransform: "capitalize", fontWeight: "bold", fontFamily: "Poppins" }} />
          )}
          <Tab value="three" label="Passenger" style={{ width: "150px", textTransform: "capitalize", fontWeight: "bold", fontFamily: "Poppins" }} />
          {ride?.status !== "new" && ride.status !== "pending" && (
            <Tab value="four" label="Vehicle Details" style={{ width: "182px", textTransform: "capitalize", fontWeight: "bold", fontFamily: "Poppins" }} />
          )}
          <Tab value="five" label="Payment" style={{ width: "150px", textTransform: "capitalize", fontWeight: "bold", fontFamily: "Poppins" }} />
        </Tabs>
      </Box>

      {valueTabs === "one" && (
        <div>
          <div style={{ fontFamily: "Poppins", paddingTop: "12px" }}>
            <div style={{ alignItems: "center", display: "flex", padding: "5px" }}>
              <img src={location} alt="loc" />
              <Typography
                variant="body2"
                style={{
                  color: colorConfigs.sidebar.bg,
                  opacity: "0.8",
                  marginTop: "3px",
                  fontWeight: "bold",
                  fontFamily: "Poppins",
                  marginLeft: "10px",
                }}
              >
                Pick Up:
                <br />
                <Typography variant="caption" style={{ color: "black", opacity: "0.8", marginTop: "3px", fontFamily: "Poppins" }}>
                  {ride?.pickup_address?.address}
                </Typography>
              </Typography>
            </div>

            {ride?.stop_points?.length > 2 ? (
              <div style={{ alignItems: "center", display: "flex", padding: "5px", justifyContent: "space-between" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img src={stopIcon} alt="loc" style={{ height: "38px", width: "38px" }} />
                  <Typography
                    variant="body2"
                    style={{
                      color: colorConfigs.sidebar.bg,
                      opacity: "0.8",
                      marginTop: "3px",
                      fontWeight: "bold",
                      fontFamily: "Poppins",
                      marginLeft: "10px",
                    }}
                  >
                    Stop points: {ride?.stop_points?.length}
                  </Typography>
                </div>
                <div>
                  {!showAll ? (
                    <ExpandMoreIcon onClick={() => setShowAll(true)} style={{ fontSize: "28px", cursor: "pointer", color: colorConfigs.sidebar.bg }} />
                  ) : (
                    <ExpandLessIcon onClick={() => setShowAll(false)} style={{ fontSize: "28px", cursor: "pointer", color: colorConfigs.sidebar.bg }} />
                  )}
                </div>
              </div>
            ) : (
              ride?.stop_points?.map((stopPoint, index) => (
                <div key={index} style={{ alignItems: "center", display: "flex", padding: "5px" }}>
                  <img src={stopIcon} alt="loc" style={{ height: "38px", width: "38px" }} />
                  <Typography
                    variant="body2"
                    style={{
                      color: colorConfigs.sidebar.bg,
                      opacity: "0.8",
                      marginTop: "3px",
                      fontWeight: "bold",
                      fontFamily: "Poppins",
                      marginLeft: "10px",
                    }}
                  >
                    Stop point: {index + 1}
                    <br />
                    <Typography variant="caption" style={{ color: "black", opacity: "0.8", marginTop: "3px", fontFamily: "Poppins" }}>
                      {stopPoint?.address}
                    </Typography>
                  </Typography>
                </div>
              ))
            )}

            {showAll &&
              ride?.stop_points?.map((stopPoint, index) => (
                <div key={index} style={{ alignItems: "center", display: "flex", padding: "5px", marginLeft: "60px" }}>
                  <img src={stopIcon} alt="loc" style={{ height: "38px", width: "38px" }} />
                  <Typography
                    variant="body2"
                    style={{
                      color: colorConfigs.sidebar.bg,
                      opacity: "0.8",
                      marginTop: "3px",
                      fontWeight: "bold",
                      fontFamily: "Poppins",
                      marginLeft: "10px",
                    }}
                  >
                    Stop point: {index + 1}
                    <br />
                    <Typography variant="caption" style={{ color: "black", opacity: "0.8", marginTop: "3px", fontFamily: "Poppins" }}>
                      {stopPoint?.address}
                    </Typography>
                  </Typography>
                </div>
              ))}

            <div style={{ alignItems: "center", display: "flex", padding: "5px" }}>
              <img src={icon} alt="loc" />
              <Typography
                variant="body2"
                style={{
                  color: colorConfigs.sidebar.bg,
                  opacity: "0.8",
                  marginTop: "3px",
                  fontWeight: "bold",
                  fontFamily: "Poppins",
                  marginLeft: "10px",
                }}
              >
                Drop Off:
                <br />
                <Typography variant="caption" style={{ color: "black", opacity: "0.8", marginTop: "3px", fontFamily: "Poppins" }}>
                  {ride?.delivery_address?.address}
                </Typography>
              </Typography>
            </div>
          </div>

          <div
            style={{
              backgroundColor: "#F0F0F0",
              borderRadius: "8px",
              width: "100%",
              height: "50px",
              fontSize: "14px",
              marginTop: "14px",
              padding: "0 12px",
              display: "flex",
              alignItems: "center",
              gap: "12px",
            }}
          >
            <div style={{ flex: "1", color: "#7A869A" }}>
              <span>Trip No:</span>{" "}
              <span style={{ color: "#172B4D", fontWeight: "bold" }}>{ride?.short_id}</span>
            </div>
          </div>
        </div>
      )}

      {valueTabs === "two" && (
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "16px" }}>
            <div className="info" style={{ display: "flex", gap: "16px" }}>
              <div style={{ width: "70px", height: "70px", borderRadius: "50%" }}>
                <img style={{ width: "100%", height: "100%", borderRadius: "50%" }} src={ride?.driver_info?.profile_picture} alt="profile-picture" />
              </div>
              <div>
                <Typography style={{ textTransform: "capitalize", color: "#172B4D", fontFamily: "Poppins", fontSize: "14px", fontWeight: "700" }}>
                  {ride?.driver_info?.first_name + " " + ride?.driver_info?.last_name}
                </Typography>
                <div style={{ width: "50px" }}>
                  <TextRating value={+ride?.driver_info?.review_avg} />
                </div>
              </div>
            </div>
          </div>

          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", padding: "0 16px", marginLeft: "82px", gap: "16px" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <EmailIcon style={{ color: colorConfigs?.sidebar?.bg, marginRight: "12px" }} />
              <span>{ride?.driver_info?.email ? ride?.driver_info?.email : "-"}</span>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <PhoneIcon style={{ color: colorConfigs?.sidebar?.bg, marginRight: "12px" }} />
              <span>{ride?.driver_info?.phone_number ? NewFormatPhoneNumber(ride?.driver_info?.phone_number, countryCode) : "-"}</span>
            </div>
          </div>
        </div>
      )}

      {valueTabs === "three" && (
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "16px" }}>
            <div className="info" style={{ display: "flex", gap: "16px" }}>
              {ride?.customer_details?.profile_picture !== "" && (
                <div style={{ width: "70px", height: "70px", borderRadius: "50%" }}>
                  <img style={{ width: "100%", height: "100%", borderRadius: "50%" }} src={ride?.customer_details?.profile_picture} alt="profile-picture" />
                </div>
              )}
              <div>
                <Typography style={{ textTransform: "capitalize", color: "#172B4D", fontFamily: "Poppins", fontSize: "14px", fontWeight: "700" }}>
                  {ride?.customer_details?.first_name + " " + ride?.customer_details?.last_name}
                </Typography>
                <div style={{ width: "50px" }}>
                  <TextRating value={ride?.customer_details?.review_avg} />
                </div>
              </div>
            </div>
          </div>

          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", padding: "0 16px", marginLeft: "82px", gap: "16px" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <EmailIcon style={{ color: colorConfigs?.sidebar?.bg, marginRight: "12px" }} />
              <span>{ride?.customer_details?.email}</span>
            </div>
            <a href={`tel:${ride?.customer_details?.phone_number}`}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <PhoneIcon style={{ color: colorConfigs?.sidebar?.bg, marginRight: "12px" }} />
                <span>{NewFormatPhoneNumber(ride?.customer_details?.phone_number, countryCode)}</span>
              </div>
            </a>
          </div>
        </div>
      )}

      {valueTabs === "four" && (
        <div style={{ display: "flex", flexDirection: "column", gap: "12px", padding: "12px" }}>
          <div className="images" style={{ display: "flex", gap: "16px", marginTop: "16px" }}>
            {ride?.driver_info?.vehicle_info?.vehicle_images?.map((i) => (
              <div>
                <img style={{ width: "160px", height: "90px", borderRadius: "8px" }} src={i} alt="vehicle-image" />
              </div>
            ))}
          </div>
          <div className="info" style={{ display: "flex", flexDirection: "column", fontSize: "14px", gap: "4px" }}>
            <div style={{ display: "flex", columnGap: "8px" }}>
              <span style={{ flex: "1", color: "#7A869A", fontWeight: "500" }}>
                Vehicle No:{" "}
                <span style={{ color: "#172B4D", fontWeight: "600", textTransform: 'uppercase' }}>{ride?.driver_info?.vehicle_info?.license_plate}</span>
              </span>

              <span style={{ flex: "2", color: "#7A869A", fontWeight: "500" }}>
                Vehicle Make:{" "}
                <span style={{ color: "#172B4D", fontWeight: "600" }}>{ride?.driver_info?.vehicle_info?.vehicle_make}</span>
              </span>
            </div>
            <div style={{ display: "flex", columnGap: "16px" }}>
              <span style={{ flex: "1.5", color: "#7A869A", fontWeight: "500" }}>
                Vehicle Type:{" "}
                <span style={{ color: "#172B4D", fontWeight: "600" }}>{ride?.driver_info?.vehicle_info?.vehicle_type}</span>
              </span>

              <span style={{ flex: "2", color: "#7A869A", fontWeight: "500" }}>
                Vehicle Model:{" "}
                <span style={{ color: "#172B4D", fontWeight: "600" }}>{ride?.driver_info?.vehicle_info?.vehicle_model}</span>
              </span>

              <span style={{ flex: "1", color: "#7A869A", fontWeight: "500" }}>
                Year:{" "}
                <span style={{ color: "#172B4D", fontWeight: "600" }}>{ride?.driver_info?.vehicle_info?.vehicle_year}</span>
              </span>
            </div>
          </div>
        </div>
      )}

      {valueTabs === "five" && (
        <div style={{ padding: "0 16px", textAlign: "center" }}>
          <p
            style={{
              flex: "1",
              color: "#7A869A",
              fontWeight: "500",
              backgroundColor: "#F5F7F8",
              borderRadius: "8px",
              padding: "12px",
            }}
          >
            Estimated Fare:{" "}
            <span style={{ color: "#172B4D", fontWeight: "600" }}>
              {formatCurrency(ride?.pay_info?.total ? ride?.pay_info?.total : ride?.estimation_cost, countryCode)}
            </span>
          </p>
          <p
            style={{
              flex: "1",
              color: "#7A869A",
              fontWeight: "500",
              backgroundColor: "#F5F7F8",
              borderRadius: "8px",
              padding: "12px",
            }}
          >
            Payment Type:{" "}
            <span style={{ color: "#172B4D", fontWeight: "600" }}>{ride?.pay_type === 'cash' ? 'Cash' : 'Card'}</span>
          </p>
          <p
            style={{
              flex: "1",
              color: "#7A869A",
              fontWeight: "500",
              backgroundColor: "#F5F7F8",
              borderRadius: "8px",
              padding: "12px",
            }}
          >
            Payment Status:{" "}
            <span style={{ color: "#172B4D", fontWeight: "600" }}>{ride?.payment_status}</span>
          </p>
        </div>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleSnackbarClose} severity="error">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
