import SearchIcon from "@mui/icons-material/Search";
import { Box, Divider, InputAdornment, TextField } from "@mui/material";
import { GoogleApiWrapper, InfoWindow, Map, Marker } from "google-maps-react";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import car from "../../../src/assets/car.jpg";
import enRouteCar from '../../assets/mapCars/En route.png';
import waitingPickCar from '../../assets/mapCars/Waiting Pickup.png';
import busyCar from '../../assets/mapCars/busy.png';
import freeCar from '../../assets/mapCars/free.png';
import colorConfigs from "../../configs/colorConfigs";
import { db } from "../../configs/firebase";
import { selectChat, setLastSupportChat } from "../../redux/features/appStateSlice";
import { GOOGLE_API_KEY, mapStyles } from "../../utils/constant";
import DirversCard from "../common/DriversCard/DriversCard";
import { getMethod } from './../../pages/Hook/UseCustomApi';
import TripInfoDialog from "./TripDialog";


const MapDrivers = (props) => {
  const [drivers, setDrivers] = useState([]);
  const [query, setQuery] = useState("");
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [state, setState] = useState({
    showingDriverInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},
    showingTripInfoDialog: false,
  });
  const [mapCenter, setMapCenter] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(null);
  const dispatch = useDispatch();
  const trafficLayerRef = useRef(null);  // To store traffic layer
  const [showTraffic, setShowTraffic] = useState(
    localStorage.getItem("showTraffic") === "true"
  );  // Traffic state
  const [mapTypeId, setMapTypeId] = useState(
    localStorage.getItem("mapTypeId") || "hybrid"
  );  // Map type state
  const mapRef = useRef(null);


  const createTrafficControl = (map) => {
    const controlDiv = document.createElement("div");
    controlDiv.style.backgroundColor = "#fff";
    controlDiv.style.color = localStorage.getItem("showTraffic") === "true" ? "rgb(25,25,25)" : "rgb(86, 86, 86)";
    controlDiv.style.borderRadius = "3px";
    controlDiv.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
    controlDiv.style.cursor = "pointer";
    controlDiv.style.marginTop = "10px";
    controlDiv.style.marginBottom = "22px";
    controlDiv.style.textAlign = "center";
    controlDiv.style.height = "40px";
    controlDiv.style.marginLeft = "-12px";

    const controlText = document.createElement("div");
    // controlText.style.color = "rgb(25,25,25)";
    controlText.style.fontFamily = "poppins";
    controlText.style.fontSize = "15px";
    controlText.style.lineHeight = "38px";
    controlText.style.fontWeight = localStorage.getItem("showTraffic") === "true" ? "600" : "500";
    controlText.style.paddingLeft = "5px";
    controlText.style.paddingRight = "5px";
    controlText.innerHTML = "Traffic Mode";
    controlDiv.appendChild(controlText);

    controlDiv.addEventListener('mouseover', () => {
      controlDiv.style.backgroundColor = "#f0f0f0";
      controlDiv.style.color = "rgb(25,25,25)";
    });

    controlDiv.addEventListener('mouseout', () => {
      controlDiv.style.backgroundColor = "#fff";
      controlDiv.style.color = "rgb(86, 86, 86)";
    });

    controlDiv.addEventListener("click", () => {
      setShowTraffic((prevShowTraffic) => {
        const newShowTraffic = !prevShowTraffic;
        if (newShowTraffic) {
          controlDiv.style.color = "rgb(25,25,25)";
          controlText.style.fontWeight = "600"
          trafficLayerRef.current.setMap(map); // Show traffic layer
        } else {
          controlDiv.style.color = "rgb(86, 86, 86)";
          controlText.style.fontWeight = "500"
          trafficLayerRef.current.setMap(null); // Remove traffic layer
        }
        localStorage.setItem("showTraffic", newShowTraffic);
        return newShowTraffic;
      });
    });

    return controlDiv;
  };



  const fetchDrivers = async () => {
    try {
      const response = await getMethod({ url: 'driver?activeDrivers=true' });
      const result = response?.data || [];

      // Initialize drivers without locations
      const driversWithEmptyLocations = result.map(driver => ({
        ...driver,
        location: null,
      }));
      setDrivers(driversWithEmptyLocations);

      // Set up real-time location updates
      const unsubscribe = db.collection('driver_dispatch')
        .onSnapshot(snapshot => {
          snapshot.docChanges().forEach(change => {
            const locationData = change.doc.data().location;
            if (change.type === "added" || change.type === "modified") {
              setDrivers(prevDrivers =>
                prevDrivers.map(driver =>
                  driver._id === change.doc.id
                    ? { ...driver, location: locationData, status: change.doc.data()?.status }
                    : driver
                )
              );
            }
          });
        });

      return () => unsubscribe(); // Cleanup listener on unmount
    } catch (error) {
      console.error("Error fetching drivers:", error);
    }
  };

  const fetchCenterPoint = () => {
    getMethod({
      url: 'driver/dispatch-view/center-point'
    })
      .then((response) => {
        const result = response?.center;
        if (result?.lat && result?.lng) {
          setMapCenter({
            lat: result?.lat,
            lng: result?.lng,
          });
        } else {
          navigator.geolocation.getCurrentPosition((position) => {
            setCurrentLocation({
              lat: position?.coords?.latitude,
              lng: position?.coords?.longitude,
            });
            setMapCenter({
              lat: position?.coords?.latitude,
              lng: position?.coords?.longitude,
            });
          });
        }
      })
      .catch((error) => {
        console.log(`Error fetching center point:`, error);
        navigator.geolocation.getCurrentPosition((position) => {
          setCurrentLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          setMapCenter({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        });
      });
  };

  useEffect(() => {
    fetchDrivers();
    fetchCenterPoint();
  }, []);

  const onMarkerClick = (driver, marker) => {
    setState({
      selectedPlace: {
        current_driver: driver,
        name: driver.first_name + " " + driver.last_name,
        status: driver.status,
        profile_picture: driver.profile_picture,
      },
      activeMarker: marker,
      showingDriverInfoWindow: true,
      showingTripInfoDialog: true,
    });
    dispatch(selectChat({ ...driver, driver: `admin_${driver?._id}`, type: "driver" }));
    dispatch(setLastSupportChat({ ...driver, driver: `admin_${driver?._id}`, type: "driver" }));

    setSelectedDriver(driver?._id);
  };

  const handleCenter = (driver) => {
    setSelectedDriver(driver._id);
    setMapCenter(driver?.location)
  }

  const handleDriverClick = (driver) => {
    setSelectedDriver(driver._id);
    setSelectedUser(driver);
    setState({
      showingDriverInfoWindow: true,
      selectedPlace: {
        current_driver: driver,
        name: driver.first_name + " " + driver.last_name,
        status: driver.status,
        profile_picture: driver.profile_picture,
      },
    });
  };

  const closeInfoWindowAndDialog = () => {
    setState({
      showingDriverInfoWindow: false,
      showingTripInfoDialog: false,
    });
    setSelectedUser(null);
  };
  useEffect(() => {
    if (trafficLayerRef.current && mapRef.current) {
      if (showTraffic) {
        trafficLayerRef.current.setMap(mapRef.current); // Show traffic layer if true
      } else {
        trafficLayerRef.current.setMap(null); // Hide traffic layer if false
      }
    }
  }, [showTraffic]);
  return (
    <Box sx={{ display: "flex", flexDirection: "column", position: "relative", height: "100vh", p: 2 }}>
      <div style={{ display: "flex", justifyContent: "space-between", height: "100vh" }}>
        <div style={{ flex: 0.8, paddingRight: "20px", height: "100vh", maxHeight: "100vh", overflowY: "none" }}>
          <div style={{ display: "flex", justifyContent: "center", gap: "14px", marginBottom: "14px", width: "100%" }}>
            <TextField
              fullWidth
              label="Search For Drivers"
              name="search"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              placeholder="Search By Name"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: "gray" }} />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              sx={{
                "& label.Mui-focused": { color: "rgba(0, 0, 0, 0.6)" },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#A0AEC0",
                  },
                  borderRadius: "10px",
                  height: "52px",
                },
              }}
            />
          </div>
          <div style={{ overflowY: "auto", height: "90vh" }}>
            {drivers.length > 0 ? (
              drivers.map((driver) => (
                <div
                  key={driver._id}
                  style={{
                    // border: driver._id === selectedDriver ? `1px solid ${colorConfigs?.sidebar.bg}` : "none",
                  }}
                >
                  <DirversCard driver={driver} selectedDriver={selectedDriver} handleCenter={() => handleCenter(driver)} onDriverClick={() => handleDriverClick(driver)} />
                  <Divider />
                </div>
              ))
            ) : (
              <p style={{ textAlign: "center", fontFamily: "Poppins" }}>No Result!</p>
            )}
          </div>
        </div>
        <div style={{ flex: 1.2, height: "70vh", position: "relative" }}>
          <Map
            google={props.google}
            zoom={10}
            minZoom={1}
            center={mapCenter || {
              lat: drivers[0]?.location?.lat,
              lng: drivers[0]?.location?.lng,
            }}
            styles={mapStyles}
            mapTypeId={mapTypeId} // Add this line to set the map type
            containerStyle={{
              position: 'absolute',
              width: '100%',
              height: '100%',
            }}
            onReady={(mapProps, map) => {
              mapRef.current = map; // Assign map instance to mapRef
              map.setMapTypeId(mapTypeId);
              trafficLayerRef.current = new window.google.maps.TrafficLayer();
              if (showTraffic) {
                trafficLayerRef.current.setMap(map); // Show traffic layer if true
              }
              map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(
                createTrafficControl(map) // Add traffic control
              );
              window.google.maps.event.addListener(map, "maptypeid_changed", () => {
                const newMapTypeId = map.getMapTypeId();
                setMapTypeId(newMapTypeId);
                localStorage.setItem("mapTypeId", newMapTypeId);
              });
            }}
          >
            {drivers.map((driver) => (
              <Marker
                key={driver._id}
                position={driver.location}
                onClick={(props, marker) => onMarkerClick(driver, marker)}
                name={driver.first_name + " " + driver.last_name}
                status={driver.status}
                icon={{
                  url: carIcons[driver.status],
                  scaledSize: new window.google.maps.Size(22, 40),
                }}
              />
            ))}

            <InfoWindow
              marker={state.activeMarker}
              visible={state?.showingDriverInfoWindow}
              onClose={closeInfoWindowAndDialog}
            >
              <div style={{ display: "flex", gap: "12px" }}>
                <img
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                  }}
                  src={state?.selectedPlace?.profile_picture}
                  alt="driver"
                />
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", padding: 0, margin: 0 }}>
                  <span style={{ fontSize: "14px", fontWeight: "600" }}>{state?.selectedPlace?.name}</span>
                  <div style={{ marginTop: "5px", display: "flex", alignItems: "center", gap: "5px" }}>
                    <StatusColor status={state?.selectedPlace?.status} />{" "}
                    <span>{state?.selectedPlace?.status}</span>
                  </div>
                </div>
              </div>
            </InfoWindow>

            {selectedUser && (
              <TripInfoDialog
                driver={selectedUser}
                onClose={() => {
                  setSelectedUser(null);
                  setState({
                    showingTripInfoDialog: false,
                    showingDriverInfoWindow: false,
                  });
                }}
              />
            )}
            {state.showingTripInfoDialog && (
              <TripInfoDialog
                driver={state?.selectedPlace?.current_driver}
                onClose={closeInfoWindowAndDialog}
              />
            )}
          </Map>
        </div>
      </div>

    </Box>
  );
};

export default GoogleApiWrapper({
  apiKey: `${GOOGLE_API_KEY}`,
})(MapDrivers);

function StatusColor({ status }) {
  let color;
  if (status === "available") color = "green";
  if (status === "onJob") color = "red";
  if (status === "offline") color = "darkGray";
  return (
    <div
      style={{
        backgroundColor: color,
        width: "10px",
        height: "10px",
        borderRadius: "50%",
      }}
    ></div>
  );
}
const carIcons = {
  "free": freeCar,
  "en-route": enRouteCar,
  "waiting-pickup": waitingPickCar,
  "busy": busyCar
};
