import {
    Box,
    Typography,
    styled,
    useTheme
} from "@mui/material";
import axios from 'axios';
import { GoogleApiWrapper, Map, Marker } from "google-maps-react";
import React, { useEffect, useRef, useState } from "react";
import { GrMenu } from "react-icons/gr";
import { IoMdClose } from "react-icons/io";
import { useDispatch } from "react-redux";

import DropImage from '../../assets/images/Icon.png';
import EnRoutePerson from '../../assets/images/en-route-person.png';
import pickupImage from '../../assets/images/location.png';
import waitingPerson from '../../assets/images/waiting-person.png';
import enRouteCar from '../../assets/mapCars/En route.png';
import waitingPickCar from '../../assets/mapCars/Waiting Pickup.png';
import stopIcon from "../../assets/images/stop.png";
import busyCar from '../../assets/mapCars/busy.png';
import freeCar from '../../assets/mapCars/free.png';
import BlueButton from "../../components/common/Buttons/BlueButton";
import { db } from "../../configs/firebase";
import { openModal } from "../../redux/features/modalSlice";
import { GOOGLE_API_KEY, mapStyles } from "../../utils/constant";
import { getMethod } from "../Hook/UseCustomApi";
import CustomerCard from "./components/CustomerCard";
import DriverDetailsModal from "./components/DriverDetailsModal";
import RideCard from "./components/RideCard";
import RideDetailsModal from "./components/RideDetailsModal";
import RideSidebar from './components/RideSidebar';
import './style.css';
import Error5xx from "../../components/common/HandelError5xx";

const carIcons = {
    "free": freeCar,
    "en-route": enRouteCar,
    "waiting-pickup": waitingPickCar,
    "busy": busyCar
};

const StatusContainer = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
}));

const StatusTitle = styled(Typography)(() => ({
    color: '#495974',
    fontSize: '12px',
    fontWeight: '600',
    textAlign: 'center',
}));

const StatusCount = styled(Typography)(() => ({
    fontSize: '16px',
    fontWeight: '700',
    textAlign: 'center',
}));

const calculateBearing = (startLat, startLng, endLat, endLng) => {
    const toRad = (angle) => angle * (Math.PI / 180);
    const toDeg = (angle) => angle * (180 / Math.PI);

    const startLatRad = toRad(startLat);
    const startLngRad = toRad(startLng);
    const endLatRad = toRad(endLat);
    const endLngRad = toRad(endLng);

    const dLng = endLngRad - startLngRad;
    const y = Math.sin(dLng) * Math.cos(endLatRad);
    const x = Math.cos(startLatRad) * Math.sin(endLatRad) -
        Math.sin(startLatRad) * Math.cos(endLatRad) * Math.cos(dLng);

    const bearing = toDeg(Math.atan2(y, x));
    return (bearing + 360) % 360;
};

const snapToRoad = async (path) => {
    const apiUrl = `https://roads.googleapis.com/v1/snapToRoads?path=${path}&interpolate=true&key=${GOOGLE_API_KEY}`;
    try {
        const response = await axios.get(apiUrl);
        return response?.data?.snappedPoints?.map(point => ({
            lat: point?.location?.latitude,
            lng: point?.location?.longitude
        }));
    } catch (error) {
        console.error("Error snapping to road", error);
        return [{ lat: path?.split(',')[0], lng: path?.split(',')[1] }];
    }
};

const status = ["busy", "en-route", "free", "waiting-pickup"];

const DispatcherView = (props) => {
    const theme = useTheme();
    const [drivers, setDrivers] = useState([]);
    const previousLocationsRef = useRef({});
    const [currentLocation, setCurrentLocation] = useState({ lat: 0, lng: 0 });
    const [selectedTab, setSelectedTab] = useState(0);
    const [selectedCompany, setSelectedCompany] = useState("");
    const [isRideContainerVisible, setIsRideContainerVisible] = useState(false);
    const [selectedRideCards, setSelectedRideCards] = useState({ 0: null, 1: null, 2: null });
    const [scheduledRides, setScheduledRides] = useState({ unassigned: false, assigned: false, active: false });
    const dispatch = useDispatch();
    const [rideData, setRideData] = useState({ unassigned: [], assigned: [], active: [] });
    const [rideDataCount, setRideDataCount] = useState({ unassigned: 0, assigned: 0, active: 0, dispatched: 0, canceled: 0 });
    const [countChanges, setCountChanges] = useState({ unassigned: false, assigned: false, active: false, dispatched: false, canceled: false });
    const [page, setPage] = useState({ unassigned: 1, assigned: 1, active: 1 });
    const [loading, setLoading] = useState({ unassigned: false, assigned: false, active: false });
    const [hasMore, setHasMore] = useState({ unassigned: true, assigned: true, active: true });
    const isInitialMount = useRef(true);
    const scrollContainerRef = useRef(null);
    const directionsServiceRef = useRef(null);
    const directionsRendererRef = useRef(null);
    const currentStatus = useRef(null);
    const DriverCurrentStatus = useRef(null);
    const [iconRotation, setIconRotation] = useState(0);
    const [pickupLocation, setPickupLocation] = useState(null);
    const [mapCenter, setMapCenter] = useState(currentLocation);
    const markersRef = useRef([]);
    const polylinesRef = useRef([]);
    const trafficLayerRef = useRef(null);  // To store traffic layer
    const [showTraffic, setShowTraffic] = useState(
        localStorage.getItem("showTraffic") === "true"
    );  // Traffic state
    const [mapTypeId, setMapTypeId] = useState(
        localStorage.getItem("mapTypeId") || "hybrid"
    );  // Map type state
    const mapRef = useRef(null);
    const [rideDataUpdateNeeded, setRideDataUpdateNeeded] = useState({
        unassigned: true,
        assigned: true,
        active: true,
    });

    const createTrafficControl = (map) => {
        const controlDiv = document.createElement("div");
        controlDiv.style.backgroundColor = "#fff";
        controlDiv.style.color = localStorage.getItem("showTraffic") === "true" ? "rgb(25,25,25)" : "rgb(86, 86, 86)";
        controlDiv.style.borderRadius = "3px";
        controlDiv.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
        controlDiv.style.cursor = "pointer";
        controlDiv.style.marginTop = "10px";
        controlDiv.style.marginBottom = "22px";
        controlDiv.style.textAlign = "center";
        controlDiv.style.height = "40px";
        controlDiv.style.marginLeft = "-12px";

        const controlText = document.createElement("div");
        // controlText.style.color = "rgb(25,25,25)";
        controlText.style.fontFamily = "poppins";
        controlText.style.fontSize = "15px";
        controlText.style.lineHeight = "38px";
        controlText.style.fontWeight = localStorage.getItem("showTraffic") === "true" ? "600" : "500";
        controlText.style.paddingLeft = "5px";
        controlText.style.paddingRight = "5px";
        controlText.innerHTML = "Traffic Mode";
        controlDiv.appendChild(controlText);

        controlDiv.addEventListener('mouseover', () => {
            controlDiv.style.backgroundColor = "#f0f0f0";
            controlDiv.style.color = "rgb(25,25,25)";
        });

        controlDiv.addEventListener('mouseout', () => {
            controlDiv.style.backgroundColor = "#fff";
            controlDiv.style.color = "rgb(86, 86, 86)";
        });

        controlDiv.addEventListener("click", () => {
            setShowTraffic((prevShowTraffic) => {
                const newShowTraffic = !prevShowTraffic;
                if (newShowTraffic) {
                    controlDiv.style.color = "rgb(25,25,25)";
                    controlText.style.fontWeight = "600"
                    trafficLayerRef.current.setMap(map); // Show traffic layer
                } else {
                    controlDiv.style.color = "rgb(86, 86, 86)";
                    controlText.style.fontWeight = "500"
                    trafficLayerRef.current.setMap(null); // Remove traffic layer
                }
                localStorage.setItem("showTraffic", newShowTraffic);
                return newShowTraffic;
            });
        });

        return controlDiv;
    };


    //handle error5xx
    const [tryAgain, setTryAgain] = useState();
    const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
    const [errorCount, setErrorCount] = useState(0);
    const [errorDialogCounter, setErrorDialogCounter] = useState(0);
    const handleError5xx = () => {
        setIsErrorDialogOpen(true);
        setErrorCount(prevCount => {
            const newCount = prevCount + 1;
            if (newCount === 1) {
                setErrorDialogCounter(5);
            } else if (newCount === 2) {
                setErrorDialogCounter(10);
            }
            return newCount;
        });
    };
    const handleCloseErrorDialog = () => {
        setIsErrorDialogOpen(false);
        if (errorCount <= 2) {
            handleErrorCountByClose()
        }

    };
    const handleErrorCountByClose = () => {
        setErrorCount(prevCount => {
            const newCount = prevCount - 1;
            if (newCount === 1) {
                setErrorDialogCounter(5);
            } else if (newCount === 2) {
                setErrorDialogCounter(10);
            }
            return newCount;
        });
    }

    useEffect(() => {
        directionsServiceRef.current = new window.google.maps.DirectionsService();
        directionsRendererRef.current = new window.google.maps.DirectionsRenderer();
        window.scrollTo({ top: 0 });
    }, []);

    const handleCompanyChange = (event) => {
        setSelectedCompany(event.target.value);
    };

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
        setRideDataUpdateNeeded(prev => ({
            ...prev,
            unassigned: newValue === 0 ? true : prev.unassigned,
            assigned: newValue === 1 ? true : prev.assigned,
            active: newValue === 2 ? true : prev.active,
        }));
        setSelectedRideCards({ 0: null, 1: null, 2: null });
        currentStatus.current = null;
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollTop = 0;
        }
        setPickupLocation(null);
        clearMarkersAndLines();
    };

    const handleClearFilters = () => {
        setSelectedRideCards({ 0: null, 1: null, 2: null });
        clearMarkersAndLines();
        setPickupLocation(null);
        getMethod({
            url: 'driver/dispatch-view/center-point'
        })
            .then((response) => {
                const result = response?.center;
                if (result?.lat && result?.lng) {
                    setMapCenter(process.env.REACT_APP_ENV === 'women_first' ? {
                        lat: 53.5461,
                        lng: -113.8112,
                    } : {
                        lat: result?.lat,
                        lng: result?.lng,
                    });
                } else {
                    navigator.geolocation.getCurrentPosition((position) => {
                        setCurrentLocation({
                            lat: position?.coords?.latitude,
                            lng: position?.coords?.longitude,
                        });
                        setMapCenter(process.env.REACT_APP_ENV === 'women_first' ? {
                            lat: 53.5461,
                            lng: -113.8112,
                        } : {
                            lat: position?.coords?.latitude,
                            lng: position?.coords?.longitude,
                        });
                    });
                }
            })
            .catch((error) => {
                console.log(`Error fetching center point:`, error);
                navigator.geolocation.getCurrentPosition((position) => {
                    setCurrentLocation({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    });
                    setMapCenter(process.env.REACT_APP_ENV === 'women_first' ? {
                        lat: 53.5461,
                        lng: -113.8112,
                    } : {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    });
                });
                if (error === "Internal Server Error: Server error occurred.") {
                    handleError5xx();
                    setTryAgain(() => handleClearFilters);
                }
            });
    };

    const toggleRideContainer = () => {
        setIsRideContainerVisible(prev => !prev);
    };

    useEffect(() => {
        getMethod({
            url: 'driver/dispatch-view/center-point'
        })
            .then((response) => {
                const result = response?.center;
                if (result?.lat && result?.lng) {
                    setMapCenter(process.env.REACT_APP_ENV === 'women_first' ? {
                        lat: 53.5461,
                        lng: -113.8112,
                    } : {
                        lat: result?.lat,
                        lng: result?.lng,
                    });
                } else {
                    navigator.geolocation.getCurrentPosition((position) => {
                        setCurrentLocation({
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        });
                        setMapCenter(process.env.REACT_APP_ENV === 'women_first' ? {
                            lat: 53.5461,
                            lng: -113.8112,
                        } : {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        });
                    });
                }
            })
            .catch((error) => {
                console.log(`Error fetching center point:`, error);
                navigator.geolocation.getCurrentPosition((position) => {
                    setCurrentLocation({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    });
                    setMapCenter(process.env.REACT_APP_ENV === 'women_first' ? {
                        lat: 53.5461,
                        lng: -113.8112,
                    } : {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    });
                });
                if (error === "Internal Server Error: Server error occurred.") {
                    handleError5xx();
                    setTryAgain(() => toggleRideContainer);
                }
            });
    }, []);

    // Fetch new rides when scheduledRides for a tab changes
    useEffect(() => {
        fetchRidesForTab(selectedTab);
    }, [scheduledRides]);

    useEffect(() => {
        if (rideDataUpdateNeeded.unassigned && selectedTab === 0) {
            fetchRidesForTab(0);
            setRideDataUpdateNeeded(prev => ({ ...prev, unassigned: false }));
            setCountChanges(prev => ({
                ...prev,
                unassigned: false
            }));
        }
        if (rideDataUpdateNeeded.assigned && selectedTab === 1) {
            fetchRidesForTab(1);
            setRideDataUpdateNeeded(prev => ({ ...prev, assigned: false }));
            setCountChanges(prev => ({
                ...prev,
                assigned: false
            }));
        }
        if (rideDataUpdateNeeded.active && selectedTab === 2) {
            fetchRidesForTab(2);
            setRideDataUpdateNeeded(prev => ({ ...prev, active: false }));
            setCountChanges(prev => ({
                ...prev,
                active: false
            }));
        }
    }, [selectedTab, rideDataUpdateNeeded]);

    const changeScheduledRides = (tab) => {
        console.log(tab)
        const tabName = tab === 0 ? 'unassigned' : tab === 1 ? 'assigned' : 'active';
        setScheduledRides(prevState => ({ ...prevState, [tabName]: !prevState[tabName] }));
    };

    const fetchRides = (type, page) => {
        let url = '';
        switch (type) {
            case 'unassigned':
                url = `ride/dispatch-view/unassigned?limit=5&page=${page}&lastDays=100`;
                break;
            case 'assigned':
                url = `ride/dispatch-view/assigned?limit=5&page=${page}&lastDays=100`;
                break;
            case 'active':
                url = `ride/dispatch-view/active?limit=5&page=${page}&lastDays=100`;
                break;
            default:
                break;
        }

        setLoading(prevState => ({ ...prevState, [type]: true }));
        getMethod({
            url,
            params: { scheduled: scheduledRides[type] },
        })
            .then((response) => {
                console.log(response?.data)
                const newData = response?.data || [];
                setRideData(prevState => ({
                    ...prevState,
                    [type]: page === 1 ? newData : [...prevState[type], ...newData]
                }));

                if (newData.length < 5) {
                    setHasMore(prevState => ({ ...prevState, [type]: false }));
                }
            })
            .catch((error) => {
                console.log(`Error fetching ${type} rides:`, error);
                if (error === "Internal Server Error: Server error occurred.") {
                    handleError5xx();
                    setTryAgain(() => () => fetchRides(type, page));
                }
            })
            .finally(() => {
                setLoading(prevState => ({ ...prevState, [type]: false }));
            });
    };

    const fetchRidesForTab = (tab) => {
        const type = tab === 0 ? 'unassigned' : tab === 1 ? 'assigned' : 'active';
        setRideData(prevState => ({ ...prevState, [type]: [] }));
        setPage(prevState => ({ ...prevState, [type]: 1 }));
        fetchRides(type, 1);
        setHasMore(prevState => ({ ...prevState, [type]: true }));
    };

    useEffect(() => {
        const unsubscribe = db.collection('rides_count')
            .onSnapshot(snapshot => {
                if (isInitialMount.current) {
                    fetchRides('unassigned', 1);
                    fetchRides('assigned', 1);
                    fetchRides('active', 1);
                    snapshot.forEach(doc => {
                        const data = doc.data();
                        setRideDataCount(
                            prevState => ({
                                ...prevState,
                                unassigned: data.unassigned,
                                assigned: data.assigned,
                                active: data.active,
                                dispatched: data.dispatched,
                                canceled: data.canceled
                            })
                        );
                    });
                    isInitialMount.current = false;
                    return;
                }

                let newCounts = {};
                snapshot.forEach(doc => {
                    const data = doc.data();
                    newCounts = data;
                });

                const changesDetected = {};
                for (let key in newCounts) {
                    if (newCounts[key] !== rideDataCount[key]) {
                        changesDetected[key] = true;
                    }
                }

                if (Object.keys(changesDetected).length > 0) {
                    setCountChanges(prev => ({
                        ...prev,
                        ...changesDetected
                    }));
                    setRideDataCount(prev => ({
                        ...prev,
                        ...newCounts
                    }));
                }

            });

        return () => unsubscribe();
    }, [rideDataCount, scheduledRides]);


    const handleUpdateClick = (type) => {
        setPage(prevState => ({ ...prevState, [type]: 1 }));
        fetchRides(type, 1);
        setHasMore(
            prevState => ({
                ...prevState,
                [type]: true
            })
        )
        setCountChanges(prevState => ({
            ...prevState,
            [type]: false
        }));
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollTop = 0;
        }

    };

    const handleNewRideAdded = () => {
        fetchRides('unassigned', 1);
        setPage(prevState => ({ ...prevState, unassigned: 1 }));
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollTop = 0;
        }
        setHasMore(
            prevState => ({
                ...prevState,
                unassigned: true
            })
        )
    };

    useEffect(() => {
        const getStream = db.collection('driver_dispatch')
            .where('status', 'in', status)
            .onSnapshot(async snapshot => {
                const updatedDrivers = await Promise.all(snapshot?.docs?.map(async doc => {
                    const data = doc?.data();
                    const prevLocation = previousLocationsRef.current[doc.id];
                    const currentLocation = data.location;

                    let snappedLocation = currentLocation;
                    if (!data.onRoad) {
                        const snappedPath = await snapToRoad(`${currentLocation?.lat},${currentLocation?.lng}`);
                        snappedLocation = snappedPath && snappedPath[0];
                        data.onRoad = true;
                    }

                    let rotation = 0;
                    if (prevLocation) {
                        rotation = calculateBearing(
                            prevLocation?.lat,
                            prevLocation?.lng,
                            snappedLocation?.lat,
                            snappedLocation?.lng
                        );
                    }
                    return {
                        id: doc.id,
                        ...data,
                        location: snappedLocation,
                        rotation,
                    };
                }));

                setDrivers(updatedDrivers);
                const updatedPreviousLocations = snapshot.docs.reduce((acc, doc) => {
                    acc[doc.id] = doc.data().location;
                    return acc;
                }, {});
                previousLocationsRef.current = updatedPreviousLocations;
            });
        return () => getStream();
    }, []);

    const clearMarkersAndLines = () => {
        markersRef.current.forEach(marker => marker.setMap(null));
        markersRef.current = [];

        polylinesRef.current.forEach(polyline => polyline.setMap(null));
        polylinesRef.current = [];
    };

    const handleMarkerClick = (driver) => {
        if (driver.status === "free") {
            dispatch(openModal(<DriverDetailsModal id={driver.id} status={driver.status} />));
        } else {
            dispatch(openModal(<RideDetailsModal active_ride_id={driver.active_ride_id} status={driver.status} />));
        }
    };

    const cutomerUpdateWhenCancel = () => {
        setSelectedRideCards({ 0: null, 1: null, 2: null });
        clearMarkersAndLines();
        fetchRidesForTab(0);
        setRideDataUpdateNeeded({
            unassigned: true,
            assigned: true,
            active: true,
        });
    }

    const rideUpdateWhenCancel = () => {
        setSelectedRideCards({ 0: null, 1: null, 2: null });
        clearMarkersAndLines();
        fetchRidesForTab(1);
        fetchRidesForTab(2);
        setRideDataUpdateNeeded({
            unassigned: true,
            assigned: true,
            active: true,
        });
    }

    const handleRideDetailsClick = (ride) => {
        if (ride.status === "pending" || ride.status === "new" || (ride.status === "scheduled" && ride?.driver_info?.uid === '')) {
            dispatch(openModal(<CustomerCard updateWhenCancel={cutomerUpdateWhenCancel} ride={ride} />));
        } else {
            dispatch(openModal(<RideDetailsModal updateWhenCancel={rideUpdateWhenCancel} active_ride_id={ride?._id} ride={ride} />));
        }
    };

    const handleRideCardClick = async (tab, _id, cardData) => {
        setSelectedRideCards(prevState => ({
            ...prevState,
            [tab]: _id,
        }));
        console.log(cardData);

        const wayPoints = cardData?.stop_points?.map(point => ({
            location: { lat: point?.lat, lng: point?.lng },
            stopover: true
        })) || [];

        if (tab === 0) {
            clearMarkersAndLines();
            currentStatus.current = null
            const pickupLocation = {
                lat: cardData?.pickup_address?.lat,
                lng: cardData?.pickup_address?.lng,
            };
            const [snappedPickupLocation] = await snapToRoad(`${pickupLocation?.lat},${pickupLocation?.lng}`);
            setPickupLocation(snappedPickupLocation || pickupLocation);
            setMapCenter(snappedPickupLocation || pickupLocation);
            const map = directionsRendererRef.current.getMap();
            map.setZoom(15);
        } else if (tab === 1) {
            clearMarkersAndLines();
            currentStatus.current = null
            setPickupLocation(null);
            let origin = new window.google.maps.LatLng(cardData?.pickup_address?.lat, cardData?.pickup_address?.lng);
            let destination = new window.google.maps.LatLng(cardData?.delivery_address?.lat, cardData?.delivery_address?.lng);

            const [snappedOrigin] = await snapToRoad(`${origin?.lat()},${origin?.lng()}`);
            const [snappedDestination] = await snapToRoad(`${destination?.lat()},${destination?.lng()}`);

            origin = new window.google.maps.LatLng(snappedOrigin?.lat, snappedOrigin?.lng);
            destination = new window.google.maps.LatLng(snappedDestination?.lat, snappedDestination?.lng);


            const request = {
                origin: origin,
                destination: destination,
                waypoints: wayPoints,
                travelMode: window.google.maps.TravelMode.DRIVING,
            };

            directionsRendererRef.current.setOptions({
                suppressMarkers: true,
            });

            directionsServiceRef.current.route(request, (result, status) => {
                if (status === window.google.maps.DirectionsStatus.OK) {
                    directionsRendererRef.current.setDirections(result);

                    const map = directionsRendererRef.current.getMap();

                    const leg = result.routes[0].legs[0];

                    const startMarker = new window.google.maps.Marker({
                        position: leg.start_location,
                        map: map,
                        icon: {
                            url: pickupImage,
                            scaledSize: new window.google.maps.Size(32, 32)
                        },
                        title: 'Start'
                    });
                    markersRef.current.push(startMarker);

                    const route = result.routes[0];
                    route.legs.forEach((leg, index) => {
                        if (index < route.legs.length - 1) {  // Skip the last leg which ends at the destination
                            const stopMarker = new window.google.maps.Marker({
                                position: leg.end_location,
                                map: map,
                                icon: {
                                    url: stopIcon,
                                    scaledSize: new window.google.maps.Size(32, 32)
                                },
                                title: `Stop ${index + 1}`
                            });
                            markersRef.current.push(stopMarker);
                        }
                    });


                    const directionsDisplayOptions = directionsRendererRef.current.get('directions').routes[0].overview_path;
                    const polyline = new window.google.maps.Polyline({
                        path: directionsDisplayOptions,
                        strokeColor: 'blue',
                        strokeOpacity: 1.0,
                        strokeWeight: 4,
                    });
                    polyline.setMap(directionsRendererRef.current.getMap());
                    polylinesRef.current.push(polyline);
                    directionsRendererRef.current.setOptions({ suppressPolylines: true });

                    const endMarker = new window.google.maps.Marker({
                        position: destination,
                        map: map,
                        icon: {
                            url: DropImage,
                            scaledSize: new window.google.maps.Size(32, 32)
                        },
                        title: 'End'
                    });
                    markersRef.current.push(endMarker);

                    setMapCenter(origin);
                } else {
                    console.error(`Error fetching directions: ${status}`);
                }
            });
        }
        else if (tab === 2) {
            currentStatus.current = null;
            clearMarkersAndLines();
            setPickupLocation(null);
            const map = directionsRendererRef.current.getMap();
            map.setCenter(new window.google.maps.LatLng(cardData?.pickup_address?.lat, cardData?.pickup_address?.lng));
            map.setZoom(15);

            const driverUid = cardData.driver_info.uid;
            const unsubscribe = db.collection('driver_dispatch')
                .doc(driverUid)
                .onSnapshot(async (doc) => {
                    const data = doc.data();
                    let origin = new window.google.maps.LatLng(data?.location?.lat, data?.location?.lng);
                    let destination = null;
                    if (data.status === 'en-route' || data.status === 'waiting-pickup') {
                        destination = new window.google.maps.LatLng(cardData?.pickup_address?.lat, cardData?.pickup_address?.lng);
                    } else if (data.status === 'busy') {
                        destination = new window.google.maps.LatLng(cardData?.delivery_address?.lat, cardData?.delivery_address?.lng);
                    } else {
                        clearMarkersAndLines();
                        return;
                    }

                    const [snappedOrigin] = await snapToRoad(`${origin?.lat()},${origin?.lng()}`);
                    const [snappedDestination] = await snapToRoad(`${destination?.lat()},${destination?.lng()}`);
                    if (currentStatus.current?.status !== data.status) {
                        clearMarkersAndLines();
                        currentStatus.current = data;
                        origin = new window.google.maps.LatLng(snappedOrigin?.lat, snappedOrigin?.lng);
                        destination = new window.google.maps.LatLng(snappedDestination?.lat, snappedDestination?.lng);

                        const getColorByStatus = (status) => {
                            switch (status) {
                                case 'busy':
                                    return '#F71500';
                                case 'en-route':
                                    return '#00AAF3';
                                case 'waiting-pickup':
                                    return '#F700CF';
                                default:
                                    return 'blue';
                            }
                        };

                        const getIconByStatus = (status) => {
                            switch (status) {
                                case 'busy':
                                    return DropImage;
                                case 'en-route':
                                    return EnRoutePerson;
                                case 'waiting-pickup':
                                    return waitingPerson;
                                default:
                                    return DropImage;
                            }
                        };
                        const getSizeIconByStatus = (status) => {
                            switch (status) {
                                case 'busy':
                                    return new window.google.maps.Size(32, 32);
                                case 'en-route':
                                    return new window.google.maps.Size(60, 60);
                                case 'waiting-pickup':
                                    return new window.google.maps.Size(60, 60);
                                default:
                                    return DropImage;
                            }
                        };
                        const getAnchorIconByStatus = (status) => {
                            switch (status) {
                                case 'busy':
                                    return new window.google.maps.Point(16, 16)
                                case 'en-route':
                                    return new window.google.maps.Point(32, 32)
                                case 'waiting-pickup':
                                    return new window.google.maps.Point(32, 32)
                                default:
                                    return DropImage;
                            }
                        };

                        const request = {
                            origin: origin,
                            destination: destination,
                            waypoints: wayPoints,
                            travelMode: window.google.maps.TravelMode.DRIVING,
                        };
                        directionsRendererRef.current.setOptions({
                            suppressMarkers: true,
                        });

                        const map = directionsRendererRef.current.getMap();

                        directionsServiceRef.current.route(request, (result, status) => {
                            if (status === window.google.maps.DirectionsStatus.OK) {
                                directionsRendererRef.current.setDirections(result);
                                setMapCenter(origin);

                                const directionsDisplayOptions = directionsRendererRef.current.get('directions').routes[0].overview_path;
                                const polyline = new window.google.maps.Polyline({
                                    path: directionsDisplayOptions,
                                    strokeColor: getColorByStatus(data.status),
                                    strokeOpacity: 1.0,
                                    strokeWeight: 4,
                                });
                                polyline.setMap(directionsRendererRef.current.getMap());
                                polylinesRef.current.push(polyline);
                                directionsRendererRef.current.setOptions({ suppressPolylines: true });

                                const route = result.routes[0];
                                route.legs.forEach((leg, index) => {
                                    if (index < route.legs.length - 1) {  // Skip the last leg which ends at the destination
                                        const stopMarker = new window.google.maps.Marker({
                                            position: leg.end_location,
                                            map: map,
                                            icon: {
                                                url: stopIcon,
                                                scaledSize: new window.google.maps.Size(32, 32)
                                            },
                                            title: `Stop ${index + 1}`
                                        });
                                        markersRef.current.push(stopMarker);
                                    }
                                });

                                const endMarker = new window.google.maps.Marker({
                                    position: destination,
                                    map: map,
                                    icon: {
                                        url: getIconByStatus(data.status),
                                        scaledSize: getSizeIconByStatus(data.status),
                                        anchor: getAnchorIconByStatus(data.status)
                                    },
                                    title: 'End'
                                });
                                markersRef.current.push(endMarker);
                            } else {
                                console.error(`Error fetching directions: ${status}`);
                            }
                        });

                        if (data.location && cardData?.delivery_address) {
                            const { lat: startLat, lng: startLng } = data.location;
                            const { lat: endLat, lng: endLng } = cardData.delivery_address;
                            const newRotation = calculateBearing(startLat, startLng, endLat, endLng);
                            setIconRotation(newRotation);
                        }
                    }
                });

            return () => unsubscribe();
        }
    };

    const handleScroll = (e) => {
        const target = e.target;
        const bottom = Math.ceil(target.scrollTop + target.clientHeight) >= target.scrollHeight;
        if (bottom) {
            const type = selectedTab === 0 ? 'unassigned' : selectedTab === 1 ? 'assigned' : 'active';
            if (!loading[type] && hasMore[type]) {
                fetchRides(type, page[type] + 1);
                setPage(prevState => ({ ...prevState, [type]: page[type] + 1 }));
            }
        }
    };

    useEffect(() => {
        if (trafficLayerRef.current && mapRef.current) {
            if (showTraffic) {
                trafficLayerRef.current.setMap(mapRef.current); // Show traffic layer if true
            } else {
                trafficLayerRef.current.setMap(null); // Hide traffic layer if false
            }
        }
    }, [showTraffic]);

    const renderTabContent = () => {
        const tabData = selectedTab === 0 ? rideData.unassigned : selectedTab === 1 ? rideData.assigned : rideData.active;
        return (
            <Box onScroll={handleScroll} ref={scrollContainerRef} sx={{
                maxHeight: '70%',
                minHeight: '70%',
                overflowY: 'auto',
                scrollbarWidth: 'none',
                '::-webkit-scrollbar': {
                    display: 'none',
                },
                '-ms-overflow-style': 'none',
            }}>
                {tabData.length > 0 ? tabData?.map((card) => (
                    <RideCard
                        status={selectedTab === 0 ? 'unassigned' : selectedTab === 1 ? 'assigned' : 'active'}
                        key={card._id}
                        cardData={card}
                        handleRideDetailsClick={() => handleRideDetailsClick(card)}
                        selected={selectedRideCards[selectedTab] === card._id}
                        onClick={() => { handleRideCardClick(selectedTab, card._id, card); console.log(card) }}
                    />
                )) : !tabData.length > 0 && !loading[selectedTab === 0 ? 'unassigned' : selectedTab === 1 ? 'assigned' : 'active'] ?
                    <Typography sx={{ textAlign: 'center', py: 1 }}>No rides found</Typography> : ''
                }
                {loading[selectedTab === 0 ? 'unassigned' : selectedTab === 1 ? 'assigned' : 'active'] && (
                    <Typography sx={{ textAlign: 'center', py: 1 }}>Loading...</Typography>
                )}
            </Box>
        );
    };

    return (
        <Box sx={{ display: "flex", flexDirection: "column", position: "relative" }}>
            {/* handleError5xx */}
            <Error5xx open={isErrorDialogOpen} errCount={errorCount} count={errorDialogCounter} handleClose={handleCloseErrorDialog} tryAgain={tryAgain} />

            <Box sx={{ flex: 1.4, height: "100%", width: "100%", minHeight: "calc(100vh - 116px)" }}>
                <Map
                    google={props.google}
                    zoom={4}
                    minZoom={1.8}
                    center={mapCenter}
                    styles={mapStyles}
                    fullscreenControl={false}
                    mapTypeControl={true}
                    streetViewControl={false}
                    termsControl={false}
                    keyboardShortcuts={false}
                    mapTypeId="hybrid" // Set map type to hybrid
                    containerStyle={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                    }}
                    onReady={(mapProps, map) => {
                        mapRef.current = map; // Assign map instance to mapRef
                        directionsRendererRef.current.setMap(map);
                        map.setMapTypeId(mapTypeId);
                        trafficLayerRef.current = new window.google.maps.TrafficLayer();
                        if (showTraffic) {
                            trafficLayerRef.current.setMap(map); // Show traffic layer if true
                        }
                        map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(
                            createTrafficControl(map) // Add traffic control
                        );
                        window.google.maps.event.addListener(map, "maptypeid_changed", () => {
                            const newMapTypeId = map.getMapTypeId();
                            setMapTypeId(newMapTypeId);
                            localStorage.setItem("mapTypeId", newMapTypeId);
                        });
                    }}
                >
                    {drivers && drivers?.map((driver) => (
                        <Marker
                            key={driver.id}
                            position={driver.location}
                            name={driver.name}
                            status={driver.status}
                            icon={{
                                url: carIcons[driver.status],
                                scaledSize: new window.google.maps.Size(20, 40),
                                rotation: driver.status !== "free" ? iconRotation : driver.rotation,
                            }}
                            onClick={() => handleMarkerClick(driver)}
                        />
                    ))}
                    {pickupLocation && (
                        <Marker
                            position={pickupLocation}
                            icon={{
                                url: pickupImage,
                                scaledSize: new window.google.maps.Size(30, 30),
                            }}
                        />
                    )}
                </Map>

                <Box
                    sx={{
                        position: "absolute",
                        top: 5,
                        left: 300,
                        padding: '8px',
                        px: 4,
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 3,
                        alignItems: "center",
                        backgroundColor: "white",
                        borderRadius: 3,
                        boxShadow: 'rgba(0, 0, 0, 0.15) 0 4px 36px',
                        [theme.breakpoints.down('sm')]: {
                            flexDirection: 'column',
                            gap: 1,
                            px: 2,
                            left: 10,
                        },
                    }}
                >
                    <StatusContainer>
                        <StatusTitle variant="body2">All</StatusTitle>
                        <StatusCount variant="body2">{drivers.length}</StatusCount>
                    </StatusContainer>
                    <StatusContainer>
                        <StatusTitle variant="body2" sx={{ textAlign: 'center' }}>Free</StatusTitle>
                        <StatusCount sx={{ color: "green" }} variant="body2">{drivers.filter(driver => driver.status === "free").length}</StatusCount>
                    </StatusContainer>
                    <StatusContainer>
                        <StatusTitle variant="body2" sx={{ textAlign: 'center' }}>Busy</StatusTitle>
                        <StatusCount sx={{ color: "red" }} variant="body2">{drivers.filter(driver => driver.status === "busy").length}</StatusCount>
                    </StatusContainer>
                    <StatusContainer>
                        <StatusTitle variant="body2" sx={{ textAlign: 'center' }}>En Route</StatusTitle>
                        <StatusCount sx={{ color: "#00AAF3" }} variant="body2">{drivers.filter(driver => driver.status === "en-route").length}</StatusCount>
                    </StatusContainer>
                    <StatusContainer>
                        <StatusTitle variant="body2" sx={{ textAlign: 'center' }}>Waiting Pickup</StatusTitle>
                        <StatusCount sx={{ color: "magenta" }} variant="body2">{drivers.filter(driver => driver.status === "waiting-pickup").length}</StatusCount>
                    </StatusContainer>
                </Box>

                <Box
                    sx={{
                        position: "absolute",
                        top: 10,
                        right: 10,
                        justifyContent: "right",
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 1,
                        [theme.breakpoints.down('sm')]: {
                            top: '10px',
                            right: 10,
                        },
                    }}
                >
                    {isRideContainerVisible ? (
                        <BlueButton onClick={toggleRideContainer} sx={{ height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', minWidth: '40px', boxShadow: '0 4px 36px rgba(0, 100, 150, 0.45)' }}>
                            <IoMdClose size={20} />
                        </BlueButton>
                    ) : (
                        <BlueButton sx={{ boxShadow: '0 4px 36px rgba(0, 100, 150, 0.45)' }} variant="contained" onClick={toggleRideContainer}>
                            <GrMenu size={16} style={{ marginRight: 5 }} />
                            Rides
                        </BlueButton>
                    )}

                    <RideSidebar
                        handleNewRideAdded={handleNewRideAdded}
                        isVisible={isRideContainerVisible}
                        rideDataCount={rideDataCount}
                        toggleVisibility={toggleRideContainer}
                        selectedTab={selectedTab}
                        handleClearFilters={handleClearFilters}
                        handleTabChange={handleTabChange}
                        selectedCompany={selectedCompany}
                        handleCompanyChange={handleCompanyChange}
                        renderTabContent={renderTabContent}
                        handleUpdateClick={handleUpdateClick}
                        countChanges={countChanges}
                        changeScheduledRides={changeScheduledRides}
                        scheduledRides={scheduledRides}
                    />
                </Box>

                <Box
                    sx={{
                        position: "absolute",
                        bottom: 10,
                        right: isRideContainerVisible ? 'calc(450px + 10px)' : '70px',
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 1,
                        transition: 'left 0.3s ease-in-out',
                        [theme.breakpoints.down('sm')]: {
                            right: '10px',
                            bottom: '10px',
                        },
                    }}
                >
                    <Box
                        sx={{
                            backgroundColor: "#FFFFFF",
                            padding: '12px',
                            borderRadius: 2,
                            boxShadow: 'rgba(0, 0, 0, 0.15) 0 4px 36px',
                        }}
                    >
                        <StatusTitle variant="body2">Cancelled ({rideDataCount?.canceled})</StatusTitle>
                    </Box>
                    <Box
                        sx={{
                            backgroundColor: "#FFFFFF",
                            padding: '12px',
                            borderRadius: 2,
                            boxShadow: 'rgba(0, 0, 0, 0.15) 0 4px 36px',
                        }}
                    >
                        <StatusTitle variant="body2">No cars ({drivers?.length})</StatusTitle>
                    </Box>
                    <Box
                        sx={{
                            backgroundColor: "#FFFFFF",
                            padding: '12px',
                            borderRadius: 2,
                            boxShadow: 'rgba(0, 0, 0, 0.15) 0 4px 36px',
                        }}
                    >
                        <StatusTitle variant="body2">Dispatched ({rideDataCount?.dispatched})</StatusTitle>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default GoogleApiWrapper({
    apiKey: `${GOOGLE_API_KEY}`,
})(DispatcherView);