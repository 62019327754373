import { Step, StepLabel, Stepper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import colorConfigs from "../../configs/colorConfigs";
import {
  getMethod
} from "../Hook/UseCustomApi";
import DriverStep from "./DriverStep";
import FeesPlane from "./FeesPlane";
import Payment from "./Payment";
import Zones from "./Zones";
import Error5xx from "../../components/common/HandelError5xx";
const AddRates = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const formData = useSelector((state) => state.appState.formData);
  const [activeStep, setActiveStep] = useState(null);
  const [carTypeList, setCarTypeList] = useState()

  //handle error5xx
  const [tryAgain, setTryAgain] = useState();
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [errorCount, setErrorCount] = useState(0);
  const [errorDialogCounter, setErrorDialogCounter] = useState(0);
  const handleError5xx = () => {
    setIsErrorDialogOpen(true);
    setErrorCount(prevCount => {
      const newCount = prevCount + 1;
      if (newCount === 1) {
        setErrorDialogCounter(5);
      } else if (newCount === 2) {
        setErrorDialogCounter(10);
      }
      return newCount;
    });
  };
  const handleCloseErrorDialog = () => {
    setIsErrorDialogOpen(false);
    if (errorCount <= 2) {
      handleErrorCountByClose()
    }

  };
  const handleErrorCountByClose = () => {
    setErrorCount(prevCount => {
      const newCount = prevCount - 1;
      if (newCount === 1) {
        setErrorDialogCounter(5);
      } else if (newCount === 2) {
        setErrorDialogCounter(10);
      }
      return newCount;
    });
  }

  const getRidesParams = () => {
    return {
    };
  };

  const fetchCarTypeList = () => {

    const url = "admin/car-type/all";
    getMethod({
      url: url,
      params: getRidesParams(),
    })
      .then((response) => {
        let result = response?.data;
        console.log(result);
        setCarTypeList(result);
      })
      .catch((error) => {
        console.error("Error fetching items:", error);
        if (error === "Internal Server Error: Server error occurred.") {
          handleError5xx();
          setTryAgain(() => fetchCarTypeList);
        }
      });

  }



  const next = (stepData) => {

    setCurrentStep((prevActiveStep) => prevActiveStep + 1);
  };

  const prev = () => {
    setCurrentStep((prevActiveStep) => prevActiveStep - 1);
  };

  const steps = [
    {
      path: "/rates/add",
      title: "Zones",
      content: (
        <Zones
          formData={formData}
          currentStep={currentStep}
          steps={activeStep}
          next={next}
          prev={prev}
          setCurrentStep={setCurrentStep}
        />
      ),
    },
    {
      path: "/service-details",
      title: "Service Details",
      content: (
        <FeesPlane
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          next={next}
          prev={prev}
          carTypeList={carTypeList}
        />
      ),
    },
    {
      path: "/driver-step",
      title: "Driver",
      content: (
        <DriverStep
          formData={formData}
          currentStep={currentStep}
          steps={activeStep}
          next={next}
          prev={prev}
          setCurrentStep={setCurrentStep}
        />
      ),
    },
    {
      path: "/payment-step",
      title: "Payment",
      content: (
        <Payment
          formData={formData}
          currentStep={currentStep}
          steps={activeStep}
          next={next}
          prev={prev}
          setCurrentStep={setCurrentStep}
        />
      ),
    },
  ];
  useEffect(() => {
    setActiveStep(steps?.length);
    fetchCarTypeList()
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <div>
      {/* handleError5xx */}
      <Error5xx open={isErrorDialogOpen} errCount={errorCount} count={errorDialogCounter} handleClose={handleCloseErrorDialog} tryAgain={tryAgain} />
      <Stepper
        activeStep={currentStep}
        alternativeLabel
        sx={{
          "& .MuiStepIcon-root": {
            color: "#DFE4EC", // Default (inactive)
            "& text": { fill: "#FFFFFF" },
          }, // Number color
          "& .MuiStepIcon-root.Mui-active": {
            color: colorConfigs?.sidebar?.bg,
          }, // Active
          "& .MuiStepIcon-root.Mui-completed": {
            color: colorConfigs?.sidebar?.bg,
          }, // Completed
          "& .MuiStepConnector-line": {
            borderColor: colorConfigs?.sidebar?.bg,
          }, // Connector lines
        }}
      >
        {steps.map((step, index) => (
          <Step key={index}>
            <StepLabel>{step.title}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <div>{steps[currentStep].content}</div>
    </div>
  );
};

export default AddRates;
