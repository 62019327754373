import {
  Box,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { IoMdAddCircle } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import colorConfigs from "../../configs/colorConfigs";
import { db } from "../../configs/firebase";
import { openModal } from "../../redux/features/modalSlice";
import CreateChatDialog from "./CreateChatDialog";
import MessagingContainer from "./MessagingContainer";
import JobCard from "./components/Card";
import SearchIcon from '@mui/icons-material/Search';

const SupportMessagingPage = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const drawerRef = useRef(null);
  const [tasks, setTasks] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [lastDoc, setLastDoc] = useState(null);
  const selecteChat = useSelector((state) => state.appState.selectedChat);
  const lastSupportChat = useSelector((state) => state.appState.lastSupportChat);
  const [activeButton, setActiveButton] = useState(lastSupportChat?.type === 'customer' ? lastSupportChat?.type : lastSupportChat?.type === 'customer' ? selecteChat?.type : 'driver');
  const [selectedTask, setSelectedTask] = useState(null);
  const dispatch = useDispatch();
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [listeners, setListeners] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");


  const handleSearchChange = (event) => {
    const newQuery = event.target.value;
    setSearchQuery(newQuery);

    if (newQuery === "") {

      setTasks([]);
      setHasMore(true);
      setLastDoc(null);
      fetchMoreTasks(activeButton);
    }
  }


  const sortTasks = (tasksToSort) => {
    return tasksToSort.sort((a, b) => {
      const updatedAtComparison = b.updatedAt?.toDate() - a.updatedAt?.toDate();
      if (updatedAtComparison !== 0) return updatedAtComparison;


      if (a.type === b.type) return 0;
      return a.type === "driver" ? -1 : 1;
    });
  };


  const fetchMoreTasks = useCallback(async (button, lastVisible = null) => {
    if (!hasMore) return;

    let tasksRef = db
      .collection("messaging_with_admin")
      .orderBy("updatedAt", "desc")
      .where("type", "==", button)
      .limit(6);

    if (searchQuery.length > 0) {
      tasksRef = db
        .collection("messaging_with_admin")
        .orderBy("user_name")
        .startAt(searchQuery)
        .endAt(searchQuery + "\uf8ff")
        .where("type", "==", button)
        .limit(6);
    }

    if (lastVisible) {
      tasksRef = tasksRef.startAfter(lastVisible);
    }

    const snapshot = await tasksRef.get();

    if (!snapshot.empty) {
      const newTasks = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));


      setTasks((prevTasks) => {
        const taskMap = new Map();


        prevTasks.forEach((task) => taskMap.set(task.id, task));


        newTasks.forEach((task) => taskMap.set(task.id, task));


        return sortTasks([...taskMap.values()]);
      });

      setLastDoc(snapshot.docs[snapshot.docs.length - 1]);

      if (snapshot.docs.length < 6) {
        setHasMore(false);
      }
    } else {
      setHasMore(false);
    }
  }, [hasMore, searchQuery]);



  const handleScroll = useCallback(() => {
    const drawer = drawerRef.current;
    if (drawer && drawer.scrollTop + drawer.clientHeight >= drawer.scrollHeight - 10) {
      fetchMoreTasks(activeButton, lastDoc);
    }
  }, [activeButton, lastDoc, fetchMoreTasks]);


  const initialFetchAndListen = useCallback((button) => {
    let tasksRef = db
      .collection("messaging_with_admin")
      .orderBy("updatedAt", "desc")
      .where("type", "==", button)
      .limit(6);

    if (searchQuery.trim().length > 0) {
      setTasks([])
      tasksRef = db
        .collection("messaging_with_admin")
        .orderBy("user_name")
        .startAt(searchQuery)
        .endAt(searchQuery + "\uf8ff")
        .where("type", "==", button)
        .limit(6);
    }

    const unsubscribe = tasksRef.onSnapshot((snapshot) => {
      const updatedTasks = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      console.log(updatedTasks)
      setTasks((prevTasks) => {
        const taskMap = new Map();
        prevTasks.forEach((task) => taskMap.set(task.id, task));
        updatedTasks.forEach((task) => taskMap.set(task.id, task));
        return sortTasks([...taskMap.values()]);
      });

      setLastDoc(snapshot.docs[snapshot.docs.length - 1]);
    });

    setListeners((prevListeners) => [...prevListeners, unsubscribe]);

    return () => unsubscribe();
  }, [searchQuery]);



  useEffect(() => {
    const drawer = drawerRef.current;
    if (drawer) {
      drawer.addEventListener("scroll", handleScroll);

      return () => {
        drawer.removeEventListener("scroll", handleScroll);
      };
    }
  }, [handleScroll]);

  useEffect(() => {

    listeners.forEach((unsubscribe) => unsubscribe());
    setListeners([]);


    initialFetchAndListen(activeButton);


    return () => {
      listeners.forEach((unsubscribe) => unsubscribe());
    };
  }, [activeButton, searchQuery, initialFetchAndListen]);


  useEffect(() => {
    if (tasks.length === 0) {
      initialFetchAndListen(activeButton);
    }
  }, [activeButton, initialFetchAndListen, searchQuery]);

  const handleButtonClick = (event) => {
    const button = event.target.value;
    setActiveButton(button);
    setTasks([]);
    setHasMore(true);
    setLastDoc(null);

    listeners.forEach((unsubscribe) => unsubscribe());
    setListeners([]);
  };

  const openCreateChatDialog = () => {
    dispatch(openModal(<CreateChatDialog setSelectedTaskId={setSelectedTaskId} memberType={activeButton} />));
  };

  const handleJobCardClick = (task) => {
    setSelectedTask(task);
    if (task.type === "driver") {
      setSelectedTaskId(task?.driver);
    } else {
      setSelectedTaskId(task?.customer);
    }
  };
  const handleOpenDialog = (task) => {
    setOpenDialog(true);
  };

  return (
    <>
      <Grid container direction="row" justify="space-between" sx={{ mt: -9, gap: 3 }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          sx={{
            margin: "10px 0",
            background: '#FFF',
            borderRadius: "10px",
            p: .5
          }}
        >
          <Grid item xs={12} md={12}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <FormControl sx={{ width: "80%" }}>
                <Select
                  labelId="select-label"
                  id="select"
                  value={activeButton}
                  label="Select Type"
                  onChange={handleButtonClick}
                  sx={{
                    border: "none",
                    fontSize: "18px",
                    fontWeight: "600",
                    fontFamily: "poppins",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    width: "fit-content",
                  }}
                >
                  <MenuItem value="driver">Driver Messages</MenuItem>
                  <MenuItem value="customer">Customer Messages</MenuItem>
                </Select>
              </FormControl>
              <Box sx={{ width: "20%", textAlign: 'center', cursor: 'pointer' }} onClick={openCreateChatDialog}>
                <IoMdAddCircle size={30} color={colorConfigs?.sidebar?.bg} />
              </Box>
            </Box>
            <Box sx={{ margin: '10px 0', width: '90%', mx: 'auto' }}>
              <TextField
                placeholder="Search..."
                variant="outlined"
                value={searchQuery}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  width: '100%',
                  height: '45px',
                  '& .MuiOutlinedInput-root': {
                    height: '45px',
                    padding: '10px',
                    '& fieldset': {
                      borderRadius: '30px',
                      borderColor: 'rgba(0,0,0,0.2)',
                    },
                    '&:hover fieldset': {
                      borderColor: 'rgba(0,0,0,0.4)',
                    },
                    '& .MuiInputBase-input': {
                      padding: '10px',
                    },
                  },
                }}
              />
            </Box>
          </Grid>

          <Box
            ref={drawerRef}
            sx={{
              overflowY: "auto",
              height: "70vh",
              padding: "10px",
            }}
          >
            {tasks?.length ? (
              tasks?.map((task) => (
                <div key={task.id}>
                  <JobCard
                    task={task}
                    fromDispute={true}
                    activeButton={activeButton}

                    selected={
                      (task?.type === "driver"
                        ? task?.driver === selectedTaskId
                        : task?.customer === selectedTaskId) ||
                      (lastSupportChat?.type === "driver"
                        ? lastSupportChat?.driver?.replace(/^admin_/, "") === task?.driver
                        : lastSupportChat?.customer?.replace(/^admin_/, "") === task?.customer)
                    }
                    handleClick={() => handleJobCardClick(task)}
                  />
                  <div style={{ height: "16px" }} />
                </div>
              ))
            ) : (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', flexDirection: 'column', gap: 1.5 }}>
                No chat found
                <Box sx={{ width: "20%", textAlign: 'center', cursor: 'pointer' }} onClick={openCreateChatDialog}>
                  <IoMdAddCircle size={50} color={colorConfigs?.sidebar?.bg} />
                </Box>
              </Box>
            )}
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={7.5} sx={{
          margin: "10px 0",
          background: '#FFF',
          borderRadius: "10px",
          p: .5
        }}>
          <MessagingContainer
            openDialog={handleOpenDialog}
            setActiveButton={setActiveButton}
            selectedTask={selectedTask}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default SupportMessagingPage;
