import { useTheme } from "@emotion/react";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { TextInput } from "@zetaton-dev/zetaton-components-bank";
import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import Loading from "react-fullscreen-loading";
import colorConfigs from "../../configs/colorConfigs";
import { auth } from "../../configs/firebase";
import { getMethod, postMethod } from "../Hook/UseCustomApi";
import Error5xx from "../../components/common/HandelError5xx";
import { useSelector } from "react-redux";


export default function AddNewAdmin({ fetchDriverss }) {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [role, setRole] = useState("");
  const [formErrors, setErrors] = useState({});
  const [error, setError] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("");
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const licAndConfig = useSelector((state) => state.licencesAndConfiguration);
  // console.log(licAndConfig);
  const admins = useSelector((state) => state.appState.admins);


  const fieldStyle = {
    "& label.Mui-focused": {
      color: "#172B4D !important",
      fontSize: "17px !important",
      fontWeight: "500 !important",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#BBC1CE",
        borderWidth: 1,
        borderRadius: "8px",
      },
      "&:hover fieldset": {
        borderColor: "#BBC1CE",
        borderWidth: 1,
        borderRadius: "8px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#BBC1CE",
        borderWidth: 1,
        borderRadius: "8px",
      },
      input: {
        "&::placeholder": {
          color: "#3A3541DE",
          opacity: 1, // otherwise firefox shows a lighter color
          fontSize: 14,
        },
      },
    },
  };


  //handle error5xx
  const [tryAgain, setTryAgain] = useState();
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [errorCount, setErrorCount] = useState(0);
  const [errorDialogCounter, setErrorDialogCounter] = useState(0);
  const handleError5xx = () => {
    setIsErrorDialogOpen(true);
    setErrorCount(prevCount => {
      const newCount = prevCount + 1;
      if (newCount === 1) {
        setErrorDialogCounter(5);
      } else if (newCount === 2) {
        setErrorDialogCounter(10);
      }
      return newCount;
    });
  };
  const handleCloseErrorDialog = () => {
    setIsErrorDialogOpen(false);
    if (errorCount <= 2) {
      handleErrorCountByClose()
    }

  };
  const handleErrorCountByClose = () => {
    setErrorCount(prevCount => {
      const newCount = prevCount - 1;
      if (newCount === 1) {
        setErrorDialogCounter(5);
      } else if (newCount === 2) {
        setErrorDialogCounter(10);
      }
      return newCount;
    });
  }






  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarErrorMessage, setSnackbarErrorMessage] = useState("");
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarErrorMessage(message);
    setSnackbarOpen(true);
  };



  let currentPage = useRef();
  useEffect(() => {
    currentPage.current = 1;
    fetchDrivers();
  }, []);



  const getParams = () => {
    return {
      page: currentPage.current,
    };
  };

  const fetchDrivers = async () => {

    getMethod({
      url: "admin",
      params: getParams(),
      handleErrorMessage: showSnackbar,
    })
      .then((response) => {

      })
      .catch((err) => {
        if (error === "Internal Server Error: Server error occurred.") {
          handleError5xx();
          setTryAgain(() => fetchDrivers);
        }
        console.error("Error fetching more stores:", err)
      })
      .finally(() => setLoading(false));
  };

  const handleChangeRole = (event) => {
    setRole(event.target.value);
    console.log(event);
  };
  const handleSubmit = async (values) => {
    setErrors({});
    let error = {};
    if (!values.name) {
      error.name = "Name is required.";
    } else if (values.name.length > 15) {
      error.name = "Name should not exceed 15 characters.";
    }

    // Validate Email
    if (!values.email) {
      error.email = "Email is required.";
    } else if (!emailRegex.test(values.email)) {
      error.email = "Invalid email address.";
    }

    // Validate Role
    if (role === "") {
      error.role = "Role is required.";
    }

    if (Object.keys(error).length > 0) {
      setErrors(error);
    } else if (auth && auth.currentUser) {
      const tokenId = await auth?.currentUser
        ?.getIdToken()
        .then((token) => token);
      const headers = {
        "Content-Type": "application/json",
        Authorization: tokenId,
      };

      setLoading(true);        // Make the API request to add a new admin
        postMethod({
          url: `admin`,
          body: {
            name: values.name,
            profile_picture: "",
            email: values.email,
            phone_number: "",
            role: role,
          },
          headers: headers,
          handleErrorMessage: showSnackbar,
        }).then((res) => {
          console.log(res, 'res');
          fetchDriverss();
          setLoading(false);
          setIsAddOpen(false);
          fetchDrivers();
          setSnackbarMessage("Successfully adding new admin account!");
          setSnackbarSeverity("success");
          setOpenSnackbar(true);
        }).catch ((error)=> {
        console.log(error, 'error');
        console.error("Error adding new admin:", error);
        setError(
          error === "Conflict: Conflict in action or duplicate request."
            ? "Admin already exist"
            : "Error adding new admin"
        );
        setLoading(false);
        if (error === "Internal Server Error: Server error occurred.") {
          handleError5xx();
          setTryAgain(() => () => handleSubmit(values));
        }
      })
    }
  };

  const originalHandleSubmit = async (values) => {
    setErrors({});
    let error = {};
    if (!values.name) {
      error.name = "Name is required.";
    } else if (values.name.length > 15) {
      error.name = "Name should not exceed 15 characters.";
    }

    // Validate Email
    if (!values.email) {
      error.email = "Email is required.";
    } else if (!emailRegex.test(values.email)) {
      error.email = "Invalid email address.";
    }

    // Validate Role
    if (role === "") {
      error.role = "Role is required.";
    }

    if (Object.keys(error).length > 0) {
      setErrors(error);
    } else if (auth && auth.currentUser) {
      const tokenId = await auth?.currentUser
        ?.getIdToken()
        .then((token) => token);
      const headers = {
        "Content-Type": "application/json",
        Authorization: tokenId,
      };

      setLoading(true);
      try {
        // Make the API request to add a new admin
        postMethod({
          url: `admin`,
          body: {
            name: values.name,
            profile_picture: "",
            email: values.email,
            phone_number: "",
            role: role,
          },
          headers: headers,
          handleErrorMessage: showSnackbar,
        }).then((res) => {
          fetchDriverss();
          setLoading(false);
          setIsAddOpen(false);
          fetchDrivers();
          setSnackbarMessage("Admin added successfully!");
          setSnackbarSeverity("success");
          setOpenSnackbar(true);
        });
        // Fetch the updated list of admins
      } catch (error) {
        console.error("Error adding new admin:", error);
        setError(
          error === "Conflict: Conflict in action or duplicate request."
            ? "Admin already exist"
            : "Error adding new admin"
        );
        setLoading(false);
        if (error === "Internal Server Error: Server error occurred.") {
          handleError5xx();
          setTryAgain(() => () => originalHandleSubmit(values));
        }
      }
    }
  };

  const initialValues = {
    name: "",
    email: "",
  };

  return (
    <>
      <Error5xx open={isErrorDialogOpen} errCount={errorCount} count={errorDialogCounter} handleClose={handleCloseErrorDialog} tryAgain={tryAgain} />

      <Dialog
        style={{ zIndex: 10, borderRadius: "30px", width: "100%" }}
        open={isAddOpen}
        onClose={() => {
          setIsAddOpen(false);
          setErrors({});
          setError("");
        }}>
        {/* keepMounted */}
        <DialogContent style={{ padding: "30px", width: "100%" }}>
          <DialogContentText style={{ width: "100%" }}>
            <Typography
              variant="h6"
              style={{
                fontWeight: "bold",
                color: "black",
                marginTop: "10px",
                marginBottom: "8px",
                width: "500px",
              }}>
              Add New Admin
            </Typography>
          </DialogContentText>
          <DialogContentText id="alert-dialog-slide-description">
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
              {({
                handleBlur,
                handleChange,
                values,
                errors,
                handleSubmit,
                touched,
              }) => {
                const handleNameChange = (e) => {
                  const { value } = e.target;
                  handleChange("name")(value);
                };

                const handleEmailChange = (e) => {
                  const { value } = e.target;
                  const trimmedValue = value.trim();
                  handleChange("email")(trimmedValue);
                };

                return (
                  <ThemeProvider theme={theme}>
                    <Box style={{ width: "100%" }}>
                      <Box
                        component="form"
                        style={{
                          fontFamily: "inter",
                          fontSize: "small",
                          marginTop: "24px",
                          width: "100%",
                        }}>
                        <Grid container spacing={3}>
                          <Grid item md={12} xs={12}>
                            <TextInput
                              styles={fieldStyle}
                              fullWidth
                              label={"Name"}
                              name="name"
                              onChange={handleNameChange}
                              onBlur={handleBlur("name")}
                              required
                              value={values?.name}
                              variant="outlined"
                              placeholder="Please enter name"
                              filled="true"
                              // Set it as a string "true"
                              focused
                              error={formErrors.name?.length > 0}
                            />
                            {formErrors.name?.length > 0 && (
                              <Typography
                                variant="caption"
                                style={{ color: "red" }}>
                                {formErrors.name}
                              </Typography>
                            )}
                          </Grid>
                          <Grid item md={12} xs={12}>
                            <TextInput
                              fullWidth
                              styles={fieldStyle}
                              label={"Email"}
                              name="email"
                              onChange={handleEmailChange}
                              onBlur={handleBlur("email")}
                              required
                              value={values?.email}
                              variant="outlined"
                              placeholder="Please enter email"
                              filled="true" // Set it as a string "true"
                              focused
                              error={formErrors.email?.length > 0}
                            />
                            {formErrors.email?.length > 0 && (
                              <Typography
                                variant="caption"
                                style={{ color: "red" }}>
                                {formErrors.email}
                              </Typography>
                            )}
                          </Grid>
                          <Grid item md={12} xs={12}>
                            <FormControl
                              fullWidth
                              style={{ marginTop: "2px" }}
                              sx={{
                                "& label.Mui-focused": {
                                  color: "rgba(0, 0, 0, 0.6)",
                                  bordercolor: "#BBC1CE",
                                },
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    borderColor: "#BBC1CE",
                                  },
                                  "&:hover fieldset": {
                                    borderColor: "#BBC1CE",
                                  },
                                  "&.Mui-focused fieldset": {
                                    borderColor: "#BBC1CE",
                                    borderWidth: "1px",
                                  },
                                  // borderRadius:"10px"
                                  height: "52px",
                                },
                              }}>
                              <InputLabel
                                id="demo-simple-select-label"
                                required
                                style={{
                                  color: "#172B4D",
                                  fontSize: "14.5px",
                                }}>
                                Role
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={role}
                                label="Role"
                                onChange={handleChangeRole}
                                style={{
                                  borderRadius: "10px",
                                  height: "50px",
                                }}>
                                <MenuItem value={"admin"}>Admin</MenuItem>
                                <MenuItem value={"dispatch"}>Dispatch</MenuItem>
                                <MenuItem value={"manager"}>Manager</MenuItem>
                              </Select>
                            </FormControl>
                            {formErrors.role?.length > 0 && (
                              <Typography
                                variant="caption"
                                style={{ color: "red" }}>
                                {formErrors.role}
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "center",
                            paddingTop: "12px",
                          }}></div>
                      </Box>
                      <p style={{ color: "red" }}>{error}</p>
                    </Box>
                    <DialogActions
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        marginBottom: "20px",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                      }}>
                      <Button
                        variant="outlined"
                        style={{
                          color: loading ? "gray" : colorConfigs?.sidebar?.bg,
                          borderColor: "lightgray",
                          width: "100%",
                        }}
                        onClick={() => {
                          setIsAddOpen(false);
                          setErrors({});
                          setError("");
                        }}>
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        style={{
                          background: loading
                            ? "gray"
                            : colorConfigs?.sidebar?.bg,
                          color: "white",
                          width: "100%",
                        }}
                        onClick={handleSubmit}
                        disabled={loading}>
                        {loading ? "...Loading" : "Add"}
                      </Button>

                    </DialogActions>
                  </ThemeProvider>
                );
              }}
            </Formik>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Grid
        item
        md={12}
        xs={12}
        style={{ marginTop: "-70px", marginBottom: "20px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}>
          <Typography
            style={{
              fontWeight: "bold",
              color: "white",
              marginBottom: "20px",
              marginTop: 10,
            }}>
            Admins
          </Typography>
          {/* {licAndConfig?.licenses?.max_admin_number > admins.length && ( */}
            <Button
              variant="contained"
              style={{
                color: colorConfigs.sidebar.bg,
                borderRadius: "20px",
                fontWeight: "bold",
                marginBottom: "17px",
                backgroundColor: "white",
              }}
              onClick={() => setIsAddOpen(true)}>
              + Add new Admin
            </Button>
          {/* )} */}
        </div>
      </Grid>
      <Loading
        loading={!!loading}
        background="rgba(86, 100, 210, 0.1)"
        loaderColor={colorConfigs.sidebar?.bg}
      />
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}>
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}>
        <Alert onClose={handleSnackbarClose} severity="error">
          {snackbarErrorMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
