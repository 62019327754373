import { collection, onSnapshot, query, where } from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import carIcon from "../../assets/car.jpg";
import dropoffIcon from "../../assets/images/deliver.png";
import EnRoutePerson from '../../assets/images/en-route-person.png';
import stopIcon from "../../assets/images/stop.png";
import enRouteCar from '../../assets/mapCars/En route.png';
import busyCar from '../../assets/mapCars/busy.png';
import { db } from "../../configs/firebase";
import { mapStyles } from "../../utils/constant";

const TrackingMap = ({ dropoff, driverLocation, wayPoints = [], activeRide }) => {
  const mapRef = useRef(null);
  const directionsRendererRef = useRef(null);
  const trafficLayerRef = useRef(null);  // Reference for Traffic Layer
  const [driverMarker, setDriverMarker] = useState(null);
  const [pickupMarker, setPickupMarker] = useState(null);
  const [dropoffMarker, setDropoffMarker] = useState(null);
  const [waypointMarkers, setWaypointMarkers] = useState([]);
  const [directionsDrawn, setDirectionsDrawn] = useState(false); // Track if directions have been drawn
  const [pickup, setPickup] = useState(null); // New state for pickup location
  const [showTraffic, setShowTraffic] = useState(
    localStorage.getItem("showTraffic") === "true"
  );
  const [mapTypeId, setMapTypeId] = useState(
    localStorage.getItem("mapTypeId") || "hybrid"
  );
  const geocodeLatLng = (geocoder, latlng, callback) => {
    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          callback(results[0].formatted_address);
        } else {
          callback("No address found");
        }
      } else {
        callback("Geocoder failed due to: " + status);
      }
    });
  };

  useEffect(() => {
    if (activeRide?._id && activeRide?.status) {
      const q = query(
        collection(db, "driver_dispatch"),
        where("status", "!=", "free")
      );

      const unsubscribe = onSnapshot(
        q,
        (querySnapshot) => {
          querySnapshot.forEach((doc) => {
            if (doc.id === activeRide?.driver_info?.uid) {
              const driverData = doc.data();
              setPickup({
                lat: driverData.location.lat,
                lng: driverData.location.lng,
              });
            }
          });
        },
        (error) => {
          console.log("Failed to fetch real-time driver location");
        }
      );

      return () => unsubscribe();
    }
  }, [activeRide._id]);

  // Reset directionsDrawn when activeRide.status changes
  useEffect(() => {
    setDirectionsDrawn(false);
  }, [activeRide.status, activeRide._id]);

  const createTrafficControl = (map) => {
    const controlDiv = document.createElement("div");
    controlDiv.style.backgroundColor = "#fff";
    controlDiv.style.color = localStorage.getItem("showTraffic") === "true" ? "rgb(25,25,25)" : "rgb(86, 86, 86)";
    controlDiv.style.borderRadius = "3px";
    controlDiv.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
    controlDiv.style.cursor = "pointer";
    controlDiv.style.marginTop = "10px";
    controlDiv.style.marginBottom = "22px";
    controlDiv.style.textAlign = "center";
    controlDiv.style.height = "40px";
    controlDiv.style.marginLeft = "-12px";

    const controlText = document.createElement("div");
    // controlText.style.color = "rgb(25,25,25)";
    controlText.style.fontFamily = "poppins";
    controlText.style.fontSize = "15px";
    controlText.style.lineHeight = "38px";
    controlText.style.fontWeight = localStorage.getItem("showTraffic") === "true" ? "600" : "500";
    controlText.style.paddingLeft = "5px";
    controlText.style.paddingRight = "5px";
    controlText.innerHTML = "Traffic Mode";
    controlDiv.appendChild(controlText);

    controlDiv.addEventListener('mouseover', () => {
      controlDiv.style.backgroundColor = "#f0f0f0";
      controlDiv.style.color = "rgb(25,25,25)";
    });

    controlDiv.addEventListener('mouseout', () => {
      controlDiv.style.backgroundColor = "#fff";
      controlDiv.style.color = "rgb(86, 86, 86)";
    });

    controlDiv.addEventListener("click", () => {
      setShowTraffic((prevShowTraffic) => {
        const newShowTraffic = !prevShowTraffic;
        if (newShowTraffic) {
          controlDiv.style.color = "rgb(25,25,25)";
          controlText.style.fontWeight = "600"
          trafficLayerRef.current.setMap(map); // Show traffic layer
        } else {
          controlDiv.style.color = "rgb(86, 86, 86)";
          controlText.style.fontWeight = "500"
          trafficLayerRef.current.setMap(null); // Remove traffic layer
        }
        localStorage.setItem("showTraffic", newShowTraffic);
        return newShowTraffic;
      });
    });

    return controlDiv;
  };

  useEffect(() => {

    if (!mapRef.current) {
      const map = new window.google.maps.Map(document.getElementById("map2"), {
        center: pickup || { lat: 0, lng: 0 }, // Fallback to 0,0 if no pickup location is provided
        zoom: 14,
        minZoom: 1,
        mapTypeId: mapTypeId,
        styles: mapStyles,
      });
      window.google.maps.event.addListener(map, "maptypeid_changed", () => {
        const newMapTypeId = map.getMapTypeId();
        setMapTypeId(newMapTypeId);
        localStorage.setItem("mapTypeId", newMapTypeId);
      });

      // Initialize traffic layer
      trafficLayerRef.current = new window.google.maps.TrafficLayer();

      mapRef.current = map;

      // Create traffic control UI on the map
      createTrafficControl(map);
    }

    const map = mapRef.current;
    const geocoder = new window.google.maps.Geocoder();

    // Toggle traffic layer
    if (showTraffic) {
      trafficLayerRef.current.setMap(map);
    } else {
      trafficLayerRef.current.setMap(null);
    }

    // Update or create pickup marker
    if (pickup) {
      if (pickupMarker) {
        pickupMarker.setPosition(pickup);
        map.panTo(pickup); // Ensure the map pans to the new pickup location
        pickupMarker.setIcon({
          url: activeRide?.status === "pickedup" || activeRide?.status === "waiting_delivery" ? busyCar : enRouteCar,
          scaledSize: new window.google.maps.Size(20, 30),
        }); // Update the icon based on ride status
      } else {
        const newPickupMarker = new window.google.maps.Marker({
          position: pickup,
          map: map,
          title: "Pickup Location",
          icon: {
            url: activeRide?.status === "pickedup" || activeRide?.status === "waiting_delivery" ? busyCar : enRouteCar,
            scaledSize: new window.google.maps.Size(20, 30),
          },
        });

        geocodeLatLng(geocoder, pickup, (address) => {
          const pickupInfoWindow = new window.google.maps.InfoWindow({
            content: `Pickup Location: ${address}`,
          });

          newPickupMarker.addListener("click", () => {
            pickupInfoWindow.open(map, newPickupMarker);
          });
        });

        setPickupMarker(newPickupMarker);
      }
    }

    // Update or create dropoff marker
    if (dropoff) {
      if (dropoffMarker) {
        dropoffMarker.setPosition(dropoff);
        dropoffMarker.setIcon({
          url: activeRide?.status === "pickedup" || activeRide?.status === "waiting_delivery" ? dropoffIcon : EnRoutePerson,
          scaledSize: activeRide?.status === "pickedup" || activeRide?.status === "waiting_delivery" ? new window.google.maps.Size(30, 30) : new window.google.maps.Size(60, 60),
        }); // Update the icon based on ride status
      } else {
        const newDropoffMarker = new window.google.maps.Marker({
          position: dropoff,
          map: map,
          title: "Dropoff Location",
          icon: {
            url: activeRide?.status === "pickedup" || activeRide?.status === "waiting_delivery" ? dropoffIcon : EnRoutePerson,
            scaledSize: activeRide?.status === "pickedup" || activeRide?.status === "waiting_delivery" ? new window.google.maps.Size(30, 30) : new window.google.maps.Size(60, 60),
          },
        });

        geocodeLatLng(geocoder, dropoff, (address) => {
          const dropoffInfoWindow = new window.google.maps.InfoWindow({
            content: `Dropoff Location: ${address}`,
          });

          newDropoffMarker.addListener("click", () => {
            dropoffInfoWindow.open(map, newDropoffMarker);
          });
        });

        setDropoffMarker(newDropoffMarker);
      }
    }

    // Update or create waypoint markers
    if (wayPoints.length > 0) {
      wayPoints.forEach((wp, index) => {
        if (waypointMarkers[index]) {
          waypointMarkers[index].setPosition(wp.location);
        } else {
          const newWaypointMarker = new window.google.maps.Marker({
            position: wp.location,
            map: map,
            title: "Waypoint",
            icon: {
              url: stopIcon,
              scaledSize: new window.google.maps.Size(30, 30),
            },
          });

          geocodeLatLng(geocoder, wp.location, (address) => {
            const waypointInfoWindow = new window.google.maps.InfoWindow({
              content: `Waypoint: ${address}`,
            });

            newWaypointMarker.addListener("click", () => {
              waypointInfoWindow.open(map, newWaypointMarker);
            });
          });

          setWaypointMarkers((prevMarkers) => [...prevMarkers, newWaypointMarker]);
        }
      });

      // Remove extra markers if wayPoints array is reduced
      if (wayPoints.length < waypointMarkers.length) {
        waypointMarkers.slice(wayPoints.length).forEach((marker) => {
          marker.setMap(null);
        });
        setWaypointMarkers(waypointMarkers.slice(0, wayPoints.length));
      }
    }

    // Update or create driver marker
    if (driverLocation) {
      if (driverMarker) {
        driverMarker.setPosition(driverLocation);
      } else {
        const newDriverMarker = new window.google.maps.Marker({
          position: driverLocation,
          map: map,
          title: "Driver's Location",
          icon: {
            url: carIcon,
            scaledSize: new window.google.maps.Size(40, 40),
          },
        });

        geocodeLatLng(geocoder, driverLocation, (address) => {
          const driverInfoWindow = new window.google.maps.InfoWindow({
            content: `Driver's Location: ${address}`,
          });

          newDriverMarker.addListener("click", () => {
            driverInfoWindow.open(map, newDriverMarker);
          });
        });

        setDriverMarker(newDriverMarker);
      }
    }

    // Draw directions when necessary
    if (!directionsDrawn && pickup && dropoff) {
      const directionsService = new window.google.maps.DirectionsService();

      // Clear previous directions before drawing new ones
      if (directionsRendererRef.current) {
        directionsRendererRef.current.set("directions", null);
      }

      directionsRendererRef.current = new window.google.maps.DirectionsRenderer({
        map: map,
        suppressMarkers: true,
        polylineOptions: {
          strokeColor: activeRide?.status === "pickedup" || activeRide?.status === "waiting_delivery" ? "#F71500" : "#00AAF3",
          strokeOpacity: 1.0,
          strokeWeight: 4,
        },
      });

      const request = {
        origin: pickup,
        destination: dropoff,
        travelMode: "DRIVING",
        waypoints: wayPoints.map((wp) => ({ location: wp.location })),
      };

      directionsService.route(request, (result, status) => {
        if (status === "OK") {
          directionsRendererRef.current.setDirections(result);
          setDirectionsDrawn(true); // Mark directions as drawn
        } else {
          console.error("Directions request failed due to " + status);
        }
      });
    }
  }, [pickup, dropoff, driverLocation, wayPoints, activeRide, directionsDrawn, showTraffic]);


  return <div id="map2" style={{ height: "550px" }}></div>;
};

export default TrackingMap;
