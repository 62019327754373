import { ThemeProvider, useTheme } from "@emotion/react";
import { Paper } from "@material-ui/core";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Container,
  IconButton,
  InputAdornment,
  Typography,
  useMediaQuery
} from "@mui/material";
import { SubmitButton, TextInput } from "@zetaton-dev/zetaton-components-bank";
import axios from "axios";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import biglogo from "../../assets/biglogo.png";
import loginback from "../../assets/loginback.png";
import womenLogin from "../../assets/womens-first/women-login.jpg";
import colorConfigs from "../../configs/colorConfigs";
import { auth, db } from "../../configs/firebase";
import { userData } from "../../redux/features/appStateSlice";
import { API_URL } from "../../utils/constant";
// import logowhitepbg from "../../assets/womens-first/logowithpbg.png" 
import Loading from "react-fullscreen-loading";
import logowhitepbg from "../../assets/womens-first/logopurple.png";


const Login = () => {
  const initialValues = { email: "", password: "" };
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const getUser = async (v) => {
    try {
      const isAuth = localStorage.getItem("userID");
      const doc = await db.collection("admins").doc(isAuth).get();

      if (doc?.exists) {
        dispatch(userData(doc?.data()));
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getUser();
  }, []);

  const handleSubmit = async (values) => {
    setLoading(true);
    axios
      .post(`${API_URL}/api/v1/admin/verifyAdmin`, {
        email: values.email,
      },
        {
          params: {
            projectId: process.env.REACT_APP_ENV === "production" ? "ridewyze" : process.env.REACT_APP_ENV === 'women_first' ? 'women-s-first' : "roadrunner"
          }
        }
      )

      .then(async (response) => {
        console.log(response, 'response')
        setLoading(true);

        if (response.data.message === "success") {
          await auth
            .signInWithEmailAndPassword(values.email, values.password)
            .then((result) => {
              setLoading(false);
              console.log("logged in");
            })
            .catch((err) => {
              setLoading("");
              console.log(err, "err login");
              switch (err.code) {
                case "auth/user-not-found":
                  setError("Couldn't find your account.");
                  break;
                case "auth/invalid-login-credentials":
                  setError("That's an incorrect password. Try again.");
                  break;
                case "auth/invalid-email":
                  setError("Please enter a valid Email Address.");
                  break;
                default:
                  setError("Something went wrong on our side.");
              }
            });
        }
        // dispatch(userData(response.data.data));
      })
      .catch((err) =>
        console.error(
          "Error fetching more loginnnnnn:",
          setError("You are not an admin"),
          err
        )
      )
      .finally(() => setLoading(false));
  };

  const validateSchema = Yup.object().shape({
    password: Yup.string().min(1).required("Password is required"),
    email: Yup.string()
      .trim()
      .email("Invalid Email")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Invalid Email format"
      )
      .required("Email is required"),
  });


  return (
    <>
      <div
        style={{
          background: `url(${process.env.REACT_APP_ENV == 'women_first' ?
            womenLogin : loginback})`,
        }}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validateSchema}
        >
          {({
            handleBlur,
            handleChange,
            values,
            errors,
            handleSubmit,
            touched,
          }) => (
            <ThemeProvider theme={theme}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Container
                  component="main"
                  sx={{
                    height: "100vh",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Paper
                    style={{
                      width: smDown ? "100%" : "45%",
                      borderRadius: "20px",
                    }}
                  >
                    <Box
                      style={{
                        paddingLeft: "50px",
                        paddingRight: "50px",
                        paddingBottom: "10px",
                      }}
                    >

                      <div style={{
                        display: "flex", justifyContent: "center", marginTop: "20px", marginBottom: "20px"

                      }}>
                        <img alt="logo" style={{ width: process.env.REACT_APP_ENV == 'women_first' ? '50%' : "100%" }} src={process.env.REACT_APP_ENV == 'women_first' ? logowhitepbg : biglogo} />

                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignContent: "center",
                          marginTop: "-20px",
                        }}
                      >
                        <Typography
                          component="h1"
                          variant="h4"
                          mb={3}
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          Welcome Back 👋
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          variant="inherit"
                          style={{
                            color: "rgba(49, 57, 87, 1)",
                            display: "flex",
                            textAlign: "center",
                            justifyContent: "center",
                          }}
                        >
                          Today is a new day. It's your day. You shape it.<br />
                          Sign
                          in to start managing your projects.
                        </Typography>
                      </div>

                      <div style={{ height: "20px" }} />

                      <Box component="form">
                        <Typography style={{ color: "black" }}>
                          Email
                        </Typography>
                        <TextInput
                          fullWidth
                          label=""
                          name="email"
                          onBlur={handleBlur("email")}
                          value={values.email}
                          onChange={handleChange("email")}
                          placeholder="Enter Your Email"
                          required
                          inputProps={{
                            maxLength: 60,
                          }}
                          variant="outlined"
                          InputLabelProps={{
                            style: { color: "black" },
                          }}
                          InputProps={{
                            style: {
                              color: "black",
                              borderColor: "white", // Border color
                            },

                            sx: {
                              "&.Mui-focused fieldset": {
                                borderColor: "white", // Focused border color
                              },
                              // backgroundColor: "rgba(247, 251, 255, 1)",
                              // color: "white",
                              borderRadius: "8px",
                              border: "1px solid white",
                              borderColor: "white", // Border color
                            },
                          }}
                        />
                        {errors?.email?.length > 0 && (
                          <Typography
                            variant="caption"
                            style={{ color: "red" }}
                          >
                            {errors.email}
                          </Typography>
                        )}
                        <div style={{ height: "20px" }} />
                        <Typography style={{ color: "black" }}>
                          Password
                        </Typography>
                        <TextInput
                          fullWidth
                          label=""
                          name="password"
                          id="password"
                          onBlur={handleBlur("password")}
                          autoComplete="current-password"
                          value={values.password}
                          onChange={handleChange("password")}
                          placeholder="Enter Password"
                          variant="outlined"
                          type={showPassword ? "text" : "password"}
                          InputLabelProps={{
                            style: { color: "black" },
                          }}
                          InputProps={{
                            style: {
                              color: "black",
                              userSelect: "none", // Prevent text selection
                            },
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            ),
                            sx: {
                              "&.Mui-focused fieldset": {
                                borderColor: "white", // Focused border color
                              },
                              borderRadius: "8px",
                              border: "1px solid white",
                              borderColor: "white", // Border color
                              "input[type='password']": {
                                userSelect: "none", // Prevent text selection
                                MozUserSelect: "none",
                                msUserSelect: "none",
                                WebkitUserSelect: "none",
                              },
                            },
                          }}
                        />
                        {errors?.password?.length > 0 && (
                          <Typography
                            variant="caption"
                            style={{ color: "red" }}
                          >
                            {errors?.password}
                          </Typography>
                        )}
                        <Box
                          style={{
                            display: "flex",
                            alignContent: "flex-end",
                            justifyContent: "flex-end",
                          }}
                        >
                          <div>
                            <Typography
                              variant="caption"
                              style={{
                                color: colorConfigs?.sidebar?.bg,
                                fontWeight: "bold",
                                cursor: "pointer",
                              }}
                              onClick={() => navigate("/forgotPassword")}
                            >
                              Forgot Password?
                            </Typography>
                          </div>
                        </Box>
                        <div style={{ height: "20px" }} />
                        <Box sx={{ marginTop: "10px", mb: "3px" }}>
                          <SubmitButton
                            variant="contained"
                            style={{
                              width: "100%",
                              backgroundColor: colorConfigs?.sidebar?.bg,
                              color: "white",
                              cursor: "pointer",
                              fontWeight: "bold",
                              borderRadius: "22px",
                            }}
                            onClick={handleSubmit}
                            label={loading ? "loading..." : "Login"}
                          />
                          <p style={{ color: "red" }}> {error}</p>
                        </Box>
                      </Box>
                      <Box sx={{ marginTop: "10px", mb: "3px" }}></Box>
                    </Box>
                  </Paper>
                  {/* <img alt="logo" style={{ width: "180px" }} src={logo} /> */}
                </Container>
              </div>
            </ThemeProvider>
          )}
        </Formik>
      </div>
      <Loading
        loading={!!loading}
        background="rgba(86, 100, 210, 0.1)"
        loaderColor={colorConfigs.sidebar?.bg}
      />
    </>
  );
};

export default Login;
