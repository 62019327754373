import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";

let config;

const env = process.env.REACT_APP_ENV;
if (env === "production") {
  config = {
    apiKey: "AIzaSyAweoAjPS9g4lhzR5EqdTFpfOVK-dA7fGw",
    authDomain: "ridewyze.firebaseapp.com",
    projectId: "ridewyze",
    storageBucket: "ridewyze.appspot.com",
    messagingSenderId: "549486077661",
    appId: "1:549486077661:web:c3111a3ba515ec53d19d34",
    measurementId: "G-ESNNZKH6D2"
  };
} else if (env === "development") {
  config = {
    apiKey: "AIzaSyDX-6AHolu80-Yb9SnXQEEluPr_qEduodQ",
    authDomain: "roadrunner-a3188.firebaseapp.com",
    projectId: "roadrunner-a3188",
    storageBucket: "roadrunner-a3188.appspot.com",
    messagingSenderId: "270375397655",
    appId: "1:270375397655:web:03c06dbd6dcb1e1f34af52",
    measurementId: "G-XFP3DXF1ZK"
  };
} else if (env === "women_first") {
  config = {
    apiKey: "AIzaSyAylkxa21mdd06h3VwU0WeOx4quBu7uNAY",
    authDomain: "women-s-first.firebaseapp.com",
    projectId: "women-s-first",
    storageBucket: "women-s-first.appspot.com",
    messagingSenderId: "948980771778",
    appId: "1:948980771778:web:39d9a71e8f849f3005445b",
    measurementId: "G-2ZYX9ND88Y"
  };
} else {
  throw new Error(`Unknown environment: ${env}`);
}

if (!firebase.apps.length) {
  firebase.initializeApp(config);
} else {
  firebase.app();
}

// Use these for db & auth
const db = firebase.firestore();
const storageSdk = firebase.storage();
const auth = firebase.auth();
const usersCollection = db.collection("users");
const firestoreFieldValue = firebase.firestore.FieldValue;

export { auth, db, firestoreFieldValue, storageSdk, usersCollection };
