import DriveFileRenameOutlineSharpIcon from "@mui/icons-material/DriveFileRenameOutlineSharp";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {
  Alert,
  Box,
  Button,
  CardContent,
  Divider,
  Grid,
  Snackbar,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import {
  PhoneNumberInput,
  SubmitButton,
  TextInput,
} from "@zetaton-dev/zetaton-components-bank";
import { Modal } from "antd";
import dayjs from "dayjs";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import colorConfigs from "../../configs/colorConfigs";
import { emailRegex } from "../../configs/validation";
import {
  setEnableEdit
} from "../../redux/features/appStateSlice";
import { putMethod } from "../Hook/UseCustomApi";
import "./index.css";
import Error5xx from "../../components/common/HandelError5xx";
import { formatCurrency } from "../../utils/localization/currencyUtils";
import { formatDate } from "../../utils/localization/dateTimeUtils";
import {
  NewFormatPhoneNumber
} from "../../utils/localization/NewPhoneUtils";

const DriverInfo = ({ selectedObject, enabled, params, handleUpdate }) => {
  const [startDate, setStartDate] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();

  //handle error5xx
  const [tryAgain, setTryAgain] = useState();
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [errorCount, setErrorCount] = useState(0);
  const [errorDialogCounter, setErrorDialogCounter] = useState(0);
  const handleError5xx = () => {
    setIsErrorDialogOpen(true);
    setErrorCount(prevCount => {
      const newCount = prevCount + 1;
      if (newCount === 1) {
        setErrorDialogCounter(5);
      } else if (newCount === 2) {
        setErrorDialogCounter(10);
      }
      return newCount;
    });
  };
  const handleCloseErrorDialog = () => {
    setIsErrorDialogOpen(false);
    if (errorCount <= 2) {
      handleErrorCountByClose()
    }

  };
  const handleErrorCountByClose = () => {
    setErrorCount(prevCount => {
      const newCount = prevCount - 1;
      if (newCount === 1) {
        setErrorDialogCounter(5);
      } else if (newCount === 2) {
        setErrorDialogCounter(10);
      }
      return newCount;
    });
  }


  useEffect(() => {
    if (selectedObject?.dateOfBirth) {
      setStartDate(dayjs(selectedObject.dateOfBirth));
    }
  }, [selectedObject]);

  const handleDateChange = (date) => {
    const today = dayjs();
    const age = today.diff(date, "year");

    if (age < 21) {
      setErrorMessage(
        "Invalid Date Of Birth. You must be at least 21 years old."
      );
      setStartDate(null); // Clear the selected date
    } else {
      setErrorMessage("");
      setStartDate(date);
    }
  };

  const [formErrors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [view, setView] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const selectedUser = useSelector((state) => state.appState.selectedUser);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const validationSchema = Yup.object().shape({
    first_name: Yup.string()
      .matches(/^[a-zA-Z]+(?: ?[a-zA-Z]+)* ?$/, "Only letters and up to two spaces are allowed")
      .required("First name is required"),
    last_name: Yup.string()
      .matches(/^[a-zA-Z]+(?: ?[a-zA-Z]+)* ?$/, "Only letters and up to two spaces are allowed")
      .required("Last name is required"),

  });

  const fieldStyle = {
    "& label.Mui-focused": {
      color: "#172B4D !important",
      fontSize: "17px !important",
      fontWeight: "500 !important",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#BBC1CE",
        borderWidth: 1,
        borderRadius: "8px",
      },
      "&:hover fieldset": {
        borderColor: "#BBC1CE",
        borderWidth: 1,
        borderRadius: "8px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#BBC1CE",
        borderWidth: 1,
        borderRadius: "8px",
      },
      input: {
        "&::placeholder": {
          color: "#3A3541DE",
          opacity: 1,
          fontSize: 14,
        },
      },
    },
  };

  const validateName = (value) => {
    const regex = /^[a-zA-Z]+(?: ?[a-zA-Z]+)* ?$/;
    return regex.test(value) ? undefined : "Use only letters.";
  };
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = (values) => {
    let error = {};
    if (!values.first_name) {
      error.first_name = "First name is required.";
    } else if (values.first_name.length > 15) {
      error.first_name = "First name should not exceed 15 characters.";
    } else if (validateName(values.first_name) === "Use only letters.") {
      error.first_name = "Only letters and up to two spaces are allowed";
    }

    if (!values.last_name) {
      error.last_name = "Last name is required.";
    } else if (values.last_name.length > 15) {
      error.last_name = "Last name should not exceed 15 characters.";
    } else if (validateName(values.last_name) === "Use only letters.") {
      error.last_name = "Only letters and up to two spaces are allowed";
    }
    if (!values.email) {
      error.email = "Email is required.";
    } else if (!emailRegex.test(values.email)) {
      error.email = "Invalid email address.";
    }
    if (Object.keys(error).length > 0) {
      setErrors(error);
      setOpenDialog(false);
      console.warn(Object.keys(error));
    }

    if (errorMessage || error.first_name || error.last_name || error.email) {
      console.log("errorMessage=>", errorMessage, "error=>", error);
    } else {
      setIsModalVisible(true);

    }

  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const startDateTimestamp = startDate && startDate.valueOf();
  const handleSubmit = async (values) => {
    setErrors({});
    setIsModalVisible(false);
    let error = {};
    if (!values.first_name) {
      error.first_name = "First name is required.";
    } else if (values.first_name.length > 15) {
      error.first_name = "First name should not exceed 15 characters.";
    }
    if (!values.last_name) {
      error.last_name = "Last name is required.";
    } else if (values.last_name.length > 15) {
      error.last_name = "Last name should not exceed 15 characters.";
    }
    if (!values.email) {
      error.email = "Email is required.";
    } else if (!emailRegex.test(values.email)) {
      error.email = "Invalid email address.";
    }

    if (Object.keys(error).length > 0) {
      setErrors(error);
      setOpenDialog(false);
      console.warn(Object.keys(error));
    } else {
      const dataToUpdate = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        dateOfBirth: startDate ? startDateTimestamp : selectedObject?.dateOfBirth,
        description: values.description,
        enabled: enabled,
      };
      handleUpdate(dataToUpdate);
      setLoading(true);
      putMethod({
        url: `driver/byAdmin/${params}`,
        body: dataToUpdate,
        handleErrorMessage: showSnackbar,
      })
        .then((r) => {
          console.log(r);
          setOpenDialog(false);
        })
        .catch((error) => {
          if (error === "Internal Server Error: Server error occurred.") {
            handleError5xx();
            setTryAgain(() => () => handleSubmit(values));
          }
          console.error("Update API error:", error.message);
        })
        .finally(() => {
          setLoading(false);
          setOpenDialog(false);
          setOpen(true);
        });
    }
  };

  const countryCode = useSelector((state) => state.country.selectedCountry);
  const locale = countryCode === "USD" ? "en-US" : "ar-EG";
  const currencyCode =
    countryCode === "USD" ? "$" : countryCode === "EG" ? "EGP" : countryCode === 'JOD' ? "JOD" : '$';

  return (
    selectedObject &&
    Object.values(selectedObject)?.length > 0 && (
      <>
        {/* handleError5xx */}
        <Error5xx open={isErrorDialogOpen} errCount={errorCount} count={errorDialogCounter} handleClose={handleCloseErrorDialog} tryAgain={tryAgain} />
        <Box
          display="flex"
          style={{ width: "100%", padding: 16, cursor: "pointer" }}
        >
          <Button
            variant="outlined"
            onClick={() => {
              setView(true);
              dispatch(setEnableEdit(false));

            }}
            style={{
              borderTopLeftRadius: "88px",
              borderBottomLeftRadius: "88px",
              borderRight: "1px solid black",
              borderColor: colorConfigs?.sidebar?.bg,
              color: view ? "white" : colorConfigs?.sidebar?.bg,
              backgroundColor: view ? colorConfigs?.sidebar?.bg : "white",
              cursor: "pointer",
            }}
          >
            <VisibilityOutlinedIcon fontSize="small" /> View
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              setView(false);
              dispatch(setEnableEdit(true));

            }}
            style={{
              borderTopRightRadius: "88px",
              borderBottomRightRadius: "88px",
              borderColor: colorConfigs?.sidebar?.bg,
              color: !view ? "white" : "#42526E",
              backgroundColor: !view ? colorConfigs?.sidebar?.bg : "white",
              cursor: "pointer",
            }}
          >
            <DriveFileRenameOutlineSharpIcon fontSize="small" /> Edit
          </Button>
        </Box>
        <div style={{ height: "30px" }} />

        <CardContent
          elevation={3}
          style={{
            padding: 16,
            display: "flex",
            alignItems: "center",
            marginLeft: "13px",
            backgroundColor: colorConfigs?.sidebar?.ligthBg,
          }}
        >

          <div style={{ display: "flex", alignItems: "center" }}>
            <MonetizationOnOutlinedIcon
              style={{ marginRight: 8, color: colorConfigs?.sidebar?.bg }}
            />
            <div>
              {console.log(selectedObject, 'selectedObject')}
              <Typography variant="caption" style={{ color: "gray" }}>
                Wallet Balance{" "}
              </Typography>
              <br />
              <Typography
                variant="caption"
                style={{
                  fontWeight: "bold",
                }}
              >
                {formatCurrency(
                  (selectedUser?.wallet_balance).toFixed(2),
                  locale,
                  currencyCode
                )}
              </Typography>
            </div>
          </div>
          <Divider
            orientation="vertical"
            style={{ margin: "0 22px", width: "3px", height: "50px" }}
          />
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <Typography variant="caption" style={{ color: "gray" }}>
                Number of Rides{" "}
              </Typography>
              <br />
              <Typography
                variant="caption"
                style={{
                  fontWeight: "bold",
                }}
              >
                {selectedObject?.rides_count?.completed > 0 || selectedObject?.rides_count?.canceled > 0
                  ? 'Total Rides: ' + (selectedObject?.rides_count?.completed + selectedObject?.rides_count?.canceled) : 'No Rides Yet'}

              </Typography>
            </div>
          </div>
          <Divider
            orientation="vertical"
            style={{ margin: "0 22px", width: "3px", height: "50px" }}
          />
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <Typography variant="caption" style={{ color: "gray" }}>
                Rides Count{" "}
              </Typography>
              <br />
              <Typography
                variant="caption"
                style={{
                  fontWeight: "bold",
                }}
              >
                {selectedObject?.rides_count?.completed > 0 ? 'Completed Rides: ' + selectedObject?.rides_count?.completed : 'No Completed Rides'}

              </Typography>
            </div>
          </div>
          <Divider
            orientation="vertical"
            style={{ margin: "0 22px", width: "3px", height: "50px" }}
          />
          <div style={{ display: "flex", alignItems: "center" }}>

            <div>
              <Typography variant="caption" style={{ color: "gray" }}>
                Rides Count{" "}
              </Typography>
              <br />
              <Typography
                variant="caption"
                style={{
                  fontWeight: "bold",
                }}
              >
                {selectedObject?.rides_count?.canceled > 0 ? 'Canceled Rides: ' + selectedObject?.rides_count?.canceled : 'No Canceled Rides'}

              </Typography>
            </div>
          </div>
        </CardContent>

        <Formik
          initialValues={{
            first_name: selectedObject?.first_name,
            last_name: selectedObject?.last_name,
            short_id: selectedObject?.short_id,
            email: selectedObject?.email,
            dateOfBirth: selectedObject?.dateOfBirth,
            phone_number: NewFormatPhoneNumber(
              selectedObject?.full_phone_number && selectedObject?.full_phone_number !== "" ? selectedObject?.full_phone_number :
                `${selectedObject?.code_phone_number + selectedObject?.phone_number}`
            ),
            description: selectedObject?.description,
            category_account: selectedObject?.category_account,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ handleBlur, handleChange, values, errors, handleSubmit }) => (
            <div style={{ width: "100%" }}>
              <div>
                <div style={{ height: "30px" }} />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextInput
                        fullWidth
                        styles={fieldStyle}
                        label={"First Name"}
                        name="first_name"
                        onChange={handleChange("first_name")}
                        onBlur={handleBlur("first_name")}
                        required
                        value={values?.first_name}
                        variant="outlined"
                        placeholder="Please enter first name"
                        filled="true"
                        focused
                        InputProps={{
                          readOnly: view ? true : false,
                        }}
                        error={Boolean(errors.first_name)}
                      />
                      {errors.first_name && (
                        <Typography variant="caption" style={{ color: "red" }}>
                          {errors.first_name}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextInput
                        fullWidth
                        label={"Last Name"}
                        styles={fieldStyle}
                        name="last_name"
                        onChange={handleChange("last_name")}
                        onBlur={handleBlur("last_name")}
                        required
                        value={values?.last_name}
                        variant="outlined"
                        placeholder="Please enter last name"
                        filled="true"
                        focused
                        InputProps={{
                          readOnly: view ? true : false,
                        }}
                        error={Boolean(errors.last_name)}
                      />
                      {errors.last_name && (
                        <Typography variant="caption" style={{ color: "red" }}>
                          {errors.last_name}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextInput
                        styles={fieldStyle}
                        fullWidth
                        label={"Driver ID"}
                        name="short_id"
                        onChange={handleChange("short_id")}
                        onBlur={handleBlur("short_id")}
                        required
                        value={values?.short_id}
                        variant="outlined"
                        placeholder="Please enter driver ID"
                        disabled={true}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextInput
                        styles={fieldStyle}
                        fullWidth
                        label={"Email"}
                        name="email"
                        onChange={handleChange("email")}
                        onBlur={handleBlur("email")}
                        required
                        value={values?.email}
                        variant="outlined"
                        placeholder="Please enter email"
                        filled="true"
                        focused
                        error={formErrors.email?.length > 0 ? true : false}
                        InputProps={{
                          readOnly: view ? true : false,
                        }}
                      />
                      {formErrors.email?.length > 0 && (
                        <Typography variant="caption" style={{ color: "red" }}>
                          {formErrors.email}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item md={6} xs={12} style={{ zIndex: 0 }}>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        style={{ borderRadius: "20px" }}
                      >
                        <DemoItem
                          styles={fieldStyle}
                          style={{ borderRadius: "20px" }}
                        >
                          <DatePicker
                            value={startDate}
                            onChange={handleDateChange}
                            dateFormat="YYYY-MM-DD"
                            className="datepicker-input"
                            placeholderText="Please select start date"
                            label="Date Of Birth"
                            disabled={view} // Disable the DatePicker in view mode
                          />
                          {errorMessage && (
                            <div style={{ color: "red" }}>{errorMessage}</div>
                          )}
                        </DemoItem>
                      </LocalizationProvider>
                      {formErrors.formattedDate?.length > 0 && (
                        <Typography variant="caption" style={{ color: "red" }}>
                          {startDate == null ? formatDate(formErrors.startDate, countryCode) : ""}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <>
                        <PhoneNumberInput
                          styles={fieldStyle}
                          fullWidth
                          label={"Phone Number"}
                          name="phone_number"
                          onChange={handleChange("phone_number")}
                          onBlur={handleBlur("phone_number")}
                          required
                          disabled={true}
                          value={values.phone_number}
                          variant="outlined"
                          placeholder="Please enter contact number"
                          focused
                          error={
                            formErrors?.phone_number?.length > 0 ? true : false
                          }
                          filled="true"
                          style={{ borderRadius: "8px" }}
                        />
                        {formErrors.phone_number?.length > 0 && (
                          <Typography
                            variant="caption"
                            style={{ color: "red" }}
                          >
                            {formErrors.phone_number}
                          </Typography>
                        )}
                      </>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <TextInput
                        fullWidth
                        styles={fieldStyle}
                        helperText=""
                        label={"Description"}
                        name="description"
                        onChange={handleChange("description")}
                        onBlur={handleBlur("description")}
                        multiline
                        maxRows={5}
                        value={values.description}
                        error={
                          formErrors?.description?.length > 0 ? true : false
                        }
                        InputProps={{
                          readOnly: view ? true : false,
                        }}
                        variant="outlined"
                        placeholder="Please enter description"
                        focused
                        filled="true"
                      />
                      {formErrors.contactEmail?.length > 0 && (
                        <Typography variant="caption" style={{ color: "red" }}>
                          {formErrors.contactEmail}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                  <div style={{ height: "10px" }} />
                  {!view && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        p: 2,
                      }}
                      style={{ marginLeft: "20px" }}
                    >
                      <SubmitButton
                        style={{
                          color: "white",
                          backgroundColor: colorConfigs?.sidebar?.bg,
                          width: "250px",
                          height: "50px",
                          borderRadius: "8px",
                          fontSize: "18px",
                        }}
                        type="submit"
                        variant="contained"
                        label={"Save"}
                        loading={loading}
                        onClick={() => { showModal(values) }}
                      />
                    </Box>
                  )}
                </CardContent>
                <Modal
                  title="Confirm Changes"
                  visible={isModalVisible}
                  onOk={handleSubmit}
                  onCancel={handleCancel}
                  okText="Yes"
                  okButtonProps={{
                    style: {
                      backgroundColor: colorConfigs?.sidebar?.bg,
                      color: "white",
                    },
                  }}
                >
                  <p>Are you sure you want to save the changes?</p>
                </Modal>
              </div>
            </div>
          )}
        </Formik>
        {/* <div style={{ height: "10px" }} />
        {!view && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              p: 2,
            }}
            style={{ marginLeft: "20px" }}
          >
            <SubmitButton
              style={{
                color: "white",
                backgroundColor: !loading ? colorConfigs.button : "gray",
                width: "250px",
                height: "50px",
                borderRadius: "8px",
                fontSize: "18px",
              }}
              type="submit"
              variant="contained"
              label={"Save"}
              loading={loading}
              onClick={showModal}
            />
          </Box>
        )} */}
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            variant="filled"
            sx={{ width: "100%" }}
          >
            The data is saved successfully!
          </Alert>
        </Snackbar>
        <div style={{ height: "30px" }} />

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={handleSnackbarClose} severity="error">
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </>
    )
  );
};

export default DriverInfo;
