import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';
import { AiOutlineGift } from "react-icons/ai";
import { BiSolidSend } from "react-icons/bi";
import { FaCarSide } from 'react-icons/fa';
import colorConfigs from '../../configs/colorConfigs';

const badgeStyles = {
    'received-money': {
        backgroundColor: '#E7F3FF',
        color: colorConfigs?.sidebar?.bg,
        icon: ''
    },
    'send-money': {
        backgroundColor: '#FFF5D7',
        color: '#F9C21A',
        icon: ''
    },
    'top-up': {
        backgroundColor: '#E7F3FF',
        color: colorConfigs?.sidebar?.bg,
        icon: ''
    },
    'admin-charge': {
        backgroundColor: '#E7F3FF',
        color: colorConfigs?.sidebar?.bg,
        icon: ''
    },
    'google-pay': {
        backgroundColor: '#E7F3FF',
        color: colorConfigs?.sidebar?.bg,
        icon: ''
    },
    "apple-pay": {
        backgroundColor: '#E7F3FF',
        color: colorConfigs?.sidebar?.bg,
        icon: ''
    },
    payout: {
        backgroundColor: '#FFF5D7',
        color: '#F9C21A',
        icon: <AiOutlineGift size={18} />
    },
    rideIncoming: {
        backgroundColor: '#E7F3FF',
        color: colorConfigs?.sidebar?.bg,
        icon: <FaCarSide size={18} />
    },
    rideOutgoing: {
        backgroundColor: '#FFF5D7',
        color: '#F9C21A',
        icon: <FaCarSide size={18} />
    }
};

const BadgeWrapper = styled(Box)(({ theme, bgcolor, color }) => ({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: bgcolor,
    color: color,
    borderRadius: 25,
    padding: '0.25rem 1rem',
    fontWeight: 'bold',
    fontSize: 14,
    width: '220px',
    justifyContent: 'center'

}));

const BadgeIcon = styled(Box)({
    marginRight: '0.5rem',
    display: 'flex',
    alignItems: 'center'
});

const BadgeComponent = ({ trans, text }) => {
    const style = trans?.type === 'ride' && trans?.amount_type === 'incoming' ? badgeStyles.rideIncoming : trans?.type === 'ride' && trans?.amount_type !== 'incoming' ? badgeStyles.rideOutgoing : badgeStyles[trans?.type];

    return (
        <BadgeWrapper bgcolor={style?.backgroundColor} color={style?.color}>
            <BadgeIcon>
                {style?.icon}
            </BadgeIcon>
            <Typography>
                {text}
            </Typography>
            {trans?.type === 'send-money' || (trans?.type === 'ride' && trans?.amount_type !== 'incoming') ||
                (trans?.type === 'google-pay' && trans?.amount_type === 'outcoming') ||
                (trans?.type === 'admin-charge' && trans?.amount_type === 'outcoming') ||
                (trans?.type === 'apple-pay' && trans?.amount_type === 'outcoming')
                ?
                <Box sx={{ background: '#FFFFFF', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 30, height: 30, ml: 1 }}>
                    <BiSolidSend style={{ transform: 'rotate(270deg)' }} />
                </Box> : ''}
            {trans?.type === 'received-money' || (trans?.type === 'ride' && trans?.amount_type === 'incoming') || trans?.type === 'top-up' ||
                (trans?.type === 'admin-charge' && trans?.amount_type === 'incoming') ||
                (trans?.type === 'google-pay' && trans?.amount_type === 'incoming') ||
                (trans?.type === 'apple-pay' && trans?.amount_type === 'incoming')
                ?
                <Box sx={{ background: '#FFFFFF', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 30, height: 30, ml: 1 }}>
                    <BiSolidSend style={{ transform: 'rotate(90deg)' }} />
                </Box>
                : ''}
        </BadgeWrapper>
    );
};

export default BadgeComponent;
