import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Box, Grid, Typography } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Calendar from "../../assets/images/Calendar.jpg";
import CalendarWF from "../../assets/women first icons/Calendar.png";
import icon from "../../assets/images/Icon.png";
import tripIcon from "../../assets/images/Vector.png";
import tripIconWf from "../../assets/women first icons/clarity_car-line.png";
import fare from "../../assets/images/fare.jpg";
import fareWf from "../../assets/women first icons/fluent_payment-28-regular.png";
import game from "../../assets/images/game-icons_path-distance.jpg";
import serviceFeeWF from "../../assets/women first icons/game-icons_path-distance.png";
import location from "../../assets/images/location.png";
import walletMinus from "../../assets/images/wallet-minus.png";
import walletMinusWf from "../../assets/women first icons/wallet-minus.png";
import BestRoute from "../../components/googleMaps/bestRoute";
import colorConfigs from "../../configs/colorConfigs";
import { formatAddress } from "../../utils/localization/addressUtils";
import { formatCurrency } from "../../utils/localization/currencyUtils";
import { formatDate } from "../../utils/localization/dateTimeUtils";
import Status from "../status/status";
import "./index.css";
import { getMethod } from '../Hook/UseCustomApi';
import stopIcon from "../../assets/images/stop.png";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const RideDetails = ({ is_canceled, selectedObjectData }) => {
  const params = useParams();
  const ridesList = useSelector((state) => state.appState.ridesList);
  const ridesListHistory = useSelector((state) => state.appState.ridesListhistory);
  const ridesScheduledList = useSelector((state) => state.appState.ridesScheduledList);
  const rides = useSelector((state) => state.appState.rides);
  const [selectedObject, setSelectedObject] = useState(selectedObjectData);
  const countryCode = useSelector((state) => state.country.selectedCountry);
  const [showAll, setShowAll] = useState(false);
  const stopPoints = selectedObject?.stop_points || [];


  const transformPayType = (payType) => {
    return payType === "cash" ? "Cash" : "Cradit Card";
  };
  const pickup = {
    lat: selectedObject?.pickup_address?.lat,
    lng: selectedObject?.pickup_address?.lng,
  };
  const dropoff = {
    lat: selectedObject?.delivery_address?.lat,
    lng: selectedObject?.delivery_address?.lng,
  };
  const getDateForamted = (date) => {
    const dateObject = new Date(date);

    // Formatting options
    const options = {
      day: "numeric",
      month: "short",
      year: "numeric",
    };

    const formattedDate = dateObject.toLocaleDateString("en-US", options);
    return formattedDate;
  };
  const requirementsList = [
    {
      key: "distance",
      icon: <img src={process.env.REACT_APP_ENV === 'women_first' ? serviceFeeWF : game} alt="distance" />,

      title: "Service fee",
      caption:
        formatCurrency(selectedObject?.pay_info?.service_fee
          ? selectedObject?.pay_info?.service_fee
          : 0, countryCode)
      ,
    },
    {
      key: "date",
      icon: <img src={process.env.REACT_APP_ENV === 'women_first' ? CalendarWF : Calendar} alt="loc" />,
      title: "Date",
      caption: formatDate(selectedObject?.createdAt, countryCode),
    },

    {
      key: "taxes",
      icon: <img src={process.env.REACT_APP_ENV === 'women_first' ? walletMinusWf : walletMinus} alt="loc" />,
      title: "Taxes",
      caption:
        formatCurrency(selectedObject?.pay_info?.tax ? selectedObject?.pay_info?.tax : 0, countryCode),

    },
    {
      key: "fare",
      icon: <img src={process.env.REACT_APP_ENV === 'women_first' ? fareWf : fare} alt="loc" />,
      title: "Fare",
      caption: formatCurrency(selectedObject?.pay_info?.total ? selectedObject?.pay_info?.total : selectedObject?.estimation_cost, countryCode),
    },
    {
      key: "pay",
      icon: <img src={process.env.REACT_APP_ENV === 'women_first' ? fareWf : fare} alt="loc" />,
      title: "Paid with",
      caption: transformPayType(selectedObject?.pay_type),
    },
  ];

  useEffect(() => {
    if (rides === "activeRides") {
      const foundObject = ridesList?.find((obj) => obj._id === params["id"]);
      setSelectedObject(foundObject);
    }
    else if (rides === "history") {
      const foundObject = ridesListHistory?.find((obj) => obj._id === params["id"]);
      setSelectedObject(foundObject);
    } else if (rides === "scheduled") {
      const foundObject = ridesScheduledList?.find((obj) => obj._id === params["id"]);
      setSelectedObject(foundObject);
    } else {
      getMethod({
        url: `ride/dispatch-view/ride/${params["id"]}`,
      }).then((response) => {
        setSelectedObject(response?.data);
      })
    }
  }, [params]);

  const profilePictureUrl =
    selectedObject?.canceled_info?.user_type === "driver"
      ? selectedObject?.driver_info?.profile_picture
      : selectedObject?.customer_details?.profile_picture;


  const wayPoints = selectedObject && selectedObject?.stop_points?.map(point => ({
    location: { lat: point.lat, lng: point.lng },
    stopover: true
  }));







  return (
    <>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "85vh",
          py: 3,
        }}
      >
        <div>
          <div style={{ width: "100%" }}>
            <Grid
              container
              spacing={1}
              style={{
                paddingTop: "30px",
                width: "100%",
                justifyContent: "center",
              }}
            >
              {requirementsList?.map((requirement) => (
                <Grid key={requirement.key} item lg={2} sm={2}>
                  <div
                    style={{
                      border: `0.5px solid lightgray`,
                      borderRadius: "10px",
                      textAlign: "center",
                      padding: "16px 0",
                      boxSizing: "border-box",
                      transition: "0.3s",

                      "&:hover": {
                        boxShadow: "0 4px 6px 0 rgba(0,0,0,0.02)",
                      },
                    }}
                  >
                    {requirement.icon}
                    <Typography
                      variant={"body2"}
                      style={{ marginTop: "8px", color: "#7A869A" }}
                    >
                      {requirement.title}
                    </Typography>
                    <Typography

                      variant={"caption"}
                      style={{ color: "#495974", fontWeight: "bold" }}
                    >
                      {requirement.caption}
                    </Typography>
                  </div>
                </Grid>
              ))}
            </Grid>
          </div>{" "}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
            }}
          >
            <Fragment>
              <div
                style={{
                  position: "relative",
                  backgroundColor: "#FAFAFA",
                  width: "48%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "16px",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: process.env.REACT_APP_ENV === 'women_first' ? "#F9F6FF" : "#D5F1FF",
                      color: colorConfigs?.sidebar?.bg,
                      fontFamily: "Poppins",
                      padding: "6px 12px",
                      borderRadius: "20px",
                      fontWeight: "600",
                      textTransform: "capitalize",
                      display: "flex",
                      alignItems: "center",
                      gap: "6px",
                    }}
                  >
                    <img
                      style={{ width: "20px", height: "20px" }}
                      src={process.env.REACT_APP_ENV === 'women_first' ? tripIconWf : tripIcon}
                      alt="trip-no"
                    />

                    <span style={{ fontSize: "12px" }}>
                      {/* Trip No: {selectedObject?.short_id} */}
                      Ride ID: {selectedObject?.short_id}
                    </span>
                  </div>
                  {is_canceled ? (
                    <Status status={"canceled"} />
                  ) : (
                    <Status status={selectedObject?.status} />
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    padding: "16px",
                    alignItems: "center",
                    margin: stopPoints && stopPoints?.length > 1 ? "" : "25px 0",
                  }}
                >
                  <img src={location} alt="loc" />
                  <Typography
                    variant="body2"
                    style={{
                      color: colorConfigs.sidebar.bg,
                      opacity: "0.8",
                      marginTop: "3px",
                      fontWeight: "bold",
                      marginLeft: "10px",
                    }}
                  >
                    Pick up:
                    <br />
                    <Typography
                      key={selectedObject?.id}
                      variant="caption"
                      style={{
                        color: "black",
                        opacity: "0.8",
                        marginTop: "3px",
                      }}
                    >
                      {formatAddress(selectedObject?.pickup_address, countryCode)}
                    </Typography>
                  </Typography>
                </div>
                <div>
                  {stopPoints && stopPoints?.length > 2 ? (
                    <div
                      style={{
                        display: "flex",
                        padding: "16px",
                        alignItems: "center",
                        justifyContent: "space-between",
                        margin: !showAll ? "20px 0" : "20px 0 0 0",
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img src={stopIcon} alt="loc" style={{ height: "38px", width: "38px" }} />
                        <Typography
                          variant="body2"
                          style={{
                            color: colorConfigs.sidebar.bg,
                            opacity: "0.8",
                            marginTop: "3px",
                            fontWeight: "bold",
                            marginLeft: "10px",
                          }}
                        >
                          Stop points : {stopPoints?.length}
                        </Typography>
                      </div>
                      <div>
                        {!showAll ? (
                          <ExpandMoreIcon onClick={() => setShowAll(true)} style={{ fontSize: '30px', cursor: 'pointer', color: colorConfigs.sidebar.bg }} />
                        ) : (
                          <ExpandLessIcon onClick={() => setShowAll(false)} style={{ fontSize: '30px', cursor: 'pointer', color: colorConfigs.sidebar.bg }} />
                        )}
                      </div>
                    </div>
                  ) : (
                    stopPoints && stopPoints?.map((stopPoint, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          padding: "16px",
                          alignItems: "center",
                        }}
                      >
                        <img src={stopIcon} alt="loc" style={{ height: "38px", width: "38px" }} />
                        <Typography
                          variant="body2"
                          style={{
                            color: colorConfigs.sidebar.bg,
                            opacity: "0.8",
                            marginTop: "3px",
                            fontWeight: "bold",
                            marginLeft: "10px",
                          }}
                        >
                          Stop point: {index + 1}
                          <br />
                          <Typography
                            variant="caption"
                            style={{
                              color: "black",
                              opacity: "0.8",
                              marginTop: "3px",
                            }}
                          >
                            {stopPoint.address}
                          </Typography>
                        </Typography>
                      </div>
                    ))
                  )}
                  {showAll && stopPoints && stopPoints.length > 2 && stopPoints?.map((stopPoint, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        padding: "10px",
                        alignItems: "center",
                        marginLeft: "60px",
                      }}
                    >
                      <img src={stopIcon} alt="loc" style={{ height: "38px", width: "38px" }} />
                      <Typography
                        variant="body2"
                        style={{
                          color: colorConfigs.sidebar.bg,
                          opacity: "0.8",
                          marginTop: "3px",
                          fontWeight: "bold",
                          marginLeft: "10px",
                        }}
                      >
                        Stop point: {index + 1}
                        <br />
                        <Typography
                          variant="caption"
                          style={{
                            color: "black",
                            opacity: "0.8",
                            marginTop: "3px",
                          }}
                        >
                          {stopPoint?.address}
                        </Typography>
                      </Typography>
                    </div>
                  ))}
                </div>
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    padding: "16px",
                    margin: stopPoints && stopPoints?.length > 1 ? "" : "25px 0",
                  }}
                >
                  <img src={icon} alt="loc" />
                  <Typography
                    variant="body2"
                    style={{
                      color: colorConfigs.sidebar.bg,
                      opacity: "0.8",
                      marginTop: "3px",
                      fontWeight: "bold",
                      marginLeft: "10px",
                    }}
                  >
                    Drop Off:
                    <br />
                    <Typography
                      key={selectedObject?.id}
                      variant="caption"
                      style={{
                        color: "black",
                        opacity: "0.8",
                        marginTop: "3px",
                      }}
                    >
                      {selectedObject?.delivery_address?.address}
                    </Typography>
                  </Typography>
                </div>
              </div>

              <div style={{ width: "50%" }}>
                {
                  selectedObject?.real_image || selectedObject?.approximate_image ? (
                    <img
                      src={selectedObject?.real_image ? selectedObject?.real_image : selectedObject?.approximate_image}
                      alt=''
                      style={{ width: '100%', height: 'auto' }}
                    />
                  ) : (
                    <BestRoute pickup={pickup} dropoff={dropoff} wayPoints={wayPoints} />
                  )
                }

              </div>
            </Fragment>
          </div>
        </div>

        {selectedObject?.is_canceled ? (
          <>
            <h3>Cancellation Reason: </h3>
            <div style={{ backgroundColor: "#F9F9F9", padding: "16px" }}>
              <Box style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "12px" }}
                >
                  {profilePictureUrl ? (
                    <img
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                      }}
                      src={profilePictureUrl}
                      alt="profile"
                    />
                  ) : (
                    <AccountCircleIcon
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                      }}
                    />
                  )}
                  <span
                    style={{ fontWeight: "600", textTransform: "capitalize" }}
                  >
                    {selectedObject?.canceled_info?.user_type === "driver"
                      ? selectedObject?.driver_info?.first_name +
                      " " +
                      selectedObject?.driver_info?.last_name
                      : selectedObject?.customer_details?.first_name +
                      " " +
                      selectedObject?.customer_details?.last_name}
                  </span>
                  <span
                    style={{
                      backgroundColor: process.env.REACT_APP_ENV === 'women_first' ? "#F9F6FF" : "#D5F1FF",
                      color: colorConfigs?.sidebar?.bg,
                      fontFamily: "Poppins",
                      padding: "6px 12px",
                      borderRadius: "20px",
                      fontWeight: "600",
                      textTransform: "capitalize",
                    }}
                  >
                    {selectedObject?.canceled_info?.user_type}
                  </span>
                </div>
                <div>{formatDate(selectedObject?.canceled_at, countryCode)}</div>
              </Box>
              <Box>
                <p style={{ padding: "16px" }}>
                  {selectedObject?.canceled_info?.reason}
                </p>
              </Box>
            </div>
          </>
        ) : (
          ""
        )}
      </Box>
    </>
  );
};

const styles = {
  title: {
    fontWeight: "bold",
    fontSize: "26px",
    marginTop: "5px",
  },
  subTitle: {
    fontWeight: "bold",
    fontSize: "16px",
    marginTop: "5px",
    marginBottom: "5px",
  },
  companyImage: {
    width: "150px",
    height: "150px",
    border: "2px solid #EEEEEE7A",
    borderRadius: 10,
  },
  companyImageMobileSize: {
    width: "90px",
    height: "90px",
    border: "2px solid #EEEEEE7A",
    borderRadius: 10,
    marginTop: "0px",
  },
};
export default RideDetails;
