import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { db } from "../../configs/firebase";
import MessagingPrivate from "./messaging-index";
import { selectChat, selectRide } from "../../redux/features/appStateSlice";
import RR_empty_Chat from '../../assets/icons/chat_empty_RR.png'
import WM_empty_Chat from '../../assets/icons/chat_empty_WM.png'
const MessagingContainer = ({ openDialog }) => {
  const [job, setjob] = useState(null);
  const [loading, setLoading] = useState(true);
  const selectedChat = useSelector((state) => state.appState.selectedChat);
  const selectedRide = useSelector((state) => state.appState.selectedRide);
  const user = useSelector((state) => state.appState.userData);
  const outChat = useSelector((state) => state.appState.outChat);
  const lastRideChat = useSelector((state) => state.appState.lastRideChat);
  const theme = useTheme();
  const uid = selectedChat?.type === 'driver' ? selectedChat?.driver : selectedChat?.customer

  const dispatch = useDispatch();
 
  const useStyles = {
    container: {
      background: "white",
      borderRadius: "8px",
      margin: "10px",
      border: "1.5px solid rgb(245,245,245)",
      boxSizing: "border-box",
      boxShadow: "0 1px 2px rgb(0,0,0,0.1)",
      [theme.breakpoints.down("sm")]: {
        height: "calc(90vh)",
        border: "none",
        boxShadow: "none",
        padding: 0,
      },
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      padding: "12px 28px",
      border: "1.5px solid rgb(245,245,245)",
      background: "white",
      borderRadius: "8px",
      boxShadow: "0 1px 2px rgb(0,0,0,0.1)",
      margin: "10px",
      width: "80%",
    },
  };
  useEffect(() => {

    if (selectedChat?.rideId || selectedRide?._id) {
      setjob(null);
      setLoading(true);
      let msgRef
      msgRef = db.collection("messaging").doc(outChat ? selectedRide?._id : selectedChat?.rideId ?? "empty");
      const expirationDate = new Date();
      expirationDate.setHours(expirationDate.getHours() + 6);

      const observer = msgRef.onSnapshot((documentSnapshot) => {
        if (documentSnapshot.exists) {
          const data = documentSnapshot.data();
          data.id = documentSnapshot.id;
          setjob(data);
        } else {

          
          // Document does not exist, create it
          const newData = {
            customer: outChat ? selectedRide?.customer_details?.fb_uid : selectedChat?.customer ?? "empty",
            driver: outChat ? selectedRide?.driver_info?.fb_uid : uid ?? "empty",
            rideId: outChat ? selectedRide?._id : selectedChat?.rideId ?? "empty",
            expired: expirationDate,
            title: outChat ? selectedRide?.short_id : selectedChat?.title ?? "empty",
            image: "",
            lastMessage: {
              message: "",
              uid: "",
            },
            lastRead: {
              admin: "",
            },
            typing: {
              admin: false,
            },
            users: [
              "admin",
              outChat ? selectedRide?.customer_details?.fb_uid : selectedChat?.customer,
              outChat ? selectedRide?.driver_info?.fb_uid : selectedChat?.driver,
            ],
            unRead: {
              admin: false,
            },
            user_name: user.name ?? '',
            user_phoneNumber: user?.phone_number ?? "",
            user_profile: user?.profile_picture ?? "",
            updatedAt: new Date(),
          };
          msgRef
            .set(newData)
            .then(() => {
              setjob(newData);
            })
            .catch((error) => {
              console.error("Error creating document: ", error);
            });
        }
        setLoading(false);

      });


      return () => {
        observer();
      };
    } else if (lastRideChat) {
      setLoading(true);
      let msgRef
      msgRef = db.collection("messaging").doc(outChat ? lastRideChat?._id : lastRideChat?.rideId ?? "empty");
      const expirationDate = new Date();
      expirationDate.setHours(expirationDate.getHours() + 6);

      const observer = msgRef.onSnapshot((documentSnapshot) => {
        if (documentSnapshot.exists) {
          const data = documentSnapshot.data();
          data.id = documentSnapshot.id;
          console.log(data)
          setjob(data);
        }

      })
      setLoading(false);

      return () => {
        observer();
      };
    }
  }, [selectedChat?.rideId, selectedRide, selectedChat, lastRideChat?.rideId]);
  return (
    <div>
      {job ? (
        <div style={useStyles.container}>
          <Grid container direction="row">
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <MessagingPrivate allowSend />
            </Grid>
          </Grid>
        </div>
      ) : (
        <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          marginTop: "20%",
        }}
      >
        <img src={process.env.REACT_APP_ENV === 'women_first' ? WM_empty_Chat : RR_empty_Chat} />
        <Typography sx={{mt:3}} variant="body1" bold>
          You don’t have a message selected
        </Typography>
        <Typography
          variant="body2"
          regular
          style={{
            color: "grey",
            maxWidth: "350px",
            textAlign: "center",
            marginTop: "8px",
          }}
        >
          All messages are private conversations between Admins & Drivers or
          Customers on {process.env.REACT_APP_ENV === 'women_first' ? 'women-s-first' : "RideWyze"}.
        </Typography>
      </div>
      )}
    </div>
  );
};

export default MessagingContainer;
