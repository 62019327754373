import { Avatar, Box, Button, Grid, IconButton, Typography, useMediaQuery } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { styled, useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { FaPhone } from "react-icons/fa6";
import { IoMdClose } from 'react-icons/io';
import { MdContentCopy, MdEmail } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, openModal } from '../../../redux/features/modalSlice';
import stopIcon from "../../../assets/images/stop.png";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import icon from "../../../assets/images/Icon.png";
import location from "../../../assets/images/location.png";
import colorConfigs from '../../../configs/colorConfigs';
import { selectChat, selectRide, updateOutChat } from '../../../redux/features/appStateSlice';
import { getMethod } from '../../Hook/UseCustomApi';
import DriverAndCustomerChatModal from './DriverAndCustomerChatModal';
import RideChatModel from './RideChatModel';
import ClickablePhoneNumber from '../../../utils/ClickablePhoneNumber';
import CancelRideModal from '../../rides/CancelRideModal';
import AssignRideModal from './AssignRideModal';

const ModalContainer = styled(Box)(({ theme }) => ({
    background: '#FFFFFF',
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    width: '806px',
    height: '650px',
    maxWidth: '95%',
    maxHeight: '94vh',
    overflow: 'auto',
    margin: 'auto',
}));

const Header = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '3px 10px',
}));

const CloseButton = styled(Box)(({ theme }) => ({
    background: '#F0F0F0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer',
}));

const Section = styled(Grid)(({ theme }) => ({
    backgroundColor: '#f5f7f8',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
}));

const InfoBox = styled(Grid)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    marginBottom: 2

}));

const AddressText = styled(Typography)(({ theme }) => ({
    fontWeight: 700,
    fontFamily: "Poppins",
    fontSize: 12,
}));

const TimeText = styled(Typography)(({ theme }) => ({
    color: "#7A869A",
    fontSize: 13,
    fontWeight: 500,
    fontFamily: "Poppins",
}));

const DotDividerContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& div': {
        width: 4,
        height: 4,
        borderRadius: '50%',
        backgroundColor: '#E0E0E0',
        margin: '1px 0',
    },
}));

const Card = styled(Box)(({ theme }) => ({
    background: 'white',
    padding: theme.spacing(1),
    boxShadow: 'rgba(0, 0, 0, 0.05) 0 4px 36px',
    borderRadius: theme.shape.borderRadius,
    margin: theme.spacing(1, 0, 0, 0),
}));

const CardHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#F4F4F4',
    padding: theme.spacing(1, 2),
    borderRadius: theme.shape.borderRadius,
}));

const ContactInfo = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(0.7),
    '& svg': {
        marginRight: theme.spacing(0.5),
        color: colorConfigs?.sidebar?.bg,
    },
}));

const InfoText = styled(Typography)(({ theme }) => ({
    color: '#495974',
    fontSize: 12,
    fontWeight: 400,
}));

const BoldText = styled(Typography)(({ theme }) => ({
    fontWeight: 700,
    color: '#172B4D',
    fontSize: 14,
}));

const GreenText = styled(Typography)(({ theme }) => ({
    fontWeight: 700,
    color: '#0FBE00',
    fontSize: 14,
}));

const SuspendButton = styled(Button)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(2),
    padding: theme.spacing(1),
    background: '#E11719',
    color: 'white',
    paddingX: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    '& svg': {
        marginRight: theme.spacing(1),
    },
    '&:hover': {
        background: '#c31517',
    },
}));

const DotDivider = () => (
    <DotDividerContainer>
        <div></div>
        <div></div>
        <div></div>
    </DotDividerContainer>
);

const CustomerCard = ({ active_ride_id, ride, updateWhenCancel }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const selectedRide = useSelector((state) => state.appState.selectedRide);
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [loading, setLoading] = useState(false);
    const countryCode = useSelector((state) => state.country.selectedCountry);
    const [showAll, setShowAll] = useState(false);

    const handleClose = () => {
        dispatch(closeModal());
        dispatch(updateOutChat(false));
        dispatch(selectChat({}));
        dispatch(selectRide({}));
    };

    const getRideDetails = () => {
        setLoading(true);
        dispatch(selectRide({}));
        getMethod({
            url: `ride/${active_ride_id}`,
        })
            .then((response) => {
                dispatch(selectRide(response.data));
                setLoading(false);
            })
            .catch((error) => {
                console.log("Error on getting Cancel Dnd Dispatch Rides:", error);
                setLoading(false);
            });
    };

    const openChatDriverAndCustomer = (type) => {
        dispatch(selectRide({ ...selectedRide, chat_type: type }));
        dispatch(updateOutChat(true));
        dispatch(openModal(<DriverAndCustomerChatModal handleClose={handleClose} />))
    }
    const openChatRide = () => {
        dispatch(selectRide({ ...selectedRide, chat_type: 'ride' }));
        dispatch(updateOutChat(true));
        dispatch(openModal(<RideChatModel handleClose={handleClose} />))
    }

    const getWaitingTime = (firstTime, secondTime) => {
        if (firstTime === undefined || secondTime === undefined || firstTime === null || secondTime === null) {
            return "-";
        }
        else {
            const firstTimestamp = new Date(firstTime).getTime();
            const secondTimestamp = new Date(secondTime).getTime();

            // Calculate the difference in milliseconds
            const timeDifference = secondTimestamp - firstTimestamp;
            // Convert milliseconds to seconds and minutes
            let waitingTimeInSeconds;
            if (timeDifference < 0) {
                waitingTimeInSeconds = 0;
            } else {
                waitingTimeInSeconds = Math.floor(timeDifference / 1000);
            }
            const minutes = Math.floor(waitingTimeInSeconds / 60);
            const seconds = waitingTimeInSeconds % 60;

            // Format the waiting time
            const formattedTime = `${minutes.toString().padStart(2, "0")}:${seconds
                .toString()
                .padStart(2, "0")} min`;

            return formattedTime;
        }
    };

    useEffect(() => {
        if (!ride) {
            getRideDetails();
        } else {
            dispatch(selectRide(ride));
        }
    }, [active_ride_id, ride, ride]);

    const handleCloseCancelRide = () => {
        dispatch(openModal(<CustomerCard ride={ride} />));
    }
    const afterSubmit = () => {
        dispatch(closeModal());
        updateWhenCancel()
    };

    const handleCancelRide = () => {
        dispatch(openModal(<CancelRideModal afterSubmit={afterSubmit} handleClose={handleCloseCancelRide} ride_id={ride?._id} />))
    };
    // dispatch(openModal(<AssignRideModal/>))

    return (
        <ModalContainer>
            <Header>
                <Typography variant="h6" sx={{ color: '#172B4D', fontWeight: 700 }}>
                    Customer Details
                </Typography>
                <CloseButton onClick={handleClose}>
                    <IoMdClose size={20} />
                </CloseButton>
            </Header>
            <Card>
                <Section container>
                    <InfoBox item xs={12} sm={6}>
                        <Typography variant="body1" sx={{ color: '#6C757D', fontSize: 12, display: 'flex', alignItems: 'center' }}>
                            <Typography component={'span'} sx={{ color: '#6C757D', fontSize: 12, fontWeight: 400 }}>
                                Trip ID:
                            </Typography>
                            {loading ? (
                                <Skeleton sx={{ ml: 1 }} width={100} />
                            ) : (
                                <>
                                    <BoldText component="span" ml={1}>
                                        {selectedRide?.short_id}
                                    </BoldText>
                                    <IconButton onClick={() => navigator.clipboard.writeText(selectedRide?.short_id)} size="small" sx={{ ml: 1, p: 0 }}>
                                        <MdContentCopy />
                                    </IconButton>
                                </>
                            )}
                        </Typography>
                    </InfoBox>
                </Section>
                <Box sx={{ mt: 2 }}>
                    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}>
                        {!isSmallScreen && (
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mr: 2 }}>
                                <img src={location} alt="loc" />
                                <DotDivider />
                                {ride?.stop_points && (
                                    <>
                                        {(ride?.stop_points?.length <= 2) || (showAll) ? (
                                            ride?.stop_points?.map((_, index) => (
                                                <React.Fragment key={index}>
                                                    <img src={stopIcon} alt="stop" style={{ height: "38px", width: "38px" }} />
                                                    {<DotDivider />}
                                                </React.Fragment>
                                            ))
                                        ) : (
                                            <>
                                                <img src={stopIcon} alt="stop" style={{ height: "38px", width: "38px" }} />
                                                <DotDivider />
                                            </>
                                        )}
                                    </>
                                )}
                                <img src={icon} alt="loc" />
                            </Box>
                        )}
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ mt: 1, width: '100%' }}>
                                <AddressText sx={{ color: colorConfigs?.sidebar?.bg }}>
                                    Pick up
                                </AddressText>
                                {loading ? (
                                    <Skeleton width="100%" />
                                ) : (
                                    <TimeText>{selectedRide?.pickup_address?.address}</TimeText>
                                )}
                            </Box>
                            {!showAll && ride?.stop_points.length > 0 && ride?.stop_points.length > 2 && (
                                <Box sx={{ mt: 2, width: '100%' }} style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'start',
                                }}>
                                    <AddressText sx={{ color: colorConfigs?.sidebar?.bg }}>
                                        Stop points : {ride?.stop_points.length}
                                    </AddressText>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        width: "20px",
                                        marginLeft: "25px"
                                    }}>
                                        <ExpandMoreIcon
                                            onClick={() => setShowAll(true)}
                                            sx={{
                                                fontSize: '26px',
                                                cursor: 'pointer',
                                                color: colorConfigs.sidebar.bg,
                                                transition: 'transform 0.2s ease-in-out',
                                                '&:hover': {
                                                    transform: 'scale(1.2)',
                                                }
                                            }}
                                        />                                    </div>
                                </Box>
                            )}
                            {(showAll || ride?.stop_points?.length <= 2) && ride?.stop_points?.map((stopPoint, index) => (
                                <Box sx={{ mt: 2, width: '100%' }} key={index}>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'start',
                                    }}>
                                        <AddressText sx={{ color: colorConfigs?.sidebar?.bg }}>
                                            Stop point:{index + 1}
                                        </AddressText>
                                        {index === 0 && (
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                width: "20px",
                                                marginLeft: "41px"
                                            }}>
                                                {showAll && (
                                                    <ExpandLessIcon
                                                        onClick={() => setShowAll(false)}
                                                        sx={{
                                                            fontSize: '26px',
                                                            cursor: 'pointer',
                                                            color: colorConfigs.sidebar.bg,
                                                            transition: 'transform 0.2s ease-in-out',
                                                            '&:hover': {
                                                                transform: 'scale(1.2)',
                                                            }
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        )}
                                    </div>

                                    {loading ? (
                                        <Skeleton width="100%" />
                                    ) : (
                                        <TimeText>{stopPoint?.address}</TimeText>
                                    )}
                                </Box>
                            ))}
                            <Box sx={{ mt: 2 }}>
                                <AddressText sx={{ color: '#03992D' }}>
                                    Drop off
                                </AddressText>
                                {loading ? (
                                    <Skeleton width="100%" />
                                ) : (
                                    <TimeText>{selectedRide?.delivery_address?.address}</TimeText>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Card>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card sx={{ height: '100%' }}>
                        <CardHeader>
                            <Typography sx={{ fontWeight: '800', fontSize: 14, color: '#172B4D' }}>
                                Passenger Info
                            </Typography>
                        </CardHeader>
                        <Box sx={{ display: 'flex', alignItems: 'start', mt: 1 }}>
                            {loading ? (
                                <Skeleton sx={{ mr: 1 }} variant="rectangular" width={110} height={110} />
                            ) : (
                                <Avatar alt="Passenger" src={selectedRide?.customer_details?.profile_picture} sx={{ width: 90, height: 90, mr: 2, borderRadius: 2 }} />
                            )}
                            <Box sx={{ width: '100%' }}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start', width: '100%' }}>
                                    {loading ? (
                                        <Skeleton width={100} />
                                    ) : (
                                        <BoldText mr={1}>
                                            {selectedRide?.customer_details?.first_name} {selectedRide?.customer_details?.last_name}
                                        </BoldText>
                                    )}

                                </Box>
                                {loading ? <Skeleton width="100%" /> : selectedRide?.customer_details?.email && <ContactInfo variant="body2"><MdEmail /> {selectedRide?.customer_details?.email}</ContactInfo>}
                                {loading ? <Skeleton width="100%" /> : <ContactInfo variant="body2"><FaPhone /> {ClickablePhoneNumber(selectedRide?.customer_details?.code_phone_number, selectedRide?.customer_details?.phone_number)} </ContactInfo>}
                            </Box>
                        </Box>
                    </Card>
                    {selectedRide && (selectedRide?.status !== 'pickedup' && selectedRide?.status !== 'enroute_to_delivery' && selectedRide?.status !== 'waiting_delivery' && selectedRide?.status !== 'delivered' && selectedRide?.status !== 'canceled') && <Button
                        variant="outlined"
                        sx={{
                            borderColor: 'red',
                            color: 'red',
                            fontWeight: 'bold',
                            borderRadius: 1,
                            mt: 2,
                            textTransform: 'none',
                            '&:hover': {
                                borderColor: 'red',
                                backgroundColor: 'transparent',
                            },
                        }}
                        onClick={handleCancelRide}
                    >
                        Cancel Ride
                    </Button>}
                </Grid>
                {selectedRide?.created_by === 'dispatch' && (
                    <Typography sx={{ ml: 'auto', mt: 2, fontSize: 12, fontWeight: 'bold' }}>
                        Created By Admin
                    </Typography>
                )}            </Grid>
        </ModalContainer>
    );
};

export default CustomerCard;
