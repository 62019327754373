import GroupsIcon from "@mui/icons-material/Groups";
import SearchIcon from "@mui/icons-material/Search";
import StarIcon from "@mui/icons-material/Star";
import { Alert, Box, InputAdornment, Snackbar, Switch, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Loading from "react-fullscreen-loading";
import { AiFillEdit } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import colorConfigs from "../../configs/colorConfigs";
import { auth } from "../../configs/firebase";
import { setEnableEdit, setRidersList, setSelectedUser } from "../../redux/features/appStateSlice";
import { NewFormatPhoneNumber } from "../../utils/localization/NewPhoneUtils";
import { getMethod, putMethod } from "../Hook/UseCustomApi";
import DynamicTable from "../table/table";
import Error5xx from "../../components/common/HandelError5xx";
import { formatDate } from "../../utils/localization/dateTimeUtils";

export default function RiderPage() {
  const [count, setCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const rowsLimits = [5, 10, 25, 100];
  const [search, setSearch] = useState(false);
  const dispatch = useDispatch();
  let currentPage = useRef();
  let currentLimit = useRef(10);
  let currentKeyword = useRef();
  let currentStatus = useRef();
  const navigate = useNavigate();
  const countryCode = useSelector((state) => state.country.selectedCountry);
  const ridersList = useSelector((state) => state.appState.riders);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [data, setData] = useState(ridersList);

  //handle error5xx
  const [tryAgain, setTryAgain] = useState();
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [errorCount, setErrorCount] = useState(0);
  const [errorDialogCounter, setErrorDialogCounter] = useState(0);
  const handleError5xx = () => {
    setIsErrorDialogOpen(true);
    setErrorCount(prevCount => {
      const newCount = prevCount + 1;
      if (newCount === 1) {
        setErrorDialogCounter(5);
      } else if (newCount === 2) {
        setErrorDialogCounter(10);
      }
      return newCount;
    });
  };
  const handleCloseErrorDialog = () => {
    setIsErrorDialogOpen(false);
    if (errorCount <= 2) {
      handleErrorCountByClose()
    }

  };
  const handleErrorCountByClose = () => {
    setErrorCount(prevCount => {
      const newCount = prevCount - 1;
      if (newCount === 1) {
        setErrorDialogCounter(5);
      } else if (newCount === 2) {
        setErrorDialogCounter(10);
      }
      return newCount;
    });
  }

  const licAndConfig = useSelector((state) => state.licencesAndConfiguration);
  console.log(licAndConfig);




  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  useEffect(() => {
    dispatch(setEnableEdit(false));
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0 });
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        if (ridersList?.length === 0) {
          fetchRiders();
        } else {
          setData(ridersList);
        }
      } else {
        console.log("No user logged in.");
      }
    });

    return () => unsubscribe(); // Clean up the subscription
  }, []);

  const renderStars = (count) => {
    if (count === undefined) {
      return (
        <Box display="flex" alignItems="center">
          <Typography>-</Typography>
        </Box>
      );
    }
    return (
      <Box display="flex" alignItems="center">
        <StarIcon
          style={{ color: "#FFCC00", width: "19.91px", height: "19.49px" }}
        />
        <Typography style={{ marginLeft: "1px" }}>{count}</Typography>
      </Box>
    );
  };



  useEffect(() => {
    currentPage.current = 1;
    currentLimit.current = 10;
    currentKeyword.current = "";
    currentStatus.current = "all";
    fetchRiders(); // Updated function call
  }, []);

  const fetchRiders = () => {
    if (ridersList?.length === 0 && !search) {
      setLoading(true);
    } else {
      setLoading(false);
    }

    const getParam = () => {
      return {
        page: currentPage.current,
        limit: currentLimit.current,
        status: currentStatus.current,
        search_first_name: currentKeyword.current,
        search_last_name: currentKeyword.current,
        search_short_id: currentKeyword.current,
        search_email: currentKeyword.current,
        search_phone_number: currentKeyword.current,
      };
    };

    getMethod({
      url: "customer",
      params: getParam(),
      handleErrorMessage: showSnackbar,
    })
      .then((response) => {
        let result = response?.data;
        console.log(result);

        if (JSON.stringify(result) !== JSON.stringify(ridersList)) {
          dispatch(setRidersList(result));
        }
        setData(result);
        setCount(response?.count);
        setLoading(false)
      })
      .catch((error) => {
        console.error("Error fetching riders:", error);
        setLoading(false)
        if (error === "Internal Server Error: Server error occurred.") {
          handleError5xx();
          setTryAgain(() => fetchRiders);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    currentPage.current = newPage;
    fetchRiders(); // Updated function call
  };

  const handleRowsChange = (rows) => {
    currentPage.current = 1;
    currentLimit.current = rows;
    setPage(1);
    fetchRiders(); // Updated function call
  };

  useEffect(() => {
    dispatch(setRidersList(data));
  }, [data]);

  const handleSearch = (e) => {
    setSearch(true)
    setKeyword(e.target.value);
    setPage(1);
    currentPage.current = 1;
    currentKeyword.current = e.target.value;
    fetchRiders(); // Updated function call
  };

  const handleEnableDisableChange = (admin, newValue) => {
    let data = {
      enabled: newValue,
    };

    putMethod({
      url: `customer/${admin?._id}`,
      body: data,
      handleErrorMessage: showSnackbar,
    })
      .then(() => {
        // Update the data array locally
        setData((prevData) => {
          const updatedData = prevData.map((item) => {
            if (item._id === admin?._id) {
              return {
                ...item,
                enabled: newValue,
              };
            }
            return item;
          });
          return updatedData;
        });
      })
      .catch((error) => {
        console.error("Error updating Active status:", error);
        if (error === "Internal Server Error: Server error occurred.") {
          handleError5xx();
          setTryAgain(() => () => handleEnableDisableChange(admin, newValue));
        }
      });
  };

  const emailStyle = {
    color: "#696969",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "24px",
    letterSpacing: "0.17px",
  };

  const style = {
    color: "#696969",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "24px" /* 150% */,
    letterSpacing: "0.17px",
    textTransform: "capitalize",
  };

  const columns = [
    {
      field: "short_id",
      headerName: "Passenger ID",
      flex: 1,
      minWidth: 130,
      editable: true,
      align: "left",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <span
          style={{ textDecoration: "underline", fontWeight: "bold", cursor: "pointer" }}
          onClick={() => { navigate(`/passengers/details/${params.row._id}`); dispatch(setSelectedUser(params.row)); }}
        >
          {params?.row?.short_id}
        </span>
      ),
    },
    {
      field: "full_name",
      headerName: "Name",
      flex: 1,
      minWidth: "200px",
      style: style,
      align: "left",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <Box style={{ display: "flex", alignItems: "center", gap: "12px", cursor: "pointer" }}
          onClick={() => { navigate(`/passengers/details/${params.row._id}`); dispatch(setSelectedUser(params.row)); }}
        >
          <img
            style={{
              width: "34px",
              height: "34px",
              borderRadius: "50%",
            }}
            src={params.row.profile_picture ? params.row.profile_picture : "https://i.pinimg.com/736x/de/59/4e/de594ec09881da3fa66d98384a3c72ff.jpg"}
            alt=""
          />
          <span style={{ textDecoration: "underline", fontWeight: "bold", cursor: "pointer" }}
          >
            {params.row.first_name
              ? params.row.first_name + " " + params.row.last_name
              : "-"}
          </span>
        </Box>
      ),
    },
    {
      field: "phone_number",
      headerName: "Phone",
      minWidth: "170px",
      flex: 1,
      style: style,
      align: "left",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        const formattedPhoneNumber = NewFormatPhoneNumber(
          params.row.phone_number,
          countryCode
        );
        return <Typography>{formattedPhoneNumber}</Typography>;
      },
    },
    {
      field: "creation_date",
      headerName: "Creation Date",
      minWidth: "190px",
      flex: 1,
      style: style,
      align: "left",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        const dateObject = new Date(params?.row?.createdAt);
        const formattedDate = formatDate(dateObject, countryCode);
        return (
          <span>
            {formattedDate}
          </span>
        );
      },
    },
    {
      field: "avgRating",
      headerName: "Rating",
      flex: 1,
      minWidth: "30px",
      editable: true,
      style: style,
      align: "left",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <Box display="flex" justifyContent="center" mt={1}>
          {renderStars(params.row.review_info?.total_avg)}
        </Box>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: "150px",
      style: style,
      align: "left",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "enabled",
      headerName: "Enable/Disable",
      headerClassName: "super-app-theme--header",
      minWidth: "50px",
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Switch
            sx={{
              color: colorConfigs?.mainColor,
              "& .MuiSwitch-switchBase.Mui-checked": {
                color: "green",
              },
              "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                backgroundColor: "green",
              },
            }}
            checked={params.row.enabled}
            onChange={(event) =>
              handleEnableDisableChange(params.row, event.target.checked)
            }
          />
        </div>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      style: style,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          <Box
            style={{
              width: "30px",
              height: "30px",
              backgroundColor: "#F3F8FD",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => { navigate(`/passengers/details/${params.row._id}`); dispatch(setSelectedUser(params.row)); }}
          >
            <AiFillEdit size="20px" style={{ color: "#5B93FF" }} />
          </Box>
        </div>
      ),
    },
  ];

  return (
    <>
      {/* handleError5xx */}
      <Error5xx open={isErrorDialogOpen} errCount={errorCount} count={errorDialogCounter} handleClose={handleCloseErrorDialog} tryAgain={tryAgain} />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h1
          style={{
            color: "#000",
            fontSize: "26px",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "137%",
            letterSpacing: "-0.8px",
          }}
        >
          <GroupsIcon size={"24px"} color="white" /> Passengers
        </h1>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "14px",
            marginBottom: "14px",
            width: "55%",
          }}
        >
          <TextField
            fullWidth
            label="Search For Passengers"
            name="keyword"
            placeholder="Search By Short_Id , Name , Email or Phone."
            value={keyword}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: "gray" }} />
                </InputAdornment>
              ),
            }}
            variant="outlined"
            sx={{
              "& label.Mui-focused": { color: "rgba(0, 0, 0, 0.6)" },
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: "#A0AEC0",
                },
                borderRadius: "10px",
                height: "52px",
              },
            }}
          />
        </div>
      </div>
      <DynamicTable
        columns={columns}
        data={data}
        count={count}
        page={page}
        rowsLimits={rowsLimits}
        pageChange={handlePageChange}
        rowsChange={handleRowsChange}
        pageType="passengers"
      />
      {loading && (
        <Loading
          loading={!!loading}
          background="rgba(86, 100, 210, 0.1)"
          loaderColor={colorConfigs.sidebar?.bg}
        />
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleSnackbarClose} severity="error">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
