import React, { useState, useEffect, useRef } from 'react';
import {
    Box,
    Typography,
    IconButton,
    List,
    Paper,
    Button,
    Tooltip,
} from '@mui/material';
import { TextInput } from "@zetaton-dev/zetaton-components-bank";
import { styled } from '@mui/material/styles';
import { RiSendPlaneFill } from "react-icons/ri";
import colorConfigs from '../../configs/colorConfigs';
import { GridCloseIcon } from '@mui/x-data-grid';
import { closeModal } from '../../redux/features/modalSlice';
import { db } from '../../configs/firebase';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';
import { useSwipeable } from 'react-swipeable';

const useStyles = {
    padding: {
        padding: "12px 16px",
    },
};

const nameValue = {
    paddingLeft: "10px",
    fontWeight: "600",
    fontFamily: "Poppins",
    fontSize: "16px",
    lineHeight: "18px",
    color: "#172B4D",
    textDecoration: "underline"
};

const ModalContainer = styled(Box)(({ theme }) => ({
    background: '#FFFFFF',
    padding: "20px",
    borderRadius: "12px",
    width: '650px',
    maxWidth: '100%',
    maxHeight: '94vh',
    overflow: 'auto',
    margin: '0 auto',
    display: "flex",
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
}));

const ChatContainer = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    height: '60vh',
    width: "95%",
    overflowY: 'auto',
    border: 'none',
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: "12px",
}));

const MessageInputContainer = styled(Box)(() => ({
    marginTop: 10,
    border: "none",
    background: '#FAFAFB',
    borderRadius: "13.28px",
    width: "95%",
    height: "50px",
}));

const Header = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '3px 10px',
    width: '100%',
}));

const nameImg = {
    marginLeft: "10px",
    marginTop: "-10px",
    width: "40px",
    height: "40px",
    borderRadius: "50%",
};

const TicketChat = ({ details, collectionName, chatId }) => {
    const dispatch = useDispatch();
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const adminData = useSelector((state) => state.appState.userData);

    const [isGalleryOpen, setIsGalleryOpen] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [galleryImages, setGalleryImages] = useState([]);

    const chatContainerRef = useRef(null);
    const messagesEndRef = useRef(null);

    useEffect(() => {
        const fetchChatData = async () => {
            const chatDoc = db.collection(collectionName).doc(chatId);
            const doc = await chatDoc.get();

            if (!doc.exists) {
                await chatDoc.set({
                    customer: details?.owner_type === "customer" ? details?.owner_info?.id : null,
                    driver: details?.owner_type === "driver" ? details?.owner_info?.id : null,
                    expired: null,
                    image: "",
                    lastMessage: {
                        message: "",
                        uid: "",
                    },
                    lastRead: {
                        admin: "",
                    },
                    typing: {
                        admin: false,
                    },
                    unRead: {
                        admin: false,
                    },
                    rideId: chatId,
                    title: "",
                    type: details?.owner_type,
                    updatedAt: new Date(),
                    user_name: details?.owner_info?.first_name + " " + details?.owner_info?.last_name,
                    user_phoneNumber: details?.owner_info?.phone_number,
                    user_profile: details?.owner_info?.profile_picture,
                    users: [
                        "admin",
                        details?.owner_info?.id,
                    ],
                });
            }

            const unsubscribe = chatDoc.collection("messages")
                .orderBy("createAt", "asc")
                .onSnapshot(snapshot => {
                    const messagesList = snapshot.docs.map(doc => {
                        const data = doc.data();
                        if (!data.createAt) {
                            console.warn('Message without createAt:', data);
                        }
                        return {
                            ...data,
                            createAt: data.createAt ? data.createAt.toDate() : new Date()
                        };
                    });
                    setMessages(messagesList);
                    setLoading(false);
                    scrollToBottom();
                }, error => {
                    console.error('Error fetching messages:', error);
                });

            return () => unsubscribe();
        };

        fetchChatData();
        scrollToBottom();

    }, [chatId]);

    const scrollToBottom = () => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const handleSendMessage = async (e) => {
        setIsDisabled(true)
        e.preventDefault();
        if (newMessage.trim()) {
            const messageObj = {
                text: newMessage,
                uid: "admin",
                admin_info: {
                    name: adminData?.name,
                    profile_picture:
                        adminData?.profile_picture?.length > 0
                            ? adminData?.profile_picture
                            : "",
                    fb_uid: adminData?.fb_uid,
                },
                type: "text",
                createAt: new Date(),
                isSupport: true,
            };

            await db.collection(collectionName)
                .doc(chatId)
                .collection("messages")
                .add(messageObj);

            await db.collection(collectionName)
                .doc(chatId)
                .update({
                    lastMessage: messageObj,
                    updatedAt: new Date(),
                });

            setNewMessage('');
            setIsDisabled(false);
            scrollToBottom();
        }
        setIsDisabled(false);

    };


    const handleImageClick = (message, imageIndex) => {
        setGalleryImages(message.images);
        setCurrentImageIndex(imageIndex);
        setIsGalleryOpen(true);
    };

    const handleNextImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % galleryImages.length);
    };

    const handlePrevImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex - 1 + galleryImages.length) % galleryImages.length);
    };

    const swipeHandlers = useSwipeable({
        onSwipedLeft: handleNextImage,
        onSwipedRight: handlePrevImage,
    });

    const renderMessageDate = (messageDate) => (
        <div style={{ margin: "8px 0", width: "100%", display: "flex", justifyContent: "center" }}>
            <Typography variant="caption" style={{ color: "grey" }}>
                {moment(messageDate).format("MMM DD, YYYY")}
            </Typography>
        </div>
    );

    const renderMessages = () => {
        return messages.map((message, index) => {
            const isDifferentDay = index === 0 || !moment(messages[index - 1].createAt).isSame(message.createAt, 'day');
            const isAdmin = message.uid === "admin";

            return (
                <React.Fragment key={index}>
                    {isDifferentDay && renderMessageDate(message.createAt)}
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: isAdmin ? 'flex-end' : 'flex-start',
                        }}
                    >
                        <Tooltip
                            title={moment(message.createAt).format("MMM DD, hh:mm A")}
                            placement={isAdmin ? "left" : "right"}
                            arrow
                        >
                            <Paper
                                style={{
                                    marginTop: "10px",
                                    marginBottom: "5px",
                                    padding: "15px",
                                    backgroundColor: message.uid === "admin" ? "#F5F5F5" : "#E0F7FA",
                                    color: message.uid === "admin" ? "#000" : "#000",
                                    borderRadius: message.uid === "admin"
                                        ? "15px 15px 0px 15px"
                                        : "15px 15px 15px 0px",
                                    maxWidth: "70%",
                                    width: "fit-content",
                                }}
                            >
                                {message.type === 'image' ? (
                                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                        {message.images.map((image, imgIndex) => (
                                            <img
                                                key={imgIndex}
                                                src={image}
                                                alt={`attachment-${imgIndex}`}
                                                style={{
                                                    maxWidth: "150px",
                                                    maxHeight: "150px",
                                                    borderRadius: "8px",
                                                    margin: "5px",
                                                    cursor: "pointer"
                                                }}
                                                onClick={() => handleImageClick(message, imgIndex)}
                                            />
                                        ))}
                                    </div>
                                ) : (
                                    <Typography
                                        variant="body2"
                                        style={{
                                            fontFamily: "Poppins",
                                            fontSize: "15px",
                                            fontWeight: "500",
                                            lineHeight: "16px",
                                            wordWrap: "break-word",
                                        }}
                                    >
                                        {message.text}
                                    </Typography>
                                )}
                            </Paper>
                        </Tooltip>
                    </div>
                </React.Fragment>
            );
        });
    };

    return (
        <ModalContainer>
            <Header>
                <Typography style={{ display: "flex" }}>
                    <img
                        style={nameImg}
                        src={details?.owner_info?.profile_picture || "https://i.pinimg.com/736x/de/59/4e/de594ec09881da3fa66d98384a3c72ff.jpg"}
                        alt=""
                    />
                    <span style={nameValue}>{details?.owner_info?.first_name + " " + details?.owner_info?.last_name}</span>
                </Typography>
                <IconButton onClick={() => dispatch(closeModal())}>
                    <GridCloseIcon />
                </IconButton>
            </Header>

            <ChatContainer ref={chatContainerRef}>
                <List>
                    {loading ? (
                        <Typography>Loading...</Typography>
                    ) : (
                        <>
                            {renderMessages()}
                            <div ref={messagesEndRef} />
                        </>
                    )}
                </List>
            </ChatContainer>
            <MessageInputContainer>
                {details?.status === "resolved" ? (
                    <Typography style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: 'center',
                        marginTop: 13,
                        fontSize: 16,
                        fontWeight: 500,
                        color: "rgb(122, 134, 154)",
                    }}>
                        This chat is now closed because the ticket has been resolved.
                    </Typography>) : (
                    <form onSubmit={handleSendMessage}>
                        <TextInput
                            value={newMessage}
                            onChange={(e) => setNewMessage(e.target.value)}
                            placeholder="Type a message..."
                            fullWidth
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        border: 'none',
                                    },
                                    '&.Mui-focused fieldset': {
                                        border: 'none',
                                    },
                                },
                            }}
                            focused
                            InputProps={{
                                style: {
                                    border: "none",
                                    height: "50px",
                                    paddingLeft: "8px",
                                },
                                endAdornment: (
                                    <Button
                                        disabled={isDisabled}
                                        type="submit"
                                        disableRipple
                                        disableFocusRipple
                                        disableTouchRipple
                                        variant="text"
                                        style={{
                                            marginRight: "22px",
                                            textTransform: "capitalize",
                                        }}
                                    >
                                        <RiSendPlaneFill
                                            style={{
                                                color: colorConfigs?.sidebar.bg,
                                                display: "flex",
                                                justifyContent: "flex-end",
                                            }}
                                            size={25}
                                        />
                                    </Button>
                                ),
                            }}
                            classes={{
                                root: useStyles.padding,
                            }}
                        />
                    </form>
                )}
            </MessageInputContainer>


            <Modal
                isOpen={isGalleryOpen}
                onRequestClose={() => setIsGalleryOpen(false)}
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        padding: 0,
                        background: 'none',
                        border: 'none',
                        overflow: 'hidden',
                        zIndex: 99999,
                        position: 'fixed',
                    },
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.75)',
                        zIndex: 99999,
                    },
                }}
            >
                <div {...swipeHandlers} style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    {galleryImages.length > 0 && (
                        <img
                            src={galleryImages[currentImageIndex]}
                            alt="Gallery"
                            style={{ maxHeight: '80vh', maxWidth: '80vw', borderRadius: '8px' }}
                        />
                    )}

                    {galleryImages.length > 1 && (
                        <>
                            <button
                                onClick={handlePrevImage}
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '10px',
                                    transform: 'translateY(-50%)',
                                    background: 'rgba(0, 0, 0, 0.5)',
                                    color: 'white',
                                    border: 'none',
                                    borderRadius: '50%',
                                    padding: '10px',
                                    cursor: 'pointer',
                                }}
                            >
                                &lt;
                            </button>
                            <button
                                onClick={handleNextImage}
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    right: '10px',
                                    transform: 'translateY(-50%)',
                                    background: 'rgba(0, 0, 0, 0.5)',
                                    color: 'white',
                                    border: 'none',
                                    borderRadius: '50%',
                                    padding: '10px',
                                    cursor: 'pointer',
                                }}
                            >
                                &gt;
                            </button>
                        </>
                    )}
                </div>
            </Modal>
        </ModalContainer>
    );
};

export default TicketChat;
