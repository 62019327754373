import { Typography } from "@mui/material";
import React from "react";
import walletSubject from "../../assets/walletSubject.png"
import carSubject from "../../assets/carSubject.png"
import { minWidth } from "@mui/system";

const Subject = ({ subject }) => {

    return subject === "ride" ? (
        <div style={styles.containerRide}>
            <Typography style={styles.ride}><img src={carSubject} alt="" style={{ width: "20px", height: '20px' }}></img> Ride</Typography>
        </div>
    ) : subject === "payment" ? (
        <div style={styles.containerPayment}>
            <Typography style={styles.payment}><img src={walletSubject} alt="" style={{ width: "20px", height: '20px' }}></img> Payment</Typography>
        </div>
    ) :
        (
            ""
        );
};
const styles = {
    ride: {
        color: "#0FBE00",
        fontSize: "14px",
        fontWeight: 600,
        lineHeight: "18px",
        fontFamily: "Poppins",
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        gap: 5

    },
    payment: {
        color: "#5E17EB",
        fontSize: "14px",
        fontWeight: 600,
        lineHeight: "18px",
        fontFamily: "Poppins",
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        gap: 5
    },
    containerRide: {
        justifyContent: "center",
        marginTop: 5,
        height: "25px",
        padding: "4px 16px",
        borderRadius: "25px",
        backgroundColor: "#F1FFEB",
        display: "flex",
        alignItems: "center",
    },
    containerPayment: {
        justifyContent: "center",
        marginTop: 5,
        height: "25px",
        borderRadius: "25px",
        backgroundColor: "#F8F4FF",
        display: "flex",
        padding: "4px 16px",
        alignItems: "center",
    },
};
export default Subject;
