import {
  Box,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { IoMdAddCircle } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { db } from "../../configs/firebase";
import { selectChat, selectRide } from "../../redux/features/appStateSlice";
import MessagingContainer from "./MessagingContainer";
import PrivateCard from "./components/Card";
import SearchIcon from "@mui/icons-material/Search";
import colorConfigs from "../../configs/colorConfigs";

const useStyles = {
  container: {
    paddingTop: "24px",
  },
  drawerPaper: {
    transition: "0.3s",
    margin: "10px 0",
    overflowX: "hidden",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
  },
  drawerPaperHiddenScrollbar: {
    "&::-webkit-scrollbar": {
      width: "0px",
    },
  },
};

const MessagingPageWithSearch = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [lastDoc, setLastDoc] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const sentinel = useRef(null);
  const drawerRef = useRef(null);
  const dispatch = useDispatch();
  const outChat = useSelector((state) => state.appState.outChat);
  const lastRideChat = useSelector((state) => state.appState.lastRideChat);
  const [selectedTask, setSelectedTask] = useState(lastRideChat);
  const [activeFilter, setActiveFilter] = useState("customer");

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    if (event.target.value === "") {
      fetchInitialTasks();
    }
  };

  const fetchInitialTasks = useCallback(async () => {
    const tasksRef = db
      .collection("messaging")
      .orderBy("updatedAt", "desc")
      .limit(5);

    tasksRef.onSnapshot((snapshot) => {
      if (!snapshot.empty) {
        const tasksList = snapshot.docs.map((doc) => doc.data());
        setTasks(tasksList);
        setLastDoc(snapshot.docs[snapshot.docs.length - 1]);
        if (snapshot.docs.length < 5) setHasMore(false);
      } else setHasMore(false);
    });
  }, []);

  const fetchMoreTasks = useCallback(async () => {
    if (!hasMore) return;

    const tasksRef = db
      .collection("messaging")
      .orderBy("updatedAt", "desc")
      .limit(5)
      .startAfter(lastDoc);

    const snapshot = await tasksRef.get();
    if (!snapshot.empty) {
      const tasksList = snapshot.docs.map((doc) => doc.data());
      setTasks((prevTasks) => [...prevTasks, ...tasksList]);
      setLastDoc(snapshot.docs[snapshot.docs.length - 1]);
      if (snapshot.docs.length < 5) setHasMore(false);
    } else setHasMore(false);
  }, [hasMore, lastDoc]);

  useEffect(() => {
    fetchInitialTasks();
  }, [fetchInitialTasks]);

  const handleCardClick = (task) => {
    setSelectedTask(task);
  };

  const handleScroll = useCallback(() => {
    const drawer = drawerRef.current;
    if (drawer && drawer.scrollTop + drawer.clientHeight >= drawer.scrollHeight - 10) {
      fetchMoreTasks();
    }
  }, [fetchMoreTasks]);

  useEffect(() => {
    const drawer = drawerRef.current;
    if (drawer) {
      drawer.addEventListener("scroll", handleScroll);
      return () => drawer.removeEventListener("scroll", handleScroll);
    }
  }, [handleScroll]);

  return (
    <Grid container direction="row" justify="space-between" sx={{ mt: -9, gap: 3 }}>
      <Grid item xs={12} sm={12} md={4} sx={{ background: "#FFF", borderRadius: "10px", p: 0.5 }}>
        {/* <Grid item xs={12} md={12}>
          <Box sx={{ margin: "10px 0", width: "90%", mx: "auto" }}>
            <TextField
              placeholder="Search..."
              variant="outlined"
              value={searchQuery}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              sx={{
                width: "100%",
                height: "45px",
                "& .MuiOutlinedInput-root": {
                  height: "45px",
                  padding: "10px",
                  "& fieldset": {
                    borderRadius: "30px",
                    borderColor: "rgba(0,0,0,0.2)",
                  },
                  "&:hover fieldset": {
                    borderColor: "rgba(0,0,0,0.4)",
                  },
                  "& .MuiInputBase-input": {
                    padding: "10px",
                  },
                },
              }}
            />
          </Box>
        </Grid> */}

        <Box ref={drawerRef} sx={{ overflowY: "auto", height: "70vh", padding: "10px" }}>
          {tasks.length ? (
            tasks.map((task, index) => (
              <PrivateCard
                key={index}
                task={task}
                fromDispute={true}
                selected={
                  (selectedTask?.customer === task?.customer &&
                    selectedTask?.driver === task?.driver &&
                    lastRideChat?.rideId === task?.rideId) ||
                  lastRideChat?.rideId === task?.rideId
                }
                onClick={() => handleCardClick(task)}
              />
            ))
          ) : (
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", flexDirection: "column", gap: 1.5 }}>
              No chat found
              <Box sx={{ width: "20%", textAlign: "center", cursor: "pointer" }} onClick={() => setOpenDialog(true)}>
                <IoMdAddCircle size={50} color={colorConfigs?.sidebar?.bg} />
              </Box>
            </Box>
          )}
        </Box>
      </Grid>

      <Grid item xs={12} sm={12} md={7.5} sx={{ background: "#FFF", borderRadius: "10px", p: 0.5 }}>
        <MessagingContainer openDialog={() => setOpenDialog(true)} selectedTask={selectedTask} />
      </Grid>
    </Grid>
  );
};

export default MessagingPageWithSearch;
