export const isEmojiOnly = (text) => {
    let removedEmojis = text.replace( '\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff]| ','').trim();
    return removedEmojis.length === 0;
  }
  
  
  /// Checks if date is Today
  export const isToday = (date) => {
    const today = new Date()
    return new Date(date).getDate() === today.getDate() &&
    new Date(date).getMonth() === today.getMonth() &&
    new Date(date).getFullYear() === today.getFullYear();
  };
  
  // Asynchronous Loop
  export const asyncForEach = async (array, callback) => {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
    }
  }