import React, { useState } from "react";
import { Avatar, Box, Drawer, List, Stack, Toolbar, Typography } from "@mui/material";
import { Modal } from "antd";
import { TbIndentDecrease } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import logoo from "../../assets/dashboard.png";
import separator from "../../assets/images/Separator-Gradient.png";
import logowhite from "../../assets/womens-first/logowhite.png";
import colorConfigs from "../../configs/colorConfigs";
import { auth } from "../../configs/firebase";
import sizeConfigs from "../../configs/sizeConfigs";
import { logOut, setOpenDrawer } from "../../redux/features/appStateSlice";
import { setCountry } from "../../redux/features/countrySlice";
import appRoutes from "../../routes/appRoutes";
import SidebarItem from "./SidebarItem";
import SidebarItemCollapse from "./SidebarItemCollapse";
import zIndex from "@mui/material/styles/zIndex";

const Sidebar = ({ isSidebarOpen }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const selectedCountry = useSelector((state) => state.country.selectedCountry);
  const userDataa = useSelector((state) => state.appState.userData);
  const licAndConfig = useSelector((state) => state.licencesAndConfiguration);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAgree = () => {
    setOpen(false);
    signOut();
  };

  const signOut = async () => {
    try {
      await auth.signOut();
      localStorage.clear();
      dispatch(logOut());
      navigate("/login");
    } catch (e) {
      console.log(e.message);
    }
  };

  const handleCountryChange = (event) => {
    const selectedCountry = event.target.value;
    dispatch(setCountry(selectedCountry));
  };

  const toggleSidebar = () => {
    dispatch(setOpenDrawer(!isSidebarOpen));
    const event = new CustomEvent('sidebarToggle', { detail: { isOpen: !isSidebarOpen } });
    window.dispatchEvent(event);
  };

  return (
    isSidebarOpen && (
      <Drawer open={isSidebarOpen} variant="permanent" sx={styles.drawer}>
        <List disablePadding sx={styles.list}>
          <div style={styles.toggleContainer}>
            <TbIndentDecrease
              onClick={toggleSidebar}
              color="white"
              size="24px"
              style={styles.toggleIcon}
            />
          </div>
          <Toolbar sx={styles.toolbar}>
            <Stack
              sx={{ width: "100%" }}
              direction="row"
              justifyContent="center">
              <img alt="logo" style={styles.logo} src={process.env.REACT_APP_ENV === 'women_first' ? logowhite : logoo} />
            </Stack>
          </Toolbar>
          <Stack sx={{ width: "100%" }} direction="row" justifyContent="center">
            <img
              style={styles.separatorImage}
              src={separator}
              alt="Separator"
            />
          </Stack>
          <div style={{ marginTop: "20px" }} />
          <Box sx={styles.routeContainer}>
            {appRoutes.map((route, index) => {
              if (route.state === "Dispatcher" && !licAndConfig?.licenses?.dispatch_view) {
                return null;
              }

              return route.children ? (
                <SidebarItemCollapse item={route} key={index} />
              ) : (
                <SidebarItem item={route} key={index} />
              );
            })}
          </Box>
          <Box>
            <Modal
              title="Confirmation Logout"
              visible={open}
              onOk={handleAgree}
              onCancel={handleClose}
              okText="Logout"
              okButtonProps={styles.modalOkButton}>
              Are you sure you want to logout?
            </Modal>
            <Stack
              sx={{ width: "100%" }}
              direction="row"
              justifyContent="center">
              <img
                style={styles.separatorImage}
                src={separator}
                alt="Separator"
              />
            </Stack>
            <Box sx={styles.userContainer}>
              <Avatar
                style={styles.avatar}
                src={userDataa?.profile_picture}
              />
              <div style={{ width: "15px" }} />
              <div>
                <Typography variant="body2" style={styles.typography}>
                  {userDataa?.name}
                </Typography>
                <Typography variant="caption" style={{ color: "#BBC1CE" }}>
                  {userDataa?.role}
                </Typography>
              </div>
              <div style={styles.logoutIconContainer} onClick={handleClickOpen}>
                <LogoutIcon />
              </div>
            </Box>
          </Box>
        </List>
      </Drawer>
    )
  );
};

const styles = {
  drawer: {
    width: sizeConfigs.sidebar.width,
    flexShrink: 0,
    "& .MuiDrawer-paper": {
      width: sizeConfigs.sidebar.width,
      boxSizing: "border-box",
      borderRight: 0,
      backgroundColor: colorConfigs.sidebar.bg,
      color: colorConfigs.sidebar.color,
    },
  },
  list: {
    overflowY: 'auto',
    height: '100%',
    scrollbarWidth: 'none', /* Firefox */
    '&::-webkit-scrollbar': {
      display: 'none', /* Chrome, Safari, Opera */
    },
  },
  toggleContainer: {
    display: "flex",
    justifyContent: "flex-end",
    cursor: "pointer",
  },
  toggleIcon: {
    marginTop: "40px",
    marginRight: "15px",
    zIndex:3
  },
  toolbar: {
    marginBottom: "10px",
    marginTop:'-30px'
  },
  logo: {
    width: "132px",
  },
  separatorImage: {
    marginTop: 10,
  },
  routeContainer: {
    flexGrow: 1,
  },
  modalOkButton: {
    backgroundColor: colorConfigs?.sidebar?.bg,
    color: "white",
  },
  userContainer: {
    display: "flex",
    flexDirection: "row",
    marginLeft: "45px",
    marginTop: "15px",
  },
  avatar: {
    height: "41px",
    width: "40px",
  },
  typography: {
    fontWeight: "bold",
  },
  logoutIconContainer: {
    marginLeft: "20px",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
  },
};

export default Sidebar;
