import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import timeCircle from "../../assets/images/TimeCircle.jpg";
import arrivePickup from "../../assets/images/arrivePickup.jpg";
import assign from "../../assets/images/assign.jpg";
import cancele from "../../assets/images/cancele.png";
import complete from "../../assets/images/complete.jpg";
import create from "../../assets/images/create.jpg";
import pickup from "../../assets/images/pickup.jpg";
import colorConfigs from "../../configs/colorConfigs";
import { formatDate } from "../../utils/localization/dateTimeUtils";
import "./index.css";
import { getMethod } from "../Hook/UseCustomApi";
export default function RideTimeline() {
  const params = useParams();
  const ridesList = useSelector((state) => state.appState.ridesList);
  const ridesListHistory = useSelector((state) => state.appState.ridesListhistory);
  const ridesScheduledList = useSelector((state) => state.appState.ridesScheduledList);
  const rides = useSelector((state) => state.appState.rides);
  const countryCode = useSelector((state) => state.country.selectedCountry);
  const [selectedObject, setSelectedObject] = useState(null);
  //   const [canceleObject, setCancelObject] = useState({});

  const getDateForamted = (date) => {
    if (date !== null && date !== undefined) {
      const dateObject = new Date(date);
      // Formatting options
      const options = {
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
      };
      // Applying formatting and getting the formatted date string
      const formattedDate = dateObject.toLocaleDateString("en-US", options);
      return formattedDate;
    }

    return null;
  };
  const getWaitingTime = (firstTime, secondTime) => {
    if (firstTime === undefined || secondTime === undefined || firstTime === null || secondTime === null) {
      return "-";
    }
    else {
      const firstTimestamp = new Date(firstTime).getTime();
      const secondTimestamp = new Date(secondTime).getTime();

      // Calculate the difference in milliseconds
      const timeDifference = secondTimestamp - firstTimestamp;
      // Convert milliseconds to seconds and minutes
      let waitingTimeInSeconds;
      if (timeDifference < 0) {
        waitingTimeInSeconds = 0;
      } else {
        waitingTimeInSeconds = Math.floor(timeDifference / 1000);
      }
      const minutes = Math.floor(waitingTimeInSeconds / 60);
      const seconds = waitingTimeInSeconds % 60;

      // Format the waiting time
      const formattedTime = `${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")} min`;

      return formattedTime;
    }


  };
  useEffect(() => {
    if (rides === "activeRides") {
      const foundObject = ridesList?.find((obj) => obj._id === params["id"]);
      setSelectedObject(foundObject);
    }
    else if (rides === "history") {
      const foundObject = ridesListHistory?.find((obj) => obj._id === params["id"]);
      setSelectedObject(foundObject);
    } else if (rides === "scheduled") {
      const foundObject = ridesScheduledList?.find((obj) => obj._id === params["id"]);
      setSelectedObject(foundObject);
    } else {
      getMethod({
        url: `ride/dispatch-view/ride/${params["id"]}`,
      }).then((response) => {
        setSelectedObject(response?.data);
      })
    }
  }, [params]);


  const timeLines = [
    {
      icon: pickup,
      title: "Scheduled at",
      time: formatDate(getDateForamted(selectedObject?.scheduled_at), countryCode)
    },
    {
      icon: create,
      title: "Created at",
      time: formatDate(getDateForamted(selectedObject?.created_at), countryCode),
    },
    {
      icon: assign,
      title: "Assigned at",
      time: formatDate(getDateForamted(selectedObject?.assigned_at), countryCode),
    },
    {
      icon: arrivePickup,
      title: "Arrived at pickup",
      time: formatDate(getDateForamted(selectedObject?.arrival_pickup_at), countryCode),
    },
    {
      icon: pickup,
      title: "Picked up at",
      time: formatDate(getDateForamted(selectedObject?.pickedup_at), countryCode),
    },
    {
      icon: complete,
      title: "Arrived at drop off",
      time: formatDate(getDateForamted(selectedObject?.arrival_delivery_at), countryCode),
    },
    {
      icon: complete,
      title: "Delivered at",
      time: formatDate(getDateForamted(selectedObject?.completed_at), countryCode)
    },
  ];
  return (
    <>
      <Box sx={{ width: "50%" }}>
        <h3>Ride Timeline</h3>
        <Box
          sx={{
            backgroundColor: "#FAFAFA",
            padding: "20px",
            position: "relative",
          }}
        >
          {timeLines.map((item) => {
            if (item.time !== null) {
              return <>
                <div
                  style={{
                    display: "flex",
                    gap: "6px",
                    alignItems: "center",
                    padding: "12px 0",
                    textAlign: "justify",
                    // marginTop: "-100px",
                  }}
                >
                  <img
                    style={{ width: "20px", height: "20px" }}
                    src={item.icon}
                    alt="loc"
                  />
                  <Typography
                    variant="body2"
                    style={{
                      color: colorConfigs.sidebar.bg,
                      opacity: "0.8",
                      marginTop: "3px",
                      fontWeight: "600",
                      marginLeft: "10px",
                    }}
                  >
                    {item.title}:
                    <br />
                    <Typography
                      key={selectedObject?.id}
                      variant="caption"
                      style={{
                        color: "black",
                        marginTop: "3px",
                      }}
                    >
                      {item.time !== null ? item.time : '-'}
                    </Typography>
                  </Typography>
                </div>
                {/* connecting line */}
                {/* <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "5%",
                    transform: "translate(-50%, -45%)",
                    borderLeft: "1px dashed gray", // Adjust the line style
                    height: "60px", // Adjust the height of the line
                  }}
                ></div> */}
              </>
            }

          })}
          {selectedObject?.is_canceled ? (
            <div
              style={{
                display: "flex",
                gap: "6px",
                alignItems: "center",
                padding: "12px 0",
                textAlign: "justify",
                // marginTop: "-100px",
              }}
            >
              <img
                style={{ width: "20px", height: "20px" }}
                src={cancele}
                alt="loc"
              />
              <Typography
                variant="body2"
                style={{
                  color: colorConfigs.sidebar.bg,
                  opacity: "0.8",
                  marginTop: "3px",
                  fontWeight: "600",
                  marginLeft: "10px",
                }}
              >
                Canceled at:
                <br />
                <Typography
                  key={selectedObject?.id}
                  variant="caption"
                  style={{
                    color: "black",
                    marginTop: "3px",
                  }}
                >
                  {formatDate(getDateForamted(selectedObject?.canceled_at), countryCode)}
                </Typography>
              </Typography>
            </div>
          ) : (
            ""
          )}
        </Box>
      </Box>
      <Box sx={{ width: "50%" }}>
        <h3>Waiting Time</h3>
        <Box sx={{ backgroundColor: "#FAFAFA", padding: "20px" }}>
          <div
            style={{
              display: "flex",
              gap: "6px",
              alignItems: "center",
              padding: "12px 0",
              textAlign: "justify",
            }}
          >
            <img
              style={{ width: "20px", height: "20px" }}
              src={timeCircle}
              alt="loc"
            />
            <Typography
              variant="body2"
              style={{
                color: colorConfigs.sidebar.bg,
                opacity: "0.8",
                marginTop: "3px",
                fontWeight: "600",
                marginLeft: "10px",
              }}
            >
              Driver Waiting Time:
              <br />
              <Typography
                key={selectedObject?.id}
                variant="caption"
                style={{
                  color: "black",
                  marginTop: "3px",
                }}
              >
                {getWaitingTime(
                  selectedObject?.arrival_pickup_at,
                  selectedObject?.pickedup_at
                )}
              </Typography>
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              gap: "6px",
              alignItems: "center",
              padding: "12px 0",
              textAlign: "justify",
            }}
          >
            <img
              style={{ width: "20px", height: "20px" }}
              src={timeCircle}
              alt="loc"
            />
            <Typography
              variant="body2"
              style={{
                color: colorConfigs.sidebar.bg,
                opacity: "0.8",
                marginTop: "3px",
                fontWeight: "600",
                marginLeft: "10px",
              }}
            >
              Passenger Waiting Time:
              <br />
              <Typography
                key={selectedObject?.id}
                variant="caption"
                style={{
                  color: "black",
                  marginTop: "3px",
                }}
              >
                {getWaitingTime(
                  selectedObject?.assigned_at,
                  selectedObject?.arrival_pickup_at
                )}
              </Typography>
            </Typography>
          </div>
        </Box>
      </Box>
    </>
  );
}