function formatPhoneNumber(number, countryCode) {
  if (!number) return "";
  if (countryCode === "US") {
    return `+1 (${number.slice(0, 3)}) ${number.slice(3, 6)}-${number.slice(
      6
    )}`;

  } else if (countryCode === "CA") {
    return `+1 (${number.slice(0, 3)}) ${number.slice(3, 6)}-${number.slice(
      6
    )}`;
  }
  else if (countryCode === "EG") {
    return `+20 ${number.slice(0, 2)} ${number.slice(2, 5)} ${number.slice(5)}`;
  } else if (countryCode === "JO") {
    return `+962 ${number.slice(0, 1)} ${number.slice(1, 4)} ${number.slice(
      4
    )}`;
  }
  return number;
}

function makePhoneNumberClickable(phoneNumber) {
  return `tel:${phoneNumber}`;
}

export { formatPhoneNumber, makePhoneNumberClickable };
