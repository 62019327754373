/* eslint-disable no-throw-literal */
import axios from "axios";
import { auth } from "../../configs/firebase";
import { API_URL } from "../../utils/constant";
import Error5xx from "../../components/common/HandelError5xx";

const getTokenAndHeaders = async (retries = 3, delay = 1000) => {
  const getToken = () => {
    return new Promise((resolve, reject) => {
      if (auth?.currentUser) {
        auth.currentUser.getIdToken(true)
          .then((token) => {
            localStorage.setItem("userToken", token);
            resolve(token);

          })
          .catch((error) => reject(error));
      } else {
        reject(new Error("User is not authenticated"));
      }
    });
  };

  for (let attempt = 0; attempt < retries; attempt++) {
    try {
      const tokenId = await getToken();
      if (tokenId) {
        const headers = {
          "Content-Type": "application/json",
          Authorization: tokenId,
        };
        return headers;
      }
    } catch (error) {
      if (attempt < retries - 1) {
        await new Promise((resolve) => setTimeout(resolve, delay));
      } else {
        throw error;
      }
    }
  }

  throw new Error("Failed to obtain user token after multiple attempts");
};

const customErrorMessages = {
  401: "Unauthorized: User is not authorized.",
  403: "Forbidden: User doesn't have permission.",
  404: "Not Found: The requested resource was not found.",
  409: "Conflict: Conflict in action or duplicate request.",
  410: "Gone: Resource is no longer available.",
  500: "Internal Server Error: Server error occurred.",
};


export const getMethod = async ({ url, params = {}, handleErrorMessage }) => {
  try {
    const headers = await getTokenAndHeaders();
    const response = await axios.get(`${API_URL}/api/v1/${url}`, {
      headers,
      params: {
        ...params,
        projectId: process.env.REACT_APP_ENV === "production" ? "ridewyze" : process.env.REACT_APP_ENV === 'women_first' ? 'women-s-first' : "roadrunner"
      },
    });

    switch (response.status) {
      case 200:
        // console.log("Success with data:", response.data);
        return response.data;

      case 201:
        // console.log("Created new document:", response.data);
        return response.data;

      case 204:
        // console.log("Success with no content.");
        return null;

      case 401:
        console.error(customErrorMessages["401"]);
        return Promise.reject(customErrorMessages["401"]);

      case 403:
        console.error(customErrorMessages["403"]);
        return Promise.reject(customErrorMessages["403"]);

      case 404:
        console.error(customErrorMessages["404"]);
        return Promise.reject(customErrorMessages["404"]);

      case 409:
        console.error(customErrorMessages["409"]);
        return Promise.reject(customErrorMessages["409"]);

      case 410:
        console.error(customErrorMessages["410"]);
        return Promise.reject(customErrorMessages["410"]);

      case response.status >= 500:
        console.error(customErrorMessages["500"]);
        return Promise.reject(customErrorMessages["500"]);

      default:
      console.log(`Unhandled status code: ${response.status}`)
        // throw new Error(`Unhandled status code: ${response.status}`);
    }
  } catch (error) {
    console.log(error)
    if (error.response && error.response.status) {
      const errorCode = error.response.status;
      const errorMessage =
        customErrorMessages[errorCode] || customErrorMessages.serverError;
      console.error("Error occurred:", errorMessage);
      if (handleErrorMessage && errorMessage) {
        handleErrorMessage(errorMessage);
      }
      console.log(errorMessage)
      // return Promise.reject(errorMessage);
    } else {
      console.error("Error occurred:", customErrorMessages.serverError);
      // return Promise.reject(customErrorMessages.serverError);
    }
  }
};

export const postMethod = async ({
  url,
  body,
  handleErrorMessage,
}) => {
  try {
    const headers = await getTokenAndHeaders();
    const response = await axios.post(`${API_URL}/api/v1/${url}`, body, {
      headers,
      params: {
        projectId: process.env.REACT_APP_ENV === "production" ? "ridewyze" : process.env.REACT_APP_ENV === 'women_first' ? 'women-s-first' : "roadrunner"
      },
    });
    switch (response.status) {
      case 200:
        // console.log("Success with data:", response.data);
        return response.data;

      case 201:
        // console.log("Created new document:", response.data);
        return response.data;

      case 204:
        console.log("Success with no content.");
        return null;

      case 401:
        console.error(customErrorMessages["401"]);
        return Promise.reject(customErrorMessages["401"]);

      case 403:
        console.error(customErrorMessages["403"]);
        return Promise.reject(customErrorMessages["403"]);

      case 404:
        console.error(customErrorMessages["404"]);
        return Promise.reject(customErrorMessages["404"]);

      case 409:
        console.error(customErrorMessages["409"]);
        return Promise.reject(customErrorMessages["409"]);

      case 410:
        console.error(customErrorMessages["410"]);
        return Promise.reject(customErrorMessages["410"]);


      case response.status >= 500:
        console.error(customErrorMessages["500"]);
        return Promise.reject(customErrorMessages["500"]);

      default:
        throw new Error(`Unhandled status code: ${response.status}`);
    }
  } catch (error) {
    if (error.response && error.response.status && customErrorMessages[error.response.status]) {
      const errorCode = error.response.status;
      const errorMessage =
        customErrorMessages[errorCode] || customErrorMessages.serverError;
      if (handleErrorMessage && errorMessage) {
        handleErrorMessage(errorMessage);
      }
      return Promise.reject(errorMessage);
    } else {
      if (customErrorMessages.serverError)
        return Promise.reject(customErrorMessages.serverError);
      else
        return Promise.reject(error);

    }
  }
};

export const DeleteMethod = async ({ url, handleErrorMessage }) => {
  try {

    const headers = await getTokenAndHeaders();
    const response = await axios.delete(`${API_URL}/api/v1/${url}`, {
      headers,
      params: {
        projectId: process.env.REACT_APP_ENV === "production" ? "ridewyze" : process.env.REACT_APP_ENV === 'women_first' ? 'women-s-first' : "roadrunner"
      },
    });
    switch (response.status) {
      case 200:
        // console.log("Success with data:", response.data);
        return response.data;

      case 201:
        // console.log("Created new document:", response.data);
        return response.data;

      case 204:
        // console.log("Success with no content.");
        return null;

      case 401:
        console.error(customErrorMessages["401"]);
        return Promise.reject(customErrorMessages["401"]);

      case 403:
        console.error(customErrorMessages["403"]);
        return Promise.reject(customErrorMessages["403"]);

      case 404:
        console.error(customErrorMessages["404"]);
        return Promise.reject(customErrorMessages["404"]);

      case 409:
        console.error(customErrorMessages["409"]);
        return Promise.reject(customErrorMessages["409"]);

      case 410:
        console.error(customErrorMessages["410"]);
        return Promise.reject(customErrorMessages["410"]);


      case response.status >= 500:
        console.error(customErrorMessages["500"]);
        return Promise.reject(customErrorMessages["500"]);

      default:
        throw new Error(`Unhandled status code: ${response.status}`);
    }
  } catch (error) {
    if (error.response && error.response.status) {
      const errorCode = error.response.status;
      const errorMessage =
        customErrorMessages[errorCode] || customErrorMessages.serverError;
      console.error("Error occurred:", errorMessage);
      if (handleErrorMessage && errorMessage) {
        handleErrorMessage(errorMessage);
      }
      return Promise.reject(errorMessage);
    } else {
      console.error("Error occurred:", customErrorMessages.serverError);
      return Promise.reject(customErrorMessages.serverError);
    }
  }
};

export const putMethod = async ({ url, body, handleErrorMessage }) => {
  try {

    const headers = await getTokenAndHeaders();
    const response = await axios.put(`${API_URL}/api/v1/${url}`, body, {
      headers,
      params: {
        projectId: process.env.REACT_APP_ENV === "production" ? "ridewyze" : process.env.REACT_APP_ENV === 'women_first' ? 'women-s-first' : "roadrunner"
      },
    });
    switch (response.status) {
      case 200:
        // console.log("Success with data:", response.data);
        return response.data;

      case 201:
        // console.log("Created new document:", response.data);
        return response.data;

      case 204:
        // console.log("Success with no content.");
        return null;

      case 401:
        console.error(customErrorMessages["401"]);
        return Promise.reject(customErrorMessages["401"]);

      case 403:
        console.error(customErrorMessages["403"]);
        return Promise.reject(customErrorMessages["403"]);

      case 404:
        console.error(customErrorMessages["404"]);
        return Promise.reject(customErrorMessages["404"]);

      case 409:
        console.error(customErrorMessages["409"]);
        return Promise.reject(customErrorMessages["409"]);

      case 410:
        console.error(customErrorMessages["410"]);
        return Promise.reject(customErrorMessages["410"]);


      case response.status >= 500:
        console.error(customErrorMessages["500"]);
        return Promise.reject(customErrorMessages["500"]);

      default:
        throw new Error(`Unhandled status code: ${response.status}`);
    }
  } catch (error) {
    if (error.response && error.response.status) {
      const errorCode = error.response.status;
      const errorMessage =
        customErrorMessages[errorCode] || customErrorMessages.serverError;
      console.error("Error occurred:", errorMessage);
      if (handleErrorMessage && errorMessage) {
        handleErrorMessage(errorMessage);
      }
      return Promise.reject(errorMessage);
    } else {
      console.error("Error occurred:", customErrorMessages.serverError);
      return Promise.reject(customErrorMessages.serverError);
    }
  }
};
export const patchMethod = async ({ url, body, handleErrorMessage }) => {
  try {

    const headers = await getTokenAndHeaders();
    const response = await axios.patch(`${API_URL}/api/v1/${url}`, body, {
      headers,
      params: {
        projectId: process.env.REACT_APP_ENV === "production" ? "ridewyze" : process.env.REACT_APP_ENV === 'women_first' ? 'women-s-first' : "roadrunner"
      },
    });
    switch (response.status) {
      case 200:
        // console.log("Success with data:", response.data);
        return response.data;

      case 201:
        // console.log("Created new document:", response.data);
        return response.data;

      case 204:
        // console.log("Success with no content.");
        return null;

      case 401:
        console.error(customErrorMessages["401"]);
        return Promise.reject(customErrorMessages["401"]);

      case 403:
        console.error(customErrorMessages["403"]);
        return Promise.reject(customErrorMessages["403"]);

      case 404:
        console.error(customErrorMessages["404"]);
        return Promise.reject(customErrorMessages["404"]);

      case 409:
        console.error(customErrorMessages["409"]);
        return Promise.reject(customErrorMessages["409"]);

      case 410:
        console.error(customErrorMessages["410"]);
        return Promise.reject(customErrorMessages["410"]);


      case response.status >= 500:
        console.error(customErrorMessages["500"]);
        return Promise.reject(customErrorMessages["500"]);

      default:
        throw new Error(`Unhandled status code: ${response.status}`);
    }
  } catch (error) {
    if (error.response && error.response.status) {
      const errorCode = error.response.status;
      const errorMessage =
        customErrorMessages[errorCode] || customErrorMessages.serverError;
      console.error("Error occurred:", errorMessage);
      if (handleErrorMessage && errorMessage) {
        handleErrorMessage(errorMessage);
      }
      return Promise.reject(errorMessage);
    } else {
      console.error("Error occurred:", customErrorMessages.serverError);
      return Promise.reject(customErrorMessages.serverError);
    }
  }
};
