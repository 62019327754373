import { Box, Tab, Tabs } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import MemberInfo from '../MemberInfo';
import RidesTab from '../RidesTab';
import EditMember from './EditMember';


function MemberDetails() {
    const savedTabIndex = localStorage.getItem(0);
    const [value, setValue] = React.useState(parseInt(savedTabIndex, 10) || 0);
    const selectedCorporateMember = useSelector((state) => state.appState.selectedCorporateMember);
    const selectedCorporate = useSelector((state) => state.appState.selectedCorporate);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        localStorage.setItem('corporateInfoTab', newValue);
    };

    return (
        <Box sx={{ mt: -9 }}>
            <Box sx={{ bgcolor: '#FFF', minHeight: '95vh', borderRadius: 3 }}>
                <MemberInfo />
                <Box sx={{ width: '100%', background: '#F5F7F8' }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        variant="standard"
                        textColor="primary"
                        sx={{
                            '& .MuiTab-root': {
                                textTransform: 'none', // Disable uppercase
                                fontWeight: 600,
                                fontSize: '14px',
                                padding: '0 20px',
                                color: '#2D5066',
                                opacity: 1,
                                '&.Mui-selected': {
                                    color: '#00659C', // Selected tab color (blue)
                                },
                            },
                        }}
                    >
                        <Tab label="Employee Details" />
                        <Tab label="Rides" />
                    </Tabs>
                </Box>
                <Box>
                    {value === 0 && <EditMember />}
                    {value === 1 && <RidesTab filter={{ customer: selectedCorporateMember?._id, corporate: selectedCorporate?._id }} />}
                </Box>
            </Box>
        </Box>
    );
}

export default MemberDetails;
