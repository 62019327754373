import moment from "moment";
import React from "react";

import Typography from "@mui/material/Typography";

import { Avatar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import colorConfigs from "../../../configs/colorConfigs";
import { selectChat, setLastSupportChat, setOpenChatFromDriver, updateOutChat } from "../../../redux/features/appStateSlice";
import { formatDate } from "../../../utils/localization/dateTimeUtils";

const useStyles = {
  taskCard: {
    display: "flex",
    flexDirection: "column",
    background: "white",
    position: "relative",
    borderRadius: "6px",
    cursor: "pointer",
    boxShadow: "0 1px 2px rgb(0,0,0,0.1)",
    border: "1.5px solid rgb(245,245,245)",
    "&:hover": {
      boxShadow: "0 10px 20px -14px rgba(50,50,50,0.3)",
    },
  },
};
const JobCard = ({ task, promptCancel, fromDispute = false, activeButton, selected, handleClick }) => {
  const dispatch = useDispatch();
  const countryCode = useSelector((state) => state.country.selectedCountry);
  const handleOpenTask = (taskData) => {
    let uid = taskData?.type === 'driver' ? taskData?.driver : taskData?.customer
    dispatch(selectChat({ ...taskData, [taskData?.type]: `admin_${uid}` }));
    dispatch(setLastSupportChat({ ...taskData, [taskData?.type]: `admin_${uid}` }));
    dispatch(updateOutChat(false));
    dispatch(setOpenChatFromDriver(false));

  };

  return (
    <div style={{
      ...useStyles.taskCard, border: selected ? `2px solid ${colorConfigs?.sidebar?.bg} ` : 'none', backgroundColor: selected ? colorConfigs?.sidebar?.ligthBg : 'white'
    }} onClick={() => { handleOpenTask(task); handleClick(task) }}>
      < div
        style={{
          display: "flex", padding: "14px 16px", paddingBottom: "0px"
        }}
      >
        <div style={{ flex: 1, display: "flex", flexDirection: "row" }}>
          <Avatar src={task?.user_profile} />
          <div style={{ height: "4px" }} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "20px",
            }}
          >
            <Typography
              variant="body2"
              style={{
                overflowWrap: "anyWhere",
                fontWeight: 700,
                lineHeight: "22.4px",
              }}
            >
              {task?.user_name}
            </Typography>
            <Typography
              variant="body2"
              style={{
                overflowWrap: "anyWhere",
                fontWeight: task?.unRead?.admin === 0 ? "normal" : "bold",
                color: task?.unRead?.admin === 0 ? "#7D7C7C" : "black",
              }}
            >
              {task?.lastMessage?.uid === "admin" &&
                task?.lastMessage?.message?.length >= 15
                ? "Admin: " +
                task?.lastMessage?.message?.slice("0", "15") +
                "..."
                : task?.lastMessage?.uid === "admin" &&
                  task?.lastMessage?.message?.length < 15
                  ? "Admin: " + task?.lastMessage?.message
                  : task?.lastMessage?.uid !== "admin" &&
                    task?.lastMessage?.message?.length >= 15
                    ? `${task?.type === "customer"
                      ? task?.user_name
                      : task?.user_name
                    }: ` +
                    task?.lastMessage?.message?.slice("0", "15") +
                    "..."
                    : `${task?.type === "customer"
                      ? task?.user_name
                      : task?.user_name
                    } :` + task?.lastMessage?.message}
            </Typography>
          </div>
          {task?.unRead?.admin > 0 && !selected && (
            <div style={{ fontWeight: 'bold', display: "flex", justifyContent: "center", alignItems: "center", position: "absolute", top: "10px", right: "10px", width: "25px", height: "25px", borderRadius: "50%", backgroundColor: "#FF4D4F", color: "white", fontSize: "14px" }}>
              {task?.unRead?.admin}
            </div>
          )}
        </div>
      </div >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "12px 16px",
          paddingTop: "0px",
          marginLeft: "20px",
        }}
      >
        <Typography variant="caption" style={{ color: "grey" }} medium>
          {formatDate(
            typeof task?.updatedAt === "string"
              ? new Date(task.updatedAt)
              : new Date(task?.updatedAt?.toDate()?.toString())
            , countryCode) || ''}
        </Typography>
      </div>
    </div >
  );
};

export default JobCard;
