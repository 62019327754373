import {
    Box,
    Typography,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button, Chip, IconButton,
    Checkbox,
} from '@mui/material';
import Loading from "react-fullscreen-loading";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
import React from 'react';
import { closeModal, openModal } from '../../redux/features/modalSlice';
import colorConfigs from "../../configs/colorConfigs";
import { TextInput } from "@zetaton-dev/zetaton-components-bank";
import { useEffect, useRef, useState } from "react";
import { LuUpload } from "react-icons/lu";
import { postMethod, DeleteMethod, getMethod, putMethod } from "../../pages/Hook/UseCustomApi";
import AddCategory from "./AddCategory"
import { useDispatch, useSelector } from "react-redux";
import { storageSdk } from '../../configs/firebase';
import { useNavigate } from "react-router-dom";


const Container = styled(Box)(({ theme }) => ({
    padding: "5px"
}));
const Header = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    padding: '10px',
    backgroundColor: "#F6F6F6",
    marginBottom: "15px",
    borderRadius: "8px",
    marginTop: "40px",

}));

const Input = styled("input")({
    display: "none",
});

const addBox = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    border: "2px solid",
    borderRadius: "8px",
    borderColor: colorConfigs.sidebar.bg,
    color: colorConfigs.sidebar.bg,
    minHeight: "45px",
    // minWidth: "50px",
    marginTop: "10px",
    cursor: "pointer"
}

const imgsBox = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    alignItems: "center",
}

const AddHelpCenter = () => {

    const status = useSelector((state) => state.appState.driverRiderStatus);
    const addEditStatus = useSelector((state) => state.appState.addEditStatus);
    const details = useSelector((state) => state.appState.details);
    // console.log(status);

    const dispatch = useDispatch();
    const userType = status === "drivers" ? "driver" : "customer";
    const [inputKey, setInputKey] = useState("");
    const [loading, setLoading] = useState("");
    const [url, setUrl] = useState("");
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const [checked, setChecked] = useState(
        addEditStatus === "edit" && details?.user_type
            ? ['driver', 'customer'].every(type => details.user_type.includes(type))
            : false
    );

    const [dataToAdd, setDataToAdd] = useState(addEditStatus === "edit" ? details : {
        title: "",
        type: "ride",
        priority: "high",
        reasone: "",
        sup_reasone: "",
        description_html: "",
        user_action: ["chat"],
        suggested_actions: "",
        user_type: userType,
        images: []
    });


    const handleClose = () => {
        setErrors({})
        dispatch(closeModal());
        setDataToAdd(addEditStatus === "edit" ? details : {
            title: "",
            type: "ride",
            priority: "high",
            reasone: "",
            sup_reasone: "",
            description_html: "",
            user_action: ["chat"],
            suggested_actions: "",
            user_type: userType,
            images: []
        })
    };

    const handleChange = (e) => {

        const { name, value } = e.target;
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
        setDataToAdd((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };
    const handleQuillChange = (value) => {
        setErrors((prevErrors) => ({ ...prevErrors, description_html: "" }));

        setDataToAdd((prevData) => ({
            ...prevData,
            description_html: value
        }));
    };

    const handleSave = () => {

        const newErrors = {};

        if (dataToAdd.title === "") {
            newErrors.title = "Title is required.";
        }
        // if (dataToAdd.reasone === "") {
        //     newErrors.reasone = "Reason is required.";
        // }
        // if (dataToAdd.sup_reasone === "") {
        //     newErrors.sup_reasone = "Sup reason is required.";
        // }
        if (dataToAdd.description_html === "") {
            newErrors.description_html = "Description is required.";
        }
        if (dataToAdd.user_action.length === 0) {
            newErrors.user_action = "Please select at least one user action.";
        }
        if (dataToAdd.suggested_actions === "") {
            newErrors.suggested_actions = "Suggested actions are required.";
        }



        setErrors(newErrors);
        if (Object.keys(newErrors).length > 0) {
            return;
        } else {
            const body = dataToAdd

            setLoading(true);

            const method = addEditStatus === "add" ? postMethod : putMethod;
            const methodUrl = addEditStatus === "add" ? `admin/help/knowledge-base` : `admin/help/knowledge-base/${details._id}`;

            method({
                url: methodUrl,
                body: body,
            })
                .then((response) => {
                    handleClose();
                    setLoading(false);
                    navigate(status && status === "drivers" ? `/driverHelpCenter/` : `/passengerHelpCenter/`)
                })
                .catch((err) => {
                    console.error("Error fetching more stores: name already used ", err);
                    setLoading(false);
                });

        }


    };

    const handleUserActionChange = (event) => {
        const value = event.target.value;
        const newValue = typeof value === 'string' ? value.split(',') : value;
        setDataToAdd(prevData => ({
            ...prevData,
            user_action: newValue
        }));
    };


    const handleDelete = (index) => {
        const updatedImages = [...dataToAdd?.images];
        updatedImages.splice(index, 1);
        setDataToAdd(prevData => ({
            ...prevData,
            images: updatedImages
        }));
    };

    const handleImagrUrl = async (e) => {
        setLoading(true);
        let file = e.target?.files[0];
        if ((file && file?.length !== 0) || (file && file !== "" && typeof file !== "string")) {
            setLoading(true);
            const uploadTask = storageSdk.ref(`/helpCenter/${file.name}`).put(file);
            uploadTask.on(
                "state_changed",
                (snapShot) => {
                    //takes a snap shot of the process as it is happening
                },
                (err) => {
                    setLoading(false); // Set loading to false in case of an error
                },
                async () => {
                    try {
                        const fireBaseUrl = await storageSdk
                            .ref("helpCenter")
                            .child(file.name)
                            .getDownloadURL();

                        try {
                            setLoading(true);
                            setDataToAdd(prevData => ({
                                ...prevData,
                                images: [...prevData.images, fireBaseUrl],
                            }));
                        } catch (error) {
                            console.error("Error adding image URL to array:", error.message);
                        } finally {
                            setLoading(false);
                        }
                        setUrl(fireBaseUrl);
                    } catch (error) {
                        console.error("Download URL error:", error.message);
                        setLoading(false);
                    } finally {
                        setLoading(false);
                    }
                }
            );
        } else {
            setUrl(file);
            setLoading(false);
        }
        setInputKey(Math.random());
    };

    const handleCheckBoxValue = () => {
        setDataToAdd(prevData => ({
            ...prevData,
            user_type: checked ? ['driver', 'customer'] : [userType]
        }));
    };

    useEffect(() => {
        handleCheckBoxValue()
    }, [checked]);


    const userActionsMap = {
        "chat": "Message Support",
        "call": "Call Support"
    };
    const fieldStyle = {
        "& label.Mui-focused": {
            color: "#172B4D !important",
            fontSize: "17px !important",
            fontWeight: "500 !important",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#BBC1CE",
                borderWidth: 1,
                borderRadius: "8px",
            },
            "&:hover fieldset": {
                borderColor: "#BBC1CE",
                borderWidth: 1,
                borderRadius: "8px",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#BBC1CE",
                borderWidth: 1,
                borderRadius: "8px",
            },
            input: {
                "&::placeholder": {
                    color: "#3A3541DE",
                    opacity: 1,
                    fontSize: 14,
                },
            },
        },
    };

    const modules = {
        toolbar: [
            [{ 'size': ['small', false, 'large', 'huge'] }],
            ['bold', 'italic', 'underline'],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'align': [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['clean']
        ],
    };

    const formats = [
        'size',
        'bold', 'italic', 'underline',
        'color', 'background',
        'align',
        'list', 'bullet',
    ];


    return (
        <>
            <Grid
                item
                xs={12}
                sm={12}
                md={9}
                lg={9}
                xl={9}
                style={{
                    display: "flex",
                    gap: "14px",
                    marginTop: "-70px",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                }}>
                <Box style={{
                    display: "flex",
                    flexDirection: 'row',

                    gap: "14px",
                }}>
                    <span style={{
                        color: "#FFFFFF",
                        fontFamily: "Outfit",
                        fontSize: "24px",
                        fontWeight: "700",
                        lineHeight: "33px",

                    }}>
                        Help Center
                    </span>
                </Box>
            </Grid>
            <Container>
                <Header>
                    <Typography sx={{
                        color: colorConfigs.sidebar.bg,
                        fontFamily: "Poppins",
                        fontSize: "18px",
                        fontWeight: "700",
                        lineHeight: "27px",
                        paddingLeft: "5px"
                    }}>
                        {addEditStatus === "add" ? "Add New" : "Edit"}  Help Center For {status === "drivers" ? "Drivers" : "Passengers"}
                    </Typography>
                </Header>
                <Grid container spacing={2}>
                    {/* title */}
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            variant="outlined"
                            placeholder="Please enter the title"
                            value={dataToAdd.title}
                            onChange={handleChange}
                            margin="dense"
                            styles={fieldStyle}
                            label={"Title"}
                            name="title"
                            filled="true"
                            focused
                            InputProps={{
                                style: { backgroundColor: 'transparent', borderRadius: '10px', height: 45 },
                            }}
                            error={Boolean(errors.title)}
                        />
                        {errors.title && (
                            <Typography variant="caption" style={{ color: "red", marginLeft: "15px" }}>
                                {errors.title}
                            </Typography>
                        )}
                    </Grid>
                    {/* Category */}
                    <Grid item xs={6}>
                        <FormControl
                            fullWidth
                            style={{ paddingTop: "8px" }}
                            sx={{
                                "& label.Mui-focused": { color: "rgba(0, 0, 0, 0.6)" },
                                "& .MuiOutlinedInput-root": {
                                    "&.Mui-focused fieldset": {
                                        borderColor: "#A0AEC0",
                                    },
                                    borderRadius: "10px",
                                    height: "50px",
                                },
                            }}
                        >
                            <InputLabel id="demo-simple-select-label" style={{ paddingTop: "8px" }}>
                                Category
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={dataToAdd.type}
                                label="Category"
                                name="type"
                                onChange={handleChange}
                                style={{ borderRadius: "10px", height: "45px", }}
                            >
                                <MenuItem value={"ride"}>Ride</MenuItem>
                                <MenuItem value={"payment"}>Payment</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    {/*5.2 Add Category */}
                    {/* <Grid item xs={0.8}>
                        <Box style={addBox}
                            onClick={() => { dispatch(openModal(<AddCategory status={status} />)) }}
                        >
                            <AddIcon style={{ fontSize: "30px" }} />
                        </Box>
                    </Grid> */}
                    {/* Reason */}
                    {/* <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            variant="outlined"
                            placeholder="Please enter the reason"
                            value={dataToAdd.reasone}
                            onChange={handleChange}
                            margin="dense"
                            styles={fieldStyle}
                            label={"Reason"}
                            name="reasone"
                            filled="true"
                            focused
                            InputProps={{
                                style: { backgroundColor: 'transparent', borderRadius: '10px', height: 45 },
                            }}
                            error={Boolean(errors.reasone)}
                        />
                        {errors.reasone && (
                            <Typography variant="caption" style={{ color: "red", marginLeft: "15px" }}>
                                {errors.reasone}
                            </Typography>
                        )}
                    </Grid> */}
                    {/* Priority */}
                    <Grid item xs={6}>
                        <FormControl
                            fullWidth
                            style={{ paddingTop: "8px" }}
                            sx={{
                                "& label.Mui-focused": { color: "rgba(0, 0, 0, 0.6)" },
                                "& .MuiOutlinedInput-root": {
                                    "&.Mui-focused fieldset": {
                                        borderColor: "#A0AEC0",
                                    },
                                    borderRadius: "10px",
                                    height: "50px",
                                },
                            }}

                        >
                            <InputLabel id="demo-simple-select-label" style={{ paddingTop: "8px" }}>
                                Priority
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={dataToAdd.priority}
                                label="Priority"
                                name="priority"
                                onChange={handleChange}
                                style={{ borderRadius: "10px", height: "45px", }}
                            >
                                <MenuItem value={"high"}>High</MenuItem>
                                <MenuItem value={"medium"}>Medium</MenuItem>
                                <MenuItem value={"low"}>Low</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    {/* Sub Reason */}
                    {/* <Grid item xs={12}>
                        <TextInput
                            fullWidth
                            variant="outlined"
                            placeholder="Please enter the sub reason"
                            value={dataToAdd.sup_reasone}
                            onChange={handleChange}
                            margin="dense"
                            styles={fieldStyle}
                            label={"Sub Reason"}
                            name="sup_reasone"
                            filled="true"
                            focused
                            InputProps={{
                                style: { backgroundColor: 'transparent', borderRadius: '10px', height: 45 },
                            }}
                            error={Boolean(errors.sup_reasone)}
                        />
                        {errors.sup_reasone && (
                            <Typography variant="caption" style={{ color: "red", marginLeft: "15px" }}>
                                {errors.sup_reasone}
                            </Typography>
                        )}
                    </Grid> */}
                    {/* Description */}
                    <Grid item xs={12}>
                        <label>Description</label>
                        <ReactQuill
                            value={dataToAdd.description_html}
                            onChange={handleQuillChange}
                            placeholder="Please add the description "
                            modules={modules}
                            formats={formats}
                            style={{ height: '200px', borderRadius: "8px", border: "1px solid #BBC1CE", overflow: 'hidden' }}
                        />
                        <style>
                            {`
                                .ql-size-small { font-size: 0.75rem; }
                                .ql-size-medium { font-size: 1rem; } /* Default size */
                                .ql-size-large { font-size: 1.5rem; }
                                .ql-size-huge { font-size: 2rem; }
                            `}
                        </style>
                        {errors.description_html && (
                            <Typography variant="caption" style={{ color: "red", marginLeft: "15px" }}>
                                {errors.description_html}
                            </Typography>
                        )}
                    </Grid>
                    {/* User Action  */}
                    <Grid item xs={12}>
                        <FormControl
                            fullWidth
                            style={{ marginTop: "2px", paddingTop: "8px" }}
                            sx={{
                                "& label.Mui-focused": { color: "rgba(0, 0, 0, 0.6)" },
                                "& .MuiOutlinedInput-root": {
                                    "&.Mui-focused fieldset": {
                                        borderColor: "#A0AEC0",
                                    },
                                    borderRadius: "10px",
                                    height: "50px",
                                },
                            }}
                        >
                            <InputLabel id="demo-simple-select-label" style={{ paddingTop: "8px" }}>
                                User Action
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                multiple
                                placeholder="Please enter the title"
                                value={dataToAdd.user_action}
                                label="User Action"
                                onChange={handleUserActionChange}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((value) => (
                                            <Chip key={value} label={userActionsMap[value]} style={{ backgroundColor: "#8AB2FF5E" }} />
                                        ))}
                                    </Box>
                                )}
                                style={{ borderRadius: "10px", height: "50px" }}
                            >
                                {Object.keys(userActionsMap).map((key) => (
                                    <MenuItem key={key} value={key}>
                                        {userActionsMap[key]}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {errors.user_action && (
                            <Typography variant="caption" style={{ color: "red", marginLeft: "15px" }}>
                                {errors.user_action}
                            </Typography>
                        )}                    </Grid>
                    {/* Suggested Actions For Agent */}
                    <Grid item xs={12}>
                        <TextInput
                            fullWidth
                            variant="outlined"
                            placeholder="Please enter suggested actions"
                            value={dataToAdd.suggested_actions}
                            onChange={handleChange}
                            margin="dense"
                            styles={fieldStyle}
                            label={"Suggested Actions For Agent"}
                            name="suggested_actions"
                            filled="true"
                            focused
                            InputProps={{
                                style: { backgroundColor: 'transparent', borderRadius: '10px', height: 45 },
                            }}
                            error={Boolean(errors.title)}
                        />
                        {errors.suggested_actions && (
                            <Typography variant="caption" style={{ color: "red", marginLeft: "15px" }}>
                                {errors.suggested_actions}
                            </Typography>
                        )}
                    </Grid>
                    {/* upload-imgs */}
                    {/* <Grid item xs={12}>
                        <Typography style={{
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            fontWeight: "400",
                            marginBottom: "5px",
                            color: "#7A869A",
                            lineHeight: "17px"
                        }}>
                            Upload Images
                        </Typography>
                        <Box style={imgsBox}>
                            {dataToAdd.images &&
                                dataToAdd?.images?.map((src, index) => (
                                    <div
                                        key={index}
                                        style={{
                                            position: "relative",
                                            marginRight: "10px",
                                            display: "flex",
                                        }}
                                    >
                                        <img
                                            src={src}
                                            alt={`alt-${index}`}
                                            style={{
                                                width: "220px",
                                                height: "185px",
                                                borderRadius: "8px",
                                                cursor: "pointer",
                                            }}
                                        />
                                        <button
                                            onClick={() => handleDelete(index)}
                                            style={{
                                                position: "absolute",
                                                top: "5px",
                                                left: "5px",
                                                background: "none",
                                                border: "none",
                                                cursor: "pointer",
                                                color: "red",
                                            }}
                                        >
                                            &#x2716;
                                        </button>
                                    </div>
                                ))}

                            {dataToAdd?.images?.length < 3 && (
                                <div
                                    style={{
                                        width: "200px",
                                        height: "185px",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        border: "1px dashed black",
                                        gap: "12px",
                                        borderRadius: "8px",
                                        backgroundColor: "#F5F5F5"
                                    }}
                                >
                                    <IconButton
                                        component="label"
                                        style={{
                                            color: colorConfigs.sidebar.bg,
                                        }}
                                    >
                                        <LuUpload
                                            size={45}
                                        />
                                        <input
                                            type="file"
                                            hidden
                                            style={{ display: "none" }}
                                            multiple
                                            size="20971520"
                                            accept="image/png, image/jpeg"
                                            key={inputKey}
                                            onChange={(e) => handleImagrUrl(e)}
                                            onClick={(e) => (e.target.value = null)}
                                        />{" "}
                                    </IconButton>
                                    <Typography color={"#7A869A"}>
                                        {" "}
                                        Tap To Upload
                                    </Typography>
                                </div>
                            )}
                        </Box>
                    </Grid> */}
                    {/* ckeck to add for driver and rider */}
                    <Grid item xs={12}>
                        <Box style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "42%",
                            padding: "8px 16px",
                            gap: "8px",
                            backgroundColor: colorConfigs.sidebar.ligthBg,
                            borderRadius: "8px"
                        }}
                            onClick={() => {
                                setChecked(!checked);
                            }
                            }
                        >
                            <Checkbox
                                style={{
                                    padding: 0,
                                    color: colorConfigs?.sidebar.bg,
                                    "&.Mui-checked": {
                                        color: colorConfigs?.sidebar.bg,
                                    },
                                }}
                                checked={checked}
                            />
                            <Typography style={{
                                fontSize: "16px",
                                fontWeight: 600,
                                color: "#172B4D"
                            }}>
                                Add this help center for {status === "drivers" ? "passengers" : "drivers"} too.
                            </Typography>
                        </Box>
                    </Grid>
                    {/* save and Cancel */}
                    <Grid item xs={4.5}>
                        <Box style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            alignItems: "flex-start",
                            width: "100%",
                            padding: "0",
                            gap: "16px",
                        }}>
                            <Button
                                variant="contained"
                                sx={{
                                    textTransform: 'none',
                                    backgroundColor: 'white',
                                    color: colorConfigs?.sidebar?.bg,
                                    borderRadius: '10px',
                                    width: "100%",
                                    padding: '10px 20px',
                                    '&:hover': {
                                        backgroundColor: '#E6E9F2',
                                    },
                                }}
                                onClick={() => {
                                    handleClose()
                                    navigate(status && status === "drivers" ? `/driverHelpCenter` : `/passengerHelpCenter`)
                                }
                                }
                            >
                                Cancel
                            </Button>

                            <Button
                                variant="contained"
                                sx={{
                                    textTransform: 'none',
                                    backgroundColor: process.env.REACT_APP_ENV === 'women_first' ? colorConfigs?.sidebar?.bg : '#005e8b',
                                    color: 'white',
                                    borderRadius: '10px',
                                    width: "100%",
                                    padding: '10px 20px',
                                    '&:hover': {
                                        backgroundColor: process.env.REACT_APP_ENV === 'women_first' ? colorConfigs?.sidebar?.bg : '#004b6e',
                                    },
                                }}
                                onClick={handleSave}
                            >
                                Save
                            </Button>
                        </Box>
                    </Grid>
                </Grid>

            </Container>
            <Loading
                loading={loading}
                background="rgba(86, 100, 210, 0.1)"
                loaderColor={colorConfigs.sidebar?.bg}
            />
        </>
    );
};

export default AddHelpCenter;
