import { Box, Button, ButtonGroup, Typography } from '@mui/material';
import React, { useState } from 'react';
import { BiEditAlt } from 'react-icons/bi';
import { HiOutlineUserGroup } from "react-icons/hi2";
import { IoEyeSharp } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import Balance from '../../../../../assets/svg/Balance';
import Employees from '../../../../../assets/svg/Employees';
import colorConfigs from '../../../../../configs/colorConfigs';
import AddMember from '../AddMember';

const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('First name is required'),
    last_name: Yup.string().required('Last name is required'),
    email: Yup.string().email('Please enter a valid email').required('Email is required'),
    voucherGroup: Yup.string().required('voucherGroup is required'),
});

function EditMember() {
    const [isEdit, setIsEdit] = useState(false);

    const selectedCorporateMember = useSelector((state) => state.appState.selectedCorporateMember);
    const handleViewMode = () => {
        setIsEdit(false);
    };

    const data = [
        { icon: <Balance />, label: 'Balance', value: `$${selectedCorporateMember?.wallet_balance}` },
        { icon: <Employees />, label: 'Voucher Groups', value: selectedCorporateMember?.voucher_details?.voucher_name },
        { icon: <HiOutlineUserGroup style={{ fontSize: 25, color: colorConfigs?.sidebar?.bg }} />, label: 'Total Rides', value:  selectedCorporateMember?.rides_count?.canceled +selectedCorporateMember?.rides_count?.completed },
    ];

    return (
        <Box sx={{ px: 3, pt: 2 }}>
            <Box sx={{
                bgcolor: colorConfigs?.sidebar?.ligthBg,
                borderRadius: 2,
                p: 2,
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
            }}>
                {data?.map((item, index) => (
                    <Box
                        key={index}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'row',
                            textAlign: 'left',
                            flex: 1,
                            px: 1.5,
                            borderLeft: index !== 0 ? '1px solid #E0E0E0' : 'none',
                        }}
                    >
                        {item.icon}
                        <Box sx={{ ml: 2 }}>
                            <Typography sx={{ color: '#7A869A', fontWeight: 500, fontSize: 14 }}>
                                {item.label}
                            </Typography>
                            <Typography sx={{ color: '#172B4D', fontWeight: 600, fontSize: 16 }}>
                                {item.value}
                            </Typography>
                        </Box>
                    </Box>
                ))}
            </Box>
            <ButtonGroup sx={{ borderRadius: 25, border: `1px solid ${colorConfigs.sidebar.bg}`, my: 3 }}>
                <Button
                    onClick={() => setIsEdit(true)}
                    style={{
                        backgroundColor: isEdit ? colorConfigs.sidebar.bg : 'transparent',
                        color: isEdit ? '#FFFFFF' : colorConfigs.sidebar.bg,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 4,
                        borderRadius: 25,
                        border: 'none',
                        textTransform: 'capitalize'
                    }}
                >
                    <BiEditAlt size={18} />
                    Edit
                </Button>
                <Button
                    onClick={handleViewMode}
                    style={{
                        backgroundColor: !isEdit ? colorConfigs.sidebar.bg : 'transparent',
                        color: !isEdit ? '#FFFFFF' : colorConfigs.sidebar.bg,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 4,
                        borderRadius: 25,
                        border: 'none',
                        textTransform: 'capitalize'
                    }}
                >
                    <IoEyeSharp size={18} />
                    View
                </Button>
            </ButtonGroup>
            <AddMember edit={isEdit} view={!isEdit} setIsEdit={setIsEdit} />
        </Box>
    );
}

export default EditMember;
