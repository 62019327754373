
import { Delete as DeleteIcon } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  Snackbar,
  Switch,
  Typography
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Loading from "react-fullscreen-loading";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import deletee from "../../assets/images/delete.png";
import colorConfigs from "../../configs/colorConfigs";
import { auth } from "../../configs/firebase";
import {
  deleteDataRedux,
  resetForm,
  saveCoordinates,
  setDivisions,
  setPlan,
  setRadius,
  setRidesTypeList,
  setZoneData,
  updateFormData
} from "../../redux/features/appStateSlice";
import {
  DeleteMethod,
  getMethod,
  postMethod,
  putMethod,
} from "../Hook/UseCustomApi";
import Error5xx from "../../components/common/HandelError5xx";
import DynamicTable from "../table/table";
// import Car from "../../assets/images/car.jpg";  notworking properly
import { AiFillEdit } from "react-icons/ai";
import AddCarType from "./AddCarType";
import EditCarType from "./EditCarType";

export default function RatesView() {
  const ridesTypeList = useSelector((state) => state.appState.ridesTypeList);
  const zonesList = useSelector(
    (state) => state.appState.zonesList
  );
  const [activeButton, setActiveButton] = useState("zones");
  const [modalOpen, setModalOpen] = useState(false);
  const [newRideType, setNewRideType] = useState({
    enabled: true,
    name: "",
    photo: null,
    createdDate: new Date(),
  });
  const [keyword, setKeyword] = useState("");
  const [count, setCount] = useState(5);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const rowsLimits = [5, 10, 25, 100];
  const [data, setData] = useState(zonesList);
  const [dataRides, setDataRides] = useState(ridesTypeList);
  const [itemToDelete, setItemToDelete] = useState(null);
  const dispatch = useDispatch();
  const [deleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] =
    useState(false);
  const [severity, setSeverity] = useState("error");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [editingRideType, setEditingRideType] = useState(null);
  const [actionType, setActionType] = useState("");

  const licAndConfig = useSelector((state) => state.licencesAndConfiguration);



  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = date.getFullYear();
    if (process.env.REACT_APP_ENV === 'women_first') {
      return `${year}-${month}-${day}`;
    }
    else {
      return `${day}/${month}/${year}`;
    }

  };

  //handle error5xx
  const [tryAgain, setTryAgain] = useState();
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [errorCount, setErrorCount] = useState(0);
  const [errorDialogCounter, setErrorDialogCounter] = useState(0);
  const handleError5xx = () => {
    setIsErrorDialogOpen(true);
    setErrorCount(prevCount => {
      const newCount = prevCount + 1;
      if (newCount === 1) {
        setErrorDialogCounter(5);
      } else if (newCount === 2) {
        setErrorDialogCounter(10);
      }
      return newCount;
    });
  };
  const handleCloseErrorDialog = () => {
    setIsErrorDialogOpen(false);
    if (errorCount <= 2) {
      handleErrorCountByClose()
    }

  };
  const handleErrorCountByClose = () => {
    setErrorCount(prevCount => {
      const newCount = prevCount - 1;
      if (newCount === 1) {
        setErrorDialogCounter(5);
      } else if (newCount === 2) {
        setErrorDialogCounter(10);
      }
      return newCount;
    });
  }

  const navigate = useNavigate();
  let currentPage = useRef();
  let currentLimit = useRef(10);
  let currentKeyword = useRef();
  let currentStatus = useRef();

  useEffect(() => {
    currentPage.current = 1;
    currentLimit.current = 10;
    currentKeyword.current = "";
    currentStatus.current = "all";
    fetchItems();
    window.scrollTo({ top: 0 });
  }, [activeButton]);

  const handleDeleteClick = (item) => {
    setItemToDelete(item);
    setDeleteConfirmationDialogOpen(true);
  };

  const handleSearch = (e) => {
    const newKeyword = e.target.value;
    if (newKeyword !== currentKeyword.current) {
      setKeyword(newKeyword);
      currentPage.current = 1;
      currentKeyword.current = newKeyword;
      fetchItems();
    }
  };
  const handleDeleteConfirmation = async (confirmed) => {
    setDeleteConfirmationDialogOpen(false);

    if (confirmed) {
      setLoading(true);
      try {
        const url =
          activeButton === "rides"
            ? `admin/car-type/${itemToDelete._id}`
            : `zone/${itemToDelete._id}`;
        DeleteMethod({
          url: url,
          handleErrorMessage: showSnackbar,
        }).then((res) => {
          if (activeButton === "rides") {
            dispatch(deleteDataRedux({ stateName: 'zonesList', key: '_id', value: itemToDelete._id }))
            setDataRides((prevData) =>
              prevData.filter((item) => item._id !== itemToDelete._id)
            );
          } else {
            dispatch(deleteDataRedux({ stateName: 'zonesList', key: '_id', value: itemToDelete._id }))
            setData((prevData) =>
              prevData.filter((item) => item._id !== itemToDelete._id)
            );
          }
        });
      } catch (error) {
        console.error("Error deleting item:", error);
        if (error === "Internal Server Error: Server error occurred.") {
          handleError5xx();
          setTryAgain(() => () => handleDeleteConfirmation(confirmed));
        }
      }
    }
    setLoading(false);
  };

  const getParam = () => {
    return {
      page: currentPage.current,
      limit: currentLimit.current,
      status: currentStatus.current,
      keyword: currentKeyword.current,
    };
  };

  const getRidesParams = () => {
    return {
      page: currentPage.current,
      limit: currentLimit.current,
      keyword: currentKeyword.current,
    };
  };
  const checkRides = (data) => {
    if (activeButton && activeButton === "zones") {
      setData(data);
    } else if (activeButton && activeButton === "rides") {
      setDataRides(data);
    }
  };
  const fetchItems = () => {
    if (
      (activeButton === "rides" && ridesTypeList?.length === 0) ||
      (activeButton === "zones" && zonesList?.length === 0)
    ) {
      setLoading(true);
    }
    const url = activeButton === "rides" ? "admin/car-type/all" : "zone";
    getMethod({
      url: url,
      params: activeButton === "rides" ? getRidesParams() : getParam(),
      handleErrorMessage: showSnackbar,
    })
      .then((response) => {
        let result = response?.data;
        checkRides(result);
        console.log(result, "result");
        if (activeButton === "rides") {
          dispatch(setRidesTypeList(result));
        } else if (activeButton === "zones") {
          dispatch(setZoneData(result));
        }
        // setData(result);
        setCount(response?.count);
      })
      .catch((error) => {
        console.error("Error fetching items:", error);
        if (error === "Internal Server Error: Server error occurred.") {
          handleError5xx();
          setTryAgain(() => fetchItems);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    currentPage.current = newPage;
    fetchItems();
  };

  const handleRowsChange = (rows) => {
    currentPage.current = 1;
    currentLimit.current = rows;
    setPage(1);
    fetchItems();
  };

  const style = {
    color: "#696969",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "24px",
    letterSpacing: "0.17px",
    textTransform: "capitalize",
  };

  const zoneColumns = [
    {
      field: "zone",
      headerName: "Zone Name",
      flex: 1,
      minWidth: "20px",
      editable: true,
      style: { ...style, color: "#172B4D", fontWeight: "600" },
      align: "left",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <Box style={{ display: "flex", alignItems: "center", gap: "12px", textDecoration: "underline", fontWeight: "bold", cursor: "pointer" }}
          onClick={() => {
            navigate(`/rates/edit/${params?.row?._id}`, {
              state: params?.row,
            });
            console.log(params?.row)
            dispatch(saveCoordinates(params?.row?.options?.polygon_points))
            dispatch(updateFormData(params?.row))
          }}
        >
          <Typography>{params.row.name}</Typography>
        </Box>
      ),
    },
    {
      field: "type",
      headerName: "Zone Type",
      minWidth: "150px",
      flex: 1,
      style: style,
      align: "left",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "car_type",
      headerName: "Car Types",
      minWidth: "150px",
      flex: 1,
      style: style,
      align: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        const carTypes = params.row.car_type[0].type;
        const mappedNames = carTypes?.map((typeObj, index) => typeObj.name);
        return (
          <Typography>
            {mappedNames.join(', ')}
          </Typography>
        );
      }
    },
    {
      field: "pickup_address",
      headerName: "Address",
      flex: 1,
      minWidth: "20px",
      editable: true,
      align: "left",
      style: { ...style, width: "400px" },
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <Typography>{params?.row?.address?.address}</Typography>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      style: style,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                alignItems: "center",
              }}>
              <Box
                style={{
                  width: "30px",
                  height: "30px",
                  backgroundColor: "#F3F8FD",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => {
                  navigate(`/rates/edit/${params?.row?._id}`, {
                    state: params?.row,
                  });
                  console.log(params?.row)
                  dispatch(saveCoordinates(params?.row?.options?.polygon_points))
                  dispatch(updateFormData(params?.row))
                }}>
                <AiFillEdit size="20px" style={{ color: "#5B93FF" }} />
              </Box>
              <IconButton
                aria-label="edit"
                onClick={() => handleDeleteClick(params.row)}>
                <div
                  style={{
                    backgroundColor: "#ffd8d8",
                    display: "flex",
                    borderRadius: "22px",
                    padding: "4px",
                  }}>
                  <DeleteIcon
                    fontSize="small"
                    className="icon"
                    style={{ color: "#E71D36" }}
                  />
                </div>
              </IconButton>
            </div>
          </>
        );
      },
    },
  ];

  const handleEnableDisableChange = async (rideType, newValue) => {
    let data = {
      enabled: newValue,
    };
    const url = `admin/car-type/${rideType._id}`;
    const tokenId = await auth?.currentUser?.getIdToken();
    const headers = {
      Authorization: `Bearer ${tokenId}`,
    };
    putMethod({
      url: url,
      body: data,
      handleErrorMessage: showSnackbar,
      headers: headers,
    })
      .then((response) => {
        setDataRides((prevData) => {
          const updatedData = prevData.map((item) => {
            if (item._id === rideType._id) {
              return {
                ...item,
                enabled: newValue,
              };
            }
            return item;
          });
          return updatedData;
        });
      })
      .catch((error) => {
        console.error("Error updating Active status:", error);
        if (error === "Internal Server Error: Server error occurred.") {
          handleError5xx();
          setTryAgain(() => () => handleEnableDisableChange(rideType, newValue));
        }
      });
  };

  const rideTypeColumns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: "20px",
      style: style,
      renderCell: (params) => (
        <span
          style={{ textDecoration: "underline", fontWeight: "bold", cursor: "pointer" }}
          onClick={() => {
            setActionType("edit");
            handleModalOpen(params.row);
          }}        >
          {params?.row?.name}
        </span>
      ),
    },
    {
      field: "image",
      headerName: "Image",
      flex: 1,
      minWidth: "20px",
      style: style,
      renderCell: (params) => (
        <img
          src={params.row.image}
          alt="ride type"
          style={{ width: "50px", height: "50px" }}
        />
      ),
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      flex: 1,
      minWidth: "20px",
      style: style,
      renderCell: (params) => (

        <Typography>
          {formatDate(params?.row?.created_at)}
        </Typography>
      ),
    },
    {
      field: "enabled",
      headerName: "Enable/Disable",
      headerClassName: "super-app-theme--header",
      width: 150,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
          }}>
          <Switch
            sx={{
              color: colorConfigs?.mainColor,
              "& .MuiSwitch-switchBase.Mui-checked": {
                color: "green",
              },
              "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                backgroundColor: "green",
              },
            }}
            checked={params.row.enabled}
            onChange={(event) =>
              handleEnableDisableChange(params.row, event.target.checked)
            }
          />
        </div>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      style: style,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            alignItems: "center",
          }}>
          <Box
            style={{
              width: "30px",
              height: "30px",
              backgroundColor: "#F3F8FD",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => {
              setActionType("edit");
              handleModalOpen(params.row);
            }}>
            <AiFillEdit size="20px" style={{ color: "#5B93FF" }} />
          </Box>

          {/* <IconButton
            aria-label="edit"
            onClick={() => handleDeleteClick(params.row)}>
            <div
              style={{
                backgroundColor: "#ffd8d8",
                display: "flex",
                borderRadius: "22px",
                padding: "4px",
              }}>
              <DeleteIcon
                fontSize="small"
                className="icon"
                style={{ color: "#E71D36" }}
              />
            </div>
          </IconButton> */}
        </div>
      ),
    }
  ];

  const handleButtonClick = (button) => {
    setActiveButton(button);
    setData(zonesList);
    setDataRides(ridesTypeList);
    // setData([]);
    clearData();
  };

  const clearData = () => {
    currentPage.current = 1;
    currentLimit.current = 10;
    currentKeyword.current = "";
    currentStatus.current = "all";
    setPage(1);
  };

  const handleModalOpen = (rideType = null) => {
    if (rideType) {
      setNewRideType({
        name: rideType.name,
        photo: rideType.image,
        enabled: rideType.enabled,
        createdDate: rideType.createdDate,
      });
      setEditingRideType(rideType);
    } else {
      setNewRideType({
        name: "",
        photo: null,
        enabled: true,
        createdDate: new Date(),
      });
      setEditingRideType(null);
    }
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const initialValues = {
    name: "",
    photo: null,
    enabled: false,
    createdDate: new Date(),
  };

  const handleAddRideType = async (values) => {
    // console.log(values);
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("enabled", values.enabled);
      if (values.photo) {
        formData.append("photo", values.photo);
      }

      const tokenId = await auth?.currentUser?.getIdToken();
      const headers = {
        Authorization: tokenId,
      };

      await postMethod({
        url: `admin/car-type`,
        body: {
          name: values.name,
          image: values.photo,
          enabled: newRideType.enabled,
        },
        handleErrorMessage: showSnackbar,
        headers: headers,
      }).then(() => {
        setNewRideType({
          enabled: true,
          name: "",
          photo: null,
          createdDate: new Date(),
        });
      });

      fetchItems();
    } catch (error) {
      console.error("Error adding/updating ride type:", error);
      setSeverity("error");
      showSnackbar("Failed to add/update ride type");
      if (error === "Internal Server Error: Server error occurred.") {
        handleError5xx();
        setTryAgain(() => () => handleAddRideType(values));
      }
    } finally {
      setLoading(false);
      setModalOpen(false);
    }
  };

  const handleEditRideType = async (values) => {
    console.log(values);
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("enabled", values.enabled);
      if (values.photo) {
        formData.append("photo", values.photo);
      }




      const tokenId = await auth?.currentUser?.getIdToken();
      const headers = {
        Authorization: tokenId,
      };

      await putMethod({
        url: `admin/car-type/${editingRideType._id}`,
        body: {
          name: values.name,
          image: values.photo,
          enabled: newRideType.enabled,
        },
        handleErrorMessage: showSnackbar,
        headers: headers,
      });
      setSeverity("success")
      showSnackbar("Ride type updated successfully");

      fetchItems();
    } catch (error) {
      console.error("Error updating ride type:", error);
      setSeverity("error")
      showSnackbar("Failed to update ride type");
      if (error === "Internal Server Error: Server error occurred.") {
        handleError5xx();
        setTryAgain(() => () => handleEditRideType(values));
      }
    } finally {
      setLoading(false);
      setModalOpen(false);
    }
  };

  return (
    <>
      {/* handleError5xx */}
      <Error5xx open={isErrorDialogOpen} errCount={errorCount} count={errorDialogCounter} handleClose={handleCloseErrorDialog} tryAgain={tryAgain} />
      {actionType === "add" ? (
        <AddCarType
          open={modalOpen}
          onClose={handleModalClose}
          handleAddRideType={handleAddRideType}
          newRideType={newRideType}
          setNewRideType={setNewRideType}
          colorConfigs={colorConfigs}
          showSnackbar={showSnackbar}
          initialValues={initialValues}
        />
      ) : (
        <EditCarType
          open={modalOpen}
          onClose={handleModalClose}
          handleEditRideType={handleEditRideType}
          newRideType={newRideType}
          setNewRideType={setNewRideType}
          colorConfigs={colorConfigs}
          rideTypeId={editingRideType?._id}
        />
      )}

      <Dialog
        open={deleteConfirmationDialogOpen}
        onClose={() => setDeleteConfirmationDialogOpen(false)}
        keepMounted>
        <DialogContent style={{ padding: "30px" }}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img src={deletee} alt="" />
          </div>
          <DialogContentText
            id="alert-dialog-slide-description"
            style={{ display: "flex", justifyContent: "center" }}>
            <Typography
              variant="h6"
              style={{
                fontWeight: "bold",
                color: "black",
                marginTop: "10px",
                marginBottom: "8px",
              }}>
              Delete {activeButton === "rides" ? "Ride Type" : "Zone"}
            </Typography>
          </DialogContentText>
          <Typography >
            Are you sure you want to delete this{" "}
            {activeButton === "rides" ? "ride type" : "zone"}?
          </Typography>
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            marginBottom: "20px",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}>
          <Button
            variant="outlined"
            style={{
              color: loading ? "gray" : "black",
              borderColor: "lightgray",
              width: "100%",
            }}
            onClick={() => handleDeleteConfirmation(false)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            style={{
              background: loading ? "gray" : "var(--Error, #FD4343)",
              color: "white",
              width: "100%",
            }}
            onClick={() => handleDeleteConfirmation(true)}>
            Yes, Delete
          </Button>
        </DialogActions>
      </Dialog>

      <div>
        <Grid
          container
          spacing={1}
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: "10px",
            paddingTop: "18px",
          }}>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={4}
            xl={3}
            style={{
              border: `1.5px solid ${colorConfigs?.sidebar?.bg}`,
              width: "38vh",
              height: "53px",
              borderRadius: "8px",
              marginTop: "2px",
              marginBottom: "20px",

              display: "flex",
              justifyContent: "center",
            }}
            sx={{
              marginBottom: { lg: "0", xs: "20px", sm: "20px" },
            }}>
            <Grid item xs={5}>
              <Button
                variant={activeButton === "zones" ? "contained" : "text"}
                style={{
                  minWidth: "75px",
                  color:
                    activeButton === "zones"
                      ? "white"
                      : colorConfigs?.sidebar?.bg,
                  backgroundColor:
                    activeButton === "zones"
                      ? colorConfigs?.sidebar?.bg
                      : "transparent",
                  fontWeight: activeButton === "zones" ? "bold" : "normal",
                }}
                onClick={() => handleButtonClick("zones")}>
                Zones
              </Button>
            </Grid>
            <Grid item xs={5}>
              <Button
                variant={activeButton === "rides" ? "contained" : "text"}
                style={{
                  minWidth: "110px",
                  color:
                    activeButton === "rides"
                      ? "white"
                      : colorConfigs?.sidebar?.bg,
                  backgroundColor:
                    activeButton === "rides"
                      ? colorConfigs?.sidebar?.bg
                      : "transparent",
                  fontWeight: activeButton === "rides" ? "bold" : "normal",
                  whiteSpace: "normal",
                }}
                onClick={() => handleButtonClick("rides")}>
                Ride Types
              </Button>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={8}
            xl={9}
            style={{

              display: "flex",
              justifyContent: "end",
              gap: "14px",
              marginBottom: "14px",
              width: "55%",
            }}>
            {/* <TextField
              label="Search for car"
              name="keyword"
              value={keyword}
              onChange={handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: "gray" }} />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              sx={{
                "& label.Mui-focused": { color: "rgba(0, 0, 0, 0.6)" },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#A0AEC0",
                  },
                  borderRadius: "10px",
                  height: "52px",
                },
              }}
            /> */}
            {((licAndConfig?.licenses?.max_car_type_number > dataRides.length && activeButton === "rides") ||
              (licAndConfig?.licenses?.max_zone_number > zonesList.length && activeButton === "zones")
            )
              && (
                <Button
                  variant="contained"
                  sx={{
                    height: "80%",
                    backgroundColor: colorConfigs.sidebar.bg,
                    borderRadius: "20px",
                    fontWeight: "bold",
                    color: "white",
                    '&:hover': {
                      backgroundColor: '#FFFFFF',
                      color: colorConfigs?.sidebar?.bg,

                    },
                  }}
                  onClick={
                    activeButton === "rides"
                      ? () => {
                        setActionType("add");
                        handleModalOpen();
                      }
                      : () => {
                        navigate("/rates/add");
                        dispatch(resetForm());
                        dispatch(setRadius(0));
                        dispatch(
                          setDivisions([
                            { key: "start", value: 0, label: "0" },
                            { key: "end", value: 9999, label: "9999" },
                          ])
                        );

                        dispatch(
                          setPlan([
                            {
                              start_distance: 0,
                              end_distance: 9999,
                              speeds: [],
                              value: 9999,
                            },
                          ])
                        );
                      }
                  }>
                  +{" "}
                  {activeButton === "rides" ? "Add New Ride Type" : "Add New Zone"}
                </Button>
              )}
          </Grid>
        </Grid>
      </div>

      <DynamicTable
        columns={activeButton === "rides" ? rideTypeColumns : zoneColumns}
        data={activeButton === "rides" ? dataRides : data}
        count={count}
        page={page}
        rowsLimits={rowsLimits}
        pageChange={handlePageChange}
        rowsChange={handleRowsChange}
      />

      <Loading
        loading={loading}
        background="rgba(86, 100, 210, 0.1)"
        loaderColor={colorConfigs.sidebar?.bg}
      />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}>
        <Alert onClose={handleSnackbarClose} severity={severity} >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}